import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as historyActions from '../../../actions/history';
import * as pageActions from '../../../actions/page';
import * as historyUtils from '../../../utils/historyUtils';

import historyShape from '../../../shapes/historyShape';

import TopLeftButton from '../../../components/TopBar/TopLeftButton';

class TopLeftButtonContainer extends Component {
  static propTypes = {
    isOverlay: PropTypes.bool,
    history: historyShape.isRequired,
    onHomeClick: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    toggleMobileMenu: PropTypes.func.isRequired,
    onExternalBackClick: PropTypes.func,
  };

  static defaultProps = {
    isOverlay: false,
    onExternalBackClick: null,
  };

  get currentLocation() {
    const { history } = this.props;

    return history.locations[history.currentIndex] ?? {};
  }

  get previousLocation() {
    const { history } = this.props;

    if (this.currentLocation.pathname === '/') return null;

    return historyUtils.determinePreviousLocation(history.currentIndex, history.locations);
  }

  get previousRoutesFlow() {
    return this.previousLocation && historyUtils.findRoutesFlow(this.previousLocation.flow);
  }

  get previousRoute() {
    return (
      this.previousLocation &&
      historyUtils.findLocationRoute(this.previousLocation, this.previousRoutesFlow.routes)
    );
  }

  get caption() {
    const { isOverlay } = this.props;

    if (isOverlay) return 'Back';

    const { currentLocation } = this;

    if (currentLocation.state?.backButtonOverwrite)
      return currentLocation.state?.backButtonOverwrite;

    return this.previousRoute ? this.previousRoute.caption : null;
  }

  get isBackButtonForceShow() {
    return this.currentLocation.state?.backButtonForceShow;
  }

  handleGoBackClick = () => {
    const { isOverlay, goBack, onExternalBackClick } = this.props;

    if (isOverlay) {
      if (isFunction(onExternalBackClick)) {
        // Call external function if it is passed to TopBar
        return onExternalBackClick();
      }
    }
    return goBack();
  };

  handleMobileMenuClick = () => {
    const { toggleMobileMenu } = this.props;

    toggleMobileMenu();
  };

  render() {
    const { isOverlay, onHomeClick } = this.props;

    return (
      <TopLeftButton
        isOverlay={isOverlay}
        caption={this.caption}
        previousLocation={this.previousLocation}
        isBackButtonForceShow={this.isBackButtonForceShow}
        onHomeClick={onHomeClick}
        onGoBackClick={this.handleGoBackClick}
        onMobileMenuClick={this.handleMobileMenuClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  history: state.history,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goBack: historyActions.goBack,
      toggleMobileMenu: pageActions.toggleMobileMenu,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TopLeftButtonContainer);
