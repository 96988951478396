import PropTypes from 'prop-types';

const menuCategoryShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  baseType: PropTypes.number.isRequired,
  desc: PropTypes.string,
});

export default menuCategoryShape;
