import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import {
  getAbsoluteMinOrderFee,
  getFreeDeliveryThreshold,
  getMinOrderFee,
  getOrderDiscountedSubtotal,
  getOrderOrderItemsIds,
} from '../../../sagas/selectors';

import Currency from '../../Currency';
import FontAwesome from '../../FontAwesome';
import ProgressBar from '../../ProgressBar/ProgressBar';

import { ReactComponent as IconTruck } from '../../../images/icon-truck.svg';

import './CartMinOrderBanner.css';

function CartMinOrderBanner() {
  const absoluteMinOrderFee = useSelector(getAbsoluteMinOrderFee);
  const freeDeliveryThreshold = useSelector(getFreeDeliveryThreshold);
  const subtotal = useSelector(getOrderDiscountedSubtotal);
  const minOrderFee = useSelector(getMinOrderFee);
  const orderItemsIds = useSelector(getOrderOrderItemsIds);

  if (orderItemsIds.length < 1) return null;

  if (!absoluteMinOrderFee && !freeDeliveryThreshold) {
    return null;
  }

  if (absoluteMinOrderFee && !freeDeliveryThreshold && !minOrderFee) {
    return null;
  }

  if (freeDeliveryThreshold && subtotal >= freeDeliveryThreshold) {
    return (
      <div className="CartMinOrderBannerWrapper">
        <div className="CartMinOrderUnlockedBanner">
          <span className="BannerLabel">
            <Typography variant="subtitle1" fontWeight="600">
              FREE delivery unlocked !
            </Typography>
          </span>
          <FontAwesome name="circle-check" />
        </div>
      </div>
    );
  }

  const minOrderProgress = (subtotal * 100) / absoluteMinOrderFee;
  const deliveryDiscountProgress =
    ((subtotal - (absoluteMinOrderFee || 0)) * 100) /
    (freeDeliveryThreshold - (absoluteMinOrderFee || 0));
  const minOrderRatio = (absoluteMinOrderFee * 100) / freeDeliveryThreshold;
  const deliveryDiscountRatio = 100 - minOrderRatio;
  const totalProgress = (subtotal * 100) / Math.max(freeDeliveryThreshold, absoluteMinOrderFee);

  /* May need to change to depend on what type, e.g. amount off/full waiver */
  const labelPhrase = !minOrderFee ? 'unlock FREE delivery' : 'waive min. order fee';
  const labelPrice = !minOrderFee ? freeDeliveryThreshold - subtotal : minOrderFee;

  return (
    <div className="CartMinOrderBannerWrapper">
      <div className="CartMinOrderBanner">
        <div className="CartMinOrderBannerRow">
          <span className="BannerLabel">
            <Currency value={labelPrice} />
            &nbsp;more to
            {labelPhrase}
          </span>
        </div>
        <div className="ProgressBarRow">
          <ProgressBar value={minOrderProgress} width={minOrderRatio} />
          {freeDeliveryThreshold !== 0 && (
            <ProgressBar value={deliveryDiscountProgress} width={deliveryDiscountRatio} />
          )}
        </div>
      </div>
      <IconTruck
        className="CartMinOrderProgressIcon"
        style={{ left: `calc(${totalProgress}% - 15px)` }}
      />
    </div>
  );
}

export default CartMinOrderBanner;
