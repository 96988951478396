import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './EmailAddress.css';

function EmailAddress(props) {
  const { className } = props;
  return <input {...props} className={classnames(className, 'EmailAddress')} />;
}

EmailAddress.propTypes = {
  className: PropTypes.string,
};

EmailAddress.defaultProps = {
  className: '',
};

export default EmailAddress;
