import moment from 'moment';

export const transformResendPinRequest = ({ pendingPhone }) => ({
  body: {
    number: pendingPhone,
  },
});

export const transformFacebookRequest = ({ accessToken, email }) => ({
  body: {
    access_token: accessToken,
    email,
  },
});

export const transformSignUpRequest = ({
  email,
  password,
  firstName,
  lastName,
  marketingOptIn,
  country,
}) => ({
  body: {
    email,
    password,
    first_name: firstName,
    last_name: lastName,
    marketing_opt_in: marketingOptIn,
    country,
  },
});

export const transformChangeMarketingOptInRequest = ({ marketingOptIn }) => ({
  body: {
    marketing_opt_in: marketingOptIn,
  },
});

export const transformAutoLoginRequest = ({ autologinToken }) => ({
  body: {
    autologin_token: autologinToken,
  },
});

export const transformUpdateProfileRequest = ({
  firstName,
  lastName,
  dob,
  gender,
  marketingOptIn,
}) => ({
  body: {
    first_name: firstName,
    last_name: lastName,
    dob: dob && moment(dob).format('YYYY-MM-DD'),
    gender: gender || undefined,
    marketing_opt_in: marketingOptIn,
  },
});

export const transformChangePasswordRequest = ({ oldPassword, newPassword }) => ({
  body: {
    old_password: oldPassword,
    new_password: newPassword,
  },
});

export const transformUpdatePartnerEmailRequest = ({ partnerEmail }) => ({
  body: {
    secondary_email: partnerEmail,
  },
});

export const transformReverifyEmailRequest = ({ email }) => ({
  body: {
    email,
  },
});

export const transformAddPaymentMethodRequest = () => ({
  body: {
    success_url: `${window.location.origin}/popup/payment?success=true&state=restore`,
    failure_url: `${window.location.origin}/popup/payment?success=false&state=restore`,
  },
});

export const transformRegister2c2pCardRequest = ({
  cardRegistrationId,
  cardHolderName,
  encryptedCardInfo,
  maskedCardInfo,
  expMonthCardInfo,
  expYearCardInfo,
}) => ({
  body: {
    card_holder_name: cardHolderName,
    encrypted_card_info: encryptedCardInfo,
    masked_card_info: maskedCardInfo,
    exp_month_card_info: expMonthCardInfo,
    exp_year_card_info: expYearCardInfo,
  },
  urlArgs: [cardRegistrationId],
});

export const transformRewardsRequest = ({ uuid, brand }) => ({
  urlArgs: [uuid],
  searchParams: {
    brand,
  },
});

export const transformInitDataRequest = ({ brand }) => ({
  searchParams: {
    brand,
  },
});

export const transformVerifyPinRequest = ({ pin }) => ({
  body: {
    pin,
  },
});

export const transformRemovePaymentMethodRequest = ({ id }) => ({
  urlArgs: [id],
});

export const setDefaultPaymentMethodRequest = ({ uuid }) => ({
  body: {
    account_uuid: uuid,
  },
});

export const transformCreatePhoneRequest = ({ number }) => ({
  body: {
    number,
  },
});

export const transformForgetPasswordRequest = ({ email }) => ({
  body: {
    email,
  },
});

export const transformLoginRequest = ({ email, password }) => ({
  body: {
    email,
    password,
  },
});

export const transformRecaptchaRequest = ({ recaptchaValue }) => ({
  body: {
    'g-recaptcha-response': recaptchaValue,
  },
});

export const transformClaimRewardRequest = ({ rewardType, rewardId }) => ({
  body: {
    reward_id: rewardId,
    reward_type: rewardType,
  },
});

export function transformPairKioskRequest({ code }) {
  return {
    body: {
      code,
    },
  };
}

export function transformFetchMassRewards({ brand }) {
  return {
    searchParams: {
      brand,
    },
  };
}

export function transformRedeemMassRewardRequest({ rewardId }) {
  return {
    urlArgs: [rewardId],
  };
}

export function transformValidateApplePayRequest({ validationUrl, domainName, displayName }) {
  return {
    body: {
      validation_url: validationUrl,
      domain_name: domainName,
      display_name: displayName,
    },
  };
}
