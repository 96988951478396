import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import favoriteMenuItemShape from '../../../shapes/favoriteMenuItemShape';

import EditFavorite from '../../../containers/EditFavorite';
import FavoriteMenuItem from '../../../containers/FavoriteMenuItem';
import Button from '../../Button';
import InfinteScrollLoader from '../../InfiniteScrollLoader/InfiniteScrollLoader';

import './Favorites.css';

function Favorites({
  favoriteMenuItems,
  hasMoreFavoriteMenus,
  editFavoriteMenuItem,
  onOrderNowClick,
  onOrderHistoryClick,
  onEditFavoriteOpen,
  onEditFavoriteCancel,
  onEditFavoriteSave,
  onLoadMoreFavoriteMenus,
}) {
  if (!favoriteMenuItems.length > 0) {
    return (
      <div className="Favorites">
        <ul className="title-function">
          <li>
            <Typography variant="h3" color="secondary.dark" className="AccountTitle">
              Favourites
            </Typography>
          </li>
        </ul>
        <div className="EmptyFavorites">
          <div className="OrderHistoryRow">
            <Typography variant="subtitle1">You don&rsquo;t have any favourites yet.</Typography>
          </div>
          <div className="ButtonsRow">
            <Typography variant="subtitle1" className="FavoritesLine">
              Add from{' '}
              <Button className="blue-link" onClick={onOrderHistoryClick}>
                order history
              </Button>{' '}
              or
            </Typography>
            <Button className="btn-primary" onClick={onOrderNowClick}>
              Proceed to order
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="Favorites">
      <EditFavorite
        favoriteMenuItem={editFavoriteMenuItem}
        onHide={onEditFavoriteCancel}
        onSave={onEditFavoriteSave}
      />
      <ul className="title-function">
        <li>
          <Typography variant="h3" color="secondary.dark" className="AccountTitle">
            Favourites
          </Typography>
        </li>
      </ul>
      <div id="FavoriteMenuListWrapper" className="FavoriteMenuListWrapper">
        <InfiniteScroll
          className="FavoriteMenuItemsList"
          dataLength={favoriteMenuItems.length}
          next={onLoadMoreFavoriteMenus}
          hasMore={hasMoreFavoriteMenus}
          loader={<InfinteScrollLoader />}
          scrollableTarget="FavoriteMenuListWrapper"
          style={{ overflow: 'hidden' }}
        >
          <ul className="FavoriteMenuItemsList">
            {favoriteMenuItems.map((favoriteMenuItem) => (
              <li key={favoriteMenuItem.id}>
                <FavoriteMenuItem data={favoriteMenuItem} onEditFavoriteOpen={onEditFavoriteOpen} />
              </li>
            ))}
          </ul>
        </InfiniteScroll>
      </div>
      <div className="ButtonsRow">
        <Typography variant="subtitle1">
          Add from{' '}
          <Button className="blue-link" onClick={onOrderHistoryClick}>
            order history
          </Button>{' '}
          or
        </Typography>
        <Button className="btn-primary" onClick={onOrderNowClick}>
          Proceed to order
        </Button>
      </div>
    </div>
  );
}

Favorites.propTypes = {
  favoriteMenuItems: PropTypes.arrayOf(favoriteMenuItemShape).isRequired,
  hasMoreFavoriteMenus: PropTypes.bool.isRequired,
  editFavoriteMenuItem: favoriteMenuItemShape,
  onOrderNowClick: PropTypes.func.isRequired,
  onOrderHistoryClick: PropTypes.func.isRequired,
  onEditFavoriteOpen: PropTypes.func.isRequired,
  onEditFavoriteCancel: PropTypes.func.isRequired,
  onEditFavoriteSave: PropTypes.func.isRequired,
  onLoadMoreFavoriteMenus: PropTypes.func.isRequired,
};

Favorites.defaultProps = {
  editFavoriteMenuItem: null,
};

export default Favorites;
