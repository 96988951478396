import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../../actions/order';

import customerAddressShape from '../../../shapes/customerAddressShape';
import routerShape from '../../../shapes/routerShape';

import Addresses from '../../../components/Account/Addresses';
import withRouter from '../../WithRouter';

import ecomService from '../../../services/api';

class AddressesContainer extends Component {
  static propTypes = {
    redirectOnSelect: PropTypes.bool,
    isSelectable: PropTypes.bool,
    selectedAddressId: PropTypes.number,
    customerAddressIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    customerAddresses: PropTypes.objectOf(customerAddressShape).isRequired,
    removeCustomerAddress: PropTypes.func.isRequired,
    selectAddress: PropTypes.func.isRequired,

    router: routerShape.isRequired,
  };

  static defaultProps = {
    redirectOnSelect: true,
    isSelectable: false,
    selectedAddressId: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      maxAddressShown: 3,
    };
  }

  get customerAddresses() {
    const { customerAddressIds, customerAddresses, selectedAddressId } = this.props;

    /* Moves currently selected address to the top */
    customerAddressIds.forEach((customerAddressId, index) => {
      if (customerAddressId === selectedAddressId) {
        customerAddressIds.splice(index, 1);
        customerAddressIds.unshift(customerAddressId);
      }
    });

    return customerAddressIds.map((customerAddressId) => customerAddresses[customerAddressId]);
  }

  handleAddAddressClick = () => {
    const { router } = this.props;

    router.push(
      {
        pathname: '/addAddress',
      },
      {
        nextLink: '/account/addresses',
      },
    );
  };

  handleShowAllAddress = () => {
    const { customerAddresses } = this;

    this.setState({ maxAddressShown: customerAddresses.length });
  };

  handleSelectAddress = (customerAddressId) => {
    const { selectAddress, router, redirectOnSelect } = this.props;

    selectAddress({ customerAddressId });
    this.setState({ maxAddressShown: 3 });

    if (redirectOnSelect) {
      router.push({ pathname: '/time' });
    }
  };

  handleRemoveAddress = (customerAddressId) => {
    const { removeCustomerAddress } = this.props;

    return removeCustomerAddress({ customerAddressId });
  };

  render() {
    const { maxAddressShown } = this.state;
    const { isSelectable, selectedAddressId } = this.props;

    return (
      <Addresses
        isSelectable={isSelectable}
        maxAddressShown={maxAddressShown}
        customerAddresses={this.customerAddresses}
        selectedAddressId={selectedAddressId}
        onSelectAddress={this.handleSelectAddress}
        onRemoveAddress={this.handleRemoveAddress}
        onAddAddressClick={this.handleAddAddressClick}
        onShowAllAddress={this.handleShowAllAddress}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  customerAddressIds: state.user.customerAddressIds,
  customerAddresses: state.user.customerAddresses,
  selectedAddressId: state.order.customerAddressId,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeCustomerAddress: ecomService.removeCustomerAddress.requestActionCreator,
      selectAddress: orderActions.selectAddress,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddressesContainer));
