import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as apiActions from '../../../actions/api';
import statusEnum from '../../../enums/statusEnum';
import * as orderUtils from '../../../utils/orderUtils';

import pageInfoShape from '../../../shapes/pageInfoShape';
import routerShape from '../../../shapes/routerShape';
import scanHistoryShape from '../../../shapes/scanHistoryShape';
import userOrderShape from '../../../shapes/userOrderShape';

import PastOrders from '../../../components/Account/PastOrders';
import withRouter from '../../WithRouter';

const INITIAL_ORDERS_DISPLAY_COUNT = 10;
const DISPLAY_COUNT_INCREMENT_STEP = 10;

class PastOrdersContainer extends Component {
  static propTypes = {
    landingPageSlug: PropTypes.string,
    userOrderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    userOrders: PropTypes.objectOf(userOrderShape).isRequired,
    scanHistory: PropTypes.arrayOf(scanHistoryShape).isRequired,
    ordersPageInfo: pageInfoShape.isRequired,
    ordersApiStatus: PropTypes.number.isRequired,
    loadNextOrdersPage: PropTypes.func.isRequired,

    router: routerShape.isRequired,
  };

  static defaultProps = {
    landingPageSlug: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      activePanel: null,
      visibleOrdersCount: INITIAL_ORDERS_DISPLAY_COUNT,
      loadingOrders: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { loadingOrders } = this.state;
    const { ordersApiStatus } = this.props;
    if (
      loadingOrders &&
      [statusEnum.REQUESTED, statusEnum.RELOADING].includes(prevProps.ordersApiStatus) &&
      [statusEnum.SUCCESS, statusEnum.ERROR].includes(ordersApiStatus)
    ) {
      // Show more orders after successful fetching
      this.setState((prevState) => ({
        visibleOrdersCount: prevState.visibleOrdersCount + DISPLAY_COUNT_INCREMENT_STEP,
        loadingOrders: false,
      }));
    }
  }

  handlePanelSelect = (selectedPanel) => {
    const { activePanel } = this.state;
    if (selectedPanel === activePanel) {
      this.handlePanelClose();
      return false;
    }

    this.setState({ activePanel: selectedPanel });
    return true;
  };

  handlePanelClose() {
    this.setState({ activePanel: false });
  }

  handleLoadMoreOrders = () => {
    const { visibleOrdersCount } = this.state;
    const { userOrderIds, loadNextOrdersPage } = this.props;

    if (!this.hasMoreOrders || this.areOrdersLoading) return;

    if (visibleOrdersCount >= userOrderIds.length) {
      this.setState({ loadingOrders: true });
      loadNextOrdersPage();
    } else {
      this.setState((prevState) => ({
        visibleOrdersCount: Math.min(
          prevState.visibleOrdersCount + DISPLAY_COUNT_INCREMENT_STEP,
          userOrderIds.length,
        ),
        loadingOrders: false,
      }));
    }
  };

  handleOrderNowClick = () => {
    const { landingPageSlug, router } = this.props;

    router.push({ pathname: landingPageSlug ? `/site/${landingPageSlug}` : '/' });
  };

  get userOrders() {
    const { userOrderIds, userOrders } = this.props;

    return orderUtils.sortedUserOrdersArray(
      userOrderIds
        .map((userOrderId) => userOrders[userOrderId])
        .filter((userOrder) => !orderUtils.isOrderStatusFailed(userOrder.orderStatusId)),
    );
  }

  get userOrderIds() {
    const { visibleOrdersCount } = this.state;
    return this.userOrders.map((userOrder) => userOrder.id).slice(0, visibleOrdersCount);
  }

  get hasMoreOrders() {
    const { visibleOrdersCount } = this.state;
    const { userOrderIds, ordersPageInfo } = this.props;

    return visibleOrdersCount < userOrderIds.length || userOrderIds.length < ordersPageInfo.count;
  }

  get areOrdersLoading() {
    const { ordersApiStatus } = this.props;
    return [statusEnum.REQUESTED, statusEnum.RELOADING].includes(ordersApiStatus);
  }

  render() {
    const { activePanel } = this.state;
    const { scanHistory } = this.props;

    return (
      <PastOrders
        userOrderIds={this.userOrderIds}
        hasMoreOrders={this.hasMoreOrders}
        scanHistory={scanHistory}
        activePanel={activePanel}
        onPanelSelect={this.handlePanelSelect}
        onLoadMoreOrders={this.handleLoadMoreOrders}
        onOrderNow={this.handleOrderNowClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userOrderIds: state.api.userOrderIds,
  userOrders: state.api.userOrders,
  ordersPageInfo: state.api.pageInfo.orders,
  ordersApiStatus: state.api.status.orders,
  scanHistory: state.loyalty.scanHistory.verified,
  landingPageSlug: state.order.landingPageHistory[state.order.landingPageHistory.length - 1],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadNextOrdersPage: apiActions.loadNextOrdersPage,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PastOrdersContainer));
