import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as orderItemUtils from '../../utils/orderItemUtils';

import OrderItemPriceDelta from '../../components/OrderItemPriceDelta';

class OrderItemPriceDeltaContainer extends Component {
  get priceDifferences() {
    const { orderItem } = this.props;
    const {
      ingredientBreakdown,
      selectedIngredientServings,
      highlightedIngredientServings,
      personalSettings,
    } = orderItem;

    return orderItemUtils.calculatePriceDelta({
      ingredientBreakdown,
      selectedIngredientServings,
      highlightedIngredientServings,
      quantity: personalSettings.length,
    });
  }

  render() {
    const { price, total, discountAmount } = this.priceDifferences;
    if (!price) {
      return null;
    }

    return (
      <OrderItemPriceDelta
        originalPriceDifference={price}
        totalPriceDifference={total}
        isDiscounted={discountAmount > 0}
      />
    );
  }
}

OrderItemPriceDeltaContainer.propTypes = {
  orderItem: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  orderItem: state.orderItem,
});

export default connect(mapStateToProps)(OrderItemPriceDeltaContainer);
