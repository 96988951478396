import { apiCallSuccessChannel } from '@spq/redux-api-client';
import { all, cancel, fork, put, select, take } from 'redux-saga/effects';

import * as loyaltyActions from '../actions/loyalty';
import * as orderActions from '../actions/order';
import * as orderItemActions from '../actions/orderItem';
import diningChoiceEnum from '../enums/diningChoiceEnum';
import guestLimitAlertEnum from '../enums/guestLimitAlertEnum';
import previewStageEnum from '../enums/previewStageEnum';
import { dispatchCallResult } from '../utils/apiUtils';
import {
  extractCurrentOrderItemOrderPreview,
  getOrderItemHashPrices,
  isPreviewStagesEqual,
  parseOrderPreviewResponse,
  prepareOrderPreviewOrderItems,
} from '../utils/orderPreviewUtils';
import * as orderUtils from '../utils/orderUtils';
import { takeLatest } from '../utils/sagaUtils';

import { VERSION as version } from '../version';
import * as selectors from './selectors';

import ecomService, { paymentService } from '../services/api';

function* getCurrentItem(stages) {
  const currentOrderItem = yield select(selectors.getValidOrderItem);

  // Only include current order item when stages have highlighted
  if (currentOrderItem && stages.includes(previewStageEnum.HIGHLIGHTED)) {
    return currentOrderItem;
  }
  return undefined;
}

function* previewOrder({ stages }) {
  let order = yield select(selectors.getOrder);
  const gglocation = yield select(selectors.getOrderGglocation);
  const timeSlot = yield select(selectors.getTimeSlot);
  const userRewardUuid = yield select(selectors.getSelectedUserRewardUuid);
  const promoCode = yield select(selectors.getSelectedPromoCode);
  const { diningChoice, customerAddressId } = order;
  const currentOrderItem = yield getCurrentItem(stages);
  const isLandingPage = orderUtils.isLandingPageOrder({
    landingPageHistory: order.landingPageHistory,
  });
  let selectedPaymentMethod = yield select(selectors.getSelectedPaymentMethod);

  // Prevent preview with add payment method
  if (selectedPaymentMethod?.id === 'add') {
    selectedPaymentMethod = null;
  }

  if (!gglocation && !isLandingPage) {
    /* Only acceptable case with no gglocation is if it's delivery (no store determined yet) */
    if (diningChoice !== diningChoiceEnum.DELIVERY) {
      return;
    }

    /* Don't preview order if it doesn't contain a specific time */
    if (!(order.due || timeSlot || order.orderTime)) {
      return;
    }
  }

  /* Preview condition for landing page */
  if (isLandingPage) {
    /* Don't preview order when user just select delivery on dining choice modal */
    if (diningChoice === diningChoiceEnum.DELIVERY && !customerAddressId && !order.orderTime) {
      return;
    }
  }

  if (stages.includes(previewStageEnum.HIGHLIGHTED)) {
    // In highlighted stage, if the current order item is under modifying,
    // then it will be temporarily removed to prevent previewing it twice
    // as it will be merged to main order item set in previewOrder call
    if (Object.keys(order.orderItems).includes(currentOrderItem?.id)) {
      const { [currentOrderItem.id]: _Ignored, ...orderItems } = order.orderItems;
      order = {
        ...order,
        orderItems,
        orderItemsIds: Object.keys(orderItems),
      };
    }
  }

  yield put(orderActions.previewOrder());
  const body = {
    order,
    gglocation,
    timeSlot,
    userRewardUuid,
    currentOrderItem,
    promoCode,
    paymentMethod: selectedPaymentMethod,
    diningChoice,
    customerAddressId,
    version,
  };
  const result = yield ecomService.previewOrder.call(body);

  const parsedResult = parseOrderPreviewResponse({ result });

  yield dispatchCallResult({
    requestActionType: orderActions.ORDER_PREVIEW,
    result: parsedResult,
  });
  if (parsedResult.response) {
    const parsedOrderItems = prepareOrderPreviewOrderItems(parsedResult?.response?.ordermenu);
    const [orderPreviewCurrentOrderItem, remainingOrderPreviewItems] =
      extractCurrentOrderItemOrderPreview({
        orderPreviewMenuItems: parsedOrderItems,
        currentOrderItem,
      });
    const orderItemHashPrices = getOrderItemHashPrices(remainingOrderPreviewItems);

    yield put(
      orderActions.updatePrices({
        ...parsedResult.response,
        orderItemHashPrices,
        orderPreviewCurrentOrderItem,
        stages,
      }),
    );

    const alert = parsedResult?.response?.alert;
    if (alert && Object.values(guestLimitAlertEnum).includes(alert.rejectCode)) {
      yield put(orderActions.setIsGuestLimitReached(true));
    } else {
      yield put(orderActions.setIsGuestLimitReached(false));
    }
  }
}

function* previewOrderWithStages() {
  const state = yield select();

  // Check if ready and highlighted stage has the same order item set
  // If not will trigger extra preview for highlighted order item
  const previewStages = isPreviewStagesEqual(
    previewStageEnum.READY,
    previewStageEnum.HIGHLIGHTED,
    state,
  )
    ? [{ stages: [previewStageEnum.HIGHLIGHTED, previewStageEnum.READY] }]
    : [{ stages: [previewStageEnum.HIGHLIGHTED] }, { stages: [previewStageEnum.READY] }];

  /* Price order for each updated set of stages */
  // eslint-disable-next-line no-restricted-syntax
  for (const previewStageGroup of previewStages) {
    yield previewOrder(previewStageGroup);
  }
}

function* watchOrderChanges() {
  let lastState;
  let lastTask;

  // eslint-disable-next-line func-names
  yield fork(function* () {
    while (true) {
      const prevState = yield select();

      yield take([
        apiCallSuccessChannel(`${paymentService.paymentMethod}`),
        loyaltyActions.CONFIRM_HIGHLIGHTED_REWARD,
        loyaltyActions.UNSET_SELECTED_USER_REWARD,
        loyaltyActions.SET_SELECTED_PROMO_CODE_SUCCESS,
        loyaltyActions.UNSET_SELECTED_PROMO_CODE,
        loyaltyActions.REMOVE_DISCOUNT,
        orderActions.SELECT_PAYMENT_METHOD,
        orderActions.PLACE_ORDER_SUCCESS,
        orderActions.UPDATE_ORDER_ORDER_ITEM,
        orderActions.REMOVE_ORDER_ITEM,
        orderActions.RESET_ORDER,
        orderActions.UPDATE_GGLOCATION,
        orderActions.SET_ORDER_TIME,
        orderActions.ENABLE_CARBON_OFFSET,
        orderActions.DISABLE_CARBON_OFFSET,
        orderActions.UPDATE_ORDER_ITEM_QUANTITY,
        orderActions.ENABLE_TAKE_OUT_WITH_BAG,
        orderActions.DISABLE_TAKE_OUT_WITH_BAG,
        orderItemActions.CREATE_ORDER_ITEM_SUCCESS,
        orderItemActions.SET_INGREDIENT_SERVINGS,
        orderItemActions.ADD_INGREDIENT_SERVINGS,
        orderItemActions.REMOVE_INGREDIENT,
        orderItemActions.REMOVE_SECTION_INGREDIENTS,
        orderItemActions.RESTORE_SECTION_INGREDIENTS,
        orderItemActions.REPLACE_SECTION_INGREDIENTS,
        orderItemActions.INCREMENT_INGREDIENT_SERVING,
        orderItemActions.DECREMENT_INGREDIENT_SERVING,
        orderItemActions.INCREMENT_ORDER_ITEM_QUANTITY,
        orderItemActions.DECREMENT_ORDER_ITEM_QUANTITY,
        orderItemActions.SAVE_HIGHLIGHTED_INGREDIENT_SERVINGS,
        orderItemActions.RESET_HIGHLIGHTED_INGREDIENT_SERVINGS,
      ]);

      /* Cancel pending pricing requests. */
      if (lastTask) {
        yield cancel(lastTask);
      } else {
        /* Update last state only if the previous task completed to the end. */
        lastState = prevState;
      }

      lastTask = yield fork(previewOrderWithStages, lastState);
    }
  });
}

function* watchOrderPreview() {
  yield takeLatest(orderActions.ORDER_PREVIEW_DISPATCH, previewOrderWithStages);
}

export default function* pricingSaga() {
  yield all([watchOrderChanges(), watchOrderPreview()]);
}
