import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as userActions from '../../../actions/user';

import Login from '../../../components/SignIn/Login';

import { auth2Service } from '../../../services/api';

class LoginContainer extends Component {
  static propTypes = {
    firstName: PropTypes.string,
    email: PropTypes.string,
    login: PropTypes.func.isRequired,
    status: PropTypes.number.isRequired,
    isPasswordModalShown: PropTypes.bool.isRequired,
    forgetPassword: PropTypes.func.isRequired,
    hidePasswordModal: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    firstName: '',
    email: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      error: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { errors } = this.props;

    this.handleErrors(prevProps.errors, errors);
  }

  handlePasswordChange = (event) => {
    const password = event.target.value;

    this.setState({ password });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();

    const { login, email } = this.props;
    const { password } = this.state;

    this.setState({ error: null });
    login({ email, password });
  };

  handleForgetPassword = (event) => {
    event.preventDefault();

    const { email, forgetPassword } = this.props;
    forgetPassword({ email });
  };

  handlePasswordModalHide = () => {
    const { hidePasswordModal } = this.props;

    hidePasswordModal();
  };

  handleErrors = (previousErrors, newErrors) => {
    if (newErrors.length === 0 || previousErrors.length === newErrors.length) return null;
    this.setState({ error: newErrors[0].error.message });

    return null;
  };

  render() {
    const { firstName, email, isPasswordModalShown, status } = this.props;
    const { password, error } = this.state;

    return (
      <Login
        firstName={firstName}
        email={email}
        status={status}
        password={password}
        isPasswordModalShown={isPasswordModalShown}
        error={error}
        onPasswordChange={this.handlePasswordChange}
        onFormSubmit={this.handleFormSubmit}
        onForgetPassword={this.handleForgetPassword}
        onPasswordModalHide={this.handlePasswordModalHide}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  firstName: state.user.firstName,
  email: state.user.registration.email,
  isPasswordModalShown: state.user.isPasswordModalShown,
  status: state.user.status.login,
  errors: state.user.errors,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login: auth2Service.login.requestActionCreator,
      forgetPassword: auth2Service.forgetPassword.requestActionCreator,
      hidePasswordModal: userActions.hidePasswordModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
