import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Typography } from '@mui/material';
import L from 'leaflet';
import PropTypes from 'prop-types';

import coordinatesShape from '../../shapes/coordinatesShape';

import DummyMarker from '../DummyMarker';

import IconLocale from '../../images/icon-locale-large.svg';

import './AddressMap.css';

/* Delete default marker icon. */
// eslint-disable-next-line no-underscore-dangle
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png',
});

const iconLocale = L.icon({
  iconUrl: IconLocale,
  iconSize: [36, 48],
  iconAnchor: [18, 24],
  popupAnchor: [0, 0],
});

class AddressMap extends React.Component {
  static handleMarkerChange(markerRef) {
    if (markerRef) {
      markerRef.openPopup();
    }
  }

  static propTypes = {
    address: PropTypes.string.isRequired,
    coordinates: coordinatesShape.isRequired,
    onCoordinatesChange: PropTypes.func.isRequired,
    zoom: PropTypes.number,
  };

  static defaultProps = {
    zoom: 20,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDummyMarkerShown: false,
    };
  }

  handleDragStart() {
    this.setState({ isDummyMarkerShown: true });
  }

  handleDragEnd(coordinates) {
    const { onCoordinatesChange } = this.props;
    onCoordinatesChange(coordinates);
    this.setState({ isDummyMarkerShown: false });
  }

  render() {
    const { address, coordinates, zoom } = this.props;
    const { isDummyMarkerShown } = this.state;
    const isMoveable = !!address;

    return (
      <div className="AddressMap">
        {isDummyMarkerShown && <DummyMarker />}
        <MapContainer
          dragging={isMoveable}
          zoom={zoom}
          style={{ height: '100%', width: '100%' }}
          center={coordinates}
          onDragStart={this.handleDragStart.bind(this)}
          onDragEnd={this.handleDragEnd.bind(this)}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {isMoveable && !isDummyMarkerShown && (
            <Marker ref={AddressMap.handleMarkerChange} icon={iconLocale} position={coordinates}>
              {address && (
                <Popup className="AddAddressPopup" closeButton={false} offset={[0, 0]}>
                  <Typography variant="subtitle1" fontWeight="600">
                    {address}
                  </Typography>
                </Popup>
              )}
            </Marker>
          )}
        </MapContainer>
      </div>
    );
  }
}

export default AddressMap;
