import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Button from '../../Button';
import Modal from '../../Modal';

import { ReactComponent as CircleCross } from '../../../images/icon-circle-cross.svg';

import './OrderCancelled.css';

function OrderCancelled(props) {
  const { show, onHide, cancellationRemark } = props;
  return (
    <Modal className="modal-small OrderCancelled" show={show} onHide={onHide}>
      <Modal.Body className="kale">
        <CircleCross />
        <Typography variant="calafia2" color="primary">
          Order Cancelled
        </Typography>
        <p>We’re sorry that we had to cancel your order!</p>
        <p>
          Reason:
          {cancellationRemark}.
        </p>
        <Button className="btn-primary" onClick={onHide}>
          Okay
        </Button>
      </Modal.Body>
    </Modal>
  );
}

OrderCancelled.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  cancellationRemark: PropTypes.string,
};

OrderCancelled.defaultProps = {
  cancellationRemark: null,
};

export default OrderCancelled;
