import React from 'react';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import orderTypeEnum from '../../../enums/orderTypeEnum';

import TimePicker from '../../../containers/TimePicker';
import MomentCalendar from '../../MomentCalendar';
import Select, { Option } from '../../Select';
import TimeRangePicker from '../../TimeRangePicker/TimeRangePicker';

import './PickupTime.css';

function StorePickupTime(props) {
  const {
    isDeliveryOrder,
    canPlaceInstantOrder,
    noStoreError,
    processingTime,
    pickupDates,
    selectedType,
    selectedTime,
    onTypeSelected,
    onDateSelected,
  } = props;

  return (
    <div
      className={classnames('PickupTime', { isInstant: selectedType === orderTypeEnum.INSTANT })}
    >
      <ButtonToolbar>
        {canPlaceInstantOrder && (
          <Select
            outerClassName="whiteCaret"
            id="pickup-type"
            value={selectedType}
            onChange={onTypeSelected}
          >
            <option value={orderTypeEnum.INSTANT}>
              As soon as possible ({processingTime.join(' - ')} mins)
            </option>
            <option value={orderTypeEnum.SCHEDULED}>Specific time</option>
          </Select>
        )}
        {selectedType === orderTypeEnum.SCHEDULED && (
          <div className="scheduled">
            <Select
              outerClassName="fullWidth"
              id="pickup-date"
              value={moment(selectedTime).startOf('day').valueOf()}
              onChange={onDateSelected}
            >
              {pickupDates.map((pickupDate) => (
                <Option
                  key={moment(pickupDate).startOf('day').valueOf()}
                  value={moment(pickupDate).startOf('day').valueOf()}
                >
                  <MomentCalendar
                    options={{
                      sameDay: '[Today], DD/MM',
                      nextDay: '[Tomorrow], DD/MM',
                      nextWeek: 'dddd, DD/MM',
                      sameElse: 'dddd, DD/MM',
                    }}
                  >
                    {pickupDate}
                  </MomentCalendar>
                </Option>
              ))}
            </Select>
            {isDeliveryOrder ? <TimeRangePicker /> : <TimePicker />}
          </div>
        )}
        {noStoreError && <p className="ErrorText">{noStoreError}</p>}
      </ButtonToolbar>
    </div>
  );
}

StorePickupTime.propTypes = {
  isDeliveryOrder: PropTypes.bool.isRequired,
  canPlaceInstantOrder: PropTypes.bool.isRequired,
  noStoreError: PropTypes.string,
  processingTime: PropTypes.arrayOf(PropTypes.number).isRequired,
  pickupDates: PropTypes.arrayOf(PropTypes.instanceOf(moment)).isRequired,
  selectedType: PropTypes.oneOf([orderTypeEnum.INSTANT, orderTypeEnum.SCHEDULED]).isRequired,
  selectedTime: PropTypes.instanceOf(moment),
  onTypeSelected: PropTypes.func.isRequired,
  onDateSelected: PropTypes.func.isRequired,
};

StorePickupTime.defaultProps = {
  noStoreError: null,
  selectedTime: null,
};

export default StorePickupTime;
