import React from 'react';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import * as selectors from '../../../sagas/selectors';
import { optionalMinuteMomentFormat } from '../../../utils/commonUtils';

import timeSlotShape from '../../../shapes/timeSlotShape';

import MomentCalendar from '../../MomentCalendar';
import Select, { Option } from '../../Select';

import './PickupTime.css';

function PartnerPickupTime(props) {
  const {
    pickupDates,
    timeSlots,
    selectedDate,
    selectedTimeSlot,
    onDateSelected,
    onTimeSlotSelected,
  } = props;

  const stationTimeInterval = useSelector(selectors.getDefaultStationTimeInterval);

  return (
    <div className="PickupTime">
      <ButtonToolbar>
        <Select
          id="pickup-date"
          value={moment(selectedDate).startOf('day').valueOf()}
          onChange={onDateSelected}
        >
          {pickupDates.map((pickupDate) => (
            <Option
              key={moment(pickupDate).startOf('day').valueOf()}
              value={moment(pickupDate).startOf('day').valueOf()}
            >
              {MomentCalendar({
                options: {
                  sameDay: '[Today], DD/MM',
                  nextDay: '[Tomorrow], DD/MM',
                  nextWeek: 'dddd, DD/MM',
                  sameElse: 'dddd, DD/MM',
                },
                children: pickupDate,
              })}
            </Option>
          ))}
        </Select>
        <Select id="pickup-timeslot" value={selectedTimeSlot.id} onChange={onTimeSlotSelected}>
          {timeSlots.map((timeSlot) => {
            const startTime = optionalMinuteMomentFormat(moment(timeSlot.datetime), {
              suffixFormat: '',
            });
            const endTime = optionalMinuteMomentFormat(
              moment(timeSlot.datetime).add(stationTimeInterval, 'minutes'),
            );
            return (
              <Option key={timeSlot.id} value={timeSlot.id}>
                {startTime}-{endTime}
              </Option>
            );
          })}
        </Select>
      </ButtonToolbar>
    </div>
  );
}

PartnerPickupTime.propTypes = {
  pickupDates: PropTypes.arrayOf(PropTypes.instanceOf(moment)).isRequired,
  timeSlots: PropTypes.arrayOf(timeSlotShape).isRequired,
  selectedDate: PropTypes.instanceOf(moment),
  selectedTimeSlot: timeSlotShape.isRequired,
  onDateSelected: PropTypes.func.isRequired,
  onTimeSlotSelected: PropTypes.func.isRequired,
};

PartnerPickupTime.defaultProps = {
  selectedDate: null,
};

export default PartnerPickupTime;
