import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../actions/order';
import * as orderItemActions from '../../actions/orderItem';
import itemTypeEnum from '../../enums/itemTypeEnum';
import orderItemStatusEnum from '../../enums/orderItemStatusEnum';
import * as commonUtils from '../../utils/commonUtils';
import * as orderItemUtils from '../../utils/orderItemUtils';

import menuItemShape from '../../shapes/menuItemShape';
import orderItemShape from '../../shapes/orderItemShape';
import { routerLocationShape } from '../../shapes/routerShape';

import OrderDataValidator from '../../components/OrderDataValidator';

import ecomService from '../../services/api';

class OrderDataValidatorContainer extends Component {
  static propTypes = {
    orderItem: orderItemShape.isRequired,
    orderItems: PropTypes.objectOf(orderItemShape).isRequired,
    menuGroups: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    apiIdMenuItemNames: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
    unavailableOrderItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.number)).isRequired,
    removeUnavailableItems: PropTypes.func.isRequired,
    resetOrderItem: PropTypes.func.isRequired,
    resetOrder: PropTypes.func.isRequired,
    removeFavoriteMenuItem: PropTypes.func.isRequired,
    removeOrderItems: PropTypes.func.isRequired,
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    gglocationId: PropTypes.string.isRequired,
    gglocationDisabledIngredients: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number))
      .isRequired,

    location: routerLocationShape.isRequired,
  };

  get isMenuItemMissing() {
    const { unavailableOrderItems, orderItem } = this.props;

    return unavailableOrderItems.length > 0 || orderItemUtils.isOrderItemMenuItemMissing(orderItem);
  }

  get disabledIngredients() {
    const { gglocationId, gglocationDisabledIngredients } = this.props;

    if (!gglocationDisabledIngredients) {
      return [];
    }

    return gglocationDisabledIngredients[gglocationId] || [];
  }

  get disabledOrderItems() {
    const { orderItems, apiIdMenuItemNames } = this.props;
    const { disabledIngredients } = this;

    if (!orderItems) return [];

    /* Return order items that have disabled ingredients */
    return Object.values(orderItems)
      .filter((orderItem) =>
        orderItemUtils.isOrderItemIngredientDisabled({
          orderItem,
          disabledIngredients,
        }),
      )
      .map((orderItem) => ({
        ...orderItem,
        orderItemName: apiIdMenuItemNames[orderItem.apiId],
      }));
  }

  get isIngredientMissing() {
    const { orderItem } = this.props;

    return orderItemUtils.isOrderItemIngredientMissing(orderItem);
  }

  get isFavoriteMenuItem() {
    const { orderItem, unavailableOrderItems } = this.props;

    if (
      this.isMenuItemMissing &&
      unavailableOrderItems[0].itemType === itemTypeEnum.FAVORITE_MENU_ITEM
    ) {
      return true;
    }

    return orderItem.orderingFavorite;
  }

  get isMenuItemAvailableInOtherLocations() {
    const { menuGroups, orderItem } = this.props;

    if (!this.isMenuItemMissing) return false;
    const menuItemGglocationMenus = commonUtils.getMenuItemGglocationMenus({
      apiId: orderItem.apiId,
      menuGroups,
    });

    return menuItemGglocationMenus.length > 0;
  }

  get menuItemNames() {
    const { apiIdMenuItemNames, orderItem, menuItems, unavailableOrderItems } = this.props;

    if (this.isMenuItemMissing) {
      return unavailableOrderItems.map((unavailableOrderItem) => {
        if (apiIdMenuItemNames[unavailableOrderItem.apiId]) {
          return apiIdMenuItemNames[unavailableOrderItem.apiId];
        }
        return Object.values(menuItems).find(({ apiId }) => apiId === unavailableOrderItem.apiId)
          ?.name;
      });
    }
    return [apiIdMenuItemNames[orderItem.apiId]];
  }

  get isMenuPageGglocationMissing() {
    const { location, gglocationId } = this.props;
    const { pathname } = location;

    return pathname === '/menu' && !gglocationId;
  }

  handleRemoveOrderItem = () => {
    const { removeOrderItems } = this.props;
    const { disabledOrderItems } = this;

    removeOrderItems(disabledOrderItems.map(({ id }) => id));
  };

  handleResetOrder = () => {
    const { resetOrder } = this.props;

    resetOrder();
  };

  handleResetOrderItem = () => {
    const { resetOrderItem, removeUnavailableItems, unavailableOrderItems } = this.props;

    if (this.isMenuItemMissing) {
      removeUnavailableItems(unavailableOrderItems.map(({ apiId }) => apiId));
    }

    resetOrderItem();
  };

  handleFavoriteRemove = () => {
    const { unavailableOrderItems, removeFavoriteMenuItem } = this.props;

    unavailableOrderItems.forEach((unavailableOrderItem) => {
      if (unavailableOrderItem.itemType === itemTypeEnum.FAVORITE_MENU_ITEM) {
        removeFavoriteMenuItem(unavailableOrderItem.itemId);
      }
    });
    this.handleResetOrder();
  };

  render() {
    const { orderItem } = this.props;

    if (orderItem.orderItemStatus === orderItemStatusEnum.TENTATIVE) {
      return null;
    }

    return (
      <OrderDataValidator
        isMenuItemMissing={this.isMenuItemMissing}
        isIngredientMissing={this.isIngredientMissing}
        disabledOrderItems={this.disabledOrderItems}
        isFavoriteMenuItem={this.isFavoriteMenuItem}
        isMenuItemAvailableInOtherLocations={this.isMenuItemAvailableInOtherLocations}
        isMenuPageGglocationMissing={this.isMenuPageGglocationMissing}
        menuItemNames={this.menuItemNames}
        onRemoveOrderItem={this.handleRemoveOrderItem}
        onResetOrder={this.handleResetOrder}
        onResetOrderItem={this.handleResetOrderItem}
        onFavoriteRemove={this.handleFavoriteRemove}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  unavailableOrderItems: state.order.unavailableOrderItems,
  orderItems: state.order.orderItems,
  orderItem: state.orderItem,
  menuGroups: state.api.menuGroups,
  apiIdMenuItemNames: state.api.apiIdMenuItemNames,
  menuItems: state.api.menuItems,
  gglocationDisabledIngredients: state.api.gglocationDisabledIngredients,
  gglocationId: state.order.gglocationId,
  location: state.router.location,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetOrderItem: orderItemActions.resetOrderItem,
      removeUnavailableItems: orderActions.removeUnavailableItems,
      resetOrder: orderActions.resetOrder,
      removeOrderItems: orderActions.removeOrderItems,
      removeFavoriteMenuItem: ecomService.removeFavoriteMenuItem.requestActionCreator,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrderDataValidatorContainer);
