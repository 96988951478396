import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import * as selectors from '../../sagas/selectors';

import Currency from '../Currency';

function GuestLimitMessage({ className }) {
  const guestLimit = useSelector(selectors.getGuestLimit);
  const { guestOrderQuantityLimit, guestOrderAmountLimit } = guestLimit || {};

  const isAmountLimitEnabled = guestOrderAmountLimit > 0;
  const isQuantityLimitEnabled = guestOrderQuantityLimit > 0;

  const limitMessages = [];

  if (isAmountLimitEnabled) {
    limitMessages.push(
      <b key="amount">
        <Currency typographyVariant="subheading" value={guestOrderAmountLimit} /> order limit
      </b>,
    );
  }

  if (isQuantityLimitEnabled) {
    limitMessages.push(<b key="quantity">{guestOrderQuantityLimit} item quantity limit</b>);
  }

  const limitMessage =
    limitMessages.length > 0 ? (
      <>
        A {limitMessages.reduce((prev, curr) => [prev, ' and ', curr])} applies to guest checkout,
      </>
    ) : (
      ''
    );

  return (
    <p className={className}>
      {limitMessage}
      {limitMessages.length > 0 ? ' for orders above this please register an account.' : ''}
    </p>
  );
}

GuestLimitMessage.propTypes = {
  className: PropTypes.string,
};

GuestLimitMessage.defaultProps = {
  className: '',
};

export default GuestLimitMessage;
