import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as geolib from 'geolib';
import PropTypes from 'prop-types';

import statusEnum from '../../enums/statusEnum';

import geolocationShape from '../../shapes/geolocationShape';

import Distance from '../../components/Distance';

class DistanceContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    showIcon: PropTypes.bool,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    geolocation: geolocationShape.isRequired,
  };

  static defaultProps = {
    children: null,
    showIcon: true,
  };

  get distanceToUser() {
    const { lat, lng, geolocation } = this.props;

    return geolib.getDistance(
      { latitude: lat, longitude: lng },
      { latitude: geolocation?.coordinates?.lat, longitude: geolocation?.coordinates?.lng },
    );
  }

  render() {
    const { children, showIcon, geolocation } = this.props;

    if (geolocation.status !== statusEnum.SUCCESS) {
      return null;
    }

    return (
      <Distance meters={this.distanceToUser} showIcon={showIcon}>
        {children}
      </Distance>
    );
  }
}

const mapStateToProps = (state) => ({
  geolocation: state.geolocation,
});

export default connect(mapStateToProps, undefined)(DistanceContainer);
