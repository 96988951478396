import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../actions/order';
import diningChoiceEnum from '../../enums/diningChoiceEnum';

import gglocationShape from '../../shapes/gglocationShape';

import OrderDelay from '../../components/OrderDelay';

class OrderDelayContainer extends Component {
  static propTypes = {
    gglocation: gglocationShape,
    diningChoice: PropTypes.string.isRequired,
    deliveryAddress: PropTypes.objectOf(PropTypes.object),
    rollbackAwaitingOrder: PropTypes.func.isRequired,
  };

  static defaultProps = {
    gglocation: null,
    deliveryAddress: {},
  };

  get isDeliveryOrder() {
    const { diningChoice } = this.props;

    if (diningChoice !== diningChoiceEnum.DELIVERY) {
      return false;
    }

    return true;
  }

  get destinationName() {
    const { deliveryAddress, gglocation } = this.props;
    const { isDeliveryOrder } = this;

    if (isDeliveryOrder) {
      return deliveryAddress && deliveryAddress.label;
    }

    return gglocation.name;
  }

  handleOnCancel = () => {
    const { rollbackAwaitingOrder } = this.props;

    rollbackAwaitingOrder();
  };

  render() {
    return (
      <OrderDelay
        isDeliveryOrder={this.isDeliveryOrder}
        destinationName={this.destinationName}
        handleOnCancel={this.handleOnCancel}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  gglocation: state.api.gglocations[state.order.gglocationId],
  diningChoice: state.order.diningChoice,
  deliveryAddress: state.order.deliveryAddress,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      rollbackAwaitingOrder: orderActions.rollbackAwaitingOrder,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrderDelayContainer);
