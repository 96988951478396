import PropTypes from 'prop-types';

import orderItemShape from './orderItemShape';

const orderShape = PropTypes.shape({
  gglocation: PropTypes.string,
  timeSlot: PropTypes.string,
  orderItemsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  orderItems: PropTypes.objectOf(orderItemShape).isRequired,
});

export default orderShape;
