/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { Api as BaseApi, Endpoint as BaseEndpoint } from '@spq/redux-api-client';
import { createEcomApi } from '@spq/redux-api-client-ecom';
import { toCamelCaseKeys } from '@spq/utils';
import { isObject } from 'lodash';

import 'isomorphic-fetch';

import { captureBreadcrumb } from '../utils/loggingUtils';
import * as requestTransformation from '../utils/requestTransformUtils';
import * as responseTransformation from '../utils/responseTransformUtils';

import * as apiSettings from './apiSettings';
import * as Sentry from './sentry';

import * as settings from '../settings';

class Endpoint extends BaseEndpoint {
  constructor({ disableSentry = false, ...otherOptions }) {
    super(otherOptions);
    this.disableSentry = disableSentry;
  }
}

class Api extends BaseApi {
  replaceErrorMessage(message) {
    if (!message || typeof message !== 'string') {
      return message;
    }

    if (message.match(/Request was throttled. Expected available in \d+ seconds\./)) {
      return {
        message: 'Please wait a moment before trying again.',
        countDown: parseInt(message.match(/\d+/g)[0], 10),
      };
    }

    return message;
  }

  logRequest(endpoint, url, options) {
    if (endpoint.disableSentry) {
      return;
    }
    captureBreadcrumb({
      message: `${options.method || 'GET'} request: ${url}`,
      category: 'action',
      data: {
        url,
        options,
      },
    });
  }

  logError(endpoint, url, options, response) {
    if (endpoint.disableSentry) {
      return;
    }
    const message = `Failed ${options.method || 'GET'} request: ${url}`;

    captureBreadcrumb({
      message,
      category: 'action',
      data: {
        url,
        options,
        response,
      },
    });

    Sentry.captureMessage(message);
  }

  responseTransformation(response) {
    return toCamelCaseKeys(response);
  }
}

class OAuthApi extends Api {
  constructor(...args) {
    super(...args);
    this.apiName = this.constructor.name;
    this.constructEndpoints(Endpoint);
  }

  // eslint-disable-next-line class-methods-use-this
  checkSuccess({ response, responseData }) {
    if (isObject(responseData) && responseData.success === false) {
      return false;
    }

    return (
      response.ok === true ||
      response.status === 204 ||
      (isObject(responseData) &&
        responseData.success !== false &&
        responseData.success !== undefined)
    );
  }

  checkUserExists = new Endpoint({
    endpoint: 'check-user-exists/',
    method: 'POST',
    transformResponse: responseTransformation.prepareCheckUserExistsResponse,
    reducer: 'user',
  });

  createPhone = new Endpoint({
    endpoint: 'create-phone/',
    method: 'POST',
    reducer: 'user',
    includeToken: true,
  });

  resendPin = new Endpoint({
    endpoint: 'resend-pin/',
    method: 'POST',
    transformRequest: requestTransformation.transformResendPinRequest,
    reducer: 'user',
    includeToken: true,
  });

  verifyPin = new Endpoint({
    endpoint: 'verify-pin/',
    method: 'POST',
    transformRequest: requestTransformation.transformVerifyPinRequest,
    transformResponse: responseTransformation.prepareVerifyPinResponse,
    reducer: 'user',
    includeToken: true,
  });
}

class Auth2Api extends Api {
  constructor(...args) {
    super(...args);
    this.apiName = this.constructor.name;
    this.constructEndpoints(Endpoint);
  }

  profile = new Endpoint({
    endpoint: 'user/',
    transformResponse: responseTransformation.prepareProfileResponse,
    reducer: 'user',
    includeToken: true,
  });

  signUp = new Endpoint({
    endpoint: 'signup/web/email/',
    method: 'POST',
    transformRequest: requestTransformation.transformSignUpRequest,
    transformResponse: responseTransformation.prepareAuthResponse,
    reducer: 'user',
    disableSentry: true,
  });

  facebookLogin = new Endpoint({
    endpoint: 'signup/web/facebook/',
    method: 'POST',
    transformRequest: requestTransformation.transformFacebookRequest,
    transformResponse: responseTransformation.prepareFacebookAuthResponse,
    reducer: 'user',
    disableSentry: true,
  });

  login = new Endpoint({
    endpoint: 'login/web/',
    method: 'POST',
    transformRequest: requestTransformation.transformLoginRequest,
    transformResponse: responseTransformation.prepareAuthResponse,
    reducer: 'user',
    disableSentry: true,
  });

  autoLogin = new Endpoint({
    endpoint: 'login/web/token/',
    method: 'POST',
    transformRequest: requestTransformation.transformAutoLoginRequest,
    transformResponse: responseTransformation.prepareAuthResponse,
    reducer: 'user',
    disableSentry: true,
  });

  forgetPassword = new Endpoint({
    endpoint: 'user/password/reset/',
    method: 'POST',
    transformRequest: requestTransformation.transformForgetPasswordRequest,
    transformResponse: responseTransformation.prepareForgetPasswordResponse,
    reducer: 'user',
  });

  updateProfile = new Endpoint({
    endpoint: 'user/',
    method: 'PATCH',
    transformRequest: requestTransformation.transformUpdateProfileRequest,
    transformResponse: responseTransformation.prepareUpdateProfileResponse,
    reducer: 'user',
    includeToken: true,
  });

  changePassword = new Endpoint({
    endpoint: 'user/password/',
    method: 'PATCH',
    transformRequest: requestTransformation.transformChangePasswordRequest,
    reducer: 'user',
    disableSentry: true,
    includeToken: true,
  });

  changeMarketingOptIn = new Endpoint({
    endpoint: 'user/marketing-opt-in/',
    method: 'PATCH',
    transformRequest: requestTransformation.transformChangeMarketingOptInRequest,
    reducer: 'user',
    disableSentry: true,
    includeToken: true,
  });

  verifyRecaptcha = new Endpoint({
    endpoint: 'recaptcha/verify/',
    method: 'POST',
    transformRequest: requestTransformation.transformRecaptchaRequest,
    version: 'v2',
  });

  updatePartnerEmail = new Endpoint({
    endpoint: 'user/secondary-email/',
    method: 'POST',
    transformRequest: requestTransformation.transformUpdatePartnerEmailRequest,
    transformResponse: responseTransformation.prepareUpdatePartnerEmailResponse,
    reducer: 'user',
    includeToken: true,
  });

  removePartnerEmail = new Endpoint({
    endpoint: 'user/secondary-email/',
    method: 'DELETE',
    transformResponse: responseTransformation.prepareRemovePartnerEmailResponse,
    reducer: 'user',
    includeToken: true,
  });

  reverifyPartnerEmail = new Endpoint({
    endpoint: 'user/secondary-email/reverify/',
    method: 'POST',
    transformRequest: requestTransformation.transformUpdatePartnerEmailRequest,
    transformResponse: responseTransformation.prepareReverifyPartnerEmailResponse,
    reducer: 'user',
    includeToken: true,
  });

  reverifyEmail = new Endpoint({
    endpoint: 'user/email/resend/',
    method: 'POST',
    transformRequest: requestTransformation.transformReverifyEmailRequest,
    reducer: 'user',
  });
}

class KioskNotifyApi extends Api {
  constructor(...args) {
    super(...args);
    this.apiName = this.constructor.name;
    this.constructEndpoints(Endpoint);
  }

  retrieveErrorMessage(error) {
    try {
      if (error.message) {
        return error.message;
      }

      if (error.error) {
        if (error.error.message) {
          return error.error.message;
        }

        return error.error;
      }

      if (error.err) {
        return error.err;
      }

      if (error.msg) {
        if (typeof error.msg === 'string') {
          return error.msg;
        }

        if (Object.keys(error.msg).length === 1) {
          if (Object.keys(error.msg)[0].length === 1) {
            return Object.values(error.msg)[0][0];
          }
          return Object.values(error.msg)[0];
        }
      }

      if (error.detail) {
        return error.detail;
      }

      if (error.non_field_errors) {
        return error.non_field_errors.join('\n');
      }

      return 'Undefined error message';
    } catch (e) {
      return 'Undefined error message';
    }
  }

  pairKiosk = new Endpoint({
    endpoint: 'pair-kiosk',
    method: 'POST',
    includeToken: true,
    transformRequest: requestTransformation.transformPairKioskRequest,
  });
}

class PaymentApi extends Api {
  constructor(...args) {
    super(...args);
    this.apiName = this.constructor.name;
    this.constructEndpoints(Endpoint);
  }

  addPaymentMethod = new Endpoint({
    endpoint: `store_sites/${settings.STORE_SITE_ID}/payment_methods/`,
    method: 'POST',
    transformRequest: requestTransformation.transformAddPaymentMethodRequest,
    transformResponse: responseTransformation.prepareAddPaymentMethodResponse,
    reducer: 'user',
    includeToken: true,
  });

  register2c2pCard = new Endpoint({
    endpoint: `payment_provider/2c2p/register_card/%s/`,
    method: 'POST',
    transformRequest: requestTransformation.transformRegister2c2pCardRequest,
    reducer: 'user',
    includeToken: true,
  });

  paymentMethod = new Endpoint({
    endpoint: `store_sites/${settings.STORE_SITE_ID}/payment_methods/`,
    transformResponse: responseTransformation.preparePaymentMethodResponse,
    disableSentry: true,
    includeToken: true,
  });

  removePaymentMethod = new Endpoint({
    endpoint: `store_sites/${settings.STORE_SITE_ID}/payment_methods/%s/`,
    method: 'DELETE',
    transformRequest: requestTransformation.transformRemovePaymentMethodRequest,
    reducer: 'user',
    includeToken: true,
  });

  setDefaultPaymentMethod = new Endpoint({
    endpoint: `store_sites/${settings.STORE_SITE_ID}/payment_methods/default/`,
    method: 'POST',
    includeToken: true,
    transformResponse: responseTransformation.prepareSetDefaultPaymentMethodResponse,
    transformRequest: requestTransformation.setDefaultPaymentMethodRequest,
  });

  validateApplePay = new Endpoint({
    endpoint: `validation/applepay/`,
    method: 'POST',
    transformRequest: requestTransformation.transformValidateApplePayRequest,
  });
}

class LoyaltyApi extends Api {
  constructor(...args) {
    super(...args);
    this.apiName = this.constructor.name;
    this.constructEndpoints(Endpoint);
  }

  /* To be overwritten by user if needed */
  retrieveErrorMessage(error) {
    try {
      if (error.message) {
        return error.message;
      }

      if (error.error) {
        if (error.error.message) {
          return error.error.message;
        }

        return error.error;
      }

      if (error.err) {
        return error.err;
      }

      if (error.msg) {
        if (Object.keys(error.msg).length === 1) {
          if (Object.keys(error.msg)[0].length === 1) {
            return Object.values(error.msg)[0][0];
          }
          return Object.values(error.msg)[0];
        }
      }

      if (error.detail) {
        return error.detail;
      }

      if (error.non_field_errors) {
        return error.non_field_errors.join('\n');
      }

      return 'Undefined error message';
    } catch (e) {
      return 'Undefined error message';
    }
  }

  scanHistory = new Endpoint({
    endpoint: 'scan-history/',
    reducer: 'loyalty',
    transformResponse: responseTransformation.prepareScanHistoryResponse,
    includeToken: true,
  });

  scanCode = new Endpoint({ endpoint: 'action/create/', method: 'POST', includeToken: true });

  rewards = new Endpoint({
    endpoint: 'rewards/%s/',
    reducer: 'loyalty',
    transformRequest: requestTransformation.transformRewardsRequest,
    transformResponse: responseTransformation.prepareRewardsResponse,
    includeToken: true,
  });

  initData = new Endpoint({
    endpoint: 'init-data/',
    reducer: 'loyalty',
    includeToken: true,
    transformRequest: requestTransformation.transformInitDataRequest,
    transformResponse: responseTransformation.prepareInitDataResponse,
  });

  claimReward = new Endpoint({
    endpoint: 'claim-reward/',
    method: 'POST',
    includeToken: true,
    reducer: 'loyalty',
    transformRequest: requestTransformation.transformClaimRewardRequest,
  });

  fetchMassRewards = new Endpoint({
    endpoint: 'reward/mass/',
    method: 'GET',
    includeToken: true,
    reducer: 'loyalty',
    transformRequest: requestTransformation.transformFetchMassRewards,
    transformResponse: responseTransformation.prepareFetchMassRewardResponse,
  });

  redeemMassReward = new Endpoint({
    endpoint: 'reward/mass/%s/redeem/',
    method: 'POST',
    includeToken: true,
    reducer: 'loyalty',
    transformRequest: requestTransformation.transformRedeemMassRewardRequest,
    transformResponse: responseTransformation.prepareClaimMassRewardResponse,
  });
}

class EsriApi extends Api {
  constructor(...args) {
    super(...args);
    this.constructEndpoints(Endpoint);
  }

  // eslint-disable-next-line class-methods-use-this
  checkSuccess({ responseData }) {
    return !responseData.error;
  }

  reverseGeocode = new Endpoint({
    endpoint:
      'reverseGeocode?f=json&location=%f,%f&featureTypes=PointAddress,StreetAddress,StreetName',
  });

  suggest = new Endpoint({
    endpoint: `suggest?f=json&text=%s&countryCode=${settings.COUNTRY_CODE}&category=Address,Postal`,
  });

  findSuggestionAddressCandidates = new Endpoint({
    endpoint: 'findAddressCandidates?f=json&SingleLine=%s&magicKey=%s&outFields=*&maxLocations=1',
  });
}

const EcomApi = createEcomApi(Api);

const ecomService = new EcomApi({
  baseUrl: settings.ECOM_BASE_URL,
  apiSettings: apiSettings.ecomApiSettings,
  version: settings.ECOM_API_VERSION,
});
window.ecomService = ecomService;
export default ecomService;

export const oauthService = new OAuthApi({
  baseUrl: settings.OAUTH_BASE_URL,
  version: settings.OAUTH_API_VERSION,
});
export const auth2Service = new Auth2Api({
  baseUrl: settings.AUTH2_BASE_URL,
  version: settings.AUTH2_API_VERSION,
});
export const paymentService = new PaymentApi({
  baseUrl: settings.PAYMENT_BASE_URL,
  version: settings.PAYMENT_API_VERSION,
});
export const loyaltyService = new LoyaltyApi({
  baseUrl: settings.LOYALTY_BASE_URL,
  version: settings.LOYALTY_API_VERSION,
});
export const cloudFrontService = new EcomApi({
  baseUrl: settings.CLOUDFRONT_API_BASE_URL,
  version: settings.ECOM_API_VERSION,
});
export const kioskNotifyService = new KioskNotifyApi({ baseUrl: settings.KIOSK_NOTIFY_BASE_URL });
export const esriService = new EsriApi({ baseUrl: settings.ESRI_GEOCODE_URL });

export const apiServices = {
  EcomApi: ecomService,
  OAuthApi: oauthService,
  Auth2Api: auth2Service,
  PaymentApi: paymentService,
  LoyaltyApi: loyaltyService,
  CloudApi: cloudFrontService,
  EsriApi: esriService,
};
