import PropTypes from 'prop-types';

import menuItemIngredientBreakdownShape from './menuItemIngredientBreakdownShape';
import promoFlagShape from './promoFlagShape';

const menuItemShape = PropTypes.shape({
  apiId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  menuCategory: PropTypes.number,
  menuSubcategory: PropTypes.number,
  image: PropTypes.number,
  ingredientServings: PropTypes.objectOf(PropTypes.number),
  ingredientBreakdown: PropTypes.arrayOf(menuItemIngredientBreakdownShape),
  /* Price is not required for menuItems in the landing page */
  price: PropTypes.string.isRequired,
  showInNutritionInfo: PropTypes.bool.isRequired,
  preferenceGroups: PropTypes.arrayOf(PropTypes.any),
  flags: PropTypes.arrayOf(promoFlagShape).isRequired,
  /* Tags are not required for favorite menu items */
  tags: PropTypes.arrayOf(PropTypes.number),
  /* Order is not required for favorite menu items */
  order: PropTypes.number.isRequired,
  disable: PropTypes.bool.isRequired,
});

export default menuItemShape;
