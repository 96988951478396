import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderItemActions from '../../actions/orderItem';
import * as pageActions from '../../actions/page';
import statusEnum from '../../enums/statusEnum';
import * as selectors from '../../sagas/selectors';
import * as foodUtils from '../../utils/foodUtils';
import { isUpsellOngoing } from '../../utils/upsellUtils';

import favoriteMenuItemShape from '../../shapes/favoriteMenuItemShape';
import ingredientCategoryShape from '../../shapes/ingredientCategoryShape';
import ingredientShape from '../../shapes/ingredientShape';
import menuItemShape from '../../shapes/menuItemShape';
import personalSettingsShape from '../../shapes/personalSettingsShape';
import routerShape from '../../shapes/routerShape';
import { baseTypeSettingsShape } from '../../shapes/settingsShape';
import upsellCategoryShape from '../../shapes/upsellCategoryShape';

import Personalize from '../../components/Personalize';
import withRouter from '../WithRouter';

class PersonalizeContainer extends Component {
  static propTypes = {
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    selectedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
    favoriteMenuItems: PropTypes.objectOf(favoriteMenuItemShape).isRequired,
    personalSettings: PropTypes.arrayOf(personalSettingsShape).isRequired,
    orderPreviewStatus: PropTypes.number.isRequired,
    menuGroupId: PropTypes.number.isRequired,
    baseTypeSettings: PropTypes.objectOf(baseTypeSettingsShape).isRequired,
    saveOrderItem: PropTypes.func.isRequired,
    returnToMenu: PropTypes.func.isRequired,
    openUpsellScreen: PropTypes.func.isRequired,
    decrementOrderItemQuantity: PropTypes.func.isRequired,
    incrementOrderItemQuantity: PropTypes.func.isRequired,
    upsellCategories: PropTypes.objectOf(upsellCategoryShape).isRequired,
    /* React Router props */
    router: routerShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResizeWindow.bind(this));
    this.handleResizeWindow();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeWindow.bind(this));
  }

  get menuItem() {
    const { menuItems, router } = this.props;
    const { params } = router;

    return menuItems[params.menuItemId];
  }

  get selectedIngredients() {
    const { selectedIngredientServings, ingredients, ingredientCategories } = this.props;

    const selectedIngredientIds = foodUtils.ingredientIdsArray(selectedIngredientServings);
    const query = { selectedIngredientIds };
    const food = { ingredientCategories };

    return foodUtils.filterIngredients(ingredients, query, food);
  }

  get baseId() {
    return this.menuItem.baseType;
  }

  get baseSettings() {
    const { baseTypeSettings } = this.props;

    return baseTypeSettings[this.baseId];
  }

  get image() {
    if (this.menuItem === null) return null;

    return this.menuItem.image;
  }

  get favoriteMenuItem() {
    const { favoriteMenuItems } = this.props;

    return favoriteMenuItems[this.favoriteMenuItemId];
  }

  get name() {
    return this.favoriteMenuItem ? this.favoriteMenuItem.name : this.menuItem.name;
  }

  get ingredientsText() {
    const { selectedIngredientServings, ingredients } = this.props;

    const compositeIngredient = foodUtils.getCompositeIngredient(
      selectedIngredientServings,
      ingredients,
    );
    if (compositeIngredient) return compositeIngredient.compositeIngredients;

    const ingredientChangesText = foodUtils.defaultIngredientsText(
      selectedIngredientServings,
      ingredients,
    );

    return ingredientChangesText;
  }

  get isOrderPreviewRequested() {
    const { orderPreviewStatus } = this.props;

    return [statusEnum.REQUESTED, statusEnum.RELOADING].includes(orderPreviewStatus);
  }

  handleResizeWindow = () => {
    this.setState({ isMobile: window.innerWidth <= 992 });
  };

  handleQuantityDecrement = () => {
    const { decrementOrderItemQuantity } = this.props;

    decrementOrderItemQuantity();
  };

  handleQuantityIncrement = () => {
    const { incrementOrderItemQuantity } = this.props;

    incrementOrderItemQuantity();
  };

  handlePersonalizeConfirm = () => {
    const {
      menuGroupId,
      upsellCategories,
      menuItems,
      saveOrderItem,
      returnToMenu,
      openUpsellScreen,
    } = this.props;
    saveOrderItem();

    if (isUpsellOngoing(upsellCategories, menuItems, menuGroupId)) {
      return openUpsellScreen();
    }
    return returnToMenu();
  };

  render() {
    const { personalSettings } = this.props;
    const { isMobile } = this.state;

    return (
      <Personalize
        menuItemId={this.menuItem.id}
        baseId={this.baseId}
        image={this.image}
        name={this.name}
        ingredients={this.selectedIngredients}
        ingredientsText={this.ingredientsText}
        personalSettings={personalSettings}
        isMobile={isMobile}
        premade={this.baseSettings?.premade}
        isOrderPreviewRequested={this.isOrderPreviewRequested}
        onQualityDecrement={this.handleQuantityDecrement}
        onQualityIncrement={this.handleQuantityIncrement}
        onPersonalizeConfirm={this.handlePersonalizeConfirm}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  menuItems: state.api.menuItems,
  ingredientBreakdown: state.orderItem.ingredientBreakdown,
  selectedIngredientServings: state.orderItem.selectedIngredientServings,
  ingredients: state.api.ingredients,
  ingredientCategories: state.api.ingredientCategories,
  personalSettings: state.orderItem.personalSettings,
  menuItemFavoriteMenuItemId: state.api.menuItemFavoriteMenuItemId,
  favoriteMenuItems: state.api.favoriteMenuItems,
  orderPreviewStatus: state.order.status.orderPreview,
  baseTypeSettings: state.api.settings.baseTypeSettings,
  upsellCategories: state.api.upsellCategories,
  gglocationMenuGroups: state.api.gglocationMenuGroups,
  menuGroupId: selectors.getCurrentMenuGroupId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      decrementOrderItemQuantity: orderItemActions.decrementOrderItemQuantity,
      incrementOrderItemQuantity: orderItemActions.incrementOrderItemQuantity,
      saveOrderItem: orderItemActions.saveOrderItem,
      returnToMenu: pageActions.returnToMenu,
      openUpsellScreen: pageActions.openUpsellScreen,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalizeContainer));
