export const GO_HOME = 'GO_HOME';
export const GO_BACK = 'GO_BACK';
export const GO_BACK_TO = 'GO_BACK_TO';
export const GO_TO_MENU = 'GO_TO_MENU';
export const LOCATION_INVALID = 'LOCATION_INVALID';

export const goHome = () => ({
  type: GO_HOME,
});

export const goBack = () => ({
  type: GO_BACK,
});

export const goBackTo = (pathname) => ({
  type: GO_BACK_TO,
  pathname,
});

export const goToMenu = ({ option, params }) => ({
  type: GO_TO_MENU,
  option,
  params,
});

export const locationInvalid = () => ({
  type: LOCATION_INVALID,
});
