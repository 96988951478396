import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IngredientChoicePrice from '../../../components/IngredientsPicker/IngredientChoicePrice';

class IngredientChoicePriceContainer extends Component {
  static propTypes = {
    price: PropTypes.number,
    includedServings: PropTypes.number,
    quantity: PropTypes.number,
  };

  static defaultProps = {
    price: null,
    includedServings: 0,
    quantity: 1,
  };

  get isLastIncluded() {
    const { includedServings, quantity } = this.props;

    return includedServings >= quantity;
  }

  render() {
    const { price } = this.props;

    return <IngredientChoicePrice included={this.isLastIncluded} price={price} />;
  }
}

export default IngredientChoicePriceContainer;
