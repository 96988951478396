import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import userOrderItemShape from '../../../shapes/userOrderItemShape';

import OrderItem from '../../../containers/Order/OrderItem';
import Button from '../../Button';
import Modal from '../../Modal';

import './PickFavorite.css';

function PickFavorite(props) {
  const { orderItems, show, onFavorite, onHide } = props;
  return (
    <Modal className="PickFavorite" show={show} onHide={onHide}>
      <Modal.Body className="kale">
        <Typography variant="h4" color="primary">
          Add to favourites
        </Typography>
        <ul className="kale">
          {orderItems.map((orderItem) => (
            <OrderItem
              key={orderItem.id}
              size="Small"
              orderItem={orderItem}
              hideFavorite
              onFavorite={onFavorite}
            />
          ))}
        </ul>
        <Button className="btn-primary" onClick={onHide}>
          Dismiss
        </Button>
      </Modal.Body>
    </Modal>
  );
}

PickFavorite.propTypes = {
  orderItems: userOrderItemShape.isRequired,
  show: PropTypes.bool.isRequired,
  onFavorite: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default PickFavorite;
