import PropTypes from 'prop-types';

import useWindowSize from '../../hooks/useWindowSize';
import { CLIENT_BRAND } from '../../version';

import * as settings from '../../settings';

function EnvQueryComponent({ brand, countryCode, showOnMobile, showOnDesktop, children }) {
  /**
   * a component to take in list of countries, brands and other props
   * to control the display of children component based on the
   * country brand combination of current instance
   */
  const { width } = useWindowSize();
  const isMobile = width < 767;

  /**
   * check if the brands and countries allowed matches with the
   * current countries / brand list
   * return null if condition not match
   */
  if (brand && !brand.includes(CLIENT_BRAND.toLowerCase())) {
    return null;
  }

  if (countryCode && !countryCode.includes(settings.COUNTRY_CODE.toLowerCase())) {
    return null;
  }

  /**
   * control display based on allowed screen size
   * return null if condition not match
   */
  if (
    !((showOnDesktop && showOnMobile) || (isMobile && showOnMobile) || (!isMobile && showOnDesktop))
  ) {
    return null;
  }

  return children;
}

EnvQueryComponent.propTypes = {
  brand: PropTypes.arrayOf(PropTypes.string), // allowed brand list
  countryCode: PropTypes.arrayOf(PropTypes.string), // allowed countrycode list
  showOnMobile: PropTypes.bool,
  showOnDesktop: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

EnvQueryComponent.defaultProps = {
  brand: null,
  countryCode: null,
  showOnMobile: true,
  showOnDesktop: true,
};

export default EnvQueryComponent;
