import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import statusEnum from '../../../enums/statusEnum';

import Edit from '../../../components/Account/Edit';

class EditContainer extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    partnerEmail: PropTypes.string,
    socialLogin: PropTypes.bool,
    status: PropTypes.objectOf(PropTypes.number).isRequired,
    errors: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    partnerEmail: null,
    socialLogin: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      activePanel: false,
      notification: null,
      error: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { status, errors } = this.props;

    this.handleUpdatePartnerEmailStatus(
      prevProps.status.updatePartnerEmail,
      status.updatePartnerEmail,
    );
    this.handleUpdateProfileStatus(prevProps.status.updateProfile, status.updateProfile);
    this.handleChangePasswordStatus(prevProps.status.changePassword, status.changePassword);
    this.handleCreatePhoneStatus(prevProps.status.createPhone, status.createPhone);
    this.handleErrors(prevProps.errors, errors);
  }

  handleUpdatePartnerEmailStatus = (previousStatus, newStatus) => {
    if (previousStatus === newStatus) return null;

    if (newStatus === statusEnum.SUCCESS) {
      const { partnerEmail } = this.props;
      this.setState({
        notification: (
          <span>
            Verify your partner status with email sent to{' '}
            <a
              className="blue-link"
              href={`mailto:${partnerEmail}`}
              target="_blank"
              rel="noreferrer"
            >
              {partnerEmail}
            </a>{' '}
            and start enjoying benefits!
          </span>
        ),
      });
    } else {
      this.setState({ notification: null });
    }

    this.setState({ error: null });

    return null;
  };

  handleUpdateProfileStatus = (previousStatus, newStatus) => {
    if (previousStatus === newStatus) return null;

    if (newStatus === statusEnum.SUCCESS) {
      this.setState({ notification: 'Profile updated!' });
    } else {
      this.setState({ notification: null });
    }

    this.setState({ error: null });

    return null;
  };

  handleChangePasswordStatus = (previousStatus, newStatus) => {
    if (previousStatus === newStatus) return null;

    if (newStatus === statusEnum.SUCCESS) {
      this.setState({ notification: 'Password changed!' });
    } else {
      this.setState({ notification: null });
    }

    this.setState({ error: null });

    return null;
  };

  handleCreatePhoneStatus = (previousStatus, newStatus) => {
    if (previousStatus === newStatus) return null;

    if (newStatus !== statusEnum.SUCCESS) {
      this.setState({ notification: null });
    }

    this.setState({ error: null });

    return null;
  };

  handleErrors = (previousErrors, newErrors) => {
    if (newErrors.length === 0 || previousErrors.length === newErrors.length) return null;

    // Exclude partner email related error as it will be handled in its own scope
    const error = newErrors.find(({ endpointName }) => endpointName !== 'updatePartnerEmail');

    if (error) {
      this.setState({ error: error.message });
    }

    return null;
  };

  handlePanelSelect = (selectedPanel) => {
    const { activePanel } = this.state;

    if (selectedPanel === activePanel) {
      this.handlePanelClose();

      return false;
    }

    this.setState({ activePanel: selectedPanel });

    return true;
  };

  handlePanelClose = () => {
    this.setState({ activePanel: false });
  };

  handleError = (error) => {
    this.setState({ error });
  };

  render() {
    const { email, socialLogin } = this.props;
    const { activePanel, notification, error } = this.state;

    return (
      <Edit
        email={email}
        socialLogin={socialLogin}
        notification={notification}
        error={error}
        activePanel={activePanel}
        onError={this.handleError}
        onPanelSelect={this.handlePanelSelect}
        onPanelClose={this.handlePanelClose}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.user.email,
  partnerEmail: state.user.partnerEmail,
  socialLogin: state.user.socialLogin,
  errors: state.user.errors,
  status: state.user.status,
});

export default connect(mapStateToProps, undefined)(EditContainer);
