import { compareDates } from '@spq/utils';
import { chain, merge, partition } from 'lodash';
import moment from 'moment';

/* API to allow picking time before location */

export default class Scheduler {
  constructor(gglocations, props) {
    Object.assign(this, props);
    this.gglocations = Object.values(gglocations);
  }

  get availableTimeSlots() {
    return chain(this.gglocations)
      .filter((gglocation) => gglocation.timeSlots)
      .flatMap((gglocation) => gglocation.availableTimeSlots)
      .uniq()
      .value();
  }

  get willOpenAgain() {
    return this.gglocations.some((gglocation) => gglocation.willOpenAgain);
  }

  isEnoughTimeToProcess = ({ processingTime }) =>
    this.gglocations.some((gglocation) => gglocation.isEnoughTimeToProcess({ processingTime }));

  // eslint-disable-next-line no-unused-vars
  getCutoffTime = ({ diningChoice }) => this.cuttoffTime || 0;

  // eslint-disable-next-line no-unused-vars
  getProcessingTime = ({ diningChoice }) => this.processingTime || 0;

  getScheduledOrderTimes = ({ diningChoice }) => {
    const scheduledOrderTimesList = chain(this.gglocations)
      .flatMap((gglocation) => gglocation.getScheduledOrderTimes({ diningChoice }))
      .value();

    return merge(...scheduledOrderTimesList);
  };

  getNextAvailableTimeSlotDate = ({ diningChoice }) =>
    chain(this.gglocations)
      .filter((gglocation) => gglocation.timeSlots)
      .map((gglocation) => gglocation.getNextAvailableTimeSlotDate({ diningChoice }))
      .min()
      .value();

  getNextAvailableDate = ({ diningChoice }) =>
    chain(this.gglocations)
      .map((gglocation) => gglocation.getNextAvailableDate({ diningChoice }))
      .min()
      .value();

  getNextAvailableInstantDate = ({ diningChoice }) =>
    chain(this.gglocations)
      .filter((gglocation) => gglocation.openingHours)
      .map((gglocation) => gglocation.getNextAvailableInstantDate({ diningChoice }))
      .min()
      .value();

  getScheduledOrderTimesOnSameDate = ({ orderTime, diningChoice }) =>
    chain(this.gglocations)
      .filter((gglocation) => gglocation.scheduledOrderTimes)
      .flatMap((gglocation) =>
        gglocation.getScheduledOrderTimesOnSameDate({
          orderTime,
          diningChoice,
        }),
      )
      .sortBy(compareDates)
      .sortedUniqBy((x) => x.valueOf())
      .value();

  getScheduledOrderTimesOnSameDateWithInterval = ({
    orderTime,
    diningChoice,
    defaultTimeInterval = 30,
  }) => {
    /**
     * @note The switchover time is use to separate the schedule date
     * exactly based on orderTime's UTC date sharp.
     * This is required as the gglocation's opening hour was depending on
     * UTC format, hence when in backend it closed at 20:00, it will be
     * 4:00 in frontend point of view. Hence, it the same date, it is
     * possible to have two different time ranges, one is before the daily
     * switchover time (0:00 sharp) and one is after.
     */
    const todaySwitchoverTime = moment.utc(orderTime.format('YYYY-MM-DD'));

    // Obtain overall scheduled time list from gglocation
    // and split the list into two, where first list is before the
    // switchover time and second is after
    const overallScheduledTimeList = partition(
      this.getScheduledOrderTimesOnSameDate({ orderTime, diningChoice }).sort(compareDates),
      (scheduleTime) => todaySwitchoverTime.isAfter(scheduleTime),
    );

    return overallScheduledTimeList.map((scheduledTimeList) => {
      // Obtain earliest possible scheduled time and latest closing time among
      // all available gglocation
      const startingTime = moment(scheduledTimeList[0]);
      const latestClosingTime = moment(scheduledTimeList[scheduledTimeList.length - 1]);
      const defaultScheduledTimeIntervalList = [];

      while (startingTime < latestClosingTime) {
        // Add the default time interval to previous time slot
        defaultScheduledTimeIntervalList.push(startingTime.clone());
        startingTime.add(defaultTimeInterval, 'minutes');
      }
      // Append latest closing time if the last closing time is not reached
      if (
        latestClosingTime.isAfter(
          defaultScheduledTimeIntervalList[overallScheduledTimeList.length - 1],
        )
      ) {
        defaultScheduledTimeIntervalList.push(latestClosingTime);
      }
      return defaultScheduledTimeIntervalList;
    });
  };

  getEnabledHours = ({ orderTime, diningChoice }) =>
    chain(this.gglocations)
      .filter((gglocation) => gglocation.scheduledOrderTimes)
      .flatMap((gglocation) => gglocation.getEnabledHours({ orderTime, diningChoice }))
      .sortBy()
      .sortedUniqBy()
      .value();

  getEnabledMinutes = ({ orderTime, diningChoice }) =>
    chain(this.gglocations)
      .filter((gglocation) => gglocation.scheduledOrderTimes)
      .flatMap((gglocation) => gglocation.getEnabledMinutes({ orderTime, diningChoice }))
      .sortBy()
      .sortedUniq()
      .value();

  canPlaceInstantOrder = ({ diningChoice }) => {
    if (!this.gglocationId) {
      return false;
    }

    return this.gglocations.some((gglocation) => gglocation.canPlaceInstantOrder({ diningChoice }));
  };

  canPlaceOrderImmediately = ({ diningChoice }) =>
    this.gglocations.some((gglocation) => gglocation.canPlaceOrderImmediately({ diningChoice }));

  canPlaceOrderEver = ({ diningChoice }) =>
    this.gglocations.some((gglocation) => gglocation.canPlaceOrderEver({ diningChoice }));

  timeSlotsBeforeDay = ({ chosenDay, diningChoice }) =>
    chain(this.gglocations)
      .filter((gglocation) => gglocation.timeSlots)
      .flatMap((gglocation) => gglocation.timeSlotsBeforeDay({ chosenDay, diningChoice }))
      .value();

  timeSlotsAfterDay = ({ chosenDay, diningChoice }) =>
    chain(this.gglocations)
      .filter((gglocation) => gglocation.timeSlots)
      .flatMap((gglocation) => gglocation.timeSlotsAfterDay({ chosenDay, diningChoice }))
      .value();

  timeSlotsOnSameDay = ({ chosenDay, diningChoice }) =>
    chain(this.gglocations)
      .filter((gglocation) => gglocation.timeSlots)
      .flatMap((gglocation) => gglocation.timeSlotsOnSameDay({ chosenDay, diningChoice }))
      .value();

  nextTimeSlots = ({ diningChoice }) =>
    chain(this.gglocations)
      .filter((gglocation) => gglocation.timeSlots)
      .flatMap((gglocation) => gglocation.nextTimeSlots({ diningChoice }))
      .sortBy((timeSlot) => timeSlot, ['datetime'])
      .value();

  possibleScheduledOrderDays = ({ diningChoice }) =>
    chain(this.gglocations)
      .filter((gglocation) => gglocation.getScheduledOrderTimes({ diningChoice }))
      .flatMap((gglocation) => gglocation.possibleScheduledOrderDays({ diningChoice }))
      .sortBy((scheduledOrderDay) => scheduledOrderDay, ['date'])
      .sortedUniqBy((scheduledOrderDay) => scheduledOrderDay.format('YYYY-MM-DD'))
      .value();
}
