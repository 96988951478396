import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Button from '../../Button';
import Modal from '../../Modal';

import './OrderCancel.css';

function OrderCancel(props) {
  const { show, onOrderCancelConfirm, onHide } = props;
  return (
    <Modal className="OrderCancel" show={show} onHide={onHide}>
      <Modal.Body className="kale">
        <Typography variant="h5" color="primary" className="OrderCancelModalTitle">
          Confirm cancellation?
        </Typography>
        <p>Order will be cancelled and payment will be not charged.</p>
        <div className="ButtonBar">
          <Button className="btn-secondary" onClick={onHide}>
            No
          </Button>
          <Button className="btn-primary" onClick={onOrderCancelConfirm}>
            Yes
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

OrderCancel.propTypes = {
  show: PropTypes.bool.isRequired,
  onOrderCancelConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default OrderCancel;
