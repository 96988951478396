import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as foodUtils from '../../../utils/foodUtils';

import ingredientShape from '../../../shapes/ingredientShape';
import tagShape from '../../../shapes/tagShape';

import DietInformation from '../../../components/MenuDetails/DietInformation';

class DietInformationContainer extends Component {
  static propTypes = {
    selectedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    tags: PropTypes.objectOf(tagShape).isRequired,
  };

  get ingredientTagsArray() {
    const { selectedIngredientServings, ingredients, tags } = this.props;

    const selectedIngredientIds = foodUtils.ingredientIdsArray(selectedIngredientServings);
    const query = { selectedIngredientIds };
    const filteredIngredients = foodUtils.filterIngredients(ingredients, query, {});
    const filteredTagsArray = foodUtils.ingredientsTagsArray(filteredIngredients, tags);

    return filteredTagsArray;
  }

  render() {
    return <DietInformation tags={this.ingredientTagsArray} />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectedIngredientServings:
    ownProps.selectedIngredientServings || state.orderItem.selectedIngredientServings,
  ingredients: state.api.ingredients,
  tags: state.api.tags,
});

export default connect(mapStateToProps, undefined)(DietInformationContainer);
