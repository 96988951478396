import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import validator from 'email-validator';

import { setGuestEmail } from '../../../actions/user';
import * as selectors from '../../../sagas/selectors';

import Button from '../../Button';
import Input from '../../Input';
import SectionSeparator from '../../SectionSeparator';

import './GuestInfoInput.css';

function GuestInfoInput() {
  const isGuestLogin = useSelector(selectors.getGuestLogin);
  const guestPhone = useSelector(selectors.getGuestPhone);
  const [guestEmailInput, setGuestEmailInput] = useState('');
  const [guestEmailInputError, setGuestEmailInputError] = useState('');

  const dispatch = useDispatch();

  const onGuestEmailChange = (event) => {
    const email = event.target.value;
    if (validator.validate(email) || email === '') {
      setGuestEmailInput(email);
      setGuestEmailInputError('');
      dispatch(setGuestEmail(email || null));
    } else {
      setGuestEmailInput(email);
      setGuestEmailInputError('Please enter a valid email');
      dispatch(setGuestEmail(null));
    }
  };

  const onGuestEmailClear = () => {
    setGuestEmailInput('');
    setGuestEmailInputError('');
    dispatch(setGuestEmail(null));
  };

  if (!isGuestLogin) return null;

  return (
    <>
      <div className="GuestInfoInputWrapper">
        <SectionSeparator
          typographyVariant="h5"
          typographyColor="secondary.dark"
          className="SharpGroteskSmBold15"
        >
          Contact Number
        </SectionSeparator>
        <div className={classnames('GuestInfoInputMain')}>
          <div className="GuestPhone">
            <Input type="text" value={guestPhone} readOnly disabled />
          </div>
        </div>
      </div>
      <div className="GuestInfoInputWrapper">
        <SectionSeparator
          typographyVariant="h5"
          typographyColor="secondary.dark"
          className="SharpGroteskSmBold15"
        >
          Email
        </SectionSeparator>
        <div className={classnames('GuestInfoInputMain', { hasError: guestEmailInputError })}>
          <div className="GuestEmailInput">
            <Input
              className={classnames({ hasError: guestEmailInputError })}
              type="text"
              placeholder="Enter email"
              value={guestEmailInput}
              onChange={onGuestEmailChange}
            />
            {guestEmailInput && (
              <Button className="ClearButton" fa="circle-xmark" onClick={onGuestEmailClear} />
            )}
          </div>
        </div>
        {guestEmailInputError && <div className="GuestEmailError">{guestEmailInputError}</div>}
        <div className="GuestEmailText">Receipt will be sent to your email</div>
      </div>
    </>
  );
}

export default GuestInfoInput;
