import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import moment from 'moment';

import * as pageActions from '../../../actions/page';
import rewardTypeEnum from '../../../enums/rewardTypeEnum';

import rewardShape from '../../../shapes/rewardShape';

import Image from '../../Image';

import defaultImg from '../../../images/default-collection-image.webp';

import './LoyaltyCard.css';

function MassRewardCard({ massReward }) {
  const dispatch = useDispatch();
  const handleCardClick = () => {
    dispatch(
      pageActions.openRewardModal({
        rewardId: massReward.id,
        rewardType: rewardTypeEnum.MASS,
      }),
    );
  };
  return (
    <div role="presentation" className="LoyaltyCard" onClick={handleCardClick}>
      <div className="LoyaltyCardImageContainer">
        <Image src={massReward.imageUrl || defaultImg} />
      </div>
      <div className="LoyaltyCardContentContainer">
        <div className="TitleContainer">
          <div className="Title">
            <Typography variant="h6">{massReward.title}</Typography>
          </div>
        </div>
        <div className="NameContainer">
          <Typography variant="subtitle2">{massReward.desc}</Typography>
        </div>
        <div className="CardBottom">
          {massReward.validUntil && (
            <Typography className="Validity" variant="body2" color="primary">
              Valid until{' '}
              <span className="bold">{moment(massReward.validUntil).format('D MMM YYYY')}</span>
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}

MassRewardCard.propTypes = {
  massReward: rewardShape.isRequired,
};

export default MassRewardCard;
