import { all, put, select, takeEvery } from 'redux-saga/effects';

import * as includeStateActions from '../actions/includeState';

function* includeState({ action }) {
  const state = yield select();
  yield put({
    ...action,
    state,
  });
}

function* watchIncludeState() {
  yield takeEvery(includeStateActions.INCLUDE_STATE, includeState);
}

export default function* includeStateSaga() {
  yield all([watchIncludeState()]);
}
