import PropTypes from 'prop-types';

const geolocationShape = PropTypes.shape({
  status: PropTypes.number.isRequired,
  coordinates: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
});

export default geolocationShape;
