import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './FontAwesome.css';

function FontAwesome({ className, name, spin, type }) {
  return (
    <span className={classnames(className, `fa-${type}`, `fa-${name}`, { 'fa-spin': spin })} />
  );
}

FontAwesome.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  spin: PropTypes.bool,
  type: PropTypes.oneOf(['regular', 'solid', 'brands']),
};

FontAwesome.defaultProps = {
  className: '',
  spin: false,
  type: 'solid',
};

export default FontAwesome;
