import React, { Component } from 'react';
import { connect } from 'react-redux';
import scrollToComponent from 'react-scroll-to-component';
import moment from 'moment';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as historyActions from '../../actions/history';
import statusEnum from '../../enums/statusEnum';
import * as foodUtils from '../../utils/foodUtils';
import { getSearchParam, setSearchParams } from '../../utils/historyUtils';
import { getSectionSettings } from '../../utils/settingsUtils';

import baseShape from '../../shapes/baseShape';
import cyoSectionShape from '../../shapes/cyoSectionShape';
import ingredientCategoryShape from '../../shapes/ingredientCategoryShape';
import ingredientShape from '../../shapes/ingredientShape';
import menuItemShape from '../../shapes/menuItemShape';
import orderShape from '../../shapes/orderShape';
import routerShape from '../../shapes/routerShape';
import { baseTypeSettingsShape, sectionSettingsShape } from '../../shapes/settingsShape';

import Cyo from '../../components/Cyo';
import withRouter from '../WithRouter';

import { BASE_SECTION_ID } from '../../settings';

class CyoContainer extends Component {
  static propTypes = {
    orderItemId: PropTypes.string.isRequired,
    highlightedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    gglocationId: PropTypes.string.isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
    cyoSections: PropTypes.objectOf(cyoSectionShape).isRequired,
    bases: PropTypes.objectOf(baseShape).isRequired,
    baseTypeSettings: PropTypes.objectOf(baseTypeSettingsShape).isRequired,
    sectionSettings: PropTypes.objectOf(sectionSettingsShape).isRequired,
    order: orderShape.isRequired,
    orderPreviewStatus: PropTypes.number.isRequired,
    action: PropTypes.string.isRequired,

    goBack: PropTypes.func.isRequired,
    goToMenu: PropTypes.func.isRequired,

    router: routerShape.isRequired,
  };

  constructor(props) {
    const { order, goToMenu, router } = props;
    const { params } = router;

    super(props);

    this.state = {
      showBaseAlert: false,
      detailsModalOpen: false,
      limitNotification: null,
      requiredNotification: null,
    };

    if (order.gglocationId === null) {
      router.push('/locations');
    }
    if (params.menuItemId === undefined || this.sectionId === undefined) {
      goToMenu({});
    }
  }

  componentDidMount() {
    this.skipDisabledSection();
  }

  componentDidUpdate(prevProps) {
    this.skipDisabledSection();

    const { router } = this.props;

    if (prevProps.router.location.key !== router.location.key) {
      this.setState({
        detailsModalOpen: false,
        limitNotification: null,
        requiredNotification: null,
      });
    }
  }

  get sectionSettings() {
    const { sectionSettings, baseTypeSettings } = this.props;

    return getSectionSettings({
      baseId: this.baseId,
      sectionId: this.sectionId,
      sectionSettings,
      baseTypeSettings,
    });
  }

  get orderItemId() {
    const { router } = this.props;
    const { location } = router;

    return getSearchParam(location.search, 'orderItemId');
  }

  get menuItem() {
    const { menuItems, router } = this.props;
    const { params } = router;

    return menuItems[params.menuItemId];
  }

  get baseId() {
    return this.menuItem.baseType;
  }

  get base() {
    const { bases } = this.props;

    return bases[this.baseId];
  }

  get cyoSectionIds() {
    const { cyoSections } = this.props;

    return Object.values(cyoSections)
      .filter((section) => {
        const sectionSettings = this.determineSectionSettings(section.id);

        return sectionSettings.hidden === false && sectionSettings?.addCategories?.cyo.length > 0;
      })
      .sort((a, b) => a.order - b.order)
      .map((cyoSection) => cyoSection.id);
  }

  get sectionId() {
    const { router } = this.props;
    const sectionId = parseInt(router.params.sectionId, 10) || this.cyoSectionIds[0];

    return sectionId;
  }

  get section() {
    const { cyoSections } = this.props;
    const section = cyoSections[this.sectionId];

    return section;
  }

  get currentSectionIndex() {
    const { cyoSectionIds, sectionId } = this;

    return cyoSectionIds.indexOf(sectionId);
  }

  get nextSectionId() {
    const { currentSectionIndex, cyoSectionIds } = this;

    return cyoSectionIds[currentSectionIndex + 1];
  }

  get nextSection() {
    const { cyoSections } = this.props;
    const section = cyoSections[this.nextSectionId];

    return section;
  }

  get previousSectionId() {
    const { currentSectionIndex, cyoSectionIds } = this;

    if (currentSectionIndex === 0) return null;
    return cyoSectionIds[currentSectionIndex - 1];
  }

  get previousSection() {
    if (this.previousSectionId === null) return null;

    const { cyoSections } = this.props;
    const section = cyoSections[this.previousSectionId];

    return section;
  }

  get isBasePage() {
    return this.sectionId === BASE_SECTION_ID;
  }

  get isNoAddCategories() {
    return (
      this.sectionSettings?.addCategories && this.sectionSettings?.addCategories?.cyo?.length === 0
    );
  }

  get sectionLimit() {
    return this.sectionSettings.limit.cyo.section && this.sectionSettings.limit.cyo.section.number;
  }

  get skipBehavior() {
    const { action } = this.props;

    return { POP: 'BACKWARD', PUSH: 'FORWARD', REPLACE: 'FORWARD' }[action];
  }

  get isOrderPreviewRequested() {
    const { orderPreviewStatus } = this.props;

    return [statusEnum.REQUESTED, statusEnum.RELOADING].includes(orderPreviewStatus);
  }

  determineSectionSettings = (sectionId) => {
    const { baseTypeSettings, sectionSettings } = this.props;

    return getSectionSettings({
      baseId: this.baseId,
      sectionId,
      baseTypeSettings,
      sectionSettings,
    });
  };

  skipDisabledSection = () => {
    const { orderItemId, goBack } = this.props;

    if (!orderItemId || this.isNoAddCategories || this.sectionLimit === 0) {
      if (this.skipBehavior === 'FORWARD') {
        this.handleIngredientAddConfirm({ isDisabledSectionSkipped: true });
      } else {
        goBack();
      }
    }
  };

  hasRequiredIngredients = () => {
    const {
      ingredients,
      ingredientCategories,
      highlightedIngredientServings,
      baseTypeSettings,
      sectionSettings,
    } = this.props;

    const result = foodUtils.hasRequiredIngredients(this.baseId, this.sectionId, {
      ingredients,
      ingredientCategories,
      selectedIngredientServings: highlightedIngredientServings,
      baseTypeSettings,
      sectionSettings,
    });

    if (result === false) {
      this.setState({
        requiredNotification: {
          id: moment().valueOf(),
          name: this.section.name,
          clearLimitNotification: this.clearLimitNotification,
        },
      });
    }

    return result;
  };

  clearLimitNotification = () => {
    this.setState({
      limitNotification: null,
    });
  };

  handleLimitExhausted = ({ name, limit }) => {
    this.setState({
      limitNotification: {
        id: moment().valueOf(),
        name,
        limit,
        clearLimitNotification: this.clearLimitNotification,
      },
    });
  };

  handleIngredientAddConfirm = ({ isDisabledSectionSkipped }) => {
    const { router } = this.props;
    const { location } = router;

    if (this.hasRequiredIngredients() === false) {
      return;
    }

    if (this.nextSection) {
      if (isDisabledSectionSkipped) {
        /* Hide and replace disabled section in history */
        router.replace({
          pathname: `/cyo/${this.menuItem.id}/${this.nextSection.id}`,
          search: setSearchParams(location.search, { orderItemId: this.orderItemId }),
        });
      } else {
        router.push({
          pathname: `/cyo/${this.menuItem.id}/${this.nextSection.id}`,
          search: setSearchParams(location.search, { orderItemId: this.orderItemId }),
        });
      }
    } else {
      this.handleCyoComplete(isDisabledSectionSkipped);
    }
  };

  handleCyoComplete = (isDisabledSectionSkipped = false) => {
    const { router } = this.props;
    const { location } = router;

    if (isDisabledSectionSkipped) {
      /* Hide and replace disabled section in history */
      router.replace({
        pathname: `/personalize/${this.menuItem.id}`,
        search: setSearchParams(location.search, { orderItemId: this.orderItemId }),
      });
    } else {
      router.push({
        pathname: `/personalize/${this.menuItem.id}`,
        search: setSearchParams(location.search, { orderItemId: this.orderItemId }),
      });
    }
  };

  handleDetailsButtonClick = () => {
    this.setState({ detailsModalOpen: true });
  };

  handleDetailsModalHide = () => {
    this.setState({ detailsModalOpen: false });
  };

  handleBackToPreviousSection = () => {
    if (this.previousSection) {
      const { goBack } = this.props;

      goBack();
    }
  };

  render() {
    const { isOrderPreviewRequested } = this;
    const { orderItemId } = this.props;
    const { showBaseAlert, limitNotification, requiredNotification, detailsModalOpen } = this.state;

    const { section } = this;
    const { sectionSettings } = this;

    if (!orderItemId) {
      return null;
    }

    return (
      <Cyo
        orderItemId={this.orderItemId}
        basePage={this.isBasePage}
        base={this.base}
        menuItemId={this.menuItem.id}
        menuItemName={this.menuItem.name}
        cyoSectionIds={this.cyoSectionIds}
        showBaseAlert={showBaseAlert}
        section={section}
        hasPagination={sectionSettings.cyoPagination}
        limitNotification={limitNotification}
        requiredNotification={requiredNotification}
        showDietButton={sectionSettings.showDietButton}
        detailsModalOpen={detailsModalOpen}
        isOrderPreviewRequested={isOrderPreviewRequested}
        previousSection={this.previousSection}
        onIngredientAddConfirm={this.handleIngredientAddConfirm}
        onLimitExhausted={this.handleLimitExhausted}
        onBaseTypeAlertInit={(ref) => scrollToComponent(ref)}
        onDetailsButtonClick={this.handleDetailsButtonClick}
        onDetailsModalHide={this.handleDetailsModalHide}
        onBackToPreviousSection={this.handleBackToPreviousSection}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  orderItemId: state.orderItem.id,
  order: state.order,
  menuItems: state.api.menuItems,
  ingredients: state.api.ingredients,
  ingredientCategories: state.api.ingredientCategories,
  cyoSections: state.api.cyoSections,
  bases: state.api.bases,
  highlightedIngredientServings: state.orderItem.highlightedIngredientServings,
  gglocationId: state.order.gglocationId,
  baseTypeSettings: state.api.settings.baseTypeSettings,
  sectionSettings: state.api.settings.sectionSettings,
  orderPreviewStatus: state.order.status.orderPreview,
  history: state.history,
  action: state.router.action,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goBack: historyActions.goBack,
      goToMenu: historyActions.goToMenu,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CyoContainer));
