import { apiCallSuccessChannel } from '@spq/redux-api-client';
import { all, put, select } from 'redux-saga/effects';

import * as orderActions from '../actions/order';
import diningChoiceEnum from '../enums/diningChoiceEnum';
import { takeEvery } from '../utils/sagaUtils';

import * as selectors from './selectors';

import ecomService from '../services/api';

function* handleAddCustomerAddressSuccess({ response }) {
  const diningChoice = yield select(selectors.getDiningChoice);
  if (diningChoice === diningChoiceEnum.DELIVERY) {
    yield put(orderActions.selectAddress({ customerAddressId: response.id }));
  }
}

function* watchApiCall() {
  yield takeEvery(
    apiCallSuccessChannel(`${ecomService.addCustomerAddress}`),
    handleAddCustomerAddressSuccess,
  );
}

export default function* addressSaga() {
  yield all([watchApiCall()]);
}
