import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import './MenuItemMissingNotice.css';

function MenuItemMissingNotice(props) {
  const { menuItemNames, isMenuItemAvailableInOtherLocations } = props;

  return (
    <div className="MenuItemMissingNotice">
      <div className="content-top">
        <Typography variant="h5" color="primary">
          Ingredient no longer available
        </Typography>
      </div>
      <p>
        We are sorry, but&nbsp;
        {menuItemNames.join(', ')}
        &nbsp;
        {menuItemNames.length > 1 ? 'are' : 'is'} no longer available.&nbsp;
        {isMenuItemAvailableInOtherLocations ? (
          <span>
            Please choose a different item or try to order from a different location where it might
            still be available.
          </span>
        ) : (
          <span>Please choose a different item.</span>
        )}
      </p>
    </div>
  );
}

MenuItemMissingNotice.propTypes = {
  menuItemNames: PropTypes.arrayOf(PropTypes.string),
  isMenuItemAvailableInOtherLocations: PropTypes.bool.isRequired,
};

MenuItemMissingNotice.defaultProps = {
  menuItemNames: [],
};

export default MenuItemMissingNotice;
