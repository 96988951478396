/* A drop in replacement for @sentry/browser extending breadcrumbs limit beyond 29 most recent. */

import * as Sentry from '@sentry/browser';

class BreadcrumbsHistory {
  static SENTRY_BREADCRUMBS_LIMIT = 29;

  breadcrumbsHistory = [];

  get unreportedBreadcrumbs() {
    return this.breadcrumbsHistory.slice(0, -BreadcrumbsHistory.SENTRY_BREADCRUMBS_LIMIT);
  }

  addBreadcrumb(breadcrumbData) {
    this.breadcrumbsHistory.push(breadcrumbData);
  }
}

const breadcrumbsHistory = new BreadcrumbsHistory();

export const addBreadcrumb = (breadcrumbData) => {
  breadcrumbsHistory.addBreadcrumb(breadcrumbData);
  Sentry.addBreadcrumb(breadcrumbData);
};

export const captureException = (error) => {
  Sentry.withScope((scope) => {
    scope.setExtra('unreportedBreadcrumbs', breadcrumbsHistory.unreportedBreadcrumbs);
    Sentry.captureException(error);
  });
};

export const captureMessage = (errorMessage) => {
  Sentry.withScope((scope) => {
    scope.setExtra('unreportedBreadcrumbs', breadcrumbsHistory.unreportedBreadcrumbs);
    Sentry.captureMessage(errorMessage);
  });
};

export {
  addGlobalEventProcessor,
  addIntegration,
  addTracingExtensions,
  Breadcrumbs,
  BrowserClient,
  BrowserProfilingIntegration,
  BrowserTracing,
  captureEvent,
  captureUserFeedback,
  chromeStackLineParser,
  close,
  configureScope,
  continueTrace,
  createTransport,
  createUserFeedbackEnvelope,
  Dedupe,
  defaultIntegrations,
  defaultRequestInstrumentationOptions,
  defaultStackLineParsers,
  defaultStackParser,
  eventFromException,
  eventFromMessage,
  exceptionFromError,
  extractTraceparentData,
  flush,
  forceLoad,
  FunctionToString,
  geckoStackLineParser,
  getActiveSpan,
  getActiveTransaction,
  getClient,
  getCurrentHub,
  getHubFromCarrier,
  GlobalHandlers,
  HttpContext,
  Hub,
  InboundFilters,
  init,
  instrumentOutgoingRequests,
  Integrations,
  lastEventId,
  LinkedErrors,
  makeBrowserOfflineTransport,
  makeFetchTransport,
  makeMain,
  makeMultiplexedTransport,
  makeXHRTransport,
  ModuleMetadata,
  onLoad,
  onProfilingStartRouteTransaction,
  opera10StackLineParser,
  opera11StackLineParser,
  Replay,
  Scope,
  SDK_VERSION,
  setContext,
  setExtra,
  setExtras,
  setMeasurement,
  setTag,
  setTags,
  setUser,
  showReportDialog,
  spanStatusfromHttpCode,
  startInactiveSpan,
  startSpan,
  startSpanManual,
  startTransaction,
  trace,
  TryCatch,
  WINDOW,
  winjsStackLineParser,
  withScope,
  wrap,
} from '@sentry/browser';
