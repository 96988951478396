import PropTypes from 'prop-types';

const routerActionShape = PropTypes.oneOf(['POP', 'PUSH', 'REPLACE']);

const routerLocationShape = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string,
  state: PropTypes.objectOf(PropTypes.any),
});

const routerShape = PropTypes.shape({
  /* mock WithRouter props */
  location: routerLocationShape.isRequired,
  params: PropTypes.objectOf(PropTypes.any),
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  action: routerActionShape,
});

export default routerShape;
export { routerActionShape, routerLocationShape };
