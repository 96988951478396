import React from 'react';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';

import preferenceGroupShape from '../../../shapes/preferenceGroupShape';

import Select from '../../Select';

function PersonalizePreferenceGroupDropdown(props) {
  const { preferenceGroup, selectedPreferenceId, onPreferenceChange } = props;
  return (
    <ButtonToolbar>
      <Select value={selectedPreferenceId} onChange={onPreferenceChange}>
        {preferenceGroup.preferences.map((preference) => (
          <option key={preference.id} value={preference.id}>
            {upperFirst(preference.name.toLowerCase())}
          </option>
        ))}
      </Select>
    </ButtonToolbar>
  );
}

PersonalizePreferenceGroupDropdown.propTypes = {
  preferenceGroup: preferenceGroupShape.isRequired,
  selectedPreferenceId: PropTypes.number.isRequired,
  onPreferenceChange: PropTypes.func.isRequired,
};

export default PersonalizePreferenceGroupDropdown;
