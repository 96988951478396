import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Checkbox from '../../Checkbox';

import './DietModalButton.css';

function DietModalButton(props) {
  const { id, name, selected, onClick } = props;
  return (
    <div key={id} className={classnames('DietModalButton', { selected })}>
      <Checkbox
        isChecked={selected}
        label={
          <Typography
            variant="subtitle2"
            sx={{
              flex: 1,
              textTransform: 'lowercase',
              '&:first-letter': { textTransform: 'capitalize' },
            }}
          >
            {name}
          </Typography>
        }
        onChange={onClick}
      />
    </div>
  );
}

DietModalButton.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

DietModalButton.defaultProps = {
  selected: false,
  onClick: null,
};

export default DietModalButton;
