import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PaymentSpinner from '../../components/PaymentSpinner';

function PaymentSpinnerContainer(props) {
  const { isPaymentSpinnerOpen } = props;

  return <PaymentSpinner show={isPaymentSpinnerOpen} />;
}

PaymentSpinnerContainer.propTypes = {
  isPaymentSpinnerOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isPaymentSpinnerOpen: state.page.isPaymentSpinnerOpen,
});

export default connect(mapStateToProps, undefined)(PaymentSpinnerContainer);
