import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import accountStatusEnum from '../../../enums/accountStatusEnum';

import Badge from '../../Badge/Badge';

import './EditPartnerEmail.css';

const emailStatusTextMapping = {
  [accountStatusEnum.ACTIVE]: 'Verified',
  [accountStatusEnum.PENDING]: 'Pending',
  [accountStatusEnum.EXPIRED]: 'Expired',
  [accountStatusEnum.DISABLED]: 'Disabled',
};
const emailStatusColorMapping = {
  [accountStatusEnum.ACTIVE]: 'success',
  [accountStatusEnum.PENDING]: 'disabled',
  [accountStatusEnum.EXPIRED]: 'error',
  [accountStatusEnum.DISABLED]: 'error',
};

function EditPartnerEmail({
  partnerEmail,
  partnerEmailStatus,
  isChanged,
  isDefault,
  isError,
  onPartnerEmailChange,
}) {
  const isEmailStatusError = [accountStatusEnum.DISABLED, accountStatusEnum.EXPIRED].includes(
    partnerEmailStatus,
  );

  return (
    <div
      className={classnames('PartnerEmailLabel', {
        isChanged,
        isError: isError || (isEmailStatusError && !isDefault && !isChanged),
      })}
    >
      <input
        className="PartnerEmailInput"
        type="email"
        placeholder="Enter partner email to enjoy benefits"
        value={partnerEmail}
        onChange={onPartnerEmailChange}
      />
      {partnerEmail && partnerEmailStatus && !isChanged && (
        <Badge color={emailStatusColorMapping[partnerEmailStatus]} outline>
          {emailStatusTextMapping[partnerEmailStatus]}
        </Badge>
      )}
    </div>
  );
}

EditPartnerEmail.propTypes = {
  partnerEmail: PropTypes.string,
  partnerEmailStatus: PropTypes.string,
  isChanged: PropTypes.bool,
  isDefault: PropTypes.bool,
  isError: PropTypes.bool,
  onPartnerEmailChange: PropTypes.func.isRequired,
};

EditPartnerEmail.defaultProps = {
  partnerEmail: null,
  partnerEmailStatus: null,
  isChanged: false,
  isDefault: false,
  isError: false,
};

export default EditPartnerEmail;
