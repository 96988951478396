import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import customerAddressShape from '../../../shapes/customerAddressShape';

import AddItemButton from '../../AddItemButton';
import Button from '../../Button';
import SectionSeparator from '../../SectionSeparator';
import Address from '../Address';

import './Addresses.css';

function Addresses({
  isSelectable,
  maxAddressShown,
  customerAddresses,
  selectedAddressId,
  onAddAddressClick,
  onShowAllAddress,
  onSelectAddress,
  onRemoveAddress,
}) {
  return (
    <div className="Addresses">
      {customerAddresses.length > 0 && (
        <ul className="title-function">
          <li>
            {isSelectable ? (
              <SectionSeparator
                className="header"
                typographyVariant="body1"
                typographyColor="secondary.dark"
              >
                PREVIOUS LOCATIONS
              </SectionSeparator>
            ) : (
              <Typography variant="h3" color="secondary.dark" className="AccountTitle">
                Addresses
              </Typography>
            )}
          </li>
        </ul>
      )}
      <ul className={classnames('AddressesList', { selectable: isSelectable })}>
        {customerAddresses.map(
          (customerAddress, index) =>
            customerAddress &&
            (index < maxAddressShown || !isSelectable) && (
              <li className="AddressItem" key={customerAddress.id}>
                <Address
                  customerAddress={customerAddress}
                  isSelectable={isSelectable}
                  isSelected={customerAddress.id === selectedAddressId}
                  onSelectAddress={onSelectAddress}
                  onRemoveAddress={onRemoveAddress}
                />
              </li>
            ),
        )}
      </ul>
      {isSelectable && customerAddresses.length > maxAddressShown && (
        <Button className="blue-link" onClick={onShowAllAddress}>
          Load more
        </Button>
      )}
      {!isSelectable && <AddItemButton text="Add address" onClick={onAddAddressClick} />}
    </div>
  );
}

Addresses.propTypes = {
  isSelectable: PropTypes.bool.isRequired,
  maxAddressShown: PropTypes.number.isRequired,
  customerAddresses: PropTypes.arrayOf(customerAddressShape).isRequired,
  selectedAddressId: PropTypes.number.isRequired,
  onAddAddressClick: PropTypes.func.isRequired,
  onShowAllAddress: PropTypes.func.isRequired,
  onSelectAddress: PropTypes.func.isRequired,
  onRemoveAddress: PropTypes.func.isRequired,
};

export default Addresses;
