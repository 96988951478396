import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import LinearIcon from '../LinearIcon';

import './InputAlertText.css';

function InputAlertText({ className, children, noIcon, typographyVariant }) {
  return (
    <div className={classnames(className, 'InputAlertText')}>
      <Typography variant={typographyVariant} color="error">
        {!noIcon && <LinearIcon name="warning" />} {children}
      </Typography>
    </div>
  );
}

InputAlertText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  noIcon: PropTypes.bool,
  typographyVariant: PropTypes.string,
};

InputAlertText.defaultProps = {
  className: '',
  children: null,
  noIcon: false,
  typographyVariant: 'subtitle2',
};

export default InputAlertText;
