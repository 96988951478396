import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as preferenceGroupDisplaySectionEnum from '../../enums/preferenceGroupDisplaySectionEnum';
import * as commonUtils from '../../utils/commonUtils';
import * as orderItemUtils from '../../utils/orderItemUtils';

import ingredientCategoryShape from '../../shapes/ingredientCategoryShape';
import ingredientShape from '../../shapes/ingredientShape';
import menuItemShape from '../../shapes/menuItemShape';
import orderItemShape from '../../shapes/orderItemShape';
import preferenceGroupShape from '../../shapes/preferenceGroupShape';

import PersonalizePreferenceGroups from '../../components/PersonalizePreferenceGroups';

class PersonalizePreferenceGroupsContainer extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isLabelShown: PropTypes.bool,
    webDisplayRow: PropTypes.oneOf(Object.values(preferenceGroupDisplaySectionEnum)),
    orderItem: orderItemShape.isRequired,
    preferenceGroups: PropTypes.objectOf(preferenceGroupShape).isRequired,
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
  };

  static defaultProps = {
    isLabelShown: false,
    webDisplayRow: null,
  };

  get preferenceGroupIds() {
    const {
      webDisplayRow,
      preferenceGroups,
      orderItem,
      menuItems,
      ingredients,
      ingredientCategories,
    } = this.props;

    return Object.values(preferenceGroups)
      .filter((preferenceGroup) => preferenceGroup.webDisplayRow === webDisplayRow)
      .filter((preferenceGroup) =>
        orderItemUtils.doesOrderItemMatchSomeQuery(
          {
            orderItem,
            queries: preferenceGroup.displayQualifiers.map(commonUtils.ecomQualifierToQuery),
          },
          { menuItems, ingredients, ingredientCategories },
        ),
      )
      .sort((preferenceGroupA, preferenceGroupB) => {
        if (preferenceGroupA.ordering < preferenceGroupB.ordering) {
          return -1;
        }

        if (preferenceGroupA.ordering > preferenceGroupB.ordering) {
          return 1;
        }

        return 0;
      })
      .map((preferenceGroup) => preferenceGroup.id);
  }

  render() {
    const { id, isLabelShown } = this.props;

    return (
      <PersonalizePreferenceGroups
        id={id}
        isLabelShown={isLabelShown}
        preferenceGroupIds={this.preferenceGroupIds}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  orderItem: state.orderItem,
  preferenceGroups: state.api.preferenceGroups,
  menuItems: state.api.menuItems,
  ingredients: state.api.ingredients,
  ingredientCategories: state.api.ingredientCategories,
});

export default connect(mapStateToProps)(PersonalizePreferenceGroupsContainer);
