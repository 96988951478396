import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FacebookSpinner from '../../components/FacebookSpinner';

function FacebookSpinnerContainer(props) {
  const { isFacebookLoginOpen } = props;

  return <FacebookSpinner show={isFacebookLoginOpen} />;
}

FacebookSpinnerContainer.propTypes = {
  isFacebookLoginOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isFacebookLoginOpen: state.page.isFacebookLoginOpen,
});

export default connect(mapStateToProps, undefined)(FacebookSpinnerContainer);
