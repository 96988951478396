import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import FontAwesome from '../FontAwesome';
import LinearIcon from '../LinearIcon';

import { ReactComponent as ArrowLeft } from '../../images/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../images/arrow-right.svg';
import { ReactComponent as ArrowUp } from '../../images/arrow-up.svg';
import { ReactComponent as ArrowCross } from '../../images/icon-close.svg';

import './Button.css';

function Button(props) {
  const {
    className,
    loading,
    lnr,
    icon,
    fa,
    faType,
    arrow,
    href,
    link,
    type,
    children,
    onClick,
    ...otherProps
  } = props;
  const disabled = otherProps.disabled || loading;

  let iconElement = '';
  let arrowElement = '';

  if (loading) {
    iconElement = <FontAwesome className="ButtonIcon" name="circle-o-notch" spin />;
  } else if (lnr || icon) {
    iconElement = <LinearIcon className="ButtonIcon" name={lnr || icon} />;
  } else if (fa) {
    iconElement = <FontAwesome className="ButtonIcon" name={fa} type={faType} />;
  } else if (arrow === 'left') {
    iconElement = <ArrowLeft />;
  } else if (arrow === 'cross') {
    iconElement = <ArrowCross />;
  } else if (arrow === 'up') {
    arrowElement = <ArrowUp />;
  } else if (arrow === 'right') {
    arrowElement = <ArrowRight />;
  }

  const childrenProps = {
    className: classnames('Button', className, { disabled, loading }, arrow),
    disabled,
    children: (
      <span>
        {iconElement} {children} {arrowElement}
      </span>
    ),
  };

  if (link) {
    return <Link {...childrenProps} {...otherProps} to={link} />;
  }

  if (href) {
    return (
      <a {...childrenProps} {...otherProps} href={href}>
        {childrenProps.children}
      </a>
    );
  }

  // eslint-disable-next-line react/button-has-type
  return <button {...childrenProps} {...otherProps} type={type} onClick={onClick} />;
}

Button.propTypes = {
  /* Using icon is depracated, use lnr instead. */
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.string,
  lnr: PropTypes.string,
  fa: PropTypes.string,
  faType: PropTypes.oneOf(['regular', 'solid', 'brands']),
  arrow: PropTypes.oneOf(['left', 'cross', 'up', 'right', null]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  href: PropTypes.string,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  ]),
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: null,
  disabled: false,
  loading: false,
  icon: null,
  lnr: null,
  fa: null,
  faType: 'solid',
  arrow: null,
  children: null,
  href: null,
  link: null,
  type: 'submit',
  onClick: null,
};

export default Button;
