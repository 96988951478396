import PropTypes from 'prop-types';

const ingredientCategoryShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  cyoSection: PropTypes.number.isRequired,
});

export default ingredientCategoryShape;
