import { rehydrateReducer } from '@spq/utils';
import { LOCATION_CHANGE } from 'redux-first-history';
import { REHYDRATE } from 'redux-persist/lib/constants';

import * as historyActions from '../actions/history';
import { determineRoutesFlowName } from '../utils/historyUtils';

const defaultState = () =>
  Object.freeze({
    currentIndex: -1,
    locations: [],
  });

const createNewLocation = (previousLocation, newLocation) => ({
  ...newLocation,
  flow: determineRoutesFlowName(previousLocation, newLocation),
  isValid: true,
});

const addNewLocation = (state, action) => {
  /* Replaces all the locations after the current index with new location */
  const previousIndex = state.currentIndex;
  const previousLocation = state.locations[previousIndex];

  const newIndex = previousIndex + 1;
  const newLocation = createNewLocation(previousLocation, action.payload.location);

  const locations = [...state.locations.slice(0, newIndex), newLocation];

  return Object.freeze({
    ...state,
    currentIndex: newIndex,
    locations,
  });
};

const checkPage = (currentLocation) => {
  const isHomePage = currentLocation.pathname === '/';
  const nonRefreshPages = ['/site/.+', '/kioskPairing', '/scan', '/popup/payment'];
  const isNonRefreshPage = nonRefreshPages.find((path) => !!currentLocation.pathname.match(path));

  return !isNonRefreshPage && !isHomePage;
};

// eslint-disable-next-line default-param-last
export default function historyReducer(state = defaultState(), action) {
  switch (action.type) {
    case REHYDRATE: {
      const incoming = action.payload && action.payload.history;
      if (incoming) {
        /* Check if the state should be restored, don't refresh if it's the specified page */
        const { currentLocation } = action;

        if ((currentLocation.state || window.hasRefreshed) && checkPage(currentLocation)) {
          return Object.freeze({
            ...rehydrateReducer(['currentIndex', 'locations'], state, incoming),
          });
        }
      }
      return Object.freeze({ ...state });
    }
    case LOCATION_CHANGE: {
      switch (action.payload.action) {
        case 'PUSH': {
          return addNewLocation(state, action);
        }
        case 'POP': {
          if (state.locations.length === 0) {
            return addNewLocation(state, action);
          }

          const existingIndex = state.locations.findIndex(
            (location) => location.key === action.payload.location.key,
          );

          const newIndex = existingIndex > 0 ? existingIndex : 0;
          const newLocation = {
            ...state.locations[newIndex],
            ...action.payload.location,
          };

          const locations = [
            ...state.locations.slice(0, newIndex),
            newLocation,
            ...state.locations.slice(newIndex + 1),
          ];

          return Object.freeze({
            ...state,
            currentIndex: newIndex,
            locations,
          });
        }
        case 'REPLACE': {
          const previousLocation = state.locations[state.currentIndex];
          const newLocation = createNewLocation(previousLocation, action.payload.location);

          const locations = [
            ...state.locations.slice(0, state.currentIndex),
            newLocation,
            ...state.locations.slice(state.currentIndex + 1),
          ];

          return Object.freeze({
            ...state,
            locations,
          });
        }
        default: {
          return state;
        }
      }
    }
    case historyActions.LOCATION_INVALID: {
      const newLocation = {
        ...state.locations[state.currentIndex],
        isValid: false,
      };

      const locations = [
        ...state.locations.slice(0, state.currentIndex),
        newLocation,
        ...state.locations.slice(state.currentIndex + 1),
      ];

      return Object.freeze({
        ...state,
        locations,
      });
    }
    default: {
      return state;
    }
  }
}
