import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import favoriteMenuItemShape from '../../shapes/favoriteMenuItemShape';

import FavoriteMenuItem from '../../containers/FavoriteMenuItem';
import Button from '../Button';
import Modal from '../Modal';

import './EditFavorite.css';

function EditFavorite(props) {
  const { favoriteMenuItem, name, onNameChange, onHide, onSave } = props;
  return (
    <Modal className="EditFavorite" show={favoriteMenuItem !== null} onHide={onHide}>
      <Modal.Body className="kale">
        <Typography variant="h4" color="secondary.dark">
          Rename your favourite
        </Typography>
        <ul className="kale">
          {favoriteMenuItem && <FavoriteMenuItem className="NoButtons" data={favoriteMenuItem} />}
        </ul>
        <input className="OrderInfo modal-input" type="text" onChange={onNameChange} value={name} />
        <div className="EditFavoriteButtonRow">
          <Button className="btn-secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button className="btn-primary" onClick={onSave}>
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

EditFavorite.propTypes = {
  favoriteMenuItem: favoriteMenuItemShape.isRequired,
  name: PropTypes.string.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditFavorite;
