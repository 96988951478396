import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './SectionSeparator.css';

function SectionSeparator({ className, children, typographyVariant, typographyColor }) {
  return (
    <div className={classnames('SectionSeparator kale', className)}>
      <ul>
        <li>
          <div className="line" />
        </li>
        <li className="name">
          <Typography variant={typographyVariant} color={typographyColor}>
            {children}
          </Typography>
        </li>
        <li>
          <div className="line" />
        </li>
      </ul>
    </div>
  );
}

SectionSeparator.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  typographyVariant: PropTypes.string,
  typographyColor: PropTypes.string,
};

SectionSeparator.defaultProps = {
  className: '',
  typographyVariant: 'h5',
  typographyColor: 'primary',
};

export default SectionSeparator;
