import React from 'react';
import Modal from 'react-bootstrap-modal';

import './Modal.css';

/*
  react-bootstrap-modal has incorrect default props.
  We use ModalWrapper to overwrite the default property for onHide.
*/
function ModalWrapper(props) {
  return <Modal {...props} />;
}

ModalWrapper.defaultProps = {
  onHide: () => {},
};

ModalWrapper.Body = Modal.Body;
ModalWrapper.Header = Modal.Header;
ModalWrapper.Title = Modal.Title;
ModalWrapper.Footer = Modal.Footer;
ModalWrapper.Dismiss = Modal.Dismiss;

ModalWrapper.BaseModal = Modal;

export default ModalWrapper;
