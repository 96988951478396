import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as foodActions from '../../actions/food';
import * as foodUtils from '../../utils/foodUtils';

import ingredientCategoryShape from '../../shapes/ingredientCategoryShape';
import ingredientShape from '../../shapes/ingredientShape';
import tagShape from '../../shapes/tagShape';

import DietConflictResolver from '../../components/DietConflictResolver';

class DietConflictResolverContainer extends Component {
  static propTypes = {
    conflictingIngredientIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
    highlightedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    tags: PropTypes.objectOf(tagShape).isRequired,
    resetHighlightedTagIds: PropTypes.func.isRequired,
    saveHighlightedTagIds: PropTypes.func.isRequired,
  };

  get conflictingIngredients() {
    const { conflictingIngredientIds, ingredients, ingredientCategories } = this.props;
    const query = {
      selectedIngredientIds: conflictingIngredientIds,
    };
    const food = { ingredientCategories };

    return foodUtils.filterIngredients(ingredients, query, food);
  }

  get ingredientConflictingTags() {
    const { highlightedTagIds, tags } = this.props;

    const ingredientTags = {};
    this.conflictingIngredients.map((ingredient) => {
      const ingredientTagIds = foodUtils.ingredientTagsArray(ingredient, tags).map((tag) => tag.id);

      const conflictingTags = Object.values(tags)
        .filter((tag) => ingredientTagIds.includes(tag.id) === false)
        .filter((tag) => highlightedTagIds.includes(tag.id) === true);

      ingredientTags[ingredient.id] = conflictingTags;
      return ingredient;
    });

    return ingredientTags;
  }

  handleReset = () => {
    const { resetHighlightedTagIds } = this.props;

    resetHighlightedTagIds();
  };

  handleSave = () => {
    const { saveHighlightedTagIds } = this.props;

    saveHighlightedTagIds();
  };

  render() {
    return (
      <DietConflictResolver
        ingredients={this.conflictingIngredients}
        ingredientConflictingTags={this.ingredientConflictingTags}
        onReset={this.handleReset}
        onSave={this.handleSave}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  highlightedTagIds: state.food.highlightedTagIds,
  conflictingIngredientIds: state.food.conflictingIngredientIds,
  ingredients: state.api.ingredients,
  ingredientCategories: state.api.ingredientCategories,
  tags: state.api.tags,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveHighlightedTagIds: foodActions.saveHighlightedTagIds,
      resetHighlightedTagIds: foodActions.resetHighlightedTagIds,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DietConflictResolverContainer);
