import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './Toast.css';

function Toast(props) {
  const { id, clearLimitNotification, children } = props;

  const [show, setShow] = useState(false);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    if (id) {
      clearTimeout(timeoutIdRef.current);
      setShow(true);
      timeoutIdRef.current = setTimeout(() => {
        setShow(false);
        clearLimitNotification();
      }, 2000);
    }

    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, [id]);

  return (
    <div
      className={classnames('ToastWrapper', { slideInUp: id && show }, { slideOutUp: id && !show })}
    >
      <div className="Toast">{children}</div>
    </div>
  );
}

Toast.propTypes = {
  id: PropTypes.number,
  children: PropTypes.node,
  clearLimitNotification: PropTypes.func.isRequired,
};

Toast.defaultProps = {
  id: null,
  children: null,
};

export default Toast;
