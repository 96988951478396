import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ApiProvider from '../../containers/ApiProvider';
import Cart from '../../containers/Cart';
import DietModal from '../../containers/DietModal';
import ErrorBoundary from '../../containers/ErrorBoundary';
import FacebookSpinner from '../../containers/FacebookSpinner';
import IncognitoWarning from '../../containers/IncognitoWarning';
import InvalidTokenError from '../../containers/InvalidTokenError';
import OrderDataValidator from '../../containers/OrderDataValidator';
import OrderStatusModal from '../../containers/OrderStatusModal';
import PaymentSpinner from '../../containers/PaymentSpinner';
import PricingError from '../../containers/PricingError';
import TopBar from '../../containers/TopBar';
import UserSpinner from '../../containers/UserSpinner';
import { AddPaymentMethodFormModal } from '../AddPaymentMethodForm';
import AppUpdateProvider from '../AppUpdateProvider';
import BarcodeScanner from '../BarcodeScanner/BarcodeScanner';
import FavoriteMenuChangedModal from '../FavoriteMenuChangedModal';
import HighlightedRewardModal from '../HighlightedRewardModal';
import PusherProvider from '../PusherProvider';

import './App.css';

function App(props) {
  const { className, children } = props;

  return (
    <div className={classnames('App', className)}>
      <ErrorBoundary>
        <AppUpdateProvider>
          <TopBar />
          <IncognitoWarning />
          <ApiProvider>
            <>
              <Cart />
              <BarcodeScanner />
              <DietModal />
              <FavoriteMenuChangedModal />
              <HighlightedRewardModal />
              <FacebookSpinner />
              <InvalidTokenError />
              <OrderDataValidator />
              <OrderStatusModal />
              <AddPaymentMethodFormModal />
              <PaymentSpinner />
              <PricingError />
              <UserSpinner />
              <PusherProvider />
              {children}
            </>
          </ApiProvider>
        </AppUpdateProvider>
      </ErrorBoundary>
    </div>
  );
}

App.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.element,
};

App.defaultProps = {
  children: null,
};

export default App;
