import React from 'react';
import PropTypes from 'prop-types';

import Image from '../../containers/Image';
import useWindowSize from '../../hooks/useWindowSize';

import './HomepageMarketing.css';

function HomepageMarketing(props) {
  const { children } = props;
  const { width } = useWindowSize();
  const isMobile = width < 767;

  return (
    <>
      {isMobile && (
        <div className="HomepageMarketingBannerWrapper-Mobile">
          <Image id="home-marketing-banner" source="bannerImages" context="homepage-marketing" />
        </div>
      )}
      {children}
      {!isMobile && (
        <div className="HomepageMarketingBannerWrapper-Desktop">
          <Image id="home-marketing-banner" source="bannerImages" context="homepage-marketing" />
        </div>
      )}
    </>
  );
}

HomepageMarketing.propTypes = {
  children: PropTypes.element.isRequired,
};

export default HomepageMarketing;
