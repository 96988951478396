import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import iconCustomize from './icon-customize.svg';
import iconPlus from './icon-plus.svg';

import './IngredientAdd.css';

function IngredientAdd(props) {
  const { menuItemId, sectionId, empty } = props;
  return (
    <Link className="IngredientAddWrapper" to={`/menu/details/${menuItemId}/add/${sectionId}`}>
      <div className="IngredientAdd">
        <img alt="" src={empty ? iconPlus : iconCustomize} />
      </div>
    </Link>
  );
}

IngredientAdd.propTypes = {
  menuItemId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  empty: PropTypes.bool.isRequired,
};

export default IngredientAdd;
