import React from 'react';

import routesFlows, { PREVIOUS_LOCATION } from '../routesFlows';
import matchPath from './matchPath';

export const findLocationRoute = (location, routes) =>
  routes.find((testedRoute) => {
    if (
      matchPath(location.pathname, {
        path: testedRoute.path,
        exact: true,
        strict: false,
      }) === null
    ) {
      return false;
    }
    return true;
  });

export const isLocationInRoutes = (location, routes) =>
  findLocationRoute(location, routes) !== undefined;

export const isLocationInRoutesFlow = (location, routesFlow) =>
  isLocationInRoutes(location, routesFlow.routes);

export const findLocationRouteIndex = (location, routes) =>
  routes.findIndex((testedRoute) => {
    if (
      matchPath(location.pathname, {
        path: testedRoute.path,
        exact: true,
        strict: false,
      }) === null
    ) {
      return false;
    }
    return true;
  });

export const findPreviousRoutes = (location, routes) =>
  /* The current route is included */
  routes.slice(0, findLocationRouteIndex(location, routes) + 1);

export const isPreviousLocationBeforeCurrentInRoutesFlow = (
  previousLocation,
  currentLocation,
  routesFlow,
) => {
  const previousRoutes = findPreviousRoutes(currentLocation, routesFlow.routes);
  const previousRoute = findLocationRoute(previousLocation, previousRoutes);

  if (previousRoute === undefined) return false;

  const currentRoute = findLocationRoute(currentLocation, routesFlow.routes);

  if (previousRoute.path === currentRoute.path) {
    const previousLocationPath = matchPath(previousLocation.pathname, {
      path: previousRoute.path,
      exact: true,
      strict: false,
    });
    const currentLocationPath = matchPath(currentLocation.pathname, {
      path: currentRoute.path,
      exact: true,
      strict: false,
    });

    if (previousRoute.compareLocationPaths === undefined) {
      return false;
    }

    if (
      previousRoute.compareLocationPaths(previousLocationPath, currentLocationPath) !==
      PREVIOUS_LOCATION
    ) {
      return false;
    }
  }

  return true;
};

export const findRoutesFlow = (routesFlowName) =>
  routesFlows.find((routesFlow) => routesFlow.name === routesFlowName);

export const determineNewRoutesFlow = (location) =>
  routesFlows.find((routesFlow) => isLocationInRoutesFlow(location, routesFlow));

export const shouldRoutesFlowChange = (location, currentRoutesFlowName) => {
  const currentRoutesFlow = findRoutesFlow(currentRoutesFlowName);

  if (currentRoutesFlow === undefined) return true;
  if (isLocationInRoutesFlow(location, currentRoutesFlow) === false) return true;

  return false;
};

export const determineRoutesFlowName = (previousLocation, newLocation) => {
  const previousRoutesFlowName = previousLocation && previousLocation.flow;
  if (shouldRoutesFlowChange(newLocation, previousRoutesFlowName)) {
    const newRoutesFlow = determineNewRoutesFlow(newLocation);
    return newRoutesFlow && newRoutesFlow.name;
  }

  return previousRoutesFlowName;
};

export const determinePreviousLocationInRoutesFlow = (
  previousLocations,
  currentLocation,
  routesFlow,
) => {
  previousLocations
    .slice()
    .reverse()
    .find((previousLocation) =>
      isPreviousLocationBeforeCurrentInRoutesFlow(previousLocation, currentLocation, routesFlow),
    );
};

export const isLocationValid = (location) => location.isValid;

export const determinePreviousLocations = (currentIndex, locations) =>
  locations.slice(0, currentIndex);

export const determineValidPreviousLocations = (currentIndex, locations) =>
  determinePreviousLocations(currentIndex, locations).filter(isLocationValid);

export const determinePreviousLocation = (currentIndex, locations) => {
  const currentLocation = locations[currentIndex];
  const previousLocations = determineValidPreviousLocations(currentIndex, locations);

  const routesFlow = findRoutesFlow(currentLocation.flow);
  const previousLocationInFlow = determinePreviousLocationInRoutesFlow(
    previousLocations,
    currentLocation,
    routesFlow,
  );

  if (previousLocationInFlow) return previousLocationInFlow;

  return previousLocations[previousLocations.length - 1];
};

export const determineDesiredLocation = (
  currentIndex,
  locations,
  pathname,
  findDifferentPath = false,
) => {
  const previousLocations = determineValidPreviousLocations(currentIndex, locations);

  const desiredLocation = previousLocations
    .slice()
    .reverse()
    .find((testedLocation) =>
      findDifferentPath
        ? !matchPath(testedLocation.pathname, {
            path: pathname,
            exact: true,
            strict: false,
          })
        : matchPath(testedLocation.pathname, {
            path: pathname,
            exact: true,
            strict: false,
          }),
    );

  return desiredLocation;
};

export const determinePreviousLocationIndex = (currentIndex, locations) => {
  const previousLocation = determinePreviousLocation(currentIndex, locations);

  if (previousLocation === undefined) return 0;

  return locations.findIndex((location) => location.key === previousLocation.key);
};

export const determineDesiredLocationIndex = (
  currentIndex,
  locations,
  pathname,
  findDifferentPath = false,
) => {
  const desiredLocation = determineDesiredLocation(
    currentIndex,
    locations,
    pathname,
    findDifferentPath,
  );

  if (desiredLocation === undefined) return 0;

  return locations.findIndex((location) => location.key === desiredLocation.key);
};

export const determineStepsToPreviousLocation = (currentIndex, locations) => {
  const previousIndex = determinePreviousLocationIndex(currentIndex, locations);

  return previousIndex - currentIndex;
};

export const determineStepsToDesiredLocation = (currentIndex, locations, pathname) => {
  const desiredIndex = determineDesiredLocationIndex(currentIndex, locations, pathname);

  return desiredIndex - currentIndex;
};

export const determineStepsToLastDifferentLocation = (currentIndex, locations, pathname) => {
  /* Check if current checking path exist in history */
  const isCurrentPathExist = matchPath(locations[currentIndex].pathname, {
    path: pathname,
    exact: true,
    strict: false,
  });

  if (!isCurrentPathExist) return null;

  /* Find steps to last location that is not this pathname */
  const desiredIndex = determineDesiredLocationIndex(currentIndex, locations, pathname, true);

  return desiredIndex - currentIndex;
};

export const resolveTitle = (title, state, location, path) => {
  if (typeof title === 'string') {
    return title;
  }

  try {
    return title(state, location, path);
  } catch (error) {
    return '';
  }
};

export const resolveIcon = (icon) => {
  if (typeof icon === 'string') {
    return <img src={icon} alt="" />;
  }

  return icon;
};

export const resolveHeader = (header, state, location, route) => {
  const path = matchPath(location.pathname, {
    path: route.path,
    exact: true,
    strict: false,
  });

  return {
    desktop: {
      subtitle: resolveTitle(header.desktop.subtitle, state, location, path),
      title: resolveTitle(header.desktop.title, state, location, path),
      icon: resolveIcon(header.desktop.icon),
    },
    mobile: {
      title: resolveTitle(header.mobile.title, state, location, path),
      icon: resolveIcon(header.mobile.icon),
    },
  };
};

/* Takes a search string and returns the value of a key */
export const getSearchParam = (search, param) => {
  const params = new URLSearchParams(search);
  return params.get(param);
};

/* Takes a search string and sets values based on given parameter mapping */
export const setSearchParams = (search, params, overwrite = false) => {
  const currentParams = new URLSearchParams(overwrite ? '' : search);
  Object.entries(params).forEach((param) => {
    if (param[1] !== undefined) {
      currentParams.set(param[0], param[1]);
    }
  });

  return currentParams.toString();
};

/* Takes a search string and deletes a param */
export const deleteSearchParam = (search, param) => {
  const currentParams = new URLSearchParams(search);
  currentParams.delete(param);

  return currentParams.toString();
};
