import React from 'react';

import markerIcon from './marker-icon.png';
import markerShadow from './marker-shadow.png';

import './DummyMarker.css';

function DummyMarker() {
  return (
    <div className="DummyMarker">
      <img alt="" src={markerIcon} className="DummyMarkerIcon" />
      <img alt="" src={markerShadow} className="DummyMarkerShadow" />
    </div>
  );
}

export default DummyMarker;
