import React from 'react';
import { Typography } from '@mui/material';

import loadingSalad from './loading-salad.gif';

import './LoadingScreen.css';

function LoadingScreen() {
  return (
    <div className="LoadingScreen">
      <img className="SaladAnimation" alt="" src={loadingSalad} />
      <Typography variant="subtitle1" color="primary">
        Loading your greens...
      </Typography>
    </div>
  );
}

export default LoadingScreen;
