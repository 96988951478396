import PropTypes from 'prop-types';

const cyoSectionShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  desc: PropTypes.string,
  order: PropTypes.number.isRequired,
  selectionBehavior: PropTypes.string.isRequired,
  itemSize: PropTypes.string.isRequired,
});

export default cyoSectionShape;
