import { rehydrateReducer } from '@spq/utils';
import { REHYDRATE } from 'redux-persist/es/constants';

import statusEnum from '../enums/statusEnum';

const defaultState = {
  name: null,
  slug: null,
  title: null,
  menuGroupId: null,
  description: '',
  metaTags: '',
  content: '',
  styling: {},
  images: {
    logo: {},
    banner: {},
  },
  associatedPartners: [],
  status: statusEnum.INITIAL,
  errors: [],
};

// eslint-disable-next-line default-param-last
const landingPageReducer = (state = defaultState, action) => {
  switch (action.type) {
    case REHYDRATE: {
      const incoming = action.payload && action.payload.landingPage;
      if (incoming && incoming.version === state.version) {
        return Object.freeze({
          ...rehydrateReducer(
            [
              'name',
              'slug',
              'title',
              'description',
              'metaTags',
              'content',
              'menuGroupId',
              'styling',
              'images',
              'diningChoices',
              'disabledGglocationTypes',
              'associatedPartners',
            ],
            state,
            incoming,
          ),
        });
      }
      return Object.freeze({ ...state });
    }

    default:
      return state;
  }
};

export default landingPageReducer;
