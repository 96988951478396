import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import ingredientShape from '../../shapes/ingredientShape';
import pricingVariationShape from '../../shapes/pricingVariationShape';

import Button from '../Button';
import Currency from '../Currency';
import FeedbackSticker from '../FeedbackSticker';

import './PricingVariationsNotification.css';

function PricingVariationsNotification(props) {
  const { pricingVariation, ingredient, onAddClick } = props;

  return (
    <FeedbackSticker className="PricingVariationsNotification">
      <span className="Text">
        <Typography variant="subtitle1" color="grey.main" className="Content">
          <strong>Recommended:</strong> {ingredient.name}
          &nbsp;for <Currency value={pricingVariation.price} />
          &nbsp;(U.P. <Currency value={ingredient.price} />)
        </Typography>
      </span>
      <Button className="Add blue-link" onClick={onAddClick}>
        Add
      </Button>
    </FeedbackSticker>
  );
}

PricingVariationsNotification.propTypes = {
  pricingVariation: pricingVariationShape.isRequired,
  ingredient: ingredientShape.isRequired,
  onAddClick: PropTypes.func.isRequired,
};

export default PricingVariationsNotification;
