import React, { Component } from 'react';
import Draggable from 'react-draggable';
import Measure from 'react-measure';
import PropTypes from 'prop-types';

import './DragScroll.css';

class DragScroll extends Component {
  static propTypes = {
    offsetLeft: PropTypes.number,
    offsetRight: PropTypes.number,
    children: PropTypes.element.isRequired,
  };

  static defaultProps = {
    offsetLeft: 0,
    offsetRight: 0,
  };

  constructor(props) {
    super(props);

    const { offsetLeft } = props;
    this.state = {
      leftBoundary: window.innerWidth - offsetLeft,
      childWidth: 0,
    };

    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleMeasure(bounds) {
    const { width } = bounds;
    this.setState({ childWidth: width }, this.updateDimensions);
  }

  handleWindowResize() {
    this.updateDimensions();
  }

  updateDimensions() {
    const { offsetLeft } = this.props;
    const { childWidth } = this.state;
    this.setState({ leftBoundary: window.innerWidth - childWidth - offsetLeft });
  }

  render() {
    const { children, offsetRight } = this.props;
    const { leftBoundary } = this.state;

    /* Provide leftBoundary as key to force rerender when it changes */
    return (
      <Measure bounds onResize={(contentRect) => this.handleMeasure(contentRect.bounds)}>
        {({ measureRef }) => (
          <Draggable
            key={leftBoundary}
            axis="x"
            bounds={{ left: leftBoundary, right: offsetRight }}
            {...this.props}
          >
            <div>
              <div className="DraggableChildWrapper" ref={measureRef}>
                {children}
              </div>
            </div>
          </Draggable>
        )}
      </Measure>
    );
  }
}

export default DragScroll;
