export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const OPEN_MOBILE_MENU = 'OPEN_MOBILE_MENU';
export const CLOSE_MOBILE_MENU = 'CLOSE_MOBILE_MENU';

export const OPEN_FACEBOOK_LOGIN = 'OPEN_FACEBOOK_LOGIN';

export const OPEN_PAYMENT_GATEWAY = 'OPEN_PAYMENT_GATEWAY';
export const OPEN_PAYMENT_SPINNER = 'OPEN_PAYMENT_SPINNER';
export const CLOSE_PAYMENT_SPINNER = 'CLOSE_PAYMENT_SPINNER';
export const SET_IS_ADD_PAYMENT_METHOD_FORM_SHOWN = 'SET_IS_ADD_PAYMENT_METHOD_FORM_SHOWN';
export const PROCESS_2C2P_CARD_FORM = 'PROCESS_2C2P_CARD_FORM';
export const SET_PAYMENT_CARD_FORM_ERROR = 'SET_PAYMENT_CARD_FORM_ERROR';

export const OPEN_LANDING_PAGE = 'OPEN_LANDING_PAGE';
export const CLOSE_LANDING_PAGE = 'CLOSE_LANDING_PAGE';

export const OPEN_MENU_ITEM_PAGE = 'OPEN_MENU_ITEM_PAGE';
export const RETURN_TO_MENU = 'RETURN_TO_MENU';
export const CLEAR_QUERY_GO_TO_MENU_DETAILS = 'CLEAR_QUERY_GO_TO_MENU_DETAILS';

export const OPEN_DINING_CHOICE_MODAL = 'OPEN_DINING_CHOICE_MODAL';
export const CLOSE_DINING_CHOICE_MODAL = 'CLOSE_DINING_CHOICE_MODAL';

export const OPEN_UPSELL_SCREEN = 'OPEN_UPSELL_SCREEN';

export const SET_PAGE_SCENE = 'SET_PAGE_SCENE';
export const OPEN_SCANNER_MODAL = 'OPEN_SCANNER_MODAL';
export const CLOSE_SCANNER_MODAL = 'CLOSE_SCANNER_MODAL';

export const OPEN_REWARD_MODAL = 'OPEN_REWARD_MODAL';
export const CLOSE_REWARD_MODAL = 'CLOSE_REWARD_MODAL';

export const SET_IS_GUEST_LOGIN_FLOW = 'SET_IS_GUEST_LOGIN_FLOW';

export const openLandingPage = () => ({
  type: OPEN_LANDING_PAGE,
});

export const closeLandingPage = () => ({
  type: CLOSE_LANDING_PAGE,
});

export const toggleMobileMenu = () => ({
  type: TOGGLE_MOBILE_MENU,
});

export const openMobileMenu = () => ({
  type: OPEN_MOBILE_MENU,
});

export const closeMobileMenu = () => ({
  type: CLOSE_MOBILE_MENU,
});

export const openFacebookLogin = () => ({
  type: OPEN_FACEBOOK_LOGIN,
});

export const openPaymentGateway = () => ({
  type: OPEN_PAYMENT_GATEWAY,
});

export const openPaymentSpinner = () => ({
  type: OPEN_PAYMENT_SPINNER,
});

export const closePaymentSpinner = () => ({
  type: CLOSE_PAYMENT_SPINNER,
});

export const setIsAddPaymentMethodFormShown = (isAddPaymentMethodFormShown) => ({
  type: SET_IS_ADD_PAYMENT_METHOD_FORM_SHOWN,
  isAddPaymentMethodFormShown,
});

export const process2C2PCardForm = (validatedData) => ({
  type: PROCESS_2C2P_CARD_FORM,
  validatedData,
});

export const setPaymentCardFormError = (error) => ({
  type: SET_PAYMENT_CARD_FORM_ERROR,
  error,
});

export const openMenuItemPage = () => ({
  type: OPEN_MENU_ITEM_PAGE,
});

export const returnToMenu = () => ({
  type: RETURN_TO_MENU,
});

export const openUpsellScreen = () => ({
  type: OPEN_UPSELL_SCREEN,
});

export const clearQueryGoToMenuDetails = () => ({
  type: CLEAR_QUERY_GO_TO_MENU_DETAILS,
});

export const openDiningChoiceModal = () => ({
  type: OPEN_DINING_CHOICE_MODAL,
});

export const closeDiningChoiceModal = () => ({
  type: CLOSE_DINING_CHOICE_MODAL,
});

export const setPageScene = (scene) => ({
  type: SET_PAGE_SCENE,
  scene,
});

export const openScannerModal = () => ({
  type: OPEN_SCANNER_MODAL,
});

export const closeScannerModal = () => ({
  type: CLOSE_SCANNER_MODAL,
});

export const openRewardModal = ({ rewardId, rewardType }) => ({
  type: OPEN_REWARD_MODAL,
  rewardId,
  rewardType,
});

export const closeRewardModal = () => ({
  type: CLOSE_REWARD_MODAL,
});

export const setIsGuestLoginFlow = (isGuestLoginFlow) => ({
  type: SET_IS_GUEST_LOGIN_FLOW,
  isGuestLoginFlow,
});
