import React from 'react';

import FontAwesome from '../FontAwesome';

import './InfiniteScrollLoader.css';

function InfinteScrollLoader() {
  return (
    <div className="LoadingButtonIcon">
      <FontAwesome name="circle-o-notch" spin />
    </div>
  );
}

export default InfinteScrollLoader;
