import { chain, isEqual, omitBy } from 'lodash';
import moment from 'moment';

import orderEnum from '../enums/orderEnum';
import previewStageEnum from '../enums/previewStageEnum';

import { calculateOrderItemHash } from './orderItemUtils';
import * as pricingUtils from './pricingUtils';

import { COMPLETED_ORDERS_CUTOFF_TIME } from '../settings';

export const orderItemPrice = (orderItem, menuItems, pricingVariations, ingredients) => {
  const menuItem = menuItems[orderItem.menuItemId];
  const quantity = orderItem.personalSettings.length;

  const totalUnitPrice = pricingUtils.customizedMenuItemPrice(
    menuItem,
    orderItem.selectedIngredientServings,
    orderItem.defaultIngredientServings,
    pricingVariations,
    ingredients,
  );

  return totalUnitPrice * quantity;
};

export const orderPrice = (order, menuItems, pricingVariations, ingredients) =>
  Object.values(order.orderItems)
    .map((orderItem) => orderItemPrice(orderItem, menuItems, pricingVariations, ingredients))
    .reduce((priceA, priceB) => priceA + priceB, 0);

export const isOrderOnHold = (orderStatus) => orderStatus === 38;

export const isOrderStatusAccepted = (orderStatus) => orderStatus >= 90 && orderStatus <= 129;

export const isOrderStatusCompleted = (orderStatus) => orderStatus >= 130;

export const isOrderStatusCancelled = (orderStatus) => orderStatus >= 70 && orderStatus < 90;

export const isOrderStatusUserCancelled = (orderStatus) => orderStatus >= 70 && orderStatus < 80;

export const isOrderStatusStoreCancelled = (orderStatus) => orderStatus >= 80 && orderStatus < 90;

export const isOrderStatusFailed = (orderStatus) => {
  if (orderStatus < 0) return true;
  const failedOrderStatuses = [
    orderEnum.SEEKING_PAYMENT_INVALID_DATA,
    orderEnum.FAILED_PAYMENT_AUTH,
    orderEnum.PAYMENT_UNSUCCESSFUL,
  ];
  return failedOrderStatuses.includes(orderStatus);
};

export const isOrderStatusRequested = (orderStatus) => {
  if (isOrderStatusFailed(orderStatus)) return false;

  return orderStatus >= 0 && orderStatus <= 60;
};

export const isOrderStatusPending = (orderStatus) =>
  isOrderStatusRequested(orderStatus) || isOrderStatusAccepted(orderStatus);

export const orderPickupTimeWithinCutoff = (userOrder) =>
  moment(userOrder.pickupTime).isBetween(moment(), moment().add(...COMPLETED_ORDERS_CUTOFF_TIME));

export const isOrderCompletedWithinCutoff = (userOrder) => {
  if (!(isOrderStatusAccepted(userOrder) || isOrderStatusCompleted(userOrder))) {
    return false;
  }

  return orderPickupTimeWithinCutoff(userOrder);
};

export const appendOrderItemPrices = (orderItems, orderItemHashPrices, stages) =>
  chain(orderItems)
    .mapValues((orderItem) => {
      const orderItemHash = calculateOrderItemHash(orderItem);
      // Update default fallback pricing from ready stage
      const orderItemPricing = stages.includes(previewStageEnum.READY)
        ? { ...orderItem.pricing, ...orderItemHashPrices[orderItemHash] }
        : { ...orderItem.pricing };
      // Update pricing for each included stage
      stages.forEach((stage) => {
        orderItemPricing[stage] = orderItemHashPrices[orderItemHash];
      });
      return {
        ...orderItem,
        pricing: orderItemPricing,
      };
    })
    .value();

export const appendOrderPrices = (order, orderPrices, stages) => {
  // Update default fallback pricing from ready stage
  const orderPricing = stages.includes(previewStageEnum.READY)
    ? { ...order.pricing, ...orderPrices }
    : { ...order.pricing };
  // Update pricing for each included stage
  stages.forEach((stage) => {
    orderPricing[stage] = orderPrices;
  });
  return orderPricing;
};

export const sortedUserOrdersArray = (userOrders) =>
  userOrders.sort((userOrderA, userOrderB) => {
    if (isOrderCompletedWithinCutoff(userOrderA) && !isOrderCompletedWithinCutoff(userOrderB)) {
      return -1;
    }
    if (!isOrderCompletedWithinCutoff(userOrderA) && isOrderCompletedWithinCutoff(userOrderB)) {
      return 1;
    }

    if (isOrderStatusPending(userOrderA) && !isOrderStatusPending(userOrderB)) {
      return -1;
    }
    if (!isOrderStatusPending(userOrderA) && isOrderStatusPending(userOrderB)) {
      return 1;
    }

    if (moment(userOrderA.created).isAfter(userOrderB.created)) return -1;
    if (moment(userOrderA.created).isBefore(userOrderB.created)) return 1;
    return 0;
  });

export const getMenuItemInMenuGroup = (
  gglocation,
  apiId,
  { menuItems, menuGroups, gglocationMenuGroups },
) => {
  const menuGroup = gglocationMenuGroups[gglocation];
  const menuGroupMenuItems = menuGroups[menuGroup];

  return menuGroupMenuItems.filter((item) => menuItems[item].apiId === apiId);
};

export const removeOrderItemsWithApiIds = ({ orderItems, apiIds }) => {
  const newOrderItems = omitBy(orderItems, ({ apiId }) => apiIds.includes(apiId));
  const orderItemsIds = Object.keys(newOrderItems);

  return { orderItems: newOrderItems, orderItemsIds };
};

export const isOrderItemFavorite = (orderItem, { favoriteMenuItems }) =>
  Object.values(favoriteMenuItems).some(
    (favoriteMenu) =>
      isEqual(orderItem.menu.ingredientServings, favoriteMenu.ingredientServings) &&
      favoriteMenu.apiId === orderItem.apiId,
  );

export const isLandingPageOrder = ({ landingPageHistory }) =>
  landingPageHistory.length > 0 && !(landingPageHistory[landingPageHistory.length - 1] === '');
