import React from 'react';
import PropTypes from 'prop-types';

import PersonalizePreferenceGroup from '../../containers/PersonalizePreferenceGroup';
import OrderItemLabelInput from '../PersonalizePreferenceGroup/OrderItemLabelInput';

import './PersonalizePreferenceGroups.css';

function PersonalizePreferenceGroups(props) {
  const { id, isLabelShown, preferenceGroupIds } = props;

  return (
    <ul className="PersonalizePreferenceGroups hide-empty">
      {isLabelShown && (
        <li>
          <OrderItemLabelInput id={id} />
        </li>
      )}
      {preferenceGroupIds.map((preferenceGroupId) => (
        <PersonalizePreferenceGroup
          key={id + preferenceGroupId}
          id={id}
          preferenceGroupId={preferenceGroupId}
        />
      ))}
    </ul>
  );
}

PersonalizePreferenceGroups.propTypes = {
  id: PropTypes.string.isRequired,
  isLabelShown: PropTypes.bool,
  preferenceGroupIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

PersonalizePreferenceGroups.defaultProps = {
  isLabelShown: false,
};

export default PersonalizePreferenceGroups;
