import * as Sentry from '../services/sentry';

export const isLocalStorageSupported = () => {
  try {
    const x = '__local_storage_test__';
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

const localStorageReloadWhitelist = [
  'user', // Persist user login session after crash
  '_persist', // redux-persist key
];

const obtainPersistStorageData = () => {
  try {
    const whiteListStorage = {};
    const localPersistStorage = localStorage.getItem('persist:root');

    if (!localPersistStorage) return null;

    const persistData = JSON.parse(localPersistStorage);

    localStorageReloadWhitelist.forEach((key) => {
      const obj = persistData[key];
      if (obj) {
        whiteListStorage[key] = obj;
      }
    });
    return whiteListStorage;
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};

const restorePersistStorage = (persistData) => {
  try {
    return localStorage.setItem('persist:root', JSON.stringify(persistData));
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};

export const obtainReloadBaseUrl = (hasLocalStorage = false) => {
  const reloadBaseUrl = `${window.location.protocol}//${window.location.host}/`;
  if (!hasLocalStorage) return reloadBaseUrl;
  try {
    const localPersistStorage = localStorage.getItem('persist:root');

    if (!localPersistStorage) return reloadBaseUrl;

    const persistData = JSON.parse(localPersistStorage);
    const order = JSON.parse(persistData.order);
    const landingPage =
      order.landingPageHistory &&
      order.landingPageHistory?.length > 0 &&
      order.landingPageHistory[order.landingPageHistory.length - 1];

    if (landingPage) {
      return `${reloadBaseUrl}site/${landingPage}/`;
    }
    return reloadBaseUrl;
  } catch (e) {
    Sentry.captureException(e);
    return reloadBaseUrl;
  }
};

export const reloadApp = () => {
  const hasLocalStorage = isLocalStorageSupported();
  const reloadBaseUrl = obtainReloadBaseUrl(hasLocalStorage);

  if (hasLocalStorage) {
    const persistData = obtainPersistStorageData();
    localStorage.clear();
    if (persistData) {
      restorePersistStorage(persistData);
    }
  }
  window.location.href = reloadBaseUrl;
};
