import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import UserSpinner from '../../components/UserSpinner';

function UserSpinnerContainer(props) {
  const { status } = props;

  return <UserSpinner status={status} />;
}

UserSpinnerContainer.propTypes = {
  status: PropTypes.objectOf(PropTypes.number).isRequired,
};

const mapStateToProps = (state) => ({
  status: {
    signUp: state.user.status.signUp,
    login: state.user.status.login,
    facebookLogin: state.user.status.facebookLogin,
    orders: state.api.status.orders,
    forgetPassword: state.user.status.forgetPassword,
    paymentMethods: state.api.status.paymentMethod,
    updateProfile: state.user.status.updateProfile,
    changePassword: state.user.status.changePassword,
    cancelOrder: state.user.status.cancelOrder,
  },
});

export default connect(mapStateToProps, undefined)(UserSpinnerContainer);
