import React from 'react';
import { useSelector } from 'react-redux';
import { chain, identity } from 'lodash';
import PropTypes from 'prop-types';

import PersonalizePreferenceGroups from '../../../containers/PersonalizePreferenceGroups';
import SectionSeparator from '../../SectionSeparator';

import './PersonalizeOrderItem.css';

function PersonalizeOrderItem(props) {
  const { id, index, name, showSeparator } = props;

  const preferenceGroups = useSelector((state) => state.api.preferenceGroups);

  const preferenceGroupWebDisplayRows = chain(preferenceGroups)
    .map('webDisplayRow')
    .uniq()
    .sortBy(identity)
    .value();

  return (
    <div className="PersonalizeOrderItem">
      {showSeparator && (
        <SectionSeparator>
          {name} ( {index + 1} )
        </SectionSeparator>
      )}
      {preferenceGroupWebDisplayRows.map((webDisplayRow, webDisplayRowIndex) => (
        <div className="PersonalizeForm" key={[id, webDisplayRow]}>
          <PersonalizePreferenceGroups
            id={id}
            isLabelShown={webDisplayRowIndex === 0}
            webDisplayRow={webDisplayRow}
          />
        </div>
      ))}
    </div>
  );
}

PersonalizeOrderItem.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  showSeparator: PropTypes.bool,
};

PersonalizeOrderItem.defaultProps = {
  showSeparator: false,
};

export default PersonalizeOrderItem;
