import PropTypes from 'prop-types';

const userShape = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  phoneConfirmed: PropTypes.bool,
  socialLogin: PropTypes.bool,
  token: PropTypes.string,
  isPasswordModalShown: PropTypes.bool,
  isResendModalShown: PropTypes.bool,
  afterSignIn: PropTypes.object,
  beforeSignIn: PropTypes.object,
  status: PropTypes.objectOf(PropTypes.number),
});

export default userShape;
