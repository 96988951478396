// eslint-disable-next-line no-redeclare
/* global google */

import React, { Component } from 'react';
import { MAP } from 'react-google-maps/lib/constants';
import PropTypes from 'prop-types';

import coordinatesShape from '../../../shapes/coordinatesShape';

import GglocationMapMobile from '../../../components/PickupTimeSelector/GglocationMapMobile';

class GglocationMapMobileComponent extends Component {
  static propTypes = {
    className: PropTypes.string,
    coordinates: coordinatesShape.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      googleMap: null,
      markerSize: 16,
    };
  }

  handleMapClick = () => {
    const { isExpanded, googleMap } = this.state;

    this.setState({ isExpanded: !isExpanded });
    const currentCenter = googleMap.getCenter();
    google.maps.event.trigger(googleMap, 'resize');
    googleMap.setCenter(currentCenter);
    this.setState({ markerSize: !isExpanded ? 40 : 16 });
  };

  handleMapLoad = (map) => {
    const { googleMap } = this.state;
    if (googleMap === null) {
      this.setState({ googleMap: map.context[MAP] });
    }
  };

  render() {
    const { className, coordinates } = this.props;
    const { isExpanded, markerSize } = this.state;

    return (
      <GglocationMapMobile
        className={className}
        coordinates={coordinates}
        isExpanded={isExpanded}
        markerSize={markerSize}
        onMapClick={this.handleMapClick}
        onMapLoad={this.handleMapLoad}
      />
    );
  }
}

export default GglocationMapMobileComponent;
