import React from 'react';
import PropTypes from 'prop-types';

import { landingPageTopBarStyles } from '../../../shapes/landingPageStyleShapes';

import Button from '../../Button';

import CartIcon from '../../../images/icon-cart.svg';
import CartIconLight from '../../../images/icon-cart-light.svg';

import './CartButton.css';

function CartButton(props) {
  const { quantity, isCartOpen, onOpenCart, landingPageStyles } = props;

  return (
    <div
      className="TopBarCartButton"
      style={landingPageStyles && landingPageStyles.cartButtonStyle}
    >
      <Button onClick={onOpenCart}>
        <img src={isCartOpen ? CartIconLight : CartIcon} alt="cart" />
      </Button>
      {quantity > 0 && (
        <span className="Quantity" style={landingPageStyles && landingPageStyles.signInStyle}>
          {quantity}
        </span>
      )}
    </div>
  );
}

CartButton.propTypes = {
  quantity: PropTypes.number.isRequired,
  isCartOpen: PropTypes.bool.isRequired,
  onOpenCart: PropTypes.func.isRequired,
  landingPageStyles: landingPageTopBarStyles.isRequired,
};

export default CartButton;
