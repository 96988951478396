import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getSectionSettings } from '../../../utils/settingsUtils';

import menuItemShape from '../../../shapes/menuItemShape';
import { baseTypeSettingsShape, sectionSettingsShape } from '../../../shapes/settingsShape';

import CyoIngredientsPicker from '../../../components/Cyo/CyoIngredientsPicker';

class CyoIngredientsPickerContainer extends Component {
  static propTypes = {
    menuItemId: PropTypes.number.isRequired,
    sectionId: PropTypes.number.isRequired,
    excludeNullBase: PropTypes.bool,
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    baseTypeSettings: PropTypes.objectOf(baseTypeSettingsShape).isRequired,
    sectionSettings: PropTypes.objectOf(sectionSettingsShape).isRequired,
    onLimitExhausted: PropTypes.func.isRequired,
  };

  static defaultProps = {
    excludeNullBase: false,
  };

  get menuItem() {
    const { menuItems, menuItemId } = this.props;

    return menuItems[menuItemId];
  }

  get sectionSettings() {
    const { sectionId, baseTypeSettings, sectionSettings } = this.props;

    return getSectionSettings({
      baseId: this.menuItem.baseType,
      sectionId,
      baseTypeSettings,
      sectionSettings,
    });
  }

  get categoryIds() {
    return this.sectionSettings?.addCategories?.cyo;
  }

  get isHeaderShown() {
    return this.categoryIds.length > 1;
  }

  render() {
    const { menuItemId, sectionId, excludeNullBase, onLimitExhausted } = this.props;

    return (
      <CyoIngredientsPicker
        menuItemId={menuItemId}
        sectionId={sectionId}
        categoryIds={this.categoryIds}
        isHeaderShown={this.isHeaderShown}
        excludeNullBase={excludeNullBase}
        onLimitExhausted={onLimitExhausted}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  menuItems: state.api.menuItems,
  ingredientCategories: state.api.ingredientCategories,
  baseTypeSettings: state.api.settings.baseTypeSettings,
  sectionSettings: state.api.settings.sectionSettings,
});

export default connect(mapStateToProps, undefined)(CyoIngredientsPickerContainer);
