import React from 'react';
import PropTypes from 'prop-types';

import './CustomizeRight.css';

function CustomizeRight(props) {
  const { children } = props;
  return <div className="CustomizeRight">{children}</div>;
}

CustomizeRight.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CustomizeRight;
