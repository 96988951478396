import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import gglocationTypeEnum from '../../../enums/gglocationTypeEnum';
import * as selectors from '../../../sagas/selectors';
import { optionalMinuteMomentFormat } from '../../../utils/commonUtils';

import coordinatesShape from '../../../shapes/coordinatesShape';
import gglocationShape from '../../../shapes/gglocationShape';

import MomentCalendar from '../../MomentCalendar/MomentCalendar';

import iconLocale from '../../../images/icon-locale-black.svg';
import iconStation from '../../../images/icon-station.svg';
import iconStore from '../../../images/icon-store.svg';

import './GglocationInfo.css';

function GglocationInfo(props) {
  const {
    name,
    address1,
    address2,
    coordinates,
    isDeliveryOrder,
    openingHours,
    gglocation,
    lastOrderTime,
    readyTime,
    fromOrder,
  } = props;

  const stationTimeInterval = useSelector(selectors.getDefaultStationTimeInterval);

  if (isDeliveryOrder && !fromOrder) {
    return (
      <div className={classnames('GglocationInfo kale', { fromOrder })}>
        {isDeliveryOrder && !fromOrder && <div className="DeliveryAddressNameLine">{name}</div>}
      </div>
    );
  }

  const ready = moment(readyTime);
  const readyLabel = ready.isValid() && ready.fromNow();
  const readyStartTime = ready.isValid() && optionalMinuteMomentFormat(ready);
  const readyEndTime =
    ready.isValid() &&
    optionalMinuteMomentFormat(ready.clone().add(stationTimeInterval, 'minutes'));
  const isStation = gglocation?.gglocationType === gglocationTypeEnum.PARTNER;
  const icon = isStation ? iconStation : iconStore;

  return (
    <div className={classnames('GglocationInfo kale', { fromOrder })}>
      <div className="AddressDetails">
        <span className="AddressNameLine">
          {fromOrder ? (
            <Typography variant="subtitle1" color="grey.main" className="OrderAddressTitle">
              <img
                src={isDeliveryOrder ? iconLocale : icon}
                alt="store"
                className={isStation && 'GglocationInfoStationIcon'}
              />
              {name}
            </Typography>
          ) : (
            <>
              {isStation && (
                <Typography variant="calafia2" color="secondary.orange" className="StationLabel">
                  Station
                </Typography>
              )}
              <Typography variant="h2" color="primary.dark" className="AddressTitle">
                {name}
              </Typography>
            </>
          )}
        </span>
        <Typography variant="subtitle1" color="grey.main" fontWeight={fromOrder ? '400' : '600'}>
          {address1} {address2}
        </Typography>
        {!isDeliveryOrder && (
          <>
            {openingHours && (
              <div className="StoreInfo">
                <Typography variant="subtitle1" color="grey.main" fontWeight="600">
                  {openingHours}
                </Typography>
              </div>
            )}
            {isStation && gglocation?.description && (
              <div className="LastOrderTime">
                <Typography variant="subtitle2" color="grey.main">
                  {gglocation?.description}
                </Typography>
              </div>
            )}
            {lastOrderTime && !fromOrder && (
              <div className="LastOrderTime">
                <Typography variant="subtitle2" color="grey.main">
                  Online ordering stops at {lastOrderTime}
                </Typography>
              </div>
            )}
            {fromOrder && (
              <a
                href={`https://www.google.com/maps/dir/Current+Location/${coordinates?.lat},${coordinates?.lng}`}
                target="_blank"
                rel="noopener noreferrer"
                className="blue-link"
              >
                Get directions
              </a>
            )}
          </>
        )}
      </div>
      {fromOrder && readyTime && !isStation && (
        <div className="OrderTimeDetails">
          <Typography variant="body1" color="grey.main">
            <MomentCalendar>{readyTime}</MomentCalendar>
          </Typography>
          {readyLabel && (
            <Typography variant="subtitle1" color="grey.main">
              {readyLabel}
            </Typography>
          )}
        </div>
      )}
      {fromOrder && isStation && readyStartTime && (
        <div className="OrderTimeDetails">
          <Typography variant="body1" color="grey.main">
            <MomentCalendar>{readyTime}</MomentCalendar>, {readyStartTime}-{readyEndTime}
          </Typography>
        </div>
      )}
    </div>
  );
}

GglocationInfo.propTypes = {
  name: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  isDeliveryOrder: PropTypes.bool,
  coordinates: coordinatesShape.isRequired,
  openingHours: PropTypes.string,
  gglocation: PropTypes.shape(gglocationShape),
  lastOrderTime: PropTypes.string,
  readyTime: PropTypes.string,
  fromOrder: PropTypes.bool,
};

GglocationInfo.defaultProps = {
  isDeliveryOrder: false,
  openingHours: null,
  gglocation: null,
  lastOrderTime: null,
  readyTime: null,
  fromOrder: false,
};

export default GglocationInfo;
