import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';

import './QuantitySelector.css';

function QuantitySelector(props) {
  const { value, onIncrement, onDecrement } = props;
  return (
    <div className="QuantitySelector kale">
      <ul>
        <li className="counter">
          <ul>
            <li>
              <Button fa="circle-minus" onClick={onIncrement} />
            </li>
            <li>{value}</li>
            <li>
              <Button fa="circle-plus" onClick={onDecrement} />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

QuantitySelector.propTypes = {
  value: PropTypes.number.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
};

export default QuantitySelector;
