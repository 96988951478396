import React from 'react';
import { Marker, withGoogleMap } from 'react-google-maps';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import coordinatesShape from '../../../shapes/coordinatesShape';

import Button from '../../Button';
import StyledGoogleMap from '../../StyledGoogleMap';

import { MAP_DEFAULT_ZOOM } from '../../../settings';

import iconLocale from '../../../images/icon-locale.svg';

import './GglocationMapMobile.css';

const GoogleMapWrapper = withGoogleMap((props) => (
  <StyledGoogleMap
    defaultZoom={MAP_DEFAULT_ZOOM}
    defaultCenter={props.coordinates}
    defaultOptions={{ disableDefaultUI: true }}
    onClick={props.onMapClick}
    onMapLoad={props.onMapLoad}
  >
    <Marker
      position={props.coordinates}
      icon={{
        url: `${iconLocale}`,
        scaledSize: new window.google.maps.Size(props.markerSize, props.markerSize),
      }}
    />
  </StyledGoogleMap>
));

function GglocationMapMobile(props) {
  const { className, coordinates, isExpanded, markerSize, onMapClick, onMapLoad } = props;
  return (
    <div className={classnames('GglocationMapMobileWrapper', className, { expanded: isExpanded })}>
      <div className={classnames('GglocationMapMobileCloseButtonWrapper')}>
        <Button className="GglocationMapMobileCloseButton" lnr="cross" onClick={onMapClick} />
      </div>
      <GoogleMapWrapper
        containerElement={<div className="GglocationMapMobile" />}
        mapElement={<div className="MapElement" />}
        coordinates={coordinates}
        markerSize={markerSize}
        onMapClick={onMapClick}
        onMapLoad={onMapLoad}
      />
    </div>
  );
}

GglocationMapMobile.propTypes = {
  className: PropTypes.string,
  coordinates: coordinatesShape.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  markerSize: PropTypes.number.isRequired,
  onMapClick: PropTypes.func,
  onMapLoad: PropTypes.func,
};

GglocationMapMobile.defaultProps = {
  className: '',
  onMapClick: PropTypes.func.isRequired,
  onMapLoad: PropTypes.func.isRequired,
};

export default GglocationMapMobile;
