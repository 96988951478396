import PropTypes from 'prop-types';

const timeSlotShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  datetime: PropTypes.string.isRequired,
  itemSlotsAvailable: PropTypes.number.isRequired,
  isTimeSlotLowCapacity: PropTypes.bool.isRequired,
});

export default timeSlotShape;
