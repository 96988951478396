import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as pageActions from '../../actions/page';

import FacebookLogin from '../../components/FacebookLogin';

class FacebookLoginContainer extends Component {
  static propTypes = {
    openFacebookLogin: PropTypes.func.isRequired,
  };

  handleLoginClick = (event) => {
    const { openFacebookLogin } = this.props;

    event.preventDefault();
    openFacebookLogin();
  };

  render() {
    return <FacebookLogin onLogin={this.handleLoginClick} />;
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openFacebookLogin: pageActions.openFacebookLogin,
    },
    dispatch,
  );

export default connect(undefined, mapDispatchToProps)(FacebookLoginContainer);
