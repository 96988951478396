import React from 'react';
import { Typography } from '@mui/material';

import './MenuPageGglocationMissingNotice.css';

function MenuPageGglocationMissingNotice() {
  return (
    <div className="MenuPageGglocationMissingNotice">
      <div className="content-top">
        <Typography variant="h3" color="primary">
          Oops! We&apos;re sorry...
        </Typography>
      </div>
      <p>Please select a store or delivery address before ordering.</p>
    </div>
  );
}

export default MenuPageGglocationMissingNotice;
