import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import NutritionalInfo from '../NutritionalInfo';

function NutritionalSection(props) {
  const { className, ingredientId } = props;

  return (
    <div className={classnames(className, 'NutritionalSection')}>
      <p>Nutritional information</p>
      <NutritionalInfo ingredientId={ingredientId} />
      <Typography variant="subtitle2" className="NutritionalDisclaimer">
        <span>Disclaimer:</span> Nutritional information may vary depending on portioning.
      </Typography>
    </div>
  );
}

NutritionalSection.propTypes = {
  className: PropTypes.string,
  ingredientId: PropTypes.number,
};

NutritionalSection.defaultProps = {
  className: '',
  ingredientId: undefined,
};

export default NutritionalSection;
