import PropTypes from 'prop-types';

import preferenceGroupDisplayQualifierShape from './preferenceGroupDisplayQualifierShape';
import preferenceShape from './preferenceShape';

const preferenceGroupShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  webDisplayStyle: PropTypes.number.isRequired,
  webDisplayRow: PropTypes.number.isRequired,
  ordering: PropTypes.number.isRequired,
  displayQualifiers: PropTypes.arrayOf(preferenceGroupDisplayQualifierShape).isRequired,
  preferences: PropTypes.arrayOf(preferenceShape).isRequired,
});

export default preferenceGroupShape;
