import React from 'react';
import PropTypes from 'prop-types';

import iconAmex from './icon-amex.svg';
import iconMasterCard from './icon-master.svg';
import iconVisa from './icon-visa.svg';

import './PaymentIcon.css';

const paymentMethodIcon = {
  MasterCard: iconMasterCard,
  Visa: iconVisa,
  Amex: iconAmex,
};

function PaymentIcon(props) {
  const { brand } = props;
  return <img className="PaymentIcon" alt={brand} src={paymentMethodIcon[brand]} />;
}

PaymentIcon.propTypes = {
  brand: PropTypes.oneOf(['MasterCard', 'Visa', 'Amex']).isRequired,
};

export default PaymentIcon;
