import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import scrollIntoView from 'scroll-into-view';

import * as historyActions from '../../../actions/history';
import * as orderItemActions from '../../../actions/orderItem';
import { getSectionSettings } from '../../../utils/settingsUtils';

import cyoSectionShape from '../../../shapes/cyoSectionShape';
import ingredientCategoryShape from '../../../shapes/ingredientCategoryShape';
import { baseTypeSettingsShape, sectionSettingsShape } from '../../../shapes/settingsShape';

import MenuDetailsAdd from '../../../components/MenuDetails/MenuDetailsAdd';

class MenuDetailsAddContainer extends Component {
  static propTypes = {
    menuItemId: PropTypes.number.isRequired,
    baseId: PropTypes.number.isRequired,
    sectionId: PropTypes.number.isRequired,
    orderItemId: PropTypes.number,
    limitNotification: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      limit: PropTypes.number,
      extra: PropTypes.bool,
      isRemove: PropTypes.bool,
      clearLimitNotification: PropTypes.func.isRequired,
    }),
    isOrderPreviewRequested: PropTypes.bool.isRequired,

    cyoSections: PropTypes.objectOf(cyoSectionShape).isRequired,
    selectedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    highlightedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
    baseTypeSettings: PropTypes.objectOf(baseTypeSettingsShape).isRequired,
    sectionSettings: PropTypes.objectOf(sectionSettingsShape).isRequired,

    onLimitExhausted: PropTypes.func.isRequired,
    saveHighlightedIngredientServings: PropTypes.func.isRequired,
    resetHighlightedIngredientServings: PropTypes.func.isRequired,
    goToMenu: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  };

  static defaultProps = {
    orderItemId: null,
    limitNotification: null,
  };

  componentDidMount() {
    const { resetHighlightedIngredientServings, orderItemId, goToMenu } = this.props;

    /* If there is no OrderItem, navigate back to menu */
    if (!orderItemId) {
      goToMenu({});
    }

    resetHighlightedIngredientServings();
  }

  // eslint-disable-next-line class-methods-use-this
  handlePricingVariationIngredientAdd = (ingredientId) => {
    const ingredientElement = document.querySelector(`.Ingredient-${ingredientId}`);
    scrollIntoView(ingredientElement);
  };

  handleIngredientAddConfirm = () => {
    const { saveHighlightedIngredientServings, goBack } = this.props;
    saveHighlightedIngredientServings();

    goBack();
  };

  handleOnCancel = () => {
    const { resetHighlightedIngredientServings, goBack } = this.props;

    resetHighlightedIngredientServings();
    goBack();
  };

  get section() {
    const { sectionId, cyoSections } = this.props;

    return cyoSections[sectionId];
  }

  get sectionSettings() {
    const { baseId, sectionId, baseTypeSettings, sectionSettings } = this.props;

    return getSectionSettings({
      baseId,
      sectionId,
      baseTypeSettings,
      sectionSettings,
    });
  }

  get categoryIds() {
    return this.sectionSettings?.addCategories?.premade;
  }

  get hasBeenUpdated() {
    const { selectedIngredientServings, highlightedIngredientServings } = this.props;

    return isEqual(selectedIngredientServings, highlightedIngredientServings) === false;
  }

  get isHeaderShown() {
    return this.categoryIds.length > 1;
  }

  get ingredientCategoriesList() {
    const { ingredientCategories } = this.props;

    return this.categoryIds
      .map((categoryId) => ingredientCategories[categoryId])
      .sort((a, b) => a.order - b.order);
  }

  render() {
    const { menuItemId, limitNotification, isOrderPreviewRequested, onLimitExhausted } = this.props;

    return (
      <MenuDetailsAdd
        menuItemId={menuItemId}
        section={this.section}
        ingredientCategoriesList={this.ingredientCategoriesList}
        isHeaderShown={this.isHeaderShown}
        isOrderPreviewRequested={isOrderPreviewRequested}
        priceDifference={this.priceDifference}
        limitNotification={limitNotification}
        updated={this.hasBeenUpdated}
        onLimitExhausted={onLimitExhausted}
        onPricingVariationIngredientAdd={this.handlePricingVariationIngredientAdd}
        onIngredientAddConfirm={this.handleIngredientAddConfirm}
        onCancel={this.handleOnCancel}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  cyoSections: state.api.cyoSections,
  ingredientCategories: state.api.ingredientCategories,
  menuItemIngredientPrices: state.food.menuItemIngredientPrices,
  selectedIngredientServings: state.orderItem.selectedIngredientServings,
  highlightedIngredientServings: state.orderItem.highlightedIngredientServings,
  orderItemId: state.orderItem.id,
  baseTypeSettings: state.api.settings.baseTypeSettings,
  sectionSettings: state.api.settings.sectionSettings,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToMenu: historyActions.goToMenu,
      goBack: historyActions.goBack,
      saveHighlightedIngredientServings: orderItemActions.saveHighlightedIngredientServings,
      resetHighlightedIngredientServings: orderItemActions.resetHighlightedIngredientServings,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuDetailsAddContainer);
