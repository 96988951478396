import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import gglocationTypeEnum from '../../../enums/gglocationTypeEnum';

import gglocationShape from '../../../shapes/gglocationShape';

import './GglocationMapMarker.css';

function GglocationMapMarker({ gglocation, image }) {
  const openingHoursOnDate =
    gglocation.gglocationType === gglocationTypeEnum.STORE &&
    gglocation.selectedDateOpeningHours({ selectedDate: moment() });
  let openingHoursString = '';

  if (openingHoursOnDate) {
    const { openingDatetime, closingDatetime } = openingHoursOnDate;

    openingHoursString = `${moment(openingDatetime).format('ddd h:mma')} - ${moment(
      closingDatetime,
    ).format('ddd h:mma')}`;
  }

  return (
    <div className="GglocationMapMarkerWrapper">
      <div className="GglocationMapMarker">
        {image && (
          <div className="GglocationMapMarkerImage">
            <img src={image} alt="store" />
          </div>
        )}
        <div className="GglocationMapMarkerDetails">
          <div>{gglocation.name}</div>
          <div>
            {gglocation.address1 ?? ''} {gglocation.address2 ?? ''}
          </div>
          <div>{openingHoursString}</div>
        </div>
      </div>
    </div>
  );
}

GglocationMapMarker.propTypes = {
  gglocation: gglocationShape.isRequired,
  image: PropTypes.string,
};

GglocationMapMarker.defaultProps = {
  image: null,
};

export default GglocationMapMarker;
