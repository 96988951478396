import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import preferenceGroupDisplayStyleEnum from '../../enums/preferenceGroupDisplayStyleEnum';

import preferenceGroupShape from '../../shapes/preferenceGroupShape';

import PersonalizePreferenceGroupDropdown from './PersonalizePreferenceGroupDropdown';
import PersonalizePreferenceGroupRadioSwitch from './PersonalizePreferenceGroupRadioSwitch';

function PersonalizePreferenceGroup(props) {
  const { preferenceGroup } = props;

  return (
    <li className="PersonalizePreferenceGroup">
      <Typography variant="subtitle1" component="p">
        {preferenceGroup.name}
      </Typography>
      {preferenceGroup.webDisplayStyle === preferenceGroupDisplayStyleEnum.DROPDOWN && (
        <PersonalizePreferenceGroupDropdown {...props} />
      )}
      {preferenceGroup.webDisplayStyle === preferenceGroupDisplayStyleEnum.SWITCH && (
        <PersonalizePreferenceGroupRadioSwitch {...props} />
      )}
    </li>
  );
}

PersonalizePreferenceGroup.propTypes = {
  preferenceGroup: preferenceGroupShape.isRequired,
  selectedPreferenceId: PropTypes.number.isRequired,
  onPreferenceChange: PropTypes.func.isRequired,
};

export default PersonalizePreferenceGroup;
