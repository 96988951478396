import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as apiActions from '../../../actions/api';
import * as pageActions from '../../../actions/page';
import statusEnum from '../../../enums/statusEnum';

import Payment from '../../../components/SignIn/Payment';

import { paymentService } from '../../../services/api';

class PaymentContainer extends Component {
  static propTypes = {
    withToken: PropTypes.func.isRequired,
    addPaymentMethod: PropTypes.func.isRequired,
    status: PropTypes.number.isRequired,
  };

  componentDidMount() {
    const { addPaymentMethod } = this.props;

    addPaymentMethod();
  }

  get loading() {
    const { status } = this.props;

    return status === statusEnum.REQUESTED;
  }

  handleOnAddPaymentMethodClick = () => {
    const { withToken } = this.props;

    withToken(pageActions.openPaymentGateway());
  };

  render() {
    return (
      <Payment
        loading={this.loading}
        onAddPaymentMethodClick={this.handleOnAddPaymentMethodClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.user.status.addPaymentMethod,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      withToken: apiActions.withToken,
      addPaymentMethod: paymentService.addPaymentMethod.requestActionCreator,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);
