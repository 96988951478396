import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Button from '../../Button';
import Modal from '../../Modal';

import './GuestPaymentUnavailableModal.css';

function GuestPaymentUnavailableModal({ isModalOpen, hideModal }) {
  return (
    <Modal className="GuestPaymentUnavailableModal" show={isModalOpen}>
      <div className="modal-control" />
      <Modal.Body className="kale">
        <Typography className="title" variant="calafia2" color="primary">
          Oops! We&apos;re sorry...
        </Typography>
        <p>
          Your device&apos;s payment methods are not currently supported. Please log in to complete
          your purchase.
        </p>
        <Button className="btn-primary" onClick={hideModal}>
          Okay
        </Button>
      </Modal.Body>
    </Modal>
  );
}

GuestPaymentUnavailableModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default GuestPaymentUnavailableModal;
