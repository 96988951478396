import PropTypes from 'prop-types';

import userOrderItemShape from './userOrderItemShape';

const userOrderShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  orderStatus: PropTypes.string.isRequired,
  orderStatusId: PropTypes.number,
  gglocationId: PropTypes.number.isRequired,
  gglocationType: PropTypes.number.isRequired,
  timeslotSchedule: PropTypes.number,
  due: PropTypes.string,
  ready: PropTypes.string,
  pickupTime: PropTypes.string,
  paymentMethod: PropTypes.number,
  totalTax: PropTypes.number.isRequired,
  discountAmount: PropTypes.number,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  ordermenu: PropTypes.arrayOf(userOrderItemShape).isRequired,
});

/*
  parseFloat: totalTax, discountAmount, price
  Replace with id: ordermenu.menu
*/

export default userOrderShape;
