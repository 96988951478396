import React from 'react';
import PropTypes from 'prop-types';

import favoriteMenuItemShape from '../../../shapes/favoriteMenuItemShape';
import menuItemShape from '../../../shapes/menuItemShape';

import MenuItem from '../../../containers/Menu/MenuItem/MenuItemContainer';

import './UpsellMenuItem.css';

function UpsellMenuItem({ menuItem, isUpsellSelected, onToggleUpsellMenuItem }) {
  return (
    <MenuItem
      data={menuItem}
      isUpselling
      isUpsellSelected={isUpsellSelected}
      onClick={onToggleUpsellMenuItem}
    />
  );
}

UpsellMenuItem.propTypes = {
  menuItem: PropTypes.oneOfType([menuItemShape, favoriteMenuItemShape]).isRequired,
  isUpsellSelected: PropTypes.bool.isRequired,
  onToggleUpsellMenuItem: PropTypes.func.isRequired,
};

export default UpsellMenuItem;
