import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as foodUtils from '../../../utils/foodUtils';

import baseShape from '../../../shapes/baseShape';
import menuCategoryShape from '../../../shapes/menuCategoryShape';
import { baseTypeSettingsShape } from '../../../shapes/settingsShape';

import MenuNavbar from '../../../components/Menu/MenuNavbar';

const baseToItem = (base) => ({
  id: `b_${base.id}`,
  baseId: base.id,
  categoryId: null,
  name: base.name,
  category: false,
});

const menuCategoryToItem = (menuCategory) => ({
  id: `c_${menuCategory.id}`,
  baseId: menuCategory.baseType,
  categoryId: menuCategory.id,
  name: menuCategory.name,
  category: true,
});

const menuCategoriesToItems = (menuCategories) => menuCategories.map(menuCategoryToItem);

class MenuNavbarContainer extends Component {
  static propTypes = {
    menuCategories: PropTypes.arrayOf(menuCategoryShape).isRequired,
    bases: PropTypes.objectOf(baseShape).isRequired,
    baseTypeSettings: PropTypes.objectOf(baseTypeSettingsShape).isRequired,
    onMenuCategoryClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedNavbarItem: null,
    };

    this.subnavClicked = false;
  }

  getSubnavItems(navbarItem) {
    const { selectedNavbarItem } = this.state;
    const currentNavbarItem = navbarItem ?? selectedNavbarItem;

    if (!currentNavbarItem) {
      return null;
    }
    if (this.hasSubcategories(currentNavbarItem.baseId) === false) {
      return null;
    }

    const menuCategories = this.determineBaseMenuCategories(currentNavbarItem.baseId);
    const subnavItems = menuCategoriesToItems(menuCategories);

    return subnavItems;
  }

  get baseIds() {
    const { menuCategories } = this.props;

    const baseIds = [...new Set(menuCategories.map((menuCategory) => menuCategory.baseType))];

    return baseIds;
  }

  get bases() {
    const { bases } = this.props;

    return foodUtils.basesArray(bases).filter((base) => this.baseIds.includes(base.id));
  }

  get navbarItems() {
    let navbarItems = [];

    this.bases.map((base) => {
      if (this.hasSubcategories(base.id)) {
        navbarItems.push(baseToItem(base));
      } else {
        const menuCategories = this.determineBaseMenuCategories(base.id);
        navbarItems = navbarItems.concat(menuCategoriesToItems(menuCategories));
      }
      return base;
    });

    return navbarItems;
  }

  hasSubcategories = (baseId) => {
    const { baseTypeSettings } = this.props;

    return baseTypeSettings[baseId]?.subcategories;
  };

  determineBaseMenuCategories = (baseId) => {
    const { menuCategories } = this.props;

    return foodUtils.baseMenuCategories(baseId, menuCategories);
  };

  toggleNavbarItem = (navbarItem) => {
    const { selectedNavbarItem } = this.state;

    const hasActiveItem = selectedNavbarItem !== null;
    const isSameItem = hasActiveItem && selectedNavbarItem.id === navbarItem.id;
    const isSameBase = hasActiveItem && navbarItem.baseId === selectedNavbarItem.baseId;
    const isParentItem = navbarItem.category === false && isSameBase;

    if (hasActiveItem && (isSameItem || isParentItem)) {
      this.setState({ selectedNavbarItem: null });
    } else {
      this.setState({ selectedNavbarItem: navbarItem });
    }
  };

  goToCategory = (navbarItem) => {
    const { onMenuCategoryClick } = this.props;

    if (navbarItem.categoryId === null) {
      const subnavItems = this.getSubnavItems(navbarItem);
      onMenuCategoryClick(subnavItems[0].categoryId);
    } else {
      onMenuCategoryClick(navbarItem.categoryId);
    }
  };

  handleItemClick = (item) => {
    this.goToCategory(item);
    this.toggleNavbarItem(item);
  };

  handleItemBlur = (item, event) => {
    if (this.subnavClicked) {
      event.target.focus();
    } else {
      this.setState({ selectedNavbarItem: null });
    }

    this.subnavClicked = false;

    return true;
  };

  handleMenuSubnavClick = () => {
    this.subnavClicked = true;
  };

  render() {
    const { selectedNavbarItem } = this.state;

    return (
      <MenuNavbar
        navbarItems={this.navbarItems}
        subnavItems={this.getSubnavItems()}
        selectedNavbarItemId={selectedNavbarItem && selectedNavbarItem.id}
        onItemClick={this.handleItemClick}
        onItemBlur={this.handleItemBlur}
        onMenuSubnavClick={this.handleMenuSubnavClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  bases: state.api.bases,
  baseTypeSettings: state.api.settings.baseTypeSettings,
});

export default connect(mapStateToProps, undefined)(MenuNavbarContainer);
