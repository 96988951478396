import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import useWindowSize from '../../../hooks/useWindowSize';
import FontAwesome from '../../FontAwesome';

import './TNCSection.css';

function TNCSection({ title, text, collapse }) {
  const { width } = useWindowSize();
  const isMobile = width < 767;
  if (isMobile || collapse) {
    return (
      <Accordion className="TNCSection">
        <AccordionSummary
          expandIcon={<FontAwesome name="caret-down" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="Title"
        >
          <Typography variant="subtitle2" color="grey.light" fontWeight="600">
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="TNCDetails">
          <Typography variant="body2" color="grey.main">
            {text}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  }
  return (
    <div className="TNCSection">
      <div className="Title">
        <Typography variant="subtitle2" color="grey.light" fontWeight="600">
          {title}
        </Typography>
      </div>
      <div className="TNCDetails">
        <Typography variant="body2" color="grey.main">
          {text}
        </Typography>
      </div>
    </div>
  );
}

TNCSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  text: PropTypes.string.isRequired,
  collapse: PropTypes.bool,
};

TNCSection.defaultProps = {
  title: 'Terms & Conditions',
  collapse: false,
};

export default TNCSection;
