import React from 'react';
import { Typography } from '@mui/material';

import loadingSalad from '../LoadingScreen/loading-salad.gif';

function OrderSpinner() {
  return (
    <>
      <Typography variant="calafia2" color="primary">
        Sending order...
      </Typography>
      <img className="SaladAnimation" alt="" src={loadingSalad} />
    </>
  );
}

export default OrderSpinner;
