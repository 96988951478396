import { useMemo } from 'react';

// Hook
const useDeviceDetect = () => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const deviceData = useMemo(() => {
    const isAndroid = Boolean(userAgent.match(/Android/i));
    const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
    const isOpera = Boolean(userAgent.match(/Opera Mini/i));
    const isWindows = Boolean(userAgent.match(/IEMobile/i));
    const isSafari = Boolean(userAgent.match(/^((?!chrome|android|crios|fxios).)*safari/i));
    const isSSR = Boolean(userAgent.match(/SSR/i));

    const isMobile = isAndroid || isIos || isOpera || isWindows;
    const isDesktop = !isMobile && !isSSR;

    return {
      isAndroid,
      isIos,
      isOpera,
      isWindows,
      isSafari,
      isSSR,
      isMobile,
      isDesktop,
    };
  }, [userAgent]);

  return deviceData;
};

export default useDeviceDetect;
