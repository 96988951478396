import PropTypes from 'prop-types';

export const nutrientsRoundingSettingsShape = PropTypes.objectOf(PropTypes.number.isRequired);

export const nutritionalGroupsShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  sectionIds: PropTypes.arrayOf(PropTypes.number.isRequired),
});

export const nutritionalSettingsShape = PropTypes.shape({
  nutritionalGroups: PropTypes.objectOf(nutritionalGroupsShape).isRequired,
  nutrientsRoundingSettings: nutrientsRoundingSettingsShape,
});

export const cyoSectionGroupsShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  sectionIds: PropTypes.arrayOf(PropTypes.number.isRequired),
});

export const sectionSettingsShape = PropTypes.shape({
  ingredientDisplaySize: PropTypes.oneOf(['Big', 'Medium', 'Small']),
  inlineIngredientsPicker: PropTypes.bool.isRequired,
  cyoPagination: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired,
  removeable: PropTypes.bool.isRequired,
  replaceable: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  isSectionToggleable: PropTypes.bool.isRequired,
  showDietInformation: PropTypes.bool.isRequired,
  addCategories: PropTypes.shape({
    premade: PropTypes.arrayOf(PropTypes.number).isRequired,
    cyo: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  addMultiple: PropTypes.bool.isRequired,
  showDietButton: PropTypes.bool.isRequired,
  isPriceDisplayed: PropTypes.bool.isRequired,
  limit: PropTypes.shape({
    premade: PropTypes.shape({
      number: PropTypes.number.isRequired,
      extra: PropTypes.bool.isRequired,
    }),
    cyo: PropTypes.shape({
      number: PropTypes.number.isRequired,
      extra: PropTypes.bool.isRequired,
    }),
  }).isRequired,
});

export const baseTypeSettingsShape = PropTypes.shape({
  sections: PropTypes.objectOf(sectionSettingsShape),
  premade: PropTypes.bool.isRequired,
  subcategories: PropTypes.bool.isRequired,
});

const settingsShape = PropTypes.shape({
  nutritionalSettings: nutritionalSettingsShape,
  cyoSectionGroups: PropTypes.objectOf(cyoSectionGroupsShape).isRequired,
  sectionSettings: PropTypes.objectOf(sectionSettingsShape).isRequired,
  baseTypeSettings: PropTypes.objectOf(baseTypeSettingsShape).isRequired,
});

export default settingsShape;
