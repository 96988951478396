import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const dateTypes = [PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object];

function MomentCalendar(props) {
  const { children, options } = props;

  return <span>{moment(children).calendar(null, options)}</span>;
}

MomentCalendar.propTypes = {
  children: PropTypes.oneOfType(dateTypes),
  options: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
};

MomentCalendar.defaultProps = {
  children: null,
  options: {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    sameElse: 'DD/MM/YYYY',
  },
};

export default MomentCalendar;
