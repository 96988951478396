import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0d4520',
      light: '#f4eedc',
      dark: '#1f423c',
      contrastText: '#fdfdfd',
    },
    secondary: {
      main: '#ef6700',
      light: '#ccdb74',
      dark: '#c49876',
      pink: '#eb4c99',
      orange: '#ff6d6d',
      contrastText: '#0d4520',
    },
    grey: {
      main: '#2f2f2f',
      light: '#7a7a7a',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#FF1C1E',
      contrastText: '#FFD5D5',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '10px',
        textTransform: 'none',
      },
      label: {
        fontFamily: 'Sharp Grotesk SmBold25',
      },
    },
  },
  typography: {
    fontFamily: ['Work Sans', 'Times New Roman', 'serif'].join(),
    button: {
      fontFamily: ['Sharp Grotesk SmBold25'],
      fontSize: '0.95em',
      textTransform: 'unset',
    },
    bold: {
      fontFamily: 'Sharp Grotesk SmBold25',
    },
    h1: {
      fontFamily: ['Sharp Grotesk SmBold25', 'Times New Roman'].join(),
      fontSize: '3em',
    },
    h2: {
      fontFamily: ['Sharp Grotesk SmBold25', 'Times New Roman'].join(),
      fontSize: '2em',
    },
    h3: {
      fontFamily: ['Sharp Grotesk SmBold25', 'Times New Roman'].join(),
      fontSize: '1.5em',
      marginBottom: '0px',
    },
    h4: {
      fontFamily: ['Sharp Grotesk SmBold25', 'Times New Roman'].join(),
      fontSize: '1.3em',
    },
    h5: {
      fontFamily: ['Sharp Grotesk SmBold25', 'Times New Roman'].join(),
      fontSize: '1.15em',
    },
    h6: {
      fontFamily: ['Sharp Grotesk SmBold25', 'Times New Roman'].join(),
      fontSize: '1.0em',
      fontWeight: '400',
    },
    body1: {
      fontFamily: ['Work Sans', 'Times New Roman'].join(),
      fontSize: '1.2em',
    },
    body2: {
      fontFamily: ['Work Sans', 'Times New Roman'].join(),
      fontSize: '12px',
    },
    subtitle1: {
      fontFamily: ['Work Sans', 'Times New Roman'].join(),
      fontSize: '1em',
    },
    subtitle2: {
      fontFamily: ['Work Sans', 'Times New Roman'].join(),
      fontSize: '14px',
    },
    calafia1: {
      fontFamily: ['Calafia', 'Times New Roman'].join(),
      fontSize: '4.8em',
      fontWeight: '400',
      transform: 'rotate(-6deg)',
      '@media (max-width: 992px)': {
        fontSize: '3.1em',
      },
    },
    calafia2: {
      fontFamily: ['Calafia', 'Times New Roman'].join(),
      fontSize: '3.2em',
      fontWeight: '400',
      transform: 'rotate(-6deg)',
      '@media (max-width: 992px)': {
        fontSize: '2.5em',
      },
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          calafia1: 'h1',
          calafia2: 'h2',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#2f2f2f',
        },
      },
    },
  },
});

export default theme;
