import PropTypes from 'prop-types';

const pricingVariationShape = PropTypes.shape({
  baseType: PropTypes.number,
  menuUuid: PropTypes.number,
  ingredientCategory: PropTypes.number,
  ingredient: PropTypes.number,
  price: PropTypes.number,
  taxAmount: PropTypes.number,
  serving: PropTypes.number,
  limit: PropTypes.number,
  minimumServings: PropTypes.number,
  maximumServings: PropTypes.number,
  isAllServings: PropTypes.bool,
});

export default pricingVariationShape;
