import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import ingredientShape from '../../shapes/ingredientShape';

import Image from '../../containers/Image';
import CarbonFootprint from '../CarbonFootprint';
import Modal from '../Modal';
import NutritionalInfo from '../NutritionalInfo';

import './IngredientModal.css';

function IngredientModal(props) {
  const { ingredient, bulkQuantity, onHide } = props;
  if (ingredient === null) return null;

  return (
    <Modal className="IngredientModal NutritionalModal" show onHide={onHide}>
      <div className="modal-control">
        <ul>
          <li>
            <Modal.Dismiss className="btn-close-modal" />
          </li>
        </ul>
      </div>
      <Modal.Body className="kale">
        <div className="IngredientModalImage">
          <Image id={ingredient.image} context="original" oneSize />
          <Typography variant="body1" color="grey.main" className="IngredientName">
            {ingredient.name}
          </Typography>
          <CarbonFootprint ingredientId={ingredient.id} />
        </div>
        <div className="IngredientInfo">
          <Typography
            variant="body1"
            color="primary.dark"
            className="IngredientName"
            fontWeight="800"
            fontSize="1.5em"
          >
            {ingredient.name}
          </Typography>
          <CarbonFootprint ingredientId={ingredient.id} />
          <Typography variant="h5" color="primary.dark" className="NutritionalInformation">
            Nutritional Information
          </Typography>
          {ingredient && ingredient.desc && (
            <p className="IngredientDescription">{ingredient.desc}</p>
          )}
          <ul className="ServingWeight">
            <li>
              <Typography variant="subtitle2" fontWeight="600">
                Serving size
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle2">
                {ingredient.servingWeight * bulkQuantity}g
              </Typography>
            </li>
          </ul>
          <NutritionalInfo ingredientId={ingredient.id} bulkQuantity={bulkQuantity} />
          <Typography variant="subtitle2" component="p" className="NutritionalDisclaimer">
            <span>Disclaimer:</span> Nutritional information may vary depending on portioning.
          </Typography>
        </div>
      </Modal.Body>
    </Modal>
  );
}

IngredientModal.propTypes = {
  ingredient: ingredientShape,
  onHide: PropTypes.func.isRequired,
  bulkQuantity: PropTypes.number,
};

IngredientModal.defaultProps = {
  ingredient: null,
  bulkQuantity: 1,
};

export default IngredientModal;
