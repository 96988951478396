import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { getIsOrderTaxLineHidden } from '../../sagas/selectors';

import Currency from '../Currency';

import { SALES_TAX_NAME } from '../../settings';

import './OrderPaymentLine.css';

function OrderPaymentLine({ amount, tax, negative, total, children, typographyVariant }) {
  const isOrderTaxLineHidden = useSelector(getIsOrderTaxLineHidden);
  return (
    <ul className="OrderPaymentLine">
      <li>
        <Typography
          variant={typographyVariant}
          color="grey.main"
          fontWeight="700"
          className={classnames({ total })}
        >
          {children}
        </Typography>
        {tax > 0 && !isOrderTaxLineHidden && (
          <Typography variant="body2" className="Tax">
            Inclusive of
            <Currency value={tax} />
            {SALES_TAX_NAME}
          </Typography>
        )}
      </li>
      <li>
        <Typography variant={typographyVariant} className={classnames({ total })}>
          {negative && <span>&#8722;&nbsp;</span>}
          <Currency value={amount} />
        </Typography>
      </li>
    </ul>
  );
}

OrderPaymentLine.propTypes = {
  amount: PropTypes.number.isRequired,
  tax: PropTypes.number,
  negative: PropTypes.bool,
  total: PropTypes.bool,
  children: PropTypes.node,
  typographyVariant: PropTypes.string,
};

OrderPaymentLine.defaultProps = {
  tax: 0,
  negative: false,
  total: false,
  children: null,
  typographyVariant: 'subtitle2',
};

export default OrderPaymentLine;
