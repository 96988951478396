import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './CustomizeLeft.css';

function CustomizeLeft(props) {
  const { className, children } = props;
  return <div className={classnames('CustomizeLeft', className)}>{children}</div>;
}

CustomizeLeft.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
};

CustomizeLeft.defaultProps = {
  className: '',
};

export default CustomizeLeft;
