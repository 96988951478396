import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import PropTypes from 'prop-types';

import statusEnum from '../../enums/statusEnum';
import * as historyUtils from '../../utils/historyUtils';

import historyShape from '../../shapes/historyShape';

import LoadingScreen from '../../components/LoadingScreen';

import { cloudFrontService } from '../../services/api';

class ApiProvider extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    history: historyShape.isRequired,
    status: PropTypes.objectOf(PropTypes.number).isRequired,
  };

  get currentLocation() {
    const { history } = this.props;

    return history.locations[history.currentIndex];
  }

  get currentFlow() {
    return historyUtils.findRoutesFlow(this.currentLocation.flow);
  }

  get currentRoute() {
    return historyUtils.findLocationRoute(this.currentLocation, this.currentFlow.routes);
  }

  get isApiRequired() {
    return !this.currentRoute?.bypassLoader;
  }

  get isEverythingLoaded() {
    const { status } = this.props;
    const whitelist = [
      `${cloudFrontService.gglocations}`,
      `${cloudFrontService.gglocationsImages}`,
      `${cloudFrontService.images}`,
      `${cloudFrontService.ingredients}`,
      `${cloudFrontService.gglocationMenuGroups}`,
      `${cloudFrontService.menuGroups}`,
      `${cloudFrontService.basees}`,
      `${cloudFrontService.menuCategories}`,
      `${cloudFrontService.nutrients}`,
      `${cloudFrontService.ingredientCategories}`,
      `${cloudFrontService.ingredientGroups}`,
      `${cloudFrontService.preferenceGroups}`,
      `${cloudFrontService.tags}`,
      `${cloudFrontService.cyoSections}`,
      `${cloudFrontService.servingNumberVariations}`,
      `${cloudFrontService.pricingVariations}`,
      `${cloudFrontService.settings}`,
      `${cloudFrontService.unavailableIngredients}`,
    ];
    const filteredStatus = pick(status, whitelist);

    return Object.values(filteredStatus).every((substatus) =>
      [statusEnum.SUCCESS, statusEnum.RELOADING].includes(substatus),
    );
  }

  render() {
    const { children } = this.props;

    if (this.isApiRequired && !this.isEverythingLoaded) {
      return <LoadingScreen />;
    }
    return children;
  }
}

const mapStateToProps = (state) => ({
  history: state.history,
  status: {
    ...state.api.status,
  },
});

export default connect(mapStateToProps, null)(ApiProvider);
