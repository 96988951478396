import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import Autocomplete from '../Autocomplete';

import { esriService } from '../../services/api';

function AddressAutocomplete({ address, hasError, onFocus, onBlur, onAddressSelect }) {
  const [value, setValue] = useState(address);
  const [suggestions, setSuggestions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchSuggestions = async (newValue) => {
    const { response, error } = await esriService.suggest.call({ urlArgs: [newValue] });

    if (response && response.suggestions) {
      setSuggestions(
        response.suggestions.map(({ text, magicKey }) => ({ key: magicKey, value: text })),
      );
      setErrorMessage(null);
    } else if (error) {
      setSuggestions([]);
      setErrorMessage('Failed to fetch suggestions. Please try again.');
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value) {
      fetchSuggestions(event.target.value);
    } else {
      setSuggestions([]);
    }
  };

  const handleFocus = () => {
    onFocus();
  };

  const handleBlur = () => {
    setValue(address);
    /* Allow onSuggestionClick to fire before removing suggestions. */
    setTimeout(() => {
      setSuggestions([]);
      setErrorMessage(null);
      onBlur();
    }, 250);
  };

  const handleSuggestionClick = async (suggestion) => {
    /* TODO: Handle errors. */
    setValue(suggestion.value);
    setSuggestions([]);
    const { response, error } = await esriService.findSuggestionAddressCandidates.call({
      urlArgs: [suggestion.value, suggestion.key],
    });
    if (response && response.candidates) {
      onAddressSelect(response.candidates[0]);
      setErrorMessage(null);
    } else if (error) {
      setErrorMessage('Failed to find address. Please try again.');
    }
  };

  /* Update value when address changes. */
  useEffect(() => {
    setValue(address);
  }, [address]);

  return (
    <Autocomplete
      autoFocus
      className={classnames({ hasError })}
      value={value}
      suggestions={suggestions}
      error={errorMessage}
      placeholder="Enter address or postal code"
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onSuggestionClick={handleSuggestionClick}
    />
  );
}

AddressAutocomplete.propTypes = {
  hasError: PropTypes.bool,
  address: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onAddressSelect: PropTypes.func.isRequired,
};

AddressAutocomplete.defaultProps = {
  hasError: false,
  onFocus: noop,
  onBlur: noop,
};

export default AddressAutocomplete;
