import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import * as foodActions from '../../actions/food';
import * as orderItemActions from '../../actions/orderItem';
import * as selectors from '../../sagas/selectors';
import { getIngredientLevelTags, groupTagsByType } from '../../utils/foodUtils';

import Button from '../Button';
import DietTags from '../DietTags/DietTags';
import DietSelectButton from './DietSelectButton';

import './DietButton.css';

function DietButton({ type }) {
  // Setup styling for landing page
  // const isLandingPageOpen = useSelector(selectors.getIsLandingPageOpen);
  // const landingPageStyles = useSelector(selectors.getLandingPageStyles);

  const tags = useSelector(selectors.getTags);
  const highlightedTagIds = useSelector(selectors.getHighlightedTagIds);
  const highlightedIngredientServings = useSelector(selectors.getHighlightedIngredientServings);
  const ingredients = useSelector(selectors.getIngredients);
  const ingredientLevelTags = getIngredientLevelTags(tags);
  const highlightedIngredientLevelTagIds = highlightedTagIds.filter(
    (id) => ingredientLevelTags[id],
  );

  const { pathname } = useLocation();

  // The diet button has the same landing Page styling as signIn button
  // const dietButtonStyles = isLandingPageOpen ? landingPageStyles.topBar.signInStyle : {};

  const [isAllergenSelectOpen, setIsAllergenSelectOpen] = useState(false);
  const [isDietSelectOpen, setIsDietSelectOpen] = useState(false);

  const dispatch = useDispatch();

  const onDietChange = (event) => {
    dispatch(foodActions.updateHighlightedTagIds(event.target.value));
  };
  const onDietSave = () => {
    setIsAllergenSelectOpen(false);
    setIsDietSelectOpen(false);
    dispatch(
      foodActions.checkHighlightedTagIds({
        ingredientServings: pathname === '/menu' ? {} : highlightedIngredientServings,
        tags,
        ingredients,
      }),
    );
  };
  const onCheckboxClick = (event) => {
    // Event handled by @mui, stop propagate action from checkbox
    event.preventDefault();
    event.stopPropagation();
  };
  const onToggleChange = () => {
    if (highlightedIngredientLevelTagIds.length === 0) {
      return dispatch(foodActions.showDietModal());
    }
    dispatch(orderItemActions.restoreRemovedTagIngredients(highlightedTagIds));
    dispatch(foodActions.clearSelectedTagIds());
    return dispatch(foodActions.resetHighlightedTagIds());
  };
  const onResetClick = () => {
    dispatch(foodActions.clearSelectedTagIds());
    dispatch(foodActions.resetHighlightedTagIds());
  };

  const { allergenTags, dietTags } = groupTagsByType(tags);

  if (type === 'button') {
    return (
      <div className="DietButtonWrapper DietButtonVariantButton">
        <div>
          <DietSelectButton
            isSelectOpen={isAllergenSelectOpen}
            selectedIds={highlightedTagIds}
            items={allergenTags}
            label="Allergens"
            onOpen={() => setIsAllergenSelectOpen(true)}
            onChange={onDietChange}
            onItemClick={onCheckboxClick}
            onSave={onDietSave}
          />
          {dietTags.length > 0 && (
            <DietSelectButton
              isSelectOpen={isDietSelectOpen}
              selectedIds={highlightedTagIds}
              items={dietTags}
              label="Diets"
              onOpen={() => setIsDietSelectOpen(true)}
              onChange={onDietChange}
              onItemClick={onCheckboxClick}
              onSave={onDietSave}
            />
          )}
          {highlightedTagIds.length > 0 && (
            <Button className="btn-secondary DietResetButton" onClick={onResetClick}>
              Reset
            </Button>
          )}
        </div>
        <DietTags onlyIngredientLevelTags={false} />
      </div>
    );
  }

  return (
    <div className="DietButtonWrapper DietButtonVariantToggle">
      <div className="DietToggleTitleRow">
        <Typography variant="subtitle1" color="grey.main" fontWeight="600">
          Allergens?
        </Typography>
        <div className="DietButtonToggle">
          <label className="switch" htmlFor="DietToggleCheckbox">
            <input
              id="DietToggleCheckbox"
              type="checkbox"
              checked={highlightedIngredientLevelTagIds.length > 0}
              onChange={onToggleChange}
            />
            <div className="slider round" />
          </label>
        </div>
      </div>
      <div className="DietToggleTagRow">
        <DietTags />
      </div>
    </div>
  );
}

DietButton.propTypes = {
  type: PropTypes.oneOf(['button', 'toggle']),
};

DietButton.defaultProps = {
  type: 'button',
};

export default DietButton;
