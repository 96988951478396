import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Gglocation } from '@spq/redux-api-client-ecom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../actions/order';
import { selectScheduler } from '../../utils/gglocationUtils';

import TimePicker from '../../components/TimePicker';
import Scheduler from '../Scheduler';

class TimePickerContainer extends Component {
  static propTypes = {
    orderTime: PropTypes.instanceOf(moment).isRequired,
    scheduler: PropTypes.instanceOf([Scheduler, Gglocation]).isRequired,
    diningChoice: PropTypes.string.isRequired,
    setOrderTime: PropTypes.func.isRequired,
  };

  get hours() {
    const { scheduler, orderTime, diningChoice } = this.props;

    return scheduler.getEnabledHours({ orderTime, diningChoice });
  }

  get minutes() {
    const { scheduler, orderTime, diningChoice } = this.props;

    return scheduler.getEnabledMinutes({ orderTime, diningChoice });
  }

  handleHoursChange = (hour) => {
    const { scheduler, orderTime, diningChoice, setOrderTime } = this.props;

    const newOrderTime = orderTime.clone();
    newOrderTime.hours(parseInt(hour, 10));
    const minutes = scheduler.getEnabledMinutes({ orderTime: newOrderTime, diningChoice });
    if (!minutes.includes(newOrderTime.minutes())) {
      newOrderTime.minutes(minutes[0]);
    }

    setOrderTime(newOrderTime);
  };

  handleMinutesChange = (minute) => {
    const { orderTime, setOrderTime } = this.props;

    const newOrderTime = orderTime.clone();
    newOrderTime.minutes(parseInt(minute, 10));
    setOrderTime(newOrderTime);
  };

  render() {
    const { hours, minutes } = this;
    const { orderTime } = this.props;

    return (
      <TimePicker
        value={orderTime}
        hours={hours}
        minutes={minutes}
        onHoursChange={this.handleHoursChange}
        onMinutesChange={this.handleMinutesChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  scheduler: selectScheduler(state),
  orderTime: moment(state.order.orderTime),
  diningChoice: state.order.diningChoice,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setOrderTime: orderActions.setOrderTime,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TimePickerContainer);
