import PropTypes from 'prop-types';

const servingNumberVariationShape = PropTypes.shape({
  baseType: PropTypes.number,
  menuUuid: PropTypes.number,
  ingredientCategory: PropTypes.number,
  ingredient: PropTypes.number,
  defaultServingNumber: PropTypes.number,
});

export default servingNumberVariationShape;
