import React from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../Button';

import { ReactComponent as IconBack } from './icon-top-bar-back.svg';

import './BackButton.css';

function BackButton(props) {
  const { forceBackButtonShow, onClick, children } = props;

  return (
    <div className={classNames('BackButton', { forceBackButtonShow })}>
      <Button
        className="BackButtonMobile visible-xs visible-sm"
        fa="chevron-left"
        onClick={onClick}
      />
      <Button className="BackButtonDesktop visible-md visible-lg" onClick={onClick}>
        <IconBack />{' '}
        <Typography variant="body1" fontWeight="600">
          {children}
        </Typography>
      </Button>
    </div>
  );
}

BackButton.propTypes = {
  forceBackButtonShow: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

BackButton.defaultProps = {
  forceBackButtonShow: false,
  children: null,
};

export default BackButton;
