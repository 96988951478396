import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as apiActions from '../../../actions/api';
import * as orderActions from '../../../actions/order';
import brandEnum from '../../../enums/brandEnum';
import countryCodeEnum from '../../../enums/countryCodeEnum';
import * as selectors from '../../../sagas/selectors';

import paymentMethodShape from '../../../shapes/paymentMethodShape';

import EnvQueryComponent from '../../EnvQueryComponent';
import FontAwesome from '../../FontAwesome';
import Select from '../../Select';

import { paymentService } from '../../../services/api';

import dbsLogo from '../../../images/dbs-logo.png';
import iconGooglePay from './google-pay-mark_800.svg';
import iconAmex from './icon-amex.svg';
import iconApplePay from './icon-apple-pay.svg';
import iconMasterCard from './icon-master.svg';
import iconVisa from './icon-visa.svg';

import './SelectPaymentMethod.css';

function SelectPaymentMethod(props) {
  const {
    selectPaymentMethod,
    selectedPaymentMethod,
    defaultPaymentMethod,
    paymentMethods,
    applePayPaymentMethod,
  } = props;

  const promoCodeError = useSelector((state) => selectors.getLoyaltyError(state, 'promoCode'));
  const selectedPromoCode = useSelector(selectors.getSelectedPromoCode);
  const selectedUserRewardUuid = useSelector(selectors.getSelectedUserRewardUuid);
  const isGuestLogin = useSelector(selectors.getGuestLogin);
  const hasApplePay =
    window.ApplePaySession && window.ApplePaySession.canMakePayments() && applePayPaymentMethod?.id;

  useEffect(() => {
    if (!paymentMethods || paymentMethods.length === 0) {
      selectPaymentMethod({ id: 'add' });
      return;
    }

    let selectedMethod = defaultPaymentMethod;

    if (hasApplePay) {
      selectedMethod = applePayPaymentMethod;
    } else if (!defaultPaymentMethod) {
      const googlePayMethod = Object.values(paymentMethods).find(
        (method) => method.methodType === 'GOOGLEPAY',
      );

      const nonApplePayMethods = Object.values(paymentMethods).filter(
        (method) => method.methodType !== 'APPLEPAY',
      );

      // Sets to GOOGLEPAY or the first non-APPLEPAY method
      selectedMethod = googlePayMethod || nonApplePayMethods[0];
    }

    // fallback
    if (!selectedMethod) {
      selectedMethod = { id: 'add' };
    }

    selectPaymentMethod(selectedMethod);
  }, [isGuestLogin, paymentMethods, defaultPaymentMethod, applePayPaymentMethod, hasApplePay]);

  const handleSelect = (paymentMethodId) => {
    if (paymentMethodId !== 'add') {
      const paymentMethod = paymentMethods[paymentMethodId];
      selectPaymentMethod(paymentMethod);
    } else {
      selectPaymentMethod({ id: 'add' });
    }
  };

  const paymentMethodIcon = {
    MasterCard: iconMasterCard,
    Visa: iconVisa,
    Amex: iconAmex,
    ApplePay: iconApplePay,
  };

  const { cardBrand, methodType } = selectedPaymentMethod || {};

  return (
    <div className="PaymentMethodContainer">
      <div className="SelectPaymentMethod">
        {cardBrand && selectedPaymentMethod && selectedPaymentMethod?.id !== 'add' && (
          <img alt={cardBrand} className="hide" src={paymentMethodIcon[cardBrand]} />
        )}
        {methodType === 'GOOGLEPAY' && (
          <img alt="Google Pay mark" className="GooglePayIcon" src={iconGooglePay} />
        )}
        {methodType === 'APPLEPAY' && (
          <img alt="Apple Pay mark" src={iconApplePay} className="ApplePayIcon" />
        )}
        {methodType !== 'GOOGLEPAY' && methodType !== 'APPLEPAY' && (
          <FontAwesome className="CardIcon" name="credit-card" />
        )}
        <Select
          className={classnames(
            'PaymentMethodList',
            { isPaymentMethodSelected: selectedPaymentMethod && selectedPaymentMethod !== null },
            { newPaymentMethod: !!selectedPaymentMethod || selectedPaymentMethod?.id === 'add' },
          )}
          value={selectedPaymentMethod?.id || 'add'}
          onChange={handleSelect}
        >
          {hasApplePay && <option value={applePayPaymentMethod?.id}>Apple Pay</option>}
          {paymentMethods &&
            Object.values(paymentMethods)
              .filter((paymentMethod) => ![applePayPaymentMethod?.id].includes(paymentMethod.id))
              .map((paymentMethod) => {
                let displayLabel = '';
                if (paymentMethod.cardBrand) {
                  displayLabel = paymentMethod.cardBrand.toUpperCase() ?? '';
                } else if (paymentMethod.methodType === 'GOOGLEPAY') {
                  displayLabel = 'Google Pay';
                }

                return (
                  <option key={paymentMethod.id} value={paymentMethod.id}>
                    {displayLabel} {paymentMethod.label}
                  </option>
                );
              })}
          {!isGuestLogin && <option value="add">New payment method</option>}
        </Select>
      </div>
      {((selectedPromoCode && !promoCodeError) || selectedUserRewardUuid) && (
        <EnvQueryComponent
          countryCode={[countryCodeEnum.SINGAPORE]}
          brand={[brandEnum.SALADSTOP]}
          showOnMobile={false}
        >
          <div className="DbsLogoWrapper">
            <img src={dbsLogo} alt="dbs logo" className="DbsLogo" />
          </div>
        </EnvQueryComponent>
      )}
    </div>
  );
}

SelectPaymentMethod.propTypes = {
  selectedPaymentMethod: paymentMethodShape,
  selectPaymentMethod: PropTypes.func.isRequired,
  paymentMethods: PropTypes.objectOf(paymentMethodShape).isRequired,
  defaultPaymentMethod: paymentMethodShape.isRequired,
  applePayPaymentMethod: paymentMethodShape,
};

SelectPaymentMethod.defaultProps = {
  selectedPaymentMethod: null,
  applePayPaymentMethod: null,
};

const mapStateToProps = (state) => ({
  paymentMethods: state.api.paymentMethods,
  selectedPaymentMethod: state.order.selectedPaymentMethod,
  defaultPaymentMethod: state.api.defaultPaymentMethod,
  applePayPaymentMethod: state.api.applePayPaymentMethod,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      withToken: apiActions.withToken,
      selectPaymentMethod: orderActions.selectPaymentMethod,
      addPaymentMethod: paymentService.addPaymentMethod.requestActionCreator,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SelectPaymentMethod);
