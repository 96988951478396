import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ingredientShape from '../../../shapes/ingredientShape';

import Image from '../../../containers/Image';
import OrderItemPrice from '../../OrderItemPrice';
import FavoriteButton from '../FavoriteButton';

import './OrderItem.css';

function OrderItem(props) {
  const {
    className,
    size,
    image,
    name,
    labels,
    quantity,
    originalPrice,
    totalPrice,
    isDiscounted,
    isFavorite,
    isButtonHidden,
    isFavoriteDisabled,
    ingredients,
    ingredientServings,
    onFavorite,
  } = props;

  const favoriteButtonElement = onFavorite && !isFavoriteDisabled && (
    <FavoriteButton isFavorite={isFavorite} onClick={onFavorite} />
  );

  return (
    <li className={classnames('OrderItem', className, size)}>
      <ul>
        <li>
          <div className="order-img">
            <Image id={image} />
          </div>
        </li>
        <li>
          <div className="OrderItemTitle">
            <Typography variant="subtitle1" color="grey.main">
              {name}
            </Typography>
            {size === 'Medium' && favoriteButtonElement}
          </div>
          <Typography variant="body2" color="grey.main" className="OrderItemIngredients">
            {ingredients.length > 0 && <strong>Ingredients: </strong>}
            {ingredients.map((ingredient) => (
              <span key={ingredient.id} className="addComma">
                {ingredient.name}
                {ingredientServings[ingredient.id] > 1 && (
                  <span>
                    &nbsp;(
                    {ingredientServings[ingredient.id]})
                  </span>
                )}
              </span>
            ))}
          </Typography>
          <ul>
            <li>
              <Typography variant="body2" color="grey.main">
                <span className="bold">Qty:</span> {quantity}
              </Typography>
            </li>
            {labels.length > 0 && (
              <li className="Label hide-empty">
                <Typography variant="body2" color="grey.main">
                  <span className="bold">Label: </span>
                  {labels.map(
                    (label) =>
                      label && (
                        <span key={label} className="addComma">
                          {label}
                        </span>
                      ),
                  )}
                </Typography>
              </li>
            )}
            <li>
              <OrderItemPrice
                originalPrice={originalPrice}
                totalPrice={totalPrice}
                isDiscounted={isDiscounted}
              />
            </li>
          </ul>
        </li>
        {onFavorite && !isButtonHidden && size === 'Small' && <li>{favoriteButtonElement}</li>}
      </ul>
    </li>
  );
}

OrderItem.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['Small', 'Medium']),
  image: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  quantity: PropTypes.number.isRequired,
  originalPrice: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  isDiscounted: PropTypes.bool.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  isButtonHidden: PropTypes.bool,
  isFavoriteDisabled: PropTypes.bool.isRequired,
  ingredients: PropTypes.arrayOf(ingredientShape).isRequired,
  ingredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
  onFavorite: PropTypes.func,
};

OrderItem.defaultProps = {
  className: '',
  size: 'Medium',
  isButtonHidden: false,
  onFavorite: null,
};

export default OrderItem;
