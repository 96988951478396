import PropTypes from 'prop-types';

const storeBusyShape = PropTypes.objectOf(
  PropTypes.shape({
    storeId: PropTypes.number.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    extraTime: PropTypes.number.isRequired,
    isManuallyCancelled: PropTypes.bool.isRequired,
  }),
);

export default storeBusyShape;
