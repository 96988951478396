import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as userActions from '../../actions/user';
import { setSearchParams } from '../../utils/historyUtils';

import routerShape, { routerLocationShape } from '../../shapes/routerShape';

import InvalidTokenError from '../../components/InvalidTokenError';
import withRouter from '../WithRouter';

class InvalidTokenErrorContainer extends Component {
  static propTypes = {
    isInvalidTokenModalShown: PropTypes.bool.isRequired,
    hideInvalidTokenModal: PropTypes.func.isRequired,
    beforeSignIn: PropTypes.func.isRequired,
    afterSignIn: PropTypes.func.isRequired,

    /* React Router props */
    location: routerLocationShape.isRequired,
    router: routerShape.isRequired,
  };

  get isModalOpen() {
    const { isInvalidTokenModalShown } = this.props;

    return isInvalidTokenModalShown;
  }

  handleRelogin = () => {
    const { hideInvalidTokenModal, location, beforeSignIn, afterSignIn, router } = this.props;

    hideInvalidTokenModal();

    const { pathname, search } = location;
    beforeSignIn({ pathname, search: setSearchParams(search, { showCart: false }) });
    afterSignIn({ pathname, search: setSearchParams(search, { showCart: false }) });
    router.push('/signIn');
  };

  render() {
    const { isModalOpen } = this;

    return <InvalidTokenError isModalOpen={isModalOpen} onRelogin={this.handleRelogin} />;
  }
}

const mapStateToProps = (state) => ({
  isInvalidTokenModalShown: state.user.isInvalidTokenModalShown,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideInvalidTokenModal: userActions.hideInvalidTokenModal,
      beforeSignIn: userActions.beforeSignIn,
      afterSignIn: userActions.afterSignIn,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvalidTokenErrorContainer));
