import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './LoyaltyTab.css';

function LoyaltyTab({
  isSelected,
  isDisabled,
  className,
  activeIcon,
  inactiveIcon,
  title,
  onSelect,
}) {
  return (
    <button
      type="button"
      disabled={isDisabled}
      className={classNames('LoyaltyTab', className, { isSelected, isDisabled })}
      onClick={onSelect}
    >
      <div className="TabImageWrapper">
        <img src={isSelected && !isDisabled ? activeIcon : inactiveIcon} alt={title} />
      </div>
      <div>{title}</div>
    </button>
  );
}

LoyaltyTab.propTypes = {
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  activeIcon: PropTypes.node.isRequired,
  inactiveIcon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

LoyaltyTab.defaultProps = {
  isSelected: false,
  isDisabled: false,
  className: '',
};

export default LoyaltyTab;
