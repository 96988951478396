import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import * as selectors from '../../sagas/selectors';
import * as commonUtils from '../../utils/commonUtils';

function Currency({ value, variant, typographyVariant, decimalPlaces }) {
  const displaySettings = useSelector((state) =>
    selectors.selectCurrencySettings(state, { variant }),
  );

  const shortenedValue = value / 10 ** displaySettings.divisor;
  const roundedValue =
    (shortenedValue * displaySettings.roundingPrecision) / displaySettings.roundingPrecision;
  const truncatedValue = roundedValue.toFixed(decimalPlaces ?? displaySettings.decimalPlaces);
  const formattedValue = commonUtils.addThousandsDivider({
    value: truncatedValue,
    divider: displaySettings.thousandSeparator || ',',
  });
  const isNegativeValue = value < 0;
  const finalFormattedValue = isNegativeValue ? formattedValue.slice(1) : formattedValue;

  return (
    <Typography className="Currency" variant={typographyVariant}>
      {isNegativeValue && '-'}
      {displaySettings && displaySettings.prefix}
      {finalFormattedValue}
      {displaySettings && displaySettings.suffix}
    </Typography>
  );
}

Currency.propTypes = {
  value: PropTypes.number.isRequired,
  variant: PropTypes.string,
  typographyVariant: PropTypes.string,
  decimalPlaces: PropTypes.number,
};

Currency.defaultProps = {
  variant: 'general',
  typographyVariant: 'inherit',
  decimalPlaces: null,
};

export default Currency;
