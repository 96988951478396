import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push, replace } from 'redux-first-history';

import { getCurrentLocation } from '../../sagas/selectors';

/* A custom wrapper to add router functionality for component,
   leverages the usage of react-router v6 hooks and redux-first-history
   methods, replacement to the deprecated withRouter from react-router */

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useSelector(getCurrentLocation);
    const params = useParams();
    const dispatch = useDispatch();

    const handlePush = (...args) => {
      dispatch(push(...args));
    };

    const handleReplace = (...args) => {
      dispatch(replace(...args));
    };

    return (
      <Component
        {...props}
        router={{
          location,
          params,
          push: handlePush,
          replace: handleReplace,
        }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter;
