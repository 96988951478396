import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import { without } from 'lodash';
import PropTypes from 'prop-types';

import Button from '../../Button';

import './Address.css';

function Address({ isSelected, isSelectable, customerAddress, onSelectAddress, onRemoveAddress }) {
  const onSelectCurrentAddress = () => onSelectAddress(customerAddress.id);
  const onRemoveCurrentAddress = () => onRemoveAddress(customerAddress.id);
  const addressLabel = without(
    [customerAddress.streetName, customerAddress.postcode],
    null,
    undefined,
  ).join(', ');

  return (
    <div className={classnames('AddressCard', { selectable: isSelectable })}>
      <ul className={classnames('AddressElements', { selected: isSelected })}>
        <li>
          <span className="AddressText">
            <Typography variant="subtitle2" color="grey.main" className="addressName">
              {customerAddress.name || customerAddress.label}
            </Typography>
            {customerAddress.name && (
              <Typography variant="subtitle2" className="address" color="grey.main">
                {addressLabel}
              </Typography>
            )}
          </span>
          {isSelectable ? (
            <Button className="btn-primary select" onClick={onSelectCurrentAddress}>
              Select
            </Button>
          ) : (
            <Button fa="trash" onClick={onRemoveCurrentAddress} />
          )}
        </li>
      </ul>
    </div>
  );
}

Address.propTypes = {
  isSelected: PropTypes.bool,
  isSelectable: PropTypes.bool.isRequired,
  customerAddress: PropTypes.objectOf(PropTypes.object).isRequired,
  onSelectAddress: PropTypes.func.isRequired,
  onRemoveAddress: PropTypes.func.isRequired,
};

Address.defaultProps = {
  isSelected: false,
};

export default Address;
