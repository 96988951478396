import React from 'react';
import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import * as selectors from '../../sagas/selectors';
import * as foodUtils from '../../utils/foodUtils';

import './CarbonFootprint.css';

function CarbonFootprint({ ingredientId }) {
  const { nutrientsRoundingSettings } = useSelector(selectors.getApiNutritionalSettings);
  const nutrients = useSelector(selectors.getNutrients);
  const sortedNutrients = foodUtils.sortedNutrients(nutrients);
  const carbonFootprintIndex = sortedNutrients.findIndex(
    (nutrient) => nutrient.isCarbonFootprint === true,
  );
  const nutrientAmounts = useSelector((state) =>
    selectors.selectIngredientNutrientAmounts(state, ingredientId),
  );
  const isNutritionalInformationHidden = useSelector(selectors.getIsNutritionalInformationHidden);

  if (carbonFootprintIndex < 0 || isNutritionalInformationHidden) {
    return null;
  }

  const carbonFootprintId = sortedNutrients[carbonFootprintIndex]?.id;

  let carbonFootprintAmount = 0;

  if (carbonFootprintId >= 0) {
    carbonFootprintAmount = nutrientAmounts[carbonFootprintId];
  }

  if (carbonFootprintAmount === 0 || carbonFootprintAmount === undefined) {
    return null;
  }

  const nutrientName = sortedNutrients[carbonFootprintIndex].measurementUnit;

  return (
    <div className="CarbonFootprint">
      <span className="CarbonFootprintTitle">{sortedNutrients[carbonFootprintIndex].name}:</span>{' '}
      <FormattedNumber
        value={carbonFootprintAmount}
        minimumFractionDigits={nutrientsRoundingSettings[carbonFootprintId]}
        maximumFractionDigits={nutrientsRoundingSettings[carbonFootprintId]}
      />
      {nutrientName}
    </div>
  );
}

CarbonFootprint.propTypes = {
  ingredientId: PropTypes.number,
};

CarbonFootprint.defaultProps = {
  ingredientId: undefined,
};

export default CarbonFootprint;
