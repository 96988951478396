import * as scrollspyActions from '../actions/scrollspy';

const defaultState = Object.freeze({
  activeIngredientCategoryId: undefined,
});

// eslint-disable-next-line default-param-last
export default function scrollspyReducer(state = defaultState, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return Object.freeze({
        ...state,
        scrollspyActions: undefined,
      });
    case scrollspyActions.SET_ACTIVE_INGREDIENT_CATEGORY_ID:
      return Object.freeze({
        ...state,
        activeIngredientCategoryId: action.ingredientCategoryId,
      });
    default:
      return state;
  }
}
