import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../actions/order';
import * as orderItemActions from '../../actions/orderItem';
import * as pageActions from '../../actions/page';
import orderItemStatusEnum from '../../enums/orderItemStatusEnum';
import * as selectors from '../../sagas/selectors';
import * as orderItemUtils from '../../utils/orderItemUtils';
import { isUpsellOngoing } from '../../utils/upsellUtils';

import favoriteMenuItemShape from '../../shapes/favoriteMenuItemShape';
import menuItemShape from '../../shapes/menuItemShape';
import orderItemShape from '../../shapes/orderItemShape';
import routerShape from '../../shapes/routerShape';
import upsellCategoryShape from '../../shapes/upsellCategoryShape';

import TitledMenuSection from '../../components/TitledMenuSection';
import withRouter from '../WithRouter';

class FavoriteMenuSectionContainer extends Component {
  static propTypes = {
    title: PropTypes.string,
    skipPersonalization: PropTypes.bool,
    hideUnavailable: PropTypes.bool,
    orderGglocationId: PropTypes.string,
    orderItem: orderItemShape.isRequired,
    favoriteMenuItems: PropTypes.objectOf(favoriteMenuItemShape).isRequired,
    createOrderItemFromFavoriteMenuItemId: PropTypes.func.isRequired,
    saveOrderItem: PropTypes.func.isRequired,
    showCart: PropTypes.func.isRequired,
    openMenuItemPage: PropTypes.func.isRequired,
    openCartAfterTimeSelected: PropTypes.func.isRequired,
    openMenuItemPageAfterTimeSelected: PropTypes.func.isRequired,
    upsellCategories: PropTypes.objectOf(upsellCategoryShape).isRequired,
    menuGroupId: PropTypes.number.isRequired,
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    setFavMenuChangedId: PropTypes.func.isRequired,

    router: routerShape.isRequired,
  };

  static defaultProps = {
    title: null,
    skipPersonalization: true,
    hideUnavailable: false,
    orderGglocationId: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      waitingForOrderItem: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { orderItem } = this.props;
    const orderItemUpdated =
      /*
        Compare UUID instead of menu item id since
        it changes even if the menu item is selected again.
      */
      orderItem.id !== prevProps.orderItem.id;

    if (orderItemUpdated) {
      this.handleOrderItemUpdated(orderItem);
    }
  }

  get menuItems() {
    const { favoriteMenuItems } = this.props;
    return Object.values(favoriteMenuItems);
  }

  handleClick = (data) => {
    const { createOrderItemFromFavoriteMenuItemId, setFavMenuChangedId, favoriteMenuItems } =
      this.props;

    createOrderItemFromFavoriteMenuItemId({ favoriteMenuItemId: data.id });

    if (orderItemUtils.isOrderItemChanged({ menuItems: favoriteMenuItems, menuItemsId: data.id })) {
      setFavMenuChangedId({ favMenuChangedId: data.id });
    } else {
      this.setState({ waitingForOrderItem: true });
    }
  };

  handleOrderItemUpdated = (nextOrderItem) => {
    const {
      skipPersonalization,
      orderGglocationId,
      saveOrderItem,
      showCart,
      openMenuItemPage,
      openCartAfterTimeSelected,
      openMenuItemPageAfterTimeSelected,
      menuGroupId,
      upsellCategories,
      menuItems,
      router,
    } = this.props;
    const { waitingForOrderItem } = this.state;

    if (waitingForOrderItem === false) return;

    this.setState({ waitingForOrderItem: false });

    const orderItemValid =
      nextOrderItem.orderItemStatus === orderItemStatusEnum.TENTATIVE || nextOrderItem.menuItemId;

    if (orderItemValid === false) return;

    if (orderGglocationId) {
      if (skipPersonalization) {
        saveOrderItem();
        if (isUpsellOngoing(upsellCategories, menuItems, menuGroupId)) {
          router.push({ pathname: '/upsell' });
        } else {
          showCart();
        }
      } else {
        openMenuItemPage();
      }
      return;
    }

    if (skipPersonalization) {
      openCartAfterTimeSelected();
    } else {
      openMenuItemPageAfterTimeSelected();
    }

    router.push({ pathname: '/locations' });
  };

  render() {
    const { title, skipPersonalization, hideUnavailable } = this.props;

    return (
      <TitledMenuSection
        title={title}
        menuItems={this.menuItems}
        skipPersonalization={skipPersonalization}
        hideUnavailable={hideUnavailable}
        onClick={this.handleClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  orderGglocationId: state.order.gglocationId,
  orderItem: state.orderItem,
  menuItems: state.api.menuItems,
  selectedTagIds: state.food.selectedTagIds,
  favoriteMenuItems: state.api.favoriteMenuItems,
  favoriteMenuItemIds: [...state.api.favoriteMenuItemIds],
  upsellCategories: state.api.upsellCategories,
  menuGroupId: selectors.getCurrentMenuGroupId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveOrderItem: orderItemActions.saveOrderItem,
      createOrderItemFromFavoriteMenuItemId: orderItemActions.createOrderItemFromFavoriteMenuItemId,
      showCart: orderActions.showCart,
      openMenuItemPage: pageActions.openMenuItemPage,
      openUpsellPage: pageActions.openUpsellScreen,
      returnToMenu: pageActions.returnToMenu,
      openCartAfterTimeSelected: orderActions.openCartAfterTimeSelected,
      openMenuItemPageAfterTimeSelected: orderActions.openMenuItemPageAfterTimeSelected,
      setFavMenuChangedId: orderItemActions.setFavMenuChangedId,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FavoriteMenuSectionContainer),
);
