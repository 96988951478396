import PropTypes from 'prop-types';

const historyLocationShape = PropTypes.shape({
  pathname: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  action: PropTypes.string,
  flow: PropTypes.string,
});

export default historyLocationShape;
