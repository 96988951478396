import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import orderTypeEnum from '../../../enums/orderTypeEnum';
import statusEnum from '../../../enums/statusEnum';
import * as selectors from '../../../sagas/selectors';
import { setSearchParams } from '../../../utils/historyUtils';

import TimePicker from '../../../containers/TimePicker';
import Button from '../../Button';
import FontAwesome from '../../FontAwesome';
import MomentCalendar from '../../MomentCalendar';
import Select, { Option } from '../../Select';
import TimeRangePicker from '../../TimeRangePicker/TimeRangePicker';
import TimeSlotLabel from '../TimeSlotLabel';
import TimeSlotPicker from '../TimeSlotPicker';

import './ScheduleTimePicker.css';

function ScheduleTimePicker(props) {
  const {
    isDeliveryOrder,
    isStationOrder,
    menuItemId,
    showCart,
    canPlaceInstantOrder,
    pickupDates,
    selectedType,
    selectedTime,
    noStoreError,
    onTypeSelected,
    onDateSelected,
  } = props;

  const { search } = useSelector(selectors.getCurrentLocation);
  const orderPreviewStatus = useSelector(selectors.getOrderPreviewStatus);
  const isOrderPreviewRequested = [statusEnum.REQUESTED, statusEnum.RELOADING].includes(
    orderPreviewStatus,
  );
  const isLandingPageOrder = useSelector(selectors.getIsLandingPageOrder);
  const landingPageSlug = useSelector(selectors.getCurrentLandingPageSlug);
  const nextPathLink = isLandingPageOrder ? `/site/${landingPageSlug}` : '/menu';

  return (
    <div className="ScheduleTimePicker kale">
      <div className="Content">
        {isStationOrder && <TimeSlotLabel />}
        <p className="Header">
          Please select a {isDeliveryOrder ? ' delivery time' : ' pick up time'}
        </p>
        {!isStationOrder && (
          <>
            {canPlaceInstantOrder && (
              <div>
                <label
                  className={classnames('ScheduleTimePickerOption', {
                    active: selectedType === orderTypeEnum.INSTANT,
                  })}
                  htmlFor="instant"
                >
                  <input
                    id="instant"
                    name="instant"
                    type="radio"
                    checked={selectedType === orderTypeEnum.INSTANT}
                    onChange={() => onTypeSelected(orderTypeEnum.INSTANT)}
                  />
                  <span className="indicator">
                    <FontAwesome name="check" />
                  </span>
                  <span className="content">
                    <Typography variant="subtitle2">ASAP</Typography>
                  </span>
                </label>
                <span className="OptionSeparator">or</span>
              </div>
            )}
            <label
              className={classnames('ScheduleTimePickerOption', {
                active: selectedType === orderTypeEnum.SCHEDULED,
              })}
              htmlFor="scheduled"
            >
              <input
                id="scheduled"
                name="scheduled"
                type="radio"
                checked={selectedType === orderTypeEnum.SCHEDULED}
                onChange={() => onTypeSelected(orderTypeEnum.SCHEDULED)}
              />
              <span className="indicator">
                <FontAwesome name="check" />
              </span>
              <span className="content">
                <Typography variant="subtitle2">Schedule for later</Typography>
              </span>
            </label>
          </>
        )}
        {selectedType === orderTypeEnum.SCHEDULED && (
          <div className={classnames('ScheduleTimePickerDate', { isDelivery: isDeliveryOrder })}>
            <Select
              id="pickup-date"
              value={moment(selectedTime).startOf('day').valueOf()}
              onChange={onDateSelected}
            >
              {pickupDates.map((pickupDate) => (
                <Option
                  key={moment(pickupDate).startOf('day').valueOf()}
                  value={moment(pickupDate).startOf('day').valueOf()}
                >
                  <MomentCalendar
                    options={{
                      sameDay: '[Today], DD/MM',
                      nextDay: '[Tomorrow], DD/MM',
                      nextWeek: 'dddd, DD/MM',
                      sameElse: 'dddd, DD/MM',
                    }}
                  >
                    {pickupDate}
                  </MomentCalendar>
                </Option>
              ))}
            </Select>
            {isDeliveryOrder && <TimeRangePicker />}
            {!isDeliveryOrder && isStationOrder && <TimeSlotPicker />}
            {!isDeliveryOrder && !isStationOrder && <TimePicker />}
          </div>
        )}
        {noStoreError && <p className="ErrorText">{noStoreError}</p>}
        <Typography variant="subtitle2" fontStyle="italic" className="FreshnessMessage">
          Our meals are best consumed within 2 hours
        </Typography>
      </div>
      <div className="ActionButton">
        <Button
          className="Split btn-next"
          loading={isOrderPreviewRequested}
          disabled={!!noStoreError}
          link={
            !isOrderPreviewRequested && {
              pathname: nextPathLink,
              search: setSearchParams(search, { menuItemId, showCart }),
            }
          }
        >
          <span className="Text">Next</span>
        </Button>
      </div>
    </div>
  );
}

ScheduleTimePicker.propTypes = {
  isDeliveryOrder: PropTypes.bool.isRequired,
  isStationOrder: PropTypes.bool,
  menuItemId: PropTypes.string,
  showCart: PropTypes.bool,
  canPlaceInstantOrder: PropTypes.bool.isRequired,
  pickupDates: PropTypes.arrayOf(PropTypes.instanceOf(moment)).isRequired,
  noStoreError: PropTypes.string,
  selectedType: PropTypes.oneOf([orderTypeEnum.INSTANT, orderTypeEnum.SCHEDULED]).isRequired,
  selectedTime: PropTypes.instanceOf(moment),
  onTypeSelected: PropTypes.func.isRequired,
  onDateSelected: PropTypes.func.isRequired,
};

ScheduleTimePicker.defaultProps = {
  isStationOrder: false,
  menuItemId: undefined,
  showCart: false,
  noStoreError: null,
  selectedTime: null,
};

export default ScheduleTimePicker;
