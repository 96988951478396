import { fork, select, take } from 'redux-saga/effects';

import * as browserActions from '../actions/browser';

export function* awaitBrowserCheckPassed() {
  const isBrowserCheckPassed = yield select((state) => state.browser.isBrowserCheckPassed);
  if (!isBrowserCheckPassed) {
    yield take(browserActions.BROWSER_CHECK_PASSED);
  }
}

export function* takeBrowserCheckPassed(callback) {
  yield fork(function* browserCheckLoop() {
    yield awaitBrowserCheckPassed();
    yield callback();
  });
}
