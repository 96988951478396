import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';

import './Counter.css';

function Counter(props) {
  const { value, onIncrement, onDecrement } = props;
  return (
    <div className="Counter">
      <Button fa="minus" className="Decrement" onClick={onDecrement} />
      <span className="Value">{value}</span>
      <Button fa="plus" className="Increment" onClick={onIncrement} />
    </div>
  );
}

Counter.propTypes = {
  value: PropTypes.number.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
};

export default Counter;
