import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { landingPageMenuStyles } from '../../../shapes/landingPageStyleShapes';
import menuItemShape from '../../../shapes/menuItemShape';
import { routerActionShape } from '../../../shapes/routerShape';

import MenuCategory from '../../../components/Menu/MenuCategory';

class MenuCategoryContainer extends Component {
  static propTypes = {
    className: PropTypes.string,
    navigationKey: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    menuItems: PropTypes.arrayOf(menuItemShape).isRequired,
    landingPageStyles: landingPageMenuStyles.isRequired,
    isLandingPageOpen: PropTypes.bool.isRequired,
    scrollable: PropTypes.bool,
    hideUnavailable: PropTypes.bool,
    onClick: PropTypes.func,
    onInit: PropTypes.func,

    action: routerActionShape.isRequired,
  };

  static defaultProps = {
    className: '',
    navigationKey: null,
    name: null,
    description: null,
    scrollable: false,
    hideUnavailable: false,
    onClick: null,
    onInit: null,
  };

  get visitedFromHistory() {
    const { action } = this.props;

    return action === 'POP';
  }

  get visible() {
    const { scrollable } = this.props;

    return scrollable || this.visitedFromHistory;
  }

  get landingPageStyles() {
    const { landingPageStyles, isLandingPageOpen } = this.props;

    return isLandingPageOpen ? landingPageStyles : {};
  }

  render() {
    const {
      className,
      navigationKey,
      name,
      description,
      menuItems,
      scrollable,
      hideUnavailable,
      onClick,
      onInit,
    } = this.props;

    return (
      <MenuCategory
        className={className}
        navigationKey={navigationKey}
        name={name}
        description={description}
        menuItems={menuItems}
        landingPageStyles={this.landingPageStyles}
        visible={this.visible}
        scrollable={scrollable}
        hideUnavailable={hideUnavailable}
        onClick={onClick}
        onInit={onInit}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  landingPageStyles: state.landingPage.styling.menuListing,
  isLandingPageOpen: state.page.isLandingPageOpen,
  location: state.router.location,
  action: state.router.action,
});

export default connect(mapStateToProps, undefined)(MenuCategoryContainer);
