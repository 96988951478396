import React from 'react';
import classnames from 'classnames';
import { PropTypes } from 'prop-types';

import Button from '../Button';

import { ReactComponent as AddIconLogo } from './addIcon.svg';

import './AddItemButton.css';

function AddItemButton({ className, text, onClick, hasIcon }) {
  return (
    <Button className={classnames('AddItemButton', className)} onClick={onClick}>
      {hasIcon && <AddIconLogo className="AddIconLogo" />}
      {text}
    </Button>
  );
}

AddItemButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  hasIcon: PropTypes.bool,
};

AddItemButton.defaultProps = {
  className: null,
  hasIcon: true,
};

export default AddItemButton;
