import PropTypes from 'prop-types';

const promoFlagShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  badgeText: PropTypes.string.isRequired,
  colorCode: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
});

export default promoFlagShape;
