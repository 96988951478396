import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderItemActions from '../../actions/orderItem';

import preferenceGroupShape from '../../shapes/preferenceGroupShape';

import PersonalizePreferenceGroup from '../../components/PersonalizePreferenceGroup';

class PersonalizePreferenceGroupContainer extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    preferenceGroup: preferenceGroupShape.isRequired,
    selectedPreferenceId: PropTypes.number.isRequired,
    updatePreference: PropTypes.func.isRequired,
  };

  handlePreferenceChange = (value) => {
    const { id, preferenceGroup, updatePreference } = this.props;

    const preferenceId = parseInt(value, 10);
    updatePreference({ id, preferenceGroupId: preferenceGroup.id, preferenceId });
  };

  render() {
    const { preferenceGroup, selectedPreferenceId } = this.props;

    return (
      <PersonalizePreferenceGroup
        preferenceGroup={preferenceGroup}
        selectedPreferenceId={selectedPreferenceId}
        onPreferenceChange={this.handlePreferenceChange}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const personalSetting = state.orderItem.personalSettings.find(({ id }) => ownProps.id === id);

  return {
    preferenceGroup: state.api.preferenceGroups[ownProps.preferenceGroupId],
    selectedPreferenceId: personalSetting.preferences[ownProps.preferenceGroupId],
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updatePreference: orderItemActions.updatePreference,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PersonalizePreferenceGroupContainer);
