import React from 'react';
import PropTypes from 'prop-types';

import './PieCountdown.css';

function PieCountdown(props) {
  const { animationDuration } = props;
  const animationStyle = {
    animationDuration: `${animationDuration}ms`,
  };

  return (
    <div className="wrapper">
      <div className="leftHalf" style={animationStyle} />
      <div className="spinner" style={animationStyle} />
      <div className="rightHalf" style={animationStyle} />
    </div>
  );
}

PieCountdown.propTypes = {
  animationDuration: PropTypes.number,
};

PieCountdown.defaultProps = {
  animationDuration: 4000,
};

export default PieCountdown;
