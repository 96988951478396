import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import IngredientCategories from '../../../containers/IngredientCategories';
import MenuDetailsAddHeader from '../../../containers/MenuDetails/MenuDetailsAddHeader';

import './CyoIngredientsPicker.css';

function CyoIngredientsPicker(props) {
  const { menuItemId, sectionId, categoryIds, isHeaderShown, excludeNullBase, onLimitExhausted } =
    props;

  return (
    <div className={classnames('CyoIngredientsPicker', { isHeaderShown })}>
      {isHeaderShown && (
        <MenuDetailsAddHeader type="cyo" sectionId={sectionId} categoryIds={categoryIds} />
      )}
      <div className="CyoBody">
        <IngredientCategories
          menuItemId={menuItemId}
          sectionId={sectionId}
          categoryIds={categoryIds}
          type="cyo"
          excludeNullBase={excludeNullBase}
          saveAutomatically
          showDietTags={false}
          onLimitExhausted={onLimitExhausted}
        />
      </div>
    </div>
  );
}

CyoIngredientsPicker.propTypes = {
  menuItemId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  categoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isHeaderShown: PropTypes.bool.isRequired,
  excludeNullBase: PropTypes.bool,
  onLimitExhausted: PropTypes.func.isRequired,
};

CyoIngredientsPicker.defaultProps = {
  excludeNullBase: false,
};

export default CyoIngredientsPicker;
