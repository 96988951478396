import { connect } from 'react-redux';

import * as historyUtils from '../../../utils/historyUtils';

import TopBarTitle from '../../../components/TopBar/TopBarTitle';

const mapStateToProps = (state) => ({
  header: (() => {
    const currentLocation = state.history.locations[state.history.currentIndex];

    // If it's a landing page. Show the landing page title.
    if (currentLocation.pathname.includes('/site/')) {
      return {
        desktop: {
          title: state.landingPage.title || '',
        },
        mobile: {
          title: state.landingPage.title || '',
        },
      };
    }
    const currentFlow = historyUtils.findRoutesFlow(currentLocation.flow);
    const currentRoute = historyUtils.findLocationRoute(currentLocation, currentFlow.routes);

    return historyUtils.resolveHeader(currentRoute.header, state, currentLocation, currentRoute);
  })(),
});

export default connect(mapStateToProps, undefined)(TopBarTitle);
