import PropTypes from 'prop-types';

import claimShape from './claimShape';

const scanHistoryCollectionShape = PropTypes.shape({
  progress: PropTypes.string.isRequired,
  rewardId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  instance: claimShape,
  rewardStatus: PropTypes.oneOf(['AW', 'IP', 'NO', 'CL']),
  email: PropTypes.string.isRequired,
  verified: PropTypes.bool.isRequired,
});

const scanHistoryShape = PropTypes.shape({
  ref: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  collections: PropTypes.arrayOf(scanHistoryCollectionShape),
  status: PropTypes.string.isRequired,
  isManualAward: PropTypes.bool.isRequired,
  receiptNo: PropTypes.string.isRequired,
});

export default scanHistoryShape;
