import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import FontAwesome from '../FontAwesome';

function FacebookLogin(props) {
  const { onLogin } = props;
  return (
    <Button type="button" className="btn-primary btn-facebook" onClick={onLogin}>
      <FontAwesome name="facebook-f" type="brands" />
      &nbsp; Continue with Facebook
    </Button>
  );
}

FacebookLogin.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default FacebookLogin;
