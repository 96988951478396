import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import gglocationShape from '../../../shapes/gglocationShape';
import userOrderShape from '../../../shapes/userOrderShape';

import CurrentOrder from '../../../components/Landing/CurrentOrder';

class CurrentOrderContainer extends Component {
  static propTypes = {
    order: userOrderShape.isRequired,
    gglocations: PropTypes.objectOf(gglocationShape).isRequired,
  };

  get gglocation() {
    const { order, gglocations } = this.props;

    return gglocations[`${order.gglocationType}_${order.gglocationId}`];
  }

  get deliveryAddress() {
    const { order } = this.props;

    return order.deliveryAddress?.address;
  }

  get totalPrice() {
    const { order } = this.props;

    return order.price - order.discountAmount;
  }

  render() {
    const { order } = this.props;

    return (
      <CurrentOrder
        id={order.uuid}
        status={order.orderStatusId}
        gglocation={this.gglocation}
        orderItems={order.ordermenu}
        diningChoice={order.diningChoice}
        orderNumber={order.orderNumber}
        deliveryAddress={this.deliveryAddress}
        totalPrice={this.totalPrice}
        pickupTime={order.pickupTime}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  gglocations: state.api.gglocations,
});

export default connect(mapStateToProps, undefined)(CurrentOrderContainer);
