import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import * as selectors from '../../../sagas/selectors';

import gglocationShape from '../../../shapes/gglocationShape';

import Gglocation from '../../../containers/Gglocation';
import Button from '../../Button';

import './GglocationsList.css';

function GglocationsList(props) {
  const {
    gglocations,
    highlightedGglocationId,
    menuItemId,
    hideRequestStationUrl,
    onGglocationClick,
  } = props;

  const isStationFlow = useSelector(selectors.getIsStationFlow);
  const requestStationUrl = useSelector(selectors.getRequestStationUrl);

  return (
    <div className="GglocationsList">
      <ul className="store-list">
        {gglocations.map((gglocation) => (
          <Gglocation
            key={gglocation.id}
            isHighlighted={gglocation.id === highlightedGglocationId}
            gglocation={gglocation}
            menuItemId={menuItemId}
            onClick={onGglocationClick}
          />
        ))}
        {isStationFlow && requestStationUrl && !hideRequestStationUrl && (
          <div className="requestStationUrlLabel">
            <Typography variant="subtitle2" color="grey.main" fontWeight="600">
              Want a Station near you?
            </Typography>
            <Typography variant="subtitle2" color="grey.main">
              Let us know{' '}
              <Button
                className="blue-link"
                href={requestStationUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                here
              </Button>
              !
            </Typography>
          </div>
        )}
      </ul>
    </div>
  );
}

GglocationsList.propTypes = {
  gglocations: PropTypes.arrayOf(gglocationShape).isRequired,
  highlightedGglocationId: PropTypes.string,
  menuItemId: PropTypes.string,
  hideRequestStationUrl: PropTypes.bool,
  onGglocationClick: PropTypes.func.isRequired,
};

GglocationsList.defaultProps = {
  highlightedGglocationId: null,
  menuItemId: undefined,
  hideRequestStationUrl: false,
};

export default GglocationsList;
