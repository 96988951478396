import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import OrderDelay from '../../containers/OrderDelay';
import Modal from '../Modal';
import OrderPriceSpinner from '../OrderPriceSpinner';
import OrderSpinner from '../OrderSpinner';

import './OrderStatusModal.css';

function OrderStatusModal(props) {
  const { show, showCountdown, showSpinner, showAwaitPriceSpinner } = props;
  return (
    <Modal
      size="sm"
      className="OrderStatusModal modal-small"
      dialogClassName={classnames({
        'modal-sm-70vh': !showAwaitPriceSpinner,
        'modal-dialog-transparent': showAwaitPriceSpinner,
      })}
      show={show}
    >
      <Modal.Body className="kale">
        {showAwaitPriceSpinner && <OrderPriceSpinner />}
        {showCountdown && <OrderDelay />}
        {showSpinner && <OrderSpinner />}
      </Modal.Body>
    </Modal>
  );
}

OrderStatusModal.propTypes = {
  show: PropTypes.bool.isRequired,
  showCountdown: PropTypes.bool.isRequired,
  showSpinner: PropTypes.bool.isRequired,
  showAwaitPriceSpinner: PropTypes.bool.isRequired,
};

export default OrderStatusModal;
