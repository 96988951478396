import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import { setIsEmailVerificationPopUpShown } from '../../../actions/user';
import accountStatusEnum from '../../../enums/accountStatusEnum';
import * as selectors from '../../../sagas/selectors';

import Button from '../../Button';

import { auth2Service } from '../../../services/api';

import { ReactComponent as IconClose } from '../../../images/xmark-solid-brown.svg';

import './EmailVerificationPopUp.css';

function EmailVerificationPopUp() {
  const isEmailVerificationPopUpShown = useSelector(selectors.getIsEmailVerificationPopUpShown);
  const user = useSelector(selectors.getUser);
  const email = useSelector(selectors.getEmail);

  const dispatch = useDispatch();

  const onSendEmailVerification = () => {
    dispatch(auth2Service.reverifyEmail.requestActionCreator({ email }));
    dispatch(setIsEmailVerificationPopUpShown(false));
  };
  const onClosePopUp = () => {
    dispatch(setIsEmailVerificationPopUpShown(false));
  };

  useEffect(() => {
    if (!!user.token && user.userStatus === accountStatusEnum.PENDING) {
      dispatch(setIsEmailVerificationPopUpShown(true));
    } else {
      dispatch(setIsEmailVerificationPopUpShown(false));
    }
  }, [user.token, dispatch, user.userStatus]);

  return (
    <div
      className="EmailVerificationPopUp"
      style={{ transform: isEmailVerificationPopUpShown ? '' : 'translateY(-100vh)' }}
    >
      <div className="popUpWrapper">
        <div id="EmailVerificationPopUpBody" className="popUp">
          <div>
            <span>
              <Typography variant="subtitle2" color="grey.main" style={{ display: 'inline' }}>
                Please confirm your email via the link that was sent to <strong>{email}</strong>.
              </Typography>
              <Button className="blue-link" onClick={onSendEmailVerification}>
                Resend email
              </Button>
            </span>
          </div>
          <Button className="closePopUp" onClick={onClosePopUp}>
            <IconClose />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EmailVerificationPopUp;
