import PropTypes from 'prop-types';

import menuItemShape from './menuItemShape';

const userOrderItemShape = PropTypes.shape({
  menu: menuItemShape.isRequired,
  personalSettings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      preferences: PropTypes.objectOf(PropTypes.number).isRequired,
    }),
  ),
  discountAmount: PropTypes.number.isRequired,
  pretaxPrice: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  isFavoriteDisabled: PropTypes.bool.isRequired,
  totalTax: PropTypes.number.isRequired,
});

export default userOrderItemShape;
