import { chain, isEqual, partition } from 'lodash';

import orderItemStatusEnum from '../enums/orderItemStatusEnum';
import previewStageEnum from '../enums/previewStageEnum';

import { groupSurcharges, parseDeliveryAddress } from './commonUtils';
import { calculateOrderItemHash, calculateOrderPreviewItemHash } from './orderItemUtils';

export function parseBackendIngredientBreakdown(backendIngredientBreakdown) {
  return chain(backendIngredientBreakdown)
    .map((ingredientBreakdown) => ({
      ...ingredientBreakdown,
      price: parseFloat(ingredientBreakdown.price),
      serving: parseFloat(ingredientBreakdown.serving),
      singlePrice: parseFloat(ingredientBreakdown.singlePrice),
      total: parseFloat(ingredientBreakdown.total),
      discountAmount: parseFloat(ingredientBreakdown.discountAmount),
    }))
    .value();
}

export const parseOrderPreviewOrderItemPrices = (orderItem) => ({
  ...orderItem,
  menu: {
    ...orderItem.menu,
    ingredientBreakdown: parseBackendIngredientBreakdown(orderItem.menu.ingredientBreakdown),
  },
  price: parseFloat(orderItem.price),
  pretaxPrice: parseFloat(orderItem.pretaxPrice),
  totalTax: parseFloat(orderItem.totalTax),
  totalPrice: parseFloat(orderItem.total),
  discountAmount: parseFloat(orderItem.discountAmount),
});

export const prepareOrderPreviewOrderItems = (orderItems) =>
  orderItems.map((orderItem) => parseOrderPreviewOrderItemPrices(orderItem));

export const parseOrderPreviewResponse = ({ result }) => {
  const { response, error } = result;

  if (error) {
    return result;
  }

  const price = parseFloat(response.price);
  const pretaxPrice = parseFloat(response.pretaxPrice);
  const totalTax = parseFloat(response.totalTax);
  const totalPrice = parseFloat(response.total);
  const discountAmount = parseFloat(response.discountAmount);

  const surcharges = groupSurcharges(response.surcharges);
  const deliveryAddress = parseDeliveryAddress(response.deliveryAddress);

  return {
    response: {
      ...response,
      totalTax: undefined,
      total: undefined,
      pretaxPrice: undefined,
      discountAmount: undefined,
      discountName: undefined,
      discountSource: undefined,
      surcharges,
      deliveryAddress,
      deliveryDistance: response.deliveryDistance,
      estimatedDelivery: response.estimatedDelivery,
      pricing: {
        price,
        pretaxPrice,
        totalTax,
        totalPrice,
        discountAmount,
        discountName: response.discountName,
        discountSource: response.discountSource,
      },
    },
  };
};

export const extractCurrentOrderItemOrderPreview = ({
  orderPreviewMenuItems,
  currentOrderItem,
}) => {
  if (!currentOrderItem) return [[], orderPreviewMenuItems];
  return partition(
    orderPreviewMenuItems,
    (item) => calculateOrderItemHash(currentOrderItem) === calculateOrderPreviewItemHash(item),
  );
};

export const mergeOrderItemPrices = (orderItem) =>
  chain(orderItem)
    .reduce(
      (accumulator, item) => {
        Object.keys(accumulator).forEach((key) => (accumulator[key] += item[key]));
        accumulator.discountName = item.discountName;
        accumulator.discountSource = item.discountSource;

        return accumulator;
      },
      {
        discountName: null,
        discountSource: null,
        totalPrice: 0,
        totalTax: 0,
        discountAmount: 0,
        pretaxPrice: 0,
        price: 0,
      },
    )
    .value();

export const getOrderItemsForPreviewStage = ({ order, orderItem, stage }) => {
  if ([previewStageEnum.READY, previewStageEnum.COMPLETED].includes(stage)) {
    return [...Object.values(order.orderItems)];
  }
  // Only include current order item for selected and highlighted stages
  return [...Object.values(order.orderItems), orderItem];
};

export const getOrderItemsStageData = ({ order, orderItem, stage }) =>
  chain(getOrderItemsForPreviewStage({ order, orderItem, stage }))
    .without(undefined, null)
    .filter(({ orderItemStatus }) => orderItemStatus > orderItemStatusEnum.TENTATIVE)
    .sortBy(['id'])
    .map((item) => {
      const ingredientServingsKey =
        stage !== previewStageEnum.HIGHLIGHTED
          ? previewStageEnum.SELECTED
          : previewStageEnum.HIGHLIGHTED;

      // Provide identifying hash for each order item for comparison
      return `${calculateOrderItemHash(item, ingredientServingsKey)}*${
        item.personalSettings.length
      }`;
    })
    .value();

/* Check if order item set of 2 preview stage is identical */
export const isPreviewStagesEqual = (stageA, stageB, { order, orderItem }) =>
  isEqual(
    getOrderItemsStageData({ order, orderItem, stage: stageA }),
    getOrderItemsStageData({ order, orderItem, stage: stageB }),
  );

export const getOrderItemHashPrices = (orderItems) =>
  chain(orderItems)
    .groupBy((item) => calculateOrderPreviewItemHash(item))
    .mapValues((itemGroup) => mergeOrderItemPrices(itemGroup))
    .value();

export const parseReplaceOrderPreviewResponse = ({ result }) => {
  const { response, error } = result;

  if (error) {
    return result;
  }

  const deliveryAddress = parseDeliveryAddress(response.deliveryAddress);

  return {
    response: {
      ...response,
      diningChoice: response.diningChoice,
      customerAddressId: response.addressId,
      deliveryAddress,
      deliveryDistance: response.deliveryDistance,
      estimatedDelivery: response.estimatedDelivery,
    },
  };
};
