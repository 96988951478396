import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Currency from '../Currency';

import './OrderItemPrice.css';

function OrderItemPrice({ originalPrice, totalPrice, isDiscounted }) {
  return (
    <span className={classnames('OrderItemPrice', { isDiscounted })}>
      <span className="OriginalPrice">
        <Currency value={originalPrice} typographyVariant="subtitle1" />
      </span>
      {isDiscounted && (
        <span className="DiscountedPrice">
          <Currency value={totalPrice} typographyVariant="subtitle1" />
        </span>
      )}
    </span>
  );
}

OrderItemPrice.propTypes = {
  originalPrice: PropTypes.number,
  totalPrice: PropTypes.number,
  isDiscounted: PropTypes.bool.isRequired,
};

OrderItemPrice.defaultProps = {
  originalPrice: null,
  totalPrice: null,
};

export default OrderItemPrice;
