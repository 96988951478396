import React from 'react';
import { Typography } from '@mui/material';

function DismissScanTooltip() {
  return (
    <>
      <Typography
        variant="subtitle1"
        color="grey.main"
        fontWeight="600"
        sx={{ marginBottom: '4px' }}
      >
        Visit SaladStop! on mobile
      </Typography>
      <Typography variant="body2" color="grey.main">
        Receipt scanning is only available on mobile devices.
      </Typography>
    </>
  );
}

export default DismissScanTooltip;
