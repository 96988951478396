import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as scrollUtils from '../../utils/scrollUtils';

import ingredientCategoryShape from '../../shapes/ingredientCategoryShape';

import CategoryPaginationBar from '../../components/CategoryPaginationBar';

class CategoryPaginationBarContainer extends Component {
  static propTypes = {
    categoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,

    activeIngredientCategoryId: PropTypes.number.isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
  };

  get ingredientCategoriesList() {
    const { categoryIds, ingredientCategories } = this.props;

    return categoryIds
      .map((categoryId) => ingredientCategories[categoryId])
      .sort((a, b) => a.order - b.order);
  }

  render() {
    const { activeIngredientCategoryId } = this.props;

    return (
      <CategoryPaginationBar
        activeIngredientCategoryId={activeIngredientCategoryId}
        ingredientCategoriesList={this.ingredientCategoriesList}
        onCategorySelected={scrollUtils.scrollToIngredientCategoryId}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  activeIngredientCategoryId: state.scrollspy.activeIngredientCategoryId,
  ingredientCategories: state.api.ingredientCategories,
});

export default connect(mapStateToProps, undefined)(CategoryPaginationBarContainer);
