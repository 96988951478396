import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from '../../../actions/user';

import withRouter from '../../../containers/WithRouter';
import Button from '../../Button';
import Checkbox from '../../Checkbox';

import { auth2Service } from '../../../services/api';

import './MarketingOptIn.css';

function MarketingOptIn() {
  const [isMarketingOptInChecked, setMarketingOptInChecked] = useState(true);

  const dispatch = useDispatch();

  const onMarketingOpCheckboxClick = () => setMarketingOptInChecked(!isMarketingOptInChecked);

  const updateMarketingOptIn = () => {
    dispatch(
      auth2Service.changeMarketingOptIn.requestActionCreator({
        marketingOptIn: isMarketingOptInChecked,
      }),
    );
    dispatch(userActions.closeMarketingOptIn());
  };

  return (
    <div className="MarketingOptIn">
      <Checkbox
        isChecked={isMarketingOptInChecked}
        label={<span>Sign me up to stay up-to-date with delicious deals and new menu drops!</span>}
        onChange={onMarketingOpCheckboxClick}
      />
      <Button className="btn-primary" onClick={updateMarketingOptIn}>
        Next
      </Button>
    </div>
  );
}

export default withRouter(MarketingOptIn);
