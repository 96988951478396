import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ingredientNutrientAmountsShape from '../../shapes/ingredientNutrientAmountsShape';
import ingredientShape from '../../shapes/ingredientShape';

import IngredientChoice from './IngredientChoice';

import { ENERGY_ID } from '../../settings';

import './IngredientsPicker.css';

function IngredientsPicker(props) {
  const {
    className,
    addMultiple,
    limitsExhausted,
    ingredients,
    defaultIngredientServings,
    highlightedIngredientServings,
    ingredientPrices,
    ingredientNutrientAmounts,
    bulkQuantity,
    size,
    unavailableIngredientIds,
    isPriceDisplayed,
    onIngredientToggle,
    onIngredientDetailsClick,
    onIngredientIncrement,
    onIngredientDecrement,
  } = props;

  const ingredientChoiceProps = (ingredient) => {
    const quantity = highlightedIngredientServings[ingredient.id];
    const nutrients = ingredientNutrientAmounts[ingredient.id] || {};
    const isUnavailable = unavailableIngredientIds.includes(ingredient.id);
    const energy = parseInt(nutrients[ENERGY_ID] * bulkQuantity, 10);

    return {
      id: ingredient.id,
      name: ingredient.name,
      image: ingredient.image,
      price: ingredientPrices[ingredient.id],
      includedServings: defaultIngredientServings[ingredient.id],
      energy,
      quantity,
      disabled: !quantity && limitsExhausted(ingredient.id),
      unavailable: isUnavailable,
      onClick: () => onIngredientToggle(ingredient.id),
      onDetailsClick: (event) => onIngredientDetailsClick(ingredient.id, event),
    };
  };

  const ingredientChoicesMultiple = () =>
    ingredients.map((ingredient) => (
      <IngredientChoice.Multiple
        key={ingredient.id}
        {...ingredientChoiceProps(ingredient)}
        onIncrement={() => onIngredientIncrement(ingredient.id)}
        onDecrement={() => onIngredientDecrement(ingredient.id)}
      />
    ));

  const mobileSize = ['Big', 'Medium'].includes(size) ? 'Small' : size;

  const ingredientChoicesSingle = () =>
    ingredients.map((ingredient) => (
      <IngredientChoice.Single
        key={ingredient.id}
        {...ingredientChoiceProps(ingredient)}
        isPriceDisplayed={isPriceDisplayed}
        size={mobileSize}
      />
    ));

  if (ingredients.length === 0) return null;

  return (
    <div className={classnames('IngredientsPicker', 'kale', className)}>
      {addMultiple ? ingredientChoicesMultiple() : ingredientChoicesSingle()}
    </div>
  );
}

IngredientsPicker.propTypes = {
  className: PropTypes.string,
  addMultiple: PropTypes.bool,
  limitsExhausted: PropTypes.func,
  ingredients: PropTypes.arrayOf(ingredientShape).isRequired,
  defaultIngredientServings: PropTypes.objectOf(PropTypes.number),
  highlightedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
  ingredientPrices: PropTypes.objectOf(PropTypes.number).isRequired,
  ingredientNutrientAmounts: ingredientNutrientAmountsShape.isRequired,
  bulkQuantity: PropTypes.number,
  size: PropTypes.oneOf(['Big', 'Medium', 'Small', 'ExtraSmall']),
  unavailableIngredientIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isPriceDisplayed: PropTypes.bool.isRequired,
  onIngredientToggle: PropTypes.func.isRequired,
  onIngredientDetailsClick: PropTypes.func.isRequired,
  onIngredientIncrement: PropTypes.func.isRequired,
  onIngredientDecrement: PropTypes.func.isRequired,
};

IngredientsPicker.defaultProps = {
  className: '',
  defaultIngredientServings: {},
  addMultiple: false,
  bulkQuantity: 1,
  limitsExhausted: () => false,
  size: 'Big',
};

export default IngredientsPicker;
