/* eslint-disable indent */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import validator from 'email-validator';
import PropTypes from 'prop-types';

import withRouter from '../../../containers/WithRouter';
import Button from '../../Button';
import EmailAddress from '../../EmailAddress';
import InputAlertText from '../../InputAlertText';
import FacebookConnectedLogo from './FacebookConnectedLogo';

import { auth2Service } from '../../../services/api';

import './FacebookRequestEmail.css';

function FacebookRequestEmail({ location }) {
  const [email, setEmail] = useState('');
  const [error, setErrorIgnored] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const signUpWithEmail = () => {
    const { state } = location;
    const { accessToken } = state;

    dispatch(auth2Service.facebookLogin.requestActionCreator({ accessToken, email }));
  };

  const handleSubmit = () => {
    if (!validator.validate(email)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
      signUpWithEmail();
    }
  };

  return (
    <div className="FacebookRequestEmail">
      <Typography
        variant="h2"
        color="secondary.light"
        className="SignInTitle visible-lg visible-md"
        fontWeight="900"
      >
        Almost done!
      </Typography>
      <FacebookConnectedLogo />
      <p className="SignInCopy">Please enter your email address to complete account creation</p>
      <EmailAddress
        className="form-register"
        type="text"
        placeholder="Your email address"
        value={email}
        onChange={handleChange}
      />
      {isEmailValid === false && <InputAlertText noIcon>Invalid email address</InputAlertText>}
      {error && <InputAlertText noIcon>{error}</InputAlertText>}
      <Button className="btn-primary center" onClick={handleSubmit}>
        Update account
      </Button>
    </div>
  );
}

FacebookRequestEmail.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(FacebookRequestEmail);
