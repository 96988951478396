import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../actions/order';
import diningChoiceEnum from '../../enums/diningChoiceEnum';
import * as foodUtils from '../../utils/foodUtils';

import customerAddressShape from '../../shapes/customerAddressShape';
import gglocationShape from '../../shapes/gglocationShape';
import menuCategoryShape from '../../shapes/menuCategoryShape';
import menuItemShape from '../../shapes/menuItemShape';
import orderShape from '../../shapes/orderShape';

import Menu from '../../components/Menu';

class MenuContainer extends Component {
  static propTypes = {
    order: orderShape.isRequired,
    menuCategories: PropTypes.objectOf(menuCategoryShape).isRequired,
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    menuGroups: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
    gglocationMenuGroups: PropTypes.objectOf(PropTypes.number).isRequired,
    selectedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    setLandingPage: PropTypes.func.isRequired,
    isLoyaltyEnabled: PropTypes.bool,
    gglocations: PropTypes.objectOf(gglocationShape).isRequired,
    customerAddresses: PropTypes.objectOf(customerAddressShape).isRequired,
  };

  static defaultProps = {
    isLoyaltyEnabled: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedCategory: null,
    };

    this.sectionElements = {};
  }

  componentDidMount() {
    const { setLandingPage } = this.props;

    setLandingPage('');
  }

  get gglocationId() {
    const { order } = this.props;

    return order.gglocationId;
  }

  get availableMenuCategories() {
    const { menuCategories } = this.props;
    const menuCategoriesArray = foodUtils.getMenuCategoriesArray(menuCategories);

    const availableMenuCategoriesArray = foodUtils.filterCategoriesWithMenuItems(
      menuCategoriesArray,
      this.availableGglocationMenuItems,
    );

    return availableMenuCategoriesArray;
  }

  get gglocationMenuGroupId() {
    const { gglocationMenuGroups } = this.props;

    return gglocationMenuGroups[this.gglocationId];
  }

  get gglocationMenuGroup() {
    const { menuGroups } = this.props;

    /*
      If there is no menu group, pick the first one (this is usually the case because
      we have global) so there's only one menu group
    */
    return menuGroups[this.gglocationMenuGroupId] || Object.values(menuGroups)[0];
  }

  get gglocationMenuItems() {
    const { menuItems } = this.props;

    return this.gglocationMenuGroup?.map((menuItemId) => menuItems[menuItemId]);
  }

  get availableGglocationMenuItems() {
    const { selectedTagIds } = this.props;

    const menuItemsMatchingTags = foodUtils.menuItemsMatchingTags(
      this.gglocationMenuItems,
      selectedTagIds,
    );
    const sortedMenuItems = foodUtils.sortedMenuItems(menuItemsMatchingTags);

    return sortedMenuItems;
  }

  get isDelivery() {
    const { order } = this.props;

    return order.diningChoice === diningChoiceEnum.DELIVERY;
  }

  get addressLabelName() {
    const { order, gglocations, customerAddresses } = this.props;

    if (!this.isDelivery) {
      return gglocations[order.gglocationId]?.name;
    }
    return customerAddresses[order.customerAddressId]?.label;
  }

  handleCategoryInit = (id, sectionElement) => {
    this.sectionElements[id] = sectionElement;
  };

  handleCategoryClick = (id) => {
    const selectedCategory = this.sectionElements[id];

    this.setState({ selectedCategory });
  };

  render() {
    const { isLoyaltyEnabled } = this.props;
    const { selectedCategory } = this.state;

    return (
      <Menu
        menuCategories={this.availableMenuCategories}
        menuItems={this.availableGglocationMenuItems}
        selectedCategory={selectedCategory}
        isDelivery={this.isDelivery}
        addressLabelName={this.addressLabelName}
        onMenuCategoryInit={this.handleCategoryInit}
        onMenuCategoryClick={this.handleCategoryClick}
        isLoyaltyEnabled={isLoyaltyEnabled}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.order,
  menuCategories: state.api.menuCategories,
  menuItems: state.api.menuItems,
  menuGroups: state.api.menuGroups,
  gglocationMenuGroups: state.api.gglocationMenuGroups,
  selectedTagIds: state.food.selectedTagIds,
  isLoyaltyEnabled: state.api.settings.isLoyaltyIntegrationEnabled,
  gglocations: state.api.gglocations,
  customerAddresses: state.user.customerAddresses,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLandingPage: orderActions.setLandingPage,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
