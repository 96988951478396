import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import LinearIcon from '../LinearIcon';

import './ErrorSticker.css';

function ErrorSticker({ className, title, icon, children, noIcon }) {
  return (
    <div className={classnames('ErrorSticker', className)}>
      {icon && <img src={icon} alt="icon" />}
      {title && (
        <Typography variant="body1" fontWeight="600">
          {title}
        </Typography>
      )}
      <p>
        {!noIcon && <LinearIcon name="warning" />} {children}
      </p>
    </div>
  );
}

ErrorSticker.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
  noIcon: PropTypes.bool,
};

ErrorSticker.defaultProps = {
  className: null,
  title: null,
  icon: null,
  children: null,
  noIcon: false,
};

export default ErrorSticker;
