import * as Sentry from '../services/sentry';

export const validate2c2pCardForm = ({
  name,
  setCardNumberError,
  setMonthError,
  setYearError,
  setCvvError,
  setOtherError,
}) => {
  let success = false;
  let validatedData = null;
  try {
    // eslint-disable-next-line no-undef
    My2c2p.getEncrypted('payment-method-form-CARD', (encryptedData, errorCode, errorDesc) => {
      if (errorCode !== 0) {
        switch (errorCode) {
          case 0:
            break;
          case 1:
            setCardNumberError('Card number is required');
            break;
          case 2:
            setCardNumberError('Card number is invalid');
            break;
          case 3:
            setMonthError('Expiry month is required');
            break;
          case 4:
            setMonthError('Expiry month must be two numbers');
            break;
          case 5:
            setYearError('Expiry year is required');
            break;
          case 6:
            setYearError('Expiry year must be four numbers');
            break;
          case 7:
            setYearError('Card already expired (year)');
            break;
          case 8:
            setMonthError('Card already expired (month)');
            break;
          case 9:
            setMonthError('Expiry month is invalid');
            break;
          case 10:
            setCvvError('CVV2/CVC2 is invalid');
            break;
          case 11:
            setMonthError('Expiry month is invalid');
            break;
          case 12:
            setYearError('Expiry year is invalid');
            break;
          default:
            setOtherError(errorDesc);
            break;
        }
        return;
      }
      // Return processed data
      success = true;
      validatedData = { ...encryptedData, cardHolderName: name };
    });
  } catch (e) {
    return { success, validatedData };
  }
  return { success, validatedData };
};

export const parse2c2pResp = (resp) => {
  try {
    if (!resp) return null;

    return JSON.parse(atob(decodeURIComponent(resp)));
  } catch (e) {
    Sentry.captureException(e);
    return { respCode: '9993' }; // Unknown error
  }
};
