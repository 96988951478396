import PropTypes from 'prop-types';

const customerAddressShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  foodProfile: PropTypes.number.isRequired,
  isDefault: PropTypes.bool.isRequired,
  point: PropTypes.string.isRequired,
  providerReference: PropTypes.string,
  floorUnit: PropTypes.string,
  building: PropTypes.string,
  address: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
});

export default customerAddressShape;
