export const LOG_ADD_ITEM_TO_CART = 'LOG_ADD_ITEM_TO_CART';
export const LOG_REMOVE_ITEM_FROM_CART = 'LOG_REMOVE_ITEM_FROM_CART';
export const LOG_CART_CONFIRM = 'LOG_CART_CONFIRM';
export const LOGGING_ERROR = 'LOGGING_ERROR';

export const logAddItemToCart = () => ({
  type: LOG_ADD_ITEM_TO_CART,
});

export const logRemoveItemFromCart = ({ orderItemId }) => ({
  type: LOG_REMOVE_ITEM_FROM_CART,
  orderItemId,
});

export const logCartConfirm = ({ order }) => ({
  type: LOG_CART_CONFIRM,
  order,
});

export const loggingError = ({ error }) => ({
  type: LOGGING_ERROR,
  error,
});
