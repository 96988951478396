import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as historyActions from '../../actions/history';
import * as orderItemActions from '../../actions/orderItem';
import statusEnum from '../../enums/statusEnum';
import * as foodUtils from '../../utils/foodUtils';
import { getSearchParam, setSearchParams } from '../../utils/historyUtils';
import * as orderUtils from '../../utils/orderUtils';
import { getSectionSettings } from '../../utils/settingsUtils';

import cyoSectionShape from '../../shapes/cyoSectionShape';
import ingredientCategoryShape from '../../shapes/ingredientCategoryShape';
import ingredientShape from '../../shapes/ingredientShape';
import menuItemShape from '../../shapes/menuItemShape';
import orderShape from '../../shapes/orderShape';
import routerShape, { routerActionShape } from '../../shapes/routerShape';
import { baseTypeSettingsShape, sectionSettingsShape } from '../../shapes/settingsShape';

import MenuDetails from '../../components/MenuDetails';
import withRouter from '../WithRouter';

class MenuDetailsContainer extends Component {
  static propTypes = {
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    cyoSections: PropTypes.objectOf(cyoSectionShape).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
    selectedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    baseTypeSettings: PropTypes.objectOf(baseTypeSettingsShape).isRequired,
    sectionSettings: PropTypes.objectOf(sectionSettingsShape).isRequired,
    order: orderShape.isRequired,
    orderPreviewStatus: PropTypes.number.isRequired,
    landingPageHistory: PropTypes.arrayOf(PropTypes.string).isRequired,

    locationInvalid: PropTypes.func.isRequired,
    resetHighlightedIngredientServings: PropTypes.func.isRequired,
    isBaseSectionSelectable: PropTypes.bool.isRequired,

    /* React Router props */
    router: routerShape.isRequired,
    action: routerActionShape.isRequired,
  };

  constructor(props) {
    const { order, landingPageHistory, router } = props;
    const { menuItemId } = router.params;

    super(props);

    this.state = {
      isNutritionModalOpen: false,
      orderItemId: getSearchParam(router.location.search, 'orderItemId'),
      requiredNotification: null,
      limitNotification: null,
      isMobile: false,
    };

    if (menuItemId === undefined) {
      router.push('/locations');
    }
    const isLandingPageOrder = orderUtils.isLandingPageOrder({ landingPageHistory });

    if (order.gglocationId === null && !isLandingPageOrder) {
      router.push('/locations');
    }
  }

  componentDidMount() {
    if (!this.visitedFromHistory) {
      this.redirectPremade();
    }
    window.addEventListener('resize', this.handleResizeWindow.bind(this));
    this.handleResizeWindow();
  }

  componentDidUpdate(prevProps) {
    const { resetHighlightedIngredientServings, router } = this.props;
    const { params } = router;

    if (prevProps.router.params.page !== params.page && !params.page) {
      resetHighlightedIngredientServings();
    }

    if (params.page === 'add' && params.page !== prevProps.router.params.page) {
      const menuDetailsContentWrapper = document.querySelector('.MenuDetailsContentWrapper');
      menuDetailsContentWrapper.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeWindow.bind(this));
  }

  get visitedFromHistory() {
    const { action } = this.props;

    return action === 'POP';
  }

  get sections() {
    const { cyoSections } = this.props;

    return Object.values(cyoSections)
      .filter((section) => {
        const sectionSettings = this.determineSectionSettings(section.id);

        return (
          sectionSettings.hidden === false && sectionSettings?.addCategories?.premade.length > 0
        );
      })
      .sort((a, b) => a.order - b.order);
  }

  get menuItem() {
    const { menuItems, router } = this.props;

    return menuItems[router.params.menuItemId];
  }

  get baseId() {
    return this.menuItem.baseType;
  }

  get isOrderPreviewRequested() {
    const { orderPreviewStatus } = this.props;

    return [statusEnum.REQUESTED, statusEnum.RELOADING].includes(orderPreviewStatus);
  }

  determineSectionSettings = (sectionId) => {
    const { baseTypeSettings, sectionSettings } = this.props;

    return getSectionSettings({
      baseId: this.baseId,
      sectionId,
      baseTypeSettings,
      sectionSettings,
    });
  };

  hasRequiredSectionIngredients = (sectionId) => {
    const {
      cyoSections,
      ingredients,
      ingredientCategories,
      selectedIngredientServings,
      baseTypeSettings,
      sectionSettings,
    } = this.props;

    const section = cyoSections[sectionId];

    const result = foodUtils.hasRequiredIngredients(this.baseId, section.id, {
      ingredients,
      ingredientCategories,
      selectedIngredientServings,
      baseTypeSettings,
      sectionSettings,
    });

    if (result === false) {
      this.setState({
        requiredNotification: {
          id: moment().valueOf(),
          name: section.name,
          clearLimitNotification: this.clearLimitNotification,
        },
      });
    }

    return result;
  };

  hasRequiredIngredients = () =>
    this.sections.every((section) => this.hasRequiredSectionIngredients(section.id));

  redirectPremade = () => {
    const { locationInvalid } = this.props;

    if (this.sections.length === 0) {
      locationInvalid();
      this.handleOrderItemAdd();
    }
  };

  handleResizeWindow = () => {
    this.setState({ isMobile: window.innerWidth <= 992 });
  };

  clearLimitNotification = () => {
    this.setState({
      limitNotification: null,
    });
  };

  handleLimitExhausted = ({ name, limit, extra, isRemove }) => {
    this.setState({
      limitNotification: {
        id: moment().valueOf(),
        name,
        limit,
        extra,
        isRemove,
        clearLimitNotification: this.clearLimitNotification,
      },
    });
  };

  handleNutritionButtonClick = () => {
    this.setState({ isNutritionModalOpen: true });
  };

  handleNutritionModalHide = () => {
    this.setState({ isNutritionModalOpen: false });
  };

  handleOrderItemAdd = () => {
    const { orderItemId } = this.state;
    const { router } = this.props;
    const { location } = router;

    if (!this.hasRequiredIngredients()) {
      return false;
    }

    router.push({
      pathname: `/personalize/${this.menuItem.id}`,
      search: setSearchParams(location.search, { orderItemId }, true),
    });

    return true;
  };

  render() {
    const { isBaseSectionSelectable, router } = this.props;
    const { params } = router;
    const { isMobile, isNutritionModalOpen, limitNotification, requiredNotification } = this.state;

    return (
      <MenuDetails
        page={params.page}
        pageSectionId={parseInt(params.pageSectionId, 10)}
        menuItem={this.menuItem}
        baseId={this.baseId}
        sections={this.sections}
        isMobile={isMobile}
        isNutritionModalOpen={isNutritionModalOpen}
        isOrderPreviewRequested={this.isOrderPreviewRequested}
        requiredNotification={requiredNotification}
        limitNotification={limitNotification}
        onLimitExhausted={this.handleLimitExhausted}
        onNutritionButtonClick={this.handleNutritionButtonClick}
        onNutritionModalHide={this.handleNutritionModalHide}
        onOrderItemAdd={this.handleOrderItemAdd}
        isBaseSectionSelectable={isBaseSectionSelectable}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  menuItems: state.api.menuItems,
  ingredients: state.api.ingredients,
  ingredientCategories: state.api.ingredientCategories,
  selectedIngredientServings: state.orderItem.selectedIngredientServings,
  cyoSections: state.api.cyoSections,
  baseTypeSettings: state.api.settings.baseTypeSettings,
  sectionSettings: state.api.settings.sectionSettings,
  order: state.order,
  orderPreviewStatus: state.order.status.orderPreview,
  landingPageHistory: state.order.landingPageHistory,
  location: state.router.location,
  action: state.router.action,
  isBaseSectionSelectable: state.api.settings.isBaseSectionSelectable,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      locationInvalid: historyActions.locationInvalid,
      resetHighlightedIngredientServings: orderItemActions.resetHighlightedIngredientServings,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuDetailsContainer));
