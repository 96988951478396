import React from 'react';

import FontAwesome from '../../../FontAwesome';

import './FacebookConnectedLogo.css';

function FacebookConnectedLogo() {
  return (
    <div className="FacebookConnectedLogo">
      <FontAwesome name="facebook" type="brands" />
      <span className="facebook-text">Facebook connected</span>
      <span className="glyphicon glyphicon-ok" />
    </div>
  );
}

export default FacebookConnectedLogo;
