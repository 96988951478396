import React, { Component } from 'react';
import PropTypes from 'prop-types';

import favoriteMenuItemShape from '../../shapes/favoriteMenuItemShape';

import EditFavorite from '../../components/EditFavorite';

class EditFavoriteContainer extends Component {
  static propTypes = {
    favoriteMenuItem: favoriteMenuItemShape.isRequired,
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { favoriteMenuItem } = this.props;

    if (favoriteMenuItem && prevProps.favoriteMenuItem !== favoriteMenuItem) {
      this.setState({ name: favoriteMenuItem.name });
    }
  }

  handleNameChange = (event) => {
    const name = event.target.value;

    this.setState({ name });
  };

  handleSave = () => {
    const { favoriteMenuItem, onSave } = this.props;
    const { name } = this.state;

    onSave(favoriteMenuItem.id, name);
  };

  render() {
    const { favoriteMenuItem, onHide } = this.props;
    const { name } = this.state;

    return (
      <EditFavorite
        favoriteMenuItem={favoriteMenuItem}
        name={name}
        onNameChange={this.handleNameChange}
        onHide={onHide}
        onSave={this.handleSave}
      />
    );
  }
}

export default EditFavoriteContainer;
