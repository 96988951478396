export const SIGN_IN_DISPATCH = 'SIGN_IN_DISPATCH';

export const PAYMENT_LOGIN = 'PAYMENT_LOGIN';
export const PAYMENT_LOGIN_REQUESTED = `${PAYMENT_LOGIN}_REQUESTED`;
export const PAYMENT_LOGIN_SUCCESS = `${PAYMENT_LOGIN}_SUCCESS`;
export const PAYMENT_LOGIN_ERROR = `${PAYMENT_LOGIN}_ERROR`;

export const SHOW_PASSWORD_MODAL = 'SHOW_PASSWORD_MODAL';
export const HIDE_PASSWORD_MODAL = 'HIDE_PASSWORD_MODAL';

export const HIDE_RESEND_MODAL = 'HIDE_RESEND_MODAL';

export const SIGNED_IN = 'SIGNED_IN';
export const SIGN_OUT = 'SIGN_OUT';

export const SET_REGISTRATION_FIRST_NAME = 'SET_REGISTRATION_FIRST_NAME';
export const SET_REGISTRATION_LAST_NAME = 'SET_REGISTRATION_LAST_NAME';
export const SET_REGISTRATION_EMAIL = 'SET_REGISTRATION_EMAIL';

export const AFTER_SIGN_IN = 'AFTER_SIGN_IN';
export const BEFORE_SIGN_IN = 'BEFORE_SIGN_IN';

export const ADD_ORDER = 'ADD_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';

export const CHANGE_LOGIN_METHOD = 'CHANGE_LOGIN_METHOD';

export const SHOW_INVALID_TOKEN_MODAL = 'SHOW_INVALID_TOKEN_MODAL';
export const HIDE_INVALID_TOKEN_MODAL = 'HIDE_INVALID_TOKEN_MODAL';
export const SET_IS_EMAIL_VERIFICATION_POP_UP_SHOWN = 'SET_IS_EMAIL_VERIFICATION_POP_UP_SHOWN';

export const CLOSE_MARKETING_OPT_IN = 'CLOSE_MARKETING_OPT_IN';

export const CLEAR_VERSION_UPDATE_FLAG = 'CLEAR_VERSION_UPDATE_FLAG';

export const GUEST_LOGIN = 'GUEST_LOGIN';
export const GUEST_LOGOUT = 'GUEST_LOGOUT';
export const SET_GUEST_PHONE = 'SET_GUEST_PHONE';
export const SET_GUEST_EMAIL = 'SET_GUEST_EMAIL';
export const SET_IS_GUEST_SESSION = 'SET_IS_GUEST_SESSION';

export const signInDispatch = (email) => ({
  type: SIGN_IN_DISPATCH,
  email,
});

export const paymentLogin = (authAccessToken) => ({
  type: PAYMENT_LOGIN_REQUESTED,
  authAccessToken,
});

export const showPasswordModal = () => ({
  type: SHOW_PASSWORD_MODAL,
});

export const hidePasswordModal = () => ({
  type: HIDE_PASSWORD_MODAL,
});

export const hideResendModal = () => ({
  type: HIDE_RESEND_MODAL,
});

export const signedIn = () => ({
  type: SIGNED_IN,
});

export const signOut = () => ({
  type: SIGN_OUT,
});

export const setRegistrationFirstName = ({ firstName }) => ({
  type: SET_REGISTRATION_FIRST_NAME,
  firstName,
});

export const setRegistrationLastName = ({ lastName }) => ({
  type: SET_REGISTRATION_LAST_NAME,
  lastName,
});

export const setRegistrationEmail = ({ email }) => ({
  type: SET_REGISTRATION_EMAIL,
  email,
});

export const afterSignIn = (link) => ({
  type: AFTER_SIGN_IN,
  link,
});

export const beforeSignIn = (link) => ({
  type: BEFORE_SIGN_IN,
  link,
});

export const addOrder = (response) => ({
  type: ADD_ORDER,
  response,
});

export const updateOrder = (response) => ({
  type: UPDATE_ORDER,
  response,
});

export const changeLoginMethod = (method) => ({
  type: CHANGE_LOGIN_METHOD,
  method,
});

export const showInvalidTokenModal = () => ({
  type: SHOW_INVALID_TOKEN_MODAL,
});

export const hideInvalidTokenModal = () => ({
  type: HIDE_INVALID_TOKEN_MODAL,
});

export const closeMarketingOptIn = () => ({
  type: CLOSE_MARKETING_OPT_IN,
});

export const clearVersionUpdateFlag = () => ({
  type: CLEAR_VERSION_UPDATE_FLAG,
});

export const setIsEmailVerificationPopUpShown = (isEmailVerificationPopUpShown) => ({
  type: SET_IS_EMAIL_VERIFICATION_POP_UP_SHOWN,
  isEmailVerificationPopUpShown,
});

export const guestLogin = () => ({
  type: GUEST_LOGIN,
});

export const guestLogout = () => ({
  type: GUEST_LOGOUT,
});

export const setGuestPhone = (number) => ({
  type: SET_GUEST_PHONE,
  number,
});

export const setGuestEmail = (email) => ({
  type: SET_GUEST_EMAIL,
  email,
});

export const setIsGuestSession = (isGuestSession) => ({
  type: SET_IS_GUEST_SESSION,
  isGuestSession,
});
