import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../actions/order';
import gglocationTypeEnum from '../../enums/gglocationTypeEnum';

import gglocationShape from '../../shapes/gglocationShape';
import userOrderShape from '../../shapes/userOrderShape';

import PastOrder from '../../components/PastOrder';

class PastOrderContainer extends Component {
  static propTypes = {
    userOrderId: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
    userOrders: PropTypes.objectOf(userOrderShape).isRequired,
    gglocations: PropTypes.objectOf(gglocationShape).isRequired,
    onToggle: PropTypes.func.isRequired,
    replaceOrderDispatch: PropTypes.func.isRequired,
  };

  get userOrder() {
    const { userOrderId, userOrders } = this.props;

    return userOrders[userOrderId];
  }

  get gglocationId() {
    return `${this.userOrder.gglocationType}_${this.userOrder.gglocationId}`;
  }

  get gglocation() {
    const { gglocations } = this.props;

    return gglocations[this.gglocationId];
  }

  get gglocationName() {
    return this.gglocation ? this.gglocation.name : 'Undefined';
  }

  get isStationOrder() {
    return this.userOrder.gglocationType === gglocationTypeEnum.PARTNER;
  }

  handleOrderReplace = () => {
    const { replaceOrderDispatch } = this.props;

    replaceOrderDispatch({ userOrderId: this.userOrder.id });
  };

  render() {
    const { isOpen, onToggle } = this.props;

    return (
      <PastOrder
        isOpen={isOpen}
        order={this.userOrder}
        isStationOrder={this.isStationOrder}
        gglocationName={this.gglocationName}
        onOrderReplace={this.handleOrderReplace}
        onToggle={onToggle}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userOrders: state.api.userOrders,
  gglocations: state.api.gglocations,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      replaceOrderDispatch: orderActions.replaceOrderDispatch,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PastOrderContainer);
