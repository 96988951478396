import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Button from '../Button';
import PieCountdown from '../PieCountdown';

import { ORDER_DELAY_TIME } from '../../settings';

import './OrderDelay.css';

function OrderDelay(props) {
  const { isDeliveryOrder, handleOnCancel, destinationName } = props;

  return (
    <>
      <p>Processing...</p>
      <Typography
        variant={isDeliveryOrder ? 'body1' : 'calafia2'}
        fontWeight={isDeliveryOrder ? '700' : '400'}
        color="primary"
      >
        {destinationName}
      </Typography>
      <PieCountdown animationDuration={ORDER_DELAY_TIME} />
      <Button
        className="btn-link kale order-delay-cancel-link blue-link"
        type="button"
        onClick={handleOnCancel}
      >
        Cancel order
      </Button>
    </>
  );
}

OrderDelay.propTypes = {
  isDeliveryOrder: PropTypes.bool.isRequired,
  destinationName: PropTypes.string.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
};

export default OrderDelay;
