import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as loyaltyActions from '../../actions/loyalty';
import discountSourceEnum from '../../enums/discountSourceEnum';

import claimShape from '../../shapes/claimShape';

import SelectedReward from '../../components/SelectedReward';

class SelectedRewardContainer extends Component {
  static propTypes = {
    fromCart: PropTypes.bool,
    discountAmount: PropTypes.number,
    discountName: PropTypes.string,
    discountSource: PropTypes.string,
    orderItemsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    claims: PropTypes.objectOf(claimShape).isRequired,
    selectedUserRewardUuid: PropTypes.string,
    unsetSelectedUserReward: PropTypes.func.isRequired,
    unsetSelectedPromoCode: PropTypes.func.isRequired,
  };

  static defaultProps = {
    discountAmount: null,
    discountName: null,
    discountSource: null,
    fromCart: false,
    selectedUserRewardUuid: null,
  };

  get discountName() {
    const { claims, selectedUserRewardUuid, discountName, discountSource } = this.props;

    if (discountSource === discountSourceEnum.LOYALTY) {
      const rewardClaim = claims.find(claim => claim.uuid === selectedUserRewardUuid);

      if (rewardClaim?.claimName) {
        return rewardClaim.claimName;
      }
    }
    return discountName;
  }

  get discountAmount() {
    const { discountAmount } = this.props;

    return discountAmount;
  }

  get isCartEmpty() {
    const { orderItemsIds } = this.props;

    return orderItemsIds.length < 1;
  }

  handleCancel = () => {
    const { unsetSelectedUserReward, unsetSelectedPromoCode, discountSource } = this.props;

    if (discountSource === discountSourceEnum.LOYALTY) {
      unsetSelectedUserReward();
    } else if (discountSource === discountSourceEnum.PROMO_CODE) {
      unsetSelectedPromoCode();
    }
  };

  render() {
    const { fromCart, discountName, discountSource } = this.props;

    return (
      <SelectedReward
        fromCart={fromCart}
        isCartEmpty={this.isCartEmpty}
        discountAmount={this.discountAmount}
        discountName={discountName}
        discountSource={discountSource}
        reward={this.reward}
        onCancel={this.handleCancel}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  claims: state.loyalty.claims,
  selectedUserRewardUuid: state.loyalty.selectedUserRewardUuid,
  discountAmount: state.order.pricing.discountAmount,
  discountName: state.order.pricing.discountName,
  discountSource: state.order.pricing.discountSource,
  orderItemsIds: state.order.orderItemsIds,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    unsetSelectedUserReward: loyaltyActions.unsetSelectedUserReward,
    unsetSelectedPromoCode: loyaltyActions.unsetSelectedPromoCode,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(SelectedRewardContainer);
