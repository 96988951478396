import React from 'react';
import { Outlet } from 'react-router-dom';

import SignIn from '../../components/SignIn';

function SignInContainer() {
  return (
    <SignIn>
      <Outlet />
    </SignIn>
  );
}

export default SignInContainer;
