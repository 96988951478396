import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import LocaleProvider from 'antd/lib/locale-provider';
import enUS from 'antd/lib/locale-provider/en_US';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { PersistGate } from 'redux-persist/lib/integration/react';

import './polyfills';

import { setTrackingContext } from './utils/loggingUtils';

import LoadingScreen from './components/LoadingScreen';
import configurePusher from './pusher';
import initializeRefreshDetector from './refreshDetector';
import Routes from './routes';
import configureSentry from './sentry';
import configureSmartlook from './smartlook';
import configureStore from './store';
import theme from './theme';

import enMessages from './intl/en.json';

configureSentry();
configureSmartlook();
setTrackingContext();

initializeRefreshDetector();

const { store, persistor, history } = configureStore();
configurePusher(store);

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={<LoadingScreen />} persistor={persistor}>
      <IntlProvider locale="en" defaultLocale="en" messages={enMessages}>
        <LocaleProvider locale={enUS}>
          <ThemeProvider theme={theme}>
            <Router history={history}>
              <Routes />
            </Router>
          </ThemeProvider>
        </LocaleProvider>
      </IntlProvider>
    </PersistGate>
  </Provider>,
);
