import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import * as orderUtils from '../../../utils/orderUtils';

import Button from '../../Button';

function OrderCancelButton({ createTime, orderStatus, noCountDown, onCancel }) {
  const cancelIntervalRef = useRef(null);
  const [countDown, setCountDown] = useState(null);

  useEffect(() => {
    if (!noCountDown && orderUtils.isOrderStatusRequested(orderStatus)) {
      clearInterval(cancelIntervalRef.current);
      setInterval(() => {
        setCountDown(
          moment(createTime)
            .add(30 + 3, 'seconds')
            .diff(moment(), 'seconds'),
        );
      }, 1000);
    }
    if (!orderUtils.isOrderStatusRequested(orderStatus)) {
      clearInterval(cancelIntervalRef.current);
    }

    return () => clearInterval(cancelIntervalRef);
  }, [createTime, orderStatus, noCountDown]);

  const onCancelClick = () => {
    clearInterval(cancelIntervalRef.current);
    onCancel();
  };

  if (!orderUtils.isOrderStatusRequested(orderStatus) || (!noCountDown && countDown < 0))
    return null;

  return (
    <Button className="btn-round OrderCancelButton" onClick={onCancelClick}>
      Cancel
      {!noCountDown && (
        <span style={{ fontWeight: 400 }}>
          &nbsp;
          {moment([2020, 1, 1]).add(countDown, 'seconds').format('m:ss')}
        </span>
      )}
    </Button>
  );
}

OrderCancelButton.propTypes = {
  createTime: PropTypes.string.isRequired,
  orderStatus: PropTypes.number.isRequired,
  noCountDown: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};

OrderCancelButton.defaultProps = {
  noCountDown: false,
};

export default OrderCancelButton;
