// eslint-disable-next-line simple-import-sort/imports
import React from 'react';
import { Marker, withGoogleMap } from 'react-google-maps';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';

import gglocationShape from '../../../shapes/gglocationShape';

import StyledGoogleMap from '../../StyledGoogleMap';
import GglocationMapMarker from '../GglocationMapMarker';

import { MAP_DEFAULT_ZOOM, MAP_MIN_ZOOM } from '../../../settings';

import iconLocale from '../../../images/icon-locale.svg';
import iconLocaleLarge from '../../../images/icon-locale-large.svg';

import './Map.css';

const GoogleMapWrapper = withGoogleMap((props) => (
  <StyledGoogleMap
    onMapLoad={props.onMapLoad}
    defaultZoom={MAP_DEFAULT_ZOOM}
    defaultCenter={props.defaultCenter}
    defaultOptions={{ minZoom: MAP_MIN_ZOOM }}
  >
    {props.gglocations.map((gglocation) => {
      const isSelected = gglocation.id === props.highlightedGglocationId;

      return (
        <Marker
          key={gglocation.id}
          position={gglocation.coordinates}
          icon={isSelected ? iconLocaleLarge : iconLocale}
          onClick={() => props.onMarkerClick(gglocation.id)}
        >
          {isSelected && (
            <InfoBox options={{ closeBoxURL: '', enableEventPropagation: true }}>
              <GglocationMapMarker gglocation={gglocation} image={props.gglocationImage} />
            </InfoBox>
          )}
        </Marker>
      );
    })}
  </StyledGoogleMap>
));

function Map(props) {
  const {
    gglocations,
    highlightedGglocationId,
    gglocationImage,
    defaultCenter,
    onMapLoad,
    onMarkerClick,
    className,
  } = props;

  return (
    <GoogleMapWrapper
      containerElement={<div className={classnames('Map', className)} />}
      mapElement={<div className="MapElement" />}
      gglocations={gglocations}
      highlightedGglocationId={highlightedGglocationId}
      gglocationImage={gglocationImage}
      defaultCenter={defaultCenter}
      onMapLoad={onMapLoad}
      onMarkerClick={onMarkerClick}
    />
  );
}

Map.propTypes = {
  gglocations: PropTypes.arrayOf(gglocationShape).isRequired,
  highlightedGglocationId: PropTypes.string,
  gglocationImage: PropTypes.string,
  defaultCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  onMapLoad: PropTypes.func.isRequired,
  onMarkerClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Map.defaultProps = {
  className: '',
  highlightedGglocationId: null,
  gglocationImage: null,
};

export default Map;
