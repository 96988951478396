import PropTypes from 'prop-types';

const pageInfoShape = PropTypes.shape({
  count: PropTypes.number,
  previous: PropTypes.string,
  next: PropTypes.string,
  currentPage: PropTypes.number,
});

export default pageInfoShape;
