import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import moment from 'moment';

import * as pageActions from '../../../actions/page';
import rewardTypeEnum from '../../../enums/rewardTypeEnum';
import { getCollectionSteps } from '../../../utils/loyaltyUtils';

import rewardShape from '../../../shapes/rewardShape';

import Image from '../../Image';
import ProgressBar from '../../ProgressBar/ProgressBar';
import BonusSticker from '../BonusSticker';

import defaultImg from '../../../images/default-collection-image.webp';

import './LoyaltyCard.css';

/* TODO: Look into reward types and see if more logic is needed in progress bars */
function LoyaltyCard({ reward }) {
  const dispatch = useDispatch();

  const renderTitle = () =>
    reward.earned === parseInt(reward.total, 10) ? `Earned ${reward.rewardName}` : reward.title;

  const handleCardClick = () => {
    dispatch(
      pageActions.openRewardModal({ rewardId: reward.id, rewardType: rewardTypeEnum.COLLECTION }),
    );
  };

  const remaining = (reward?.total ?? 0) - (reward?.earned ?? 0);
  const steps = getCollectionSteps(reward);
  const hasSecondary = steps.length > 0;

  const remainingLabel = (() => {
    if (reward && remaining) {
      if (hasSecondary) {
        const nextStep = steps.sort().find((value) => value > reward.earned);

        if (nextStep) {
          return `${remaining} more to the next reward!`;
        }
      }
      return `${remaining} more to go!`;
    }
    return '';
  })();

  return (
    <div role="presentation" className="LoyaltyCard" onClick={handleCardClick}>
      <div className="TitleContainer">
        <div className="Title">
          <Typography className="TitleLabel" variant="body1" fontWeight="700">
            {renderTitle()}
          </Typography>
        </div>
      </div>
      <div className="LoyaltyCardBody">
        <div className="LoyaltyCardImageContainer">
          <Image src={reward.dashboardImageUrl || defaultImg} />
        </div>
        <div className="LoyaltyCardContentContainer">
          <div className="NameContainer">
            <Typography variant="subtitle1" color="grey.main" fontWeight="600">
              {reward.isCollection ? reward.name : reward.desc}
            </Typography>
          </div>
          <div className="CardBottom">
            <BonusSticker bonus={reward.bonus} />
            {reward.isCollection && (
              <ProgressBar value={(reward.earned / reward.total) * 100} steps={steps} />
            )}
            <div className="ValidityRow">
              {reward.rewardEnd && (
                <Typography variant="body2" className="Validity" color="grey.main">
                  Valid until{' '}
                  <span className="bold">{moment(reward.rewardEnd).format('D MMM YYYY')}</span>
                </Typography>
              )}
              {reward.isCollection && remaining && (
                <Typography variant="body2" className="Remaining" color="grey.main">
                  {remainingLabel}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

LoyaltyCard.propTypes = {
  reward: rewardShape.isRequired,
};

export default LoyaltyCard;
