import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { setOrderTime } from '../../actions/order';
import * as selectors from '../../sagas/selectors';
import {
  getEarliestTimeFromTimeRanges,
  getScheduleOrderTimeRangeOnSameOrNextDay,
  inferScheduleTimeRangeFromOrderTime,
} from '../../utils/gglocationUtils';

import Scheduler from '../../containers/Scheduler';
import Select, { Option } from '../Select';

import './TimeRangePicker.css';

function TimeRangePicker() {
  const orderTime = useSelector(selectors.getOrderOrderTime);
  const diningChoice = useSelector(selectors.getDiningChoice);
  const orderScheduler = useSelector(selectors.getOrderScheduler);
  const gglocationId = useSelector(selectors.getOrderGglocationId);
  const { gglocations } = useSelector(selectors.getGglocations);
  const defaultTimeInterval = useSelector(selectors.getDefaultDeliveryTimeInterval);

  const [selectedOrderTime, setSelectedOrderTime] = useState(moment(orderTime));
  const [timeRanges, setTimeRanges] = useState([]);
  const [scheduler, setScheduler] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    // Convert order time to moment object
    if (orderTime) {
      setSelectedOrderTime(moment(orderTime));
    }
  }, [orderTime]);

  useEffect(() => {
    // Select scheduler here, as useSelector will always return a new object
    // thus, create loop
    const selectScheduler = () => {
      if (gglocationId) {
        return gglocations[gglocationId];
      }

      return new Scheduler(gglocations, orderScheduler);
    };
    setScheduler(selectScheduler());
  }, [gglocationId, gglocations, diningChoice]);

  useEffect(() => {
    // Retrieve the available time ranges on the selected date
    // orderTime will be null if no gglocation is available
    const timeRangesOnSameDay = getScheduleOrderTimeRangeOnSameOrNextDay({
      scheduler,
      orderTime: selectedOrderTime.isValid() ? selectedOrderTime : moment(),
      diningChoice,
      defaultTimeInterval,
    });
    setTimeRanges(timeRangesOnSameDay);
  }, [scheduler, selectedOrderTime, diningChoice, defaultTimeInterval]);

  useEffect(() => {
    // Infer the correct time range from the schedule time
    // Because the time ranges may be changed between pick up time page
    // and cart checkout page after the gglocation is finalized by ECOM.
    if (
      timeRanges.length >= 1 &&
      !timeRanges.find(([lowerBound]) => lowerBound.valueOf() === selectedOrderTime.valueOf())
    ) {
      const selectedTimeRange = inferScheduleTimeRangeFromOrderTime({
        scheduler,
        orderTime: selectedOrderTime.isValid() ? selectedOrderTime : moment(),
        diningChoice,
        defaultTimeInterval,
      });

      if (selectedTimeRange) {
        dispatch(setOrderTime(selectedTimeRange[0]));
      } else if (!selectedOrderTime.isValid()) {
        // Temporarily select earliest possible schedule time
        // if there are no available instant gglocation as we cannot
        // infer what is the truely earliest possible time for delivery yet
        dispatch(setOrderTime(getEarliestTimeFromTimeRanges(timeRanges)));
      }
    }
  }, [scheduler, selectedOrderTime, diningChoice, defaultTimeInterval, dispatch, timeRanges]);

  const onTimeRangeChange = (timeRange) => dispatch(setOrderTime(moment(parseInt(timeRange, 10))));

  return (
    <div className="TimePicker TimeRangePicker">
      <Select value={selectedOrderTime.valueOf()} onChange={onTimeRangeChange}>
        {timeRanges.map(([lowerBound, upperBound]) => (
          <Option key={lowerBound.valueOf()} value={lowerBound.valueOf()}>
            <Moment format="hh:mm A">{moment(lowerBound)}</Moment> to{' '}
            <Moment format="hh:mm A">{moment(upperBound)}</Moment>
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default TimeRangePicker;
