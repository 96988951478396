import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { callWithLogging, LoggingCase } from '../../utils/loggingUtils';

import './Select.css';

function Select(props) {
  const { id, className, outerId, outerClassName, icon, onChange, ...otherProps } = props;

  return (
    <div id={outerId} className={classnames('SelectWrapper', outerClassName)}>
      {icon && <img src={icon} className="icon" alt="icon" />}
      <select
        {...otherProps}
        id={id}
        className={classnames('Select', className)}
        onChange={(event) =>
          callWithLogging({
            func: onChange,
            args: [event.target.value],
            eventName: `Select status changed: ${event.target.value}`,
            eventLoggingCase: LoggingCase.ANY,
          })
        }
      />
    </div>
  );
}

Select.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  outerId: PropTypes.string,
  outerClassName: PropTypes.string,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  id: '',
  icon: '',
  className: '',
  outerId: '',
  outerClassName: '',
  onChange: () => {},
};

export default Select;
