const statusEnum = Object.freeze({
  INITIAL: 0,
  AWAITING: 1,
  REQUESTED: 2,
  ERROR: 3,
  SUCCESS: 4,
  RELOADING: 5,
});

export default statusEnum;
