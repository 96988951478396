import { combineReducers } from 'redux';

import apiReducer from '../reducers/api';
import browserReducer from '../reducers/browser';
import foodReducer from '../reducers/food';
import geolocationReducer from '../reducers/geolocation';
import historyReducer from '../reducers/history';
import landingPageReducer from '../reducers/landingPage';
import loyaltyReducer from '../reducers/loyalty';
import orderReducer from '../reducers/order';
import orderItemReducer from '../reducers/orderItem';
import pageReducer from '../reducers/page';
import scrollspyReducer from '../reducers/scrollspy';
import userReducer from '../reducers/user';

const configureReducer = (routerReducer) =>
  combineReducers({
    api: apiReducer,
    browser: browserReducer,
    food: foodReducer,
    geolocation: geolocationReducer,
    history: historyReducer,
    loyalty: loyaltyReducer,
    landingPage: landingPageReducer,
    order: orderReducer,
    orderItem: orderItemReducer,
    page: pageReducer,
    user: userReducer,
    router: routerReducer,
    scrollspy: scrollspyReducer,
  });

export default configureReducer;
