import React from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import useDeviceDetect from '../../hooks/useDeviceDetect';

import './DesktopTooltip.css';

function DesktopTooltip({ children, className, title, ...tooltipProps }) {
  const { isDesktop } = useDeviceDetect();

  return (
    <Tooltip
      classes={{ popper: 'DesktopTooltipPopper', tooltip: `DesktopTooltip ${className}` }}
      title={isDesktop ? title : null}
      {...tooltipProps}
    >
      <div className="DesktopTooltipInnerWrapper">{children}</div>
    </Tooltip>
  );
}

DesktopTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
};

DesktopTooltip.defaultProps = {
  className: '',
};

export default DesktopTooltip;
