import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as selectors from '../../../sagas/selectors';

import Image from '../../../containers/Image';
import IngredientChoicePrice from '../../../containers/IngredientsPicker/IngredientChoicePrice';
import Button from '../../Button';
import Counter from '../../Counter';
import FontAwesome from '../../FontAwesome';

import './IngredientChoice.css';

function Multiple(props) {
  const {
    id,
    name,
    image,
    price,
    includedServings,
    energy,
    quantity,
    disabled,
    unavailable,
    onClick,
    onDetailsClick,
    onIncrement,
    onDecrement,
  } = props;

  const isNutritionalInformationHidden = useSelector(selectors.getIsNutritionalInformationHidden);

  return (
    <div
      className={classnames(`Ingredient-${id}`, 'IngredientChoiceWrapper', 'IngredientSmall', {
        disabled,
      })}
    >
      <div
        role="presentation"
        className={classnames(
          'IngredientChoice',
          'IngredientChoiceMultiple',
          { selected: quantity > 0 },
          { unavailable },
          { addOn: includedServings < quantity },
        )}
        onClick={onClick}
      >
        <ul className="IngredientDetails">
          <li>
            <div className="IngredientPicture">
              <div className="Status">
                <IngredientChoicePrice
                  price={price}
                  includedServings={includedServings}
                  quantity={quantity}
                />
              </div>
              <Image id={image} />
            </div>
          </li>
          <li>
            <div className="IngredientChoiceName">
              <div className="IngredientChoiceTitleRow">
                <Typography variant="body2" color="grey.main" className="Name">
                  {name}
                </Typography>
                {!isNutritionalInformationHidden && (
                  <Button className="IconInfo" onClick={onDetailsClick}>
                    <FontAwesome name="info-circle" />
                  </Button>
                )}
              </div>
              {!isNutritionalInformationHidden && (
                <div className="IngredientChoiceEnergyRow">
                  <p className="Energy">{energy} kcals</p>
                  <Button className="IconInfo" onClick={onDetailsClick}>
                    <FontAwesome name="info-circle" />
                  </Button>
                </div>
              )}
              {includedServings > 0 && <div className="IngredientIncludeLabel">Included</div>}
            </div>
          </li>
          {quantity > 0 && (
            <li className="CounterWrapper">
              <Counter
                value={quantity}
                onIncrement={(e) => {
                  e.stopPropagation();
                  onIncrement();
                }}
                onDecrement={(e) => {
                  e.stopPropagation();
                  onDecrement();
                }}
              />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

Multiple.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.number.isRequired,
  price: PropTypes.number,
  includedServings: PropTypes.number,
  energy: PropTypes.number.isRequired,
  quantity: PropTypes.number,
  disabled: PropTypes.bool,
  unavailable: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onDetailsClick: PropTypes.func.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
};

Multiple.defaultProps = {
  price: null,
  includedServings: 0,
  quantity: 0,
  disabled: false,
};

export default Multiple;
