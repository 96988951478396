import { toCamelCaseString } from '@spq/utils';
import { chain, intersection, mapValues, upperFirst } from 'lodash';
import { v4 as uuid4 } from 'uuid';

import tagTypeEnum from '../enums/tagTypeEnum';

import { getSectionSettings } from './settingsUtils';

import { BASE_SECTION_ID, INGREDIENT_BASES, INGREDIENT_LEVEL_TAG_TYPES } from '../settings';

export const ingredientIdsArray = (selectedIngredientServings) =>
  chain(selectedIngredientServings)
    .keys()
    .map((ingredientId) => parseInt(ingredientId, 10))
    .value();

export const getIngredientLevelTags = (tags) =>
  Object.fromEntries(
    Object.entries(tags).filter(([, { tagType }]) => INGREDIENT_LEVEL_TAG_TYPES.includes(tagType)),
  );

export const excludeGroupIdsTagsArray = (groupIds, tags) => {
  const isTagExcludingGroupIds = ({ excludeGroups }) => {
    if (excludeGroups.length > 0) {
      return intersection(excludeGroups, groupIds).length === 0;
    }
    return true;
  };
  return Object.values(tags).filter(isTagExcludingGroupIds);
};

export const includeGroupIdsArray = (groupIds, tags) => {
  const isTagIncludingGroupIds = ({ includeGroups }) => {
    if (includeGroups.length > 0) {
      return intersection(includeGroups, groupIds).length === includeGroups.length;
    }
    return true;
  };
  return Object.values(tags).filter(isTagIncludingGroupIds);
};

export const ingredientTagsArray = (ingredient, tags) => {
  const groupIds = ingredient.ingredientGroups;
  const ingredientLevelTags = getIngredientLevelTags(tags);
  const exclude = excludeGroupIdsTagsArray(groupIds, ingredientLevelTags);
  const include = includeGroupIdsArray(groupIds, ingredientLevelTags);

  return intersection(exclude, include);
};

export const ingredientCategoriesArray = (ingredientCategories) =>
  Object.values(ingredientCategories).sort((categoryA, categoryB) => {
    if (categoryA.order < categoryB.order) return -1;
    if (categoryA.order > categoryB.order) return 1;
    return 0;
  });

export const sectionsCategoriesArray = (sectionIds, ingredientCategories) => {
  const sortedIngredientCategories = ingredientCategoriesArray(ingredientCategories);
  const filteredIngredientCategories = sortedIngredientCategories.filter((category) =>
    sectionIds.includes(category.cyoSection),
  );

  return filteredIngredientCategories;
};

export const baseIngredientsFilter = (ingredient, { baseId, includeNull }) => {
  if (ingredient.baseType === baseId) {
    return true;
  }
  if (includeNull && ingredient.baseType === null) {
    return true;
  }

  return false;
};

export const cyoIngredientsFilter = (ingredient) => !ingredient.excludeInCYO;

export const tagIngredientsFilter = (ingredient, { selectedTagIds, tags }) => {
  const ingredientTags = ingredientTagsArray(ingredient, tags);
  const ingredientTagIds = ingredientTags.map((tag) => tag.id);
  const ingredientLevelTags = getIngredientLevelTags(tags);
  const ingredientLevelSelectedTagIds = selectedTagIds.filter((id) => ingredientLevelTags[id]);

  return ingredientLevelSelectedTagIds.every((tagId) => ingredientTagIds.includes(tagId));
};

export const categoryIngredientsFilter = (ingredient, { selectedCategoryIds }) =>
  selectedCategoryIds.includes(ingredient.ingredientCategory);

export const sectionsIngredientsFilter = (
  ingredient,
  { selectedSectionIds, ingredientCategories, includeNull = false },
) => {
  if (includeNull && ingredient.ingredientCategory === null) return true;

  const selectedCategoryIds = sectionsCategoriesArray(selectedSectionIds, ingredientCategories).map(
    (category) => category.id,
  );

  return categoryIngredientsFilter(ingredient, { selectedCategoryIds });
};

export const selectedIngredientsFilter = (ingredient, { selectedIngredientIds }) => {
  if (selectedIngredientIds.includes(ingredient.id)) {
    return true;
  }

  return false;
};

export const ingredientsSort = (ingredientA, ingredientB, { ingredientCategories }) => {
  const ingredientACategory = ingredientCategories[ingredientA.ingredientCategory];
  const ingredientBCategory = ingredientCategories[ingredientB.ingredientCategory];

  if (ingredientACategory !== undefined && ingredientBCategory === undefined) return -1;
  if (ingredientACategory === undefined && ingredientBCategory !== undefined) return 1;
  if (ingredientACategory === undefined && ingredientBCategory === undefined) return 0;

  if (ingredientACategory.cyoSection < ingredientBCategory.cyoSection) return -1;
  if (ingredientACategory.cyoSection > ingredientBCategory.cyoSection) return 1;
  if (ingredientACategory.order < ingredientBCategory.order) return -1;
  if (ingredientACategory.order > ingredientBCategory.order) return 1;
  if (ingredientA.order < ingredientB.order) return -1;
  if (ingredientA.order > ingredientB.order) return 1;

  return 0;
};

export const filterIngredients = (ingredients, query, food) => {
  const {
    baseId,
    selectedIngredientIds,
    selectedSectionIds,
    selectedCategoryIds,
    selectedTagIds,
    includeDeletedIngredients,
    includeOnlyCyo,
    includeNullBase,
    includeNullSection,
  } = query;

  return Object.values(ingredients)
    .filter((ingredient) => {
      if (
        selectedIngredientIds !== undefined &&
        selectedIngredientsFilter(ingredient, {
          selectedIngredientIds,
        }) !== true
      ) {
        return false;
      }

      if (!includeDeletedIngredients && ingredient.isDeleted) {
        return false;
      }

      if (
        baseId !== undefined &&
        baseIngredientsFilter(ingredient, {
          baseId,
          includeNull: includeNullBase,
        }) !== true
      ) {
        return false;
      }

      if (
        selectedSectionIds !== undefined &&
        sectionsIngredientsFilter(ingredient, {
          selectedSectionIds,
          ingredientCategories: food.ingredientCategories,
          includeNull: includeNullSection,
        }) !== true
      ) {
        return false;
      }

      if (
        selectedCategoryIds !== undefined &&
        categoryIngredientsFilter(ingredient, {
          selectedCategoryIds,
        }) !== true
      ) {
        return false;
      }

      if (
        selectedTagIds !== undefined &&
        tagIngredientsFilter(ingredient, {
          selectedTagIds,
          tags: food.tags,
        }) !== true
      ) {
        return false;
      }

      if (includeOnlyCyo === true && cyoIngredientsFilter(ingredient) !== true) {
        return false;
      }

      return true;
    })
    .sort((ingredientA, ingredientB) => {
      if (food.ingredientCategories === undefined) {
        return 0;
      }

      return ingredientsSort(ingredientA, ingredientB, {
        ingredientCategories: food.ingredientCategories,
      });
    });
};

export const filterIngredientServings = (ingredients, ingredientServings, query, food) => {
  const result = {};

  const selectedIngredientIds =
    query.selectedIngredientIds || ingredientIdsArray(ingredientServings);
  const filteredIngredients = filterIngredients(
    ingredients,
    { ...query, selectedIngredientIds },
    food,
  );
  filteredIngredients.map((ingredient) => {
    result[ingredient.id] = ingredientServings[ingredient.id];
    return ingredient;
  });

  return result;
};

export const filterHighlightedIngredientServings = ({
  sectionIds,
  ingredientId,
  highlightedIngredientServings,
  ingredients,
  ingredientCategories,
}) => {
  /* Show nutritional info for a single ingredient */
  if (ingredientId) {
    return { [ingredientId]: 1 };
  }

  /* Show nutrtional info for highlighted ingredients */
  const query = {
    selectedSectionIds: sectionIds,
    includeNullSection: true,
  };

  return filterIngredientServings(ingredients, highlightedIngredientServings, query, {
    ingredientCategories,
  });
};

export const processBulkQuantity = ({ ingredientServings, bulkQuantity }) =>
  mapValues(ingredientServings, (ingdSrv) => ingdSrv * bulkQuantity);

export const removeDuplicateBaseIngredient = (ingredients, baseId) => {
  const query = {
    baseId,
    includeNullBase: false,
  };
  const baseTypeIngredients = filterIngredients(ingredients, query, {});

  if (baseTypeIngredients.length === 0) {
    return ingredients;
  }

  return ingredients.filter((ingredient) => !INGREDIENT_BASES[ingredient.id]);
};

export const ingredientChangesArray = (ingredientBreakdown, ingredients) => {
  const addedIngredients = ingredientBreakdown
    .filter(({ direction }) => direction === 'addon')
    .map((ingredient) => ({
      name: ingredients[ingredient.ingredient].name,
      difference: ingredient.serving,
    }));
  const removedIngredients = ingredientBreakdown
    .filter(({ direction }) => direction === 'removed')
    .map((ingredient) => ({
      name: ingredients[ingredient.ingredient].name,
      difference: ingredient.serving,
    }));
  const substituteIngredients = ingredientBreakdown
    .filter(({ direction }) => direction === 'substitute')
    .map((ingredient) => ({
      name: ingredients[ingredient.ingredient].name,
      difference: ingredient.serving,
    }));

  return {
    added: addedIngredients,
    removed: removedIngredients,
    substitute: substituteIngredients,
  };
};

export const missingIngredientsText = (missingIngredientsArray) => {
  const result = missingIngredientsArray
    .map((ingredient) => {
      if (ingredient.difference <= 1) {
        return ingredient.name;
      }

      return `${ingredient.name} (${ingredient.difference})`;
    })
    .join(', ');

  return result && result[0].toUpperCase() + result.slice(1).toLowerCase();
};

export const getCompositeIngredient = (ingredientServings, ingredients) => {
  if (Object.keys(ingredientServings).length !== 1) {
    return false;
  }

  const ingredientId = Object.keys(ingredientServings)[0];
  const ingredient = ingredients[ingredientId];

  return ingredient.isComposite && ingredient;
};

export const defaultIngredientsText = (selectedIngredients, ingredients) => {
  const ingredientText = Object.keys(selectedIngredients)
    .map((ingredientId) => {
      if (selectedIngredients[ingredientId] === 1) {
        return ingredients[ingredientId].name;
      }
      return `${ingredients[ingredientId].name} (${selectedIngredients[ingredientId]})`;
    })
    .join(', ');

  return ingredientText;
};

export const ingredientChangesText = (ingredientBreakdown, ingredients) => {
  const ingredientChanges = ingredientChangesArray(ingredientBreakdown, ingredients);

  const addedAndSubstituteIngredients = [
    ...ingredientChanges.added,
    ...ingredientChanges.substitute.filter(
      (substituteIngredient) =>
        !ingredientChanges.added.some(
          (addedIngredient) => addedIngredient.name === substituteIngredient.name,
        ),
    ),
  ];

  const addedIngredientsText = missingIngredientsText(addedAndSubstituteIngredients);
  const removedIngredientsText = missingIngredientsText(ingredientChanges.removed);
  const substituteIngredientsText = missingIngredientsText(ingredientChanges.substitute);

  return {
    added: addedIngredientsText,
    removed: removedIngredientsText,
    substitute: substituteIngredientsText,
  };
};

export const totalIngredientNutrientAmounts = (serving, nutrientAmounts) => {
  const result = {};
  Object.keys(nutrientAmounts).map((nutrientId) => {
    const nutrientAmount = nutrientAmounts[nutrientId] * serving;
    result[nutrientId] = nutrientAmount;
    return nutrientId;
  });

  return result;
};

export const ingredientServingsNutrientAmounts = (
  ingredientServings,
  ingredientNutrientAmounts,
) => {
  const result = {};
  Object.keys(ingredientServings).map((ingredientId) => {
    const serving = ingredientServings[ingredientId];
    const nutrientAmounts = ingredientNutrientAmounts[ingredientId];
    if (nutrientAmounts === undefined) return ingredientId;

    result[ingredientId] = totalIngredientNutrientAmounts(serving, nutrientAmounts);
    return ingredientId;
  });

  return result;
};

export const totalIngredientsNutrientAmounts = (ingredientServings, ingredientNutrientAmounts) => {
  const result = {};
  const currentIngredientsNutrientAmounts = ingredientServingsNutrientAmounts(
    ingredientServings,
    ingredientNutrientAmounts,
  );
  Object.keys(currentIngredientsNutrientAmounts).map((ingredientId) => {
    const currentNutrientAmounts = currentIngredientsNutrientAmounts[ingredientId];

    Object.keys(currentNutrientAmounts).map((nutrientId) => {
      const currentTotalNutrientAmount = result[nutrientId] || 0;
      const currenttNutrientAmount = currentNutrientAmounts[nutrientId];

      result[nutrientId] = currentTotalNutrientAmount + currenttNutrientAmount;

      return nutrientId;
    });

    return ingredientId;
  });

  return result;
};

export const basesArray = (bases) =>
  Object.values(bases).sort((baseA, baseB) => {
    if (baseA.order < baseB.order) return -1;
    if (baseA.order > baseB.order) return 1;
    return 0;
  });

export const menuItemHasEveryTag = (menuItem, tagIds) =>
  tagIds.every((tagId) => menuItem.tags.includes(tagId));

export const menuItemsMatchingTags = (menuItems, tagIds) =>
  menuItems.filter((menuItem) => menuItemHasEveryTag(menuItem, tagIds));

export const sortedMenuItems = (menuItems) =>
  menuItems.sort((menuItemA, menuItemB) => {
    if (menuItemA.flags.length > 0 && menuItemB.flags.length === 0) return -1;
    if (menuItemA.flags.length === 0 && menuItemB.flags.length > 0) return 1;
    if (menuItemA.order < menuItemB.order) return -1;
    if (menuItemA.order > menuItemB.order) return 1;
    return 0;
  });

export const sortedNutrients = (nutrients) =>
  Object.values(nutrients).sort((nutrientA, nutrientB) => {
    if (nutrientA.group < nutrientB.group) return -1;
    if (nutrientA.group > nutrientB.group) return 1;
    if (nutrientA.orderInGroup < nutrientB.orderInGroup) return -1;
    if (nutrientA.orderInGroup > nutrientB.orderInGroup) return 1;
    return 0;
  });

export const getMenuCategoriesArray = (menuCategories) =>
  Object.values(menuCategories).sort((menuCategoryA, menuCategoryB) => {
    if (menuCategoryA.order < menuCategoryB.order) return -1;
    if (menuCategoryA.order > menuCategoryB.order) return 1;
    return 0;
  });

export const filterCategoriesWithMenuItems = (menuCategoriesArray, menuItemsArray) => {
  const menuCategoryIds = new Set(menuItemsArray.map((menuItem) => menuItem.menuCategory));
  const menuCategoriesFiltered = menuCategoriesArray.filter((menuCategory) =>
    menuCategoryIds.has(menuCategory.id),
  );

  return menuCategoriesFiltered;
};

export const baseIngredientsArray = (base, ingredientsArray, includeNull = true) =>
  ingredientsArray.filter(
    (ingredient) => ingredient.baseType === base || (includeNull && ingredient.baseType === null),
  );

export const baseMenuCategories = (baseId, menuCategories) =>
  menuCategories.filter((menuCategory) => menuCategory.baseType === baseId);

export const ingredientsGroupIdsArray = (ingredientsArray) => [
  ...new Set(
    ingredientsArray.reduce(
      (groupsArray, ingredient) => groupsArray.concat(ingredient.ingredientGroups),
      [],
    ),
  ),
];

export const ingredientsTagsArray = (ingredientsArray, tags) => {
  const groupIds = ingredientsGroupIdsArray(ingredientsArray);
  const ingredientLevelTags = getIngredientLevelTags(tags);
  const exclude = excludeGroupIdsTagsArray(groupIds, ingredientLevelTags);
  const include = includeGroupIdsArray(groupIds, ingredientLevelTags);

  return intersection(exclude, include);
};

export const tagIngredientServings = (
  selectedTagIds,
  selectedIngredientServings,
  ingredients,
  tags,
) => {
  const selectedIngredientIds = ingredientIdsArray(selectedIngredientServings);
  const query = { selectedIngredientIds, selectedTagIds };
  const food = { tags };
  const filteredIngredients = filterIngredients(ingredients, query, food);

  const result = {};
  filteredIngredients.map((ingredient) => {
    result[ingredient.id] = selectedIngredientServings[ingredient.id];
    return ingredient;
  });

  return result;
};

/* A function to return the ingredients that would be removed by the tags */
export const inverseTagIngredientServings = (
  selectedTagIds,
  selectedIngredientServings,
  ingredients,
  tags,
) => {
  const selectedIngredientIds = ingredientIdsArray(selectedIngredientServings);
  const query = { selectedIngredientIds, selectedTagIds };
  const food = { tags };
  const filteredIngredients = filterIngredients(ingredients, query, food);

  const result = {};

  Object.entries(selectedIngredientServings).forEach(([key, value]) => {
    if (!Object.keys(filteredIngredients).includes(key)) {
      result[key] = value;
    }
  });

  return result;
};

export const addIngredientServings = (ingredientServingsA, ingredientServingsB) => {
  const ingredientServings = { ...ingredientServingsA };
  const ingredientIds = ingredientIdsArray(ingredientServingsB);

  ingredientIds.map((ingredientId) => {
    const servingsA = ingredientServingsA[ingredientId] || 0;
    const servingsB = ingredientServingsB[ingredientId];
    ingredientServings[ingredientId] = servingsA + servingsB;
    return ingredientId;
  });

  return ingredientServings;
};

export const removeIngredient = (ingredientServings, id) => {
  const result = { ...ingredientServings };
  delete result[id];

  return result;
};

export const addBaseToIngredientServings = (ingredientServings, baseId) => {
  const baseIngredientId = Object.keys(INGREDIENT_BASES).filter(
    (ingredientId) => INGREDIENT_BASES[ingredientId] === baseId,
  )[0];

  if (!baseIngredientId) return ingredientServings;
  if (ingredientServings[baseIngredientId] !== undefined) return ingredientServings;

  return { ...ingredientServings, [baseIngredientId]: 1 };
};

export const removeSectionIngredients = (
  sectionId,
  { selectedIngredientServings, ingredientCategories, ingredients },
) => {
  const selectedIngredientIds = ingredientIdsArray(selectedIngredientServings);
  const query = {
    selectedIngredientIds,
    selectedSectionIds: [sectionId],
  };
  const food = { ingredientCategories };
  const filteredIngredients = filterIngredients(ingredients, query, food);
  const filteredIngredientIds = filteredIngredients.map((ingredient) => ingredient.id);

  const result = {};
  selectedIngredientIds
    .filter((ingredientId) => filteredIngredientIds.includes(ingredientId) === false)
    .map((ingredientId) => {
      result[ingredientId] = selectedIngredientServings[ingredientId];
      return ingredientId;
    });

  return result;
};

export const restoreSectionIngredients = (
  sectionId,
  { selectedIngredientServings, defaultIngredientServings, ingredientCategories, ingredients },
) => {
  const defaultIngredients = Object.keys(defaultIngredientServings).map(
    (ingredientId) => ingredients[ingredientId],
  );
  const query = { selectedSectionIds: [sectionId] };
  const food = { ingredientCategories };
  const defaultSectionIngredients = filterIngredients(defaultIngredients, query, food);
  const defaultSectionIngredientIds = defaultSectionIngredients.map(
    (sectionIngredient) => sectionIngredient.id,
  );

  const result = { ...selectedIngredientServings };
  defaultSectionIngredientIds.map((ingredientId) => {
    result[ingredientId] = defaultIngredientServings[ingredientId];
    return ingredientId;
  });

  return result;
};

export const updateMenuItems = (currentMenuItems, incomingMenuItems) => {
  const mergedMenuItems = { ...currentMenuItems, ...incomingMenuItems };
  const newestMenuItems = {};

  Object.values(mergedMenuItems)
    .filter((menuItem) => menuItem.customized === false)
    .sort((menuItemA, menuItemB) => {
      if (menuItemA.id < menuItemB.id) return 1;
      if (menuItemA.id > menuItemB.id) return -1;
      return 0;
    })
    .map((menuItem) => {
      newestMenuItems[menuItem.uuid] = menuItem;
      return menuItem;
    });

  const newestMenuItemIds = Object.values(newestMenuItems).map((menuItem) => menuItem.id);

  Object.keys(mergedMenuItems).map((menuItemId) => {
    if (newestMenuItemIds.includes(parseInt(menuItemId, 10))) {
      mergedMenuItems[menuItemId].hidden = false;
    } else {
      mergedMenuItems[menuItemId].hidden = true;
    }
    return menuItemId;
  });

  return mergedMenuItems;
};

export const hasRequiredIngredients = (
  baseId,
  sectionId,
  {
    ingredients,
    ingredientCategories,
    selectedIngredientServings,
    sectionSettings,
    baseTypeSettings,
  },
) => {
  const currentSectionSettings = getSectionSettings({
    baseId,
    sectionId,
    sectionSettings,
    baseTypeSettings,
  });

  if (currentSectionSettings.required === true) {
    const query = {
      baseId,
      selectedSectionIds: [sectionId],
      includeNullBase: sectionId !== BASE_SECTION_ID,
    };
    const sectionIngredientServings = filterIngredientServings(
      ingredients,
      selectedIngredientServings,
      query,
      { ingredientCategories },
    );

    if (Object.keys(sectionIngredientServings).length === 0) {
      return false;
    }
  }

  return true;
};

export const defaultIngredientServing = (
  baseId,
  menuUuid,
  ingredientId,
  { ingredients, servingNumberVariations },
) => {
  const ingredient = ingredients[ingredientId];

  const matchedServingNumberVariation = servingNumberVariations.find((servingNumberVariation) => {
    if (servingNumberVariation.baseType && servingNumberVariation.baseType !== baseId) {
      return false;
    }
    if (servingNumberVariation.menuUuid && servingNumberVariation.menuUuid !== menuUuid) {
      return false;
    }
    if (
      servingNumberVariation.ingredientCategory &&
      servingNumberVariation.ingredientCategory !== ingredient.ingredientCategory
    ) {
      return false;
    }
    if (servingNumberVariation.ingredient && servingNumberVariation.ingredient !== ingredient.id) {
      return false;
    }
    return true;
  });

  return matchedServingNumberVariation ? matchedServingNumberVariation.defaultServingNumber : 1;
};

export const getDefaultPersonalSettings = ({ preferenceGroups }) => {
  const preferences = {};

  Object.values(preferenceGroups).map((preferenceGroup) => {
    preferences[preferenceGroup.id] = preferenceGroup.defaultPreference;
    return preferenceGroup;
  });

  return {
    id: uuid4(),
    label: '',
    preferences,
  };
};

export const findMissingIngredientServings = (selectedIngredientServings, { ingredients }) => {
  const selectedIngredientIds = Object.keys(selectedIngredientServings);

  return selectedIngredientIds
    .filter((ingredientId) => ingredients[ingredientId] === undefined)
    .reduce(
      (ingredientServings, ingredientId) => ({
        ...ingredientServings,
        [ingredientId]: selectedIngredientServings[ingredientId],
      }),
      {},
    );
};

export const getRemovedIngredientServings = (
  defaultIngredientServings,
  selectedIngredientServings,
) =>
  Object.entries(defaultIngredientServings).reduce((acc, [key, val]) => {
    if (selectedIngredientServings[key]) {
      return acc + (val - selectedIngredientServings[key]);
    }
    return acc + val;
  }, 0);

/* Given an ingredientsBreakdown, retrieve the number of removed servings in a section */
export const getSectionRemovedIngredientServings = (
  ingredients,
  ingredientCategories,
  sectionId,
  defaultIngredientServings,
  selectedIngredientServings,
) => {
  const sectionDefaultIngredientServings = filterIngredientServings(
    ingredients,
    defaultIngredientServings,
    { selectedSectionIds: [sectionId] },
    { ingredientCategories },
  );
  const sectionSelectedIngredientServings = filterIngredientServings(
    ingredients,
    selectedIngredientServings,
    { selectedSectionIds: [sectionId] },
    { ingredientCategories },
  );

  return getRemovedIngredientServings(
    sectionDefaultIngredientServings,
    sectionSelectedIngredientServings,
  );
};

export const baseToItem = (base) => ({
  id: `b_${base.id}`,
  baseId: base.id,
  categoryId: null,
  name: base.name,
  category: false,
});

export const menuCategoryToItem = (menuCategory) => ({
  id: `c_${menuCategory.id}`,
  baseId: menuCategory.baseType,
  categoryId: menuCategory.id,
  name: menuCategory.name,
  order: menuCategory.order,
  category: true,
});

export const menuCategoriesToItems = (menuCategories) => menuCategories.map(menuCategoryToItem);

export const sortMenuNavItems = (navItemA, navItemB) => {
  if (!navItemA.category && navItemB.category) return -1;
  if (navItemA.category && !navItemB.category) return 1;
  if (!navItemA.category && !navItemB.category) return navItemA.baseId - navItemB.baseId;
  if (navItemA.order === navItemB.order) return navItemA.baseId - navItemB.baseId;
  return navItemA.order - navItemB.order;
};

export const getConflictedTags = (
  ingredient,
  selectedTagIds,
  tags,
  ingredientLevelOnly = false,
) => {
  const ingredientLevelTags = getIngredientLevelTags(tags);
  const ingredientLevelTagsIds = Object.values(ingredientLevelTags).map((tag) => tag.id);
  const validTags = ingredientTagsArray(ingredient, tags);
  const validTagsIds = validTags.map((tag) => tag.id);
  const conflictedTagIds = selectedTagIds
    .filter((tagId) => !validTagsIds.includes(tagId))
    .filter((tagId) => !ingredientLevelOnly || ingredientLevelTagsIds.includes(tagId));

  return conflictedTagIds.map((tagId) => tags[tagId]);
};

export const getConflictedTagNames = ({
  ingredient,
  selectedTagIds,
  tags,
  ingredientLevelOnly = false,
}) => {
  const conflictedTags = getConflictedTags(ingredient, selectedTagIds, tags, ingredientLevelOnly);

  return upperFirst(
    conflictedTags
      .map((tag) => tag.name)
      .map((name) => name.replace(/^[Nn]o /, ''))
      .join(', ')
      .toLowerCase(),
  );
};

export const tagTypeNameMapping = (tagValue) => {
  const matchType = Object.entries(tagTypeEnum).find(([, value]) => value === tagValue);
  if (matchType) {
    return toCamelCaseString(`${matchType[0].toLowerCase()}Tags`);
  }
  return toCamelCaseString(`${tagValue}Tags`);
};

export const groupTagsByType = (tags, includedIds = []) =>
  chain(tags)
    .filter((tag) => includedIds.length === 0 || includedIds.includes(tag.id))
    .groupBy('tagType')
    .mapKeys((value, key) => tagTypeNameMapping(key))
    .value();

// NOTE: If you wish to order by tag type, use the Tag.order to do the trick instead
// @example 1XX for type A and 2XX for type b
export const sortDietTags = (a, b) => {
  if (a.order !== b.order) {
    return a.order - b.order;
  }
  return a.id - b.id;
};
