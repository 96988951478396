import { all, delay, put, select, takeEvery } from 'redux-saga/effects';

import * as foodActions from '../actions/food';
import { goToMenu } from '../actions/history';
import * as orderActions from '../actions/order';
import { restoreRemovedTagIngredients } from '../actions/orderItem';
import { getSearchParam, setSearchParams } from '../utils/historyUtils';

import * as selectors from './selectors';

/*
  A hack to refresh all waypoints after the filters are applied
  Alternative would include applying random keys to MenuItems on render, but then animation
  would trigger on MenuItems that stay in the view.
*/
function* refreshWindow() {
  yield delay(50);
  window.dispatchEvent(new Event('resize'));
}

function* mitigateMissingOrderItem(action) {
  const order = yield select(selectors.getOrder);
  const { search } = yield select(selectors.getCurrentLocation);

  if (getSearchParam(search, 'orderItemId') === action.orderItemId) {
    yield put(
      goToMenu({
        params: {
          search: setSearchParams(search, {
            gglocation: order.gglocationId,
            timeSlot: order.timeSlotId,
          }),
        },
      }),
    );
  }
}

function* handleRemoveTagId(action) {
  const tagId = action.id;

  yield put(restoreRemovedTagIngredients({ tagId }));
}

function* watchFood() {
  yield takeEvery(foodActions.SAVE_HIGHLIGHTED_TAG_IDS, refreshWindow);
  yield takeEvery(orderActions.REMOVE_ORDER_ITEM, mitigateMissingOrderItem);
  yield takeEvery(foodActions.REMOVE_HIGHLIGHTED_TAG_ID, handleRemoveTagId);
}

export default function* foodSaga() {
  yield all([watchFood()]);
}
