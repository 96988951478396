import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ProgressBarStep } from './progress-bar-step.svg';

import './ProgressBar.css';

function ProgressBar({ value, width = 100, steps }) {
  return (
    <div className="ProgressBarBackground" style={{ width: `${width > 0 ? width : 0}%` }}>
      <div className="ProgressBar" style={{ width: `${value > 0 ? value : 0}%` }} />
      {steps.map((step) => (
        <ProgressBarStep
          key={`progress-bar-step-${step}`}
          className="ProgressBarStep"
          style={{ left: `calc(${step}% - 8px - 8px)` }}
        />
      ))}
    </div>
  );
}

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  width: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.number),
};

ProgressBar.defaultProps = {
  width: 100,
  steps: [],
};

export default ProgressBar;
