import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import historyLocationShape from '../../../shapes/historyLocationShape';

import Button from '../../Button';
import BackButton from '../BackButton';
import { ReactComponent as IconBack } from '../BackButton/icon-top-bar-back.svg';
import MenuMobileButton from '../MenuMobileButton';

function TopLeftButton(props) {
  const {
    isOverlay,
    caption,
    previousLocation,
    isBackButtonForceShow,
    onHomeClick,
    onGoBackClick,
    onMobileMenuClick,
  } = props;

  return (
    <div className="TopBarLeftButton">
      <div>
        <Button className="HomeButton" onClick={onHomeClick}>
          <IconBack />{' '}
          <Typography variant="body1" fontWeight="600">
            Home
          </Typography>
        </Button>
        {caption !== false && (previousLocation || isOverlay) && (
          <BackButton forceBackButtonShow={isBackButtonForceShow} onClick={onGoBackClick}>
            {caption}
          </BackButton>
        )}
      </div>
      <MenuMobileButton onClick={onMobileMenuClick} />
    </div>
  );
}

TopLeftButton.propTypes = {
  isOverlay: PropTypes.bool,
  caption: PropTypes.string,
  previousLocation: historyLocationShape.isRequired,
  isBackButtonForceShow: PropTypes.bool,
  onHomeClick: PropTypes.func.isRequired,
  onGoBackClick: PropTypes.func.isRequired,
  onMobileMenuClick: PropTypes.func.isRequired,
};

TopLeftButton.defaultProps = {
  isOverlay: false,
  isBackButtonForceShow: false,
  caption: null,
};

export default TopLeftButton;
