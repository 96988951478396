import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { getIsOrderTaxLineHidden } from '../../../sagas/selectors';

import Currency from '../../Currency';

import { SALES_TAX_NAME } from '../../../settings';

import './CartPriceLine.css';

export function CartPriceLine({ className, title, price, tax, typographyVariant }) {
  const isOrderTaxLineHidden = useSelector(getIsOrderTaxLineHidden);
  return (
    <>
      <ul className={classnames('CartPriceLine', className)}>
        <li>
          <p className="CartPriceLineTitle">
            <Typography variant={typographyVariant} color="grey.main" fontWeight="600">
              {title}
            </Typography>
          </p>
        </li>
        <li>
          <p className="TotalPrice">
            <Currency value={price} typographyVariant={typographyVariant} />
          </p>
        </li>
      </ul>
      {tax !== null && !isOrderTaxLineHidden && (
        <Typography className="Tax" variant="subtitle2" align="left">
          Inclusive of
          <Currency value={tax} />
          {SALES_TAX_NAME}
        </Typography>
      )}
    </>
  );
}

CartPriceLine.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  tax: PropTypes.number,
  typographyVariant: PropTypes.string,
};

CartPriceLine.defaultProps = {
  className: '',
  tax: null,
  typographyVariant: 'subtitle1',
};

export default CartPriceLine;
