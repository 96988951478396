import { findLast } from 'lodash';

import { SENTRY_IGNORE_ERROR_MAPPING } from '../settings';

export default function filterSentryErrors(event, matchedMessage) {
  if (event?.breadcrumbs) {
    /* Look for the errors to ignore in the last 5 breadcrumbs, we want to avoid looking
     further so that we don't get a lot of false negatives */
    return findLast(event.breadcrumbs.slice(-5), (breadcrumb) => {
      /* Only take events with an action category */
      if (breadcrumb?.category === 'action') {
        /* If the right message is found, filter for the responses of that event */
        return SENTRY_IGNORE_ERROR_MAPPING[matchedMessage]?.some((ignoreResponse) =>
          breadcrumb?.data?.response?.includes(ignoreResponse),
        );
      }
      return false;
    });
  }

  return null;
}
