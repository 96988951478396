import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as loyaltyActions from '../../actions/loyalty';
import statusEnum from '../../enums/statusEnum';

import massRewardShape from '../../shapes/massRewardShape';
import rewardShape from '../../shapes/rewardShape';

import LoyaltyRewardsList from '../../components/LoyaltyRewardsList';

import { loyaltyService } from '../../services/api';

class LoyaltyRewardsListContainer extends Component {
  static propTypes = {
    size: PropTypes.oneOf(['Small', 'Medium']).isRequired,
    showHeader: PropTypes.bool,
    claims: PropTypes.objectOf(rewardShape).isRequired,
    initDataStatus: PropTypes.number.isRequired,
    selectedUserRewardUuid: PropTypes.string,
    fetchInitData: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    brand: PropTypes.string,
    selectedMassReward: rewardShape,
    massRewardClaims: PropTypes.arrayOf(massRewardShape).isRequired,
    redeemMassReward: PropTypes.func.isRequired,
    setHighlightedUserReward: PropTypes.func.isRequired,
    onRedeem: PropTypes.func,
    redeemMassRewardStatus: PropTypes.number.isRequired,
  };

  static defaultProps = {
    showHeader: false,
    brand: null,
    selectedUserRewardUuid: null,
    onRedeem: null,
    selectedMassReward: {},
  };

  constructor(props, context) {
    const { fetchInitData, uuid, brand, initDataStatus } = props;

    if (initDataStatus < statusEnum.SUCCESS && uuid) {
      fetchInitData({ brand });
    }

    super(props, context);
  }

  componentDidUpdate(prevProps) {
    const { redeemMassRewardStatus } = this.props;

    if (
      prevProps.redeemMassRewardStatus !== statusEnum.SUCCESS &&
      redeemMassRewardStatus === statusEnum.SUCCESS
    ) {
      const { selectedMassReward, setHighlightedUserReward } = this.props;
      setHighlightedUserReward({ userRewardUuid: selectedMassReward?.uuid });
    }
  }

  get claims() {
    const { claims, massRewardClaims } = this.props;

    const claimArr = claims.filter((claim) => ['AWARDED', 'NOTIFIED'].includes(claim.rewardStatus));
    return claimArr.concat(massRewardClaims);
  }

  get hasSelectedUserReward() {
    const { selectedUserRewardUuid } = this.props;

    return selectedUserRewardUuid !== null;
  }

  handleRedeem = (userReward) => {
    const { redeemMassReward, setHighlightedUserReward, onRedeem } = this.props;

    if (isFunction(onRedeem)) {
      onRedeem();
    }

    if (userReward?.isMassReward) {
      redeemMassReward({ rewardId: userReward.id });
      return;
    }

    setHighlightedUserReward({ userRewardUuid: userReward?.uuid });
  };

  render() {
    const { size, showHeader } = this.props;

    if (this.claims.length === 0) return null;
    if (this.hasSelectedUserReward) return null;

    return (
      <LoyaltyRewardsList
        size={size}
        showHeader={showHeader}
        rewards={this.claims}
        onRedeem={this.handleRedeem}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  uuid: state.user.uuid,
  brand: state.api.settings?.loyaltyBrandPrefix,
  claims: state.loyalty.claims,
  initDataStatus: state.loyalty.status.initData,
  redeemMassRewardStatus: state.loyalty.status.redeemMassReward,
  selectedMassReward: state.loyalty.selectedMassReward,
  massRewardClaims: state.loyalty.massRewardClaims,
  selectedUserRewardUuid: state.loyalty.selectedUserRewardUuid,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      redeemMassReward: loyaltyService.redeemMassReward.requestActionCreator,
      setHighlightedUserReward: loyaltyActions.setHighlightedUserReward,
      fetchInitData: loyaltyService.initData.requestActionCreator,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyRewardsListContainer);
