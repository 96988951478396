import { mapValues } from 'lodash';
import moment from 'moment';
import smartlookClient from 'smartlook-client';

import { GIT_HASH, VERSION } from '../version';

import * as Sentry from '../services/sentry';

/* LoggingCase determines what events should be logged. */
export const LoggingCase = Object.freeze({
  ANY: 0,
  ERROR: 1,
});

export const captureBreadcrumb = ({ category, message, data }) => {
  const parsedData = mapValues(data, (value) => JSON.stringify(value));

  Sentry.addBreadcrumb({
    category,
    message,
    level: 'info',
    data: parsedData,
  });

  try {
    smartlookClient.track(message, parsedData);
  } catch (e) {
    /*
      In some rare cases this might be called thrown before Smartlook is initialized.
      The default behavior in Smartlook is to throw an exception, instead we will silence
      the error.
    */
  }
};

export const tagSession = ({ key, value }) => {
  Sentry.configureScope((scope) => {
    scope.setTag(key, value);
  });

  try {
    smartlookClient.track(key, value);
  } catch (e) {
    /*
      In some rare cases this might be called thrown before Smartlook is initialized.
      The default behavior in Smartlook is to throw an exception, instead we will silence
      the error.
    */
  }
};

export const logCall = ({ func, args, eventName, result }) => {
  captureBreadcrumb({
    message: eventName || `Calling function: ${func.name}`,
    category: 'action',
    data: {
      name: func.name,
      args,
      result,
    },
  });
};

/* logEvent determines what events should be logged. */
export const callWithLogging = ({ func, args, eventName, eventLoggingCase }) => {
  try {
    const result = func(...args);

    if (LoggingCase.ANY === eventLoggingCase) {
      logCall({
        func,
        args,
        eventName,
        result,
      });
    }

    return result;
  } catch (error) {
    if ([LoggingCase.ANY, LoggingCase.ERROR].includes(eventLoggingCase)) {
      logCall({ func, args, eventName });
    }

    throw error;
  }
};

export const captureTime = () => {
  captureBreadcrumb({
    message: `Local time ${moment().toString()}`,
    category: 'action',
    data: {},
  });
};

export const captureVersion = () => {
  tagSession({ key: 'version.number', value: VERSION });
  tagSession({ key: 'git.hash', value: GIT_HASH });
};

export const setTrackingContext = () => {
  captureTime();
  captureVersion();
};
