import itemTypeEnum from '../enums/itemTypeEnum';

import { includeState } from './includeState';

export const CREATE_ORDER_ITEM_FROM_ITEM_ID = 'CREATE_ORDER_ITEM_FROM_ITEM_ID';
export const CREATE_ORDER_ITEM_SUCCESS = 'CREATE_ORDER_ITEM_SUCCESS';
export const CREATE_ORDER_ITEM_ERROR = 'CREATE_ORDER_ITEM_ERROR';

export const MODIFY_ORDER_ITEM = 'MODIFY_ORDER_ITEM';
export const SAVE_ORDER_ITEM = 'SAVE_ORDER_ITEM';

export const RESET_ORDER_ITEM = 'RESET_ORDER_ITEM';

export const SET_INGREDIENT_SERVINGS = 'SET_INGREDIENT_SERVINGS';
export const ADD_INGREDIENT_SERVINGS = 'ADD_INGREDIENT_SERVINGS';
export const REMOVE_INGREDIENT = 'REMOVE_INGREDIENT';
export const REMOVE_SECTION_INGREDIENTS = 'REMOVE_SECTION_INGREDIENTS';
export const RESTORE_SECTION_INGREDIENTS = 'RESTORE_SECTION_INGREDIENTS';
export const REPLACE_SECTION_INGREDIENTS = 'REPLACE_SECTION_INGREDIENTS';
export const INCREMENT_INGREDIENT_SERVING = 'INCREMENT_INGREDIENT_SERVING';
export const DECREMENT_INGREDIENT_SERVING = 'DECREMENT_INGREDIENT_SERVING';
export const SAVE_HIGHLIGHTED_INGREDIENT_SERVINGS = 'SAVE_HIGHLIGHTED_INGREDIENT_SERVINGS';
export const RESET_HIGHLIGHTED_INGREDIENT_SERVINGS = 'RESET_HIGHLIGHTED_INGREDIENT_SERVINGS';

export const UPDATE_LABEL = 'UPDATE_LABEL';
export const UPDATE_PREFERENCE = 'UPDATE_PREFERENCE';
export const INCREMENT_ORDER_ITEM_QUANTITY = 'INCREMENT_ORDER_ITEM_QUANTITY';
export const DECREMENT_ORDER_ITEM_QUANTITY = 'DECREMENT_ORDER_ITEM_QUANTITY';
export const SET_ACTIVE_NUTRITIONAL_GROUP = 'SET_ACTIVE_NUTRITIONAL_GROUP';

export const FILTER_TAG_INGREDIENT_SERVINGS = 'FILTER_TAG_INGREDIENT_SERVINGS';
export const RESTORE_REMOVED_TAG_INGREDIENTS = 'RESTORE_REMOVED_TAG_INGREDIENTS';

export const SET_FAV_MENU_CHANGED_ID = 'SET_FAV_MENU_CHANGED_ID';
export const RESET_FAV_MENU_CHANGED_ID = 'RESET_FAV_MENU_CHANGED_ID';

export const UPDATE_FAV_MENU_IS_CHANGED = 'UPDATE_FAV_MENU_IS_CHANGED';

export const createOrderItemFromItemId = ({
  itemId,
  itemType,
  personalSettings,
  isUpsold = false,
}) => ({
  type: CREATE_ORDER_ITEM_FROM_ITEM_ID,
  itemId,
  itemType,
  personalSettings,
  isUpsold,
});

export const createOrderItemFromMenuItemId = ({ menuItemId, personalSettings, isUpsold = false }) =>
  createOrderItemFromItemId({
    itemId: menuItemId,
    itemType: itemTypeEnum.MENU_ITEM,
    personalSettings,
    isUpsold,
  });

export const createOrderItemFromFavoriteMenuItemId = ({ favoriteMenuItemId, personalSettings }) =>
  createOrderItemFromItemId({
    itemId: favoriteMenuItemId,
    itemType: itemTypeEnum.FAVORITE_MENU_ITEM,
    personalSettings,
  });

export const createOrderItemFromUserOrderItemId = ({ userOrderItemId, personalSettings }) =>
  createOrderItemFromItemId({
    itemId: userOrderItemId,
    itemType: itemTypeEnum.USER_ORDER_ITEM,
    personalSettings,
  });

/*
  For most cases you would prefer calling createOrderItemFromMenuItemId instead that offers simpler
  API and that will programatically determine all of the values for you.
*/
export const createOrderItem = ({
  apiId,
  menuItemId,
  bulkQuantity,
  favoriteMenuItemId,
  personalSettings,
  selectedIngredientServings,
  highlightedIngredientServings,
  missingIngredientServings,
  defaultIngredientServings,
  ingredientBreakdown,
  landingPageSlug,
  itemType,
  orderingFavorite,
  orderItemStatus,
  isUpsold = false,
}) => ({
  type: CREATE_ORDER_ITEM_SUCCESS,
  apiId,
  menuItemId,
  bulkQuantity,
  favoriteMenuItemId,
  personalSettings,
  selectedIngredientServings,
  highlightedIngredientServings,
  missingIngredientServings,
  defaultIngredientServings,
  ingredientBreakdown,
  landingPageSlug,
  itemType,
  orderingFavorite,
  orderItemStatus,
  isUpsold,
});

export const createOrderItemError = ({ error }) => ({
  type: CREATE_ORDER_ITEM_ERROR,
  error,
});

export const resetOrderItem = () => ({
  type: RESET_ORDER_ITEM,
});

export const modifyOrderItem = ({ orderItem }) => ({
  type: MODIFY_ORDER_ITEM,
  orderItem,
});

export const saveOrderItem = () => ({
  type: SAVE_ORDER_ITEM,
});

export const setIngredientServings = (ingredientServings, { store }) => ({
  type: SET_INGREDIENT_SERVINGS,
  ingredientServings,
  store,
});

export const addIngredientServings = (ingredientServings, { store }) => ({
  type: ADD_INGREDIENT_SERVINGS,
  ingredientServings,
  store,
});

export const removeIngredient = (ingredientId, { store }) => ({
  type: REMOVE_INGREDIENT,
  ingredientId,
  store,
});

export const removeSectionIngredients = (sectionId, { store }) =>
  includeState({
    action: {
      type: REMOVE_SECTION_INGREDIENTS,
      sectionId,
      store,
    },
  });

export const restoreSectionIngredients = (sectionId, { store }) =>
  includeState({
    action: {
      type: RESTORE_SECTION_INGREDIENTS,
      sectionId,
      store,
    },
  });

export const replaceSectionIngredients = (sectionId, ingredientServings, { store }) =>
  includeState({
    action: {
      type: REPLACE_SECTION_INGREDIENTS,
      sectionId,
      ingredientServings,
      store,
    },
  });

export const incrementIngredientServing = (ingredientId, { store }) => ({
  type: INCREMENT_INGREDIENT_SERVING,
  ingredientId,
  store,
});

export const decrementIngredientServing = (ingredientId, { store }) => ({
  type: DECREMENT_INGREDIENT_SERVING,
  ingredientId,
  store,
});

export const saveHighlightedIngredientServings = () => ({
  type: SAVE_HIGHLIGHTED_INGREDIENT_SERVINGS,
});

export const resetHighlightedIngredientServings = () => ({
  type: RESET_HIGHLIGHTED_INGREDIENT_SERVINGS,
});

export const updateLabel = ({ id, value }) => ({
  type: UPDATE_LABEL,
  id,
  value,
});

export const updatePreference = ({ id, preferenceGroupId, preferenceId }) => ({
  type: UPDATE_PREFERENCE,
  id,
  preferenceGroupId,
  preferenceId,
});

export const incrementOrderItemQuantity = () =>
  includeState({
    action: {
      type: INCREMENT_ORDER_ITEM_QUANTITY,
    },
  });

export const decrementOrderItemQuantity = () => ({
  type: DECREMENT_ORDER_ITEM_QUANTITY,
});

export const filterTagIngredientServings = ({ tagIds }) =>
  includeState({
    action: {
      type: FILTER_TAG_INGREDIENT_SERVINGS,
      tagIds,
    },
  });

export const restoreRemovedTagIngredients = ({ tagId }) =>
  includeState({
    action: {
      type: RESTORE_REMOVED_TAG_INGREDIENTS,
      tagId,
    },
  });

export const setActiveNutritionalGroup = ({ nutritionalGroupId }) => ({
  type: SET_ACTIVE_NUTRITIONAL_GROUP,
  nutritionalGroupId,
});

export const setFavMenuChangedId = ({ favMenuChangedId }) => ({
  type: SET_FAV_MENU_CHANGED_ID,
  favMenuChangedId,
});

export const resetFavMenuChangedId = () => ({
  type: RESET_FAV_MENU_CHANGED_ID,
});

export const updateFavMenuIsChanged = ({ id, isChanged }) => ({
  type: UPDATE_FAV_MENU_IS_CHANGED,
  id,
  isChanged,
});
