import React from 'react';
import PropTypes from 'prop-types';

import Toast from '../Toast';

import './LimitNotification.css';

function LimitNotification({ id, name, limit, extra, isRemove, clearLimitNotification }) {
  return (
    <Toast id={id} clearLimitNotification={clearLimitNotification}>
      <div className="LimitNotification">
        Oops! You have selected a maximum {isRemove && <span>removal</span>} of{' '}
        {extra && <span>extra</span>} {limit} {name?.toLowerCase()}.
      </div>
    </Toast>
  );
}

LimitNotification.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  limit: PropTypes.number,
  extra: PropTypes.bool,
  isRemove: PropTypes.bool,
  clearLimitNotification: PropTypes.func.isRequired,
};

LimitNotification.defaultProps = {
  id: null,
  name: null,
  limit: null,
  extra: false,
  isRemove: false,
};

export default LimitNotification;
