import { removeError } from '@spq/redux-api-client';
import { rehydrateReducer } from '@spq/utils';
import { REHYDRATE } from 'redux-persist/lib/constants';

import * as loyaltyActions from '../actions/loyalty';
import * as orderActions from '../actions/order';
import * as pageActions from '../actions/page';
import * as userActions from '../actions/user';
import statusEnum from '../enums/statusEnum';

import packageInfo from '../../package.json';

const defaultState = Object.freeze({
  scanHistory: {
    pending: [],
    verified: [],
  },
  claims: [],
  rewards: [],
  promos: [],
  specials: [],
  massRewards: [],
  massRewardClaims: [],
  highlightedUserRewardUuid: null,
  selectedUserRewardUuid: null,
  selectedPromoCode: null,
  selectedRewardId: null,
  selectedRewardType: null,
  selectedMassReward: {},
  collections: [],
  errors: [],
  rewardUuids: [],
  status: {
    promoCode: statusEnum.INITIAL,
    claimReward: statusEnum.INITIAL,
    redeemMassReward: statusEnum.INITIAL,
    rewards: statusEnum.INITIAL,
    initData: statusEnum.INITIAL,
    scanHistory: statusEnum.INITIAL,
  },
  version: packageInfo.version,
});

// eslint-disable-next-line default-param-last
export default function loyaltyReducer(state = defaultState, action) {
  switch (action.type) {
    case REHYDRATE: {
      const incoming = action.payload && action.payload.loyalty;
      if (incoming && incoming.version === state.version) {
        let rehydrateReducerWhitelist = [];

        /* Check if the state should be restored */
        const { currentLocation } = action;
        if (currentLocation.state || window.hasRefreshed) {
          rehydrateReducerWhitelist = rehydrateReducerWhitelist.concat([
            'highlightedUserRewardUuid',
            'selectedUserRewardUuid',
            'selectedPromoCode',
            'scanHistory',
            'collections',
            'claims',
            'rewards',
            'promos',
            'massRewards',
            'massRewardClaims',
            'rewardUuids',
            'selectedCollectionId',
            'specials',
          ]);
        }

        return Object.freeze({
          ...rehydrateReducer(rehydrateReducerWhitelist, state, incoming),
          status: { ...state.status },
        });
      }
      return Object.freeze({ ...state });
    }
    case loyaltyActions.SET_HIGHLIGHTED_USER_REWARD:
      return Object.freeze({
        ...state,
        highlightedUserRewardUuid: action.userRewardUuid,
      });
    case loyaltyActions.UNSET_HIGHLIGHTED_USER_REWARD:
      return Object.freeze({
        ...state,
        highlightedUserRewardUuid: null,
        status: {
          ...state.status,
          claimReward: statusEnum.INITIAL,
        },
      });
    case loyaltyActions.CONFIRM_HIGHLIGHTED_REWARD:
      return Object.freeze({
        ...state,
        highlightedUserRewardUuid: null,
        selectedUserRewardUuid: state.highlightedUserRewardUuid,
      });
    case loyaltyActions.UNSET_SELECTED_USER_REWARD:
      return Object.freeze({
        ...state,
        selectedUserRewardUuid: null,
      });
    case userActions.SIGN_OUT:
      return {
        ...defaultState,
      };
    case loyaltyActions.SET_SELECTED_PROMO_CODE_REQUESTED:
      return {
        ...state,
        status: {
          ...state.status,
          promoCode: statusEnum.REQUESTED,
        },
      };
    case loyaltyActions.SET_SELECTED_PROMO_CODE_SUCCESS:
      return {
        ...state,
        selectedPromoCode: action.promoCode,
        errors: removeError(state.errors, 'promoCode'),
        status: {
          ...state.status,
          promoCode: statusEnum.SUCCESS,
        },
      };
    case loyaltyActions.SET_SELECTED_PROMO_CODE_ERROR:
      return {
        ...state,
        errors: [...state.errors, action.error],
        status: {
          ...state.status,
          promoCode: statusEnum.ERROR,
        },
      };
    case loyaltyActions.UNSET_SELECTED_PROMO_CODE:
      return {
        ...state,
        errors: removeError(state.errors, 'promoCode'),
        selectedPromoCode: null,
      };
    case loyaltyActions.REMOVE_DISCOUNT:
      return {
        ...state,
        errors: [...defaultState.errors],
        highlightedUserRewardUuid: null,
        selectedUserRewardUuid: null,
        selectedPromoCode: null,
      };
    case pageActions.OPEN_REWARD_MODAL:
      return {
        ...state,
        selectedRewardId: action.rewardId,
        selectedRewardType: action.rewardType,
      };
    case pageActions.CLOSE_REWARD_MODAL:
      return {
        ...state,
        selectedRewardId: null,
        selectedRewardType: null,
      };
    case orderActions.RESET_ORDER:
    case orderActions.PLACE_ORDER_SUCCESS:
      return Object.freeze({
        ...state,
        errors: [...defaultState.errors],
        highlightedUserRewardUuid: null,
        selectedUserRewardUuid: null,
        selectedPromoCode: null,
      });
    case loyaltyActions.CLEAR_ERRORS:
      return {
        ...state,
        errors: [...defaultState.errors],
      };
    default:
      return state;
  }
}
