import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import broadcastMessageEnum from '../../enums/broadcastMessageEnum';
import { getSearchParam } from '../../utils/historyUtils';
import { BroadcastMessageChannel } from '../../utils/pageUtils';

import LoadingScreen from '../../components/LoadingScreen';

import './PaymentPopup.css';

class PaymentPopupContainer extends Component {
  static propTypes = {
    /* React Router props */
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }).isRequired,
  };

  success = true;

  componentDidMount() {
    const { location } = this.props;
    const { search } = location;

    const is2c2p = !!getSearchParam(search, 'respCode');

    this.success = getSearchParam(search, 'success') === 'true';

    let response = { success: this.success };

    if (is2c2p) {
      /**
       * For 2c2p we need to check the respCode of paymentResponse
       * to know if it is successful, in this case, code "2000"
       * View: https://developer.2c2p.com/docs/response-code-payment-flow
       */
      const respCode = getSearchParam(search, 'respCode');
      this.success = respCode === '2000';
      response = {
        success: this.success,
        respCode,
      };
    }

    window.response = response;
    window.completed = true;

    // Set popup response and close the window
    const channel = new BroadcastMessageChannel(broadcastMessageEnum.PAYMENT_POPUP);
    channel.postMessage(response);

    window.close();
  }

  render() {
    const { success } = this;

    return (
      <div className="PaymentPopupContainer">
        <div className="PaymentPopupText">
          <Typography variant="h6" color="primary">
            Add payment method {success ? 'success' : 'failed'}
          </Typography>
          <Typography variant="subtitle1" color="primary">
            Please <strong>close</strong> this tab if it is not auto closed.
          </Typography>
        </div>
        <LoadingScreen />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  location: state.router.location,
});

export default connect(mapStateToProps, undefined)(PaymentPopupContainer);
