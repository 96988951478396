/*
  Used to determine the status of the order item
  INITIAL: Initial status before any action is taken. For example when the page is first loaded,
  the order was reset, when order item was reset, or when order item was added to cart
  TENTATIVE: Tentative status used for order items that don't have all details yet for any reason,
  for example when menu item to use couldn't be determined due to lack of gglocation selected.
  orderItemSaga is responsible for checking wether item can be updated and moved to READY.
  READY: Ready status used when the order item has all of the required details and is ready to be
  modified by user or added to card.
  COMPLETED: Completed status used when the menu item is saved in cart.
*/
const orderItemStatusEnum = Object.freeze({
  INITIAL: 0,
  TENTATIVE: 1,
  READY: 2,
  COMPLETED: 3,
});

export default orderItemStatusEnum;
