const previewStageEnum = Object.freeze({
  /* Price for all order items paid for. */
  COMPLETED: 'completed',
  /* Price for all order items saved to the cart. */
  READY: 'ready',
  /* Price for all order items including one that is being edited. */
  SELECTED: 'selected',
  /* Price for all order items including one that is being edited with highlighted ingredients. */
  HIGHLIGHTED: 'highlighted',
});

export default previewStageEnum;
