import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import previewStageEnum from '../../enums/previewStageEnum';

import OrderItemPrice from '../../components/OrderItemPrice';

class OrderItemPriceContainer extends Component {
  static propTypes = {
    orderItem: PropTypes.object.isRequired,
  };

  get totalPrice() {
    const { orderItem } = this.props;

    return orderItem.pricing?.totalPrice;
  }

  get originalPrice() {
    const { orderItem } = this.props;

    return orderItem.pricing?.price;
  }

  get isDiscounted() {
    const { orderItem } = this.props;

    return orderItem.pricing?.discountAmount > 0;
  }

  render() {
    const { totalPrice, originalPrice, isDiscounted } = this;

    if (!originalPrice) {
      return null;
    }

    return (
      <OrderItemPrice
        originalPrice={originalPrice}
        totalPrice={totalPrice}
        isDiscounted={isDiscounted}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const orderItemId = ownProps.orderItemId || state.orderItem.id;
  const isReadyOnly = ownProps.stage === previewStageEnum.READY;

  return {
    orderItem:
      orderItemId === state.orderItem.id && !isReadyOnly
        ? state.orderItem
        : state.order.orderItems[orderItemId],
  };
};

export default connect(mapStateToProps)(OrderItemPriceContainer);
