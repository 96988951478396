import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import discountSourceEnum from '../../enums/discountSourceEnum';

import Button from '../Button';
import Currency from '../Currency';
import FeedbackSticker from '../FeedbackSticker';
import FontAwesome from '../FontAwesome';

import './SelectedReward.css';

// TODO: change 'qualify item' copy to actual needed item for better description
function SelectedReward(props) {
  const { fromCart, isCartEmpty, discountAmount, discountName, discountSource, onCancel } = props;

  if (!discountSource) return null;

  // Don't show other discount on loyalty reward list
  if (!fromCart && discountSource !== discountSourceEnum.LOYALTY) return null;

  const isAutoDiscount = discountSource === discountSourceEnum.AUTO_DISCOUNT;

  return (
    <div className={classnames('SelectedRewardWrapper', { fromCart })} tabIndex="-2">
      <FeedbackSticker className="SelectedReward">
        <div>
          <span className="Title bold">
            {discountName}
          </span>
          {fromCart && discountAmount && (
            <Currency value={discountAmount * -1} />
          )}
        </div>
        {fromCart && isCartEmpty && (
          <Typography variant="subtitle2">
            Please add a qualify item to enjoy this reward.
          </Typography>
        )}
        {!fromCart && (
          <Button className="blue-link" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </FeedbackSticker>
      {fromCart && !isAutoDiscount && (
        <button className="SelectedRewardCancelButton" onClick={onCancel}>
          <FontAwesome name="circle-xmark" />
        </button>
      )}
      {fromCart && isAutoDiscount && (
        <div className="SelectedRewardCancelButton" />
      )}
    </div>
  );
}

SelectedReward.propTypes = {
  fromCart: PropTypes.bool,
  isCartEmpty: PropTypes.bool,
  discountAmount: PropTypes.number,
  discountName: PropTypes.string,
  discountSource: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
};

SelectedReward.defaultProps = {
  fromCart: false,
  isCartEmpty: false,
  discountAmount: null,
  discountName: null,
  discountSource: null,
  reward: null,
};

export default SelectedReward;
