import React, { Component } from 'react';
import { connect } from 'react-redux';
import { intersection, range } from 'lodash';
import PropTypes from 'prop-types';

import { setSearchParams } from '../../../utils/historyUtils';
import * as settingsUtils from '../../../utils/settingsUtils';

import menuItemShape from '../../../shapes/menuItemShape';
import routerShape from '../../../shapes/routerShape';
import settingsShape from '../../../shapes/settingsShape';

import CyoDetails from '../../../components/Cyo/CyoDetails';
import withRouter from '../../WithRouter';

import { INGREDIENT_BASES } from '../../../settings';

class CyoDetailsContainer extends Component {
  static propTypes = {
    orderItemId: PropTypes.string,
    baseId: PropTypes.number.isRequired,
    menuItemId: PropTypes.number.isRequired,
    cyoSectionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    sectionId: PropTypes.number.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    highlightedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    settings: PropTypes.objectOf(settingsShape).isRequired,
    onModalHide: PropTypes.func.isRequired,

    router: routerShape.isRequired,
  };

  static defaultProps = {
    orderItemId: undefined,
  };

  get detailsTabId() {
    const { sectionId, settings } = this.props;
    const { cyoSectionGroups } = settings;

    const detailsTab = Object.values(cyoSectionGroups).find((cyoSectionGroup) =>
      cyoSectionGroup.sectionIds.includes(sectionId),
    );

    if (detailsTab) {
      return detailsTab.id;
    }
    return 1;
  }

  get menuItem() {
    const { menuItems, menuItemId } = this.props;

    return menuItems[menuItemId];
  }

  get ingredientsCount() {
    const { highlightedIngredientServings } = this.props;
    const baseIngredientIds = Object.keys(INGREDIENT_BASES);

    return Object.keys(highlightedIngredientServings).filter(
      (id) => !baseIngredientIds.includes(id),
    ).length;
  }

  get filteredCyoSectionGroups() {
    // Retrieve the cyoSections that are not hidden
    const { cyoSectionIds } = this.props;
    const { settings } = this.props;
    const { cyoSectionGroups } = settings;

    return Object.values(cyoSectionGroups).filter(
      (cyoSectionGroup) => intersection(cyoSectionGroup.sectionIds, cyoSectionIds).length > 0,
    );
  }

  handleDetailsTabSelect = (detailsTabId) => {
    const { orderItemId, menuItemId, settings, router } = this.props;
    const { cyoSectionGroups, sectionSettings, baseTypeSettings } = settings;

    const { sectionId: currentSectionId } = this.props;
    const cyoSectionGroup = cyoSectionGroups[detailsTabId];
    const nextSectionId = cyoSectionGroup.sectionIds.sort().find((sectionId) => {
      const overallSettings = settingsUtils.getSectionSettings({
        baseId: this.menuItem.baseType,
        sectionId,
        sectionSettings,
        baseTypeSettings,
      });
      return overallSettings.limit.cyo.section !== 0;
    });

    /* Ensure we have history of visiting the intermediate sections for the back navigation */
    if (currentSectionId < nextSectionId) {
      range(currentSectionId + 1, nextSectionId + 1).map((sectionId) =>
        router.push({
          pathname: `/cyo/${menuItemId}/${sectionId}`,
          search: setSearchParams(router.location.search, { orderItemId }),
        }),
      );
    } else if (currentSectionId > nextSectionId) {
      router.push({
        pathname: `/cyo/${menuItemId}/${nextSectionId}`,
        search: setSearchParams(router.location.search, { orderItemId }),
      });
    }
  };

  render() {
    const { baseId, modalOpen, onModalHide } = this.props;

    return (
      <CyoDetails
        baseId={baseId}
        menuItem={this.menuItem}
        detailsTabId={this.detailsTabId}
        ingredientsCount={this.ingredientsCount}
        modalOpen={modalOpen}
        cyoSectionGroups={this.filteredCyoSectionGroups}
        onModalHide={onModalHide}
        onDetailsTabSelect={this.handleDetailsTabSelect}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  menuItems: state.api.menuItems,
  highlightedIngredientServings: state.orderItem.highlightedIngredientServings,
  settings: state.api.settings,
});

export default withRouter(connect(mapStateToProps, undefined)(CyoDetailsContainer));
