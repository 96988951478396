import React, { useRef, useState } from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import previewStageEnum from '../../../enums/previewStageEnum';

import Image from '../../../containers/Image';
import OrderItemPrice from '../../../containers/OrderItemPrice';
import Button from '../../Button';
import FontAwesome from '../../FontAwesome';
import CartItemQuantitySelector from './CartItemQuantitySelector';

import './CartItem.css';

function CartItem(props) {
  const {
    orderItemId,
    image,
    name,
    labels,
    quantity,
    ingredientChangesTexts,
    onOrderItemModify,
    onOrderItemRemove,
    onOrderItemIncrement,
    onOrderItemDecrement,
  } = props;

  const cartItemRef = useRef();

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // The required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    // Reset touch end, otherwise the swipe is fired even with usual touch events
    setTouchEnd(null);

    const touchEvent = e.targetTouches[0];

    setTouchStart({ x: touchEvent.clientX, y: touchEvent.clientY });
  };

  const onTouchMove = (e) => {
    const touchEvent = e.targetTouches[0];

    setTouchEnd({ x: touchEvent.clientX, y: touchEvent.clientY });
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distanceX = touchStart.x - touchEnd.x;
    const absDistanceX = Math.abs(distanceX);
    const distanceY = touchStart.y - touchEnd.y;
    const absDistanceY = Math.abs(distanceY);

    if (Math.max(absDistanceX, absDistanceY) < minSwipeDistance) return;

    if (absDistanceX >= absDistanceY) {
      // Swipe horizontally
      if (distanceX > 0) {
        // Swipe left
        if (cartItemRef.current) {
          cartItemRef.current.focus();
        }
      }
    }
  };

  return (
    <div
      className="CartItem"
      role="tabpanel"
      tabIndex="0"
      ref={cartItemRef}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <ul className="CartItemUpperBody">
        <li className="CartItemImage">
          <Image id={image} />
        </li>
        <li className="CartItemBody">
          <div className="CartItemLine">
            <Typography variant="subtitle1" color="grey.main" className="CartItemName">
              <strong>{name}</strong>
            </Typography>
            <CartItemQuantitySelector orderItemId={orderItemId} />
            <p className="CartItemPrice">
              <OrderItemPrice orderItemId={orderItemId} stage={previewStageEnum.READY} />
            </p>
          </div>
          <div className="CartItemChanges">
            {ingredientChangesTexts.map((ingredientChangesText) => (
              <Typography
                variant="body2"
                color="grey.main"
                className="ingredientChanges"
                key={ingredientChangesText.label}
              >
                {ingredientChangesText.label && <strong>{ingredientChangesText.label}: </strong>}
                {ingredientChangesText.text}
              </Typography>
            ))}
          </div>
          <div className="CartItemDetails">
            <Typography variant="body2" color="grey.main" className="Customer hide-empty">
              {labels.map(
                (label) =>
                  label && (
                    <span key={label} className="addComma">
                      {label}
                    </span>
                  ),
              )}
            </Typography>
          </div>
        </li>
        <li className="CartItemAction">
          <button type="button" aria-label="increase" onMouseDown={onOrderItemModify}>
            <FontAwesome name="pencil" />
          </button>
          <button type="button" aria-label="decrease" onMouseDown={onOrderItemRemove}>
            <FontAwesome name="circle-xmark" />
          </button>
        </li>
      </ul>
      <div className="CartItemCounter">
        <Button fa="minus" onClick={onOrderItemDecrement} />
        <Typography>{quantity}</Typography>
        <Button fa="plus" onClick={onOrderItemIncrement} />
      </div>
    </div>
  );
}

CartItem.propTypes = {
  orderItemId: PropTypes.string.isRequired,
  image: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  quantity: PropTypes.number.isRequired,
  ingredientChangesTexts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onOrderItemModify: PropTypes.func.isRequired,
  onOrderItemRemove: PropTypes.func.isRequired,
  onOrderItemIncrement: PropTypes.func.isRequired,
  onOrderItemDecrement: PropTypes.func.isRequired,
};

export default CartItem;
