import React from 'react';

import * as orderUtils from './utils/orderUtils';

import { CYO_SECTION_ROUTES } from './settings';

import { ReactComponent as StoreIcon } from './images/icon-store.svg';

export const PREVIOUS_LOCATION = -1;
export const CURRENT_LOCATION = 1;
export const UNDETERMINED_LOCATION = 0;

const menuHeaderTitle = (state, location, path) => {
  const { menuItemId } = path.params;
  const menuItem = state.api.menuItems[menuItemId];

  const favoriteMenuItemId =
    state.api.menuItemFavoriteMenuItemId[state.orderItem.favoriteMenuItemId];
  const favoriteMenuItem = state.api.favoriteMenuItems[favoriteMenuItemId];

  return favoriteMenuItem ? favoriteMenuItem.name : menuItem.name;
};

const menuHeader = {
  desktop: {
    subtitle: '',
    title: '',
  },
  mobile: { title: menuHeaderTitle },
};

/* Ordered by priority */
export const mainRoutesFlow = {
  name: 'mainRoutesFlow',
  routes: [
    {
      path: '/',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'HomePage',
      caption: 'Home',
    },
    {
      path: '/locations',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'LocationsPage',
      caption: 'Change store',
    },
    {
      path: '/time',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'LocationsPage',
      caption: 'Change time',
    },
    {
      path: '/addAddress',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'AddAddressPage',
      caption: 'Back',
    },
    {
      path: '/menu',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: {
          title: (state) => state.api.gglocations[state.order.gglocationId]?.name,
          icon: <StoreIcon />,
        },
      },
      className: 'ShoppingPage MenuListingPage',
      caption: 'Menu',
    },
    {
      path: '/menu/details/:menuItemId',
      header: menuHeader,
      className: 'ShoppingPage',
      caption: 'Back',
    },
    {
      path: '/menu/details/:menuItemId/add/:sectionId',
      header: {
        desktop: {
          subtitle: '',
          title: '',
        },
        mobile: { title: 'Customize' },
      },
      className: 'ShoppingPage',
      caption: '',
    },
    // Get CYO section routes from settings and append header
    ...CYO_SECTION_ROUTES.map((route) => ({
      ...route,
      header: menuHeader,
    })),
    {
      path: '/personalize/:menuItemId',
      header: menuHeader,
      className: 'ShoppingPage',
      caption: '',
    },
    {
      path: '/upsell',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'ShoppingPage UpsellPage',
      caption: '',
    },
  ],
  rootPath: true,
};

export const landingPageRoutesFlow = {
  name: 'landingPageRoutesFlow',
  routes: [
    {
      path: '/site/:landingPageSlug',
      className: 'LandingPage',
    },
    {
      path: '/locations',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: 'Select a pick-up location' },
      },
      className: 'LocationsPage',
      caption: 'Change store',
    },
    {
      path: '/addAddress',
      header: {
        desktop: { title: 'Tell us your location', subtitle: '' },
        mobile: { title: 'Deliver to' },
      },
      className: 'AddAddressPage',
      caption: 'Back',
    },
    {
      path: '/menu/details/:menuItemId',
      header: menuHeader,
      className: 'ShoppingPage',
      caption: 'Edit menu',
    },
    {
      path: '/menu/details/:menuItemId/add/:sectionId',
      header: menuHeader,
      className: 'ShoppingPage',
      caption: '',
    },
    // Get CYO section routes from settings and append header
    ...CYO_SECTION_ROUTES.map((route) => ({
      ...route,
      header: menuHeader,
    })),
    {
      path: '/personalize/:menuItemId',
      header: {
        desktop: {
          subtitle: 'Personalize your order',
          title: menuHeaderTitle,
        },
        mobile: {
          title: 'Personalize your order',
        },
      },
      className: 'ShoppingPage',
      caption: '',
    },
  ],
};

export const signInRoutesFlow = {
  name: 'signInRoutesFlow',
  routes: [
    {
      path: '/signIn',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'SignInPage',
      caption: 'Back',
    },
    {
      path: '/signIn/facebookRequestEmail',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'FacebookRequestEmailPage',
    },
    {
      path: '/signIn/facebookReset',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'FacebookResetPage',
    },
    {
      path: '/signIn/register',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'SignInPage',
      caption: '',
    },
    {
      path: '/signIn/login',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'SignInPage',
      caption: '',
    },
  ],
};

export const phoneRoutesFlow = {
  name: 'phoneRoutesFlow',
  routes: [
    {
      path: '/',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'HomePage',
      caption: 'Home',
    },
    {
      path: '/signIn/phone',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: 'Your phone number' },
      },
      className: 'SignInPage PhonePage',
      caption: '',
    },
    {
      path: '/signIn/verify',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: 'Verify' },
      },
      className: 'SignInPage',
      caption: '',
    },
  ],
};

export const paymentRoutesFlow = {
  name: 'paymentRoutesFlow',
  routes: [
    {
      path: '/signIn/payment',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: 'Payment' },
      },
      className: 'SignInPage',
      caption: '',
    },
    {
      path: '/signIn/payment/failure',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: 'Failure' },
      },
      className: 'SignInPage',
      caption: '',
    },
  ],
};

export const orderRoutesFlow = {
  name: 'orderRoutesFlow',
  routes: [
    {
      path: '/',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'HomePage',
      caption: 'Home',
    },
    {
      path: '/order/:orderId',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: {
          title: (state, location, path) => {
            const userOrder = state.api.userOrders[path.params.orderId];

            if (orderUtils.isOrderStatusRequested(userOrder.orderStatusId)) {
              return 'Order placed';
            }
            return `${userOrder.orderNumber}`;
          },
        },
      },
      className: 'OrderPage',
      caption: '',
    },
  ],
};

export const accountRoutesFlow = {
  name: 'accountRoutesFlow',
  routes: [
    {
      path: '/',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'HomePage',
      caption: 'Home',
    },
    {
      path: '/account/edit',
      header: {
        desktop: {
          title: '',
          subtitle: '',
        },
        mobile: {
          title: '',
        },
      },
      className: 'AccountPage',
    },
    {
      path: '/account/paymentMethods',
      header: {
        desktop: {
          title: '',
          subtitle: '',
        },
        mobile: {
          title: '',
        },
      },
      className: 'AccountPage',
    },
    {
      path: '/account/favorites',
      header: {
        desktop: {
          title: '',
          subtitle: '',
        },
        mobile: {
          title: '',
        },
      },
      className: 'AccountPage',
    },
    {
      path: '/account/orders',
      header: {
        desktop: {
          title: '',
          subtitle: '',
        },
        mobile: {
          title: '',
        },
      },
      className: 'AccountPage',
      caption: 'Orders',
    },
    {
      path: '/account/addresses',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'AccountPage',
      caption: 'Addresses',
    },
    {
      path: '/addAddress',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: '' },
      },
      className: 'AccountPage AddAddressPage',
    },
    {
      path: '/order/:orderId',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: {
          title: (state, location, path) => {
            const userOrder = state.api.userOrders[path.params.orderId];

            if (orderUtils.isOrderStatusRequested(userOrder.orderStatusId)) {
              return 'Order placed';
            }
            return `${userOrder.orderNumber}`;
          },
        },
      },
      className: 'AccountPage OrderPage',
    },
  ],
};

export const browserRoutesFlow = {
  name: 'browserRoutesFlow',
  routes: [
    {
      path: '/browser',
      header: {
        desktop: {
          title: '',
          subtitle: '',
        },
        mobile: {
          title: '',
        },
      },
      bypassLoader: true,
      className: 'BrowserPage',
    },
  ],
};

export const loyaltyFlow = {
  name: 'loyaltyFlow',
  routes: [
    {
      path: '/loyalty',
      header: {
        desktop: {
          title: '',
          subtitle: '',
        },
        mobile: {
          title: '',
        },
      },
      className: 'LoyaltyPage',
    },
  ],
};

export const kioskPairingFlow = {
  name: 'kioskPairingFlow',
  routes: [
    {
      path: '/kioskPairing',
      header: {
        desktop: {
          title: '',
          subtitle: '',
        },
        mobile: {
          title: '',
        },
      },
      className: 'KioskPairingPage',
    },
    {
      path: '/signIn',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: 'Welcome!' },
      },
      className: 'SignInPage',
      caption: '',
    },
  ],
};

export const scanOrderNumberFlow = {
  name: 'scanOrderNumberFlow',
  routes: [
    {
      path: '/scan',
      header: {
        desktop: {
          title: '',
          subtitle: '',
        },
        mobile: {
          title: '',
        },
      },
      className: 'scanOrderNumberPage',
    },
    {
      path: '/signIn',
      header: {
        desktop: { title: '', subtitle: '' },
        mobile: { title: 'Welcome!' },
      },
      className: 'SignInPage',
      caption: '',
    },
  ],
};

export const popupFlow = {
  name: 'popupFlow',
  routes: [
    {
      path: '/popup/payment',
      header: {
        desktop: {
          title: '',
          subtitle: '',
        },
        mobile: {
          title: '',
        },
      },
      bypassLoader: true,
      className: 'RegularPage',
    },
    {
      path: '/popup/facebook',
      header: {
        desktop: {
          title: '',
          subtitle: '',
        },
        mobile: {
          title: '',
        },
      },
      bypassLoader: true,
      className: 'RegularPage',
    },
  ],
};

/* Ordered by priority */
const routesFlows = [
  mainRoutesFlow,
  landingPageRoutesFlow,
  signInRoutesFlow,
  phoneRoutesFlow,
  paymentRoutesFlow,
  orderRoutesFlow,
  accountRoutesFlow,
  browserRoutesFlow,
  loyaltyFlow,
  kioskPairingFlow,
  scanOrderNumberFlow,
  popupFlow,
];

export default routesFlows;
