import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import basicMoment from 'moment';
import * as momentRound from 'moment-round';
import PropTypes from 'prop-types';

import gglocationTypeEnum from '../../enums/gglocationTypeEnum';
import * as selectors from '../../sagas/selectors';

import gglocationShape from '../../shapes/gglocationShape';

import MomentCalendar from '../MomentCalendar';

const moment = momentRound.monkey(basicMoment);

function NextAvailableLabel({ gglocation, verbose }) {
  const diningChoice = useSelector(selectors.getDiningChoice);
  const stationTimeInterval = useSelector(selectors.getDefaultStationTimeInterval);

  const [nextAvailableDateTime, setNextAvailableDateTime] = useState(null);

  useEffect(() => {
    if (gglocation) {
      const nextAvailableDate = moment(gglocation?.getNextAvailableDate({ diningChoice }));

      if (nextAvailableDate.isValid()) {
        setNextAvailableDateTime(nextAvailableDate);
      }
    }
  }, [diningChoice, gglocation]);

  if (nextAvailableDateTime == null || !gglocation) return null;

  if (gglocation?.gglocationType === gglocationTypeEnum.PARTNER && verbose) {
    const endTime = nextAvailableDateTime.clone().add(stationTimeInterval, 'minutes');
    const cutoffTime = gglocation.getCutoffTime({ diningChoice });
    const placeTime = nextAvailableDateTime.clone().subtract(cutoffTime, 'minutes');
    const isSameDay = nextAvailableDateTime.isSame(moment(), 'day');
    return (
      <span className={isSameDay && 'optionalBold'}>
        {isSameDay ? (
          <span>Order by {placeTime.format('h:mma')} for </span>
        ) : (
          <span>Pre-order for </span>
        )}
        <MomentCalendar
          options={{
            sameDay: 'h:mma',
            nextDay: '[tomorrow] h:mma',
            nextWeek: 'ddd D MMM h',
            sameElse: 'D MMM h',
          }}
        >
          {nextAvailableDateTime}
        </MomentCalendar>
        <span>
          -{endTime.format('h:mma')}
          {isSameDay && ' pickup'}
        </span>
      </span>
    );
  }

  return (
    <MomentCalendar
      options={{
        sameDay: 'h:mma',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        sameElse: 'DD/MM/YYYY',
      }}
    >
      {moment(nextAvailableDateTime).round(5, 'minutes')}
    </MomentCalendar>
  );
}

NextAvailableLabel.propTypes = {
  gglocation: PropTypes.shape(gglocationShape),
  verbose: PropTypes.bool,
};

NextAvailableLabel.defaultProps = {
  gglocation: null,
  verbose: false,
};

export default NextAvailableLabel;
