import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import preferenceGroupShape from '../../../shapes/preferenceGroupShape';

import './PersonalizePreferenceGroupRadioSwitch.css';

function PersonalizePreferenceGroupRadioSwitch(props) {
  const { preferenceGroup, selectedPreferenceId, onPreferenceChange } = props;
  const isRecommended = preferenceGroup.recommended;
  const recommendedtext = <span className="recommended-text">(Recommended)</span>;

  return (
    <div className="PersonalizePreferenceGroupRadioSwitch">
      {preferenceGroup.preferences.map((preference) => (
        <div key={preference.id} className="PreferenceGroupRadioSwitch">
          <input
            type="radio"
            id={preference.id}
            name={`preference-radio-switch-${preferenceGroup.id}`}
            value={preference.id}
            checked={selectedPreferenceId === preference.id}
            onClick={(event) => onPreferenceChange(event.target.value)}
          />
          <Typography variant="subtitle1">
            {preference.name}
            {isRecommended && recommendedtext}
          </Typography>
        </div>
      ))}
    </div>
  );
}

PersonalizePreferenceGroupRadioSwitch.propTypes = {
  preferenceGroup: preferenceGroupShape.isRequired,
  selectedPreferenceId: PropTypes.number.isRequired,
  onPreferenceChange: PropTypes.func.isRequired,
};

export default PersonalizePreferenceGroupRadioSwitch;
