import React, { Component } from 'react';
import { GoogleMap } from 'react-google-maps';
import PropTypes from 'prop-types';

import mapStyles from './styles.json';

class StyledGoogleMap extends Component {
  static propTypes = {
    defaultOptions: PropTypes.objectOf(PropTypes.any),
    onMapLoad: PropTypes.func,
  };

  static defaultProps = {
    defaultOptions: {},
    onMapLoad: null,
  };

  handleMapLoad(map) {
    const { onMapLoad } = this.props;
    if (onMapLoad) {
      onMapLoad(map);
    }
  }

  render() {
    const { defaultOptions, onMapLoad, ...otherProps } = this.props;
    const onMapLoadIgnored = onMapLoad;

    return (
      <GoogleMap
        ref={(map) => this.handleMapLoad(map)}
        defaultOptions={{ ...defaultOptions, styles: mapStyles }}
        {...otherProps}
      />
    );
  }
}

export default StyledGoogleMap;
