import React from 'react';
import PropTypes from 'prop-types';

import './GglocationName.css';

function GglocationName(props) {
  const { name } = props;

  return (
    <ul className="GglocationName">
      <li>
        <span className="Name">{name}</span>
      </li>
    </ul>
  );
}

GglocationName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default GglocationName;
