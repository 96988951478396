import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import * as selectors from '../../sagas/selectors';

import useWindowSize from '../../hooks/useWindowSize';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import Button from '../Button';

import './AppUpdateProvider.css';

function AppUpdateProvider({ children }) {
  const isEmailVerificationPopUpShown = useSelector(selectors.getIsEmailVerificationPopUpShown);

  const [hasNewUpdate, setHasNewUpdate] = useState(false);

  const registrationRef = useRef(null);
  const refreshingRef = useRef(false);

  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const isMobile = width <= 767;

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    //
    // For our code base purpose, please view service worker
    // section in README.md
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        setHasNewUpdate(true);
        // closePopUpTimeout(8000);
        registrationRef.current = registration;

        // Update service worker in the next reload/refresh
        registration.update();
        if (registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
      },
    });
  }, []);

  const triggerUpdate = () => {
    if (registrationRef.current) {
      if (registrationRef.current?.waiting) {
        // Let the waiting service worker to be active
        registrationRef.current.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
      if (!refreshingRef.current) {
        // Reload/refresh the app for new updates
        window.location.reload();
        refreshingRef.current = false;
      }
    }
  };

  const extraMargin = useMemo(() => {
    let padding = 0;

    if (isMobile) return 0;

    const isMainPage = /(\/site\/.+|^\/$)/.test(pathname);

    if (isEmailVerificationPopUpShown && isMainPage) {
      const emailVerificationPopUp = document.getElementById('EmailVerificationPopUpBody');
      padding += emailVerificationPopUp?.clientHeight ?? 54;
      padding += 16;
    }
    return padding;
  }, [isMobile, isEmailVerificationPopUpShown, pathname]);

  return (
    <>
      {hasNewUpdate && (
        <div
          className="AppUpdateBanner"
          style={{ marginTop: isMobile ? '0px' : `${extraMargin}px` }}
        >
          <div className="AppUpdatePopUp">
            <div className="AppUpdateInnerContainer">
              <Button className="AppUpdateLeftButton" onClick={triggerUpdate}>
                <div className="AppUpdateLeftContainer">
                  <Typography variant="subtitle2" color="grey.main">
                    A new version of the app is available.
                  </Typography>
                  <Typography variant="subtitle2" color="grey.main">
                    Click here or refresh to update.
                  </Typography>
                </div>
              </Button>
              <Button
                className="AppUpdateRightButton blue-link"
                onClick={() => setHasNewUpdate(false)}
              >
                Dismiss for now
              </Button>
            </div>
          </div>
        </div>
      )}
      {children}
    </>
  );
}

AppUpdateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppUpdateProvider;
