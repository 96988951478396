import React from 'react';
import { Typography } from '@mui/material';

import chromeLogo from './browser-chrome.png';
import firefoxLogo from './browser-firefox.png';
import saladStopLogo from './ss-logo-green.png';

import './Browser.css';

function Browser() {
  return (
    <div className="Browser kale">
      <img className="Logo" alt="SaladStop" src={saladStopLogo} />
      <Typography variant="h4">Your browser is currently not supported.</Typography>
      <p>
        Don&#39;t worry there&#39;s an easy fix. All you have to do is get a compatible browser by
        clicking one of the icons below.
      </p>
      <div className="BrowserPicker">
        <a href="https://www.google.com/chrome/browser">
          <img alt="Chrome" src={chromeLogo} />
        </a>
        <a href="https://www.mozilla.org/en-US/firefox/desktop/">
          <img alt="Firefox" src={firefoxLogo} />
        </a>
      </div>
    </div>
  );
}

export default Browser;
