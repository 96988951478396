import PropTypes from 'prop-types';

import menuItemIngredientBreakdownShape from './menuItemIngredientBreakdownShape';
import personalSettingShape from './personalSettingShape';

const orderItemShape = PropTypes.shape({
  menuItemId: PropTypes.string,
  baseId: PropTypes.number,
  orderingFavorite: PropTypes.bool,
  personalSettings: PropTypes.arrayOf(personalSettingShape),
  ingredientBreakdown: PropTypes.arrayOf(menuItemIngredientBreakdownShape),
  defaultIngredientServings: PropTypes.objectOf(PropTypes.number),
  selectedIngredientServings: PropTypes.objectOf(PropTypes.number),
  highlightedIngredientServings: PropTypes.objectOf(PropTypes.number),
  orderItemStatus: PropTypes.number,
  apiId: PropTypes.number,
});

export default orderItemShape;
