import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as loyaltyActions from '../../../actions/loyalty';
import discountSourceEnum from '../../../enums/discountSourceEnum';

import CartPriceLine from '../../../components/Cart/CartPriceLine';

class CartDiscountLineContainer extends Component {
  static propTypes = {
    discountAmount: PropTypes.number,
    discountName: PropTypes.string,
    discountSource: PropTypes.string.isRequired,
    selectedPromoCode: PropTypes.string,
    removeDiscount: PropTypes.func.isRequired,
  };

  static defaultProps = {
    discountAmount: null,
    discountName: null,
    selectedPromoCode: null,
  };

  get shouldComponentRender() {
    const { discountAmount } = this.props;

    if (!discountAmount) {
      return false;
    }

    return true;
  }

  get title() {
    const { discountName, selectedPromoCode } = this.props;

    if (selectedPromoCode) {
      return `${discountName} – ${selectedPromoCode}`;
    }

    return 'Reward';
  }

  get price() {
    const { discountAmount } = this.props;

    return -discountAmount;
  }

  get isAutoDiscount() {
    const { discountSource } = this.props;

    return discountSource === discountSourceEnum.AUTO_DISCOUNT;
  }

  handleDiscountRemove = () => {
    const { removeDiscount } = this.props;

    removeDiscount();
  };

  render() {
    if (!this.shouldComponentRender) {
      return null;
    }

    const { title, price, isAutoDiscount } = this;

    return (
      <CartPriceLine
        title={title}
        price={price}
        typographyVariant="subtitle1"
        onRemove={isAutoDiscount ? this.handleDiscountRemove : null}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  discountAmount: state.order.pricing.discountAmount,
  discountName: state.order.pricing.discountName,
  discountSource: state.order.pricing.discountSource,
  selectedPromoCode: state.loyalty.selectedPromoCode,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeDiscount: loyaltyActions.removeDiscount,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CartDiscountLineContainer);
