import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';

import MenuIcon from '../../../images/icon-menu.svg';

import './MenuMobileButton.css';

function MenuMobileButton(props) {
  const { onClick } = props;
  return (
    <Button className="MenuMobileButton btn-tl-mb" onClick={onClick}>
      <img src={MenuIcon} alt="menu" />
    </Button>
  );
}

MenuMobileButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MenuMobileButton;
