import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import * as selectors from '../../../sagas/selectors';
import * as foodUtils from '../../../utils/foodUtils';

import MenuCategory from '../../../containers/Menu/MenuCategory';
import DietButton from '../../DietButton';

import './LandingPageMenu.css';

function LandingPageMenu({ menuGroupId }) {
  const menuGroupMenuItems = useSelector((state) =>
    selectors.selectMenuGroupMenuItems(state, menuGroupId),
  );
  const menuCategories = useSelector(selectors.getMenuCategories);

  const landingPageStyles = useSelector(selectors.getLandingPageStyles);

  const menuCategoriesArray = foodUtils.getMenuCategoriesArray(menuCategories);

  const availableMenuCategories = foodUtils.filterCategoriesWithMenuItems(
    menuCategoriesArray,
    menuGroupMenuItems,
  );

  const selectedTagIds = useSelector(selectors.getSelectedTagIds);
  const menuItemsMatchingTags = foodUtils.menuItemsMatchingTags(menuGroupMenuItems, selectedTagIds);
  const sortedMenuItems = foodUtils.sortedMenuItems(menuItemsMatchingTags);

  const menuCategoryElement = (menuCategory) => (
    <MenuCategory
      isLandingPage
      key={menuCategory.id}
      name={menuCategory.name}
      description={menuCategory.desc}
      landingPageStyles={landingPageStyles.menuListing}
      menuItems={sortedMenuItems.filter((menuItem) => menuItem.menuCategory === menuCategory.id)}
    />
  );

  return (
    <div className="LandingPageMenu">
      <DietButton type="button" landingPageStyles={landingPageStyles.topBar} />
      {availableMenuCategories.length > 0 && availableMenuCategories.map(menuCategoryElement)}
    </div>
  );
}

LandingPageMenu.propTypes = {
  menuGroupId: PropTypes.number.isRequired,
};

export default LandingPageMenu;
