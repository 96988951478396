import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Select, Typography } from '@mui/material';
import { range } from 'lodash';
import PropTypes from 'prop-types';

import * as orderActions from '../../../../actions/order';

import FontAwesome from '../../../FontAwesome';

import './CartItemQuantitySelector.css';

const defaultQuantityList = range(1, 11);

function CartItemIconComponent(props) {
  return <FontAwesome name="caret-down" {...props} />;
}

function CartItemQuantitySelector({ orderItemId }) {
  const orderItem = useSelector((state) => state.order.orderItems[orderItemId]);

  const quantity = orderItem?.personalSettings?.length ?? 1;

  const dispatch = useDispatch();

  const onUpdate = (newQuantity) => {
    if (orderItem && newQuantity !== quantity) {
      dispatch(orderActions.updateOrderItemQuantity(orderItemId, newQuantity));
    }
  };

  return (
    <Select
      className="CartItemQuantitySelector"
      id={`CartItemQuantitySelector-${orderItemId}`}
      value={quantity}
      onChange={(event) => onUpdate(event.target.value)}
      notched={false}
      label="Label"
      IconComponent={CartItemIconComponent}
      MenuProps={{
        PaperProps: {
          sx: {
            marginTop: '-16px',
            borderRadius: '16px',
            boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.06)',
          },
        },
        PopoverClasses: { root: 'CartItemQuantitySelectorPopOver' },
        disableAutoFocus: true,
        disableAutoFocusItem: true,
        disableEnforceFocus: true,
        autoFocus: false,
      }}
    >
      {defaultQuantityList.map((option) => (
        <MenuItem
          key={option}
          value={option}
          sx={{
            padding: 0,
            justifyContent: 'center',
            '&.Mui-selected, &.Mui-focusVisible': { backgroundColor: 'transparent' },
            '&:hover': { backgroundColor: '#F3F6F4' },
          }}
        >
          <Typography variant="subtitle1">{option}</Typography>
        </MenuItem>
      ))}
      {!defaultQuantityList.includes(quantity) && (
        <MenuItem
          key={quantity}
          value={quantity}
          sx={{
            padding: 0,
            justifyContent: 'center',
            '&.Mui-selected, &.Mui-focusVisible': { backgroundColor: 'transparent' },
            '&:hover': { backgroundColor: '#F3F6F4' },
          }}
        >
          <Typography variant="subtitle1">{quantity}</Typography>
        </MenuItem>
      )}
    </Select>
  );
}

CartItemQuantitySelector.propTypes = {
  orderItemId: PropTypes.string.isRequired,
};

export default CartItemQuantitySelector;
