import * as browserActions from '../actions/browser';

const defaultState = Object.freeze({
  isBrowserCheckPassed: false,
});

// eslint-disable-next-line default-param-last
export default function browserReducer(state = defaultState, action) {
  switch (action.type) {
    case browserActions.BROWSER_CHECK_PASSED: {
      return Object.freeze({
        ...state,
        isBrowserCheckPassed: true,
      });
    }
    default:
      return state;
  }
}
