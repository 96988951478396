// Sort upsell category with sortOrder and its menuItems with
// upsellSortOrder ascendingly
export const sortUpsellCategories = (upsellCategories) =>
  Object.values(upsellCategories)
    .filter((category) => category.upsellMenus.length >= 1)
    .sort((categoryA, categoryB) => categoryA.sortOrder - categoryB.sortOrder)
    .map((category) => ({
      ...category,
      upsellMenus: category.upsellMenus.sort(
        (menuA, menuB) => menuA.upsellSortOrder - menuB.upsellSortOrder,
      ),
    }));

// Sort upsell categories and its menu items and map menuId to menuItem
export const mapUpsellCategoriesWithMenus = (upsellCategories, menuItems, menuGroupId) =>
  sortUpsellCategories(upsellCategories)
    .map((category) => ({
      ...category,
      upsellMenus: category.upsellMenus
        .map((menu) => menuItems[`${menu.id}_${menuGroupId}`])
        .filter((menu) => !!menu),
    }))
    .filter((category) => category.upsellMenus.length >= 1);

// Check if there's any upsell menu items
// If menuItems and menuGroupId are pass, then we will check against current menu group
export const isUpsellOngoing = (upsellCategories, menuItems = null, menuGroupId = null) => {
  if (!menuItems || !menuGroupId) {
    return Object.values(upsellCategories).some((category) => category.upsellMenus.length >= 1);
  }

  return Object.values(upsellCategories)
    .filter((category) => category.upsellMenus.length >= 1)
    .some((category) =>
      category.upsellMenus.some((menu) => !!menuItems[`${menu.id}_${menuGroupId}`]),
    );
};
