import PropTypes from 'prop-types';

const headerShape = PropTypes.shape({
  desktop: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
  mobile: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
});

export default headerShape;
