import { compareDates } from '@spq/utils';
import { chain, difference, intersection, partition, uniqBy } from 'lodash';
import moment from 'moment';

import statusEnum from '../enums/statusEnum';

import Scheduler from '../containers/Scheduler';
import { pyZip } from './commonUtils';

import { RECOMMENDED_GGLOCATIONS_LIMITS } from '../settings';

export const filterGglocationByType = ({
  gglocations,
  landingPagePartners,
  disabledGglocationTypes,
}) =>
  chain(gglocations)
    /* Remove disabled gglocation types */
    .filter(
      (gglocation) => !disabledGglocationTypes.includes(parseInt(gglocation.id.split('_')[0], 10)),
      /* Select only associated partners for landing page */
    )
    .filter(
      (gglocation) =>
        !gglocation.timeSlots || landingPagePartners?.includes(gglocation.gglocationId),
    )
    .value();

const selectAvailableGglocations = ({ gglocations, diningChoice }) =>
  Object.values(gglocations).filter((gglocation) => gglocation.canPlaceOrderEver({ diningChoice }));

const compareGglocationsAvailability = ({ gglocationA, gglocationB, diningChoice }) => {
  const aCanPlaceOrderEver = gglocationA.canPlaceOrderEver({ diningChoice });
  const bCanPlaceOrderEver = gglocationB.canPlaceOrderEver({ diningChoice });
  const aCanPlaceOrderImmediately = gglocationA.canPlaceOrderImmediately({
    diningChoice,
  });
  const bCanPlaceOrderImmediately = gglocationB.canPlaceOrderImmediately({
    diningChoice,
  });

  if (aCanPlaceOrderImmediately && !bCanPlaceOrderImmediately) return -1;
  if (!aCanPlaceOrderImmediately && bCanPlaceOrderImmediately) return 1;
  if (aCanPlaceOrderEver && !bCanPlaceOrderEver) return -1;
  if (!aCanPlaceOrderEver && bCanPlaceOrderEver) return 1;

  return 0;
};

const compareGglocationsDistance = ({ gglocationA, gglocationB, geolocation }) => {
  if (geolocation.status === statusEnum.SUCCESS) {
    const { coordinates } = geolocation;
    const aDistanceToUser = gglocationA.distanceToLocation({ coordinates });
    const bDistanceToUser = gglocationB.distanceToLocation({ coordinates });

    if (aDistanceToUser < bDistanceToUser) return -1;
    if (aDistanceToUser > bDistanceToUser) return 1;
  }

  return 0;
};

export const compareGglocations = ({ gglocationA, gglocationB, geolocation, diningChoice }) => {
  const availabilityDifference = compareGglocationsAvailability({
    gglocationA,
    gglocationB,
    diningChoice,
  });
  if (availabilityDifference !== 0) return availabilityDifference;

  const distanceDifference = compareGglocationsDistance({
    gglocationA,
    gglocationB,
    geolocation,
  });
  if (distanceDifference !== 0) return distanceDifference;

  return 0;
};

export const filterAvailableGglocations = ({
  gglocations,
  timeSlots,
  isLandingPageOrder,
  landingPageDisabledGglocationTypes,
  landingPagePartners,
  diningChoice,
  excludedGglocationTypes = [],
}) => {
  let filteredGglocations = gglocations;
  if (isLandingPageOrder && landingPageDisabledGglocationTypes) {
    filteredGglocations = filterGglocationByType({
      gglocations,
      landingPagePartners,
      disabledGglocationTypes: landingPageDisabledGglocationTypes,
    });
  } else if (!isLandingPageOrder) {
    filteredGglocations = chain(gglocations)
      .filter(
        (gglocation) =>
          gglocation.gglocationType === 1 ||
          (gglocation.gglocationType === 2 && gglocation.displayOnHomepage === true),
      )
      .value();
  }

  if (excludedGglocationTypes?.length > 0) {
    filteredGglocations = chain(filteredGglocations)
      .filter((gglocation) => !excludedGglocationTypes.includes(gglocation.gglocationType))
      .value();
  }
  return selectAvailableGglocations({
    gglocations: filteredGglocations,
    timeSlots,
    diningChoice,
  });
};

const filterGglocationIds = ({
  limits,
  api,
  isLandingPageOrder,
  landingPageDisabledGglocationTypes,
  landingPagePartners,
  geolocation,
  diningChoice,
  excludedGglocationTypes = [],
}) => {
  const { gglocations, timeSlots } = api;

  const availableGglocations = filterAvailableGglocations({
    gglocations,
    timeSlots,
    isLandingPageOrder,
    landingPageDisabledGglocationTypes,
    landingPagePartners,
    diningChoice,
    excludedGglocationTypes,
  });
  const availableGglocationIds = availableGglocations.map((gglocation) => gglocation.id);

  const mostFrequentGglocationIds = intersection(
    api.mostFrequentGglocationIds,
    availableGglocationIds,
  );
  const mostRecentGglocationIds = intersection(api.mostRecentGglocationIds, availableGglocationIds);
  const nearestGglocations = availableGglocations.sort((gglocationA, gglocationB) =>
    compareGglocationsDistance({ gglocationA, gglocationB, geolocation }),
  );
  const nearestGglocationIds = nearestGglocations.map((gglocation) => gglocation.id);
  const nextAvailableGglocations = availableGglocations.sort((gglocationA, gglocationB) =>
    compareGglocationsAvailability({
      gglocationA,
      gglocationB,
      timeSlots,
      diningChoice,
    }),
  );
  const nextAvailableGglocationIds = nextAvailableGglocations.map((gglocation) => gglocation.id);

  const providers = {
    mostFrequentGglocationIds,
    mostRecentGglocationIds,
    nearestGglocationIds,
    nextAvailableGglocationIds,
  };

  const selectedGglocationIds = limits.reduce(
    (currentGglocationIds, { provider, limit }) =>
      currentGglocationIds.concat(
        difference(providers[`${provider}GglocationIds`], currentGglocationIds).slice(0, limit),
      ),
    [],
  );

  return selectedGglocationIds;
};

export const getRecommendedGglocationIds = ({
  isUsedSignedIn,
  isLandingPageOrder,
  landingPageDisabledGglocationTypes,
  landingPagePartners,
  api,
  geolocation,
  diningChoice,
  excludedGglocationTypes = [],
}) => {
  const key = isUsedSignedIn ? 'user' : 'guest';
  const recommendedLimits = RECOMMENDED_GGLOCATIONS_LIMITS[key];
  const fallbackLimits = RECOMMENDED_GGLOCATIONS_LIMITS.fallback;

  const gglocationIds = (() => {
    const recommendedGglocationIds = filterGglocationIds({
      limits: recommendedLimits,
      api,
      isLandingPageOrder,
      landingPageDisabledGglocationTypes,
      landingPagePartners,
      geolocation,
      diningChoice,
      excludedGglocationTypes,
    });

    if (recommendedGglocationIds.length > 0) {
      return recommendedGglocationIds;
    }

    const fallbackGglocationIds = filterGglocationIds({
      limits: fallbackLimits,
      api,
      isLandingPageOrder,
      landingPageDisabledGglocationTypes,
      landingPagePartners,
      geolocation,
      diningChoice,
      excludedGglocationTypes,
    });

    return fallbackGglocationIds;
  })();

  return gglocationIds;
};

export const filterGglocationIdsByType = ({ gglocationIds, gglocationTypes }) =>
  chain(gglocationIds)
    .filter((gglocationId) => gglocationTypes.includes(parseInt(gglocationId.split('_')[0], 10)))
    .value();

export const selectScheduler = (state) => {
  if (state.order.gglocationId) {
    return state.api.gglocations[state.order.gglocationId];
  }

  return new Scheduler(state.api.gglocations, state.order.scheduler);
};

const getScheduledTimeListFromScheduler = ({
  scheduler,
  orderTime,
  diningChoice,
  includePast = false,
  defaultTimeInterval = 30,
}) => {
  if (!scheduler) return [];
  // Obtain scheduled time list from default time interval
  // when gglocation is not specified
  if (scheduler instanceof Scheduler) {
    return scheduler.getScheduledOrderTimesOnSameDateWithInterval({
      orderTime,
      diningChoice,
      defaultTimeInterval,
    });
  }
  // Obtain scheduled time list from gglocation delivery time interval
  // after gglocation is selected
  const todaySwitchoverTime = moment.utc(orderTime.format('YYYY-MM-DD'));

  return partition(
    scheduler
      .getScheduledOrderTimesOnSameDate({
        orderTime,
        diningChoice,
        includePast,
      })
      .sort(compareDates),
    (scheduleTime) => todaySwitchoverTime.isAfter(scheduleTime),
  );
};

const generateTimeRangeFromTimeIntervals = (scheduleTimeList) => {
  if (!scheduleTimeList) return [];

  const lowerTimeBoundaries = uniqBy(scheduleTimeList, (x) => x.valueOf());
  const upperTimeBoundaries = lowerTimeBoundaries.slice(1);

  // Zip two array to let the first time as lower time boundary
  // and the next index as its upper time boundary
  return pyZip(lowerTimeBoundaries, upperTimeBoundaries);
};

export const getScheduleOrderTimeRangeOnNextAvailableDay = ({
  scheduler,
  diningChoice,
  includePast = false,
  defaultTimeInterval = 30,
}) => {
  if (!scheduler) return [];

  const orderTime = scheduler.getNextAvailableDate({ diningChoice });
  const scheduleTimeList = getScheduledTimeListFromScheduler({
    scheduler,
    orderTime,
    diningChoice,
    includePast,
    defaultTimeInterval,
  });
  // There will be 2 possible schedule time list in the same date.
  // For more details, please refer to containers/Scheduler.js
  // getScheduledOrderTimesOnSameDateWithInterval function.
  return [
    ...generateTimeRangeFromTimeIntervals(scheduleTimeList[0]),
    ...generateTimeRangeFromTimeIntervals(scheduleTimeList[1]),
  ];
};

export const getScheduleOrderTimeRangeOnSameDay = ({
  scheduler,
  orderTime,
  diningChoice,
  includePast = false,
  defaultTimeInterval = 30,
}) => {
  const scheduleTimeList = getScheduledTimeListFromScheduler({
    scheduler,
    orderTime,
    diningChoice,
    includePast,
    defaultTimeInterval,
  });
  // There will be 2 possible schedule time list in the same date.
  // For more details, please refer to containers/Scheduler.js
  // getScheduledOrderTimesOnSameDateWithInterval function.
  return [
    ...generateTimeRangeFromTimeIntervals(scheduleTimeList[0]),
    ...generateTimeRangeFromTimeIntervals(scheduleTimeList[1]),
  ];
};

export const getScheduleOrderTimeRangeOnSameOrNextDay = ({
  scheduler,
  orderTime,
  diningChoice,
  includePast = false,
  defaultTimeInterval = 30,
}) => {
  const sameDayScheduleTimeList = getScheduleOrderTimeRangeOnSameDay({
    scheduler,
    orderTime,
    diningChoice,
    includePast,
    defaultTimeInterval,
  });
  // If no available time range on the same day,
  // return the time ranges for next available day
  if (sameDayScheduleTimeList.length === 0) {
    return getScheduleOrderTimeRangeOnNextAvailableDay({
      scheduler,
      diningChoice,
      includePast,
      defaultTimeInterval,
    });
  }
  return sameDayScheduleTimeList;
};

export const inferScheduleTimeRangeFromOrderTime = ({
  scheduler,
  orderTime,
  diningChoice,
  includePast = false,
  defaultTimeInterval = 30,
}) => {
  const timeRanges = getScheduleOrderTimeRangeOnSameDay({
    scheduler,
    orderTime,
    diningChoice,
    includePast,
    defaultTimeInterval,
  });

  // Infer the time range with left-inclusive right-exclusive logic
  return timeRanges.find(
    ([lowerBound, upperBound]) =>
      lowerBound.isSameOrBefore(orderTime) && upperBound.isAfter(orderTime),
  );
};

export const getScheduleTimeRangeFromReadyTime = ({ scheduler, readyTime, diningChoice }) => {
  if (!readyTime) return null;

  const readyMoment = moment(readyTime).startOf('date');

  if (moment().startOf('date').isSameOrBefore(readyMoment)) {
    return inferScheduleTimeRangeFromOrderTime({
      scheduler,
      orderTime: moment(readyTime),
      diningChoice,
      includePast: true,
    });
  }

  // Skew the past date to future/current date with same day of week
  // because past schedule time is not existed on the api, hence,
  // we would need to 'reproduce' the time range based on
  // current logic with the date with same day of week
  const daysDiff = moment().startOf('date').diff(readyMoment, 'days');
  // Get the day of week difference
  const dayOfWeekDiff = (7 - (daysDiff % 7)) % 7;
  // Manipulate past date to current date then to the future date
  // with the same day of week
  const skewedReadyDate = moment(readyTime).add(daysDiff + dayOfWeekDiff, 'days');
  const skewedRange = inferScheduleTimeRangeFromOrderTime({
    scheduler,
    orderTime: skewedReadyDate,
    diningChoice,
    includePast: true,
  });

  // Re-skew back to the original date
  return skewedRange.map((date) =>
    date.year(readyMoment.year()).month(readyMoment.month()).date(readyMoment.date()),
  );
};

export const getEarliestTimeFromTimeRanges = (timeRanges) => {
  if (!timeRanges || !timeRanges?.length) return null;
  if (!timeRanges[0] || !timeRanges[0]?.length) return null;
  return timeRanges[0][0];
};
