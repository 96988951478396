import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import cyoSectionShape from '../../shapes/cyoSectionShape';
import menuItemShape from '../../shapes/menuItemShape';

import DietConflictResolver from '../../containers/DietConflictResolver';
import Image from '../../containers/Image';
import MenuDetailsAdd from '../../containers/MenuDetails/MenuDetailsAdd';
import CustomizeLeft from '../CustomizeLeft';
import CustomizeRight from '../CustomizeRight';
import NutritionalBlock from '../NutritionalBlock';
import NutritionalModal from '../NutritionalModal';
import RequirementNotification from '../RequirementNotification';
import MenuDetailsView from './MenuDetailsView';

import './MenuDetails.css';

function MenuDetails(props) {
  const {
    page,
    pageSectionId,
    menuItem,
    baseId,
    sections,
    isMobile,
    isNutritionModalOpen,
    isOrderPreviewRequested,
    limitNotification,
    requiredNotification,
    onLimitExhausted,
    onNutritionButtonClick,
    onNutritionModalHide,
    onOrderItemAdd,
    isBaseSectionSelectable,
  } = props;

  return (
    <div className={classnames('MenuDetails', { [`Add-${pageSectionId}`]: page === 'add' })}>
      <CustomizeLeft>
        <div className="NotPremadeItem">
          <div
            className={classnames('MenuDetailImage', {
              'visible-lg visible-md': page === 'add',
            })}
          >
            <Image id={menuItem.image} context="desktopX2" oneSize />
          </div>
          {!isMobile && <NutritionalBlock isMobile={isMobile} />}
          <NutritionalModal
            name={menuItem.name}
            show={isNutritionModalOpen}
            onHide={onNutritionModalHide}
            isMobile={isMobile}
          />
        </div>
      </CustomizeLeft>
      <CustomizeRight>
        <div className="MenuDetailsContentWrapper">
          <div className="MenuDetailImage">
            <Image id={menuItem.image} context="desktopX2" oneSize />
          </div>
          {isMobile && page === 'view' && <NutritionalBlock isMobile={isMobile} />}
          <RequirementNotification {...requiredNotification} />
          {page === 'view' && (
            <MenuDetailsView
              menuItemId={menuItem.id}
              menuItemName={menuItem.name}
              baseId={baseId}
              sections={sections}
              limitNotification={limitNotification}
              isOrderPreviewRequested={isOrderPreviewRequested}
              onLimitExhausted={onLimitExhausted}
              onNutritionButtonClick={onNutritionButtonClick}
              onOrderItemAdd={onOrderItemAdd}
              isBaseSectionSelectable={isBaseSectionSelectable}
            />
          )}
          {page === 'add' && (
            <MenuDetailsAdd
              menuItemId={menuItem.id}
              baseId={baseId}
              sectionId={pageSectionId}
              limitNotification={limitNotification}
              isOrderPreviewRequested={isOrderPreviewRequested}
              onLimitExhausted={onLimitExhausted}
            />
          )}
        </div>
      </CustomizeRight>
      <DietConflictResolver />
    </div>
  );
}

MenuDetails.propTypes = {
  page: PropTypes.string,
  pageSectionId: PropTypes.number,
  menuItem: menuItemShape.isRequired,
  baseId: PropTypes.number.isRequired,
  sections: PropTypes.arrayOf(cyoSectionShape).isRequired,
  isMobile: PropTypes.bool.isRequired,
  isNutritionModalOpen: PropTypes.bool.isRequired,
  isOrderPreviewRequested: PropTypes.bool.isRequired,
  limitNotification: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    limit: PropTypes.number,
    extra: PropTypes.bool,
    isRemove: PropTypes.bool,
    clearLimitNotification: PropTypes.func.isRequired,
  }),
  requiredNotification: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    clearLimitNotification: PropTypes.func.isRequired,
  }),
  onLimitExhausted: PropTypes.func.isRequired,
  onNutritionButtonClick: PropTypes.func.isRequired,
  onNutritionModalHide: PropTypes.func.isRequired,
  onOrderItemAdd: PropTypes.func.isRequired,
  isBaseSectionSelectable: PropTypes.bool.isRequired,
};

MenuDetails.defaultProps = {
  page: 'view',
  pageSectionId: null,
  requiredNotification: null,
  limitNotification: null,
};

export default MenuDetails;
