import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { process2C2PCardForm, setPaymentCardFormError } from '../../../actions/page';
import paymentMethodFormEnum from '../../../enums/paymentMethodFormEnum';
import { getAddPaymentMethodType, getPaymentCardFormError } from '../../../sagas/selectors';
import { hasOnlyDigits } from '../../../utils/commonUtils';
import { validate2c2pCardForm } from '../../../utils/paymentFormUtils';

import useScript from '../../../hooks/useScript';
import Button from '../../Button';
import InputAlertText from '../../InputAlertText';

import { PAYMENT_2C2P_CARD_SDK } from '../../../settings';

import '../PaymentForm.css';

function FieldLabel({ labelName }) {
  return (
    <Typography
      variant="subtitle1"
      color="secondary.dark"
      fontWeight="600"
      className="PaymentMethodFormFieldLabel"
    >
      {labelName}
    </Typography>
  );
}

FieldLabel.propTypes = {
  labelName: PropTypes.string.isRequired,
};

function FieldError({ error }) {
  return (
    error && (
      <InputAlertText className="PaymentMethodFormFieldError" noIcon>
        {error}
      </InputAlertText>
    )
  );
}

FieldError.propTypes = {
  error: PropTypes.string,
};

FieldError.defaultProps = {
  error: null,
};

/**
 * Card form details for different payment gateway/vendors.
 *
 * @namespace formKey - Card form enum for the payment gateway/vendor
 * @property {URL} [formKey.extraScript] - SDK or script needed to load
 * @property {function} formKey.validator - Form validation and data manipulation
 * for specific payment gateway/vendor
 * @property {function} formKey.processor - Form data processor which should handle backend API
 * @property {object} [formKey.cardNumberInputProps] - Additional props for card number input
 * @property {object} [formKey.nameInputProps] - Additional props for card holder name input
 * @property {object} [formKey.monthInputProps] - Additional props for expiry month input
 * @property {object} [formKey.yearInputProps] - Additional props for expiry year input
 * @property {object} [formKey.cvvInputProps] - Additional props for cvv input
 * @property {boolean} [formKey.isNameInputHidden] - Is card holder name input should be shown
 *
 * @example
 * {
 *   // Form details for `Hello` payment gateway
 *   HELLO_CARD_FORM: {
 *     extraScript: "https://extraScript.com/script",
 *     validator: validatorFunction,
 *     processor: processFunction,
 *     cardNumberInputProps: { id: "card-name-input"},
 *     nameInputProps: {},
 *     monthInputProps: {},
 *     yearInputProps: {},
 *     cvvInputProps: {},
 *     isNameInputHidden: true, // Hide name input
 *     isNameInputRequired: true, // Require name input
 *   }
 * }
 */
const FORM_TYPE_DETAILS_MAPPING = Object.freeze({
  [paymentMethodFormEnum.PAY_2C2P_CARD_FORM]: {
    extraScript: PAYMENT_2C2P_CARD_SDK,
    validator: validate2c2pCardForm,
    processor: process2C2PCardForm,
    cardNumberInputProps: { 'data-encrypt': 'cardnumber', maxLength: 19 },
    nameInputProps: { 'data-encrypt': 'name' },
    monthInputProps: { 'data-encrypt': 'month' },
    yearInputProps: { 'data-encrypt': 'year' },
    cvvInputProps: { 'data-encrypt': 'cvv' },
    isNameInputHidden: false,
    isNameInputRequired: true,
  },
});

function CreditCardForm() {
  const addPaymentMethodType = useSelector(getAddPaymentMethodType);
  const paymentCardFormError = useSelector(getPaymentCardFormError);

  const [cardNumber, setCardNumber] = useState('');
  const [cardNumberError, setCardNumberError] = useState(null);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(null);
  const [month, setMonth] = useState('');
  const [monthError, setMonthError] = useState(null);
  const [year, setYear] = useState('');
  const [yearError, setYearError] = useState(null);
  const [cvv, setCvv] = useState('');
  const [cvvError, setCvvError] = useState(null);
  const [otherError, setOtherError] = useState(null);

  const dispatch = useDispatch();

  const addPaymentMethodFormDetails = FORM_TYPE_DETAILS_MAPPING[addPaymentMethodType];

  // NOTE: Only needed it because react-meta-tags don't load script
  // TODO: migrate to react-helmet-async (need new package update)
  useScript(addPaymentMethodFormDetails?.extraScript);
  // NOTE: hooks must come before any conditions

  if (!addPaymentMethodFormDetails) {
    return null;
  }

  const {
    validator,
    processor,
    cardNumberInputProps,
    nameInputProps,
    monthInputProps,
    yearInputProps,
    cvvInputProps,
    isNameInputHidden,
    isNameInputRequired,
  } = addPaymentMethodFormDetails;

  const onClearErrors = () => {
    setCardNumberError(null);
    setNameError(null);
    setMonthError(null);
    setYearError(null);
    setCvvError(null);
    setOtherError(null);

    dispatch(setPaymentCardFormError(null));
  };

  const generalFormValidation = () => {
    let isFormValid = true;

    if (!cardNumber) {
      setCardNumberError('Card number is required');
      isFormValid = false;
    } else if (!hasOnlyDigits(cardNumber)) {
      setCardNumberError('Card number can only have digits');
      isFormValid = false;
    }
    if (!month) {
      setMonthError('Expiry month is required');
      isFormValid = false;
    } else if (!hasOnlyDigits(month)) {
      setMonthError('Expiry month can only have digits');
      isFormValid = false;
    } else if (month.length !== 2) {
      setMonthError(
        'Expiry month must be 2 digits. Please add a zero in front for single digit month, i.e. 01 for January',
      );
      isFormValid = false;
    }
    if (!year) {
      setYearError('Expiry year is required');
      isFormValid = false;
    } else if (!hasOnlyDigits(year)) {
      setYearError('Expiry year can only have digits');
      isFormValid = false;
    } else if (year.length !== 4) {
      setYearError('Expiry year must be 4 digits');
      isFormValid = false;
    }
    if (!cvv) {
      setCvvError('CVV is required');
      isFormValid = false;
    }
    if (!isNameInputHidden && isNameInputRequired && !name) {
      setNameError('Card holder name is required');
      isFormValid = false;
    }
    return isFormValid;
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    onClearErrors();

    const isFormLocalValid = generalFormValidation();

    if (!isFormLocalValid) return false;

    // Specific form validation and data manipulation
    // for respective payment gateway
    const { success, validatedData } = validator({
      cardNumber: cardNumber?.replace(' ', ''),
      name,
      month,
      year,
      cvv,
      setCardNumberError,
      setNameError,
      setMonthError,
      setYearError,
      setCvvError,
      setOtherError,
    });

    if (!success) return false;

    // It should trigger openPaymentSpinner to block user interaction,
    // and closePaymentSpinner upon completion.
    dispatch(processor(validatedData));

    return true;
  };

  const miscError = otherError || paymentCardFormError;

  return (
    <form id="payment-method-form-CARD" className="PaymentMethodForm" onSubmit={onFormSubmit}>
      <div className="PaymentMethodFormRow">
        <div className="PaymentMethodFormColumn">
          <FieldLabel labelName="Card number" />
          {/* Hide this from user as the SDK will need to pull input without spaces */}
          <input
            type="input"
            placeholder="Card number"
            autoComplete="cc-number"
            value={cardNumber}
            {...(cardNumberInputProps ?? {})}
            style={{ display: 'none' }}
          />
          {/* Show this input for user as it contains value with spaces */}
          <input
            type="input"
            placeholder="Card number"
            className="PaymentMethodFormField"
            autoComplete="cc-number"
            value={cardNumber.replace(/\d{4}?(?=.)/g, '$& ')}
            onChange={(event) => setCardNumber(event.target.value.replace(/[^0-9]/g, ''))}
            maxLength={cardNumberInputProps?.maxLength}
          />
          <FieldError error={cardNumberError} />
        </div>
      </div>
      {!isNameInputHidden && (
        <div className="PaymentMethodFormRow">
          <div className="PaymentMethodFormColumn">
            <FieldLabel labelName="Name on card" />
            <input
              type="text"
              placeholder="Your name"
              className="PaymentMethodFormField"
              autoComplete="cc-name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              {...(nameInputProps ?? {})}
            />
            <FieldError error={nameError} />
          </div>
        </div>
      )}
      <div className="PaymentMethodFormRow">
        <div className="PaymentMethodFormColumn">
          <FieldLabel labelName="Expiry month" />
          <input
            type="input"
            maxLength="2"
            placeholder="MM"
            className="PaymentMethodFormField"
            autoComplete="cc-exp-month"
            value={month}
            onChange={(event) => setMonth(event.target.value.replace(/[^0-9]/g, ''))}
            {...(monthInputProps ?? {})}
          />
          <FieldError error={monthError} />
        </div>
        <div className="PaymentMethodFormColumn">
          <FieldLabel labelName="Expiry year" />
          <input
            type="input"
            maxLength="4"
            placeholder="YYYY"
            className="PaymentMethodFormField"
            autoComplete="cc-exp-year"
            value={year}
            onChange={(event) => setYear(event.target.value.replace(/[^0-9]/g, ''))}
            {...(yearInputProps ?? {})}
          />
          <FieldError error={yearError} />
        </div>
      </div>
      <div className="PaymentMethodFormRow">
        <div className="PaymentMethodFormColumn">
          <FieldLabel labelName="CVV / CVC2" />
          <input
            type="password"
            maxLength="4"
            placeholder="CVV / CVC2"
            className="PaymentMethodFormField"
            autoComplete="cc-csc"
            value={cvv}
            onChange={(event) => setCvv(event.target.value)}
            {...(cvvInputProps ?? {})}
          />
          <FieldError error={cvvError} />
        </div>
        <div className="PaymentMethodFormColumn" />
      </div>
      {miscError && (
        <InputAlertText className="PaymentMethodFormOtherError" noIcon>
          {miscError}
        </InputAlertText>
      )}
      <Button type="submit" className="btn-primary PaymentMEthodFormSubmitBtn">
        Proceed
      </Button>
    </form>
  );
}

export default CreditCardForm;
