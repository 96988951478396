import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import cyoSectionShape from '../../../shapes/cyoSectionShape';
import ingredientCategoryShape from '../../../shapes/ingredientCategoryShape';

import IngredientCategories from '../../../containers/IngredientCategories';
import IngredientModal from '../../../containers/IngredientModal';
import MenuDetailsAddHeader from '../../../containers/MenuDetails/MenuDetailsAddHeader';
import OrderItemPriceDelta from '../../../containers/OrderItemPriceDelta';
import PricingVariationsNotifications from '../../../containers/PricingVariationsNotifications';
import Button from '../../Button';
import LimitNotification from '../../LimitNotification';

import './MenuDetailsAdd.css';

function MenuDetailsAdd(props) {
  const {
    menuItemId,
    section,
    ingredientCategoriesList,
    isHeaderShown,
    isOrderPreviewRequested,
    limitNotification,
    updated,
    onLimitExhausted,
    onPricingVariationIngredientAdd,
    onIngredientAddConfirm,
    onCancel,
  } = props;

  return (
    <div className={classnames('MenuDetailsAdd', 'MenuContentFrame', { isHeaderShown })}>
      {isHeaderShown && (
        <MenuDetailsAddHeader
          sectionId={section.id}
          categoryIds={ingredientCategoriesList.map((ingredientCategory) => ingredientCategory.id)}
        />
      )}
      <LimitNotification {...limitNotification} />
      <IngredientModal />
      <div className="MenuDetailsAddBody">
        <PricingVariationsNotifications
          menuItemId={menuItemId}
          sectionId={section.id}
          onAdd={onPricingVariationIngredientAdd}
        />
        <IngredientCategories
          menuItemId={menuItemId}
          sectionId={section.id}
          categoryIds={ingredientCategoriesList.map((ingredientCategory) => ingredientCategory.id)}
          onLimitExhausted={onLimitExhausted}
        />
      </div>
      <div className="ActionButtonCyo">
        {updated ? (
          <ul>
            <li>
              <Button className="btn-energy" onClick={onCancel}>
                <span className="Text">Cancel</span>
              </Button>
            </li>
            <li>
              <Button
                className="Split btn-next"
                loading={isOrderPreviewRequested}
                onClick={onIngredientAddConfirm}
              >
                <span className="Text">
                  Done
                  <span className="AttentionSeeker">
                    <OrderItemPriceDelta />
                  </span>
                </span>
              </Button>
            </li>
          </ul>
        ) : (
          <Button
            className="btn-next"
            loading={isOrderPreviewRequested}
            onClick={onIngredientAddConfirm}
          >
            <span className="Text">Return to previous menu</span>
          </Button>
        )}
      </div>
    </div>
  );
}

MenuDetailsAdd.propTypes = {
  menuItemId: PropTypes.number.isRequired,
  section: cyoSectionShape.isRequired,
  ingredientCategoriesList: PropTypes.arrayOf(ingredientCategoryShape).isRequired,
  isHeaderShown: PropTypes.bool.isRequired,
  isOrderPreviewRequested: PropTypes.bool.isRequired,
  limitNotification: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    limit: PropTypes.number,
    extra: PropTypes.bool,
    isRemove: PropTypes.bool,
    clearLimitNotification: PropTypes.func.isRequired,
  }),
  updated: PropTypes.bool,
  onLimitExhausted: PropTypes.func.isRequired,
  onPricingVariationIngredientAdd: PropTypes.func.isRequired,
  onIngredientAddConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

MenuDetailsAdd.defaultProps = {
  limitNotification: null,
  updated: false,
};

export default MenuDetailsAdd;
