import filterSentryErrors from './utils/sentryUtils';

import packageInfo from '../package.json';
import * as Sentry from './services/sentry';
import {
  COUNTRY_CODE,
  SENTRY_ENVIRONMENT,
  SENTRY_IGNORE_ERROR_MAPPING,
  SENTRY_KEY,
  SENTRY_PROJECT,
} from './settings';

const configureSentry = () =>
  Sentry.init({
    dsn: `https://${SENTRY_KEY}@sentry.ggops.net/${SENTRY_PROJECT}`,
    environment: `${COUNTRY_CODE}-${SENTRY_ENVIRONMENT}`,
    release: packageInfo.version,
    beforeSend: (event) => {
      if (!SENTRY_IGNORE_ERROR_MAPPING) {
        return event;
      }

      /* Filter for events that contain the right message */
      const matchedMessage = Object.keys(SENTRY_IGNORE_ERROR_MAPPING).find((ignoreMessage) =>
        event?.message?.includes(ignoreMessage),
      );

      /* Check if the sentry error is whitelisted, and prevent sending if needed */
      if (matchedMessage && filterSentryErrors(event, matchedMessage)) {
        // Return the event to send it to Sentry
        return null;
      }
      /* Event is whitelisted, don't send */
      return event;
    },
  });

export default configureSentry;
