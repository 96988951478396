import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover, Typography } from '@mui/material';
import classNames from 'classnames';

import * as selectors from '../../sagas/selectors';
import { getValidClaims } from '../../utils/loyaltyUtils';

import LoyaltyRewardsList from '../../containers/LoyaltyRewardsList';
import SelectedReward from '../../containers/SelectedReward';
import Button from '../Button';

import { ReactComponent as IconLoyalty } from '../../images/icon-loyalty-pure-white.svg';

import './LoyaltyFab.css';

function LoyaltyFab() {
  const fabEl = useRef(null);
  const [isFabOpen, setIsFabOpen] = useState(false);

  const claims = useSelector(selectors.getClaims);
  const validClaims = getValidClaims({ claims });
  const massRewardClaims = useSelector(selectors.getMassRewardClaims);

  const availableRewardCount = useMemo(() => {
    const validClaimsCount = validClaims ? validClaims.length : 0;
    const massRewardClaimsCount = massRewardClaims ? massRewardClaims.length : 0;

    return validClaimsCount + massRewardClaimsCount;
  }, [validClaims, massRewardClaims]);

  if (availableRewardCount < 1) return null;

  return (
    <>
      <button
        type="button"
        ref={fabEl}
        className={classNames('LoyaltyFab', { isFabOpen })}
        onClick={() => setIsFabOpen(true)}
      >
        <IconLoyalty />
        <Typography variant="body1" className="LoyaltyFabCount" fontWeight="600">
          {availableRewardCount}
        </Typography>
      </button>
      <Popover
        className="LoyaltyFabPopover"
        open={isFabOpen}
        anchorEl={fabEl.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setIsFabOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <div className="LoyaltyFabPopoverPaper">
          <LoyaltyRewardsList
            size="Medium"
            showHeader={false}
            onRedeem={() => setIsFabOpen(false)}
          />
          <SelectedReward />
          <Button
            className="btn-teal LoyaltyFabRewardButton"
            link="/loyalty"
            state={{ backButtonOverwrite: 'Back', backButtonForceShow: true }}
          >
            Go to rewards
          </Button>
        </div>
      </Popover>
    </>
  );
}

export default LoyaltyFab;
