import { AsYouType } from 'libphonenumber-js';

export default function phoneNumberSeparator(input) {
  const asYouType = new AsYouType().input(input);

  const defaultCountryCode = input.substr(0, 1) !== '+' ? '+' : null;
  let fullFormatted = asYouType;
  let countryCode = '';
  let phoneNumberFormatted = fullFormatted;

  if (defaultCountryCode === '+') {
    countryCode = '+';
    fullFormatted = `+ ${fullFormatted}`;
  } else {
    const parts = fullFormatted.split(' ');
    countryCode = parts.length > 1 ? parts.shift() : '';
    phoneNumberFormatted = parts.join(' ');
  }
  const phoneNumber = phoneNumberFormatted.split(' ').join('');

  return {
    fullFormatted,
    phoneNumberFormatted,
    countryCode,
    phoneNumber,
  };
}
