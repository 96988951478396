import TagManager from 'react-gtm-module';

import * as settings from '../settings';

export const pushCartConfirm = (order, orderGglocation, landingPageSlug) => {
  // Clear the previous ecommerce object.
  TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  TagManager.dataLayer({
    dataLayer: {
      event: 'purchase',
      ecommerce: {
        currency: settings.CURRENCY_CODE,
        transaction_id: order.uuid,
        order_number: order.orderNumber,
        dining_choice: order.diningChoice,
        order_type: order.due ? 'scheduled' : 'instant',
        coupon: order.discountName,
        discount: Number(order.discountAmount),
        discount_source: order.discountSource,
        tax: Number(order.totalTax),
        value: Number(order.total),
        gglocation: orderGglocation.name,
        items: order?.ordermenu?.map((item, index) => ({
          item_id: item.menu.id.toString(),
          item_name: item.menu.name,
          index,
          item_category: item.menu.menuCategory,
          base_type: item.menu.baseType,
          is_cyo: item.menu.isCyo,
          discount: Number(item.discountAmount),
          price: Number(item.total),
          quantity: 1,
          affiliation: landingPageSlug,
        })),
      },
    },
  });
};

export const pushScanRewardSuccess = (action) => {
  // Clear the previous ecommerce object.
  TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  TagManager.dataLayer({
    dataLayer: {
      event: 'scan_success',
      ecommerce: {
        scan_code: action.receiptNo,
        scan_type: 'scan_receipt', // Placeholder for now
      },
    },
  });
};

export const pushClaimPromoCode = (pricing) => {
  // Clear the previous ecommerce object.
  TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  TagManager.dataLayer({
    dataLayer: {
      event: 'select_promotion',
      ecommerce: {
        promotion_id: pricing.discountName,
        promotion_name: pricing.discountName,
        promotion_type: 'promo_code',
      },
    },
  });
};

export const pushClaimUserReward = (userReward) => {
  // Clear the previous ecommerce object.
  TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  TagManager.dataLayer({
    dataLayer: {
      event: 'select_promotion',
      ecommerce: {
        promotion_id: userReward.rewardUuid,
        promotion_name: userReward.name,
        promotion_type: 'user_reward',
      },
    },
  });
};

export const pushAddToCart = (menuItem, quantity, landingPageSlug) => {
  if (menuItem) {
    // Clear the previous ecommerce object.
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({
      dataLayer: {
        event: 'add_to_cart',
        ecommerce: {
          currency: settings.CURRENCY_CODE,
          value: Number(menuItem.price),
          items: [
            {
              item_id: menuItem.apiId,
              item_name: menuItem.name,
              item_category: menuItem.menuCategory,
              base_type: menuItem.baseType,
              is_cyo: menuItem.isCyo,
              price: Number(menuItem.price),
              quantity,
              affiliation: landingPageSlug,
            },
          ],
        },
      },
    });
  }
};

export const pushRemoveFromCart = (menuItem, quantity, landingPageSlug) => {
  if (menuItem) {
    // Clear the previous ecommerce object.
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({
      dataLayer: {
        event: 'remove_from_cart',
        ecommerce: {
          currency: settings.CURRENCY_CODE,
          value: Number(menuItem.price),
          items: [
            {
              item_id: menuItem.apiId,
              item_name: menuItem.name,
              item_category: menuItem.menuCategory,
              base_type: menuItem.baseType,
              is_cyo: menuItem.isCyo,
              price: Number(menuItem.price),
              quantity,
              affiliation: landingPageSlug,
            },
          ],
        },
      },
    });
  }
};
