import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import baseShape from '../../shapes/baseShape';
import cyoSectionShape from '../../shapes/cyoSectionShape';

import CyoDetails from '../../containers/Cyo/CyoDetails';
import CyoIngredientsPicker from '../../containers/Cyo/CyoIngredientsPicker';
import DietConflictResolver from '../../containers/DietConflictResolver';
import Image from '../../containers/Image';
import IngredientModal from '../../containers/IngredientModal';
import OrderItemPrice from '../../containers/OrderItemPrice';
import Button from '../Button';
import CustomizeRight from '../CustomizeRight';
import DietButton from '../DietButton';
import FontAwesome from '../FontAwesome';
import LimitNotification from '../LimitNotification';
import RequirementNotification from '../RequirementNotification';
import CyoAlertText from './CyoAlertText';

import './Cyo.css';

function Cyo(props) {
  const {
    orderItemId,
    basePage,
    base,
    menuItemId,
    menuItemName,
    cyoSectionIds,
    showBaseAlert,
    section,
    hasPagination,
    limitNotification,
    requiredNotification,
    detailsModalOpen,
    isOrderPreviewRequested,
    previousSection,
    onIngredientAddConfirm,
    onLimitExhausted,
    onBaseTypeAlertInit,
    onDetailsButtonClick,
    onDetailsModalHide,
    onBackToPreviousSection,
  } = props;

  return (
    <div className={classnames('Cyo', `Cyo-${section.id}`, { hasPagination })} key={section.id}>
      <IngredientModal />
      <CyoDetails
        orderItemId={orderItemId}
        baseId={base.id}
        menuItemId={menuItemId}
        cyoSectionIds={cyoSectionIds}
        sectionId={section.id}
        modalOpen={detailsModalOpen}
        onModalHide={onDetailsModalHide}
      />
      <CustomizeRight>
        <div className="MenuContentFrame">
          <div className="img-cyo-tablet visible-sm">
            <Image id="cyo-tablet-banner" source="bannerImages" />
          </div>
          {showBaseAlert && basePage && base && (
            <CyoAlertText onInit={onBaseTypeAlertInit}>
              Please select a base to proceed.
            </CyoAlertText>
          )}
          <div className="CyoSection kale">
            {menuItemName && (
              <Typography variant="h2" color="primary" className="CyoMenuName">
                {menuItemName}
              </Typography>
            )}
            <DietButton type="toggle" />
            <LimitNotification {...limitNotification} />
            <RequirementNotification {...requiredNotification} />
            <CyoIngredientsPicker
              menuItemId={menuItemId}
              sectionId={section.id}
              excludeNullBase={basePage}
              onLimitExhausted={onLimitExhausted}
            />
          </div>
        </div>
        <DietButton type="toggle" />
        <div className="ActionButtonCyo">
          <ul>
            {previousSection && (
              <li>
                <Button className="btn-energy" onClick={onBackToPreviousSection}>
                  <span className="Text">
                    <FontAwesome name="caret-left" />
                    {previousSection.name}
                  </span>
                </Button>
              </li>
            )}
            <li className="visible-sm visible-xs">
              <Button className="Ingredients btn-energy" onClick={onDetailsButtonClick}>
                <span className="Text">Ingredients</span>
              </Button>
            </li>
            <li>
              <Button
                className="Split btn-next"
                loading={isOrderPreviewRequested}
                onClick={onIngredientAddConfirm}
              >
                <span className="Text">
                  <span>Next</span>
                  <span className="AttentionSeeker">
                    <OrderItemPrice />
                  </span>
                </span>
              </Button>
            </li>
          </ul>
        </div>
      </CustomizeRight>
      <DietConflictResolver />
    </div>
  );
}

Cyo.propTypes = {
  orderItemId: PropTypes.string,
  basePage: PropTypes.bool,
  base: baseShape,
  menuItemId: PropTypes.number,
  menuItemName: PropTypes.string,
  cyoSectionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  showBaseAlert: PropTypes.bool,
  section: cyoSectionShape.isRequired,
  hasPagination: PropTypes.bool,
  limitNotification: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    limit: PropTypes.number,
    extra: PropTypes.bool,
    isRemove: PropTypes.bool,
    clearLimitNotification: PropTypes.func.isRequired,
  }),
  requiredNotification: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    clearLimitNotification: PropTypes.func.isRequired,
  }),
  showDietButton: PropTypes.bool,
  dietModalOpen: PropTypes.bool.isRequired,
  detailsModalOpen: PropTypes.bool.isRequired,
  isOrderPreviewRequested: PropTypes.bool.isRequired,
  previousSection: cyoSectionShape,
  onIngredientAddConfirm: PropTypes.func.isRequired,
  onLimitExhausted: PropTypes.func.isRequired,
  onDietButtonClick: PropTypes.func.isRequired,
  onBaseTypeAlertInit: PropTypes.func.isRequired,
  onDetailsButtonClick: PropTypes.func.isRequired,
  onDetailsModalHide: PropTypes.func.isRequired,
  onDietModalHide: PropTypes.func.isRequired,
  onBackToPreviousSection: PropTypes.func.isRequired,
};

Cyo.defaultProps = {
  orderItemId: undefined,
  basePage: false,
  base: null,
  menuItemId: null,
  menuItemName: null,
  hasPagination: false,
  showBaseAlert: false,
  limitNotification: null,
  requiredNotification: null,
  showDietButton: false,
  previousSection: null,
};

export default Cyo;
