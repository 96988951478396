import * as pageActions from '../actions/page';
import sceneEnum from '../enums/sceneEnum';

const defaultState = Object.freeze({
  currentScene: sceneEnum.ORDER,
  isFacebookLoginOpen: false,
  isPaymentSpinnerOpen: false,
  isMobileMenuOpen: false,
  isLandingPageOpen: false,
  isDiningChoiceModalOpen: false,
  isScannerModalOpen: false,
  isGuestLoginFlow: false,
  isAddPaymentMethodFormShown: false,
  paymentCardFormError: null,
});

// eslint-disable-next-line default-param-last
export default function pageReducer(state = defaultState, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      const currentScene = action.payload.location.pathname.match('/loyalty')
        ? sceneEnum.LOYALTY
        : sceneEnum.ORDER;

      return Object.freeze({
        ...state,
        currentScene,
        isMobileMenuOpen: false,
      });
    }
    case pageActions.TOGGLE_MOBILE_MENU:
      return Object.freeze({
        ...state,
        isMobileMenuOpen: !state.isMobileMenuOpen,
      });
    case pageActions.OPEN_MOBILE_MENU:
      return Object.freeze({
        ...state,
        isMobileMenuOpen: true,
      });
    case pageActions.CLOSE_MOBILE_MENU:
      return Object.freeze({
        ...state,
        isMobileMenuOpen: false,
      });
    case pageActions.OPEN_PAYMENT_SPINNER: {
      return Object.freeze({
        ...state,
        isPaymentSpinnerOpen: true,
      });
    }
    case pageActions.CLOSE_PAYMENT_SPINNER: {
      return Object.freeze({
        ...state,
        isPaymentSpinnerOpen: false,
      });
    }
    case pageActions.SET_IS_ADD_PAYMENT_METHOD_FORM_SHOWN: {
      return Object.freeze({
        ...state,
        isAddPaymentMethodFormShown: action.isAddPaymentMethodFormShown,
      });
    }
    case pageActions.SET_PAYMENT_CARD_FORM_ERROR: {
      return Object.freeze({
        ...state,
        paymentCardFormError: action.error,
      });
    }
    case pageActions.OPEN_LANDING_PAGE: {
      return Object.freeze({
        ...state,
        isLandingPageOpen: true,
      });
    }
    case pageActions.CLOSE_LANDING_PAGE: {
      return Object.freeze({
        ...state,
        isLandingPageOpen: false,
      });
    }
    case pageActions.OPEN_DINING_CHOICE_MODAL: {
      return Object.freeze({
        ...state,
        isDiningChoiceModalOpen: true,
      });
    }
    case pageActions.CLOSE_DINING_CHOICE_MODAL: {
      return Object.freeze({
        ...state,
        isDiningChoiceModalOpen: false,
      });
    }
    case pageActions.OPEN_SCANNER_MODAL: {
      return Object.freeze({
        ...state,
        isScannerModalOpen: true,
      });
    }
    case pageActions.CLOSE_SCANNER_MODAL: {
      return Object.freeze({
        ...state,
        isScannerModalOpen: false,
      });
    }
    case pageActions.OPEN_REWARD_MODAL: {
      return Object.freeze({
        ...state,
        isRewardModalOpen: true,
      });
    }
    case pageActions.CLOSE_REWARD_MODAL: {
      return Object.freeze({
        ...state,
        isRewardModalOpen: false,
      });
    }
    case pageActions.SET_IS_GUEST_LOGIN_FLOW: {
      return Object.freeze({
        ...state,
        isGuestLoginFlow: action.isGuestLoginFlow,
      });
    }
    default:
      return state;
  }
}
