import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, scroller } from 'react-scroll';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import * as selectors from '../../../sagas/selectors';
import * as foodUtils from '../../../utils/foodUtils';

import menuCategoryShape from '../../../shapes/menuCategoryShape';

import useWindowSize from '../../../hooks/useWindowSize';
import FontAwesome from '../../FontAwesome';

import './MenuSidebar.css';

function MenuSidebar({ menuCategories }) {
  const bases = useSelector(selectors.getApiBases);
  const { baseTypeSettings } = useSelector(selectors.getApiSettings);
  const favoriteMenuItemIds = useSelector(selectors.getFavoriteMenuItemIds);
  const giftCardUrl = useSelector(selectors.getGiftCardUrl);

  const { width } = useWindowSize();
  const isMobile = width < 767;

  const navbarItems = useMemo(() => {
    const baseIds = [...new Set(menuCategories.map((menuCategory) => menuCategory.baseType))];
    let navItems = [];
    foodUtils
      .basesArray(bases)
      .filter((base) => baseIds.includes(base.id))
      .map((base) => {
        if (baseTypeSettings[base.id]?.subcategories) {
          navItems.push(foodUtils.baseToItem(base));
        } else {
          const categories = foodUtils.baseMenuCategories(base.id, menuCategories);
          navItems = navItems.concat(foodUtils.menuCategoriesToItems(categories));
        }
        return base;
      });
    return navItems.sort(foodUtils.sortMenuNavItems);
  }, [menuCategories, baseTypeSettings, bases]);

  const onSetActive = (tabActiveName) => {
    if (isMobile) {
      scroller.scrollTo(`${tabActiveName}-nav-tab`, {
        containerId: 'MenuSidebarList',
        horizontal: true,
        smooth: true,
        offset: -8,
      });
    }
  };

  return (
    <div className="MenuSidebarWrapper">
      <nav className="MenuSidebar">
        <ul id="MenuSidebarList">
          {favoriteMenuItemIds?.length > 0 && (
            <li
              key="menu-navigate-favorite"
              id="menu-navigate-favorite-nav-tab"
              className="MenuSidebarItem"
            >
              <Link
                activeClass="active"
                smooth
                spy
                containerId="MenuContentWrapper"
                to="menu-navigate-favorite"
                offset={-100}
                onSetActive={onSetActive}
              >
                <Typography variant="h5" fontWeight="400" color="primary">
                  <FontAwesome name="heart" />
                  &nbsp; FAVOURITES
                </Typography>
              </Link>
            </li>
          )}
          {navbarItems.map((navbarItem) => (
            <li
              key={navbarItem.id}
              id={`menu-navigate-${navbarItem.id}-nav-tab`}
              className="MenuSidebarItem"
            >
              <Link
                activeClass="active"
                smooth
                spy
                containerId="MenuContentWrapper"
                to={`menu-navigate-${navbarItem.id}`}
                offset={-100}
                onSetActive={onSetActive}
              >
                <Typography variant="h5" fontWeight="400" color="primary">
                  {navbarItem.name}
                </Typography>
              </Link>
            </li>
          ))}
          {giftCardUrl && (
            <li
              key="menu-navigate-gifts"
              id="menu-navigate-gifts-nav-tab"
              className="MenuSidebarItem"
            >
              <Link
                activeClass="active"
                smooth
                spy
                containerId="MenuContentWrapper"
                to="menu-navigate-gifts"
                offset={-110}
                onSetActive={onSetActive}
              >
                <Typography variant="h5" fontWeight="400" color="primary">
                  GIFTS
                </Typography>
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

MenuSidebar.propTypes = {
  menuCategories: PropTypes.objectOf(menuCategoryShape).isRequired,
};

export default MenuSidebar;
