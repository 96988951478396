import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import collectionBonusShape from '../../../shapes/collectioBonusShape';

import './BonusSticker.css';

function BonusSticker({ bonus, titleTypoVariant, titleColor }) {
  if (!bonus) return null;

  return (
    <div className="BonusSticker">
      <span className="BonusMultiplierLabel">
        &times;
        {bonus?.multiplier}
      </span>
      <Typography
        className="BonusTitle"
        variant={titleTypoVariant}
        color={titleColor}
        fontWeight="600"
      >
        {bonus?.text}
      </Typography>
    </div>
  );
}

BonusSticker.propTypes = {
  bonus: PropTypes.shape(collectionBonusShape),
  titleTypoVariant: PropTypes.string,
  titleColor: PropTypes.string,
};

BonusSticker.defaultProps = {
  bonus: null,
  titleTypoVariant: 'subtitle1',
  titleColor: 'primary',
};

export default BonusSticker;
