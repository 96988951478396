import PropTypes from 'prop-types';

const preferenceGroupDisplayQualifierShape = PropTypes.shape({
  baseTypeId: PropTypes.number,
  menuId: PropTypes.number,
  cyoSectionId: PropTypes.number,
  ingredientCategoryId: PropTypes.number,
  ingredientId: PropTypes.number,
});

export default preferenceGroupDisplayQualifierShape;
