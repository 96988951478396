import React from 'react';
import PropTypes from 'prop-types';

import Toast from '../Toast';

import './RequirementNotification.css';

function CyoRequirement(props) {
  const { id, name, clearLimitNotification } = props;

  return (
    <Toast id={id} clearLimitNotification={clearLimitNotification}>
      <div className="CyoRequirement">
        You need to select a {name && name.toLowerCase()} to continue.
      </div>
    </Toast>
  );
}

CyoRequirement.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  clearLimitNotification: PropTypes.func.isRequired,
};

CyoRequirement.defaultProps = {
  id: null,
  name: null,
};

export default CyoRequirement;
