import React from 'react';
import { useSelector } from 'react-redux';

import paymentMethodFormEnum from '../../enums/paymentMethodFormEnum';
import { getAddPaymentMethodType } from '../../sagas/selectors';

import CreditCardForm from './CreditCardForm';

function AddPaymentMethodForm() {
  const addPaymentMethodType = useSelector(getAddPaymentMethodType);

  return (
    <div className="AddPaymentMethodForm">
      {addPaymentMethodType === paymentMethodFormEnum.PAY_2C2P_CARD_FORM && <CreditCardForm />}
    </div>
  );
}

export default AddPaymentMethodForm;
