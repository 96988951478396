import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import * as selectors from '../../../sagas/selectors';

import Button from '../../Button';

import iconFavOff from './icon-fav-off.svg';
import iconFavOn from './icon-fav-on.svg';

import './FavoriteButton.css';

function FavoriteButton(props) {
  const { isFavorite, onClick } = props;
  const isGuestSession = useSelector(selectors.getIsGuestSession);

  if (isGuestSession) {
    return null;
  }

  return (
    <Button className="FavoriteButton btn-favourite" onClick={onClick}>
      <img
        alt={isFavorite ? 'Favourited' : 'Add Favourite'}
        src={isFavorite ? iconFavOn : iconFavOff}
      />
      &nbsp;
      <Typography variant="body2" color="primary" className="FavoriteLink">
        {isFavorite ? 'Favourited' : 'Add Favourite'}
      </Typography>
    </Button>
  );
}

FavoriteButton.propTypes = {
  isFavorite: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

FavoriteButton.defaultProps = {
  isFavorite: false,
};

export default FavoriteButton;
