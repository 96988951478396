import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import moment from 'moment';

import * as loyaltyActions from '../../../actions/loyalty';

import claimShape from '../../../shapes/claimShape';

import Image from '../../Image';
import ProgressBar from '../../ProgressBar/ProgressBar';

import { LOYALTY_S3_DOMAIN } from '../../../settings';

import defaultImg from '../../../images/default-collection-image.webp';

import './LoyaltyCard.css';

// TODO: Reward title and description is lacked on the loyalty claim
// and the claim image url is invalid too
function ClaimCard({ claim }) {
  const dispatch = useDispatch();

  const handleCardClick = () => {
    dispatch(loyaltyActions.setHighlightedUserReward({ userRewardUuid: claim?.uuid }));
  };

  const claimImageUrl = (() => {
    // TODO: Temporary fix to claim url as endpoint does not include the domain
    if (claim.claimImageUrl) {
      if (!/\/\/.+/.test(claim.claimImageUrl)) {
        return LOYALTY_S3_DOMAIN + claim.claimImageUrl;
      }
      return claim.claimImageUrl;
    }
    return defaultImg;
  })();

  return (
    <button type="button" className="LoyaltyCard ClaimCard" onClick={handleCardClick}>
      <div className="TitleContainer">
        <div className="Title">
          <Typography className="TitleLabel" variant="body1" fontWeight="700">
            {claim.name}
          </Typography>
        </div>
      </div>
      <div className="LoyaltyCardBody">
        <div className="LoyaltyCardImageContainer">
          <Image src={claimImageUrl} />
        </div>
        <div className="LoyaltyCardContentContainer">
          <div className="NameContainer">
            <Typography variant="subtitle1" color="grey.main" fontWeight="600">
              {claim.rewardName || claim.name}
            </Typography>
            {!claim.isCollection && claim.desc && (
              <Typography variant="subtitle2" color="grey.main" className="ClaimDescription">
                {claim.desc || claim.name}
              </Typography>
            )}
          </div>
          <div className="CardBottom">
            {(claim.isCollection || claim.isSpecial) && <ProgressBar value={100} />}
            <div className="ValidityRow">
              {claim.expiry && (
                <Typography variant="body2" className="Validity" color="grey.main">
                  Valid until{' '}
                  <span className="bold">{moment(claim.expiry).format('D MMM YYYY')}</span>
                </Typography>
              )}
              {(claim.isCollection || claim.isSpecial) && (
                <Typography variant="body2" className="Remaining" color="grey.main">
                  You did it!
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}

ClaimCard.propTypes = {
  claim: claimShape.isRequired,
};

export default ClaimCard;
