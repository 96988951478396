import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toCamelCaseString } from '@spq/utils';
import PropTypes from 'prop-types';

import Image from '../../components/Image';

class ImageContainer extends Component {
  static getDevicePixelRatio() {
    return [1, 2].includes(window.devicePixelRatio) ? window.devicePixelRatio : 1;
  }

  static prepareSize() {
    return `x${ImageContainer.getDevicePixelRatio()}`;
  }

  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    alt: PropTypes.string,
    context: PropTypes.string,
    source: PropTypes.string,
    images: PropTypes.objectOf(PropTypes.object).isRequired,
    oneSize: PropTypes.bool,
  };

  static defaultProps = {
    id: null,
    alt: '',
    context: null,
    source: 'images',
    oneSize: false,
  };

  get contexts() {
    const { context } = this.props;

    if (context) {
      return [context];
    }
    return ['desktop'];
  }

  get variations() {
    return this.contexts.map((context) => ({
      context,
      src: this.prepareSrc(context),
    }));
  }

  get image() {
    const { id, source, images } = this.props;

    return images[source][id];
  }

  prepareSrc(context) {
    const { source } = this.props;

    if (this.image === undefined) {
      return '';
    }

    if (source === 'bannerImages') {
      return this.image;
    }

    return this.image.file[this.prepareName(context)];
  }

  prepareName(context) {
    const { oneSize } = this.props;

    if (oneSize) return context;

    return toCamelCaseString(`${context}_${ImageContainer.prepareSize()}`);
  }

  render() {
    const { id, alt } = this.props;

    return (
      <>
        {this.variations.map((variation) => (
          <Image
            key={`${variation.context}_${id}`}
            alt={alt}
            src={variation.src}
            context={variation.context}
          />
        ))}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  images: state.api.imageData,
});

export default connect(mapStateToProps, undefined)(ImageContainer);
