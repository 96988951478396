import { REHYDRATE } from 'redux-persist/lib/constants';

/* A middleware to append router location to REHYDRATE actions */
const rehydrateLocationMiddleware = (store) => (next) => (action) => {
  if (action.type !== REHYDRATE) {
    return next(action);
  }
  const state = store.getState();

  // eslint-disable-next-line no-param-reassign
  action.currentLocation = state.router.location;
  return next(action);
};

// eslint-disable-next-line import/prefer-default-export
export { rehydrateLocationMiddleware };
