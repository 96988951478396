import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import DietInformation from '../../containers/MenuDetails/DietInformation';
import PricingVariationsNotifications from '../../containers/PricingVariationsNotifications';

import { INGREDIENT_BASES } from '../../settings';

import './IngredientsSectionHeader.css';

function IngredientsSectionHeader(props) {
  const {
    menuItemId,
    sectionId,
    name,
    description,
    selectedIngredientServings,
    isCyo,
    isSectionToggleable,
    hasPricingVariations,
    replaceable,
    showDietInformation,
    onSectionToggle,
  } = props;

  const baseIngredientIds = Object.keys(INGREDIENT_BASES);

  const nonBaseIngredientServings = Object.fromEntries(
    Object.entries(selectedIngredientServings).filter(([key]) => !baseIngredientIds.includes(key)),
  );

  const ingredientsCount = Math.ceil(
    Object.values(nonBaseIngredientServings).reduce((a, b) => a + b, 0),
  );
  const showIngredientsCount = !replaceable && ingredientsCount > 0;

  return (
    <div className="IngredientsSectionHeader kale">
      <div className="IngredientsSectionName">
        <Typography variant="body1" color="secondary.dark" className="SharpGroteskSmBold15">
          {name}
          {showIngredientsCount && (
            <>
              &nbsp;(
              {ingredientsCount})
            </>
          )}
        </Typography>
      </div>
      <PricingVariationsNotifications
        menuItemId={menuItemId}
        sectionId={sectionId}
        saveAutomatically
        showAll={false}
      />
      {!isCyo && showDietInformation && !hasPricingVariations && <DietInformation />}
      {!isCyo && !hasPricingVariations && (
        <div className="Description hide-empty">{description}</div>
      )}
      {!isCyo && isSectionToggleable && (
        <div className="SectionToggle">
          <span className="caption">
            No
            {name.toLowerCase()}
          </span>
          <label className="switch" htmlFor={`SectionToggleCheckbox-${sectionId}`}>
            <input
              id={`SectionToggleCheckbox-${sectionId}`}
              type="checkbox"
              onChange={onSectionToggle}
              checked={Object.keys(selectedIngredientServings).length === 0}
            />
            <div className="slider round" />
          </label>
        </div>
      )}
    </div>
  );
}

IngredientsSectionHeader.propTypes = {
  menuItemId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  selectedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
  isCyo: PropTypes.bool.isRequired,
  isSectionToggleable: PropTypes.bool.isRequired,
  hasPricingVariations: PropTypes.bool.isRequired,
  replaceable: PropTypes.bool.isRequired,
  showDietInformation: PropTypes.bool.isRequired,
  onSectionToggle: PropTypes.func.isRequired,
};

IngredientsSectionHeader.defaultProps = {
  description: null,
};

export default IngredientsSectionHeader;
