import React from 'react';
import PropTypes from 'prop-types';

import ButtonsRow from '../ButtonsRow';
import Modal from '../Modal';

import './PricingError.css';

function PricingError({ children, buttons, isModalOpen }) {
  return (
    <Modal className="PricingError" show={isModalOpen} onHide={() => {}}>
      <div className="modal-control" />
      <Modal.Body className="kale">
        <div className="content-top">{children}</div>
        <ButtonsRow>{buttons}</ButtonsRow>
      </Modal.Body>
    </Modal>
  );
}

PricingError.propTypes = {
  children: PropTypes.node.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default PricingError;
