import React from 'react';

import Image from '../../../containers/Image';

import './MenuHeader.css';

function MenuHeader() {
  return (
    <div className="MenuHeader header-image">
      <Image id="menu-banner" source="bannerImages" />
    </div>
  );
}

export default MenuHeader;
