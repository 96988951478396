import React, { Component } from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import { range } from 'lodash';
import PropTypes from 'prop-types';

import statusEnum from '../../../enums/statusEnum';

import Button from '../../Button';
import InputAlertText from '../../InputAlertText';
import Modal from '../../Modal';
import Spinner from '../../Spinner';

import { PIN_DIGITS } from '../../../settings';

import './Verify.css';

class Verify extends Component {
  static propTypes = {
    phone: PropTypes.string.isRequired,
    pin: PropTypes.string.isRequired,
    error: PropTypes.string,
    timer: PropTypes.number,
    status: PropTypes.number.isRequired,
    isResendModalShown: PropTypes.bool,
    onPinChange: PropTypes.func.isRequired,
    onInputFocus: PropTypes.func.isRequired,
    onInputInit: PropTypes.func.isRequired,
    onPinResend: PropTypes.func.isRequired,
    onResendModalHide: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    timer: null,
    isResendModalShown: false,
  };

  render() {
    const {
      phone,
      pin,
      error,
      timer,
      status,
      isResendModalShown,
      onPinChange,
      onInputFocus,
      onInputInit,
      onPinResend,
      onResendModalHide,
    } = this.props;

    return (
      <div className="form-body">
        <Typography
          variant="calafia1"
          color="primary.dark"
          className="SignInTitle visible-lg visible-md"
        >
          Verify Phone
        </Typography>
        <p className="SignInCopy">
          Please key in the {PIN_DIGITS}
          -digits confirmation code sent to <strong>{phone}</strong>
        </p>
        <ul className="mobile-verification">
          {range(0, PIN_DIGITS).map((id) => (
            <li key={id}>
              <input
                type="tel"
                onChange={(event) => onPinChange(id, event)}
                onFocus={(event) => onInputFocus(id, event)}
                ref={(input) => onInputInit(id, input)}
                value={pin[id] || ''}
              />
            </li>
          ))}
        </ul>
        {error && (
          <div>
            <InputAlertText noIcon>{error}</InputAlertText>
          </div>
        )}
        {timer ? (
          <p>
            <Typography variant="body2">
              Resend verification code ({timer}
              s)
            </Typography>
          </p>
        ) : (
          <Button className={classnames('blue-link', { error })} onClick={onPinResend}>
            Resend verification code
          </Button>
        )}

        <Modal className="modal-small" show={status === statusEnum.REQUEST}>
          <Modal.Body className="kale">
            <p>Verifying ...</p>
            <Spinner />
          </Modal.Body>
        </Modal>
        <Modal className="modal-small" show={isResendModalShown} onHide={onResendModalHide}>
          <Modal.Body className="kale">
            <Typography variant="h3" color="primary">
              Verification code sent
            </Typography>
            <p>
              5-digits confirmation code resend to
              <strong>{phone}</strong>
            </p>
            <Button className="btn-primary" onClick={onResendModalHide}>
              Okay
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Verify;
