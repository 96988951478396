import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Button from '../../Button';

import './ErrorPage.css';

export function ErrorPage({ errorMessage }) {
  return (
    <div className="ErrorPage">
      {errorMessage ? (
        <>
          <Typography variant="h2" color="primary">
            {' '}
            Page not found.
          </Typography>
          <Button className="btn-primary" link={{ pathname: '/' }}>
            Go Back Home
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h1" color="primary">
            {' '}
            404
          </Typography>
          <Typography variant="h2" color="primary">
            {' '}
            Page not found.
          </Typography>
          <Typography variant="subtitle2" color="primary" className="ErrorPrompt">
            We can&apos;t seem to find the page you are looking for
          </Typography>
        </>
      )}
    </div>
  );
}

ErrorPage.propTypes = {
  errorMessage: PropTypes.string,
};

ErrorPage.defaultProps = {
  errorMessage: '',
};

export default ErrorPage;
