import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { setOrderTime, updateTimeSlot } from '../../../actions/order';
import * as selectors from '../../../sagas/selectors';
import { optionalMinuteMomentFormat } from '../../../utils/commonUtils';

import Select, { Option } from '../../Select';

import './TimeSlotPicker.css';

function TimeSlotPicker() {
  const orderTime = useSelector(selectors.getOrderOrderTime);
  const gglocation = useSelector(selectors.getOrderGglocation);
  const diningChoice = useSelector(selectors.getDiningChoice);
  const timeSlotId = useSelector(selectors.getOrderTimeSlotId);
  const stationTimeInterval = useSelector(selectors.getDefaultStationTimeInterval);

  const nextTimeSlots = useMemo(
    () => gglocation?.nextTimeSlots({ diningChoice }) ?? [],
    [diningChoice, gglocation],
  );
  const chosenTimeSlot = useMemo(
    () => timeSlotId && nextTimeSlots.find((timeSlot) => timeSlot.id === timeSlotId),
    [nextTimeSlots, timeSlotId],
  );
  const timeSlotsOnSameDay = useMemo(
    () =>
      (chosenTimeSlot &&
        gglocation?.timeSlotsOnSameDay({
          chosenDay: chosenTimeSlot.datetime,
          diningChoice,
        })) ??
      [],
    [chosenTimeSlot, diningChoice, gglocation],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!chosenTimeSlot) {
      // Initialize time slot
      const nextTimeSlot = gglocation.getNextTimeSlot({ diningChoice });
      if (nextTimeSlot) {
        dispatch(updateTimeSlot(nextTimeSlot.id));
        dispatch(setOrderTime(moment(nextTimeSlot.datetime).startOf('day')));
      }
    }
  }, [chosenTimeSlot, diningChoice, dispatch, gglocation]);

  useEffect(() => {
    if (orderTime) {
      // When order date changes, auto select the first time slot
      const timeSlotsOnOrderTime =
        gglocation.timeSlotsOnSameDay({
          chosenDay: orderTime,
          diningChoice,
        }) ?? [];
      const isChosenDaySame =
        !chosenTimeSlot || moment(orderTime).isSame(moment(chosenTimeSlot.datetime), 'day');
      if (!isChosenDaySame && timeSlotsOnOrderTime.length > 0) {
        dispatch(updateTimeSlot(timeSlotsOnOrderTime[0].id));
        dispatch(setOrderTime(moment(timeSlotsOnOrderTime[0].datetime).startOf('day')));
      }
    }
  }, [chosenTimeSlot, diningChoice, dispatch, gglocation, orderTime]);

  const onTimeSlotChange = (chosenTimeSlotId) => {
    dispatch(updateTimeSlot(chosenTimeSlotId, 10));
  };

  return (
    <div className="TimePicker TimeSlotPicker">
      <Select value={timeSlotId.valueOf()} onChange={onTimeSlotChange}>
        {timeSlotsOnSameDay.map((timeSlot) => {
          const startTime = optionalMinuteMomentFormat(moment(timeSlot.datetime), {
            suffixFormat: '',
          });
          const endTime = optionalMinuteMomentFormat(
            moment(timeSlot.datetime).add(stationTimeInterval, 'minutes'),
          );
          return (
            <Option key={timeSlot.id} value={timeSlot.id}>
              {startTime}-{endTime}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}

export default TimeSlotPicker;
