export const CLEAR_SELECTED_TAG_IDS = 'CLEAR_SELECTED_TAG_IDS';
export const ADD_HIGHLIGHTED_TAG_ID = 'ADD_HIGHLIGHTED_TAG_ID';
export const REMOVE_HIGHLIGHTED_TAG_ID = 'REMOVE_HIGHLIGHTED_TAG_ID';
export const UPDATE_HIGHLIGHTED_TAG_IDS = 'UPDATE_HIGHLIGHTED_TAG_IDS';
export const CLEAR_HIGHLIGHTED_TAG_IDS = 'CLEAR_HIGHLIGHTED_TAG_IDS';
export const CHECK_HIGHLIGHTED_TAG_IDS = 'CHECK_HIGHLIGHTED_TAG_IDS';
export const SAVE_HIGHLIGHTED_TAG_IDS = 'SAVE_HIGHLIGHTED_TAG_IDS';
export const RESET_HIGHLIGHTED_TAG_IDS = 'RESET_HIGHLIGHTED_TAG_IDS';

export const SHOW_INGREDIENT_MODAL = 'SHOW_INGREDIENT_MODAL';
export const HIDE_INGREDIENT_MODAL = 'HIDE_INGREDIENT_MODAL';

export const SHOW_DIET_MODAL = 'SHOW_DIET_MODAL';
export const HIDE_DIET_MODAL = 'HIDE_DIET_MODAL';

export const clearSelectedTagIds = () => ({
  type: CLEAR_SELECTED_TAG_IDS,
});

export const addHighlightedTagId = (id) => ({
  type: ADD_HIGHLIGHTED_TAG_ID,
  id,
});

export const removeHighlightedTagId = (id) => ({
  type: REMOVE_HIGHLIGHTED_TAG_ID,
  id,
});

export const updateHighlightedTagIds = (highlightedTagIds) => ({
  type: UPDATE_HIGHLIGHTED_TAG_IDS,
  highlightedTagIds,
});

export const clearHighlightedTagIds = () => ({
  type: CLEAR_HIGHLIGHTED_TAG_IDS,
});

export const checkHighlightedTagIds = ({ ingredientServings, tags, ingredients }) => ({
  type: CHECK_HIGHLIGHTED_TAG_IDS,
  ingredientServings,
  tags,
  ingredients,
});

export const saveHighlightedTagIds = () => ({
  type: SAVE_HIGHLIGHTED_TAG_IDS,
});

export const resetHighlightedTagIds = () => ({
  type: RESET_HIGHLIGHTED_TAG_IDS,
});

export const showIngredientModal = ({ ingredientId, bulkQuantity }) => ({
  type: SHOW_INGREDIENT_MODAL,
  ingredientId,
  bulkQuantity,
});

export const hideIngredientModal = () => ({
  type: HIDE_INGREDIENT_MODAL,
});

export const showDietModal = () => ({
  type: SHOW_DIET_MODAL,
});

export const hideDietModal = () => ({
  type: HIDE_DIET_MODAL,
});
