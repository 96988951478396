import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compareDates } from '@spq/utils';
import basicMoment from 'moment';
import * as momentRange from 'moment-range';
import * as momentRound from 'moment-round';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../../actions/order';
import diningChoiceEnum from '../../../enums/diningChoiceEnum';
import orderTypeEnum from '../../../enums/orderTypeEnum';
import { getDefaultDeliveryTimeInterval } from '../../../sagas/selectors';

import gglocationShape from '../../../shapes/gglocationShape';
import orderShape from '../../../shapes/orderShape';
import storeBusyShape from '../../../shapes/storeBusyShape';
import timeSlotShape from '../../../shapes/timeSlotShape';

import * as PickupTime from '../../../components/Cart/PickupTime';

const moment = momentRound.monkey(momentRange.extendMoment(basicMoment));

class PickupTimeContainer extends Component {
  static propTypes = {
    gglocation: gglocationShape.isRequired,
    timeSlots: PropTypes.objectOf(timeSlotShape).isRequired,
    order: orderShape.isRequired,
    storeBusyDetails: storeBusyShape,
    deliveryTimeInterval: PropTypes.number.isRequired,
    noStoreError: PropTypes.string,
    updateTimeSlot: PropTypes.func.isRequired,
    setOrderType: PropTypes.func.isRequired,
    setOrderTime: PropTypes.func.isRequired,
  };

  static defaultProps = {
    noStoreError: null,
    storeBusyDetails: {},
  };

  constructor(props) {
    super(props);
    const { gglocation, timeSlots, order } = props;

    if (gglocation.type === orderTypeEnum.SCHEDULED) {
      const selectedTimeSlot = timeSlots[order.timeSlotId];
      this.state = {
        selectedDate: moment(selectedTimeSlot.datetime).startOf('day'),
      };
    } else {
      this.state = {};
    }
  }

  get diningChoice() {
    const { order } = this.props;
    const { diningChoice } = order;

    return diningChoice;
  }

  get isDeliveryOrder() {
    const { diningChoice } = this;

    return diningChoice === diningChoiceEnum.DELIVERY;
  }

  get processingTime() {
    const { diningChoice } = this;
    const { gglocation, storeBusyDetails, deliveryTimeInterval } = this.props;
    const storeBusy = storeBusyDetails[gglocation.gglocationId];
    const estimatedTime = gglocation.getProcessingTime({ diningChoice, storeBusy });

    if (estimatedTime && diningChoice === diningChoiceEnum.DELIVERY) {
      // Show an estimated time range for delivery order
      return [estimatedTime, estimatedTime + deliveryTimeInterval];
    }

    return [estimatedTime];
  }

  get storePickupDates() {
    const { diningChoice } = this;
    const { gglocation } = this.props;

    const days = gglocation.possibleScheduledOrderDays({ diningChoice });

    return days.slice(0, 5);
  }

  get partnerPickupDates() {
    const { diningChoice } = this;
    const { gglocation } = this.props;

    const nextTimeSlots = gglocation.nextTimeSlots({ diningChoice });

    const dates = [];
    const days = [];
    nextTimeSlots.map((timeSlot) => {
      const date = moment(timeSlot.datetime).startOf('day');
      const day = date.dayOfYear();
      if (days.includes(day) === false) {
        dates.push(date);
        days.push(day);
      }
      return timeSlot;
    });

    return dates.slice(0, 5);
  }

  get pickupDates() {
    const { gglocation } = this.props;

    return gglocation.type === orderTypeEnum.INSTANT
      ? this.storePickupDates
      : this.partnerPickupDates;
  }

  get canPlaceInstantOrder() {
    const { diningChoice } = this;
    const { gglocation } = this.props;

    return gglocation.canPlaceInstantOrder({ diningChoice });
  }

  determineScheduledOrderTimesOnSameDate = (selectedOrderTime) => {
    const { diningChoice } = this;
    const { gglocation, order } = this.props;
    const orderTime = selectedOrderTime || (order.orderTime && moment(order.orderTime));

    return orderTime && gglocation?.getScheduledOrderTimesOnSameDate({ orderTime, diningChoice });
  };

  determineTimeSlotsForSelectedDay = (dateSelected) => {
    const { diningChoice } = this;
    const { gglocation, order } = this.props;
    const { selectedDate } = this.state;

    const orderTime = dateSelected || selectedDate || moment(order.orderTime);

    if (gglocation.type === orderTypeEnum.SCHEDULED) {
      return gglocation.timeSlotsOnSameDay({
        diningChoice,
        chosenDay: orderTime,
      });
    }

    return orderTime && gglocation?.getScheduledOrderTimesOnSameDate({ orderTime, diningChoice });
  };

  handleTypeSelected = (selectedType) => {
    const { setOrderType, order } = this.props;

    if (selectedType === orderTypeEnum.SCHEDULED && order.orderTime === null) {
      this.handleDateSelected(this.pickupDates[0]?.valueOf());
    }
    setOrderType(selectedType);
  };

  handleDateSelected = (value) => {
    const { gglocation, updateTimeSlot, setOrderTime } = this.props;
    const selectedDate = moment(parseInt(value, 10));
    if (gglocation.type === orderTypeEnum.SCHEDULED) {
      this.setState({ selectedDate });

      const timeSlots = this.determineTimeSlotsForSelectedDay(selectedDate);

      updateTimeSlot(timeSlots[0]?.id);
    } else if (gglocation.type === orderTypeEnum.INSTANT) {
      const scheduledOrderTimes =
        this.determineScheduledOrderTimesOnSameDate(selectedDate).sort(compareDates);

      setOrderTime(moment(scheduledOrderTimes[0]));
    }
  };

  handleTimeSlotSelected = (selectedTimeSlot) => {
    const { updateTimeSlot } = this.props;

    updateTimeSlot(selectedTimeSlot);
  };

  render() {
    const { gglocation, timeSlots, order, noStoreError } = this.props;
    const { selectedDate } = this.state;
    const selectedTimeSlot = timeSlots[order.timeSlotId];

    if (gglocation.type === orderTypeEnum.SCHEDULED) {
      return (
        <PickupTime.Partner
          pickupDates={this.pickupDates}
          timeSlots={this.determineTimeSlotsForSelectedDay()}
          selectedDate={selectedDate}
          selectedTimeSlot={selectedTimeSlot}
          onDateSelected={this.handleDateSelected}
          onTimeSlotSelected={this.handleTimeSlotSelected}
        />
      );
    }

    return (
      <PickupTime.Store
        isDeliveryOrder={this.isDeliveryOrder}
        canPlaceInstantOrder={this.canPlaceInstantOrder}
        processingTime={this.processingTime}
        noStoreError={noStoreError}
        pickupDates={this.pickupDates}
        selectedType={order.orderType}
        selectedTime={order.orderTime}
        onTypeSelected={this.handleTypeSelected}
        onDateSelected={this.handleDateSelected}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  gglocation: state.api.gglocations[state.order.gglocationId],
  timeSlots: state.api.timeSlots,
  order: state.order,
  storeBusyDetails: state.api.storeBusyDetails,
  deliveryTimeInterval: getDefaultDeliveryTimeInterval(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateTimeSlot: orderActions.updateTimeSlot,
      setOrderType: orderActions.setOrderType,
      setOrderTime: orderActions.setOrderTime,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PickupTimeContainer);
