import { range } from 'lodash';

import { filterIngredients, ingredientIdsArray } from './foodUtils';

export const ingredientPrices = (ingredientsArray) => {
  const result = {};
  ingredientsArray.forEach((ingredient) => {
    result[ingredient.id] = ingredient.price || 0.0;
  });

  return result;
};

/*
  Determines the priority of pricing variations.
  The ones that match menuItem more explicitely take the priority first.
  The ones that match ingredient more explicitely take the priority in a second round.
  Then the ones that are applied earlier take the priority in a third round.
*/
export const comparePricingVariations = (a, b, { menuItem, ingredient }) => {
  if (a.menuUuid === menuItem.uuid && b.menuUuid !== menuItem.uuid) return -1;
  if (a.menuUuid !== menuItem.uuid && b.menuUuid === menuItem.uuid) return 1;
  if (a.baseType === menuItem.baseType && b.baseType !== menuItem.baseType) return -1;
  if (a.baseType !== menuItem.baseType && b.baseType === menuItem.baseType) return 1;
  if (a.ingredient === ingredient.id && b.ingredient !== ingredient.id) return -1;
  if (a.ingredient !== ingredient.id && b.ingredient === ingredient.id) return 1;
  if (
    a.ingredientCategory === ingredient.ingredientCategory &&
    b.ingredientCategory !== ingredient.ingredientCategory
  )
    return -1;
  if (
    a.ingredientCategory !== ingredient.ingredientCategory &&
    b.ingredientCategory === ingredient.ingredientCategory
  )
    return 1;
  if (a.minimumServings < b.minimumServings) return -1;
  if (a.minimumServings > b.minimumServings) return 1;

  return 0;
};

/* Determines wether pricing variation applies to the menu item. */
export const matchMenuItemPricingVariation = (pricingVariation, { menuItem }) => {
  if (pricingVariation.menuUuid === menuItem.uuid) return true;
  if (pricingVariation.baseType === menuItem.baseType) return true;

  return false;
};

/* Determines wether pricing variation applies to the ingredient. */
export const matchIngredientPricingVariation = (pricingVariation, { ingredient }) => {
  if (pricingVariation.ingredient === ingredient.id) return true;
  if (pricingVariation.ingredientCategory === ingredient.ingredientCategory) return true;

  return false;
};

/* Determines wether pricing variation applies to the menuItem and ingredient combination. */
export const matchPricingVariation = (pricingVariation, { menuItem, ingredient }) => {
  if (matchMenuItemPricingVariation(pricingVariation, { menuItem }) === false) return false;
  if (matchIngredientPricingVariation(pricingVariation, { ingredient }) === false) return false;

  return true;
};

/*
  Determines wether pricing progress applies to the ingredient being priced.
  Each menu item is priced separately so pricing progress is separate for every menu item in order.
*/
export const matchIngredientPricingProgress = (ingredientPricingProgress, { ingredient }) => {
  if (ingredient.id && ingredient.id === ingredientPricingProgress.id) return true;

  if (
    ingredient.ingredientCategory &&
    ingredient.ingredientCategory === ingredientPricingProgress.ingredientCategory
  )
    return true;

  return false;
};

/*
  Returns ordered, valid pricing variations that apply to the menuItem and ingredient combination.
*/
export const findIngredientPricingVariations = (pricingVariations, { menuItem, ingredient }) =>
  pricingVariations
    .filter((pricingVariation) =>
      matchPricingVariation(pricingVariation, {
        menuItem,
        ingredient,
      }),
    )
    .sort((a, b) => comparePricingVariations(a, b, { menuItem, ingredient }));

export const calculateMinimumServings = (minimumServings, pricingProgressInteger) => {
  const minimumServingsInteger = Math.ceil(minimumServings) || 1.0;

  return minimumServingsInteger + pricingProgressInteger;
};

export const calculateMaximumServings = (maximumServings, totalProgressInteger) => {
  if (maximumServings === null) return totalProgressInteger;

  const maximumServingsInteger = Math.ceil(maximumServings);

  return Math.min(maximumServingsInteger, totalProgressInteger);
};

/*
  Creates a list of servings that are included in the menu item.
  Those will be used to set initial pricing progress.
*/
export const createIncludedServingsList = (includedServingsInteger) =>
  range(1, includedServingsInteger + 1);

/* Creates a list of servings that qualify for the pricing variation */
export const createPricingVariationServingsList = ({
  pricingVariation,
  quantityInteger,
  pricedServingsList,
  pricingProgressInteger,
}) => {
  const totalProgressInteger = pricingProgressInteger + quantityInteger;

  const minimumServingsInteger = calculateMinimumServings(
    pricingVariation.minimumServings,
    pricingProgressInteger,
  );

  const maximumServingsInteger = calculateMaximumServings(
    pricingVariation.maximumServings,
    totalProgressInteger,
  );

  if (
    minimumServingsInteger > totalProgressInteger ||
    minimumServingsInteger > maximumServingsInteger
  )
    return [];

  return range(minimumServingsInteger, maximumServingsInteger + 1)
    .map((ingredientServing) => ingredientServing - pricingProgressInteger)
    .filter((pricingVariationServing) => !pricedServingsList.includes(pricingVariationServing));
};

/* Creates a list of servings priced at a regular price */
export const createRegularServingsList = (quantityInteger, pricedServingsList) =>
  range(1, quantityInteger + 1).filter(
    (regularServing) => pricedServingsList.includes(regularServing) === false,
  );

export const addIngredientIdPricingProgress = (pricingProgress, ingredient) => {
  const ingredientIdPricingProgress = pricingProgress.find(
    (ingredientPricingProgress) => ingredientPricingProgress.id === ingredient.id,
  );

  if (!ingredientIdPricingProgress) {
    return [
      ...pricingProgress,
      {
        id: ingredient.id,
        ingredientCategory: null,
        servings: 0,
      },
    ];
  }

  return pricingProgress;
};

export const addIngredientCategoryPricingProgress = (pricingProgress, ingredient) => {
  const ingredientCategoryPricingProgress = pricingProgress.find(
    (ingredientPricingProgress) =>
      ingredientPricingProgress.ingredientCategory === ingredient.ingredientCategory,
  );

  if (!ingredientCategoryPricingProgress) {
    return [
      ...pricingProgress,
      {
        id: null,
        ingredientCategory: ingredient.ingredientCategory,
        servings: 0,
      },
    ];
  }

  return pricingProgress;
};

/* Adds ingredient pricing progress for the ingredient */
export const addIngredientPricingProgress = (initialPricingProgress, ingredient) => {
  let pricingProgress = [...initialPricingProgress];

  pricingProgress = addIngredientIdPricingProgress(pricingProgress, ingredient);
  pricingProgress = addIngredientCategoryPricingProgress(pricingProgress, ingredient);

  return pricingProgress;
};

/* Updates pricing progress that matches ingredient with added servings */
export const updatePricingProgress = (pricingProgress, ingredient, addedServings) =>
  pricingProgress.map((ingredientPricingProgress) => {
    if (matchIngredientPricingProgress(ingredientPricingProgress, { ingredient })) {
      return {
        ...ingredientPricingProgress,
        servings: ingredientPricingProgress.servings + addedServings,
      };
    }
    return ingredientPricingProgress;
  });

/* Determines pricing progress for an ingredient */
export const determineIngredientPricingProgress = (pricingProgress, ingredient) =>
  pricingProgress
    .filter((ingredientPricingProgress) =>
      matchIngredientPricingProgress(ingredientPricingProgress, { ingredient }),
    )
    .map((ingredientPricingProgress) => ingredientPricingProgress.servings)
    .reduce((a, b) => Math.max(a, b));

/* Calculates the total price for the ingredient. */
export const totalIngredientPrice = (
  menuItem,
  ingredient,
  pricingVariations,
  initialPricingProgress = [],
  includedServings = 0,
  quantity = 0,
  regularPrice = 0,
) => {
  let totalPrice = 0;
  let pricedServingsList = [];
  let pricingProgress = addIngredientPricingProgress(initialPricingProgress, ingredient);

  /* All servings should be ceiled for price calculation as the step is an integer. */
  const quantityInteger = Math.ceil(quantity - includedServings);

  /* Price variations servings */
  const ingredientPricingVariations = findIngredientPricingVariations(pricingVariations, {
    menuItem,
    ingredient,
  });

  /*
    Keep track of applied pricing variation on each ingredient serving.
    To prevent different pricing variations being applied on the same serving twice.
  */
  let appliedPricingVariationServings = 0;

  ingredientPricingVariations.map((pricingVariation) => {
    /*
      Query category progress by pricing variation not by ingredient since we want to know the
      progress of all ingredients that would match this rule.
      Remove servings that had pricing variation applied to it previously.
    */
    const pricingProgressInteger =
      determineIngredientPricingProgress(pricingProgress, {
        ...ingredient,
        ingredientCategory: pricingVariation.ingredientCategory,
      }) - appliedPricingVariationServings;
    const pricingVariationUsedBefore = pricingProgressInteger > 0;
    const pricingVariationAddedServingsList = createPricingVariationServingsList({
      pricingVariation,
      quantityInteger,
      pricedServingsList,
      pricingProgressInteger: pricingProgressInteger - appliedPricingVariationServings,
    });
    const pricingVariationAddedServingsInteger = pricingVariationAddedServingsList.length;

    if (pricingVariationAddedServingsInteger === 0) return pricingVariation;

    if (pricingVariation.isAllServings) {
      if (pricingVariationUsedBefore === false) {
        totalPrice += pricingVariation.price;
      }
    } else {
      totalPrice += pricingVariation.price * pricingVariationAddedServingsInteger;
    }
    pricedServingsList = pricedServingsList.concat(pricingVariationAddedServingsList);
    pricingProgress = updatePricingProgress(
      pricingProgress,
      ingredient,
      pricingVariationAddedServingsInteger - appliedPricingVariationServings,
    );

    /*
      Update total number of servings with applied pricing variation
      on this ingredient.
    */
    appliedPricingVariationServings = pricingVariationAddedServingsInteger;

    return pricingVariation;
  });

  const regularServingsList = createRegularServingsList(quantityInteger, pricedServingsList);
  const regularServingsInteger = regularServingsList.length;
  pricingProgress = updatePricingProgress(pricingProgress, ingredient, regularServingsInteger);
  totalPrice += regularPrice * regularServingsList.length;

  return { totalPrice, pricingProgress };
};

export const calculateIngredientServingsRegularPrices = (ingredientServings, ingredients) => {
  const query = { selectedIngredientIds: ingredientIdsArray(ingredientServings) };
  const filteredIngredients = filterIngredients(ingredients, query, {});

  return ingredientPrices(filteredIngredients);
};

const createInitialPricingProgress = ({ defaultIngredientServings, ingredients }) => {
  let pricingProgress = [];

  Object.keys(defaultIngredientServings)
    .map((defaultIngredientId) => ingredients[defaultIngredientId])
    .map((defaultIngredient) => {
      pricingProgress = addIngredientPricingProgress(pricingProgress, defaultIngredient);
      pricingProgress = updatePricingProgress(
        pricingProgress,
        defaultIngredient,
        0, // Default ingredient servings will be set to 0 in pricing process
      );
      return defaultIngredient;
    });

  return pricingProgress;
};

export const calculateIngredientServingsPrices = (
  menuItem,
  selectedIngredientServings,
  defaultIngredientServings,
  pricingVariations,
  ingredients,
) => {
  const ingredientServingsPrices = {};

  /*
    Include all default ingredients in the pricing progress to avoid double pricing in
    case default ingredient was removed to avoid double charging.
  */
  let pricingProgress = createInitialPricingProgress({
    defaultIngredientServings,
    ingredients,
  });
  const selectedIngredientRegularPrices = calculateIngredientServingsRegularPrices(
    selectedIngredientServings,
    ingredients,
  );

  ingredientIdsArray(selectedIngredientServings)
    .sort((ingredientIdA, ingredientIdB) => {
      if (defaultIngredientServings[ingredientIdA] && !defaultIngredientServings[ingredientIdB]) {
        return -1;
      }
      if (!defaultIngredientServings[ingredientIdA] && defaultIngredientServings[ingredientIdB]) {
        return 1;
      }
      return 0;
    })
    .map((ingredientId) => ingredients[ingredientId])
    .map((ingredient) => {
      /* Not including default servings for checking ingredient price */
      if (selectedIngredientServings[ingredient.id] <= defaultIngredientServings[ingredient.id]) {
        return ingredient;
      }

      const result = totalIngredientPrice(
        menuItem,
        ingredient,
        defaultIngredientServings[ingredient.id],
        selectedIngredientServings[ingredient.id],
        selectedIngredientRegularPrices[ingredient.id],
        pricingVariations,
        pricingProgress,
      );

      ingredientServingsPrices[ingredient.id] = result.totalPrice;
      pricingProgress = result.pricingProgress;

      return ingredient;
    });

  return ingredientServingsPrices;
};

export const calculateIngredientServingsTotalPrice = (
  menuItem,
  selectedIngredientServings,
  defaultIngredientServings,
  pricingVariations,
  ingredients,
) => {
  const ingredientServingsPrices = calculateIngredientServingsPrices(
    menuItem,
    selectedIngredientServings,
    defaultIngredientServings,
    pricingVariations,
    ingredients,
  );

  return Object.values(ingredientServingsPrices).reduce((priceA, priceB) => priceA + priceB, 0);
};

export const customizedMenuItemPrice = (
  menuItem,
  selectedIngredientServings,
  defaultIngredientServings,
  pricingVariations,
  ingredients,
) => {
  const totalIngredientsPrice = calculateIngredientServingsTotalPrice(
    menuItem,
    selectedIngredientServings,
    defaultIngredientServings,
    pricingVariations,
    ingredients,
  );
  const menuItemPrice = menuItem ? menuItem.price : 0;

  return totalIngredientsPrice + menuItemPrice;
};
