import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classnames from 'classnames';

import * as orderActions from '../../../actions/order';
import {
  getIsTakeOutWithBagEnabled,
  getOrderOrderItemsIds,
  getOrderTakeOutWithBagSurcharge,
} from '../../../sagas/selectors';

import Currency from '../../Currency';
import FontAwesome from '../../FontAwesome';

import { ReactComponent as ShoppingBag } from './shopping-bag.svg';

import './TakeOutWithBagOption.css';

function TakeOutWithBagOption() {
  const dispatch = useDispatch();

  const isTakeOutWithBagEnabled = useSelector(getIsTakeOutWithBagEnabled);
  const orderItemsIds = useSelector(getOrderOrderItemsIds);
  const orderTakeOutWithBagSurcharge = useSelector(getOrderTakeOutWithBagSurcharge);

  useEffect(() => {
    if (orderItemsIds.length < 1 && isTakeOutWithBagEnabled) {
      dispatch(orderActions.disableTakeOutWithBag());
    }
  }, [orderItemsIds, dispatch, isTakeOutWithBagEnabled]);

  if (orderTakeOutWithBagSurcharge === '0.00') return null;
  if (orderItemsIds.length < 1) return null;

  const onAdd = () => {
    dispatch(orderActions.enableTakeOutWithBag());
  };
  const onCancel = () => {
    dispatch(orderActions.disableTakeOutWithBag());
  };

  return (
    <div className={classnames('TakeOutWithBagOption', { isTakeOutWithBagEnabled })} tabIndex="-2">
      <div className="TakeOutWithBagImage">
        <ShoppingBag />
      </div>
      <div className="TakeOutWithBagOptionBody">
        <Typography variant="subtitle1" color="grey.main" className="TakeOutWithBagOptionName">
          <strong>Takeout Bag</strong>
        </Typography>
        {!isTakeOutWithBagEnabled && (
          <div role="presentation" className="TakeOutWithBagOptionAdd" onClick={onAdd}>
            Add
          </div>
        )}
        <div className="TakeOutWithBagPrice">
          + <Currency value={orderTakeOutWithBagSurcharge} />
        </div>
      </div>
      {!isTakeOutWithBagEnabled ? (
        <div className="TakeOutWithBagOptionCancelButton" />
      ) : (
        <button
          type="button"
          aria-label="cancel"
          className="TakeOutWithBagOptionCancelButton"
          onClick={onCancel}
        >
          <FontAwesome name="circle-xmark" />
        </button>
      )}
    </div>
  );
}

export default TakeOutWithBagOption;
