import { apiCallSuccessChannel } from '@spq/redux-api-client';
import { push } from 'redux-first-history';
import { all, put, select, take, takeLatest } from 'redux-saga/effects';

import * as errorResolutionActions from '../actions/errorResolution';
import * as orderActions from '../actions/order';

import * as selectors from './selectors';

import ecomService, { cloudFrontService } from '../services/api';

function* changeOrderGglocation() {
  yield put(cloudFrontService.gglocations.requestActionCreator());
  yield take(apiCallSuccessChannel(`${cloudFrontService.gglocations}`));

  yield put(orderActions.resetAddress());

  /* Redirects user to the gglocations page. */
  yield put(push({ pathname: '/locations' }));
  yield put(orderActions.previewOrderDispatch());
}

function* changeOrderTime() {
  yield put(cloudFrontService.gglocations.requestActionCreator());
  yield take(apiCallSuccessChannel(`${cloudFrontService.gglocations}`));

  /* Updating gglocation will cause the next available time to be selected. */
  const orderGglocation = yield select(selectors.getOrderGglocation);
  yield put(orderActions.updateGglocation({ gglocation: orderGglocation }));

  /* Redirects user to the time selection page. */
  yield put(push({ pathname: '/time' }));
  yield put(orderActions.previewOrderDispatch());
}

function* changeOrderAddress() {
  yield put(ecomService.customerAddresses.requestActionCreator());
  yield take(apiCallSuccessChannel(`${ecomService.customerAddresses}`));

  yield put(orderActions.resetAddress());
  yield put(push({ pathname: '/' }));
}

function* watchErrorResolutionRequests() {
  yield takeLatest(errorResolutionActions.CHANGE_ORDER_GGLOCATION, changeOrderGglocation);
  yield takeLatest(errorResolutionActions.CHANGE_ORDER_TIME, changeOrderTime);
  yield takeLatest(errorResolutionActions.CHANGE_ORDER_ADDRESS, changeOrderAddress);
}

export default function* pricingSaga() {
  yield all([watchErrorResolutionRequests()]);
}
