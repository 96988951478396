/* eslint-disable indent */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import * as pageActions from '../../../actions/page';
import { getIsGuestLoginEnabled } from '../../../sagas/selectors';

import Button from '../../Button';

import './GuestLogin.css';

function GuestLogin() {
  const dispatch = useDispatch();

  const onButtonClick = () => {
    dispatch(pageActions.setIsGuestLoginFlow(true));
    dispatch(push({ pathname: '/signIn/phone' }));
  };

  const isGuestLoginEnabled = useSelector(getIsGuestLoginEnabled);
  if (!isGuestLoginEnabled) {
    return null;
  }

  return (
    <Button type="button" className="btn-secondary guestButton" onClick={onButtonClick}>
      Continue as guest
    </Button>
  );
}

export default GuestLogin;
