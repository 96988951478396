import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';

import * as pageActions from '../../../actions/page';
import * as selectors from '../../../sagas/selectors';

import GglocationsSelect from '../../../containers/GglocationsSelect';
import Button from '../../Button';
import Currency from '../../Currency';

import iconLocale from '../../../images/icon-locale.svg';

import './Pickup.css';

function Pickup({ orderLink }) {
  const dispatch = useDispatch();
  const isLandingPageOrder = useSelector(selectors.getIsLandingPageOrder);
  const landingPageDisabledGglocationTypes = useSelector(
    selectors.getLandingPageDisabledGglocationTypes,
  );
  const groupOrderingUrl = useSelector(selectors.getGroupOrderingUrl);
  const isStationFlow = useSelector(selectors.getIsStationFlow);

  const handleCloseDiningChoiceModal = () => {
    dispatch(pageActions.closeDiningChoiceModal());
  };
  const handlePickUpSelectionUpdate = (gglocation) => {
    if (!gglocation && isLandingPageOrder) {
      // Go to locations page for landing page when select other store
      dispatch(push({ pathname: '/locations' }));
    }
  };

  return (
    <div className="Pickup">
      <p>Select a {isStationFlow ? 'Station' : 'store'} to order from</p>
      <GglocationsSelect
        icon={iconLocale}
        isLandingPageOrder={isLandingPageOrder}
        landingPageDisabledGglocationTypes={landingPageDisabledGglocationTypes}
        onSelectionUpdate={handlePickUpSelectionUpdate}
      />
      <Button
        className="btn-primary center"
        link={!isLandingPageOrder && orderLink}
        onClick={isLandingPageOrder && handleCloseDiningChoiceModal}
      >
        {isLandingPageOrder ? 'Continue' : 'Start order'}
      </Button>
      {groupOrderingUrl && (
        <div className="groupOrderingUrlLabel">
          <Typography variant="subtitle2" color="grey.main" fontWeight="600">
            Ordering for a group?
          </Typography>
          <Typography variant="body2" color="grey.main">
            Go to{' '}
            <Button
              className="blue-link"
              href={groupOrderingUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              {groupOrderingUrl}
            </Button>{' '}
            for orders above <Currency value={300} decimalPlaces={0} />.
          </Typography>
        </div>
      )}
    </div>
  );
}

Pickup.propTypes = {
  orderLink: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Pickup;
