import statusEnum from '@spq/redux-api-client/lib/statusEnum';
import { rehydrateReducer } from '@spq/utils';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { v4 as uuidV4 } from 'uuid';

import * as orderActions from '../actions/order';
import * as orderItemActions from '../actions/orderItem';
import orderItemStatusEnum from '../enums/orderItemStatusEnum';
import * as foodUtils from '../utils/foodUtils';
import * as orderItemUtils from '../utils/orderItemUtils';
import * as orderPreviewUtils from '../utils/orderPreviewUtils';

import packageInfo from '../../package.json';

const defaultState = Object.freeze({
  id: null,
  menuItemId: null,
  favoriteMenuItemId: null,
  personalSettings: null,
  selectedIngredientServings: {},
  highlightedIngredientServings: {},
  defaultIngredientServings: {},
  ingredientBreakdown: [],
  pricing: {
    totalPrice: null,
    totalTax: null,
    discountAmount: null,
    pretaxPrice: null,
    price: null,
  },
  bulkQuantity: null,
  activeNutritionalGroupId: null,
  nutrients: {},
  errors: [],
  status: {
    calculateNutrients: statusEnum.INITIAL,
  },
  itemType: null,
  isUpsold: false,
  isModifying: false,
  favMenuChangedId: null,
  orderItemStatus: orderItemStatusEnum.INITIAL,
  version: packageInfo.version,
});

// eslint-disable-next-line default-param-last
export default function orderItemReducer(state = defaultState, action) {
  switch (action.type) {
    case REHYDRATE: {
      const incoming = action.payload && action.payload.orderItem;
      if (incoming && incoming.version === state.version) {
        /* Check if the state should be restored */
        const { currentLocation } = action;
        if (currentLocation.state || window.hasRefreshed) {
          return Object.freeze({
            ...rehydrateReducer(
              [
                'id',
                'apiId',
                'menuItemId',
                'bulkQuantity',
                'favoriteMenuItemId',
                'personalSettings',
                'selectedIngredientServings',
                'highlightedIngredientServings',
                'defaultIngredientServings',
                'ingredientBreakdown',
                'pricing',
                'itemType',
                'orderItemStatus',
                'nutrients',
                'isUpsold',
                'isModifying',
              ],
              state,
              incoming,
            ),
          });
        }
      }
      return Object.freeze({ ...state });
    }
    case orderItemActions.CREATE_ORDER_ITEM_SUCCESS:
      return Object.freeze({
        ...defaultState,
        ...action,
        id: uuidV4(),
        type: undefined,
      });
    case orderItemActions.MODIFY_ORDER_ITEM:
      return Object.freeze({
        ...state,
        ...action.orderItem,
        isModifying: true,
        orderItemStatus: orderItemStatusEnum.READY,
      });
    case orderItemActions.RESET_ORDER_ITEM:
      return Object.freeze({
        ...defaultState,
      });
    case orderActions.RESET_ORDER:
      return Object.freeze({
        ...defaultState,
      });
    case orderActions.UPDATE_ORDER_ORDER_ITEM:
      /* Reset orderItem reducer state to default when it's saved in order reducer */
      return Object.freeze({
        ...defaultState,
      });
    case orderItemActions.SET_INGREDIENT_SERVINGS: {
      const storeKey = `${action.store}IngredientServings`;
      return Object.freeze({
        ...state,
        [storeKey]: action.ingredientServings,
      });
    }
    case orderItemActions.ADD_INGREDIENT_SERVINGS: {
      const storeKey = `${action.store}IngredientServings`;
      return Object.freeze({
        ...state,
        [storeKey]: foodUtils.addIngredientServings(state[storeKey], action.ingredientServings),
      });
    }
    case orderActions.UPDATE_INGREDIENT_BREAKDOWNS: {
      return Object.freeze({
        ...state,
        ingredientBreakdown: orderItemUtils.determineOrderItemBreakdown(state, action.ordermenu),
      });
    }
    case orderItemActions.REMOVE_INGREDIENT: {
      const storeKey = `${action.store}IngredientServings`;

      return Object.freeze({
        ...state,
        [storeKey]: foodUtils.removeIngredient(state[storeKey], action.ingredientId),
      });
    }
    case orderItemActions.REMOVE_SECTION_INGREDIENTS: {
      const storeKey = `${action.store}IngredientServings`;
      return Object.freeze({
        ...state,
        [storeKey]: foodUtils.removeSectionIngredients(action.sectionId, {
          selectedIngredientServings: state[storeKey],
          ingredientCategories: action.state.api.ingredientCategories,
          ingredients: action.state.api.ingredients,
        }),
      });
    }
    case orderItemActions.RESTORE_SECTION_INGREDIENTS: {
      const storeKey = `${action.store}IngredientServings`;
      return Object.freeze({
        ...state,
        [storeKey]: foodUtils.restoreSectionIngredients(action.sectionId, {
          selectedIngredientServings: state[storeKey],
          defaultIngredientServings: state.defaultIngredientServings,
          ingredientCategories: action.state.api.ingredientCategories,
          ingredients: action.state.api.ingredients,
        }),
      });
    }
    case orderItemActions.REPLACE_SECTION_INGREDIENTS: {
      const storeKey = `${action.store}IngredientServings`;
      return Object.freeze({
        ...state,
        [storeKey]: foodUtils.addIngredientServings(
          foodUtils.removeSectionIngredients(action.sectionId, {
            selectedIngredientServings: state[storeKey],
            ingredientCategories: action.state.api.ingredientCategories,
            ingredients: action.state.api.ingredients,
          }),
          action.ingredientServings,
        ),
      });
    }
    case orderItemActions.INCREMENT_INGREDIENT_SERVING: {
      const storeKey = `${action.store}IngredientServings`;
      return Object.freeze({
        ...state,
        [storeKey]: {
          ...state[storeKey],
          [action.ingredientId]: state[storeKey][action.ingredientId] + 1,
        },
      });
    }
    case orderItemActions.DECREMENT_INGREDIENT_SERVING: {
      const storeKey = `${action.store}IngredientServings`;
      const ingredientServings = {
        ...state[storeKey],
        [action.ingredientId]: state[storeKey][action.ingredientId] - 1,
      };

      if (ingredientServings[action.ingredientId] === 0) {
        delete ingredientServings[action.ingredientId];
      }

      return Object.freeze({
        ...state,
        [storeKey]: ingredientServings,
      });
    }
    case orderItemActions.RESET_HIGHLIGHTED_INGREDIENT_SERVINGS: {
      return Object.freeze({
        ...state,
        highlightedIngredientServings: { ...state.selectedIngredientServings },
      });
    }
    case orderItemActions.UPDATE_LABEL: {
      const personalSettings = state.personalSettings.map((personalSetting) => {
        if (personalSetting.id !== action.id) {
          return personalSetting;
        }

        return {
          ...personalSetting,
          label: action.value,
        };
      });

      return Object.freeze({
        ...state,
        personalSettings,
      });
    }
    case orderItemActions.UPDATE_PREFERENCE: {
      const personalSettings = state.personalSettings.map((personalSetting) => {
        if (personalSetting.id !== action.id) {
          return personalSetting;
        }

        return {
          ...personalSetting,
          preferences: {
            ...personalSetting.preferences,
            [action.preferenceGroupId]: action.preferenceId,
          },
        };
      });

      return Object.freeze({
        ...state,
        personalSettings,
      });
    }
    case orderActions.UPDATE_PRICES: {
      const currentOrderItem = action.orderPreviewCurrentOrderItem;

      if (currentOrderItem.length > 0) {
        const pricingData = orderPreviewUtils.mergeOrderItemPrices(
          action.orderPreviewCurrentOrderItem,
        );

        return Object.freeze({
          ...state,
          pricing: pricingData,
          ingredientBreakdown: currentOrderItem[0].menu.ingredientBreakdown,
        });
      }

      return Object.freeze({
        ...state,
      });
    }
    case orderItemActions.SAVE_HIGHLIGHTED_INGREDIENT_SERVINGS: {
      return Object.freeze({
        ...state,
        selectedIngredientServings: { ...state.highlightedIngredientServings },
      });
    }
    case orderItemActions.INCREMENT_ORDER_ITEM_QUANTITY: {
      return Object.freeze({
        ...state,
        personalSettings: [
          ...state.personalSettings,
          foodUtils.getDefaultPersonalSettings({
            preferenceGroups: action.state.api.preferenceGroups,
          }),
        ],
      });
    }
    case orderItemActions.DECREMENT_ORDER_ITEM_QUANTITY: {
      if (state.personalSettings.length === 1) return state;

      return Object.freeze({
        ...state,
        personalSettings: state.personalSettings.slice(0, state.personalSettings.length - 1),
      });
    }
    case orderItemActions.FILTER_TAG_INGREDIENT_SERVINGS: {
      const highlightedIngredientServings = foodUtils.tagIngredientServings(
        action.tagIds,
        state.highlightedIngredientServings,
        action.state.api.ingredients,
        action.state.api.tags,
      );

      const selectedIngredientServings = foodUtils.tagIngredientServings(
        action.tagIds,
        state.selectedIngredientServings,
        action.state.api.ingredients,
        action.state.api.tags,
      );

      return Object.freeze({
        ...state,
        highlightedIngredientServings,
        selectedIngredientServings,
      });
    }
    case orderItemActions.RESTORE_REMOVED_TAG_INGREDIENTS: {
      const defaultTagIngredientServings = foodUtils.inverseTagIngredientServings(
        [action.tagId],
        state.defaultIngredientServings,
        action.state.api.ingredients,
        action.state.api.tags,
      );

      return Object.freeze({
        ...state,
        highlightedIngredientServings: {
          ...state.highlightedIngredientServings,
          ...defaultTagIngredientServings,
        },
        selectedIngredientServings: {
          ...state.selectedIngredientServings,
          ...defaultTagIngredientServings,
        },
      });
    }
    case orderItemActions.SET_ACTIVE_NUTRITIONAL_GROUP: {
      return Object.freeze({
        ...state,
        activeNutritionalGroupId: action.nutritionalGroupId,
      });
    }
    case orderItemActions.SET_FAV_MENU_CHANGED_ID: {
      return Object.freeze({
        ...state,
        favMenuChangedId: action.favMenuChangedId,
      });
    }
    case orderItemActions.RESET_FAV_MENU_CHANGED_ID: {
      return Object.freeze({
        ...state,
        favMenuChangedId: null,
      });
    }
    default:
      return state;
  }
}
