import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as orderUtils from '../../../utils/orderUtils';

import './OrderProgressBar.css';

function OrderProgressBar(props) {
  const { isDeliveryOrder, status } = props;

  if (orderUtils.isOrderStatusCancelled(status)) {
    return null;
  }

  let currentStepId = 1;

  if (orderUtils.isOrderStatusAccepted(status)) {
    currentStepId = 2;
  } else if (orderUtils.isOrderStatusCompleted(status)) {
    currentStepId = 3;
  }

  const steps = [
    {
      id: 1,
      caption: (
        <p>
          Order
          <br />
          placed
        </p>
      ),
    },
    {
      id: 2,
      caption: (
        <p>
          Order
          <br />
          confirmed
        </p>
      ),
    },
    {
      id: 3,
      caption: isDeliveryOrder ? (
        <p>Out for delivery</p>
      ) : (
        <p>
          Ready for
          <br />
          collection
        </p>
      ),
    },
  ];

  return (
    <div className="OrderProgressBar">
      <div className="StepTickRow">
        <div className="StepTicks">
          {steps.map((step) => (
            <div
              className={classnames('StepTick', { marked: step.id <= currentStepId })}
              key={step.id}
            >
              {step.id}
            </div>
          ))}
        </div>
        <ul className="StepBar">
          <li className="StepBarBg" />
          <li className={classnames('BarProgress', `BarProgress-${currentStepId}`)} />
        </ul>
      </div>
      <div className="Steps">
        {steps.map((step) => (
          <div className="Step" key={step.id} id={step.id}>
            {step.caption}
          </div>
        ))}
      </div>
    </div>
  );
}

OrderProgressBar.propTypes = {
  isDeliveryOrder: PropTypes.bool.isRequired,
  status: PropTypes.number.isRequired,
};

export default OrderProgressBar;
