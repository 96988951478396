import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Addresses from './containers/Account/Addresses';
import AccountEdit from './containers/Account/Edit';
import AccountFavorites from './containers/Account/Favorites';
import AccountPastOrders from './containers/Account/PastOrders';
import PaymentMethods from './containers/Account/PaymentMethods';
import AddAddress from './containers/AddAddress';
import App from './containers/App';
import Cyo from './containers/Cyo';
import FacebookPopup from './containers/FacebookPopup';
import GglocationLocator from './containers/GglocationLocator';
import Landing from './containers/Landing';
import Menu from './containers/Menu';
import MenuDetails from './containers/MenuDetails';
import Order from './containers/Order';
import PaymentPopup from './containers/PaymentPopup';
import Personalize from './containers/Personalize';
import PickupTimeSelector from './containers/PickupTimeSelector';
import SignIn from './containers/SignIn';
import Login from './containers/SignIn/Login';
import Payment from './containers/SignIn/Payment';
import PaymentFailure from './containers/SignIn/PaymentFailure';
import Phone from './containers/SignIn/Phone';
import Verify from './containers/SignIn/Verify';
import Account from './components/Account';
import Browser from './components/Browser';
import FacebookReset from './components/FacebookReset/FacebookReset';
import KioskPairingModal from './components/KioskPairingModal/KioskPairingModal';
import LandingPage from './components/LandingPage';
import LoyaltyCollection from './components/LoyaltyCollection';
import ScanOrderNumberModal from './components/ScanOrderNumberModal/ScanOrderNumberModal';
import FacebookRequestEmail from './components/SignIn/FacebookRequestEmail';
import MarketingOptIn from './components/SignIn/MarketingOptIn';
import Register from './components/SignIn/Register';
import SignInRouter from './components/SignIn/SignInRouter';
import UpsellScreen from './components/UpsellScreen/UpsellScreen';

export default function AllRoutes() {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Landing />} />
        <Route path="locations" element={<GglocationLocator />} />
        <Route path="addAddress" element={<AddAddress />} />
        <Route path="time" element={<PickupTimeSelector />} />
        <Route path="loyalty" element={<LoyaltyCollection />} />
        <Route path="menu">
          <Route index element={<Menu />} />
          <Route path="details/:menuItemId/:page/:pageSectionId" element={<MenuDetails />} />
          <Route path="details/:menuItemId/:page" element={<MenuDetails />} />
          <Route path="details/:menuItemId" element={<MenuDetails />} />
        </Route>
        <Route path="site/:landingPageSlug">
          <Route index element={<LandingPage />} />
        </Route>
        <Route path="cyo/:menuItemId/:sectionId" element={<Cyo />} />
        <Route path="cyo/:menuItemId" element={<Cyo />} />
        <Route path="cyo" element={<Cyo />} />
        <Route path="personalize/:menuItemId" element={<Personalize />} />
        <Route path="personalize" element={<Personalize />} />
        <Route path="upsell" element={<UpsellScreen />} />
        <Route path="signIn" element={<SignIn />}>
          <Route index element={<SignInRouter />} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="phone" element={<Phone />} />
          <Route path="verify" element={<Verify />} />
          <Route path="facebookRequestEmail" element={<FacebookRequestEmail />} />
          <Route path="facebookReset" element={<FacebookReset />} />
          <Route path="marketingOptIn" element={<MarketingOptIn />} />
          <Route path="payment">
            <Route index element={<Payment />} />
            <Route path="failure" element={<PaymentFailure />} />
          </Route>
        </Route>
        <Route path="order/:orderId" element={<Order />} />
        <Route path="account" element={<Account />}>
          <Route path="edit" element={<AccountEdit />} />
          <Route path="paymentMethods" element={<PaymentMethods />} />
          <Route path="favorites" element={<AccountFavorites />} />
          <Route path="addresses" element={<Addresses />} />
          <Route path="orders" element={<AccountPastOrders />} />
        </Route>
        <Route path="browser" element={<Browser />} />
        <Route path="popup">
          <Route path="payment" element={<PaymentPopup />} />
          <Route path="facebook" element={<FacebookPopup />} />
        </Route>
        <Route path="kioskPairing" element={<KioskPairingModal />} />
        <Route path="scan" element={<ScanOrderNumberModal />} />
      </Route>
    </Routes>
  );
}
