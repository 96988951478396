import PropTypes from 'prop-types';

export const upsellMenuShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  upsellSortOrder: PropTypes.number.isRequired,
});

const upsellCategoryShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  sortOrder: PropTypes.number.isRequired,
  upsellMenus: PropTypes.arrayOf(upsellMenuShape).isRequired,
});

export default upsellCategoryShape;
