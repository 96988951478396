import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as selectors from '../../../sagas/selectors';

import Image from '../../../containers/Image';
import IngredientChoicePrice from '../../../containers/IngredientsPicker/IngredientChoicePrice';
import Button from '../../Button';
import FontAwesome from '../../FontAwesome';

import './IngredientChoice.css';

function Single(props) {
  const {
    id,
    name,
    image,
    price,
    quantity,
    includedServings,
    energy,
    disabled,
    unavailable,
    size,
    isPriceDisplayed,
    onClick,
    onDetailsClick,
  } = props;

  const isNutritionalInformationHidden = useSelector(selectors.getIsNutritionalInformationHidden);

  return (
    <div
      className={classnames(`Ingredient-${id}`, 'IngredientChoiceWrapper', `Ingredient${size}`, {
        disabled,
      })}
    >
      <div
        role="presentation"
        className={classnames(
          'IngredientChoice',
          'IngredientChoiceSingle',
          { selected: quantity > 0 },
          { unavailable },
          { addOn: includedServings < quantity && isPriceDisplayed },
        )}
        onClick={onClick}
      >
        <ul className="IngredientDetails">
          <li>
            <div className="IngredientPicture">
              <div className="Status">
                {isPriceDisplayed && (
                  <IngredientChoicePrice
                    price={price}
                    includedServings={includedServings}
                    quantity={quantity}
                  />
                )}
              </div>
              <Image id={image} />
            </div>
          </li>
          <li>
            <div className="IngredientChoiceName">
              <div className="IngredientChoiceTitleRow">
                <Typography variant="body2" color="grey.main" className="Name">
                  {name}
                </Typography>
                {!isNutritionalInformationHidden && (
                  <Button className="IconInfo" onClick={onDetailsClick}>
                    <FontAwesome name="info-circle" />
                  </Button>
                )}
              </div>
              {!isNutritionalInformationHidden && (
                <div className="IngredientChoiceEnergyRow">
                  <p className="Energy">{energy} kcals</p>
                  <Button className="IconInfo" onClick={onDetailsClick}>
                    <FontAwesome name="info-circle" />
                  </Button>
                </div>
              )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

Single.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.number.isRequired,
  price: PropTypes.number,
  quantity: PropTypes.number,
  includedServings: PropTypes.number,
  energy: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  unavailable: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['Big', 'Medium', 'Small', 'ExtraSmall']),
  isPriceDisplayed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onDetailsClick: PropTypes.func.isRequired,
};

Single.defaultProps = {
  price: null,
  quantity: 0,
  includedServings: 0,
  disabled: false,
  size: 'Big',
};

export default Single;
