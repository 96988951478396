import itemTypeEnum from '../enums/itemTypeEnum';
import rewardTypeEnum from '../enums/rewardTypeEnum';
import sceneEnum from '../enums/sceneEnum';
import { getSearchParam } from '../utils/historyUtils';
import * as orderItemUtils from '../utils/orderItemUtils';
import * as orderUtils from '../utils/orderUtils';

export const getOrder = (state) => state.order;
export const getOrderOrderItems = (state) => state.order.orderItems;
export const getOrderOrderItemsIds = (state) => state.order.orderItemsIds;
export const selectOrderOrderItem = (state, orderItemId) => state.order.orderItems[orderItemId];
export const getDiningChoice = (state) => state.order.diningChoice;
export const getDiningChoiceOptions = (state) => state.order.diningChoiceOptions;
export const getIsDeliveryAvailable = (state) => !!state.order.diningChoiceOptions.delivery;
export const getOrderScheduler = (state) => state.order.scheduler;
export const getOrderOrderTime = (state) => state.order.orderTime;
export const getOrderGglocationId = (state) => state.order.gglocationId;
export const getOrderCustomerAddressId = (state) => state.order.customerAddressId;
export const getCustomerAddressIds = (state) => state.user.customerAddressIds;
export const getIsStationFlow = (state) => state.order.isStationFlow;
export const getOrderTimeSlotId = (state) => state.order.timeSlotId;
export const getIsGuestLimitReached = (state) => state.order.isGuestLimitReached;
export const getIsGuestPaymentUnavailable = (state) => state.order.isGuestPaymentUnavailable;

export const getOrderItem = (state) => state.orderItem;

export const getValidOrderItem = (state) => {
  const orderItem = getOrderItem(state);

  if (!orderItemUtils.isOrderItemValid(orderItem)) {
    return undefined;
  }

  return orderItem;
};

export const getGglocations = (state) => state.api;
export const getStoresList = (state) =>
  state.api.storeIds.map((gglocationId) => state.api.gglocations[gglocationId]);
export const selectGglocation = (state, gglocationId) => state.api.gglocations[gglocationId];
export const getOrderGglocation = (state) => selectGglocation(state, state.order.gglocationId);

export const getFood = (state) => state.food;
export const getPreferenceGroups = (state) => state.api.preferenceGroups;
export const getApiBases = (state) => state.api.bases;
export const getIngredients = (state) => state.api.ingredients;
export const getIngredientCategories = (state) => state.api.ingredientCategories;
export const selectMenuItem = (state, menuItemId) => state.api.menuItems[menuItemId];
export const selectMenuItemIngredientServings = (state, menuItemId) =>
  state.api.menuItemIngredientServings[menuItemId];
export const getNutrients = (state) => state.api.nutrients;

export const selectCustomerAddress = (state, customerAddressId) =>
  state.user.customerAddresses[customerAddressId];
export const selectUserPaymentMethod = (state, id) => state.api.paymentMethods[id];

export const getSelectedTagIds = (state) => state.food.selectedTagIds;

export const getTags = (state) => state.api.tags;
export const getHighlightedTagIds = (state) => state.food.highlightedTagIds;
export const getIsDietModalShown = (state) => state.food.isDietModalShown;

export const getCurrentMenuGroup = (state) =>
  state.api.menuGroups[state.api.gglocationMenuGroups[state.order.gglocationId]];

export const getCurrentMenuGroupId = (state) => {
  const isLandingPageOrder = orderUtils.isLandingPageOrder({
    landingPageHistory: state.order.landingPageHistory,
  });

  if (isLandingPageOrder) {
    return state.landingPage.menuGroupId;
  }

  const orderGglocationId = state.order.gglocationId;
  if (orderGglocationId === null) {
    return null;
  }

  const menuGroupId = state.api.gglocationMenuGroups[orderGglocationId];

  return menuGroupId;
};

export const getGeolocation = (state) => state.geolocation;

export const selectMenuGroupMenuItems = (state, menuGroupId) => {
  const menuGroup = state.api.menuGroups[menuGroupId];
  return menuGroup.map((menuItemId) => state.api.menuItems[menuItemId]);
};

export const findGglocationMenuItemByApiId = (state, apiId) => {
  const orderGglocationId = state.order.gglocationId;
  if (orderGglocationId === null) {
    return null;
  }

  const menuGroupId = state.api.gglocationMenuGroups[orderGglocationId];
  const menuGroup = state.api.menuGroups[menuGroupId];
  const menuItems = menuGroup.map((menuItemId) => state.api.menuItems[menuItemId]);

  return menuItems.find((menuItem) => menuItem.apiId === apiId);
};

/* User Selectors */
export const getUserToken = (state) => state.user.token;
export const getIsVersionUpdated = (state) => state.user.isVersionUpdated;
export const getPaymentToken = (state) => state.user.paymentToken;
export const getUserUuid = (state) => state.user.uuid;
export const getPendingPhoneNumber = (state) => state.user.pendingPhone;
export const getPhoneNumber = (state) => state.user.phone;
export const getPhoneConfirmed = (state) => state.user.phoneConfirmed;
export const getUser = (state) => state.user;
export const getFirstName = (state) => state.user.firstName;
export const getRegistrationFirstName = (state) => state.user.registration.firstName;
export const getRegistrationLastName = (state) => state.user.registration.lastName;
export const getRegistrationEmail = (state) => state.user.registration.email;
export const getEmail = (state) => state.user.email;
export const getUserStatus = (state) => state.user.status;
export const getIsEmailVerificationPopUpShown = (state) => state.user.isEmailVerificationPopUpShown;
export const getIsPasswordModalShown = (state) => state.user.isPasswordModalShown;

export const getGuestLogin = (state) => state.user.guestLogin;
export const getGuestPhone = (state) => state.user.guestPhone;
export const getGuestEmail = (state) => state.user.guestEmail;
export const getIsGuestSession = (state) => state.user.isGuestSession;

export const getPaymentMethodIds = (state) => state.api.paymentMethodIds;
export const getUserDefaultPaymentMethod = (state) => state.api.defaultPaymentMethod;
export const getApplePaymentMethod = (state) => state.api.applePayPaymentMethod;
export const getSelectedPaymentMethod = (state) => state.order.selectedPaymentMethod;
export const getIsCarbonOffsetEnabled = (state) => state.order.isCarbonOffsetEnabled;
export const getCarbonOffsetFee = (state) => state.order.optionalSurcharges.addCarbonOffset?.price;
export const getMinOrderFee = (state) => state.order.surcharges?.enforceMinPrice?.price;
export const getAbsoluteMinOrderFee = (state) =>
  parseFloat(state.order.optionalSurcharges?.orderTotalEnf?.price ?? 0);
export const getFreeDeliveryThreshold = (state) =>
  parseFloat(state.order.optionalSurcharges.freeDeliveryAward?.price ?? 0);
export const getIsTakeOutWithBagEnabled = (state) => state.order.isTakeOutWithBagEnabled;
export const getBeforeSignIn = (state) => state.user.beforeSignIn;
export const getAfterSignIn = (state) => state.user.afterSignIn;
export const getAfterSignInPath = (state) => state.user.afterSignIn.pathname;
export const getPaymentMethods = (state) =>
  state.api.paymentMethodIds.map((paymentMethodId) => state.api.paymentMethods[paymentMethodId]);
export const getAddPaymentMethodUrl = (state) => state.user.addPaymentMethodUrl;
export const getAddPaymentMethodType = (state) => state.user.addPaymentMethodType;
export const getIsAddPaymentMethodFormShown = (state) => state.page.isAddPaymentMethodFormShown;
export const getPaymentCardFormError = (state) => state.page.paymentCardFormError;
export const selectMenuItemFavoriteMenuItemId = (state, menuItemId) =>
  state.api.menuItemFavoriteMenuItemId[menuItemId];
export const selectUserOrderMenuItem = (state, userOrderId) =>
  state.api.userOrderMenuItems[userOrderId];
export const selectFavoriteMenuItem = (state, favoriteMenuItemId) =>
  state.api.favoriteMenuItems[favoriteMenuItemId];
export const getFavoriteMenuItemIds = (state) => state.api.favoriteMenuItemIds;
export const selectItemFromItemType = (state, itemType, itemId) => {
  switch (itemType) {
    case itemTypeEnum.MENU_ITEM:
      return state.api.menuItems[itemId];
    case itemTypeEnum.USER_ORDER_ITEM:
      return state.api.userOrderMenuItems[itemId];
    case itemTypeEnum.FAVORITE_MENU_ITEM:
      return state.api.favoriteMenuItems[itemId];
    default:
      return null;
  }
};
export const selectMenuGroup = (state, menuGroupId) => state.api.menuGroups[menuGroupId];

/* Page selectors */
/* TODO: getCurrentLocation might be redundant, better
  just use history, the issue is that we would like to be consistent with
  redux-first-history's method to retrieve current location from router */
export const getCurrentLocation = (state) => state.router.location;
export const getCurrentLocationHistory = (state) =>
  state.history.locations[state.history.currentIndex];

export const getHistory = (state) => state.history;
export const getIsMobileMenuOpen = (state) => state.page.isMobileMenuOpen;

export const getOpenMenuItemPageAfterTimeSelected = (state) =>
  state.order.openMenuItemPageAfterTimeSelected;
export const getOpenCartAfterTimeSelected = (state) => state.order.openCartAfterTimeSelected;

export const getIsDiningChoiceModalOpen = (state) => state.page.isDiningChoiceModalOpen;

export const getIsScannerModalOpen = (state) => {
  const { search } = getCurrentLocation(state);
  const isScannerQuery = getSearchParam(search, 'showScanner') === 'true';

  return state.page.isScannerModalOpen || isScannerQuery;
};
export const getIsRewardModalOpen = (state) => state.page.isRewardModalOpen;

export const getCurrentScene = (state) => {
  if (state.page.isScannerModalOpen) {
    return sceneEnum.SCAN_RECEIPT;
  }
  return state.page.currentScene;
};

/* Loyalty Selectors */

export const getSelectedPromoCode = (state) => state.loyalty.selectedPromoCode;
export const getScanHistory = (state) => state.loyalty.scanHistory;
export const getUserRewards = (state) => state.loyalty.rewards;
export const getClaims = (state) => state.loyalty.claims;
export const getCollections = (state) => state.loyalty.collections;
export const getPromos = (state) => state.loyalty.promos;
export const getSpecials = (state) => state.loyalty.specials;
export const getMassRewards = (state) => state.loyalty.massRewards;
export const getMassRewardClaims = (state) => state.loyalty.massRewardClaims;
export const getAllRewards = (state) => [
  ...state.loyalty.promos,
  ...state.loyalty.collections,
  ...state.loyalty.specials,
];
export const getHighlightedReward = (state) => {
  const rewardUuid = state.loyalty.highlightedUserRewardUuid;
  return state.loyalty.claims.find((claim) => claim.uuid === rewardUuid);
};
export const getSelectedUserRewardUuid = (state) => state.loyalty.selectedUserRewardUuid;
export const getSelectedRewardRewardUuid = (state) =>
  state.loyalty.rewards[state.loyalty.selectedUserRewardUuid] &&
  state.loyalty.rewards[state.loyalty.selectedUserRewardUuid].rewardUuid;

export const getSelectedCollectionId = (state) => state.loyalty.selectedCollectionId;

export const getSelectedReward = (state) => {
  const rewardId = state.loyalty.selectedRewardId;
  const rewardType = state.loyalty.selectedRewardType;
  switch (rewardType) {
    case rewardTypeEnum.COLLECTION:
      return state.loyalty.collections.find((collection) => collection.id === rewardId);
    case rewardTypeEnum.PROMO:
      return state.loyalty.promos.find((promo) => promo.id === rewardId);
    case rewardTypeEnum.MASS:
      return state.loyalty.massRewards.find((massReward) => massReward.id === rewardId);
    case rewardTypeEnum.SPECIAL:
      return state.loyalty.specials.find((special) => special.id === rewardId);
    default:
      return state.loyalty.collections.find((collection) => collection.id === rewardId);
  }
};

export const getOrderDiscountedSubtotal = (state) =>
  state.order.pricing.price - state.order.pricing.discountAmount;
export const getOrderTotalPrice = (state) => state.order.pricing.totalPrice;
export const getOrderPricing = (state) => state.order.pricing;
export const getOrderReadyPricing = (state) => state.order.pricing.ready || {};
export const getOrderSurcharges = (state) => state.order.surcharges;
export const getBeforeSignInOrderPrice = (state) => state.order.beforeSignInOrderPrice;

export const getOrderStatus = (state) => state.order.status;
export const getOrderPreviewStatus = (state) => state.order.status.orderPreview;

export const getTimeSlots = (state) => state.api.timeSlots;

export const getTimeSlot = (state) => {
  const timeSlots = getTimeSlots(state);
  const order = getOrder(state);

  return timeSlots[order.timeSlotId];
};

export const selectCurrencySettings = (state, { variant }) =>
  state.api.settings.displaySettings[variant].currency;

/* Settings selectors */
export const getApiSettings = (state) => state.api.settings;
export const getApiNutritionalSettings = (state) => state.api.settings.nutritionalSettings;
export const getDefaultDeliveryTimeInterval = (state) =>
  state.api.settings.defaultDeliveryTimeInterval || 30;
export const getIsLoyaltyEnabled = (state) => state.api.settings?.isLoyaltyIntegrationEnabled;
export const getIsLoyaltyTabsHidden = (state) => state.api.settings?.isLoyaltyTabsHidden;
export const getIsRecaptchaEnabled = (state) => state.api.settings?.isRecaptchaEnabled;
export const getIsOrderTaxLineHidden = (state) => state.api.settings?.isOrderTaxLineHidden;
export const getLoyaltyBrandPrefix = (state) => state.api.settings?.loyaltyBrandPrefix;
export const getGiftCardUrl = (state) => state.api.settings?.giftCardUrl;
export const getOrderTakeOutWithBagSurcharge = (state) =>
  state.api.settings?.orderTakeOutWithBagSurcharge ?? '0.00';
export const getGuestLimit = (state) => ({
  guestOrderAmountLimit: state.api.settings?.orderGuestAmountLimit,
  guestOrderQuantityLimit: state.api.settings?.orderGuestQuantityLimit,
});
export const getIsGuestLoginEnabled = (state) => {
  const allowLoginlessOrdersFromList = state.api.settings?.allowLoginlessOrdersFrom;
  if (allowLoginlessOrdersFromList) {
    // WEB is 1
    return allowLoginlessOrdersFromList.includes(1);
  }
  return false;
};

export const getRequestStationUrl = (state) => state.api.settings?.requestStationUrl;
export const getGroupOrderingUrl = (state) => state.api.settings?.groupOrderingUrl;
export const getDefaultStationTimeInterval = (state) =>
  state.api.settings?.defaultStationTimeInterval ?? 60;
export const getIsClimatePositiveHidden = (state) => state.api.settings?.isClimatePositiveHidden;
export const getIsNutritionalInformationHidden = (state) =>
  state.api.settings?.isNutritionalInformationHidden;

export const getMenuItems = (state) => state.api.menuItems;
export const getMenuItemIngredientServings = (state) => state.api.menuItemIngredientServings;
export const getApiStatus = (state) => state.api.status;
export const getUserOrders = (state) => state.api.userOrders;
export const getUserOrderIds = (state) => state.api.userOrderIds;
export const getApiError = (state, endpoint) =>
  state.api.errors.find((error) => error.endpointName === endpoint);
export const getLoyaltyStatus = (state) => state.loyalty.status;
export const getLoyaltyError = (state, endpoint) =>
  state.loyalty.errors.find((error) => error.endpointName === endpoint);

export const getMenuCategories = (state) => state.api.menuCategories;

export const getHighlightedIngredientServings = (state) =>
  state.orderItem.highlightedIngredientServings;

export const getGglocationStoreBusy = (state, gglocationId) =>
  state.api.storeBusyDetails[gglocationId];

export const getUpsellCategories = (state) => state.api.upsellCategories;

/* Landing Page selectors */
export const getLandingPage = (state) => state.landingPage;
export const getLandingPageHistory = (state) => state.order.landingPageHistory;

export const getCurrentLandingPageSlug = (state) =>
  state.order.landingPageHistory[state.order.landingPageHistory.length - 1];

export const getLandingPageDisabledGglocationTypes = (state) =>
  state.landingPage.disabledGglocationTypes;

export const getLandingPagePartners = (state) => state.landingPage.associatedPartners;

export const getLandingPageDisabledDiningChoices = (state) =>
  state.landingPage.disabledDiningChoices;

export const getIsLandingPageOpen = (state) => state.page.isLandingPageOpen;

export const getIsLandingPageOrder = (state) =>
  orderUtils.isLandingPageOrder({ landingPageHistory: state.order.landingPageHistory }) ||
  getIsLandingPageOpen(state);

export const getLandingPageStyles = (state) => state.landingPage.styling;

export const getLandingPageBannerUrl = (state) =>
  state.landingPage.images.banner && state.landingPage.images.banner.url;

/* Nutritional Selectors */
export const selectIngredientNutrientAmounts = (state, ingredientId) => {
  if (ingredientId) {
    return state.api.ingredientNutrientAmounts[ingredientId];
  }
  return state.orderItem.nutrients;
};

export const getActiveNutritionalGroup = (state) => state.orderItem.activeNutritionalGroupId;
export const getFavMenuChangedId = (state) => state.orderItem.favMenuChangedId;

export const getApiPageInfo = (state) => state.api.pageInfo;
