import React from 'react';
import { MenuItem, Select } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { sortDietTags } from '../../../utils/foodUtils';

import tagShape from '../../../shapes/tagShape';

import DietModalButton from '../../DietModal/DietModalButton';
import FontAwesome from '../../FontAwesome';
import { ReactComponent as IconBarsFilter } from '../icon-bars-filter.svg';

function DietSelectIconComponent(props) {
  return <FontAwesome name="caret-down" {...props} />;
}

function DietSelectButton({
  isSelectOpen = false,
  selectedIds = [],
  items = [],
  label = 'Dietary preferences',
  onOpen,
  onChange,
  onItemClick,
  onSave,
}) {
  return (
    <Select
      className={classNames('DietSelectButton', { isSelectOpen })}
      multiple
      value={selectedIds}
      onChange={onChange}
      displayEmpty
      notched={false}
      label="Label"
      IconComponent={DietSelectIconComponent}
      renderValue={() => (
        <div className="DietButtonInputLabel">
          <IconBarsFilter />
          <div>{label}</div>
        </div>
      )}
      onOpen={onOpen}
      onClose={onSave}
      MenuProps={{
        PaperProps: {
          sx: {
            marginTop: '8px',
            borderRadius: '16px',
            boxShadow: '2px 2px 4px 2px rgba(0, 0, 0, 0.1)',
            minWidth: '180px !important',
          },
        },
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: { vertical: 'top', horizontal: 'left' },
      }}
    >
      {items.sort(sortDietTags).map((tag) => (
        <MenuItem
          key={tag.id}
          value={tag.id}
          sx={{
            padding: 0,
            '&.Mui-selected, &.Mui-focusVisible': { backgroundColor: 'transparent' },
            '&:hover': { backgroundColor: '#F3F6F4' },
          }}
        >
          <DietModalButton
            id={tag.id}
            name={tag.name}
            selected={selectedIds.indexOf(tag.id) > -1}
            onClick={onItemClick}
          />
        </MenuItem>
      ))}
    </Select>
  );
}

DietSelectButton.propTypes = {
  isSelectOpen: PropTypes.bool.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  items: PropTypes.arrayOf(tagShape).isRequired,
  label: PropTypes.string.isRequired,
  onOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DietSelectButton;
