import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { getSearchParam } from '../../../utils/historyUtils';

import { landingPageTopBarStyles } from '../../../shapes/landingPageStyleShapes';

import Button from '../../Button';

import AccountIcon from '../../../images/icon-account.svg';
import AccountIconLight from '../../../images/icon-account-light.svg';

import './UserButton.css';

function UserButton(props) {
  const { loggedIn, onSignInClick, landingPageStyles, isLandingPageOpen } = props;

  const [isHover, setIsHover] = useState(false);

  const { pathname, search } = useLocation();

  const isOnAccountPage = /account/.test(pathname);
  const isCartOpen = getSearchParam(search, 'showCart') === 'true';

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  if (loggedIn) {
    return (
      <div className="UserButton">
        <Button className="AccountButton" link="/account/edit">
          <img
            src={isOnAccountPage && !isCartOpen ? AccountIconLight : AccountIcon}
            alt="Account"
          />
        </Button>
      </div>
    );
  }

  return (
    <div
      className={classnames('UserButton', { LandingPage: isLandingPageOpen })}
      style={{
        ...landingPageStyles,
        ...(isHover && landingPageStyles && landingPageStyles['&:hover']),
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button onClick={onSignInClick} className="SignInButton">
        <Typography variant="body1" fontWeight="600">
          Log in
        </Typography>
      </Button>
    </div>
  );
}

UserButton.propTypes = {
  loggedIn: PropTypes.bool,
  isLandingPageOpen: PropTypes.bool.isRequired,
  onSignInClick: PropTypes.func.isRequired,
  landingPageStyles: landingPageTopBarStyles.isRequired,
};

UserButton.defaultProps = {
  loggedIn: false,
};

export default UserButton;
