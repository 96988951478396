import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import FontAwesome from '../../FontAwesome';

import './PaymentMethod.css';

function PaymentMethod({ label, brand, isDefault, onPaymentMethodRemoveClick, onSetDefaultClick }) {
  return (
    <li className="PaymentMethod">
      <ul>
        <li className="paymentMethodLabel">
          <FontAwesome name="credit-card" />
          &ensp;
          {brand}
          &ensp;
          {label}
        </li>
        {isDefault && <li className="defaultText">Default</li>}
        {!isDefault && (
          <li className="setDefaultPaymentMethodButton">
            <Button onClick={onSetDefaultClick}>Set as default</Button>
          </li>
        )}
        <li className="trashButton">
          <Button fa="trash" onClick={onPaymentMethodRemoveClick} />
        </li>
      </ul>
    </li>
  );
}

PaymentMethod.propTypes = {
  label: PropTypes.string.isRequired,
  brand: PropTypes.oneOf(['MasterCard', 'Visa', 'Amex']).isRequired,
  isDefault: PropTypes.bool,
  onPaymentMethodRemoveClick: PropTypes.func.isRequired,
  onSetDefaultClick: PropTypes.func.isRequired,
};

PaymentMethod.defaultProps = {
  isDefault: false,
};

export default PaymentMethod;
