import { put } from 'redux-saga/effects';

import statusEnum from '../enums/statusEnum';

function* dispatchCallSuccess({ requestActionType, response }) {
  yield put({
    type: `${requestActionType}_SUCCESS`,
    response,
  });
}

function* dispatchCallError({ requestActionType, error }) {
  yield put({
    type: `${requestActionType}_ERROR`,
    error,
  });
}

export function* dispatchCallAction({ requestActionType, result }) {
  const { response, error } = result;

  if (response) {
    yield dispatchCallSuccess({ requestActionType, response });
  } else if (error) {
    yield dispatchCallError({ requestActionType, error });
  }
}

export function* dispatchCallResult({ requestActionType, result }) {
  yield dispatchCallAction({ requestActionType, result });
}

/**
 * Check endpoint is needed to be fetched through status.
 * Will performed fetching if the endpoint is not being fetched before or failed.
 *
 * @param {statusEnum} status Endpoint status
 * @returns {Boolean} True if fetch needed else false
 */
export const isFetchNeeded = (status) => [statusEnum.INITIAL, statusEnum.ERROR].includes(status);
