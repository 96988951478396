import PropTypes from 'prop-types';

const ingredientShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  desc: PropTypes.string,
  ingredientCategory: PropTypes.number,
  ingredientSubcategory: PropTypes.number,
  baseType: PropTypes.number,
  image: PropTypes.number,
  servingWeight: PropTypes.number,
  isComposite: PropTypes.bool,
  compositeIngredients: PropTypes.string,
  nutrientServingWeight: PropTypes.number,
  nutrients: PropTypes.arrayOf(
    PropTypes.shape({
      nutrient: PropTypes.number,
      amount: PropTypes.string,
    }),
  ).isRequired,
});

export default ingredientShape;
