import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as scrollspyActions from '../../actions/scrollspy';
import * as foodUtils from '../../utils/foodUtils';
import * as scrollUtils from '../../utils/scrollUtils';

import ingredientCategoryShape from '../../shapes/ingredientCategoryShape';
import ingredientShape from '../../shapes/ingredientShape';

import IngredientCategories from '../../components/IngredientCategories';

import { INGREDIENT_BASES, MENU_DETAILS_ADD_SCROLL_DELAY } from '../../settings';

class IngredientCategoriesContainer extends Component {
  static propTypes = {
    menuItemId: PropTypes.number.isRequired,
    sectionId: PropTypes.number.isRequired,
    categoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    type: PropTypes.oneOf(['cyo', 'premade']),
    excludeNullBase: PropTypes.bool,
    saveAutomatically: PropTypes.bool,
    showDietTags: PropTypes.bool,
    onLimitExhausted: PropTypes.func.isRequired,

    highlightedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
    setActiveIngredientCategoryId: PropTypes.func.isRequired,
  };

  static defaultProps = {
    type: 'premade',
    excludeNullBase: false,
    saveAutomatically: false,
    showDietTags: true,
  };

  constructor(props) {
    super(props);

    const { setActiveIngredientCategoryId } = props;

    setActiveIngredientCategoryId({ ingredientCategoryId: undefined });
  }

  get ingredientCategoriesList() {
    const { categoryIds, ingredientCategories } = this.props;

    return categoryIds
      .map((categoryId) => ingredientCategories[categoryId])
      .sort((a, b) => a.order - b.order);
  }

  handleIngredientCategoryEnter = (ingredientCategoryId) => {
    const { setActiveIngredientCategoryId } = this.props;

    setActiveIngredientCategoryId({ ingredientCategoryId });
  };

  handleIngredientCategoryLeave = (categoryId, { previousPosition, currentPosition }) => {
    const { categoryIds, setActiveIngredientCategoryId } = this.props;

    if (previousPosition === 'inside') {
      if (currentPosition === 'below') {
        const previousCategoryId = [...categoryIds]
          .reverse()
          .find((testedCategoryId) => testedCategoryId < categoryId);

        if (previousCategoryId) {
          setActiveIngredientCategoryId({ ingredientCategoryId: previousCategoryId });
        }
      } else if (currentPosition === 'above') {
        const nextCategoryId = [...categoryIds].find(
          (testedCategoryId) => testedCategoryId > categoryId,
        );

        if (nextCategoryId) {
          setActiveIngredientCategoryId({ ingredientCategoryId: nextCategoryId });
        }
      }
    }
  };

  handleLimitReached = ({ type, id }) => {
    const { categoryIds } = this.props;

    if (type === 'category') {
      const nextCategoryId = categoryIds.find((categoryId) => categoryId > id);
      if (nextCategoryId) {
        setTimeout(
          () => scrollUtils.scrollToIngredientCategoryId(nextCategoryId),
          MENU_DETAILS_ADD_SCROLL_DELAY,
        );
      }
    }
  };

  getCategoryIngredientServingsCount = (categoryId) => {
    const { highlightedIngredientServings, ingredients, ingredientCategories } = this.props;

    const baseIngredientIds = Object.keys(INGREDIENT_BASES);
    const categoryIngredientServings = foodUtils.filterIngredientServings(
      ingredients,
      highlightedIngredientServings,
      { selectedCategoryIds: [categoryId] },
      { ingredientCategories },
    );

    return Object.keys(categoryIngredientServings).filter((id) => !baseIngredientIds.includes(id))
      .length;
  };

  render() {
    const {
      menuItemId,
      sectionId,
      type,
      excludeNullBase,
      saveAutomatically,
      showDietTags,
      onLimitExhausted,
    } = this.props;

    return (
      <IngredientCategories
        menuItemId={menuItemId}
        sectionId={sectionId}
        ingredientCategoriesList={this.ingredientCategoriesList}
        excludeNullBase={excludeNullBase}
        type={type}
        saveAutomatically={saveAutomatically}
        showDietTags={showDietTags}
        getCategoryIngredientServingsCount={this.getCategoryIngredientServingsCount}
        onIngredientCategoryEnter={this.handleIngredientCategoryEnter}
        onIngredientCategoryLeave={this.handleIngredientCategoryLeave}
        onLimitExhausted={onLimitExhausted}
        onLimitReached={this.handleLimitReached}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  highlightedIngredientServings: state.orderItem.highlightedIngredientServings,
  ingredients: state.api.ingredients,
  ingredientCategories: state.api.ingredientCategories,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setActiveIngredientCategoryId: scrollspyActions.setActiveIngredientCategoryId,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(IngredientCategoriesContainer);
