import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';

import './Checkbox.css';

function Checkbox({ isChecked, label, onChange, children, ...otherProps }) {
  const id = otherProps.id || uuid4();
  const name = otherProps.name || uuid4();

  return (
    <div className="Checkbox">
      <input type="checkbox" id={id} name={name} checked={isChecked} onClick={onChange} />
      <label htmlFor={id}>
        {label}
        {children}
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  isChecked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.element,
};

Checkbox.defaultProps = {
  id: null,
  name: null,
  isChecked: false,
  children: null,
};

export default Checkbox;
