import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../../actions/order';
import gglocationTypeEnum from '../../../enums/gglocationTypeEnum';
import * as gglocationUtils from '../../../utils/gglocationUtils';
import * as orderUtils from '../../../utils/orderUtils';

import geolocationShape from '../../../shapes/geolocationShape';
import gglocationShape from '../../../shapes/gglocationShape';
import timeSlotShape from '../../../shapes/timeSlotShape';

import GglocationsList from '../../../components/GglocationLocator/GglocationsList';

class GglocationsListContainer extends Component {
  static propTypes = {
    menuItemId: PropTypes.string,
    isStationFlow: PropTypes.bool,
    hideRequestStationUrl: PropTypes.bool,
    highlightedGglocationId: PropTypes.string,
    gglocations: PropTypes.objectOf(gglocationShape).isRequired,
    diningChoice: PropTypes.string.isRequired,
    isLandingPageOrder: PropTypes.bool.isRequired,
    landingPageDisabledGglocationTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
    landingPagePartners: PropTypes.arrayOf(PropTypes.number).isRequired,
    geolocation: geolocationShape.isRequired,
    timeSlots: PropTypes.objectOf(timeSlotShape).isRequired,
    menuGroups: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
    gglocationMenuGroups: PropTypes.objectOf(PropTypes.number).isRequired,
    updateGglocation: PropTypes.func.isRequired,
  };

  static defaultProps = {
    menuItemId: undefined,
    isStationFlow: false,
    hideRequestStationUrl: false,
    highlightedGglocationId: null,
  };

  get gglocations() {
    const {
      gglocations,
      timeSlots,
      diningChoice,
      isLandingPageOrder,
      landingPagePartners,
      landingPageDisabledGglocationTypes,
    } = this.props;

    return gglocationUtils.filterAvailableGglocations({
      gglocations,
      timeSlots,
      isLandingPageOrder,
      landingPageDisabledGglocationTypes,
      landingPagePartners,
      diningChoice,
    });
  }

  get sortedGglocations() {
    const { menuItemId, gglocationMenuGroups, menuGroups, diningChoice, isStationFlow } =
      this.props;

    return this.gglocations
      .sort((a, b) => this.compareGglocations(a, b))
      .filter((gglocation) => {
        if (!menuItemId) return true;

        const gglocationMenuGroupId = gglocationMenuGroups[gglocation.id];
        const gglocationMenuGroup = menuGroups[gglocationMenuGroupId];

        return gglocationMenuGroup.includes(menuItemId);
      })
      .filter((gglocation) =>
        isStationFlow
          ? gglocation.gglocationType === gglocationTypeEnum.PARTNER
          : gglocation.gglocationType === gglocationTypeEnum.STORE,
      )
      .filter((gglocation) => gglocation.canPlaceOrderEver({ diningChoice }));
  }

  compareGglocations = (gglocationA, gglocationB) => {
    const { diningChoice, timeSlots, geolocation } = this.props;

    return gglocationUtils.compareGglocations({
      gglocationA,
      gglocationB,
      timeSlots,
      geolocation,
      diningChoice,
    });
  };

  handleGglocationClick = (gglocation) => {
    const { updateGglocation, timeSlots } = this.props;

    updateGglocation({ gglocation, timeSlots });
  };

  render() {
    const { highlightedGglocationId, menuItemId, hideRequestStationUrl } = this.props;

    return (
      <GglocationsList
        gglocations={this.sortedGglocations}
        highlightedGglocationId={highlightedGglocationId}
        menuItemId={menuItemId}
        hideRequestStationUrl={hideRequestStationUrl}
        onGglocationClick={this.handleGglocationClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  highlightedGglocationId: state.order.gglocationId,
  gglocations: state.api.gglocations,
  diningChoice: state.order.diningChoice,
  isLandingPageOrder: orderUtils.isLandingPageOrder({
    landingPageHistory: state.order.landingPageHistory,
  }),
  landingPageDisabledGglocationTypes: state.landingPage.disabledGglocationTypes,
  landingPagePartners: state.landingPage.associatedPartners,
  timeSlots: state.api.timeSlots,
  geolocation: state.geolocation,
  menuGroups: state.api.menuGroups,
  gglocationMenuGroups: state.api.gglocationMenuGroups,
  isStationFlow: state.order.isStationFlow,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateGglocation: orderActions.updateGglocation,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GglocationsListContainer);
