import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import * as pageActions from '../../../actions/page';
import * as selectors from '../../../sagas/selectors';

import Addresses from '../../../containers/Account/Addresses';
import Button from '../../Button';
import Currency from '../../Currency';

import { ReactComponent as IconLocationAim } from '../../../images/icon-location-aim.svg';

import './Delivery.css';

function Delivery({
  customerAddressId,
  minimumOrderTotal,
  hasAddresses,
  loggedIn,
  onGetLocation,
  onAddressClick,
}) {
  const dispatch = useDispatch();
  const landingPageSlug = useSelector(selectors.getCurrentLandingPageSlug);

  const addAddressLink = {
    pathname: '/addAddress',
    state: {
      nextLink: landingPageSlug && `/site/${landingPageSlug}`,
    },
  };

  const signInLink = {
    pathname: '/signIn',
  };

  const handleCloseDiningChoiceModal = () => dispatch(pageActions.closeDiningChoiceModal());

  return (
    <div className="Delivery">
      <div className="SelectAddress">
        <span className="header">
          <span className="headerText">
            <Typography variant="body2" color="grey.main" fontWeight="600">
              Islandwide delivery to your doorstep*
            </Typography>
          </span>
        </span>
      </div>
      <Typography variant="body2" className="info">
        *min. order of&nbsp;
        <Currency value={minimumOrderTotal} />
        &nbsp;applies
      </Typography>
      {!landingPageSlug && (
        <Button className="btn-primary GetLocationButton" onClick={onGetLocation}>
          <IconLocationAim />
          Get my location
        </Button>
      )}
      {landingPageSlug && hasAddresses && (
        <>
          <Addresses isSelectable redirectOnSelect={false} />
          <Button
            className="btn-primary"
            onClick={!!landingPageSlug && handleCloseDiningChoiceModal}
            disabled={customerAddressId === null}
          >
            {landingPageSlug ? 'Continue' : 'Start Order'}
          </Button>
        </>
      )}
      <Button
        className="btn-secondary"
        link={landingPageSlug && addAddressLink}
        onClick={landingPageSlug ? handleCloseDiningChoiceModal : onAddressClick}
      >
        Enter address manually
      </Button>
      {!loggedIn && (
        <Button className="blue-link" link={signInLink}>
          Sign-in to view your addresses
        </Button>
      )}
    </div>
  );
}

Delivery.propTypes = {
  customerAddressId: PropTypes.number,
  loggedIn: PropTypes.bool.isRequired,
  hasAddresses: PropTypes.bool.isRequired,
  minimumOrderTotal: PropTypes.number.isRequired,
  onGetLocation: PropTypes.func.isRequired,
  onAddressClick: PropTypes.func.isRequired,
};

Delivery.defaultProps = {
  customerAddressId: null,
};

export default Delivery;
