import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderItemActions from '../../actions/orderItem';
import * as foodUtils from '../../utils/foodUtils';
import * as pricingUtils from '../../utils/pricingUtils';

import cyoSectionShape from '../../shapes/cyoSectionShape';
import ingredientCategoryShape from '../../shapes/ingredientCategoryShape';
import ingredientShape from '../../shapes/ingredientShape';
import menuItemShape from '../../shapes/menuItemShape';
import pricingVariationShape from '../../shapes/pricingVariationShape';
import servingNumberVariationShape from '../../shapes/servingNumberVariationShape';
import tagShape from '../../shapes/tagShape';

import PricingVariationsNotification from '../../components/PricingVariationsNotification';

class PricingVariationsNotificationsContainer extends Component {
  static propTypes = {
    menuItem: menuItemShape.isRequired,
    sectionId: PropTypes.number.isRequired,
    saveAutomatically: PropTypes.bool,
    showAll: PropTypes.bool,
    pricingVariations: PropTypes.arrayOf(pricingVariationShape).isRequired,
    servingNumberVariations: PropTypes.arrayOf(servingNumberVariationShape).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
    sections: PropTypes.objectOf(cyoSectionShape).isRequired,
    tags: PropTypes.objectOf(tagShape).isRequired,
    highlightedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    selectedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onAdd: PropTypes.func,
    addIngredientServings: PropTypes.func.isRequired,
    incrementIngredientServing: PropTypes.func.isRequired,
    saveHighlightedIngredientServings: PropTypes.func.isRequired,
  };

  static defaultProps = {
    saveAutomatically: false,
    showAll: true,
    onAdd: () => {},
  };

  handleIngredientAdd(id) {
    const {
      saveAutomatically,
      ingredients,
      servingNumberVariations,
      highlightedIngredientServings,
      onAdd,
      addIngredientServings,
      incrementIngredientServing,
      saveHighlightedIngredientServings,
      menuItem,
    } = this.props;

    const exists = !!highlightedIngredientServings[id];

    if (exists) {
      incrementIngredientServing(id, { store: 'highlighted' });
    } else {
      const defaultIngredientServing = foodUtils.defaultIngredientServing(
        menuItem?.baseType,
        menuItem?.uuid,
        id,
        { ingredients, servingNumberVariations },
      );
      addIngredientServings({ [id]: defaultIngredientServing }, { store: 'highlighted' });
    }

    if (saveAutomatically) {
      saveHighlightedIngredientServings();
    }

    onAdd(id);
  }

  get section() {
    const { sectionId, sections } = this.props;

    return sections[sectionId];
  }

  get pricingVariations() {
    const {
      pricingVariations,
      ingredients,
      ingredientCategories,
      tags,
      selectedTagIds,
      highlightedIngredientServings,
      menuItem,
    } = this.props;

    return pricingVariations.filter((pricingVariation) => {
      /* Match variations based on ingredient id */
      const ingredientId = pricingVariation.ingredient;
      if (ingredientId === null) return false;

      /* Match variations based on menu item */
      if (!pricingUtils.matchMenuItemPricingVariation(pricingVariation, { menuItem })) {
        return false;
      }

      /* Match variations based on section */
      const ingredient = ingredients[ingredientId];
      const ingredientCategory = ingredientCategories[ingredient.ingredientCategory];
      if (ingredientCategory.cyoSection !== this.section.id) return false;

      /* Match variations based on dietary preferences */
      if (!foodUtils.tagIngredientsFilter(ingredient, { selectedTagIds, tags })) return false;

      /* Match variations that can be applied next */
      const ingredientServings = highlightedIngredientServings[ingredientId];
      if (ingredientServings + 1 < pricingVariation.minimumServings) return false;
      if (ingredientServings + 1 > pricingVariation.maximumServings) return false;
      return true;
    });
  }

  render() {
    const { ingredients, showAll } = this.props;

    if (!showAll) {
      const pricingVariation = this.pricingVariations[0];

      if (!pricingVariation) return null;

      return (
        <PricingVariationsNotification
          pricingVariation={pricingVariation}
          ingredient={ingredients[pricingVariation.ingredient]}
          onAddClick={() => this.handleIngredientAdd(pricingVariation.ingredient)}
        />
      );
    }

    return (
      <div className="PricingVariationsNotifications">
        {this.pricingVariations.map((pricingVariation) => (
          <PricingVariationsNotification
            key={pricingVariation.ingredient}
            pricingVariation={pricingVariation}
            ingredient={ingredients[pricingVariation.ingredient]}
            onAddClick={() => this.handleIngredientAdd(pricingVariation.ingredient)}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  highlightedIngredientServings: state.orderItem.highlightedIngredientServings,
  selectedTagIds: state.food.selectedTagIds,
  tags: state.api.tags,
  pricingVariations: state.api.pricingVariations,
  menuItem: state.api.menuItems[ownProps.menuItemId],
  servingNumberVariations: state.api.servingNumberVariations,
  ingredients: state.api.ingredients,
  ingredientCategories: state.api.ingredientCategories,
  sections: state.api.cyoSections,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addIngredientServings: orderItemActions.addIngredientServings,
      incrementIngredientServing: orderItemActions.incrementIngredientServing,
      saveHighlightedIngredientServings: orderItemActions.saveHighlightedIngredientServings,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PricingVariationsNotificationsContainer);
