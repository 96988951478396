import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import menuCategoryShape from '../../../shapes/menuCategoryShape';
import menuItemShape from '../../../shapes/menuItemShape';

import FavoriteMenuSection from '../../../containers/FavoriteMenuSection';
import MenuCategory from '../../../containers/Menu/MenuCategory';
import DietButton from '../../DietButton';
import GiftCardCategory from '../GiftCardCategory/GiftCardCategory';

function MenuContent({ menuCategories, menuItems, selectedCategory, onMenuCategoryInit }) {
  const menuCategoryElement = (menuCategory) => (
    <MenuCategory
      key={menuCategory.id}
      navigationKey={`menu-navigate-c_${menuCategory.id}`}
      name={menuCategory.name}
      description={menuCategory.desc}
      menuItems={menuItems.filter((menuItem) => menuItem.menuCategory === menuCategory.id)}
      onInit={(sectionElement) => onMenuCategoryInit(menuCategory.id, sectionElement)}
    />
  );

  const giftCardUrl = useSelector((state) => state.api.settings.giftCardUrl);

  useEffect(() => {
    if (selectedCategory) {
      selectedCategory.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedCategory]);

  return (
    <div id="MenuContentWrapper" className="MenuContentWrapper">
      <DietButton type="button" />
      <FavoriteMenuSection title="Favourites" />
      {menuCategories.length > 0 && menuCategories.map(menuCategoryElement)}
      {giftCardUrl && (
        <GiftCardCategory
          key="menu-category-gifts"
          navigationKey="menu-navigate-gifts"
          onInit={(sectionElement) => onMenuCategoryInit('menu-category-gifts', sectionElement)}
          giftCardUrl={giftCardUrl}
        />
      )}
    </div>
  );
}

MenuContent.propTypes = {
  menuCategories: PropTypes.objectOf(menuCategoryShape).isRequired,
  menuItems: PropTypes.objectOf(menuItemShape).isRequired,
  onMenuCategoryInit: PropTypes.func.isRequired,
  selectedCategory: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

MenuContent.defaultProps = {
  selectedCategory: null,
};

export default MenuContent;
