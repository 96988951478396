// eslint-disable-next-line no-redeclare
/* global google */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as gglocationsActions from '../../actions/gglocations';
import statusEnum from '../../enums/statusEnum';

import geolocationShape from '../../shapes/geolocationShape';
import gglocationShape from '../../shapes/gglocationShape';

import Map from '../../components/GglocationLocator/Map';

import * as settings from '../../settings';

class MapContainer extends Component {
  static propTypes = {
    diningChoice: PropTypes.string.isRequired,
    gglocations: PropTypes.objectOf(gglocationShape).isRequired,
    highlightGglocation: PropTypes.func.isRequired,
    geolocation: geolocationShape.isRequired,
    className: PropTypes.string,
    highlightedGglocationId: PropTypes.string,
    images: PropTypes.objectOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    className: null,
    highlightedGglocationId: null,
  };

  onMapLoad = (map) => {
    if (map) {
      if (!this.isGeolocationSuccessful || this.isFarToAnyGglocation) {
        const bounds = new google.maps.LatLngBounds();
        this.gglocationsArray.map((gglocation) =>
          bounds.extend(
            new google.maps.LatLng(gglocation.coordinates.lat, gglocation.coordinates.lng),
          ),
        );
        map.fitBounds(bounds);

        if (this.defaultCenter && 'lat' in this.defaultCenter && 'lng' in this.defaultCenter) {
          map.panTo(this.defaultCenter);
        }
      }
    }
  };

  get gglocationsArray() {
    const { diningChoice, gglocations } = this.props;

    return Object.values(gglocations).filter((gglocation) =>
      gglocation.canPlaceOrderEver({ diningChoice }),
    );
  }

  get closeGglocations() {
    const closeGglocations = this.gglocationsArray.filter(
      (gglocation) => this.determineDistanceToUser(gglocation) < 10000,
    );

    return closeGglocations;
  }

  get isFarToAnyGglocation() {
    return this.closeGglocations.length === 0;
  }

  get isGeolocationSuccessful() {
    const { geolocation } = this.props;
    return geolocation.status === statusEnum.SUCCESS;
  }

  get isGeolocationOver() {
    const { geolocation } = this.props;
    return geolocation.status > statusEnum.REQUESTED;
  }

  get defaultCenter() {
    const { geolocation, gglocations, highlightedGglocationId } = this.props;
    const highlightedGglocation = gglocations[highlightedGglocationId];

    if (highlightedGglocation) return highlightedGglocation.coordinates;
    if (geolocation?.coordinates) return geolocation.coordinates;
    return settings.DEFAULT_COORDINATES;
  }

  get gglocationImage() {
    const { gglocations, highlightedGglocationId, images } = this.props;
    const highlightedGglocation = gglocations[highlightedGglocationId];

    if (!highlightedGglocation) return null;

    return images?.gglocationsImages[highlightedGglocation?.images?.banner]?.file?.original;
  }

  determineDistanceToUser = (gglocation) => {
    const { geolocation } = this.props;
    const { coordinates } = geolocation;

    return gglocation.distanceToLocation({ coordinates });
  };

  handleMarkerClick = (id) => {
    const { highlightGglocation } = this.props;

    highlightGglocation({ id });
  };

  render() {
    const { className, highlightedGglocationId } = this.props;

    if (this.isGeolocationOver) {
      return (
        <Map
          gglocations={this.gglocationsArray}
          highlightedGglocationId={highlightedGglocationId}
          gglocationImage={this.gglocationImage}
          defaultCenter={this.defaultCenter}
          className={className}
          onMapLoad={this.onMapLoad}
          onMarkerClick={this.handleMarkerClick}
        />
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => ({
  diningChoice: state.order.diningChoice,
  gglocations: state.api.gglocations,
  geolocation: state.geolocation,
  highlightedGglocationId: state.order.gglocationId,
  images: state.api.imageData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      highlightGglocation: gglocationsActions.highlightGglocation,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
