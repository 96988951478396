import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as userActions from '../../../actions/user';

import routerShape from '../../../shapes/routerShape';

import Verify from '../../../components/SignIn/Verify';
import withRouter from '../../WithRouter';

import { oauthService } from '../../../services/api';
import { PIN_DIGITS } from '../../../settings';

class VerifyContainer extends Component {
  static propTypes = {
    pendingPhone: PropTypes.string.isRequired,
    isResendModalShown: PropTypes.bool.isRequired,
    status: PropTypes.number.isRequired,
    errors: PropTypes.arrayOf(PropTypes.object).isRequired,
    verifyPin: PropTypes.func.isRequired,
    resendPin: PropTypes.func.isRequired,
    hideResendModal: PropTypes.func.isRequired,

    router: routerShape.isRequired,
  };

  constructor(props) {
    const { pendingPhone, router } = props;

    super(props);

    this.interval = 0;
    this.focusedInputId = 0;
    this.inputs = Array(PIN_DIGITS).fill(null);

    this.state = {
      timer: null,
      error: null,
      pin: '',
    };

    if (pendingPhone === null) {
      router.replace({ pathname: '/signIn/phone' });
    }
  }

  componentDidUpdate(prevProps) {
    const { errors } = this.props;

    this.handleErrors(prevProps.errors, errors);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handlePinChange = (id, event) => {
    const { pin } = this.state;
    const { verifyPin } = this.props;
    const digit = event.target.value;
    const pinArray = pin.split('');

    if (digit.length > 0 && /^\d+$/.test(digit) === false) {
      return null;
    }

    if (digit.length === 0) {
      pinArray.splice(id, id + 1);
      this.focusPreviousInput(id);
    } else {
      pinArray.splice(id, digit.length, digit);
      this.focusNextInput(id);
    }

    const newPin = pinArray.join('');
    this.setState({ pin: newPin });

    if (newPin.length === PIN_DIGITS) {
      verifyPin({ pin: newPin });
    }

    return true;
  };

  focusPreviousInput = (id) => {
    const focusedInputId = Math.max(id - 1, 0);
    this.inputs[focusedInputId].focus();
    this.focusedInputId = focusedInputId;
  };

  focusNextInput = (id) => {
    const focusedInputId = Math.min(id + 1, PIN_DIGITS - 1);
    this.inputs[focusedInputId].focus();
    this.focusedInputId = focusedInputId;
  };

  handleInputFocus = (id) => {
    const { pin } = this.state;

    let focusedInputId = id;
    if (pin.length < id + 1) {
      focusedInputId = Math.max(pin.length - 1, 0);
    }
    this.inputs[focusedInputId].focus();
    this.focusedInputId = focusedInputId;
  };

  handleInputInit = (id, input) => {
    if (input === null) return null;
    this.inputs[id] = input;
    if (id === this.focusedInputId) {
      input.focus();
    }

    return true;
  };

  handlePinResend = () => {
    const { resendPin, pendingPhone } = this.props;

    const stateOptions = {
      isResendModalShown: true,
    };
    resendPin({ stateOptions, pendingPhone });
  };

  handleResendModalHide = () => {
    const { hideResendModal } = this.props;

    hideResendModal();
  };

  handleTick = () => {
    const { timer } = this.state;
    const newTimer = timer - 1;

    this.setState({
      timer: newTimer,
    });
    if (newTimer === 0) {
      clearInterval(this.interval);
      this.setState({
        error: null,
      });
    }
  };

  handleErrors = (previousErrors, newErrors) => {
    if (newErrors.length === 0 || previousErrors.length === newErrors.length) return null;
    if (newErrors[0].error.message.countDown) {
      this.setState({
        error: newErrors[0].error.message.message,
        timer: newErrors[0].error.message.countDown,
      });
      this.interval = setInterval(this.handleTick, 1000);
    } else {
      this.setState({
        error: newErrors[0].error.message[0],
        timer: 0,
      });
    }

    return null;
  };

  render() {
    const { pendingPhone, status, isResendModalShown } = this.props;
    const { error, pin, timer } = this.state;

    return (
      <Verify
        phone={pendingPhone}
        pin={pin}
        error={error}
        timer={timer}
        status={status}
        isResendModalShown={isResendModalShown}
        onPinChange={this.handlePinChange}
        onInputFocus={this.handleInputFocus}
        onInputInit={this.handleInputInit}
        onPinResend={this.handlePinResend}
        onResendModalHide={this.handleResendModalHide}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  pendingPhone: state.user.pendingPhone,
  status: state.user.status.verifyPin,
  isResendModalShown: state.user.isResendModalShown,
  errors: state.user.errors,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      verifyPin: oauthService.verifyPin.requestActionCreator,
      resendPin: oauthService.resendPin.requestActionCreator,
      hideResendModal: userActions.hideResendModal,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyContainer));
