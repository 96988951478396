import React, { Component } from 'react';
import { connect } from 'react-redux';
import { chain } from 'lodash';
import PropTypes, { arrayOf } from 'prop-types';
import { bindActionCreators } from 'redux';

import * as foodActions from '../../actions/food';
import * as orderItemActions from '../../actions/orderItem';
import * as foodUtils from '../../utils/foodUtils';
import { getSectionSettings } from '../../utils/settingsUtils';

import cyoSectionShape from '../../shapes/cyoSectionShape';
import ingredientCategoryShape from '../../shapes/ingredientCategoryShape';
import ingredientNutrientAmountsShape from '../../shapes/ingredientNutrientAmountsShape';
import ingredientShape from '../../shapes/ingredientShape';
import menuItemIngredientBreakdownShape from '../../shapes/menuItemIngredientBreakdownShape';
import menuItemShape from '../../shapes/menuItemShape';
import servingNumberVariationShape from '../../shapes/servingNumberVariationShape';
import { baseTypeSettingsShape, sectionSettingsShape } from '../../shapes/settingsShape';
import tagShape from '../../shapes/tagShape';

import IngredientsPicker from '../../components/IngredientsPicker';

import { BASE_SECTION_ID, INGREDIENT_BASES } from '../../settings';

class IngredientsPickerContainer extends Component {
  static propTypes = {
    className: PropTypes.string,
    menuItemId: PropTypes.number.isRequired,
    sectionId: PropTypes.number.isRequired,
    globalIngredientRemovalLimit: PropTypes.number.isRequired,
    categoryIds: PropTypes.arrayOf(PropTypes.number),
    excludeNullBase: PropTypes.bool,
    size: PropTypes.oneOf(['Big', 'Medium', 'Small', 'ExtraSmall', 'Minimal']),
    type: PropTypes.oneOf(['premade', 'cyo']),
    saveAutomatically: PropTypes.bool,
    onLimitExhausted: PropTypes.func,
    onLimitReached: PropTypes.func,
    isBaseSectionSelectable: PropTypes.bool.isRequired,

    selectedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    ingredientBreakdown: PropTypes.arrayOf(menuItemIngredientBreakdownShape).isRequired,
    defaultIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    highlightedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
    ingredientNutrientAmounts: ingredientNutrientAmountsShape.isRequired,
    sections: PropTypes.objectOf(cyoSectionShape).isRequired,
    tags: PropTypes.objectOf(tagShape).isRequired,
    servingNumberVariations: PropTypes.arrayOf(servingNumberVariationShape).isRequired,
    baseTypeSettings: PropTypes.objectOf(baseTypeSettingsShape).isRequired,
    sectionSettings: PropTypes.objectOf(sectionSettingsShape).isRequired,
    gglocationId: PropTypes.string.isRequired,
    gglocationDisabledIngredients: PropTypes.objectOf(arrayOf(PropTypes.number)).isRequired,

    showIngredientModal: PropTypes.func.isRequired,
    addIngredientServings: PropTypes.func.isRequired,
    replaceSectionIngredients: PropTypes.func.isRequired,
    saveHighlightedIngredientServings: PropTypes.func.isRequired,
    removeIngredient: PropTypes.func.isRequired,
    incrementIngredientServing: PropTypes.func.isRequired,
    decrementIngredientServing: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    categoryIds: undefined,
    excludeNullBase: false,
    size: undefined,
    type: 'premade',
    saveAutomatically: false,
    onLimitExhausted: () => {},
    onLimitReached: () => {},
  };

  componentDidUpdate(prevProps) {
    const { highlightedIngredientServings } = this.props;
    this.checkIngredientServingAdded(
      prevProps.highlightedIngredientServings,
      highlightedIngredientServings,
    );
  }

  getCategoryIncludedIngredientServings = (categoryId) => {
    const { ingredients } = this.props;

    return foodUtils.filterIngredientServings(
      ingredients,
      this.includedIngredientServings,
      { selectedCategoryIds: [categoryId] },
      {},
    );
  };

  getCategoryIncludedServings = (categoryId) => {
    const categoryIncludedIngredientServings =
      this.getCategoryIncludedIngredientServings(categoryId);

    return Object.values(categoryIncludedIngredientServings).reduce((a, b) => a + b, 0);
  };

  getBaseCategoryLimit = (categoryId) => {
    const { type } = this.props;

    if (!this.sectionSettings.limit[type][categoryId]) return undefined;

    return this.sectionSettings.limit[type][categoryId].number;
  };

  getCategoryLimit = (categoryId) => {
    const { type } = this.props;

    if (
      this.getBaseCategoryLimit(categoryId) === null ||
      this.getBaseCategoryLimit(categoryId) === undefined
    ) {
      return undefined;
    }

    const addExtraLimit = this.sectionSettings.limit[type][categoryId].extra;
    const extraLimit = addExtraLimit ? this.getCategoryIncludedServings(categoryId) : 0;

    return this.getBaseCategoryLimit(categoryId) + extraLimit;
  };

  get unavailableIngredientIds() {
    const { gglocationId, gglocationDisabledIngredients } = this.props;

    if (!gglocationDisabledIngredients) {
      return [];
    }

    return gglocationDisabledIngredients[gglocationId] || [];
  }

  get menuItem() {
    const { menuItems, menuItemId } = this.props;

    return menuItems[menuItemId];
  }

  get includedIngredientServings() {
    const { defaultIngredientServings, highlightedIngredientServings } = this.props;

    const result = {};
    Object.keys(highlightedIngredientServings).map((ingredientId) => {
      result[ingredientId] = Math.min(
        defaultIngredientServings[ingredientId] || 0,
        highlightedIngredientServings[ingredientId] || 0,
      );
      return ingredientId;
    });

    return result;
  }

  get baseId() {
    return this.menuItem.baseType;
  }

  get sectionSettings() {
    const { sectionId, baseTypeSettings, sectionSettings } = this.props;

    return getSectionSettings({
      baseId: this.baseId,
      sectionId,
      baseTypeSettings,
      sectionSettings,
    });
  }

  get categoryIds() {
    const { categoryIds, type } = this.props;
    return categoryIds || this.sectionSettings.addCategories[type];
  }

  get section() {
    const { sectionId, sections } = this.props;
    return sections[sectionId];
  }

  get sectionIncludedIngredientServings() {
    const { ingredients, ingredientCategories } = this.props;

    return foodUtils.filterIngredientServings(
      ingredients,
      this.includedIngredientServings,
      { selectedSectionIds: [this.section.id] },
      { ingredientCategories },
    );
  }

  get globalRemovedIngredientServings() {
    const { defaultIngredientServings, highlightedIngredientServings } = this.props;

    return foodUtils.getRemovedIngredientServings(
      defaultIngredientServings,
      highlightedIngredientServings,
    );
  }

  get sectionRemovedIngredientServings() {
    const {
      sectionId,
      ingredients,
      ingredientCategories,
      defaultIngredientServings,
      highlightedIngredientServings,
    } = this.props;

    return foodUtils.getSectionRemovedIngredientServings(
      ingredients,
      ingredientCategories,
      sectionId,
      defaultIngredientServings,
      highlightedIngredientServings,
    );
  }

  get globalRemoveLimitExhausted() {
    const { type, globalIngredientRemovalLimit } = this.props;

    if (type === 'cyo') {
      return false;
    }

    return (
      globalIngredientRemovalLimit &&
      this.globalRemovedIngredientServings >= globalIngredientRemovalLimit
    );
  }

  get sectionRemoveLimitExhausted() {
    return (
      this.sectionSettings.sectionIngredientRemoveLimit &&
      this.sectionRemovedIngredientServings >= this.sectionSettings.sectionIngredientRemoveLimit
    );
  }

  get sectionIncludedServings() {
    return Object.values(this.sectionIncludedIngredientServings).reduce((a, b) => a + b, 0);
  }

  get baseSectionLimit() {
    const { type } = this.props;

    if (!this.sectionSettings.limit[type].section) return undefined;
    return this.sectionSettings.limit[type].section.number;
  }

  get sectionLimit() {
    const { type } = this.props;

    if (this.baseSectionLimit === null || this.baseSectionLimit === undefined) return undefined;

    const addExtraLimit = this.sectionSettings.limit[type].section.extra;
    const extraLimit = addExtraLimit ? this.sectionIncludedServings : 0;

    return this.baseSectionLimit + extraLimit;
  }

  get sectionLimitExhausted() {
    const { sectionId, highlightedIngredientServings, ingredients, ingredientCategories } =
      this.props;

    if (this.sectionLimit === undefined || this.sectionLimit === null) return false;

    let highlightedIngredientIds = foodUtils.ingredientIdsArray(highlightedIngredientServings);

    if (sectionId === BASE_SECTION_ID) {
      // Don't include base section ingredient tagged in the menu
      const baseIngredientId = Object.keys(INGREDIENT_BASES).find(
        (baseId) => INGREDIENT_BASES[baseId] === this.baseId,
      );

      if (baseIngredientId != null) {
        highlightedIngredientIds = highlightedIngredientIds.filter(
          (ingredientId) => ingredientId !== baseIngredientId,
        );
      }
    }

    const query = {
      selectedIngredientIds: highlightedIngredientIds,
      selectedSectionIds: [sectionId],
    };
    const food = { ingredientCategories };

    const filteredIngredients = foodUtils.filterIngredients(ingredients, query, food);

    const totalIngredientsNumber = filteredIngredients
      .map((ingredient) => highlightedIngredientServings[ingredient.id])
      .map(Math.ceil)
      .reduce((totalNumber, ingredientServing) => totalNumber + ingredientServing, 0);

    return totalIngredientsNumber >= this.sectionLimit;
  }

  get size() {
    const { size } = this.props;

    return size || this.sectionSettings.ingredientDisplaySize;
  }

  get ingredients() {
    const {
      excludeNullBase,
      sectionId,
      type,
      selectedTagIds,
      ingredients,
      ingredientCategories,
      tags,
      isBaseSectionSelectable,
    } = this.props;

    const query = {
      baseId: this.baseId,
      selectedCategoryIds: this.categoryIds,
      selectedTagIds,
      includeNullBase:
        !isBaseSectionSelectable && type !== 'cyo'
          ? !(sectionId === BASE_SECTION_ID)
          : !excludeNullBase,
      includeOnlyCyo: type === 'cyo',
    };
    const food = { ingredientCategories, tags };
    return foodUtils.filterIngredients(ingredients, query, food);
  }

  get ingredientPrices() {
    const { ingredientBreakdown } = this.props;

    return chain(ingredientBreakdown)
      .keyBy('ingredient')
      .mapValues((item) => parseFloat(item.price))
      .value();
  }

  get isPriceDisplayed() {
    return this.sectionSettings.isPriceDisplayed;
  }

  get bulkQuantity() {
    return this.menuItem.bulkQuantity;
  }

  isDefaultIngredient = (ingredientId) => {
    const { defaultIngredientServings } = this.props;

    return Object.keys(defaultIngredientServings).map(Number).includes(ingredientId);
  };

  limitsExhausted = (ingredientId) => {
    const { type, ingredients, defaultIngredientServings, highlightedIngredientServings } =
      this.props;

    const ingredient = ingredients[ingredientId];
    const categoryId = ingredient.ingredientCategory;

    const defaultServings = defaultIngredientServings[ingredientId] || 0;
    const highlightedServings = highlightedIngredientServings[ingredientId] || 0;

    if (this.sectionLimitExhausted) {
      if (this.sectionSettings.limit[type].section.extra) {
        if (highlightedServings < defaultServings) return false;
      }

      return true;
    }

    if (this.categoryLimitExhausted(categoryId)) {
      if (
        this.sectionSettings.limit[type][categoryId].extra ||
        this.isDefaultIngredient(ingredientId)
      ) {
        if (highlightedServings < defaultServings) return false;
      }

      return true;
    }

    return false;
  };

  categoryLimitExhausted = (categoryId) => {
    const { highlightedIngredientServings, ingredients, ingredientCategories } = this.props;

    const categoryLimit = this.getCategoryLimit(categoryId);

    /* If there is no category limit, we assume that we allow infinite number */
    if (categoryLimit === undefined || categoryLimit === null) return false;

    const highlightedIngredientIds = foodUtils.ingredientIdsArray(highlightedIngredientServings);
    const query = {
      selectedIngredientIds: highlightedIngredientIds,
      selectedCategoryIds: [categoryId],
    };
    const food = { ingredientCategories };

    const filteredIngredients = foodUtils.filterIngredients(ingredients, query, food);
    const totalIngredientsNumber = filteredIngredients
      .map((ingredient) => highlightedIngredientServings[ingredient.id])
      .map(Math.ceil)
      .reduce((totalNumber, ingredientServing) => totalNumber + ingredientServing, 0);

    return totalIngredientsNumber >= categoryLimit;
  };

  restoreBase = () => {
    const { sectionId, addIngredientServings } = this.props;
    if (sectionId === BASE_SECTION_ID) {
      addIngredientServings(foodUtils.addBaseToIngredientServings({}, this.baseId), {
        store: 'highlighted',
      });
    }
  };

  checkIngredientServingAdded = (previousIngredientServings, nextIngredientServings) => {
    Object.keys(nextIngredientServings).map((ingredientId) => {
      const previousServing = previousIngredientServings[ingredientId] || 0;
      const nextServing = nextIngredientServings[ingredientId] || 0;

      if (nextServing > previousServing) {
        this.checkLimitsReached(ingredientId);
      }

      return ingredientId;
    });
  };

  checkLimitsReached = (ingredientId) => {
    const { ingredients, onLimitReached } = this.props;
    const ingredient = ingredients[ingredientId];

    if (this.sectionLimitExhausted) {
      return onLimitReached({ type: 'section', id: this.section.id });
    }

    if (this.categoryLimitExhausted(ingredient.ingredientCategory)) {
      return onLimitReached({ type: 'category', id: ingredient.ingredientCategory });
    }

    return null;
  };

  handleCategoryLimitExhausted = (categoryId) => {
    const { type, sectionId, ingredientCategories, onLimitExhausted } = this.props;

    onLimitExhausted({
      sectionId,
      categoryId,
      name: ingredientCategories[categoryId].name,
      limit: this.getBaseCategoryLimit(categoryId),
      extra: this.sectionSettings.limit[type][categoryId].extra,
    });
  };

  handleGlobalLimitExhausted = (isRemove = false) => {
    const { globalIngredientRemovalLimit, onLimitExhausted } = this.props;

    onLimitExhausted({
      sectionId: undefined,
      categoryId: undefined,
      name: 'ingredients',
      limit: globalIngredientRemovalLimit,
      isRemove,
    });
  };

  handleSectionLimitExhausted = (isRemove = false) => {
    const { type, sectionId, onLimitExhausted } = this.props;

    onLimitExhausted({
      sectionId,
      categoryId: undefined,
      name: this.section.name,
      limit: isRemove ? this.sectionSettings.sectionIngredientRemoveLimit : this.baseSectionLimit,
      extra: this.sectionSettings.limit[type].section?.extra,
      isRemove,
    });
  };

  handleIngredientToggle = (ingredientId) => {
    const { saveAutomatically, saveHighlightedIngredientServings } = this.props;

    if (this.unavailableIngredientIds.includes(ingredientId)) {
      return;
    }

    this.toggleIngredientServings(ingredientId);
    if (saveAutomatically) {
      saveHighlightedIngredientServings();
    }
  };

  toggleIngredientServings = (id) => {
    const {
      sectionId,
      highlightedIngredientServings,
      addIngredientServings,
      removeIngredient,
      replaceSectionIngredients,
      ingredients,
      servingNumberVariations,
    } = this.props;

    const ingredient = ingredients[id];
    const categoryId = ingredient.ingredientCategory;

    const defaultIngredientServing = foodUtils.defaultIngredientServing(
      this.baseId,
      this.menuItem.uuid,
      id,
      { ingredients, servingNumberVariations },
    );

    const exists = !!highlightedIngredientServings[id];
    const swappable = this.sectionLimit === 1;
    const isDefaultIngredient = this.isDefaultIngredient(id);

    if (exists) {
      if (this.globalRemoveLimitExhausted && isDefaultIngredient) {
        return this.handleGlobalLimitExhausted(true);
      }
      /* Allow removal of non-default ingredients only */
      if (this.sectionRemoveLimitExhausted && isDefaultIngredient) {
        return this.handleSectionLimitExhausted(true);
      }
      /* Ignore removeable setting if limit is over 1 */
      if (this.sectionSettings.removeable || this.sectionLimit > 1) {
        removeIngredient(id, { store: 'highlighted' });
      }
      return null;
    }

    /* If it's swappable and no ingredient is in that section, it's not considered removal */
    if (swappable) {
      if (this.globalRemoveLimitExhausted && this.sectionIncludedServings > 0) {
        return this.handleGlobalLimitExhausted(true);
      }
      /* Allow removal of non-default ingredients only */
      if (this.sectionRemoveLimitExhausted && this.sectionIncludedServings > 0) {
        return this.handleSectionLimitExhausted(true);
      }
      replaceSectionIngredients(
        sectionId,
        { [id]: defaultIngredientServing },
        { store: 'highlighted' },
      );
      this.restoreBase();
      return null;
    }

    if (this.limitsExhausted(id)) {
      if (this.sectionLimitExhausted) {
        return this.handleSectionLimitExhausted();
      }

      if (this.categoryLimitExhausted(categoryId)) {
        return this.handleCategoryLimitExhausted(categoryId);
      }
    }

    addIngredientServings({ [id]: defaultIngredientServing }, { store: 'highlighted' });
    return null;
  };

  handleIngredientIncrement = (ingredientId) => {
    const {
      saveAutomatically,
      ingredients,
      incrementIngredientServing,
      saveHighlightedIngredientServings,
    } = this.props;
    const categoryId = ingredients[ingredientId].ingredientCategory;

    if (this.limitsExhausted(ingredientId)) {
      if (this.sectionLimitExhausted) {
        return this.handleSectionLimitExhausted();
      }

      if (this.categoryLimitExhausted(categoryId)) {
        return this.handleCategoryLimitExhausted(categoryId);
      }
    }

    incrementIngredientServing(ingredientId, { store: 'highlighted' });
    if (saveAutomatically) {
      saveHighlightedIngredientServings();
    }
    return null;
  };

  handleIngredientDecrement = (ingredientId) => {
    const { saveAutomatically, decrementIngredientServing, saveHighlightedIngredientServings } =
      this.props;

    const isDefaultIngredient = this.isDefaultIngredient(ingredientId);

    if (this.globalRemoveLimitExhausted && isDefaultIngredient) {
      this.handleGlobalLimitExhausted(true);
    } else if (this.sectionRemoveLimitExhausted && isDefaultIngredient) {
      this.handleSectionLimitExhausted(true);
    } else {
      decrementIngredientServing(ingredientId, { store: 'highlighted' });

      if (saveAutomatically) {
        saveHighlightedIngredientServings();
      }
    }
  };

  handleIngredientDetailsClick = (ingredientId, event) => {
    const { showIngredientModal } = this.props;

    event.preventDefault();
    event.stopPropagation();
    showIngredientModal({ ingredientId, bulkQuantity: this.bulkQuantity });
  };

  render() {
    const { isPriceDisplayed } = this;
    const {
      className,
      defaultIngredientServings,
      highlightedIngredientServings,
      ingredientNutrientAmounts,
    } = this.props;

    return (
      <IngredientsPicker
        className={className}
        addMultiple={this.sectionSettings.addMultiple}
        limitsExhausted={this.limitsExhausted}
        size={this.size}
        ingredients={this.ingredients}
        unavailableIngredientIds={this.unavailableIngredientIds}
        defaultIngredientServings={defaultIngredientServings}
        highlightedIngredientServings={highlightedIngredientServings}
        ingredientPrices={this.ingredientPrices}
        ingredientNutrientAmounts={ingredientNutrientAmounts}
        bulkQuantity={this.bulkQuantity}
        isPriceDisplayed={isPriceDisplayed}
        onIngredientToggle={this.handleIngredientToggle}
        onIngredientIncrement={this.handleIngredientIncrement}
        onIngredientDecrement={this.handleIngredientDecrement}
        onIngredientDetailsClick={this.handleIngredientDetailsClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  defaultIngredientServings: state.orderItem.defaultIngredientServings,
  highlightedIngredientServings: state.orderItem.highlightedIngredientServings,
  ingredientBreakdown: state.orderItem.ingredientBreakdown,
  selectedTagIds: state.food.selectedTagIds,
  menuItems: state.api.menuItems,
  ingredients: state.api.ingredients,
  ingredientCategories: state.api.ingredientCategories,
  ingredientNutrientAmounts: state.api.ingredientNutrientAmounts,
  sections: state.api.cyoSections,
  tags: state.api.tags,
  servingNumberVariations: state.api.servingNumberVariations,
  pricingVariations: state.api.pricingVariations,
  baseTypeSettings: state.api.settings.baseTypeSettings,
  sectionSettings: state.api.settings.sectionSettings,
  globalIngredientRemovalLimit: state.api.settings.globalIngredientRemovalLimit,
  gglocationId: state.order.gglocationId,
  gglocationDisabledIngredients: state.api.gglocationDisabledIngredients,
  isBaseSectionSelectable: state.api.isBaseSectionSelectable,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showIngredientModal: foodActions.showIngredientModal,
      replaceSectionIngredients: orderItemActions.replaceSectionIngredients,
      addIngredientServings: orderItemActions.addIngredientServings,
      saveHighlightedIngredientServings: orderItemActions.saveHighlightedIngredientServings,
      removeIngredient: orderItemActions.removeIngredient,
      incrementIngredientServing: orderItemActions.incrementIngredientServing,
      decrementIngredientServing: orderItemActions.decrementIngredientServing,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(IngredientsPickerContainer);
