import { isFunction } from 'lodash';


// eslint-ignore-next-line import/prefer-default-export
export const openPOSTWindow = (url) => {
  const form = document.createElement('FORM');
  form.method = 'POST';
  form.action = url;
  form.target = 'POSTWindow';
  document.body.append(form);
  const postWindow = window.open('', 'POSTWindow');
  form.submit();

  return postWindow;
};

// TODO: Migrate to BroadCastChannel api
// Didn't do it now because it still fairly new on iOS on 2023
export class BroadcastMessageChannel {
  constructor(channel) {
    const chn = String(channel);

    this.name = chn;
    this.closed = false;
    // Assuming that key will be the same for channel first
    this.key = chn;
    // May be with some sort of encryption?
  }

  postMessage = (message) => {
    if (this.closed) {
      throw new Error(`BroadcastMessageChannel: ${this.name} already closed`);
    }

    localStorage.setItem(this.key, JSON.stringify(message));
    // Remove immediately to prevent dangling and security concern
    localStorage.removeItem(this.key);
  }

  onMessage = (listener) => {
    if (this.closed) {
      throw new Error(`BroadcastMessageChannel: ${this.name} already closed`);
    }
    if (!isFunction(listener)) {
      throw new TypeError('BroadcastMessageChannel.onMessage expects a function');
    }

    this.listener = listener;
    const listeningKey = this.key;

    window.addEventListener('storage', (event) => {
      if (event.key !== listeningKey) return;
      if (event.newValue == null || event.newValue === '') return;
      // Relay message to listener
      listener(JSON.parse(event.newValue));
    });
  }

  close = () => {
    this.close = true;

    window.removeEventListener('storage', this.listener);
  }
}
