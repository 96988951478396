import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './FeedbackSticker.css';

function FeedbackSticker({ className, children, variant }) {
  return <div className={classnames('FeedbackSticker', className, variant)}>{children}</div>;
}

FeedbackSticker.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.oneOf(['outline', 'filled']),
};

FeedbackSticker.defaultProps = {
  className: '',
  children: null,
  variant: 'filled',
};

export default FeedbackSticker;
