import React from 'react';
import PropTypes from 'prop-types';

import Currency from '../../Currency';

function IngredientChoicePrice(props) {
  const { included, price } = props;
  if (included) {
    return null;
  }
  if (price === null) {
    return null;
  }
  return (
    <span className="Price">
      &#43;
      <Currency value={price} variant="ingredient" />
    </span>
  );
}

IngredientChoicePrice.propTypes = {
  included: PropTypes.bool,
  price: PropTypes.number,
};

IngredientChoicePrice.defaultProps = {
  included: false,
  price: null,
};

export default IngredientChoicePrice;
