import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Slider, { SliderRail } from '@mui/material/Slider';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './MuiSlideButton.css';

function MuiSlideButton({ onSuccess, railText = 'Swipe to confirm' }) {
  const [isHidden, setIsHidden] = useState(false);
  const [value, setValue] = useState(0);
  const [unmounted, setUnmounted] = useState(false);

  const handleMouseUp = () => {
    if (unmounted) return;
    setValue(0);
  };

  const handleChange = (e, val) => {
    if (unmounted) return;

    if (Math.abs(val - value) > 20) {
      return;
    }
    if (val > 90) {
      setIsHidden(true);
      setUnmounted(true);
      onSuccess();
    } else {
      setValue(val - 10);
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function SliderRailComponent(props) {
    // eslint-disable-next-line react/prop-types
    const { children, ...other } = props;
    return (
      <SliderRail {...other}>
        <Typography className={classNames('RailText', isHidden && 'hidden')}>{railText}</Typography>
        {children}
      </SliderRail>
    );
  }

  if (unmounted) return null;

  return (
    <Slider
      className={classNames('MuiSlideButton', isHidden && 'hidden')}
      value={value}
      components={{ Rail: SliderRailComponent }}
      componentsProps={{
        track: {
          style: {
            width: `${value + 10}%`,
          },
        },
      }}
      onChange={handleChange}
      onChangeCommitted={handleMouseUp}
    />
  );
}

MuiSlideButton.propTypes = {
  railText: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default MuiSlideButton;
