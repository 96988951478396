import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as apiActions from '../../../actions/api';
import statusEnum from '../../../enums/statusEnum';

import favoriteMenuItemShape from '../../../shapes/favoriteMenuItemShape';
import pageInfoShape from '../../../shapes/pageInfoShape';
import routerShape from '../../../shapes/routerShape';

import Favorites from '../../../components/Account/Favorites';
import withRouter from '../../WithRouter';

import ecomService from '../../../services/api';

const INITIAL_MENUS_DISPLAY_COUNT = 10;
const DISPLAY_COUNT_INCREMENT_STEP = 10;

class FavoritesContainer extends Component {
  static propTypes = {
    landingPageSlug: PropTypes.string.isRequired,
    favoriteMenuItemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    favoriteMenuItems: PropTypes.objectOf(favoriteMenuItemShape).isRequired,
    favoriteMenuItemsPageInfo: pageInfoShape.isRequired,
    favoriteMenuItemsApiStatus: PropTypes.number.isRequired,
    editFavoriteMenuItem: PropTypes.func.isRequired,
    loadFavoriteMenuItems: PropTypes.func.isRequired,
    loadNextFavoriteMenuItemsPage: PropTypes.func.isRequired,

    router: routerShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      editFavoriteId: null,
      visibleMenusCount: INITIAL_MENUS_DISPLAY_COUNT,
      loadingMenus: false,
    };
  }

  componentDidMount() {
    const { loadFavoriteMenuItems, favoriteMenuItemsApiStatus } = this.props;

    if (favoriteMenuItemsApiStatus === statusEnum.INITIAL) {
      loadFavoriteMenuItems({ page: 1, clearPrevious: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { loadingMenus } = this.state;
    const { favoriteMenuItemsApiStatus } = this.props;
    if (
      loadingMenus &&
      [statusEnum.REQUESTED, statusEnum.RELOADING].includes(prevProps.favoriteMenuItemsApiStatus) &&
      [statusEnum.SUCCESS].includes(favoriteMenuItemsApiStatus)
    ) {
      // Show more favorite after successful fetching
      this.setState((prevState) => ({
        visibleMenusCount: prevState.visibleMenusCount + DISPLAY_COUNT_INCREMENT_STEP,
        loadingMenus: false,
      }));
    }
  }

  get favoriteMenuItems() {
    const { visibleMenusCount } = this.state;
    const { favoriteMenuItemIds, favoriteMenuItems } = this.props;

    return favoriteMenuItemIds
      .sort((a, b) => a - b)
      .slice(0, visibleMenusCount)
      .map((favoriteMenuItemId) => favoriteMenuItems[favoriteMenuItemId]);
  }

  get editFavoriteMenuItem() {
    const { favoriteMenuItems } = this.props;
    const { editFavoriteId } = this.state;

    return favoriteMenuItems[editFavoriteId];
  }

  get hasMoreFavoriteMenus() {
    const { favoriteMenuItemIds, favoriteMenuItemsPageInfo } = this.props;
    const { visibleMenusCount } = this.state;

    return (
      visibleMenusCount < favoriteMenuItemIds.length ||
      favoriteMenuItemIds.length < favoriteMenuItemsPageInfo.count
    );
  }

  get areFavoriteMenusLoading() {
    const { favoriteMenuItemsApiStatus } = this.props;
    return [statusEnum.REQUESTED, statusEnum.RELOADING].includes(favoriteMenuItemsApiStatus);
  }

  handleOrderNowClick = () => {
    const { landingPageSlug, router } = this.props;

    router.push({ pathname: landingPageSlug ? `/site/${landingPageSlug}` : '/' });
  };

  handleOrderHistoryClick = () => {
    const { router } = this.props;

    router.push({ pathname: '/account/orders' });
  };

  handleEditFavoriteOpen = (id) => {
    this.setState({ editFavoriteId: id });
  };

  handleEditFavoriteCancel = () => {
    this.setState({ editFavoriteId: null });
  };

  handleEditFavoriteSave = (id, name) => {
    const { editFavoriteMenuItem } = this.props;
    this.setState({ editFavoriteId: null });
    editFavoriteMenuItem({ id, name });
  };

  handleLoadMoreFavoriteMenus = () => {
    const { visibleMenusCount } = this.state;
    const { favoriteMenuItemIds, loadNextFavoriteMenuItemsPage } = this.props;

    if (!this.hasMoreFavoriteMenus || this.areFavoriteMenusLoading) return;

    if (visibleMenusCount >= favoriteMenuItemIds.length) {
      this.setState({ loadingMenus: true });
      loadNextFavoriteMenuItemsPage();
    } else {
      this.setState((prevState) => ({
        visibleMenusCount: Math.min(
          prevState.visibleMenusCount + DISPLAY_COUNT_INCREMENT_STEP,
          favoriteMenuItemIds.length,
        ),
        loadingMenus: false,
      }));
    }
  };

  render() {
    return (
      <Favorites
        favoriteMenuItems={this.favoriteMenuItems}
        hasMoreFavoriteMenus={this.hasMoreFavoriteMenus}
        editFavoriteMenuItem={this.editFavoriteMenuItem}
        onOrderNowClick={this.handleOrderNowClick}
        onOrderHistoryClick={this.handleOrderHistoryClick}
        onEditFavoriteOpen={this.handleEditFavoriteOpen}
        onEditFavoriteCancel={this.handleEditFavoriteCancel}
        onEditFavoriteSave={this.handleEditFavoriteSave}
        onLoadMoreFavoriteMenus={this.handleLoadMoreFavoriteMenus}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  favoriteMenuItemIds: state.api.favoriteMenuItemIds,
  favoriteMenuItems: state.api.favoriteMenuItems,
  favoriteMenuItemsPageInfo: state.api.pageInfo.favoriteMenuItems,
  favoriteMenuItemsApiStatus: state.api.status.favoriteMenuItems,
  landingPageSlug: state.order.landingPageHistory[state.order.landingPageHistory.length - 1],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editFavoriteMenuItem: ecomService.editFavoriteMenuItem.requestActionCreator,
      loadFavoriteMenuItems: ecomService.favoriteMenuItems.requestActionCreator,
      loadNextFavoriteMenuItemsPage: apiActions.loadNextFavoriteMenuItemsPage,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FavoritesContainer));
