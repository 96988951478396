import PropTypes from 'prop-types';

import historyLocationShape from './historyLocationShape';

const historyShape = PropTypes.shape({
  currentIndex: PropTypes.number,
  locations: PropTypes.arrayOf(historyLocationShape),
});

export default historyShape;
