export const GEOLOCATION = 'GEOLOCATION';
export const GEOLOCATION_REQUESTED = `${GEOLOCATION}_REQUESTED`;
export const GEOLOCATION_SUCCESS = `${GEOLOCATION}_SUCCESS`;
export const GEOLOCATION_ERROR = `${GEOLOCATION}_ERROR`;

export const geolocationSuccess = ({ coordinates }) => ({
  type: GEOLOCATION_SUCCESS,
  coordinates: {
    lat: coordinates.latitude,
    lng: coordinates.longitude,
  },
});

export const geolocationError = () => ({
  type: GEOLOCATION_ERROR,
});

export const requestGeolocation = () => ({
  type: GEOLOCATION_REQUESTED,
});
