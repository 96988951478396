import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../actions/order';
import diningChoiceEnum from '../../enums/diningChoiceEnum';
import * as orderUtils from '../../utils/orderUtils';

import gglocationShape from '../../shapes/gglocationShape';
import routerShape from '../../shapes/routerShape';
import userShape from '../../shapes/userShape';

import Landing from '../../components/Landing';
import withRouter from '../WithRouter';

import { cloudFrontService } from '../../services/api';

const INITIAL_ORDERS_DISPLAY_COUNT = 3;
const DISPLAY_COUNT_INCREMENT_STEP = 5;
const MAX_DISPLAY_COUNT = 8;

class LandingContainer extends Component {
  static propTypes = {
    diningChoice: PropTypes.string.isRequired,
    orderGglocationId: PropTypes.string,
    diningChoiceOptions: PropTypes.objectOf(PropTypes.object).isRequired,
    user: userShape.isRequired,
    userOrderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    userOrders: PropTypes.objectOf(PropTypes.object).isRequired,
    geolocation: PropTypes.shape({
      status: PropTypes.number.isRequired,
      coordinates: PropTypes.objectOf(PropTypes.number),
    }).isRequired,
    gglocations: PropTypes.objectOf(gglocationShape).isRequired,
    fetchGglocations: PropTypes.func.isRequired,
    setLandingPage: PropTypes.func.isRequired,
    isDeliveryAvailable: PropTypes.bool.isRequired,
    isLoyaltyEnabled: PropTypes.bool,

    router: routerShape.isRequired,
  };

  static defaultProps = {
    orderGglocationId: null,
    isLoyaltyEnabled: true,
  };

  constructor(props) {
    const { fetchGglocations, setLandingPage } = props;
    fetchGglocations();
    setLandingPage('');

    super(props);

    this.state = {
      visibleOrdersCount: INITIAL_ORDERS_DISPLAY_COUNT,
    };
  }

  get loggedIn() {
    const { user } = this.props;

    return !!user.token;
  }

  get unsortedUserOrders() {
    const { userOrderIds, userOrders } = this.props;

    return [...userOrderIds]
      .map((userOrderId) => userOrders[userOrderId])
      .filter((userOrder) => {
        if (orderUtils.isOrderStatusFailed(userOrder.orderStatusId)) return false;
        if (moment().diff(userOrder.created, 'day') > 7) return false;
        if (moment().isSame(userOrder.created, 'day')) return true;
        if (moment().isSame(userOrder.pickupTime, 'day')) return true;
        if (orderUtils.isOrderStatusPending(userOrder.orderStatusId)) return true;
        return false;
      });
  }

  get userOrders() {
    const { visibleOrdersCount } = this.state;

    return orderUtils.sortedUserOrdersArray(this.unsortedUserOrders).slice(0, visibleOrdersCount);
  }

  get hasMoreOngoingOrders() {
    const { visibleOrdersCount } = this.state;

    return (
      visibleOrdersCount < MAX_DISPLAY_COUNT && this.unsortedUserOrders.length > visibleOrdersCount
    );
  }

  get orderGglocation() {
    const { orderGglocationId, gglocations } = this.props;

    return gglocations[orderGglocationId];
  }

  get isDeliveryOrder() {
    const { diningChoice } = this.props;

    return diningChoice === diningChoiceEnum.DELIVERY;
  }

  get minimumOrderTotal() {
    const { diningChoiceOptions } = this.props;

    return (
      diningChoiceOptions &&
      diningChoiceOptions.delivery &&
      diningChoiceOptions.delivery.deliveryPartner &&
      diningChoiceOptions.delivery.deliveryPartner.minimumOrderTotal
    );
  }

  handleGetLocation = () => {
    const { geolocation, router } = this.props;

    router.push(
      {
        pathname: '/addAddress',
      },
      {
        geolocation,
        zoom: 13,
      },
    );
  };

  handleAddressClick = (event) => {
    const { router } = this.props;

    router.push(
      {
        pathname: '/addAddress',
      },
      {
        addressInput: event.target.value,
      },
    );
  };

  handleLoadMoreOngoingOrders = () => {
    const { visibleOrdersCount } = this.state;

    if (!this.hasMoreOngoingOrders || visibleOrdersCount >= MAX_DISPLAY_COUNT) return;

    this.setState((prevState) => ({
      visibleOrdersCount: prevState.visibleOrdersCount + DISPLAY_COUNT_INCREMENT_STEP,
    }));
  };

  render() {
    const { isDeliveryAvailable, isLoyaltyEnabled } = this.props;

    return (
      <Landing
        isDeliveryAvailable={isDeliveryAvailable}
        isDeliveryOrder={this.isDeliveryOrder}
        loggedIn={this.loggedIn}
        hasMoreOngoingOrders={this.hasMoreOngoingOrders}
        userOrders={this.userOrders}
        orderGglocation={this.orderGglocation}
        minimumOrderTotal={this.minimumOrderTotal}
        onPickup={this.handlePickup}
        onDelivery={this.handleDelivery}
        onGetLocation={this.handleGetLocation}
        onAddressClick={this.handleAddressClick}
        onLoadMoreOngoingOrders={this.handleLoadMoreOngoingOrders}
        isLoyaltyEnabled={isLoyaltyEnabled}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  customerAddressId: state.order.customerAddressId,
  diningChoice: state.order.diningChoice,
  orderGglocationId: state.order.gglocationId,
  diningChoiceOptions: state.order.diningChoiceOptions,
  isDeliveryAvailable: !!state.order.diningChoiceOptions.delivery,
  user: state.user,
  userOrderIds: state.api.userOrderIds,
  userOrders: state.api.userOrders,
  gglocations: state.api.gglocations,
  customerAddressIds: state.user.customerAddressIds,
  geolocation: state.geolocation,
  isLoyaltyEnabled: state.api.settings.isLoyaltyIntegrationEnabled,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchGglocations: cloudFrontService.gglocations.requestActionCreator,
      setLandingPage: orderActions.setLandingPage,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingContainer));
