import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import partnerShape from '../../shapes/partnerShape';
import storeShape from '../../shapes/storeShape';

import Gglocation from '../../components/Gglocation';

function GglocationContainer(props) {
  const { isHighlighted, menuItemId, gglocation, onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick(gglocation);
    }
  };

  return (
    <Gglocation
      gglocation={gglocation}
      isHighlighted={isHighlighted}
      menuItemId={menuItemId}
      onClick={handleClick}
    />
  );
}

GglocationContainer.propTypes = {
  isHighlighted: PropTypes.bool,
  menuItemId: PropTypes.string,
  gglocation: PropTypes.oneOfType([storeShape, partnerShape]).isRequired,
  onClick: PropTypes.func,
};

GglocationContainer.defaultProps = {
  isHighlighted: false,
  menuItemId: undefined,
  onClick: null,
};

const mapStateToProps = (state, ownProps) => ({
  gglocation: ownProps.gglocation || state.api.gglocations[ownProps.id],
});

export default connect(mapStateToProps, undefined)(GglocationContainer);
