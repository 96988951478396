import React, { Component } from 'react';
import { connect } from 'react-redux';

import userOrderItemShape from '../../../shapes/userOrderItemShape';

import CurrentOrderItem from '../../../components/Landing/CurrentOrderItem';

class CurrentOrderItemContainer extends Component {
  static propTypes = {
    orderItem: userOrderItemShape.isRequired,
  };

  get menuItem() {
    const { orderItem } = this.props;

    return orderItem.menu;
  }

  get orderItemName() {
    return this.menuItem.name;
  }

  get orderItemQuantity() {
    const { orderItem } = this.props;

    return orderItem.personalSettings.length;
  }

  render() {
    const { orderItem } = this.props;

    return (
      <CurrentOrderItem
        name={this.orderItemName}
        quantity={this.orderItemQuantity}
        price={orderItem.price}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  menuItems: state.api.menuItems,
});

export default connect(mapStateToProps, undefined)(CurrentOrderItemContainer);
