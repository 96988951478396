import React from 'react';
import PropTypes from 'prop-types';

import CartPriceLine from '../CartPriceLine';

import './OrderSurcharges.css';

function OrderSurcharges({ surcharges }) {
  const deliveryFee =
    (surcharges && surcharges.baseDelivery && surcharges.baseDelivery.price) || null;

  const minOrderFee =
    (surcharges && surcharges.enforceMinPrice && surcharges.enforceMinPrice.price) || null;

  const locationSurcharge =
    (surcharges && surcharges.deliveryExtra && surcharges.deliveryExtra.price) || null;

  const carbonOffsetFee =
    (surcharges && surcharges.carbonOffset && surcharges.carbonOffset.price) || null;

  const takeOutWBagFee =
    (surcharges && surcharges.takeOutWBag && surcharges.takeOutWBag.price) || null;

  return (
    <>
      {deliveryFee && <CartPriceLine title="Delivery fee" price={deliveryFee} />}
      {locationSurcharge && <CartPriceLine title="Location surcharge" price={locationSurcharge} />}
      {minOrderFee && <CartPriceLine title="Min. order fee" price={minOrderFee} />}
      {carbonOffsetFee && <CartPriceLine title="Climate positive" price={carbonOffsetFee} />}
      {takeOutWBagFee && <CartPriceLine title="Takeout bag" price={takeOutWBagFee} />}
    </>
  );
}

OrderSurcharges.propTypes = {
  surcharges: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default OrderSurcharges;
