import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import DragScroll from '../../DragScroll';

import './MenuNavbar.css';

function MenuNavbar(props) {
  const {
    navbarItems,
    subnavItems,
    selectedNavbarItemId,
    onItemClick,
    onItemBlur,
    onMenuSubnavClick,
  } = props;

  const [topBorderVisible, setTopBorderVisible] = useState(false);

  useEffect(() => {
    /* Query Menu component (scrollable parent) */
    const menuElement = document.querySelector('.Menu');
    const handleMenuScroll = () => {
      /* Add top border if navbar reach to top of the screen */
      if (menuElement && menuElement.scrollTop > 300 && !topBorderVisible) {
        setTopBorderVisible(true);
      } else if (menuElement && menuElement.scrollTop <= 300 && topBorderVisible) {
        setTopBorderVisible(false);
      }
    };

    if (menuElement) {
      menuElement.addEventListener('scroll', handleMenuScroll);
    }

    return () => menuElement && menuElement.removeEventListener('scroll', handleMenuScroll);
  });

  return (
    <div className={classnames('MenuNavbarWrapper', { topBorderVisible })}>
      <div className="MenuNavbar">
        <div className="MenuNavbarCategories">
          <DragScroll>
            <ul>
              {navbarItems.map((navbarItem) => (
                <span key={navbarItem.id}>
                  <li>
                    <button
                      type="button"
                      onClick={() => onItemClick(navbarItem)}
                      onBlur={(e) => onItemBlur(navbarItem, e)}
                      tabIndex="0"
                    >
                      <Typography
                        variant="h5"
                        className={classnames('MenuNavbarItemName', {
                          selectedNavbarItem: selectedNavbarItemId === navbarItem.id,
                        })}
                      >
                        {navbarItem.name}
                      </Typography>
                    </button>
                  </li>
                </span>
              ))}
            </ul>
          </DragScroll>
        </div>
      </div>
      <div
        role="presentation"
        className={classnames('MenuSubnav', { hidden: !subnavItems })}
        onMouseDown={onMenuSubnavClick}
      >
        <DragScroll>
          <ul>
            {subnavItems &&
              subnavItems.map((subnavItem) => (
                <li key={subnavItem.id}>
                  <button
                    type="button"
                    onClick={() => onItemClick(subnavItem)}
                    onBlur={(e) => onItemBlur(subnavItem, e)}
                  >
                    {subnavItem.name}
                  </button>
                </li>
              ))}
          </ul>
        </DragScroll>
      </div>
    </div>
  );
}

MenuNavbar.propTypes = {
  navbarItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      baseId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      category: PropTypes.bool,
    }),
  ).isRequired,
  subnavItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      baseId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      category: PropTypes.bool,
    }),
  ),
  selectedNavbarItemId: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onItemBlur: PropTypes.func.isRequired,
  onMenuSubnavClick: PropTypes.func.isRequired,
};

MenuNavbar.defaultProps = {
  subnavItems: null,
};

export default MenuNavbar;
