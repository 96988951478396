import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as errorResolutionUtils from '../../utils/errorResolutionUtils';

import ErrorBoundary from '../../components/ErrorBoundary';

import * as Sentry from '../../services/sentry';

class ErrorBoundaryContainer extends Component {
  static unregisterErrorHandler() {
    window.removeEventListener('error');
  }

  static reloadApp() {
    errorResolutionUtils.reloadApp();
  }

  static reportToSentry() {
    Sentry.showReportDialog();
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount() {
    ErrorBoundaryContainer.unregisterErrorHandler();
    this.registerErrorHandler();
  }

  componentDidCatch(error, errorInfo) {
    this.handleError(error, errorInfo);
  }

  componentWillUnmount() {
    ErrorBoundaryContainer.unregisterErrorHandler();
  }

  registerErrorHandler = () => {
    window.addEventListener('error', (event) => {
      event.preventDefault();

      /* Events with no error attached to them are safe to ignore. */
      if (event.error) {
        this.handleError(event.error.message, event.error.stack);
      }
    });
  };

  handleError = (error, errorInfo) => {
    console.error(error);
    this.setState({ error });

    Sentry.withScope((scope) => {
      scope.setExtra('errorInfo', errorInfo);
      Sentry.captureException(error);
    });
  };

  render() {
    const { reloadApp, reportToSentry } = ErrorBoundaryContainer;
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <ErrorBoundary reportToSentry={reportToSentry} reloadApp={reloadApp} />;
    }

    return children;
  }
}

export default ErrorBoundaryContainer;
