import PropTypes from 'prop-types';

const deliveryInfoShape = PropTypes.shape({
  minOrderPrice: PropTypes.string,
  deliveryAddressHeader: PropTypes.string,
  deliveryAddressLabel: PropTypes.string,
  deliveryDistance: PropTypes.number,
  estimatedDelivery: PropTypes.string,
});

export default deliveryInfoShape;
