import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import accountStatusEnum from '../../../enums/accountStatusEnum';
import { getUser } from '../../../sagas/selectors';

import Button from '../../Button';
import ErrorSticker from '../../ErrorSticker';

import { auth2Service } from '../../../services/api';
import { CONTACT_EMAIL } from '../../../settings';

function EditPartnerEmailSnackbar() {
  const { partnerEmail, partnerEmailStatus } = useSelector(getUser);
  const dispatch = useDispatch();

  const onResendEmailVerification = () =>
    dispatch(auth2Service.reverifyPartnerEmail.requestActionCreator({ partnerEmail }));
  const onRemovePartnerEmail = () =>
    dispatch(auth2Service.removePartnerEmail.requestActionCreator());

  return (
    <>
      {partnerEmail && partnerEmailStatus === accountStatusEnum.EXPIRED && (
        <ErrorSticker className="edit-account-p" noIcon>
          <p>
            Please reactivate your partner status with the email sent to {partnerEmail} to continue
            enjoy the benefit
          </p>
          <p>
            <Button className="blue-link" onClick={onResendEmailVerification}>
              Resend email verification
            </Button>
            <Button className="blue-link" onClick={onRemovePartnerEmail}>
              Remove email
            </Button>
          </p>
        </ErrorSticker>
      )}
      {partnerEmail && partnerEmailStatus === accountStatusEnum.DISABLED && (
        <ErrorSticker className="edit-account-p" noIcon>
          <p>Unexpected error encountered with your partner email.</p>
          <p>
            <Button className="blue-link" onClick={onRemovePartnerEmail}>
              Remove email
            </Button>
            <Button className="blue-link" href={`mailto:${CONTACT_EMAIL}`}>
              Contact us
            </Button>
          </p>
        </ErrorSticker>
      )}
    </>
  );
}

export default EditPartnerEmailSnackbar;
