import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { filterHighlightedIngredientServings } from '../../../utils/foodUtils';

import cyoSectionShape from '../../../shapes/cyoSectionShape';
import ingredientCategoryShape from '../../../shapes/ingredientCategoryShape';
import ingredientShape from '../../../shapes/ingredientShape';

import MenuDetailsAddHeader from '../../../components/MenuDetails/MenuDetailsAddHeader';

class MenuDetailsAddHeaderContainer extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['cyo', 'premade']),
    sectionId: PropTypes.number.isRequired,
    categoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    activeCategoryId: PropTypes.number,
    onIngredientCategoryClick: PropTypes.func.isRequired,

    sections: PropTypes.objectOf(cyoSectionShape).isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
    highlightedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
  };

  static defaultProps = {
    type: 'premade',
    activeCategoryId: undefined,
  };

  get section() {
    const { sectionId, sections } = this.props;

    return sections[sectionId];
  }

  get ingredientCategoriesList() {
    const { categoryIds, ingredientCategories } = this.props;

    return categoryIds
      .map((categoryId) => ingredientCategories[categoryId])
      .sort((a, b) => a.order - b.order);
  }

  get ingredientServingsCount() {
    const {
      categoryIds,
      sectionId,
      ingredientCategories,
      highlightedIngredientServings,
      ingredients,
    } = this.props;

    if (categoryIds.length < 2) return null;

    const filteredIngredientServings = filterHighlightedIngredientServings({
      sectionIds: [sectionId],
      highlightedIngredientServings,
      ingredients,
      ingredientCategories,
    });

    return Math.ceil(Object.values(filteredIngredientServings).reduce((a, b) => a + b, 0));
  }

  render() {
    const { activeCategoryId, type, onIngredientCategoryClick } = this.props;

    return (
      <MenuDetailsAddHeader
        type={type}
        section={this.section}
        ingredientCategoriesList={this.ingredientCategoriesList}
        ingredientServingsCount={this.ingredientServingsCount}
        activeCategoryId={activeCategoryId}
        onIngredientCategoryClick={onIngredientCategoryClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  sections: state.api.cyoSections,
  ingredientCategories: state.api.ingredientCategories,
  highlightedIngredientServings: state.orderItem.highlightedIngredientServings,
  ingredients: state.api.ingredients,
});

export default connect(mapStateToProps, undefined)(MenuDetailsAddHeaderContainer);
