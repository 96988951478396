import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../actions/order';
import diningChoiceEnum from '../../enums/diningChoiceEnum';
import orderTypeEnum from '../../enums/orderTypeEnum';
import statusEnum from '../../enums/statusEnum';
import { getSearchParam } from '../../utils/historyUtils';

import customerAddressShape from '../../shapes/customerAddressShape';
import gglocationShape from '../../shapes/gglocationShape';
import orderShape from '../../shapes/orderShape';

import PickupTimeSelector from '../../components/PickupTimeSelector';

class PickupTimeSelectorContainer extends Component {
  static propTypes = {
    gglocations: PropTypes.objectOf(gglocationShape).isRequired,
    order: orderShape.isRequired,
    /* React Router props */
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    customerAddresses: PropTypes.arrayOf(customerAddressShape).isRequired,
    addCustomerAddressStatus: PropTypes.number.isRequired,
  };

  get menuItemId() {
    const { location } = this.props;

    return getSearchParam(location.search, 'menuItemId');
  }

  get gglocationId() {
    const { order } = this.props;

    return order.gglocationId;
  }

  get gglocation() {
    const { gglocations } = this.props;

    return gglocations[this.gglocationId];
  }

  get isScheduledOrder() {
    const { order } = this.props;

    return order.orderType === orderTypeEnum.SCHEDULED;
  }

  get isDeliveryOrder() {
    const { order } = this.props;

    return order.diningChoice === diningChoiceEnum.DELIVERY;
  }

  get coordinates() {
    const { order, customerAddresses } = this.props;

    const { customerAddressId } = order;
    if (!customerAddresses || !customerAddresses[customerAddressId]) {
      return null;
    }

    const { coordinates } = customerAddresses[customerAddressId];
    if (!coordinates) {
      return null;
    }

    return coordinates;
  }

  get address() {
    const { order, customerAddresses } = this.props;
    const { customerAddressId } = order;

    return customerAddresses && customerAddresses[customerAddressId];
  }

  get isAddAddressRequested() {
    const { addCustomerAddressStatus } = this.props;

    return [statusEnum.REQUESTED, statusEnum.RELOADING].includes(addCustomerAddressStatus);
  }

  get openingHours() {
    const { gglocation } = this;
    const { order } = this.props;
    const { orderTime, orderType, diningChoice } = order;

    // Retrieve store opening hours for pickup order only
    if (diningChoice !== diningChoiceEnum.TAKE_AWAY || !gglocation || !gglocation.openingHours) {
      return null;
    }

    const selectedDate = orderType === orderTypeEnum.INSTANT ? moment() : moment(orderTime);
    const openingHoursOnDate = gglocation.selectedDateOpeningHours({ selectedDate });

    if (openingHoursOnDate) {
      const { openingDatetime, closingDatetime } = openingHoursOnDate;

      const openingHoursString = `${moment(openingDatetime).format('ddd h:mma')} - ${moment(
        closingDatetime,
      ).format('ddd h:mma')}`;

      return openingHoursString;
    }

    return null;
  }

  get lastOrderTime() {
    const { gglocation } = this;
    const { order } = this.props;
    const { orderTime, orderType, diningChoice } = order;

    if (diningChoice !== diningChoiceEnum.TAKE_AWAY || !gglocation || !gglocation.openingHours)
      return null;

    const selectedDate = orderType === orderTypeEnum.INSTANT ? moment() : moment(orderTime);
    const openingHoursOnDate = gglocation.selectedDateOpeningHours({ selectedDate });

    if (openingHoursOnDate) {
      const { closingDatetime } = openingHoursOnDate;

      return moment(closingDatetime)
        .subtract(gglocation.getProcessingTime({ diningChoice }), 'minutes')
        .format('h:mma');
    }
    return null;
  }

  render() {
    return (
      <PickupTimeSelector
        gglocation={this.gglocation}
        menuItemId={this.menuItemId}
        isDeliveryOrder={this.isDeliveryOrder}
        isScheduledOrder={this.isScheduledOrder}
        isAddAddressRequested={this.isAddAddressRequested}
        coordinates={this.coordinates}
        address={this.address}
        openingHours={this.openingHours}
        lastOrderTime={this.lastOrderTime}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  gglocations: state.api.gglocations,
  order: state.order,
  location: state.router.location,
  customerAddresses: state.user.customerAddresses,
  addCustomerAddressStatus: state.user.status.addCustomerAddress,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateGglocation: orderActions.updateGglocation,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PickupTimeSelectorContainer);
