import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { getSearchParam } from '../../utils/historyUtils';

import { routerLocationShape } from '../../shapes/routerShape';

import GglocationLocator from '../../components/GglocationLocator';

import { cloudFrontService } from '../../services/api';

class GglocationLocatorContainer extends Component {
  static propTypes = {
    gglocationId: PropTypes.number,
    fetchGglocations: PropTypes.func.isRequired,
    /* React Router props */
    location: routerLocationShape.isRequired,
  };

  static defaultProps = {
    gglocationId: null,
  };

  constructor(props, context) {
    const { fetchGglocations } = props;
    fetchGglocations();

    super(props, context);
  }

  get menuItemId() {
    const { location } = this.props;

    return getSearchParam(location.search, 'menuItemId');
  }

  get isStoreSelected() {
    const { gglocationId } = this.props;

    return !!gglocationId;
  }

  get locationSearch() {
    const { location } = this.props;

    return location?.search;
  }

  render() {
    return (
      <GglocationLocator
        menuItemId={this.menuItemId}
        isStoreSelected={this.isStoreSelected}
        locationSearch={this.locationSearch}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  location: state.router.location,
  gglocationId: state.order.gglocationId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchGglocations: cloudFrontService.gglocations.requestActionCreator,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GglocationLocatorContainer);
