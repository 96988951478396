import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as foodActions from '../actions/food';
import * as historyActions from '../actions/history';
import * as loggingActions from '../actions/logging';
import * as orderActions from '../actions/order';
import * as orderItemActions from '../actions/orderItem';
import itemTypeEnum from '../enums/itemTypeEnum';
import orderItemStatusEnum from '../enums/orderItemStatusEnum';
import * as foodUtils from '../utils/foodUtils';

import * as selectors from './selectors';

import ecomService from '../services/api';
import { BASE_SECTION_ID } from '../settings';

export function* getOrderItemFromItemId(action) {
  const ingredients = yield select(selectors.getIngredients);
  const preferenceGroups = yield select(selectors.getPreferenceGroups);
  const menuGroupId = yield select(selectors.getCurrentMenuGroupId);
  const menuGroup = yield select(selectors.selectMenuGroup, menuGroupId);
  const landingPageSlug = yield select(selectors.getCurrentLandingPageSlug);

  const menuItem = yield select(selectors.selectItemFromItemType, action.itemType, action.itemId);

  if (!menuGroup.includes(`${menuItem.apiId}_${menuGroupId}`)) {
    yield put(
      orderActions.addUnavailableItems([
        {
          apiId: menuItem.apiId,
          itemId: action.itemId,
          itemType: action.itemType,
        },
      ]),
    );
    return null;
  }

  const baseMenuItem = yield select(selectors.selectMenuItem, `${menuItem.apiId}_${menuGroupId}`);
  const menuItemId = baseMenuItem.id;

  if (menuGroupId === null) {
    return {
      menuItemId,
      personalSettings: action.personalSettings,
      itemType: action.itemType,
      orderItemStatus: orderItemStatusEnum.TENTATIVE,
      isUpsold: action.isUpsold,
    };
  }

  const defaultIngredientServings = baseMenuItem.ingredientServings;
  const ingredientBreakdown =
    action.itemType === itemTypeEnum.USER_ORDER_ITEM
      ? menuItem.menu.ingredientBreakdown
      : menuItem.ingredientBreakdown;

  let selectedIngredientServings =
    action.itemType === itemTypeEnum.USER_ORDER_ITEM
      ? menuItem.menu.ingredientServings
      : menuItem.ingredientServings;

  const missingIngredientServings = foodUtils.findMissingIngredientServings(
    selectedIngredientServings,
    { ingredients },
  );

  /* Filter out missing ingredient servings from selected ingredient servings */
  selectedIngredientServings = Object.entries(selectedIngredientServings)
    .filter(([ingredientId]) => missingIngredientServings[ingredientId] === undefined)
    .reduce(
      (ingredientServings, [ingredientId, ingredientServing]) => ({
        ...ingredientServings,
        [ingredientId]: ingredientServing,
      }),
      {},
    );

  const highlightedIngredientServings = selectedIngredientServings;

  const defaultPersonalSettings = foodUtils.getDefaultPersonalSettings({
    preferenceGroups,
  });
  /* Personal Settings related to Favorite or userOrder */
  const itemPersonalSettings = menuItem.personalSettings || [];
  /* Personal Settings dispatched with action */
  const actionPersonalSettings = action.personalSettings || [];

  /* We identify the item quantity by either action or item personal settings, else the quantity is 1 */
  const itemQuantity = Math.max(actionPersonalSettings?.length, itemPersonalSettings?.length, 1);

  const personalSettings = [];

  /* Loop over the item quantity, if item quantity is less than one, loop once */
  for (let i = 0; i < itemQuantity; i += 1) {
    /* Item personalSettings take precedence over Action personalSettings */
    if (itemPersonalSettings[i]) {
      personalSettings.push({
        ...itemPersonalSettings[i],
        preferences: {
          ...itemPersonalSettings[i].preferences,
        },
      });
    } else if (actionPersonalSettings[i]) {
      personalSettings.push({
        ...actionPersonalSettings[i],
        preferences: {
          ...actionPersonalSettings[i].preferences,
        },
      });
      /* We then fall back to personal settings */
    } else {
      personalSettings.push({
        ...defaultPersonalSettings,
        preferences: {
          ...defaultPersonalSettings.preferences,
        },
      });
    }
  }

  return {
    apiId: menuItem.apiId,
    baseType: menuItem.baseType,
    bulkQuantity: menuItem.bulkQuantity,
    menuItemId,
    personalSettings,
    selectedIngredientServings,
    highlightedIngredientServings,
    missingIngredientServings,
    defaultIngredientServings,
    ingredientBreakdown: ingredientBreakdown || [],
    landingPageSlug,
    itemType: action.itemType,
    orderingFavorite: action.itemType === itemTypeEnum.FAVORITE_MENU_ITEM,
    orderItemStatus: orderItemStatusEnum.READY,
    isUpsold: action.isUpsold,
  };
}

function* createOrderItemFromItemId({ itemId, itemType, personalSettings, isUpsold = false }) {
  try {
    const orderItem = yield getOrderItemFromItemId({
      itemId,
      personalSettings,
      itemType,
      isUpsold,
    });
    if (orderItem) {
      yield put(orderItemActions.createOrderItem(orderItem));
    }
  } catch (e) {
    yield put(orderItemActions.createOrderItemError({ error: e }));
  }
}

function* saveOrderItem() {
  const orderItem = yield select(selectors.getOrderItem);

  if (orderItem.menuItemId) {
    /* Need to dispatch logging action before orderItem is cleared */
    try {
      yield put(loggingActions.logAddItemToCart());
    } catch (e) {
      yield put(loggingActions.loggingError({ error: e }));
    }
    yield put(orderActions.updateOrderOrderItem({ orderItem }));
  }
}

function* updateTentativeOrderItem() {
  const orderItem = yield select(selectors.getOrderItem);

  if (orderItem.orderItemStatus === orderItemStatusEnum.TENTATIVE) {
    try {
      yield put(
        orderItemActions.createOrderItemFromMenuItemId({
          menuItemId: orderItem.menuItemId,
          personalSettings: orderItem.personalSettings,
        }),
      );
    } catch (e) {
      yield put(orderItemActions.createOrderItemError({ error: e }));
    }
  }
}

function* filterTagIngredientServings() {
  const selectedTagIds = yield select(selectors.getSelectedTagIds);

  yield put(orderItemActions.filterTagIngredientServings({ tagIds: selectedTagIds }));
}

function* handleGglocationUpdated() {
  yield updateTentativeOrderItem();
}

function* handleHighlightedTagIdsSaved() {
  yield filterTagIngredientServings();
}

function* checkBaseTypeRemoved() {
  const food = yield select(selectors.getFood);
  const ingredients = yield select(selectors.getIngredients);
  const orderItem = yield select(selectors.getOrderItem);

  if (food.selectedIngredientIds) {
    const query = {
      selectedSectionIds: [BASE_SECTION_ID],
      selectedIngredientIds: orderItem.selectedIngredientIds,
    };
    const baseIngredients = foodUtils.filterIngredients(ingredients, query, food);

    /* Redirect back to the menu page if base type was removed */
    if (baseIngredients.length > 0) {
      yield put(historyActions.goToMenu());
    }
  }
}

function* handleNutritionalChanges() {
  const highlightedIngredientServings = yield select(selectors.getHighlightedIngredientServings);
  // Get nutritional group information
  const { nutritionalGroups } = yield select(selectors.getApiNutritionalSettings);
  const activeGroupId = yield select(selectors.getActiveNutritionalGroup);
  const sectionIds = nutritionalGroups[activeGroupId]?.sectionIds;

  // Get ingredient info
  const ingredients = yield select(selectors.getIngredients);
  const ingredientCategories = yield select(selectors.getIngredientCategories);

  // Filter ingredient servings based on sectionIds
  const filteredIngredientServings = foodUtils.filterHighlightedIngredientServings({
    sectionIds,
    highlightedIngredientServings,
    ingredients,
    ingredientCategories,
  });
  const orderItem = yield select(selectors.getOrderItem);
  const bulkIngredientServings = foodUtils.processBulkQuantity({
    ingredientServings: filteredIngredientServings,
    bulkQuantity: orderItem.bulkQuantity || 1,
  });
  yield put(
    ecomService.calculateNutrients.requestActionCreator({
      ingredientServings: bulkIngredientServings,
      orderItem: orderItem.BaseType,
    }),
  );
}

function* updateFavMenuItemIsChanged({ id, isChanged }) {
  yield put(ecomService.editFavoriteMenuItem.requestActionCreator({ id, isChanged }));
}

function* watchOrderItem() {
  yield takeEvery(orderItemActions.CREATE_ORDER_ITEM_FROM_ITEM_ID, createOrderItemFromItemId);

  yield takeEvery(orderItemActions.SAVE_ORDER_ITEM, saveOrderItem);

  yield takeEvery(orderItemActions.FILTER_TAG_INGREDIENT_SERVINGS, checkBaseTypeRemoved);

  yield takeEvery(orderItemActions.UPDATE_FAV_MENU_IS_CHANGED, updateFavMenuItemIsChanged);
}

function* watchFood() {
  yield takeEvery(foodActions.SAVE_HIGHLIGHTED_TAG_IDS, handleHighlightedTagIdsSaved);
}

function* watchOrder() {
  yield takeEvery(orderActions.UPDATE_GGLOCATION, handleGglocationUpdated);
  yield takeLatest(
    [
      orderItemActions.DECREMENT_INGREDIENT_SERVING,
      orderItemActions.INCREMENT_INGREDIENT_SERVING,
      orderItemActions.REMOVE_INGREDIENT,
      orderItemActions.RESET_ORDER_ITEM,
      orderItemActions.ADD_INGREDIENT_SERVINGS,
      orderItemActions.SET_ACTIVE_NUTRITIONAL_GROUP,
      orderItemActions.RESTORE_SECTION_INGREDIENTS,
      orderItemActions.REMOVE_SECTION_INGREDIENTS,
      orderItemActions.REPLACE_SECTION_INGREDIENTS,
      orderItemActions.RESET_HIGHLIGHTED_INGREDIENT_SERVINGS,
    ],
    handleNutritionalChanges,
  );
}

export default function* orderItemSaga() {
  yield all([watchOrderItem(), watchFood(), watchOrder()]);
}
