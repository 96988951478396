import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Button from '../Button';
import Modal from '../Modal';

function IncognitoWarning(props) {
  const { modalOpen, onModalHide } = props;

  return (
    <Modal className="" show={modalOpen} onHide={onModalHide}>
      <div className="modal-control">
        <ul>
          <li>
            <Modal.Dismiss className="btn-close-modal" />
          </li>
        </ul>
      </div>
      <Modal.Body className="kale">
        <div className="content-top">
          <Typography variant="h5">Private Window</Typography>
        </div>
        <p>This site does not support the private window feature of your browser.</p>
        <p>
          You might lose your progress during login and check out due to your browsers privacy
          policies.
        </p>
        <p>We apologize for the inconvenience.</p>
        <div className="padded-top">
          <Button className="btn-primary" onClick={onModalHide}>
            Continue
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

IncognitoWarning.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onModalHide: PropTypes.func.isRequired,
};

export default IncognitoWarning;
