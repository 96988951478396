import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as foodUtils from '../../../utils/foodUtils';
import * as orderUtils from '../../../utils/orderUtils';

import favoriteMenuItemShape from '../../../shapes/favoriteMenuItemShape';
import ingredientCategoryShape from '../../../shapes/ingredientCategoryShape';
import ingredientShape from '../../../shapes/ingredientShape';
import userOrderItemShape from '../../../shapes/userOrderItemShape';

import OrderItem from '../../../components/Order/OrderItem';

class OrderItemContainer extends Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['Small', 'Medium']),
    orderItem: userOrderItemShape.isRequired,
    hideFavorite: PropTypes.bool,
    isButtonHidden: PropTypes.bool,
    favoriteMenuItems: PropTypes.objectOf(favoriteMenuItemShape).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
    onFavorite: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    size: 'Medium',
    hideFavorite: false,
    isButtonHidden: false,
    onFavorite: null,
  };

  get menuItem() {
    const { orderItem } = this.props;

    return orderItem.menu;
  }

  get image() {
    return this.menuItem.image;
  }

  get ingredientServings() {
    return this.menuItem.ingredientServings;
  }

  get ingredients() {
    const { ingredients, ingredientCategories } = this.props;
    const orderIngredientIds = foodUtils.ingredientIdsArray(this.ingredientServings);
    const query = {
      selectedIngredientIds: orderIngredientIds,
      includeDeletedIngredients: true,
    };
    const food = { ingredientCategories };

    return foodUtils.filterIngredients(ingredients, query, food);
  }

  get orderItemName() {
    return this.menuItem.name;
  }

  get orderItemLabels() {
    const { orderItem } = this.props;

    return orderItem.personalSettings
      .map((personalSetting) => personalSetting.label)
      .filter((label) => label);
  }

  get orderItemQuantity() {
    const { orderItem } = this.props;

    return orderItem.personalSettings.length;
  }

  get totalPrice() {
    const { orderItem } = this.props;

    return orderItem.total;
  }

  get discountAmount() {
    const { orderItem } = this.props;

    return orderItem.discountAmount;
  }

  get isFavoriteDisabled() {
    const { orderItem } = this.props;

    return orderItem.isFavoriteDisabled;
  }

  get originalPrice() {
    const { totalPrice, discountAmount } = this;

    return totalPrice + discountAmount;
  }

  get isDiscounted() {
    const { discountAmount } = this;

    return discountAmount > 0;
  }

  get isFavorite() {
    const { orderItem, favoriteMenuItems } = this.props;

    return orderUtils.isOrderItemFavorite(orderItem, {
      favoriteMenuItems,
    });
  }

  handleFavorite = () => {
    const { onFavorite, orderItem } = this.props;

    if (onFavorite === null || this.isFavorite) {
      return false;
    }

    return onFavorite(orderItem);
  };

  render() {
    const { className, size, hideFavorite, isButtonHidden } = this.props;

    if (hideFavorite && this.isFavorite) return null;

    return (
      <OrderItem
        className={className}
        size={size}
        image={this.image}
        name={this.orderItemName}
        labels={this.orderItemLabels}
        quantity={this.orderItemQuantity}
        originalPrice={this.originalPrice}
        totalPrice={this.totalPrice}
        isDiscounted={this.isDiscounted}
        isFavorite={this.isFavorite}
        isButtonHidden={isButtonHidden}
        isFavoriteDisabled={this.isFavoriteDisabled}
        ingredients={this.ingredients}
        ingredientServings={this.ingredientServings}
        onFavorite={this.handleFavorite}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  favoriteMenuItems: state.api.favoriteMenuItems,
  ingredients: state.api.ingredients,
  ingredientCategories: state.api.ingredientCategories,
});

export default connect(mapStateToProps, undefined)(OrderItemContainer);
