import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { landingPageMenuStyles } from '../../../shapes/landingPageStyleShapes';

import FontAwesome from '../../FontAwesome';

import giftCardDuotone1 from './giftCardDuotone1.png';

import './GiftCardCategory.css';

function GiftCardCategory(props) {
  const {
    className,
    navigationKey,
    description,
    giftCardUrl,
    landingPageStyles,
    scrollable,
    onInit,
  } = props;

  return (
    <div
      id={navigationKey}
      className={classnames('section', className)}
      ref={onInit}
      style={{ marginBottom: '80px' }}
    >
      <div className="category-name kale">
        <Typography
          variant="h4"
          color="secondary.dark"
          className="SharpGroteskSmBold15"
          style={landingPageStyles && landingPageStyles.categoryTitleStyle}
        >
          Gifts
        </Typography>
      </div>
      <div className="category-desc kale">
        <p style={landingPageStyles && landingPageStyles.categoryDescStyle}>{description}</p>
      </div>
      <ul className={classnames('GiftCardCategory', { scrollable })}>
        {/* gift card item using MenuItem classNames to have same consistent
              styling with menu items in general */}
        <li className="MenuItem">
          <div role="presentation" onClick={() => window.open(giftCardUrl, '_blank')}>
            <Typography
              variant="h5"
              fontWeight="400"
              color="secondary.dark"
              className="absolute-right-top"
            >
              <FontAwesome name="arrow-up-right-from-square" />
            </Typography>
            <div className="menu-image" style={{ padding: '20px', textAlign: 'center' }}>
              <img src={giftCardDuotone1} alt="" style={{ height: 'auto' }} />
            </div>
            <div
              className="menu-desc"
              style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="700"
                color="grey.main"
                style={{
                  fontFamily: landingPageStyles && landingPageStyles.fontFamily,
                  color: landingPageStyles && landingPageStyles.colorHeading,
                }}
              >
                Gift Cards
              </Typography>

              <p className="PriceWrapper">
                <Typography
                  className="Price"
                  style={{
                    fontFamily: landingPageStyles && landingPageStyles.fontFamily,
                    color: landingPageStyles && landingPageStyles.colorHeading,
                    overflowWrap: 'break-word',
                  }}
                >
                  Open in new tab
                </Typography>
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

GiftCardCategory.propTypes = {
  className: PropTypes.string,
  navigationKey: PropTypes.string,
  description: PropTypes.string,
  giftCardUrl: PropTypes.string,
  scrollable: PropTypes.bool.isRequired,
  landingPageStyles: landingPageMenuStyles.isRequired,
  onInit: PropTypes.func,
};

GiftCardCategory.defaultProps = {
  className: '',
  navigationKey: null,
  giftCardUrl: null,
  description: null,
  onInit: null,
};

export default GiftCardCategory;
