import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { getOrderPricing, getOrderSurcharges } from '../../../sagas/selectors';

import CartDiscountLine from '../../../containers/Cart/CartDiscountLine';
import CartMinOrderBanner from '../CartMinOrderBanner/CartMinOrderBanner';
import CartPriceLine from '../CartPriceLine';
import OrderSurcharges from '../OrderSurcharges';

import './CartPaymentInfo.css';

function CartPaymentInfo({ className }) {
  const surcharges = useSelector(getOrderSurcharges);
  const { price, discountAmount, totalTax, totalPrice } = useSelector(getOrderPricing);
  const showSubtotal = !isEmpty(surcharges) || !!discountAmount;

  return (
    <div className={classnames('CartPaymentInfo', className)}>
      <CartMinOrderBanner />
      <div className="CartPaymentDetails">
        {showSubtotal && <CartPriceLine title="Subtotal" price={price} />}
        {!isEmpty(surcharges) && <OrderSurcharges surcharges={surcharges} />}
        <CartDiscountLine />
        {surcharges && surcharges.surchargeDisc && (
          <CartPriceLine title="Delivery discount" price={surcharges.surchargeDisc.price} />
        )}
        <CartPriceLine
          className="CartTotal"
          title="Total"
          tax={totalTax}
          price={totalPrice}
          typographyVariant="body1"
        />
      </div>
    </div>
  );
}

CartPaymentInfo.propTypes = {
  className: PropTypes.string,
};

CartPaymentInfo.defaultProps = {
  className: '',
};

export default CartPaymentInfo;
