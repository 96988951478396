export const SET_HIGHLIGHTED_USER_REWARD = 'SET_HIGHLIGHTED_USER_REWARD';
export const UNSET_HIGHLIGHTED_USER_REWARD = 'UNSET_HIGHLIGHTED_USER_REWARD';
export const CONFIRM_HIGHLIGHTED_REWARD = 'CONFIRM_HIGHLIGHTED_REWARD';
export const UNSET_SELECTED_USER_REWARD = 'UNSET_SELECTED_USER_REWARD';

export const SET_SELECTED_PROMO_CODE_REQUESTED = 'SET_SELECTED_PROMO_CODE_REQUESTED';
export const SET_SELECTED_PROMO_CODE_ERROR = 'SET_SELECTED_PROMO_CODE_ERROR';
export const SET_SELECTED_PROMO_CODE_SUCCESS = 'SET_SELECTED_PROMO_CODE_SUCCESS';
export const UNSET_SELECTED_PROMO_CODE = 'UNSET_SELECTED_PROMO_CODE';

export const DISPATCH_SCAN_CODE = 'DISPATCH_SCAN_CODE';
export const SCAN_CODE_ERROR = 'SCAN_CODE_ERROR';

export const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const setHighlightedUserReward = ({ userRewardUuid }) => ({
  type: SET_HIGHLIGHTED_USER_REWARD,
  userRewardUuid,
});

export const unsetHighlightedUserReward = () => ({
  type: UNSET_HIGHLIGHTED_USER_REWARD,
});

export const confirmHighlightedUserReward = () => ({
  type: CONFIRM_HIGHLIGHTED_REWARD,
});

export const unsetSelectedUserReward = () => ({
  type: UNSET_SELECTED_USER_REWARD,
});

export const setSelectedPromoCode = ({ promoCode }) => ({
  type: SET_SELECTED_PROMO_CODE_REQUESTED,
  promoCode,
});

export const unsetSelectedPromoCode = () => ({
  type: UNSET_SELECTED_PROMO_CODE,
});

export const dispatchScanCode = ({ newCode, geolocation }) => ({
  type: DISPATCH_SCAN_CODE,
  newCode,
  geolocation,
});

export const scanCodeError = ({ error }) => ({
  type: SCAN_CODE_ERROR,
  error,
});

export const removeDiscount = () => ({
  type: REMOVE_DISCOUNT,
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});
