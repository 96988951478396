import { apiMiddleware, wrapReducer } from '@spq/redux-api-client';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import { gglocationTransform } from '../utils/persistUtils';

import { rehydrateLocationMiddleware } from '../middleware';
import rootSaga from '../sagas';
import configureReducer from './configReducer';

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const history = createBrowserHistory();

  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history,
  });

  const rootReducer = configureReducer(routerReducer);

  const initialState = {};

  const persistConfig = {
    key: 'root',
    storage,
    timeout: null,
    whitelist: [
      'food',
      'geolocation',
      'history',
      'loyalty',
      'order',
      'orderItem',
      'pricing',
      'user',
      'api',
    ],
    transforms: [gglocationTransform],
  };

  const apiReducer = wrapReducer(rootReducer);
  const persistedReducer = persistReducer(persistConfig, apiReducer);

  const store = createStore(
    persistedReducer,
    initialState,
    compose(
      applyMiddleware(sagaMiddleware, apiMiddleware, routerMiddleware, rehydrateLocationMiddleware),
    ),
  );
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);

  const reduxHistory = createReduxHistory(store);

  return { store, persistor, history: reduxHistory };
};

export default configureStore;
