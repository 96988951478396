import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../../actions/order';
import * as foodUtils from '../../../utils/foodUtils';

import ingredientShape from '../../../shapes/ingredientShape';
import menuItemShape from '../../../shapes/menuItemShape';
import orderItemShape from '../../../shapes/orderItemShape';
import { baseTypeSettingsShape } from '../../../shapes/settingsShape';

import CartItem from '../../../components/Cart/CartItem';

class CartItemContainer extends Component {
  static propTypes = {
    orderItem: orderItemShape.isRequired,
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    baseTypeSettings: PropTypes.objectOf(baseTypeSettingsShape).isRequired,
    modifyOrderOrderItem: PropTypes.func.isRequired,
    removeOrderItemDispatch: PropTypes.func.isRequired,
    updateOrderItemQuantity: PropTypes.func.isRequired,
  };

  get orderItemId() {
    const { orderItem } = this.props;

    return orderItem.id;
  }

  get menuItem() {
    const { menuItems, orderItem } = this.props;

    return menuItems[orderItem.menuItemId];
  }

  get baseId() {
    return this.menuItem.baseType;
  }

  get baseSettings() {
    const { baseTypeSettings } = this.props;
    const currentBaseTypeSettings = baseTypeSettings[this.baseId];

    return currentBaseTypeSettings;
  }

  get orderItemName() {
    return this.favoriteMenuItem ? this.favoriteMenuItem.name : this.menuItem.name;
  }

  get orderItemLabels() {
    const { orderItem } = this.props;

    return orderItem.personalSettings.map((personalSetting) => personalSetting.label);
  }

  get orderItemQuantity() {
    const { orderItem } = this.props;

    return orderItem.personalSettings.length;
  }

  get image() {
    return this.menuItem.image;
  }

  get compositeIngredientChangesText() {
    const { orderItem, ingredients } = this.props;
    const { selectedIngredientServings } = orderItem;

    const compositeIngredient = foodUtils.getCompositeIngredient(
      selectedIngredientServings,
      ingredients,
    );

    return [{ label: null, text: compositeIngredient.compositeIngredients }];
  }

  get defaultIngredientChangesText() {
    const { menuItem } = this;
    if (menuItem === null) return null;

    const { orderItem, ingredients } = this.props;

    const ingredientChangesText = foodUtils.ingredientChangesText(
      orderItem.ingredientBreakdown,
      ingredients,
    );
    const result = [];
    if (ingredientChangesText.removed)
      result.push({ label: 'Removed', text: ingredientChangesText.removed });
    if (ingredientChangesText.added)
      result.push({ label: 'Added', text: ingredientChangesText.added });
    return result;
  }

  get cyoIngredientChangesText() {
    const { ingredients, orderItem } = this.props;

    const ingredientChangesText = foodUtils.ingredientChangesText(
      orderItem.ingredientBreakdown,
      ingredients,
    );

    return [{ label: 'Ingredients', text: ingredientChangesText.added }];
  }

  get ingredientChangesTexts() {
    if (this.baseSettings?.premade) return this.compositeIngredientChangesText;
    if (this.menuItem.isCyo) return this.cyoIngredientChangesText;

    return this.defaultIngredientChangesText;
  }

  handleOrderItemModify = () => {
    const { orderItem, modifyOrderOrderItem } = this.props;

    modifyOrderOrderItem({ orderItemId: orderItem.id });
  };

  handleOrderItemRemove = () => {
    const { orderItem, removeOrderItemDispatch } = this.props;

    removeOrderItemDispatch(orderItem.id);
  };

  handleOrderItemIncrement = () => {
    const { orderItem, updateOrderItemQuantity } = this.props;

    updateOrderItemQuantity(orderItem.id, this.orderItemQuantity + 1);
  };

  handleOrderItemDecrement = () => {
    const { orderItem, updateOrderItemQuantity } = this.props;

    updateOrderItemQuantity(orderItem.id, this.orderItemQuantity - 1);
  };

  render() {
    const {
      orderItemId,
      image,
      orderItemName,
      orderItemLabels,
      orderItemQuantity,
      ingredientChangesTexts,
    } = this;

    return (
      <CartItem
        orderItemId={orderItemId}
        image={image}
        name={orderItemName}
        labels={orderItemLabels}
        quantity={orderItemQuantity}
        ingredientChangesTexts={ingredientChangesTexts}
        onOrderItemModify={this.handleOrderItemModify}
        onOrderItemRemove={this.handleOrderItemRemove}
        onOrderItemIncrement={this.handleOrderItemIncrement}
        onOrderItemDecrement={this.handleOrderItemDecrement}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  menuItems: state.api.menuItems,
  ingredients: state.api.ingredients,
  ingredientCategories: state.api.ingredientCategories,
  baseTypeSettings: state.api.settings.baseTypeSettings,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      modifyOrderOrderItem: orderActions.modifyOrderOrderItem,
      removeOrderItemDispatch: orderActions.removeOrderItemDispatch,
      updateOrderItemQuantity: orderActions.updateOrderItemQuantity,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CartItemContainer);
