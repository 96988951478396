import { useEffect, useRef, useState } from 'react';
import { isFunction } from 'lodash';

/**
 * Loading script using hooks.
 * Workaround since `react-meta-tags` is not able to load script correctly.
 * @todo switch to `react-helmet-async`
 *
 * @param {string | undefined} url - Url of the script to load from
 * @param {object | undefined} options - Options
 * @param {string | undefined} options.name - Name space of the library
 * @param {function | undefined} options.onLoad - Callback when script is loaded,
 * a small caveat is that it only copy the function on first mount
 * @returns {object | null} Will return the library if name is given else true
 *
 * @example
 * useScript(scriptUrl);
 *
 * // Or use variable as namespace
 * const MyLib = useScript(scriptUrl, { name: "MyLib" });
 * MyLib.anyMethod();
 *
 * // Or perform action after script is loaded
 * const [isLoaded, setIsLoaded] = useState(false);
 * useScript(scriptUrl, { onLoad: () => setIsLoaded(true) });
 *
 * if (isLoaded) ...
 */
const useScript = (url, { name, onLoad } = {}) => {
  const [lib, setLib] = useState(null);
  const onLoadRef = useRef(onLoad); // Use ref as it may change frequently

  useEffect(() => {
    const script = url && document.createElement('script');

    if (script) {
      script.src = url;
      script.async = true;
      script.onload = () => {
        if (name && window[name]) {
          setLib(window[name]);
        }
        if (onLoadRef.current && isFunction(onLoadRef.current)) {
          onLoadRef.current();
        }
      };

      document.body.appendChild(script);
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [url, name]);

  return lib;
};

export default useScript;
