import React from 'react';
import PropTypes from 'prop-types';

import './TwoColumns.css';

function TwoColumns(props) {
  const { children } = props;
  return <ul className="TwoColumns">{children}</ul>;
}

TwoColumns.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default TwoColumns;
