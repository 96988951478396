import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import InputAlertText from '../InputAlertText';

import './Autocomplete.css';

function Autocomplete({ className, suggestions, onSuggestionClick, error, ...inputProps }) {
  return (
    <div className="Autocomplete">
      <input className={classnames('AutocompleteInput', className)} {...inputProps} />
      <ul className="AutocompleteSuggestions">
        {suggestions &&
          suggestions.map(({ key, value }, index) => (
            <li className="AutocompleteSuggestion" key={key}>
              <button
                type="button"
                className="AutocompleteSuggestionButton"
                tabIndex={index}
                onClick={(event) => {
                  event.preventDefault();
                  onSuggestionClick({ key, value });
                }}
              >
                {value}
              </button>
            </li>
          ))}
      </ul>
      {error && <InputAlertText>{error}</InputAlertText>}
    </div>
  );
}

Autocomplete.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  error: PropTypes.string,
  onSuggestionClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

Autocomplete.defaultProps = {
  className: '',
  error: null,
};

export default Autocomplete;
