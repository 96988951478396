import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { Typography } from '@mui/material';

import { setIsAddPaymentMethodFormShown } from '../../actions/page';
import { getIsAddPaymentMethodFormShown } from '../../sagas/selectors';

import TopBar from '../../containers/TopBar';
import Modal from '../Modal';
import AddPaymentMethodForm from './AddPaymentMethodForm';

import './AddPaymentMethodForm.css';

function AddPaymentMethodFormModal() {
  const isShown = useSelector(getIsAddPaymentMethodFormShown);
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const dispatch = useDispatch();

  useEffect(() => {
    if (navigationType === 'POP' && isShown) {
      // Close modal if click go back natively
      dispatch(setIsAddPaymentMethodFormShown(false));
    }
  }, [navigationType, isShown, dispatch]);

  const onHide = () => {
    // Imitate go back redirection when close modal
    navigate(-1);
    dispatch(setIsAddPaymentMethodFormShown(false));
  };

  return (
    <Modal className="AddPaymentMethodFormModal" show={isShown} onHide={onHide}>
      <Modal.Body>
        <TopBar isOverlay onExternalBackClick={onHide} />
        <div className="AddPaymentMethodFormModalBody">
          <Typography variant="h4" color="primary" className="AddPaymentMethodFormModalTitle">
            Add payment method
          </Typography>
          {isShown && <AddPaymentMethodForm />}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddPaymentMethodFormModal;
