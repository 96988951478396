import React from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import Moment from 'react-moment';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import diningChoiceEnum from '../../enums/diningChoiceEnum';
import * as orderUtils from '../../utils/orderUtils';

import userOrderShape from '../../shapes/userOrderShape';

import CurrentOrderItem from '../../containers/Landing/CurrentOrderItem';
import Button from '../Button';
import Currency from '../Currency';

import './PastOrder.css';

function PastOrder(props) {
  const { order, gglocationName, isStationOrder, onOrderReplace, onToggle } = props;
  const isDeliveryOrder = order.diningChoice === diningChoiceEnum.DELIVERY;
  const deliveryAddress = (() => {
    if (!isDeliveryOrder) {
      return null;
    }

    if (!order.deliveryAddress) {
      return null;
    }

    return order.deliveryAddress.label;
  })();

  return (
    <Panel className="PastOrder" expanded={false}>
      <Panel.Heading onClick={onToggle}>
        <div className="PastOrderHeader">
          <div className="Body">
            <div className="Date">
              <Typography className="DiningChoice" variant="subtitle2" color="primary">
                {isStationOrder && 'Station '}
                {isDeliveryOrder ? 'Delivery' : 'Pickup'}
              </Typography>
              <Typography variant="subtitle2" color="grey.main">
                <span className="OrderNumber">{order.orderNumber}</span>
              </Typography>
              <Typography variant="subtitle2" color="grey.main">
                <Moment format="DD MMM YYYY, hh:mma">{order.created}</Moment>
              </Typography>
            </div>
            <Typography variant="subtitle2" className="GglocationName">
              {isDeliveryOrder ? deliveryAddress : gglocationName}
              &nbsp;
            </Typography>
          </div>
          <div className="Details">
            <Button className="blue-link" link={`/order/${order.uuid}`}>
              View details
            </Button>
            <div className="Status">
              {orderUtils.isOrderCompletedWithinCutoff(order) && (
                <span className="Ready">Ready</span>
              )}
              {orderUtils.isOrderStatusPending(order.orderStatusId) && (
                <span className="Pending">Pending</span>
              )}
              {orderUtils.isOrderStatusCancelled(order.orderStatusId) && (
                <span className="Cancelled">Cancelled</span>
              )}
            </div>
            <div className="Price">
              <Currency value={order.price - order.discountAmount} />
            </div>
          </div>
        </div>
      </Panel.Heading>
      <Panel.Body collapsible>
        <div className="PastOrderItems">
          {order.ordermenu.map((orderItem) => (
            <CurrentOrderItem key={orderItem.id} orderItem={orderItem} />
          ))}
        </div>
        <div className="PastOrderFooter">
          <Button className="blue-link" link={`/order/${order.uuid}`}>
            View details
          </Button>
          <Button className="btn-primary" onClick={onOrderReplace}>
            Re-order
          </Button>
        </div>
      </Panel.Body>
    </Panel>
  );
}

PastOrder.propTypes = {
  isStationOrder: PropTypes.bool.isRequired,
  order: userOrderShape.isRequired,
  gglocationName: PropTypes.string.isRequired,
  onOrderReplace: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default PastOrder;
