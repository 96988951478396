import { replace } from 'redux-first-history';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import * as orderActions from '../actions/order';
import { setSearchParams } from '../utils/historyUtils';

import * as selectors from './selectors';

function* showCart() {
  const { pathname, search } = yield select(selectors.getCurrentLocation);
  yield put(replace({ pathname, search: setSearchParams(search, { showCart: true }) }));
}

function* hideCart() {
  const { pathname, search } = yield select(selectors.getCurrentLocation);
  yield put(replace({ pathname, search: setSearchParams(search, { showCart: false }) }));
}

function* watchCart() {
  yield takeEvery(orderActions.SHOW_CART, showCart);
  yield takeEvery(orderActions.HIDE_CART, hideCart);
}

export default function* cartSaga() {
  yield all([watchCart()]);
}
