import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './Input.css';

function Input({ className, ...otherProps }) {
  return <input className={classnames('Input', className)} {...otherProps} />;
}

Input.propTypes = {
  className: PropTypes.string,
};

Input.defaultProps = {
  className: '',
};

export default Input;
