import PropTypes from 'prop-types';

import coordinatesShape from './coordinatesShape';
import timeSlotShape from './timeSlotShape';

const partnerShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  gglocationId: PropTypes.number.isRequired,
  gglocationType: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  phone: PropTypes.string,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  coordinates: coordinatesShape.isRequired,
  cutoffTime: PropTypes.objectOf(PropTypes.number).isRequired,
  timeSlots: PropTypes.arrayOf(PropTypes.string).isRequired,
  images: PropTypes.shape({
    banner: PropTypes.number,
    logo: PropTypes.number,
  }).isRequired,
  allTimeSlots: PropTypes.objectOf(timeSlotShape).isRequired,
  scheduledOrderTimes: PropTypes.objectOf(PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))),
});

export default partnerShape;
