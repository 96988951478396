import PropTypes from 'prop-types';

import ingredientServingsShape from './ingredientServingsShape';
import menuItemIngredientBreakdownShape from './menuItemIngredientBreakdownShape';
import personalSettingsShape from './personalSettingsShape';

const favoriteMenuItemShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      ingredient: PropTypes.number.isRequired,
      serving: PropTypes.string.isRequired,
    }),
  ).isRequired,
  ingredientServings: ingredientServingsShape,
  personalSettings: personalSettingsShape,
  ingredientBreakdown: PropTypes.arrayOf(menuItemIngredientBreakdownShape),
  menu: PropTypes.number.isRequired,
  menuCategory: PropTypes.number.isRequired,
  apiId: PropTypes.number.isRequired,
  isCyo: PropTypes.bool.isRequired,
  image: PropTypes.number,
  isChanged: PropTypes.bool.isRequired,
});

export default favoriteMenuItemShape;
