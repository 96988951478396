import React, { useEffect } from 'react';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as orderItemActions from '../../actions/orderItem';
import * as selectors from '../../sagas/selectors';

import NutritionalInfo from '../NutritionalInfo';

import './NutritionalBlock.css';

function NutritionalBlock({ hideTabs, isMobile }) {
  const dispatch = useDispatch();
  const { nutritionalGroups } = useSelector(selectors.getApiNutritionalSettings);
  const activeGroupId = useSelector(selectors.getActiveNutritionalGroup);

  const onGroupSelect = (nutritionalGroupId) => {
    dispatch(orderItemActions.setActiveNutritionalGroup({ nutritionalGroupId }));
  };

  useEffect(() => {
    /* Nutritional groups are not used in mobile view */
    if (isMobile) {
      dispatch(orderItemActions.setActiveNutritionalGroup({ nutritionalGroupId: null }));
    }
    /* If the active group is not set, set the default one (not on mobile view) */
    if (!activeGroupId && !isMobile) {
      const nutritionalGroupId = Object.values(nutritionalGroups)[0]?.id;
      dispatch(orderItemActions.setActiveNutritionalGroup({ nutritionalGroupId }));
    }
  }, [isMobile]);

  const isNutritionalInformationHidden = useSelector(selectors.getIsNutritionalInformationHidden);

  if (isNutritionalInformationHidden) {
    return null;
  }

  return (
    <div className={classnames('NutritionalBlock', { hideTabs })}>
      {hideTabs === false && (
        <Nav bsStyle="tabs" activeKey={activeGroupId} onSelect={onGroupSelect} id="NutritionalTabs">
          {Object.values(nutritionalGroups).map((nutritionalGroup) => (
            <NavItem
              key={nutritionalGroup.id}
              eventKey={nutritionalGroup.id}
              title={nutritionalGroup.title}
            >
              <Typography variant="body1">{nutritionalGroup.title}</Typography>
            </NavItem>
          ))}
        </Nav>
      )}
      <NutritionalInfo showPreview={false} />
      <Typography variant="subtitle2" className="NutritionalDisclaimer">
        <span>Disclaimer:</span> Nutritional information may vary depending on portioning.
      </Typography>
    </div>
  );
}

NutritionalBlock.propTypes = {
  hideTabs: PropTypes.bool,
  isMobile: PropTypes.bool,
};

NutritionalBlock.defaultProps = {
  hideTabs: false,
  isMobile: false,
};

export default NutritionalBlock;
