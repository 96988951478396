import React from 'react';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import { MenuItem, Select as MuiSelect } from '@mui/material';
import PropTypes from 'prop-types';

import gglocationShape from '../../shapes/gglocationShape';

import Distance from '../../containers/Distance';
import FontAwesome from '../FontAwesome';
import NextAvailableLabel from '../NextAvailableLabel';

import { ReactComponent as IconLocale } from '../../images/icon-locale.svg';

import './GglocationsSelect.css';

function GglocationsSelectIconComponent(props) {
  return <FontAwesome name="caret-down" {...props} />;
}

function GglocationsSelect(props) {
  const {
    orderGglocationId,
    gglocationsList,
    isDistanceShown,
    isStationFlow,
    onGglocationSelected,
  } = props;

  return (
    <ButtonToolbar className="GglocationsSelectWrapper">
      <MuiSelect
        id={`GglocationsSelect-${isStationFlow ? 'station' : 'store'}`}
        className="GglocationsSelect"
        value={orderGglocationId}
        onChange={onGglocationSelected}
        displayEmpty
        notched={false}
        label="Label"
        IconComponent={GglocationsSelectIconComponent}
        renderValue={() => (
          <div className="GglocationsSelectInputLabel">
            <IconLocale />
            {gglocationsList.find(({ id }) => id === orderGglocationId)?.name || 'Other stores'}
          </div>
        )}
        MenuProps={{
          PaperProps: { sx: { borderRadius: '16px' } },
          classes: { paper: 'GglocationsSelectPopover', root: 'GglocationsSelectBasePopover' },
        }}
      >
        {gglocationsList.map((gglocation) => (
          <MenuItem
            className="GglocationsSelectMenuItem"
            key={gglocation.id}
            value={gglocation.id}
            sx={{
              '&.Mui-selected, &.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
                backgroundColor: '#F3F6F4',
              },
              '&:hover, &.Mui-selected:hover': { backgroundColor: '#F3F6F4' },
            }}
          >
            <div>{gglocation.name}</div>
            <div className="GglocationsSelectExtraInfo">
              &nbsp;
              {isDistanceShown ? (
                <Distance
                  showIcon={false}
                  lat={gglocation.coordinates?.lat}
                  lng={gglocation.coordinates?.lng}
                />
              ) : (
                <NextAvailableLabel gglocation={gglocation} />
              )}
            </div>
          </MenuItem>
        ))}
        <MenuItem
          className="GglocationsSelectMenuItem"
          value={null}
          sx={{
            '&.Mui-selected, &.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
              backgroundColor: '#F3F6F4',
            },
            '&:hover, &.Mui-selected:hover': { backgroundColor: '#F3F6F4' },
          }}
        >
          Other stores
        </MenuItem>
      </MuiSelect>
    </ButtonToolbar>
  );
}

GglocationsSelect.propTypes = {
  orderGglocationId: PropTypes.string,
  gglocationsList: PropTypes.arrayOf(gglocationShape).isRequired,
  isDistanceShown: PropTypes.bool.isRequired,
  isStationFlow: PropTypes.bool.isRequired,
  onGglocationSelected: PropTypes.func.isRequired,
};

GglocationsSelect.defaultProps = {
  orderGglocationId: null,
};

export default GglocationsSelect;
