import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as foodActions from '../../actions/food';

import ingredientShape from '../../shapes/ingredientShape';

import IngredientModal from '../../components/IngredientModal';

class IngredientModalContainer extends Component {
  static propTypes = {
    ingredientId: PropTypes.number,
    bulkQuantity: PropTypes.number,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    hideIngredientModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ingredientId: null,
    bulkQuantity: 1,
  };

  get ingredient() {
    const { ingredientId, ingredients } = this.props;

    return ingredients[ingredientId];
  }

  render() {
    const { hideIngredientModal, bulkQuantity } = this.props;

    return (
      <IngredientModal
        ingredient={this.ingredient}
        bulkQuantity={bulkQuantity}
        onHide={hideIngredientModal}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ingredientId: state.food.ingredientDetailsId,
  bulkQuantity: state.food.bulkQuantity,
  ingredients: state.api.ingredients,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideIngredientModal: foodActions.hideIngredientModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(IngredientModalContainer);
