import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Currency from '../../Currency';

import './CurrentOrderItem.css';

function CurrentOrderItem(props) {
  const { name, quantity, price } = props;
  return (
    <div className="CurrentOrderItem">
      <Typography variant="subtitle2" className="CurrentOrderItemName" color="grey.main">
        {name}
      </Typography>
      <Typography variant="subtitle2" className="CurrentOrderItemQuantity">
        {quantity}
      </Typography>
      <div className="CurrentOrderItemPrice">
        <Currency typographyVariant="subtitle2" value={price} />
      </div>
    </div>
  );
}

CurrentOrderItem.propTypes = {
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
};

export default CurrentOrderItem;
