import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import Spinner from '../Spinner';

function PaymentSpinner(props) {
  const { show } = props;
  return (
    <Modal className="modal-small" show={show}>
      <Modal.Body className="kale">
        <Spinner />
        <p>Waiting for payment details...</p>
        <p>Please do not close this page.</p>
      </Modal.Body>
    </Modal>
  );
}

PaymentSpinner.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default PaymentSpinner;
