import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import favoriteMenuItemShape from '../../shapes/favoriteMenuItemShape';
import menuItemShape from '../../shapes/menuItemShape';

import Image from '../../containers/Image';
import Button from '../Button';

import './FavoriteMenuItem.css';

function FavoriteMenuItem(props) {
  const {
    className,
    data,
    menuItem,
    ingredientsText,
    addedIngredientsText,
    removedIngredientsText,
    onRemove,
    onEditFavoriteOpen,
  } = props;

  return (
    <ul className={classnames(className, 'FavoriteMenuItem')}>
      <li className="Image">
        <Image id={menuItem.image} />
      </li>
      <li className="Details">
        <Typography variant="subtitle2" color="grey.main" fontWeight="700">
          {data.name}
          <Button className="EditName" fa="pencil" onClick={onEditFavoriteOpen} />
        </Typography>
        {!!ingredientsText && (
          <Typography variant="body2" color="grey.main" className="IngredientsText">
            <strong>Ingredients:</strong> {ingredientsText}
          </Typography>
        )}
        {addedIngredientsText && (
          <Typography variant="body2" color="grey.main" className="AddedIngredientsText">
            <strong>Added:</strong> {addedIngredientsText}
          </Typography>
        )}
        {removedIngredientsText && (
          <Typography variant="body2" color="grey.main" className="RemovedIngredientsText">
            <strong>Removed:</strong> {removedIngredientsText}
          </Typography>
        )}
      </li>
      <li className="Toolbar">
        <Button fa="trash" onClick={onRemove} />
      </li>
    </ul>
  );
}

FavoriteMenuItem.propTypes = {
  className: PropTypes.string,
  data: favoriteMenuItemShape.isRequired,
  menuItem: menuItemShape.isRequired,
  ingredientsText: PropTypes.string.isRequired,
  addedIngredientsText: PropTypes.string,
  removedIngredientsText: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  onEditFavoriteOpen: PropTypes.func.isRequired,
};

FavoriteMenuItem.defaultProps = {
  className: '',
  addedIngredientsText: null,
  removedIngredientsText: null,
};

export default FavoriteMenuItem;
