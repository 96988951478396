import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { setPageScene } from '../../actions/page';
import sceneEnum from '../../enums/sceneEnum';
import * as selectors from '../../sagas/selectors';

import useDeviceDetect from '../../hooks/useDeviceDetect';
import DesktopTooltip from '../DesktopTooltip/DesktopTooltip';
import DismissScanTooltip from './DismissScanTooltip/DismissScanTooltip';
import LoyaltyTab from './LoyaltyTab';

import IconLoyaltyLight from '../../images/icon-loyalty-light.svg';
import IconLoyaltyWhite from '../../images/icon-loyalty-white.svg';
import IconOrderLight from '../../images/icon-order-light.svg';
import IconOrderWhite from '../../images/icon-order-white.svg';
import IconScanLight from '../../images/icon-scan-light.svg';
import IconScanWhite from '../../images/icon-scan-white.svg';

import './LoyaltyTabs.css';

// @todo: Change the mapping
const LoyaltyTabIconMapping = {
  loyalty: {
    active: IconLoyaltyWhite,
    inactive: IconLoyaltyWhite,
  },
  order: {
    active: IconOrderWhite,
    inactive: IconOrderWhite,
  },
  scanReceipt: {
    active: IconScanWhite,
    inactive: IconScanWhite,
  },
};

const LoyaltyBarIconMapping = {
  loyalty: {
    active: IconLoyaltyLight,
    inactive: IconLoyaltyWhite,
  },
  order: {
    active: IconOrderLight,
    inactive: IconOrderWhite,
  },
  scanReceipt: {
    active: IconScanLight,
    inactive: IconScanWhite,
  },
};

function LoyaltyTabs({ type }) {
  const scene = useSelector(selectors.getCurrentScene);
  const isLoyaltyEnabled = useSelector(selectors.getIsLoyaltyEnabled);
  const isLoyaltyTabsHidden = useSelector(selectors.getIsLoyaltyTabsHidden);
  const { isDesktop } = useDeviceDetect();

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const iconMapping = type === 'tab' ? LoyaltyTabIconMapping : LoyaltyBarIconMapping;

  if (!isLoyaltyEnabled || isLoyaltyTabsHidden) {
    return null;
  }

  const isOnOtherScene = (() => {
    if (/account/.test(pathname)) return true;
    return false;
  })();

  return (
    <div className={classNames('LoyaltyTabs', type)}>
      <LoyaltyTab
        isSelected={!isOnOtherScene && scene === sceneEnum.LOYALTY}
        activeIcon={iconMapping.loyalty.active}
        inactiveIcon={iconMapping.loyalty.inactive}
        title="Rewards"
        onSelect={() => dispatch(setPageScene(sceneEnum.LOYALTY))}
      />
      <LoyaltyTab
        className="order"
        isSelected={!isOnOtherScene && scene === sceneEnum.ORDER}
        activeIcon={iconMapping.order.active}
        inactiveIcon={iconMapping.order.inactive}
        title="Order"
        onSelect={() => dispatch(setPageScene(sceneEnum.ORDER))}
      />
      <DesktopTooltip title={<DismissScanTooltip />}>
        <LoyaltyTab
          isSelected={!isOnOtherScene && scene === sceneEnum.SCAN_RECEIPT}
          isDisabled={type === 'bar' && isDesktop}
          activeIcon={iconMapping.scanReceipt.active}
          inactiveIcon={iconMapping.scanReceipt.inactive}
          title="Scan"
          onSelect={() => dispatch(setPageScene(sceneEnum.SCAN_RECEIPT))}
        />
      </DesktopTooltip>
    </div>
  );
}

LoyaltyTabs.propTypes = {
  type: PropTypes.oneOf(['tab', 'bar']),
};

LoyaltyTabs.defaultProps = {
  type: 'tab',
};

export default LoyaltyTabs;
