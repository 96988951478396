import PropTypes from 'prop-types';

const personalSettingsShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  chopping: PropTypes.string,
  addition: PropTypes.string,
  preferences: PropTypes.objectOf(PropTypes.number),
});

export default personalSettingsShape;
