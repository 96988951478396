import React from 'react';
import PropTypes from 'prop-types';

import LinearIcon from '../../LinearIcon';

import './CyoAlertText.css';

function CyoAlertText(props) {
  const { children, onInit } = props;

  return (
    <div className="cyo-alert-text" ref={onInit}>
      <p>
        <LinearIcon name="warning" /> {children}
      </p>
    </div>
  );
}

CyoAlertText.propTypes = {
  children: PropTypes.node,
  onInit: PropTypes.func,
};

CyoAlertText.defaultProps = {
  children: '',
  onInit: undefined,
};

export default CyoAlertText;
