import React from 'react';
import { FormattedNumber } from 'react-intl';
import PropTypes from 'prop-types';

import iconLocale from '../../images/icon-locale-grey.svg';

function Distance(props) {
  const { children, meters, showIcon } = props;
  return (
    <span className="Distance">
      {showIcon && <img className="DistanceIcon" src={iconLocale} alt="Distance" />}
      &nbsp;
      <FormattedNumber
        minimumFractionDigits={1}
        maximumFractionDigits={1}
        value={meters / 1000}
      />{' '}
      km
      {children}
    </span>
  );
}

Distance.propTypes = {
  children: PropTypes.node,
  showIcon: PropTypes.bool,
  meters: PropTypes.number.isRequired,
};

Distance.defaultProps = {
  children: null,
  showIcon: true,
};

export default Distance;
