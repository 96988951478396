import React from 'react';
import PropTypes from 'prop-types';

import './SignIn.css';

function SignIn(props) {
  const { children } = props;
  return (
    <div className="SignInWrapper iconBackground">
      <div className="SignIn">{children}</div>
    </div>
  );
}

SignIn.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SignIn;
