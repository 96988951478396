import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import * as foodActions from '../../actions/food';
import * as selectors from '../../sagas/selectors';
import { getIngredientLevelTags, sortDietTags, tagTypeNameMapping } from '../../utils/foodUtils';

import Button from '../Button';

import { ReactComponent as IconClose } from '../../images/icon-close.svg';

import './DietTags.css';

function DietTags({ onlyIngredientLevelTags }) {
  const tags = useSelector(selectors.getTags);
  const highlightedTagIds = useSelector(selectors.getHighlightedTagIds);
  const filteredTags = onlyIngredientLevelTags ? getIngredientLevelTags(tags) : tags;

  const dispatch = useDispatch();

  const onDietTagRemove = (id) => {
    dispatch(foodActions.removeHighlightedTagId(id));
    dispatch(foodActions.saveHighlightedTagIds());
  };

  return (
    <>
      {Object.values(filteredTags)
        .sort(sortDietTags)
        .filter(({ id }) => highlightedTagIds.includes(id))
        .map(({ id, name, tagType }) => (
          <div key={id} className={`DietTag ${tagTypeNameMapping(tagType)}`}>
            <span>{name}</span>
            <Button onClick={() => onDietTagRemove(id)}>
              <IconClose />
            </Button>
          </div>
        ))}
    </>
  );
}

DietTags.propTypes = {
  onlyIngredientLevelTags: PropTypes.bool,
};

DietTags.defaultProps = {
  onlyIngredientLevelTags: true,
};

export default DietTags;
