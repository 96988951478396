export const WITH_TOKEN = 'WITH_TOKEN';
export const WITH_PAYMENT_TOKEN = 'WITH_PAYMENT_TOKEN';

export const API_CALL = 'API_CALL';

export const RESET_STATUS = 'RESET_STATUS';
export const CLEAR_API_ENDPOINT_ERRORS = 'CLEAR_API_ENDPOINT_ERRORS';

export const UPDATE_STORE_BUSY_FROM_PUSHER = 'UPDATE_STORE_BUSY_FROM_PUSHER';

export const UPDATE_DISABLED_INGREDIENT_FROM_PUSHER = 'UPDATE_DISABLED_INGREDIENT_FROM_PUSHER';

export const LOAD_NEXT_ORDERS_PAGE = 'LOAD_NEXT_ORDERS_PAGE';
export const LOAD_NEXT_FAVORITE_MENU_ITEMS_PAGE = 'LOAD_NEXT_FAVORITE_MENU_ITEMS_PAGE';

export const withToken = (request) => ({
  type: WITH_TOKEN,
  request,
});

export const withPaymentToken = (request) => ({
  type: WITH_PAYMENT_TOKEN,
  request,
});

export const resetStatus = ({ reducer, field }) => ({
  type: RESET_STATUS,
  reducer,
  field,
});

export const updateStoreBusyFromPusher = ({ operation, object }) => ({
  type: UPDATE_STORE_BUSY_FROM_PUSHER,
  operation,
  object,
});

export const updateDisabledIngredientFromPusher = ({ operation, ingredient }) => ({
  type: UPDATE_DISABLED_INGREDIENT_FROM_PUSHER,
  operation,
  ingredient,
});

export const loadNextOrdersPage = (page = null) => ({
  type: LOAD_NEXT_ORDERS_PAGE,
  page,
});

export const loadNextFavoriteMenuItemsPage = (page = null) => ({
  type: LOAD_NEXT_FAVORITE_MENU_ITEMS_PAGE,
  page,
});

export const clearApiEndpointErrors = (endpointName) => ({
  type: CLEAR_API_ENDPOINT_ERRORS,
  endpointName,
});
