import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import Modal from '../Modal';

import './InvalidTokenError.css';

function InvalidTokenError({ isModalOpen, onRelogin }) {
  return (
    <Modal className="InvalidTokenError" show={isModalOpen} onHide={() => {}}>
      <div className="modal-control" />
      <Modal.Body className="kale">
        <div className="content-top">
          <div>
            <p>Your session has expired. Please sign in again.</p>
          </div>
        </div>
        <div className="Buttons">
          <Button className="btn-primary" onClick={onRelogin}>
            Relogin
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

InvalidTokenError.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onRelogin: PropTypes.func.isRequired,
};

export default InvalidTokenError;
