import PropTypes from 'prop-types';

const tagShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  includeGroups: PropTypes.arrayOf(PropTypes.number).isRequired,
  excludeGroups: PropTypes.arrayOf(PropTypes.number).isRequired,
  typeType: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  kitchenSortOrder: PropTypes.number.isRequired,
});

export default tagShape;
