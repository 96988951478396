import { all, put } from 'redux-saga/effects';

import * as geolocationActions from '../actions/geolocation';
import { takeBrowserCheckPassed } from '../utils/browserUtils';
import { takeEvery } from '../utils/sagaUtils';

import geolocationService from '../services/geolocation';

function* geolocate() {
  const { response, error } = yield geolocationService();

  if (response) {
    yield put(geolocationActions.geolocationSuccess({ coordinates: response }));
  } else if (error) {
    yield put(geolocationActions.geolocationError());
  }
}

function* watchGeolocation() {
  yield takeEvery(geolocationActions.GEOLOCATION_REQUESTED, geolocate);
}

function* watchBrowserCheck() {
  yield takeBrowserCheckPassed(geolocate);
}

export default function* geolocationSaga() {
  yield all([watchGeolocation(), watchBrowserCheck()]);
}
