import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import statusEnum from '../../enums/statusEnum';

import OrderStatusModal from '../../components/OrderStatusModal';

class OrderStatusModalContainer extends Component {
  static propTypes = {
    orderStatus: PropTypes.number.isRequired,
    orderPreviewStatus: PropTypes.number.isRequired,
  };

  get isOrderPriceLoading() {
    const { orderPreviewStatus } = this.props;

    return [statusEnum.REQUESTED, statusEnum.RELOADING].includes(orderPreviewStatus);
  }

  get isOrderPriceLoaded() {
    const { orderPreviewStatus } = this.props;

    return orderPreviewStatus === statusEnum.SUCCESS;
  }

  get isOrderAwaiting() {
    const { orderStatus } = this.props;

    return orderStatus === statusEnum.AWAITING;
  }

  get isOrderRequested() {
    const { orderStatus } = this.props;

    return orderStatus === statusEnum.REQUESTED;
  }

  render() {
    return (
      <OrderStatusModal
        show={this.isOrderAwaiting || this.isOrderRequested}
        showAwaitPriceSpinner={this.isOrderAwaiting && this.isOrderPriceLoading}
        showCountdown={this.isOrderAwaiting && this.isOrderPriceLoaded}
        showSpinner={this.isOrderRequested}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  orderStatus: state.order.orderStatus,
  orderPreviewStatus: state.order.status.orderPreview,
});

export default connect(mapStateToProps, undefined)(OrderStatusModalContainer);
