import { isNumber } from 'lodash';
import moment from 'moment';

export const getValidRewards = ({ rewards }) => {
  const now = moment();

  return rewards.filter((reward) => {
    if (reward.rewardStart && now.isBefore(reward.rewardStart)) {
      return false;
    }

    if (reward.rewardEnd && now.isAfter(reward.rewardEnd)) {
      return false;
    }

    if (reward.isValid && reward.isValid === false) {
      return false;
    }

    return true;
  });
};

export const getSpecialRewards = ({ rewards }) => {
  const validRewards = getValidRewards({ rewards });
  return validRewards.filter((reward) => reward.isSpecial === true);
};

export const getValidPromos = ({ promos }) => {
  const now = moment();

  return promos.filter((promo) => {
    if (promo.rewardEnd && now.isAfter(promo.rewardEnd)) {
      return false;
    }

    if (promo.isValid && promo.isValid === false) {
      return false;
    }

    return true;
  });
};

export const getValidClaims = ({ claims }) => {
  const now = moment();

  return claims.filter((claim) => !claim.claimed && now.isSameOrBefore(claim.expiry));
};

export const getEverydayClaims = ({ claims }) =>
  claims.filter((claim) => claim.rewardSourceType && claim.rewardSourceType !== 'special');
export const getSpecialClaims = ({ claims }) =>
  claims.filter((claim) => claim.rewardSourceType && claim.rewardSourceType === 'special');

export const getCollectionSteps = (collection) => {
  if (!collection?.secondary) return [];
  if (!isNumber(collection?.total)) return [];

  const steps = Object.keys(collection.secondary)
    .map((value) => (value / collection.total) * 100)
    .filter((value) => isNumber(value));

  if (steps.length > 0) {
    // Always add final step to the end if there's extra steps
    steps.push(100);
  }
  return steps;
};
