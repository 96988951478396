import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import moment from 'moment';

import gglocationTypeEnum from '../../../enums/gglocationTypeEnum';
import * as selectors from '../../../sagas/selectors';
import { optionalMinuteMomentFormat } from '../../../utils/commonUtils';

import './TimeSlotLabel.css';

function TimeSlotLabel() {
  const gglocation = useSelector(selectors.getOrderGglocation);
  const orderTime = useSelector(selectors.getOrderOrderTime);
  const diningChoice = useSelector(selectors.getDiningChoice);
  const stationTimeInterval = useSelector(selectors.getDefaultStationTimeInterval);

  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    if (gglocation && gglocation.gglocationType === gglocationTypeEnum.PARTNER) {
      const timeSlotsOnOrderTime =
        gglocation.timeSlotsOnSameDay({
          chosenDay: orderTime,
          diningChoice,
        }) ?? [];
      setTimeSlots(timeSlotsOnOrderTime);
    } else {
      setTimeSlots([]);
    }
  }, [diningChoice, gglocation, orderTime]);

  if (timeSlots.length === 0) return null;

  return (
    <div className="TimeSlotLabel">
      {timeSlots.map((timeSlot) => {
        const startTime = optionalMinuteMomentFormat(moment(timeSlot.datetime));
        const endTime = optionalMinuteMomentFormat(
          moment(timeSlot.datetime).add(stationTimeInterval, 'minutes'),
        );
        const cutoffTime = gglocation.getCutoffTime({ diningChoice });
        const placeTime = optionalMinuteMomentFormat(
          moment(timeSlot.datetime).subtract(cutoffTime, 'minutes'),
        );
        return (
          <Typography key={timeSlot.id} className="TimeSlotLabelLine" variant="subtitle2">
            Order by <span>{placeTime}</span> for {startTime}-{endTime} pickup
          </Typography>
        );
      })}
    </div>
  );
}

export default TimeSlotLabel;
