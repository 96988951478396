import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as orderItemActions from '../../actions/orderItem';
import * as pageActions from '../../actions/page';
import * as selectors from '../../sagas/selectors';

import Button from '../Button';
import Modal from '../Modal';

function FavoriteMenuChangedModal() {
  const favMenuChangedId = useSelector(selectors.getFavMenuChangedId);
  const [menuId, setMenuId] = useState(null);
  const [isShowFavoriteMenuChangedModal, setIsShowFavoriteMenuChangedModal] = useState(false);

  const dispatch = useDispatch();
  const onModalHide = () => {
    dispatch(orderItemActions.updateFavMenuIsChanged({ id: menuId, isChanged: false }));
    dispatch(orderItemActions.resetFavMenuChangedId());

    dispatch(pageActions.openMenuItemPage());
  };

  useEffect(() => {
    if (favMenuChangedId) {
      setMenuId(favMenuChangedId);
      setIsShowFavoriteMenuChangedModal(true);
    } else {
      setIsShowFavoriteMenuChangedModal(false);
      setMenuId(favMenuChangedId);
    }
  }, [favMenuChangedId]);

  return (
    <Modal className="DietModal" show={isShowFavoriteMenuChangedModal}>
      <Modal.Body className="kale">
        <div className="diet-support-list">
          Some ingredients might have changed or are no longer available.
        </div>
        <div className="DietModalButtonRow">
          <Button className="btn-secondary" onClick={onModalHide}>
            Next
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FavoriteMenuChangedModal;
