import React, { useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as selectors from '../../sagas/selectors';
import * as foodUtils from '../../utils/foodUtils';

import useWindowSize from '../../hooks/useWindowSize';
import Button from '../Button';
// eslint-disable-next-line import/no-cycle
import NutritionalModal from '../NutritionalModal';

import { MOBILE_PREVIEW_NUTRIENTS } from '../../settings';

import './NutritionalInfo.css';

function NutritionalInfo({ ingredientId, bulkQuantity, showPreview }) {
  const { nutrientsRoundingSettings } = useSelector(selectors.getApiNutritionalSettings);

  const nutrients = useSelector(selectors.getNutrients);
  const sortedNutrients = foodUtils.sortedNutrients(nutrients);
  const nonCarbonFootprintNutrients = sortedNutrients.filter(nutrient => nutrient.isCarbonFootprint === false);

  const nutrientAmounts = useSelector((state) =>
    selectors.selectIngredientNutrientAmounts(state, ingredientId),
  );

  const { menuItemId } = useSelector(selectors.getOrderItem);
  const menuItem = useSelector((state) => selectors.selectMenuItem(state, menuItemId));

  const [isNutritionModalOpen, setIsNutritionModalOpen] = useState(false);

  const { width } = useWindowSize();
  const isMobile = width < 767;

  if (isMobile && !showPreview) {
    return (
      <>
        <div className="NutrientsList ViewNutritionalInfoRow">
          <Button onClick={() => setIsNutritionModalOpen(true)}>
            <Typography variant="subtitle2" color="primary" fontWeight="600">
              View nutritional information
            </Typography>
          </Button>
        </div>
        <NutritionalModal
          name={menuItem?.name}
          show={isNutritionModalOpen}
          onHide={() => setIsNutritionModalOpen(false)}
          isMobile={isMobile}
        />
      </>
    );
  }

  return (
    <div className="NutrientsList">
      {nonCarbonFootprintNutrients.map(nutrient => (
        <ul
          key={nutrient.id}
          className={classnames({
            visibleOnMobile: MOBILE_PREVIEW_NUTRIENTS.includes(nutrient.id),
          })}
        >
          <li className="NutrientName">
            <Typography variant="subtitle2">{nutrient.name}</Typography>
          </li>
          <li className="NutrientValue">
            <Typography variant="subtitle2" color="grey.main">
              <FormattedNumber
                value={nutrientAmounts[nutrient.id] * bulkQuantity || 0}
                minimumFractionDigits={nutrientsRoundingSettings[nutrient.id]}
                maximumFractionDigits={nutrientsRoundingSettings[nutrient.id]}
              />
              <span className="NutrientUnit"> {nutrient.measurementUnit}</span>
            </Typography>
          </li>
        </ul>
      ))}
    </div>
  );
}

NutritionalInfo.propTypes = {
  ingredientId: PropTypes.number,
  bulkQuantity: PropTypes.number,
  showPreview: PropTypes.bool,
};

NutritionalInfo.defaultProps = {
  ingredientId: undefined,
  bulkQuantity: 1,
  showPreview: true,
};

export default NutritionalInfo;
