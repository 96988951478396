import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ingredientShape from '../../shapes/ingredientShape';
import personalSettingShape from '../../shapes/personalSettingShape';

import Image from '../../containers/Image';
import OrderItemPrice from '../../containers/OrderItemPrice';
import Button from '../Button';
import CustomizeLeft from '../CustomizeLeft';
import CustomizeRight from '../CustomizeRight';
import FontAwesome from '../FontAwesome';
import NutritionalBlock from '../NutritionalBlock';
import PersonalizeOrderItem from './PersonalizeOrderItem';
import QuantitySelector from './QuantitySelector';

import './Personalize.css';

function Personalize(props) {
  const {
    menuItemId,
    baseId,
    image,
    name,
    ingredients,
    ingredientsText,
    personalSettings,
    premade,
    isMobile,
    isOrderPreviewRequested,
    onQualityDecrement,
    onQualityIncrement,
    onPersonalizeConfirm,
  } = props;

  return (
    <div className={classnames('Personalize', `PersonalizeBase-${baseId}`)}>
      <CustomizeLeft>
        <div
          className={classnames({
            NotPremadeItem: !premade,
          })}
        >
          <div className="MenuDetailImage">
            <Image id={image} context="desktopX2" oneSize />
          </div>
          <div
            className={classnames({
              NutritionalBlockDisplayNone: premade,
            })}
          >
            <NutritionalBlock hideTabs={premade} isMobile={isMobile} />
          </div>
        </div>
      </CustomizeLeft>
      <CustomizeRight>
        <div className="MenuContentFrame">
          <div className="PersonalizeInfo section kale">
            <Accordion
              className="PersonalizeIngredientsAccordion"
              defaultExpanded={!isMobile}
              disabled={!isMobile}
            >
              <AccordionSummary expandIcon={isMobile && <FontAwesome name="caret-down" />}>
                <Typography variant="h4" color="secondary.dark" className="SharpGroteskSmBold15">
                  INGREDIENTS {ingredients.length > 0 && !isMobile && <>({ingredients.length})</>}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <p className="IngredientsText">{ingredientsText}</p>
                {ingredients &&
                  premade &&
                  ingredients.map((item) => (
                    <p key={item.id} className="IngredientDescription">
                      {item.desc}
                    </p>
                  ))}
              </AccordionDetails>
            </Accordion>
          </div>
          {personalSettings &&
            personalSettings.map((personalSetting, index) => (
              <PersonalizeOrderItem
                key={personalSetting.id}
                id={personalSetting.id}
                index={index}
                name={name}
                showSeparator={personalSettings.length > 1}
              />
            ))}
        </div>
        <QuantitySelector
          value={personalSettings ? personalSettings.length : 0}
          onIncrement={onQualityDecrement}
          onDecrement={onQualityIncrement}
        />
        <div className="ActionButton">
          <Button
            className="Split btn-next"
            loading={isOrderPreviewRequested}
            onClick={onPersonalizeConfirm}
          >
            <span className="Text">
              <span>Add to cart</span>
              <span className="AttentionSeeker">
                <OrderItemPrice menuItemId={menuItemId} />
              </span>
            </span>
          </Button>
        </div>
      </CustomizeRight>
    </div>
  );
}

Personalize.propTypes = {
  menuItemId: PropTypes.number,
  baseId: PropTypes.number,
  image: PropTypes.number,
  name: PropTypes.string.isRequired,
  ingredients: PropTypes.arrayOf(ingredientShape).isRequired,
  ingredientsText: PropTypes.string.isRequired,
  personalSettings: PropTypes.arrayOf(personalSettingShape).isRequired,
  premade: PropTypes.bool,
  isMobile: PropTypes.bool,
  isOrderPreviewRequested: PropTypes.bool.isRequired,
  onQualityDecrement: PropTypes.func.isRequired,
  onQualityIncrement: PropTypes.func.isRequired,
  onPersonalizeConfirm: PropTypes.func.isRequired,
};

Personalize.defaultProps = {
  menuItemId: null,
  baseId: null,
  image: null,
  premade: false,
  isMobile: false,
};

export default Personalize;
