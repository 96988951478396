import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as CloseEyeLogo } from './close-eye.svg';
import { ReactComponent as OpenEyeLogo } from './open-eye.svg';

import './PasswordInput.css';

function PasswordInput({ className, inputClassName, placeholder, password, onPasswordChange }) {
  const [isRevealPassword, setIsRevealPassword] = useState(false);

  const handleRevealPassword = () => setIsRevealPassword((prevState) => !prevState);

  return (
    <div className={classnames('PasswordInput', className)}>
      <input
        className={classnames('form-register', inputClassName)}
        type={isRevealPassword ? 'text' : 'password'}
        placeholder={placeholder}
        value={password}
        onChange={onPasswordChange}
      />
      {isRevealPassword ? (
        <CloseEyeLogo onClick={handleRevealPassword} />
      ) : (
        <OpenEyeLogo onClick={handleRevealPassword} />
      )}
    </div>
  );
}

PasswordInput.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  password: PropTypes.string.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
};

PasswordInput.defaultProps = {
  className: '',
  inputClassName: '',
  placeholder: 'Your password',
};

export default PasswordInput;
