import { call, cancel, fork, take } from 'redux-saga/effects';

import * as Sentry from '../services/sentry';

function* catchErrors(saga, { showErrors }, ...callbackArgs) {
  try {
    yield call(saga, ...callbackArgs);
  } catch (error) {
    Sentry.captureException(error);

    if (showErrors) {
      window.dispatchEvent(new CustomEvent('error', { detail: { error } }));
    }
  }
}

export const takeEvery = (patternOrChannel, saga, options = {}, ...args) =>
  fork(function* effectCreator() {
    while (true) {
      const action = yield take(patternOrChannel);
      yield fork(catchErrors, saga, options, ...args.concat(action));
    }
  });

export const takeLatest = (patternOrChannel, saga, options = {}, ...args) =>
  fork(function* effectCreator() {
    let lastTask;

    while (true) {
      const action = yield take(patternOrChannel);
      if (lastTask) {
        yield cancel(lastTask);
      }

      lastTask = yield fork(catchErrors, saga, options, ...args.concat(action));
    }
  });

export const takeLeading = (patternOrChannel, saga, options = {}, ...args) =>
  fork(function* effectCreator() {
    while (true) {
      const action = yield take(patternOrChannel);
      yield call(catchErrors, saga, options, ...args.concat(action));
    }
  });
