import {
  GEOLOCATION_ERROR,
  GEOLOCATION_REQUESTED,
  GEOLOCATION_SUCCESS,
} from '../actions/geolocation';
import statusEnum from '../enums/statusEnum';

const defaultState = Object.freeze({
  status: statusEnum.INITIAL,
  coordinates: null,
});

// eslint-disable-next-line default-param-last
export default function geolocationReducer(state = defaultState, action) {
  switch (action.type) {
    case GEOLOCATION_REQUESTED: {
      return Object.freeze({
        ...state,
        status: statusEnum.REQUESTED,
      });
    }
    case GEOLOCATION_SUCCESS: {
      return Object.freeze({
        status: statusEnum.SUCCESS,
        coordinates: action.coordinates,
      });
    }
    case GEOLOCATION_ERROR: {
      return Object.freeze({
        ...state,
        status: statusEnum.ERROR,
      });
    }
    default:
      return state;
  }
}
