import React, { Component } from 'react';
import PropTypes from 'prop-types';

import userOrderItemShape from '../../../shapes/userOrderItemShape';

import NameFavorite from '../../../components/Order/NameFavorite';

class NameFavoriteContainer extends Component {
  static propTypes = {
    favoriteOrderItem: userOrderItemShape.isRequired,
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { favoriteOrderItem } = this.props;

    if (favoriteOrderItem && favoriteOrderItem !== prevProps.favoriteOrderItem) {
      this.setState({ name: favoriteOrderItem.name });
    }
  }

  handleNameChange = (event) => {
    const name = event.target.value;

    this.setState({ name });
  };

  handleSave = () => {
    const { favoriteOrderItem, onSave } = this.props;
    const { name } = this.state;

    if (!name) {
      onSave(favoriteOrderItem, favoriteOrderItem.menu.name);
    } else {
      onSave(favoriteOrderItem, name);
    }
  };

  render() {
    const { favoriteOrderItem, onHide } = this.props;
    const { name } = this.state;

    return (
      <NameFavorite
        favoriteOrderItem={favoriteOrderItem}
        name={name}
        isButtonHidden
        onNameChange={this.handleNameChange}
        onHide={onHide}
        onSave={this.handleSave}
      />
    );
  }
}

export default NameFavoriteContainer;
