import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import rewardShape from '../../shapes/rewardShape';

import Button from '../Button';
import MomentCalendar from '../MomentCalendar';

import './LoyaltyReward.css';

function LoyaltyReward(props) {
  const { size, reward, onRedeem } = props;

  let name = '';
  let validUntil = null;

  if (reward.isMassReward) {
    name = reward.title;
    if (reward.validTo) {
      validUntil = reward.validTo;
    } else {
      // Get the current moment
      const currentDate = moment();

      // Add N days to the current moment
      validUntil = currentDate.add(reward.validFor, 'days');
    }
  } else {
    name = reward.claimName;
    validUntil = reward.validUntil;
  }

  return (
    <div className="LoyaltyRewardWrapper">
      <div className={classnames('LoyaltyReward', 'kale', size)}>
        <div className="LoyaltyRewardBody">
          <Typography variant="subtitle2" className="Name" color="grey.main" fontWeight="600">
            {name}
          </Typography>
          {validUntil && (
            <Typography variant="body2" className="Validity" color="grey.main">
              Valid until: <MomentCalendar>{validUntil}</MomentCalendar>
            </Typography>
          )}
        </div>
        <Button className="LoyaltyRewardButton btn-primary" onClick={onRedeem}>
          Redeem
        </Button>
      </div>
    </div>
  );
}

LoyaltyReward.propTypes = {
  size: PropTypes.oneOf(['Small', 'Medium']).isRequired,
  reward: rewardShape.isRequired,
  onRedeem: PropTypes.func.isRequired,
};

export default LoyaltyReward;
