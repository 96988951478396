import { parseBackendIngredientServings } from '@spq/redux-api-client-ecom';
import { chain, flatten, isEqual, sortBy, toPairs } from 'lodash';
import md5 from 'md5';

import * as foodUtils from './foodUtils';

export const isOrderItemMenuItemMissing = (orderItem) => orderItem.menuItemId === undefined;

export const isOrderItemIngredientDisabled = ({ orderItem, disabledIngredients }) => {
  /* If there are no disabled ingredients for gglocation, return false */
  if (!disabledIngredients || !orderItem.selectedIngredientServings) {
    return false;
  }

  /* Check if there is an overlap between selected ingredients and disabled ingredients */
  return Object.keys(orderItem.selectedIngredientServings).some((ingd) =>
    disabledIngredients.map((disabledIngd) => disabledIngd.toString()).includes(ingd),
  );
};

export const isOrderItemIngredientMissing = (orderItem) =>
  orderItem.missingIngredientServings &&
  Object.keys(orderItem.missingIngredientServings).length > 0;

export const isOrderItemValid = (orderItem) => {
  if (isOrderItemMenuItemMissing(orderItem)) return false;
  if (isOrderItemIngredientMissing(orderItem)) return false;

  return true;
};

export const determineOrderItemBreakdown = (orderItem, orderMenu) => {
  const item = orderMenu.find(
    (menu) =>
      isEqual(
        orderItem.highlightedIngredientServings,
        parseBackendIngredientServings(menu.menu.ingredients),
      ) && menu.menu.id === orderItem.apiId,
  );

  return item.menu.ingredientBreakdown;
};

/*
  Checks wether order item matches the query.

  Arguments:
  * query
    * selectedBaseType
    * selectedSectionIds
    * selectedCategoryIds
    * selectedIngredientIds

    All query parameters are optional.

  Returns:
  * true if all query parameters are matched.
  * false otherwise
*/
export const doesOrderItemMatchQuery = ({ orderItem, query }, food) => {
  const { menuItems, ingredients, ingredientCategories } = food;
  const orderItemMenuItem = menuItems[orderItem.menuItemId];

  if (query.selectedBaseTypeId) {
    if (orderItemMenuItem.baseType !== query.selectedBaseTypeId) {
      return false;
    }
  }

  const ingredientsQuery = {
    selectedSectionIds: query.selectedSectionIds,
    selectedCategoryIds: query.selectedCategoryIds,
    selectedIngredientIds: query.selectedIngredientIds,
  };

  const matchedIngredientServings = foodUtils.filterIngredientServings(
    ingredients,
    orderItem.selectedIngredientServings,
    ingredientsQuery,
    { ingredientCategories },
  );

  if (Object.keys(matchedIngredientServings).length === 0) {
    return false;
  }

  return true;
};

/*
  Checks wether order item matches some or every query.

  Arguments:
    * queries is an array of query objects with the given parameters
      * selectedBaseType
      * selectedSectionIds
      * selectedCategoryIds
      * selectedIngredientIds

      All query parameters are optional.

  Returns:
    * true if order item matches some/every query
    * false otherwise
*/
export const doesOrderItemMatchSomeQuery = ({ orderItem, queries }, food) =>
  queries.length === 0 ||
  queries.some((query) => doesOrderItemMatchQuery({ orderItem, query }, food));

export const doesOrderItemMatchEveryQuery = ({ orderItem, queries }, food) =>
  queries.every((query) => doesOrderItemMatchQuery({ orderItem, query }, food));

const menuItemIngredientServingsHash = ({ menuItemId, ingredientServingsPairs }) => {
  const stringifiedIngredientServings = flatten(
    sortBy(ingredientServingsPairs, (pair) => parseFloat(pair[0])),
  );

  return md5(`${menuItemId}${stringifiedIngredientServings}`);
};

export const calculateOrderPreviewItemHash = (orderPreviewItem) => {
  const ingredientServingsPairs = orderPreviewItem.menu.ingredients.map((ingredient) => [
    ingredient.ingredient,
    parseFloat(ingredient.serving),
  ]);

  return menuItemIngredientServingsHash({
    menuItemId: orderPreviewItem.menu.id,
    ingredientServingsPairs,
  });
};

export const calculateOrderItemHash = (orderItem, prefix = 'highlighted') => {
  const ingredientServingsPairs = toPairs(orderItem[`${prefix}IngredientServings`]);

  return menuItemIngredientServingsHash({
    menuItemId: orderItem.apiId,
    ingredientServingsPairs,
  });
};

export const calculatePriceDelta = ({
  ingredientBreakdown,
  highlightedIngredientServings,
  selectedIngredientServings,
  quantity = 1,
}) =>
  chain(ingredientBreakdown)
    .reduce(
      (result, item) => {
        const serving =
          highlightedIngredientServings[item.ingredient] -
          (selectedIngredientServings[item.ingredient] || 0);
        if (item.direction === 'addon') {
          return {
            price: result.price + item.singlePrice * serving * item.bulkQuantity,
            discountAmount: result.discountAmount + item.discountAmount,
            total: result.total + item.total * serving * item.bulkQuantity,
          };
        }
        return result;
      },
      {
        price: 0,
        discountAmount: 0,
        total: 0,
      },
    )
    .mapValues((value) => value * quantity)
    .value();

export const isOrderItemChanged = ({ menuItems, menuItemsId }) =>
  !!menuItems[menuItemsId].isChanged;
