import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as selectors from '../../../sagas/selectors';
import { isFetchNeeded } from '../../../utils/apiUtils';
import { getSearchParam } from '../../../utils/historyUtils';

import scanHistoryShape from '../../../shapes/scanHistoryShape';

import PastOrder from '../../../containers/PastOrder';
import Button from '../../Button';
import InfiniteScrollLoader from '../../InfiniteScrollLoader';
import RewardModal from '../../LoyaltyCollection/RewardModal';
import PastScan from '../../PastScan/PastScan';

import { loyaltyService } from '../../../services/api';

import './PastOrders.css';

function PastOrders({
  userOrderIds,
  hasMoreOrders,
  scanHistory,
  activePanel,
  onPanelSelect,
  onLoadMoreOrders,
  onOrderNow,
}) {
  const [searchParams] = useSearchParams();
  const isOrderTabInitShown = (getSearchParam(searchParams, 'tab') ?? 'orders') === 'orders';
  const [isOrderSelected, setIsOrderSelected] = useState(isOrderTabInitShown);

  const isLoyaltyTabsHidden = useSelector(selectors.getIsLoyaltyTabsHidden);
  const { scanHistory: scanHistoryStatus } = useSelector(selectors.getLoyaltyStatus);

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetching loyalty scan-history only when needed
    // as it is not force-fetched when rehydrate to protect loyalty backend performance
    if (isFetchNeeded(scanHistoryStatus)) {
      dispatch(loyaltyService.scanHistory.requestActionCreator());
    }
  }, [dispatch, scanHistoryStatus]);

  return (
    <div className="PastOrders">
      {isLoyaltyTabsHidden ? (
        <Typography variant="h3" color="secondary.light" className="AccountTitle">
          Past orders
        </Typography>
      ) : (
        <div className="tabsRow">
          <Button
            className={classnames('Button tabsButton', { selected: isOrderSelected })}
            onClick={() => setIsOrderSelected(true)}
          >
            <Typography variant="body1" fontWeight="800" lineHeight="1.2">
              Order History
            </Typography>
          </Button>
          <Button
            className={classnames('Button tabsButton', { selected: !isOrderSelected })}
            onClick={() => setIsOrderSelected(false)}
          >
            <Typography variant="body1" fontWeight="800" lineHeight="1.2">
              Rewards History
            </Typography>
          </Button>
        </div>
      )}
      {isOrderSelected ? (
        <div id="PastOrderListWrapper" className="PastOrderListWrapper">
          {userOrderIds.length > 0 ? (
            <InfiniteScroll
              dataLength={userOrderIds.length}
              next={onLoadMoreOrders}
              hasMore={hasMoreOrders}
              loader={<InfiniteScrollLoader />}
              scrollableTarget="PastOrderListWrapper"
            >
              {userOrderIds.map((userOrderId) => (
                <PastOrder
                  key={userOrderId}
                  userOrderId={userOrderId}
                  isOpen={activePanel === userOrderId}
                  onToggle={() => onPanelSelect(userOrderId)}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <div className="PastOrderEmptyContainer">
              <Typography variant="subtitle1">You haven&apos;t made any orders yet.</Typography>
              <Button className="btn-primary" onClick={onOrderNow}>
                Proceed to order
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className="PastScansWrapper">
          <RewardModal />
          {scanHistory.map((scan) => (
            <PastScan key={scan.ref} scan={scan} />
          ))}
        </div>
      )}
    </div>
  );
}

PastOrders.propTypes = {
  userOrderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  hasMoreOrders: PropTypes.bool.isRequired,
  activePanel: PropTypes.string,
  onPanelSelect: PropTypes.func.isRequired,
  scanHistory: PropTypes.arrayOf(scanHistoryShape).isRequired,
  onLoadMoreOrders: PropTypes.func.isRequired,
  onOrderNow: PropTypes.func.isRequired,
};

PastOrders.defaultProps = {
  activePanel: null,
};

export default PastOrders;
