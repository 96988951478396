import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { push, replace } from 'redux-first-history';

import * as loyaltyActions from '../../actions/loyalty';
import * as userActions from '../../actions/user';
import * as selectors from '../../sagas/selectors';
import { getSearchParam } from '../../utils/historyUtils';

import Button from '../Button';
import ErrorSticker from '../ErrorSticker';
import Modal from '../Modal';

import IconLoyaltyPink from '../../images/icon-loyalty-pink.svg';
import receiptIcon from '../../images/icon-receipt-red.svg';

import './ScanOrderNumberModal.css';

function ScanOrderNumberModal() {
  const dispatch = useDispatch();
  const location = useSelector(selectors.getCurrentLocation);
  const geolocation = useSelector(selectors.getGeolocation);
  const userToken = useSelector(selectors.getUserToken);
  const scanCodeError = useSelector((state) => selectors.getApiError(state, 'scanCode'));
  const code = getSearchParam(location.search, 'code');

  const [isScanCodeError, setIsScanCodeError] = useState(false);
  const [error, setError] = useState(null);

  const goToHome = () => {
    dispatch(replace({ pathname: '/' }));
  };

  useEffect(() => {
    if (!userToken) {
      dispatch(userActions.afterSignIn({ pathname: location.pathname, search: location.search }));
      dispatch(push({ pathname: '/signIn' }));
    } else {
      dispatch(loyaltyActions.dispatchScanCode({ newCode: code, geolocation }));
    }
  }, []);

  useEffect(() => {
    if (scanCodeError) {
      /* Set or clear api error */
      setIsScanCodeError(true);
      setError(scanCodeError?.error?.message);
    } else {
      setIsScanCodeError(false);
      setError(null);
    }
  }, [scanCodeError, isScanCodeError]);

  return (
    <div className="ScanSuccessPage">
      <Modal className="modal ScanSuccessModal" show>
        <Modal.Body>
          <div className="Content">
            {!isScanCodeError && (
              <>
                <Typography className="Title" variant="calafia1" color="primary">
                  Success!
                </Typography>
                <img src={IconLoyaltyPink} className="IconLoyalty" alt="IconLoyalty" />
                <Typography className="Text" variant="subtitle2" color="grey.main">
                  Your purchase has been credited to our rewards program but may take up to 24 hours
                  to appear.
                </Typography>
              </>
            )}
            {isScanCodeError && (
              <ErrorSticker icon={receiptIcon} title="Error processing receipt" noIcon>
                {error}
              </ErrorSticker>
            )}
            <Button className="btn-primary" onClick={goToHome}>
              Dismiss
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ScanOrderNumberModal;
