import React, { Component } from 'react';
import { connect } from 'react-redux';
import { chain } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderItemActions from '../../../actions/orderItem';
import * as foodUtils from '../../../utils/foodUtils';
import { getSectionSettings } from '../../../utils/settingsUtils';

import cyoSectionShape from '../../../shapes/cyoSectionShape';
import ingredientCategoryShape from '../../../shapes/ingredientCategoryShape';
import ingredientShape from '../../../shapes/ingredientShape';
import menuItemIngredientBreakdownShape from '../../../shapes/menuItemIngredientBreakdownShape';
import routerShape from '../../../shapes/routerShape';
import { baseTypeSettingsShape, sectionSettingsShape } from '../../../shapes/settingsShape';
import tagShape from '../../../shapes/tagShape';

import IngredientsSection from '../../../components/MenuDetails/IngredientsSection';
import withRouter from '../../WithRouter';

import { INGREDIENT_BASES } from '../../../settings';

class IngredientsSectionContainer extends Component {
  static propTypes = {
    menuItemId: PropTypes.number.isRequired,
    baseId: PropTypes.number.isRequired,
    sectionId: PropTypes.number.isRequired,
    globalIngredientRemovalLimit: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['cyo', 'premade']),
    size: PropTypes.oneOf(['Label', 'Medium', 'Small', 'ExtraSmall']),
    defaultIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    selectedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    selectedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    tags: PropTypes.objectOf(tagShape).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
    sections: PropTypes.objectOf(cyoSectionShape).isRequired,
    baseTypeSettings: PropTypes.objectOf(baseTypeSettingsShape).isRequired,
    sectionSettings: PropTypes.objectOf(sectionSettingsShape).isRequired,
    ingredientBreakdown: PropTypes.arrayOf(menuItemIngredientBreakdownShape).isRequired,
    addIngredientServings: PropTypes.func.isRequired,
    removeIngredient: PropTypes.func.isRequired,
    replaceSectionIngredients: PropTypes.func.isRequired,
    onLimitExhausted: PropTypes.func.isRequired,
    saveHighlightedIngredientServings: PropTypes.func.isRequired,

    router: routerShape.isRequired,
  };

  static defaultProps = {
    type: 'premade',
    size: undefined,
  };

  get sectionSettings() {
    const { baseId, sectionId, baseTypeSettings, sectionSettings } = this.props;

    return getSectionSettings({
      baseId,
      sectionId,
      baseTypeSettings,
      sectionSettings,
    });
  }

  get selectedIngredients() {
    const { sectionId, selectedIngredientServings, ingredients, ingredientCategories } = this.props;

    const selectedIngredientIds = foodUtils
      .ingredientIdsArray(selectedIngredientServings)
      .filter((ingredientId) => !INGREDIENT_BASES[ingredientId]);
    const query = { selectedIngredientIds, selectedSectionIds: [sectionId] };
    const food = { ingredientCategories };

    return foodUtils.filterIngredients(ingredients, query, food);
  }

  get disabledIngredientServings() {
    const { defaultIngredientServings, selectedIngredientServings } = this.props;

    const removedBasicIngredientIds = foodUtils
      .ingredientIdsArray(defaultIngredientServings)
      .filter((ingredientId) => selectedIngredientServings[ingredientId] === undefined)
      .filter((ingredientId) => this.dietDisabledIngredientServings[ingredientId] === undefined);

    const disabledIngredientServings = {};
    removedBasicIngredientIds.map((ingredientId) => {
      disabledIngredientServings[ingredientId] = defaultIngredientServings[ingredientId];
      return ingredientId;
    });

    return disabledIngredientServings;
  }

  get dietDisabledIngredientServings() {
    const { defaultIngredientServings, selectedTagIds, ingredients, tags } = this.props;

    const filteredIngredientServings = foodUtils.tagIngredientServings(
      selectedTagIds,
      defaultIngredientServings,
      ingredients,
      tags,
    );

    const dietDisabledIngredientServings = {};
    foodUtils
      .ingredientIdsArray(defaultIngredientServings)
      .filter((ingredientId) => filteredIngredientServings[ingredientId] === undefined)
      .map((ingredientId) => {
        dietDisabledIngredientServings[ingredientId] = defaultIngredientServings[ingredientId];
        return ingredientId;
      });

    return dietDisabledIngredientServings;
  }

  get disabledIngredients() {
    if (this.sectionSettings.removeable === false) return [];

    const { sectionId, ingredients, ingredientCategories } = this.props;

    const disabledIngredientIds = foodUtils.ingredientIdsArray(this.disabledIngredientServings);
    const query = {
      selectedIngredientIds: disabledIngredientIds,
      selectedSectionIds: [sectionId],
    };
    const food = { ingredientCategories };

    return foodUtils.filterIngredients(ingredients, query, food);
  }

  get dietDisabledIngredients() {
    const { sectionId, ingredients, ingredientCategories } = this.props;

    const resolvedIngredientIds = foodUtils.ingredientIdsArray(this.dietDisabledIngredientServings);
    const query = {
      selectedIngredientIds: resolvedIngredientIds,
      selectedSectionIds: [sectionId],
    };
    const food = { ingredientCategories };

    return foodUtils.filterIngredients(ingredients, query, food);
  }

  get globalRemovedIngredientServings() {
    const { defaultIngredientServings, selectedIngredientServings } = this.props;

    return foodUtils.getRemovedIngredientServings(
      defaultIngredientServings,
      selectedIngredientServings,
    );
  }

  get sectionRemovedIngredientServings() {
    const {
      sectionId,
      ingredients,
      ingredientCategories,
      defaultIngredientServings,
      selectedIngredientServings,
    } = this.props;

    return foodUtils.getSectionRemovedIngredientServings(
      ingredients,
      ingredientCategories,
      sectionId,
      defaultIngredientServings,
      selectedIngredientServings,
    );
  }

  get globalRemoveLimitExhausted() {
    const { type, globalIngredientRemovalLimit } = this.props;

    if (type === 'cyo') {
      return false;
    }

    return (
      globalIngredientRemovalLimit &&
      this.globalRemovedIngredientServings >= globalIngredientRemovalLimit
    );
  }

  get baseSectionLimit() {
    const { type } = this.props;

    if (!this.sectionSettings.limit[type].section) return undefined;
    return this.sectionSettings.limit[type].section.number;
  }

  get sectionLimit() {
    const { type } = this.props;

    if (this.baseSectionLimit === null || this.baseSectionLimit === undefined) return undefined;

    const addExtraLimit = this.sectionSettings.limit[type].section.extra;
    const extraLimit = addExtraLimit ? this.sectionIncludedServings : 0;

    return this.baseSectionLimit + extraLimit;
  }

  get sectionLimitExhausted() {
    const { sectionId, selectedIngredientServings, ingredients, ingredientCategories } = this.props;

    if (this.sectionLimit === undefined || this.sectionLimit === null) return false;

    const highlightedIngredientIds = foodUtils.ingredientIdsArray(selectedIngredientServings);
    const query = {
      selectedIngredientIds: highlightedIngredientIds,
      selectedSectionIds: [sectionId],
    };
    const food = { ingredientCategories };

    const filteredIngredients = foodUtils.filterIngredients(ingredients, query, food);
    const totalIngredientsNumber = filteredIngredients
      .map((ingredient) => selectedIngredientServings[ingredient.id])
      .map(Math.ceil)
      .reduce((totalNumber, ingredientServing) => totalNumber + ingredientServing, 0);

    return totalIngredientsNumber >= this.sectionLimit;
  }

  get sectionRemoveLimitExhausted() {
    const { type } = this.props;
    if (type === 'cyo') {
      return false;
    }

    return (
      this.sectionSettings.sectionIngredientRemoveLimit &&
      this.sectionRemovedIngredientServings >= this.sectionSettings.sectionIngredientRemoveLimit
    );
  }

  get sectionIncludedIngredientServings() {
    const { ingredients, ingredientCategories } = this.props;

    return foodUtils.filterIngredientServings(
      ingredients,
      this.includedIngredientServings,
      { selectedSectionIds: [this.section.id] },
      { ingredientCategories },
    );
  }

  get sectionIncludedServings() {
    return Object.values(this.sectionIncludedIngredientServings).reduce((a, b) => a + b, 0);
  }

  get section() {
    const { sectionId, sections } = this.props;

    return sections[sectionId];
  }

  get ingredientPrices() {
    const { ingredientBreakdown } = this.props;

    return chain(ingredientBreakdown)
      .keyBy('ingredient')
      .mapValues((item) => parseFloat(item.price))
      .value();
  }

  getCategoryIncludedIngredientServings = (categoryId) => {
    const { ingredients } = this.props;

    return foodUtils.filterIngredientServings(
      ingredients,
      this.includedIngredientServings,
      { selectedCategoryIds: [categoryId] },
      {},
    );
  };

  getCategoryIncludedServings = (categoryId) => {
    const categoryIncludedIngredientServings =
      this.getCategoryIncludedIngredientServings(categoryId);

    return Object.values(categoryIncludedIngredientServings).reduce((a, b) => a + b, 0);
  };

  getBaseCategoryLimit = (categoryId) => {
    const { type } = this.props;

    if (!this.sectionSettings.limit[type][categoryId]) return undefined;

    return this.sectionSettings.limit[type][categoryId].number;
  };

  getCategoryLimit = (categoryId) => {
    const { type } = this.props;

    if (
      this.getBaseCategoryLimit(categoryId) === null ||
      this.getBaseCategoryLimit(categoryId) === undefined
    ) {
      return undefined;
    }

    const addExtraLimit = this.sectionSettings.limit[type][categoryId].extra;
    const extraLimit = addExtraLimit ? this.getCategoryIncludedServings(categoryId) : 0;

    return this.getBaseCategoryLimit(categoryId) + extraLimit;
  };

  isDefaultIngredient = (ingredientId) => {
    const { defaultIngredientServings } = this.props;

    return Object.keys(defaultIngredientServings).map(Number).includes(ingredientId);
  };

  limitsExhausted = (ingredientId) => {
    const { type, ingredients, defaultIngredientServings, selectedIngredientServings } = this.props;

    const ingredient = ingredients[ingredientId];
    const categoryId = ingredient.ingredientCategory;

    const defaultServings = defaultIngredientServings[ingredientId] || 0;
    const highlightedServings = selectedIngredientServings[ingredientId] || 0;

    if (this.sectionLimitExhausted) {
      if (this.sectionSettings.limit[type].section.extra) {
        if (highlightedServings < defaultServings) return false;
      }

      return true;
    }

    if (this.categoryLimitExhausted(categoryId)) {
      if (
        this.sectionSettings.limit[type][categoryId].extra ||
        this.isDefaultIngredient(ingredientId)
      ) {
        if (highlightedServings < defaultServings) return false;
      }

      return true;
    }

    return false;
  };

  categoryLimitExhausted = (categoryId) => {
    const { selectedIngredientServings, ingredients, ingredientCategories } = this.props;

    const categoryLimit = this.getCategoryLimit(categoryId);

    /* If there is no category limit, we assume that we allow infinite number */
    if (categoryLimit === undefined || categoryLimit === null) return false;

    const highlightedIngredientIds = foodUtils.ingredientIdsArray(selectedIngredientServings);
    const query = {
      selectedIngredientIds: highlightedIngredientIds,
      selectedCategoryIds: [categoryId],
    };
    const food = { ingredientCategories };

    const filteredIngredients = foodUtils.filterIngredients(ingredients, query, food);
    const totalIngredientsNumber = filteredIngredients
      .map((ingredient) => selectedIngredientServings[ingredient.id])
      .map(Math.ceil)
      .reduce((totalNumber, ingredientServing) => totalNumber + ingredientServing, 0);

    return totalIngredientsNumber >= categoryLimit;
  };

  handleGlobalLimitExhausted = (isRemove = false) => {
    const { globalIngredientRemovalLimit, onLimitExhausted } = this.props;

    onLimitExhausted({
      sectionId: undefined,
      categoryId: undefined,
      name: 'ingredients',
      limit: globalIngredientRemovalLimit,
      isRemove,
    });
  };

  handleCategoryLimitExhausted = (categoryId) => {
    const { type, sectionId, ingredientCategories, onLimitExhausted } = this.props;

    onLimitExhausted({
      sectionId,
      categoryId,
      name: ingredientCategories[categoryId].name,
      limit: this.getBaseCategoryLimit(categoryId),
      extra: this.sectionSettings.limit[type][categoryId].extra,
    });
  };

  handleSectionLimitExhausted = (isRemove = false) => {
    const { sectionId, onLimitExhausted, type } = this.props;

    onLimitExhausted({
      sectionId,
      categoryId: undefined,
      name: this.section.name,
      limit: isRemove ? this.sectionSettings.sectionIngredientRemoveLimit : this.baseSectionLimit,
      extra: this.sectionSettings.limit[type].section?.extra,
      isRemove,
    });
  };

  handleIngredientRemove = (id) => {
    const { removeIngredient, saveHighlightedIngredientServings } = this.props;

    const isDefaultIngredient = this.isDefaultIngredient(id);

    if (this.globalRemoveLimitExhausted && isDefaultIngredient) {
      return this.handleGlobalLimitExhausted(true);
    }
    if (this.sectionRemoveLimitExhausted && isDefaultIngredient) {
      return this.handleSectionLimitExhausted(true);
    }
    removeIngredient(id, { store: 'highlighted' });
    return saveHighlightedIngredientServings();
  };

  handleIngredientReplace = () => {
    const { menuItemId, sectionId, type, router } = this.props;

    if (type === 'premade') {
      router.push(`/menu/details/${menuItemId}/add/${sectionId}`);
    } else {
      router.push(`/cyo/${menuItemId}/${sectionId}`);
    }
  };

  handleIngredientAdd = (id) => {
    const {
      sectionId,
      defaultIngredientServings,
      addIngredientServings,
      replaceSectionIngredients,
      saveHighlightedIngredientServings,
      ingredients,
    } = this.props;

    if (this.limitsExhausted(id)) {
      if (this.sectionLimitExhausted) {
        return this.handleSectionLimitExhausted();
      }

      const categoryId = ingredients[id].ingredientCategory;
      if (this.categoryLimitExhausted(categoryId)) {
        return this.handleCategoryLimitExhausted(categoryId);
      }
    }

    if (this.sectionSettings.replaceable) {
      replaceSectionIngredients(sectionId, { [id]: 1 }, { store: 'highlighted' });
    } else {
      addIngredientServings({ [id]: defaultIngredientServings[id] }, { store: 'highlighted' });
    }

    return saveHighlightedIngredientServings();
  };

  render() {
    const {
      menuItemId,
      sectionId,
      type,
      size,
      defaultIngredientServings,
      selectedIngredientServings,
      selectedTagIds,
      tags,
    } = this.props;

    return (
      <IngredientsSection
        menuItemId={menuItemId}
        sectionId={sectionId}
        name={this.section.name}
        type={type}
        size={size}
        selectedIngredients={this.selectedIngredients}
        disabledIngredients={this.disabledIngredients}
        dietDisabledIngredients={this.dietDisabledIngredients}
        ingredientPrices={this.ingredientPrices}
        defaultIngredientServings={defaultIngredientServings}
        selectedIngredientServings={selectedIngredientServings}
        selectedTagIds={selectedTagIds}
        tags={tags}
        settings={this.sectionSettings}
        onIngredientRemove={this.handleIngredientRemove}
        onIngredientReplace={this.handleIngredientReplace}
        onIngredientAdd={this.handleIngredientAdd}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  defaultIngredientServings: state.orderItem.defaultIngredientServings,
  selectedIngredientServings: state.orderItem.selectedIngredientServings,
  selectedTagIds: state.food.selectedTagIds,
  tags: state.api.tags,
  ingredients: state.api.ingredients,
  ingredientCategories: state.api.ingredientCategories,
  sections: state.api.cyoSections,
  globalIngredientRemovalLimit: state.api.settings.globalIngredientRemovalLimit,
  baseTypeSettings: state.api.settings.baseTypeSettings,
  sectionSettings: state.api.settings.sectionSettings,
  ingredientBreakdown: state.orderItem.ingredientBreakdown,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addIngredientServings: orderItemActions.addIngredientServings,
      removeIngredient: orderItemActions.removeIngredient,
      replaceSectionIngredients: orderItemActions.replaceSectionIngredients,
      restoreSectionIngredients: orderItemActions.restoreSectionIngredients,
      saveHighlightedIngredientServings: orderItemActions.saveHighlightedIngredientServings,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IngredientsSectionContainer),
);
