import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classNames from 'classnames';

import * as orderActions from '../../actions/order';
import { returnToMenu } from '../../actions/page';
import * as selectors from '../../sagas/selectors';
import { mapUpsellCategoriesWithMenus } from '../../utils/upsellUtils';

import useWindowSize from '../../hooks/useWindowSize';
import Button from '../Button';
import Currency from '../Currency';
import UpsellMenuItem from './UpsellMenuItem';

import { ReactComponent as HeartIcon } from './icon-heart.svg';

import './UpsellScreen.css';

function UpsellScreen() {
  const upsellCategories = useSelector(selectors.getUpsellCategories);
  const menuGroupId = useSelector(selectors.getCurrentMenuGroupId);
  const menuItems = useSelector(selectors.getMenuItems);
  const orderItemsIds = useSelector(selectors.getOrderOrderItemsIds);

  const [sortedUpsellCategories, setSortedUpsellCategories] = useState([]);
  const [selectedUpsellMenus, setSelectedUpsellMenus] = useState([]);
  const [selectedUpsellMenusPrice, setSelectedUpsellMenusPrice] = useState(0);

  const { width } = useWindowSize();
  const isMobile = width < 767;

  useEffect(() => {
    setSortedUpsellCategories(
      mapUpsellCategoriesWithMenus(upsellCategories, menuItems, menuGroupId),
    );
  }, [upsellCategories, menuItems, menuGroupId]);

  useEffect(() => {
    const calculateSelectedUpsellMenuItemsPrice = () =>
      selectedUpsellMenus
        .map((menuId) => menuItems[menuId])
        .map((menu) => parseFloat(menu.price))
        .reduce((sum, price) => sum + price, 0);
    setSelectedUpsellMenusPrice(calculateSelectedUpsellMenuItemsPrice());
  }, [selectedUpsellMenus, menuItems]);

  const dispatch = useDispatch();

  const onButtonClick = () => {
    dispatch(orderActions.createUpsellMenuItems(selectedUpsellMenus));
    dispatch(returnToMenu());
  };

  const onBackClick = () => {
    const orderItemId = orderItemsIds[orderItemsIds.length - 1];

    if (orderItemId) {
      dispatch(orderActions.modifyOrderOrderItem({ orderItemId }));
    }
  };

  const onToggleUpsellMenuItem = ({ id }) => {
    if (selectedUpsellMenus.includes(id)) {
      const arr = [...selectedUpsellMenus];
      const index = selectedUpsellMenus.indexOf(id);
      arr.splice(index, 1);
      setSelectedUpsellMenus(arr);
    } else {
      setSelectedUpsellMenus([...selectedUpsellMenus, id]);
    }
  };

  return (
    <div className="UpsellScreen iconBackground">
      <Typography
        variant={isMobile ? 'h3' : 'h2'}
        color="primary.dark"
        className="UpsellTitle"
        fontFamily="Work Sans"
        fontWeight="700"
      >
        <HeartIcon className="UpsellHeart" /> &nbsp; You may also like...
      </Typography>
      <div className="UpsellContent">
        {!isMobile &&
          sortedUpsellCategories.map(({ id, name, upsellMenus }) => (
            <div className="UpsellCategoryRow" key={`upsell-category-${id}`}>
              <Typography
                className="UpsellCategoryName"
                variant="body1"
                color="secondary.dark"
                fontWeight="700"
              >
                {name}
              </Typography>
              <div className="UpsellSection">
                <div className="UpsellMenusRow">
                  {upsellMenus.map((menu) => (
                    <UpsellMenuItem
                      key={`upsell-menu-${menu.id}`}
                      menuItem={menu}
                      isUpsellSelected={selectedUpsellMenus.includes(menu.id)}
                      onToggleUpsellMenuItem={onToggleUpsellMenuItem}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))}
        {isMobile && (
          <div className="UpsellMobileList">
            {sortedUpsellCategories
              .map(({ upsellMenus }) => upsellMenus)
              .flat()
              .map((menu) => (
                <UpsellMenuItem
                  key={`upsell-mobile-menu-${menu.id}`}
                  menuItem={menu}
                  isUpsellSelected={selectedUpsellMenus.includes(menu.id)}
                  onToggleUpsellMenuItem={onToggleUpsellMenuItem}
                />
              ))}
          </div>
        )}
      </div>
      <div className="ActionButtonCyo">
        <ul>
          <li>
            <Button className="btn-energy" onClick={onBackClick}>
              Back
            </Button>
          </li>
          <li>
            <Button
              className={classNames('Split btn-next', { dim: selectedUpsellMenus.length < 1 })}
              onClick={onButtonClick}
            >
              <span className="Text">
                {selectedUpsellMenus.length >= 1 ? (
                  <>
                    <span>Add to cart</span>
                    <span className="AttentionSeeker">
                      <Currency value={selectedUpsellMenusPrice} />
                    </span>
                  </>
                ) : (
                  <span>Skip</span>
                )}
              </span>
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default UpsellScreen;
