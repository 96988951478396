import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getSearchParam, setSearchParams } from '../../../utils/historyUtils';

import { landingPageTopBarStyles } from '../../../shapes/landingPageStyleShapes';
import orderShape from '../../../shapes/orderShape';
import routerShape, { routerLocationShape } from '../../../shapes/routerShape';

import CartButton from '../../../components/TopBar/CartButton';
import withRouter from '../../WithRouter';

class CartButtonContainer extends Component {
  static propTypes = {
    order: orderShape.isRequired,
    isLandingPageOpen: PropTypes.bool.isRequired,
    landingPageStyles: landingPageTopBarStyles.isRequired,

    /* React Router props */
    location: routerLocationShape.isRequired,
    router: routerShape.isRequired,
  };

  get quantity() {
    const { order } = this.props;

    return Object.values(order.orderItems)
      .map((orderItem) => orderItem.personalSettings.length)
      .reduce((a, b) => a + b, 0);
  }

  get landingPageStyles() {
    const { isLandingPageOpen, landingPageStyles } = this.props;

    return isLandingPageOpen ? landingPageStyles : {};
  }

  get isCartOpen() {
    const { location } = this.props;
    const { search } = location;

    return getSearchParam(search, 'showCart') === 'true';
  }

  handleOpenCart = () => {
    const { location, router } = this.props;
    const { pathname, search } = location;

    return router.replace({ pathname, search: setSearchParams(search, { showCart: true }) });
  };

  render() {
    return (
      <CartButton
        landingPageStyles={this.landingPageStyles}
        quantity={this.quantity}
        isCartOpen={this.isCartOpen}
        onOpenCart={this.handleOpenCart}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.order,
  location: state.router.location,
});

export default withRouter(connect(mapStateToProps, undefined)(CartButtonContainer));
