import { removeError } from '@spq/redux-api-client';
import { rehydrateReducer, requestStatus, toCamelCaseKeys } from '@spq/utils';
import { omit } from 'lodash';
import moment from 'moment';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { v4 as uuidV4 } from 'uuid';

import { HIGHLIGHT_GGLOCATION } from '../actions/gglocations';
import * as orderActions from '../actions/order';
import * as orderItemActions from '../actions/orderItem';
import * as userActions from '../actions/user';
import diningChoiceEnum from '../enums/diningChoiceEnum';
import gglocationTypeEnum from '../enums/gglocationTypeEnum';
import orderItemStatusEnum from '../enums/orderItemStatusEnum';
import orderTypeEnum from '../enums/orderTypeEnum';
import statusEnum from '../enums/statusEnum';
import * as foodUtils from '../utils/foodUtils';
import { calculateOrderItemHash } from '../utils/orderItemUtils';
import * as orderUtils from '../utils/orderUtils';

import packageInfo from '../../package.json';

import ecomService from '../services/api';

const defaultPricingState = {
  totalTax: null,
  discountAmount: null,
  price: null,
  discountName: null,
  discountSource: null,
  totalPrice: null,
  pretaxPrice: null,
};

const defaultState = Object.freeze({
  gglocationId: null,
  timeSlotId: null,
  customerAddressId: null,
  selectedPaymentMethod: null,
  orderType: null,
  orderTime: null,
  orderItemsIds: [],
  orderItems: {},
  surcharges: {},
  optionalSurcharges: {},
  pricing: {
    totalTax: null,
    discountAmount: null,
    price: null,
    discountName: null,
    discountSource: null,
    totalPrice: null,
    pretaxPrice: null,
    ready: { ...defaultPricingState },
    completed: { ...defaultPricingState },
    selected: { ...defaultPricingState },
    highlighted: { ...defaultPricingState },
  },
  diningChoice: diningChoiceEnum.TAKE_AWAY,
  diningChoiceOptions: {},
  deliveryAddress: null,
  deliveryDistance: null,
  estimatedDelivery: null,
  isCarbonOffsetEnabled: false,
  openCartAfterTimeSelected: false,
  openMenuItemPageAfterTimeSelected: false,
  scheduler: {
    gglocationId: null,
    processingTime: null,
    cutoffTime: null,
  },
  landingPageHistory: [],
  highlightedGglocationId: null,
  unavailableOrderItems: [],
  pretaxPrice: null,
  totalPrice: null,
  totalTax: null,
  discountAmount: null,
  orderStatus: statusEnum.INITIAL,
  isStationFlow: false,
  isTakeOutWithBagEnabled: false,
  replaceOrderErrorIds: [],
  email: null,
  phone: null,
  isGuestLimitReached: false,
  isGuestPaymentUnavailable: false,
  status: {
    diningChoiceOptions: statusEnum.INITIAL,
    orderPreview: statusEnum.INITIAL,
  },
  errors: [],
  version: packageInfo.version,
});

// eslint-disable-next-line default-param-last
export default function orderReducer(state = defaultState, action) {
  switch (action.type) {
    case REHYDRATE: {
      const incoming = action.payload && action.payload.order;
      if (incoming && incoming.version === state.version) {
        /* Check if the state should be restored */
        const { currentLocation } = action;
        if (currentLocation.state || window.hasRefreshed) {
          return Object.freeze({
            ...rehydrateReducer(
              [
                'gglocationId',
                'timeSlotId',
                'customerAddressId',
                'orderType',
                'orderTime',
                'orderItemsIds',
                'orderItems',
                'diningChoice',
                'diningChoiceOptions',
                'landingPageHistory',
                'deliveryAddress',
                'deliveryDistance',
                'estimatedDelivery',
                'isCarbonOffsetEnabled',
                'isStationFlow',
                'openMenuItemPageAfterTimeSelected',
                'isTakeOutWithBagEnabled',
              ],
              state,
              incoming,
            ),
          });
        }
      }

      return Object.freeze({ ...state });
    }
    /* TODO: when updating gglocation we should also update scheduled time */
    case orderActions.UPDATE_GGLOCATION: {
      const { gglocation } = action;
      if (!gglocation) {
        return Object.freeze({
          ...state,
          unavailableOrderItems: [],
          gglocationId: null,
          timeSlotId: null,
          orderType: null,
          orderTime: null,
        });
      }

      if (gglocation.id === state.gglocationId) return state;

      const { diningChoice } = state;
      let timeSlotId = null;
      let orderType = orderTypeEnum.SCHEDULED;
      let orderTime = null;

      if (gglocation.type === orderTypeEnum.INSTANT) {
        if (gglocation.canPlaceInstantOrder({ diningChoice })) {
          orderType = orderTypeEnum.INSTANT;
        } else {
          orderTime = moment(gglocation.earliestScheduledOrderDate({ diningChoice })).valueOf();
        }
      } else {
        const timeSlot = gglocation.getNextTimeSlot({ diningChoice });
        timeSlotId = timeSlot && timeSlot.id;
        orderTime = timeSlot && moment(timeSlot.datetime).startOf('day');
      }

      return Object.freeze({
        ...state,
        gglocationId: gglocation.id,
        timeSlotId,
        orderType,
        orderTime,
        isStationFlow: gglocation.gglocationType === gglocationTypeEnum.PARTNER,
      });
    }
    case orderActions.UPDATE_TIME_SLOT: {
      return Object.freeze({
        ...state,
        timeSlotId: action.id,
      });
    }
    case orderActions.SELECT_ADDRESS: {
      return Object.freeze({
        ...state,
        diningChoice: diningChoiceEnum.DELIVERY,
        customerAddressId: action.customerAddressId,
      });
    }
    case orderActions.RESET_ADDRESS: {
      return Object.freeze({
        ...state,
        customerAddressId: null,
        gglocationId: null,
        error: null,
      });
    }
    case orderActions.SELECT_PAYMENT_METHOD: {
      return Object.freeze({
        ...state,
        selectedPaymentMethod: action.paymentMethod,
      });
    }
    case orderActions.UPDATE_SCHEDULER: {
      return Object.freeze({
        ...state,
        gglocationId: action.gglocationId,
        scheduler: {
          gglocationId: action.gglocationId,
          processingTime: action.processingTime,
          cutoffTime: action.cutoffTime,
        },
      });
    }
    case orderActions.UPDATE_ORDER_ORDER_ITEM: {
      const { orderItem } = action;
      const orderItemHash = calculateOrderItemHash(orderItem);
      const sameOrderItemId = Object.keys(state.orderItems).find(
        (orderItemId) => calculateOrderItemHash(state.orderItems[orderItemId]) === orderItemHash,
      );

      // Combine the same order items as one to prevent dealing with
      // individual order item discount as it may get difficult as preview don't
      // keep track of order item individually
      if (sameOrderItemId && !orderItem.isModifying) {
        const personalSettings = [
          ...state.orderItems[sameOrderItemId].personalSettings,
          ...orderItem.personalSettings,
        ];
        return Object.freeze({
          ...state,
          orderItems: {
            ...state.orderItems,
            [sameOrderItemId]: {
              ...state.orderItems[sameOrderItemId],
              personalSettings,
              orderItemStatus: orderItemStatusEnum.COMPLETED,
              isModifying: false,
            },
          },
        });
      }
      return Object.freeze({
        ...state,
        orderItemsIds: [...new Set([...state.orderItemsIds, orderItem.id])],
        orderItems: {
          ...state.orderItems,
          [orderItem.id]: {
            ...orderItem,
            orderItemStatus: orderItemStatusEnum.COMPLETED,
            isModifying: false,
          },
        },
      });
    }
    case orderActions.REMOVE_ORDER_ITEM: {
      const orderItemsIds = state.orderItemsIds.filter(
        (orderItemId) => orderItemId !== action.orderItemId,
      );
      const orderItems = { ...state.orderItems };
      delete orderItems[action.orderItemId];

      return Object.freeze({
        ...state,
        orderItemsIds,
        orderItems,
      });
    }
    case orderActions.REMOVE_ORDER_ITEMS: {
      const orderItems = omit(state.orderItems, action.orderItemIds);
      const orderItemsIds = Object.keys(orderItems);

      return Object.freeze({
        ...state,
        orderItemsIds,
        orderItems,
      });
    }
    case orderActions.UPDATE_ORDER_ITEM_QUANTITY: {
      const orderItem = state.orderItems[action.orderItemId];

      if (!orderItem || action.quantity === orderItem.personalSettings.length) return state;

      if (action.quantity === 0) {
        const orderItems = omit(state.orderItems, action.orderItemId);
        const orderItemsIds = Object.keys(orderItems);

        return Object.freeze({
          ...state,
          orderItemsIds,
          orderItems,
        });
      }

      const { personalSettings } = orderItem;

      if (action.quantity > personalSettings.length) {
        const incrementQuantity = action.quantity - personalSettings.length;
        const newPersonalSettings = Array(incrementQuantity)
          .fill()
          .map(() =>
            foodUtils.getDefaultPersonalSettings({
              preferenceGroups: action.state.api.preferenceGroups,
            }),
          );

        return Object.freeze({
          ...state,
          orderItems: {
            ...state.orderItems,
            [action.orderItemId]: {
              ...orderItem,
              personalSettings: [...personalSettings, ...newPersonalSettings],
            },
          },
        });
      }

      return Object.freeze({
        ...state,
        orderItems: {
          ...state.orderItems,
          [action.orderItemId]: {
            ...orderItem,
            personalSettings: personalSettings.slice(0, action.quantity),
          },
        },
      });
    }
    case orderActions.ADD_UNAVAILABLE_ITEMS: {
      return Object.freeze({
        ...state,
        unavailableOrderItems: [...state.unavailableOrderItems, ...action.items],
      });
    }
    case orderActions.REMOVE_UNAVAILABLE_ITEMS: {
      const { orderItems, orderItemsIds } = orderUtils.removeOrderItemsWithApiIds({
        orderItems: state.orderItems,
        apiIds: action.apiIds,
      });
      return Object.freeze({
        ...state,
        orderItems,
        orderItemsIds,
        unavailableOrderItems: state.unavailableOrderItems.filter(
          ({ apiId }) => !action.apiIds.includes(apiId),
        ),
      });
    }
    case orderActions.SET_ORDER_TYPE: {
      return Object.freeze({
        ...state,
        orderType: action.orderType,
      });
    }
    case orderActions.SET_ORDER_TIME: {
      return Object.freeze({
        ...state,
        orderTime: action.orderTime.valueOf(),
      });
    }
    case orderActions.SET_DINING_CHOICE: {
      const { diningChoice, isStationFlow } = action;
      const customerAddressId =
        diningChoice !== diningChoiceEnum.DELIVERY ? null : state.customerAddressId;

      return Object.freeze({
        ...state,
        diningChoice,
        customerAddressId,
        deliveryAddress: customerAddressId && state.deliveryAddress,
        isStationFlow,
        isTakeOutWithBagEnabled: false,
      });
    }
    case orderActions.SET_LANDING_PAGE: {
      const { landingPage } = action;

      return Object.freeze({
        ...state,
        // If the new landing page is not the same as the last one, add it
        landingPageHistory:
          landingPage !== state.landingPageHistory[state.landingPageHistory.length - 1]
            ? [...state.landingPageHistory, landingPage]
            : state.landingPageHistory,
      });
    }
    case orderActions.ENABLE_CARBON_OFFSET: {
      return Object.freeze({
        ...state,
        isCarbonOffsetEnabled: true,
      });
    }
    case orderActions.DISABLE_CARBON_OFFSET: {
      return Object.freeze({
        ...state,
        isCarbonOffsetEnabled: false,
      });
    }
    case orderActions.ENABLE_TAKE_OUT_WITH_BAG: {
      return Object.freeze({
        ...state,
        isTakeOutWithBagEnabled: true,
      });
    }
    case orderActions.DISABLE_TAKE_OUT_WITH_BAG: {
      return Object.freeze({
        ...state,
        isTakeOutWithBagEnabled: false,
      });
    }
    case orderActions.PLACE_ORDER_REQUESTED: {
      return Object.freeze({
        ...state,
        isCartShown: false,
        orderStatus: statusEnum.REQUESTED,
        errors: removeError(state.errors, `${ecomService.placeOrder}`),
      });
    }
    case orderActions.PLACE_ORDER_SUCCESS: {
      return Object.freeze({
        ...state,
        orderItemsIds: [],
        orderItems: {},
        isCartShown: false,
        isCarbonOffsetEnabled: false,
        isTakeOutWithBagEnabled: false,
        orderStatus: statusEnum.SUCCESS,
        phone: null,
        email: null,
        errors: removeError(state.errors, `${ecomService.placeOrder}`),
      });
    }
    case orderActions.PLACE_ORDER_ERROR: {
      const errors = [
        { uuid: uuidV4(), endpointName: `${ecomService.placeOrder}`, error: action.error },
        ...state.errors,
      ];

      return Object.freeze({
        ...state,
        isCartShown: true,
        errors,
        orderStatus: statusEnum.ERROR,
      });
    }
    case orderActions.ADD_REPLACE_ORDER_ERROR: {
      return Object.freeze({
        ...state,
        replaceOrderErrorIds: [...state.replaceOrderErrorIds, action.userOrderId],
      });
    }
    case orderActions.RESET_ORDER:
      return Object.freeze({
        ...state,
        orderItemsIds: [],
        orderItems: {},
        unavailableOrderItems: [],
        isCartShown: false,
        isTakeOutWithBagEnabled: false,
        email: null,
        phone: null,
      });
    case orderItemActions.RESET_ORDER_ITEM:
      return Object.freeze({
        ...state,
        openCartAfterTimeSelected: false,
        openMenuItemPageAfterTimeSelected: false,
      });
    case orderActions.OPEN_CART_AFTER_TIME_SELECTED: {
      return Object.freeze({
        ...state,
        openCartAfterTimeSelected: true,
      });
    }
    case orderActions.OPEN_MENU_ITEM_PAGE_AFTER_TIME_SELECTED: {
      return Object.freeze({
        ...state,
        openMenuItemPageAfterTimeSelected: true,
      });
    }
    case orderActions.SHOW_CART: {
      return Object.freeze({
        ...state,
        openCartAfterTimeSelected: false,
        openMenuItemPageAfterTimeSelected: false,
      });
    }
    case '@@router/LOCATION_CHANGE': {
      if (['/locations', '/time', '/menu'].includes(action.payload.pathname) === false) {
        return Object.freeze({
          ...state,
          openCartAfterTimeSelected: false,
          openMenuItemPageAfterTimeSelected: false,
        });
      }

      return state;
    }
    case orderActions.AWAIT_ORDER_PRICE:
    case orderActions.DELAY_ORDER: {
      return Object.freeze({
        ...state,
        orderStatus: statusEnum.AWAITING,
      });
    }
    case orderActions.ROLLBACK_AWAITING_ORDER: {
      return Object.freeze({
        ...state,
        orderStatus: statusEnum.INITIAL,
      });
    }

    case HIGHLIGHT_GGLOCATION: {
      return Object.freeze({
        ...state,
        highlightedGglocationId: action.id,
      });
    }

    case orderActions.ORDER_PREVIEW_SUCCESS: {
      const response = toCamelCaseKeys(action.response);

      return Object.freeze({
        ...state,
        gglocationId: `${response.gglocationType}_${response.gglocationId}`,
        deliveryAddress: response.deliveryAddress,
        deliveryDistance: response.deliveryDistance,
        estimatedDelivery: response.estimatedDelivery,
        surcharges: response.surcharges,
        optionalSurcharges: response.optionalSurcharges,
        status: {
          ...state.status,
          orderPreview: statusEnum.SUCCESS,
        },
        error: null,
        warning: response.warning && {
          success: false,
          message: response.warning,
          code: null,
          data: response.warning,
        },
      });
    }

    case orderActions.ORDER_PREVIEW_ERROR: {
      return Object.freeze({
        ...state,
        status: {
          ...state.status,
          orderPreview: statusEnum.ERROR,
        },
        error: action.error,
        warning: null,
      });
    }

    case orderActions.ORDER_PREVIEW_REQUESTED: {
      return Object.freeze({
        ...state,
        status: {
          ...state.status,
          orderPreview: requestStatus(state.status.orderPreview),
        },
        error: null,
        warning: null,
      });
    }

    case orderActions.UPDATE_PRICES: {
      const updatedOrderItems = orderUtils.appendOrderItemPrices(
        state.orderItems,
        action.orderItemHashPrices,
        action.stages,
      );
      const updatedOrderPrice = orderUtils.appendOrderPrices(state, action.pricing, action.stages);

      return Object.freeze({
        ...state,
        orderItems: updatedOrderItems,
        pricing: updatedOrderPrice,
      });
    }

    case orderActions.SAVE_BEFORE_SIGN_IN_ORDER_PRICE: {
      return Object.freeze({
        ...state,
        beforeSignInOrderPrice: { ...state.pricing },
      });
    }

    case orderActions.REPLACE_ORDER_SUCCESS: {
      const response = toCamelCaseKeys(action.response);

      return Object.freeze({
        ...state,
        gglocationId: `${response.gglocationType}_${response.gglocationId}`,
        diningChoice: response.diningChoice,
        customerAddressId: response.customerAddressId,
        deliveryAddress: response.deliveryAddress,
        deliveryDistance: response.deliveryDistance,
        estimatedDelivery: response.estimatedDelivery,
        status: {
          ...state.status,
          orderPreview: statusEnum.SUCCESS,
        },
        error: null,
        warning: response.warning && {
          success: false,
          message: response.warning,
          code: null,
          data: response.warning,
        },
      });
    }

    case orderActions.REPLACE_ORDER_ERROR: {
      return Object.freeze({
        ...state,
        status: {
          ...state.status,
          orderPreview: statusEnum.ERROR,
        },
        error: action.error,
        warning: null,
      });
    }

    case orderActions.SET_IS_GUEST_LIMIT_REACHED: {
      return Object.freeze({
        ...state,
        isGuestLimitReached: action.isGuestLimitReached,
      });
    }

    case orderActions.SET_IS_GUEST_PAYMENT_UNAVAILABLE: {
      return Object.freeze({
        ...state,
        isGuestPaymentUnavailable: action.isGuestPaymentUnavailable,
      });
    }

    case userActions.SIGN_OUT: {
      return Object.freeze({
        ...state,
        /* Set to default so order preview request does not trigger upon login */
        diningChoice: defaultState.diningChoice,
        customerAddressId: defaultState.customerAddressId,
        selectedPaymentMethod: defaultState.selectedPaymentMethod,
      });
    }
    default:
      return state;
  }
}
