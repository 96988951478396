import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './LinearIcon.css';

function LinearIcon(props) {
  const { className, name } = props;
  return <span className={classnames(className, 'lnr', `lnr-${name}`)} />;
}

LinearIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

LinearIcon.defaultProps = {
  className: '',
};

export default LinearIcon;
