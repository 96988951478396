import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './Badge.css';

const BadgeColorMapping = {
  default: 'Default',
  primary: 'Primary',
  secondary: 'Secondary',
  success: 'Success',
  error: 'Error',
  disabled: 'Disabled',
};

function Badge({ className, children, outline, color, typographyVariant }) {
  return (
    <div className={classnames('Badge', BadgeColorMapping[color], className, { outline })}>
      <Typography variant={typographyVariant} fontWeight="600">
        {children}
      </Typography>
    </div>
  );
}

Badge.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  outline: PropTypes.bool,
  color: PropTypes.string,
  typographyVariant: PropTypes.string,
};

Badge.defaultProps = {
  className: null,
  outline: false,
  color: 'primary',
  typographyVariant: 'subtitle2',
};

export default Badge;
