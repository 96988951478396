import React from 'react';
import Tab from 'react-bootstrap/lib/Tab';
import Tabs from 'react-bootstrap/lib/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import * as orderItemActions from '../../../actions/orderItem';
import * as selectors from '../../../sagas/selectors';

import menuItemShape from '../../../shapes/menuItemShape';
import { cyoSectionGroupsShape } from '../../../shapes/settingsShape';

import IngredientsSectionHeader from '../../../containers/IngredientsSectionHeader';
import IngredientsSection from '../../../containers/MenuDetails/IngredientsSection';
import CustomizeLeft from '../../CustomizeLeft';
import Modal from '../../Modal';
import NutritionalBlock from '../../NutritionalBlock';
import NutritionalSection from '../../NutritionalSection';

import './CyoDetails.css';

function CyoDetails(props) {
  const {
    baseId,
    detailsTabId,
    menuItem,
    modalOpen,
    cyoSectionGroups,
    onModalHide,
    onDetailsTabSelect,
  } = props;
  const subtitleElement = (
    <Typography variant="body1" component="span" fontWeight="800">
      Items
    </Typography>
  );

  const sectionTitleElements = {};

  const dispatch = useDispatch();
  const { nutritionalGroups } = useSelector(selectors.getApiNutritionalSettings);

  const onGroupSelect = (nutritionalGroupId) => {
    if (nutritionalGroupId !== 0) {
      dispatch(orderItemActions.setActiveNutritionalGroup({ nutritionalGroupId }));
    }
  };

  Object.values(cyoSectionGroups).map((sectionsGroup) => {
    if (sectionsGroup.title) {
      sectionTitleElements[sectionsGroup.id] = (
        <div className="IngredientsSectionName kale">
          <Typography
            variant="h6"
            color="primary"
            className="Name"
            fontWeight="900"
            textTransform="capitalize"
          >
            {sectionsGroup.title}
          </Typography>
        </div>
      );
    } else {
      sectionTitleElements[sectionsGroup.id] = (
        <IngredientsSectionHeader
          menuItemId={menuItem.id}
          sectionId={sectionsGroup.sectionIds[0]}
          isCyo
        />
      );
    }
    return sectionsGroup;
  });

  return (
    <>
      <Modal className="CyoModal NutritionalModal" show={modalOpen} onHide={onModalHide}>
        <div className="modal-control">
          <ul>
            <li>
              <Modal.Dismiss className="btn-close-modal" />
            </li>
          </ul>
        </div>
        <Modal.Body className="kale">
          <Tabs
            id="NutritionalTabs"
            defaultActiveKey={0}
            animation={false}
            onSelect={onGroupSelect}
          >
            <Tab eventKey={0} title={subtitleElement}>
              {Object.values(cyoSectionGroups).map((sectionsGroup) => (
                <>
                  {sectionTitleElements[sectionsGroup.id]}
                  <div className="CyoSectionGroup">
                    {sectionsGroup.sectionIds.map((id) => (
                      <IngredientsSection
                        key={id}
                        menuItemId={menuItem.id}
                        baseId={baseId}
                        sectionId={id}
                        type="cyo"
                        size="Label"
                      />
                    ))}
                  </div>
                </>
              ))}
            </Tab>
            {Object.values(nutritionalGroups).map((nutritionalGroup) => (
              <Tab
                key={nutritionalGroup.id}
                eventKey={nutritionalGroup.id}
                title={
                  <Typography variant="body1" component="span" fontWeight="800">
                    {nutritionalGroup.title}
                  </Typography>
                }
              >
                <NutritionalSection sectionIds={nutritionalGroup.sectionIds} />
              </Tab>
            ))}
          </Tabs>
        </Modal.Body>
      </Modal>
      <CustomizeLeft>
        <div className="CyoDetails kale">
          <div className="content">
            <Tabs
              activeKey={detailsTabId}
              onSelect={onDetailsTabSelect}
              className="CyoDetailsTabs"
              animation={false}
            >
              {Object.values(cyoSectionGroups).map((sectionsGroup) => (
                <Tab
                  key={sectionsGroup.id}
                  eventKey={sectionsGroup.id}
                  title={sectionTitleElements[sectionsGroup.id]}
                >
                  <div className="CyoSectionGroup">
                    {sectionsGroup.sectionIds.map((id) => (
                      <IngredientsSection
                        key={id}
                        menuItemId={menuItem.id}
                        baseId={baseId}
                        sectionId={id}
                        type="cyo"
                      />
                    ))}
                  </div>
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>
        <NutritionalBlock />
      </CustomizeLeft>
    </>
  );
}

CyoDetails.propTypes = {
  baseId: PropTypes.number,
  detailsTabId: PropTypes.number.isRequired,
  menuItem: menuItemShape,
  modalOpen: PropTypes.bool.isRequired,
  cyoSectionGroups: PropTypes.objectOf(cyoSectionGroupsShape).isRequired,
  onModalHide: PropTypes.func.isRequired,
  onDetailsTabSelect: PropTypes.func.isRequired,
};

CyoDetails.defaultProps = {
  baseId: null,
  menuItem: null,
};

export default CyoDetails;
