import { REHYDRATE } from 'redux-persist/lib/constants';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as apiActions from '../actions/api';

import * as selectors from './selectors';

import ecomService, { cloudFrontService, loyaltyService } from '../services/api';

function* withToken({ request }) {
  const token = yield select(selectors.getUserToken);
  const uuid = yield select(selectors.getUserUuid);
  yield put({ ...request, token, uuid });
}

function* withPaymentToken({ request }) {
  const token = yield select(selectors.getPaymentToken);
  const uuid = yield select(selectors.getUserUuid);
  yield put({ ...request, token, uuid });
}

const DEFAULT_PAGE_SIZE = 10;

function* loadApiWithPage({
  desiredPage = null,
  currentItemsCount = 0,
  maxItemsCount,
  pageSize,
  apiCall,
}) {
  let pageToBeFetch = desiredPage;
  const lastPage = Math.ceil(maxItemsCount / pageSize);

  if (!pageToBeFetch) {
    if (currentItemsCount < maxItemsCount || maxItemsCount == null) {
      pageToBeFetch = Math.ceil((currentItemsCount + 1) / pageSize);
    }
  }
  // Fetch the page if the page is valid or the page info
  // haven't been initialized yet (endpoint haven't been fetched before)
  if (pageToBeFetch && (pageToBeFetch <= lastPage || maxItemsCount == null)) {
    yield put(apiCall({ page: pageToBeFetch }));
  }
}

function* loadNextOrdersPage({ page }) {
  const { orders } = yield select(selectors.getApiPageInfo);
  const userOrderIds = yield select(selectors.getUserOrderIds);

  yield loadApiWithPage({
    desiredPage: page,
    currentItemsCount: userOrderIds.length,
    maxItemsCount: orders.count,
    pageSize: DEFAULT_PAGE_SIZE,
    apiCall: ecomService.orders.requestActionCreator,
  });
}

function* loadNextFavoriteMenuItemsPage({ page }) {
  const { favoriteMenuItems } = yield select(selectors.getApiPageInfo);
  const favoriteMenuItemIds = yield select(selectors.getFavoriteMenuItemIds);

  yield loadApiWithPage({
    desiredPage: page,
    currentItemsCount: favoriteMenuItemIds.length,
    maxItemsCount: favoriteMenuItems.count,
    pageSize: DEFAULT_PAGE_SIZE,
    apiCall: ecomService.favoriteMenuItems.requestActionCreator,
  });
}

function* watchWithToken() {
  yield takeEvery(apiActions.WITH_TOKEN, withToken);
}

function* watchWithPaymentToken() {
  yield takeEvery(apiActions.WITH_PAYMENT_TOKEN, withPaymentToken);
}

function* handleRehydrate() {
  const token = yield select(selectors.getUserToken);
  loyaltyService.setToken(token);

  yield put(cloudFrontService.gglocations.requestActionCreator());
  yield put(cloudFrontService.gglocationsImages.requestActionCreator());
  yield put(cloudFrontService.images.requestActionCreator());
  yield put(cloudFrontService.ingredients.requestActionCreator());
  yield put(cloudFrontService.gglocationMenuGroups.requestActionCreator());
  yield put(cloudFrontService.menuGroups.requestActionCreator());
  yield put(cloudFrontService.bases.requestActionCreator());
  yield put(cloudFrontService.menuCategories.requestActionCreator());
  yield put(cloudFrontService.nutrients.requestActionCreator());
  yield put(cloudFrontService.ingredientCategories.requestActionCreator());
  yield put(cloudFrontService.ingredientGroups.requestActionCreator());
  yield put(cloudFrontService.preferenceGroups.requestActionCreator());
  yield put(cloudFrontService.tags.requestActionCreator());
  yield put(cloudFrontService.cyoSections.requestActionCreator());
  yield put(cloudFrontService.servingNumberVariations.requestActionCreator());
  yield put(cloudFrontService.pricingVariations.requestActionCreator());
  yield put(cloudFrontService.settings.requestActionCreator());
  yield put(cloudFrontService.unavailableIngredients.requestActionCreator());
  yield put(cloudFrontService.upsellCategories.requestActionCreator());
  yield put(ecomService.diningChoiceOptions.requestActionCreator());
  yield put(ecomService.storeBusy.requestActionCreator());

  if (token) {
    try {
      const brand = yield select(selectors.getLoyaltyBrandPrefix);
      yield put(loyaltyService.fetchMassRewards.requestActionCreator({ brand }));
    } catch (e) {
      console.log(e);
    }
  }
}

function* watchRehydrateDispatched() {
  yield takeEvery(REHYDRATE, handleRehydrate);
}

function* watchApiCall() {
  yield takeLatest(apiActions.LOAD_NEXT_ORDERS_PAGE, loadNextOrdersPage);
  yield takeLatest(apiActions.LOAD_NEXT_FAVORITE_MENU_ITEMS_PAGE, loadNextFavoriteMenuItemsPage);
}

export default function* apiSaga() {
  yield all([
    watchWithToken(),
    watchWithPaymentToken(),
    watchRehydrateDispatched(),
    watchApiCall(),
  ]);
}
