import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import Spinner from '../Spinner';

function FacebookSpinner(props) {
  const { show } = props;
  return (
    <Modal className="modal-small" show={show}>
      <Modal.Body className="kale">
        <p>Waiting for the process to be completed...</p>
        <Spinner />
      </Modal.Body>
    </Modal>
  );
}

FacebookSpinner.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default FacebookSpinner;
