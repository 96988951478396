import React from 'react';
import PropTypes from 'prop-types';

import { getConflictedTagNames } from '../../../utils/foodUtils';

import ingredientShape from '../../../shapes/ingredientShape';
import { sectionSettingsShape } from '../../../shapes/settingsShape';
import tagShape from '../../../shapes/tagShape';

import Ingredient from '../Ingredient';
import IngredientAdd from '../IngredientAdd';

import './IngredientsSection.css';

function IngredientsSection(props) {
  const {
    menuItemId,
    sectionId,
    type,
    size,
    selectedIngredients,
    disabledIngredients,
    dietDisabledIngredients,
    ingredientPrices,
    defaultIngredientServings,
    selectedIngredientServings,
    selectedTagIds,
    tags,
    settings,
    onIngredientRemove,
    onIngredientReplace,
    onIngredientAdd,
  } = props;

  const limit = settings.limit ? settings.limit[type] : {};

  return (
    <div className="IngredientsSection kale">
      <div className="IngredientsList">
        {selectedIngredients.map((ingredient) => {
          /* Menu items can't be replaceable if the section limit is above 1 */
          /* TODO: Clean up */

          const limitedToOne =
            limit.section && limit.section.extra === false && limit.section.number === 1;
          const isIncluded =
            selectedIngredientServings[ingredient.id] <= defaultIngredientServings[ingredient.id];

          return (
            <Ingredient
              key={ingredient.id}
              id={ingredient.id}
              servings={selectedIngredientServings[ingredient.id]}
              name={ingredient.name}
              image={ingredient.image}
              removeable={settings?.removeable || (!limitedToOne && settings?.replaceable)}
              replaceable={limitedToOne && settings?.replaceable}
              size={size}
              included={isIncluded}
              price={ingredientPrices[ingredient.id]}
              onIngredientRemove={onIngredientRemove}
              onIngredientReplace={onIngredientReplace}
            />
          );
        })}
        {disabledIngredients.map((ingredient) => (
          <Ingredient
            key={ingredient.id}
            id={ingredient.id}
            name={ingredient.name}
            image={ingredient.image}
            disabled
            onIngredientClick={onIngredientAdd}
          />
        ))}
        {(settings.removeable || selectedIngredients.length === 0) &&
          dietDisabledIngredients.map((ingredient) => {
            const conflictedTagNames = getConflictedTagNames({
              ingredient,
              selectedTagIds,
              tags,
              ingredientLevelOnly: true,
            });

            return (
              <Ingredient
                key={ingredient.id}
                id={ingredient.id}
                name={ingredient.name}
                image={ingredient.image}
                conflictedTagNames={conflictedTagNames}
                replaceable={settings?.replaceable}
                onIngredientReplace={onIngredientReplace}
                resolved
              />
            );
          })}
        {((selectedIngredients.length === 0 && type !== 'cyo') ||
          (type === 'premade' && settings?.addCategories[type].length > 0)) && (
          <IngredientAdd
            menuItemId={menuItemId}
            sectionId={sectionId}
            empty={selectedIngredients.length === 0}
          />
        )}
      </div>
    </div>
  );
}

IngredientsSection.propTypes = {
  menuItemId: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['premade', 'cyo']).isRequired,
  size: PropTypes.oneOf(['Label', 'Medium', 'Small', 'ExtraSmall']),
  selectedIngredients: PropTypes.arrayOf(ingredientShape).isRequired,
  disabledIngredients: PropTypes.arrayOf(ingredientShape).isRequired,
  dietDisabledIngredients: PropTypes.arrayOf(ingredientShape).isRequired,
  ingredientPrices: PropTypes.objectOf(PropTypes.number).isRequired,
  defaultIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
  selectedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
  selectedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  tags: PropTypes.objectOf(tagShape).isRequired,
  settings: sectionSettingsShape.isRequired,
  onIngredientRemove: PropTypes.func.isRequired,
  onIngredientReplace: PropTypes.func.isRequired,
  onIngredientAdd: PropTypes.func.isRequired,
};

IngredientsSection.defaultProps = {
  size: undefined,
};

export default IngredientsSection;
