import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { push, replace } from 'redux-first-history';

import * as userActions from '../../actions/user';
import statusEnum from '../../enums/statusEnum';
import * as selectors from '../../sagas/selectors';
import { getSearchParam } from '../../utils/historyUtils';

import Button from '../Button';
import Modal from '../Modal';
import Spinner from '../Spinner';

import { kioskNotifyService } from '../../services/api';

import './KioskPairingModal.css';

function KioskPairingModal() {
  const dispatch = useDispatch();
  const location = useSelector(selectors.getCurrentLocation);
  const userToken = useSelector(selectors.getUserToken);
  const code = getSearchParam(location.search, 'code');
  const { pairKiosk } = useSelector(selectors.getApiStatus);
  const apiError = useSelector((state) => selectors.getApiError(state, 'pairKiosk'));

  const goToHome = () => {
    dispatch(replace({ pathname: '/' }));
  };

  useEffect(() => {
    if (!userToken) {
      dispatch(userActions.afterSignIn({ pathname: location.pathname, search: location.search }));
      dispatch(push({ pathname: '/signIn' }));
    } else {
      kioskNotifyService.setToken(userToken);
      dispatch(kioskNotifyService.pairKiosk.requestActionCreator({ code }));
    }
  }, []);

  return (
    <div className="PairingPage">
      <Modal className="modal-small KioskPairingModal" show>
        <Modal.Body>
          <div className="Content">
            {pairKiosk === statusEnum.SUCCESS && (
              <>
                <Typography className="Title" variant="h3" color="primary">
                  Pairing Successful
                </Typography>
                <Typography className="Text" variant="subtitle2" color="grey.main">
                  Please select your loyalty reward on the kiosk screen
                </Typography>
              </>
            )}
            {pairKiosk === statusEnum.ERROR && (
              <>
                <Typography className="Title" variant="h2" color="error">
                  Error
                </Typography>
                <Typography className="Text" variant="h4" color="grey.main">
                  {apiError?.error?.message}
                </Typography>
              </>
            )}
            {![statusEnum.SUCCESS, statusEnum.ERROR].includes(pairKiosk) && (
              <>
                <Spinner />
                <Typography variant="h4" className="Text" color="primary">
                  Pairing...
                </Typography>
              </>
            )}
            {[statusEnum.SUCCESS, statusEnum.ERROR].includes(pairKiosk) && (
              <Button className="btn-secondary" onClick={goToHome}>
                Close
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default KioskPairingModal;
