import React from 'react';
import PropTypes from 'prop-types';

import './ButtonsRow.css';

function ButtonsRow(props) {
  const { children } = props;

  return <div className="ButtonsRow">{children}</div>;
}

ButtonsRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonsRow;
