import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import menuCategoryShape from '../../shapes/menuCategoryShape';
import menuItemShape from '../../shapes/menuItemShape';

import MenuNavbar from '../../containers/Menu/MenuNavbar';
import DiningChoiceChanger from '../DiningChoiceChanger';
import LoyaltyFab from '../LoyaltyFab';
import MenuContent from './MenuContent';
import MenuHeader from './MenuHeader';
import MenuSidebar from './MenuSidebar';

import './Menu.css';

function Menu(props) {
  const {
    menuCategories,
    menuItems,
    selectedCategory,
    isDelivery,
    addressLabelName,
    onMenuCategoryInit,
    onMenuCategoryClick,
    isLoyaltyEnabled,
  } = props;

  return (
    <div className="Menu">
      <MenuHeader />
      <MenuNavbar menuCategories={menuCategories} onMenuCategoryClick={onMenuCategoryClick} />
      <div className="MenuListingHeader">
        <Typography variant="body1" color="grey.main" sx={{ position: 'relative' }}>
          Ordering for{' '}
          {isDelivery ? (
            <>
              <strong>DELIVERY</strong> to <strong>{addressLabelName}</strong>
            </>
          ) : (
            <>
              <strong>PICKUP</strong> from <strong>{addressLabelName}</strong>
            </>
          )}
          <DiningChoiceChanger />
        </Typography>
      </div>
      <div className="MenuListing">
        <MenuSidebar menuCategories={menuCategories} />
        <MenuContent
          menuItems={menuItems}
          menuCategories={menuCategories}
          onMenuCategoryInit={onMenuCategoryInit}
          selectedCategory={selectedCategory}
          isLoyaltyEnabled={isLoyaltyEnabled}
        />
      </div>
      <LoyaltyFab />
    </div>
  );
}

Menu.propTypes = {
  menuCategories: PropTypes.arrayOf(menuCategoryShape).isRequired,
  menuItems: PropTypes.arrayOf(menuItemShape).isRequired,
  selectedCategory: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  onMenuCategoryInit: PropTypes.func,
  onMenuCategoryClick: PropTypes.func,
  isLoyaltyEnabled: PropTypes.bool.isRequired,
  isDelivery: PropTypes.bool.isRequired,
  addressLabelName: PropTypes.string.isRequired,
};

Menu.defaultProps = {
  selectedCategory: null,
  onMenuCategoryInit: null,
  onMenuCategoryClick: null,
};

export default Menu;
