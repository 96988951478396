import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import * as loyaltyActions from '../../actions/loyalty';
import * as pageActions from '../../actions/page';
import rewardTypeEnum from '../../enums/rewardTypeEnum';
import * as selectors from '../../sagas/selectors';

import scanHistoryShape from '../../shapes/scanHistoryShape';

import Button from '../Button';

import './PastScan.css';

function PastScan({ scan }) {
  const { date, amount, ref, collections } = scan;

  const dispatch = useDispatch();

  const allRewards = useSelector(selectors.getAllRewards);
  const claims = useSelector(selectors.getClaims);

  const getCollectionReward = (collection) =>
    allRewards.find((reward) => reward.id === collection.rewardId);

  const handleCollectionPress = ({ reward, status }) => {
    if (!reward) return;

    if (status === 'IP') {
      let rewardType = rewardTypeEnum.COLLECTION;
      if (reward.isSpecial) {
        rewardType = rewardTypeEnum.SPECIAL;
      } else if (reward.isMassReward) {
        rewardType = rewardTypeEnum.MASS;
      }
      dispatch(pageActions.openRewardModal({ rewardId: reward.id, rewardType }));
    } else if (['AW', 'NO'].indexOf(status) > -1) {
      const userReward = Object.values(claims).find((claim) => reward.uuid === claim.rewardUuid);
      if (userReward) {
        dispatch(loyaltyActions.setHighlightedUserReward({ userRewardUuid: userReward.uuid }));
      }
    }
  };

  const getCollectionName = (name, status) => {
    if (['AW', 'NO', 'CL'].indexOf(status) > -1) {
      return `${name} (completed)`;
    }
    return name;
  };

  const collectionElement = (collection) => {
    const reward = getCollectionReward(collection);

    return (
      <div className="CollectionContainer" key={collection.id}>
        <Typography variant="subtitle2" color="grey.main">
          {collection?.progress} accumulated
          <br />
          {reward && (
            <Button
              className="blue-link"
              onClick={() =>
                handleCollectionPress({
                  reward: getCollectionReward(collection),
                  status: collection.rewardStatus,
                })
              }
            >
              {getCollectionName(reward?.name, collection?.rewardStatus)}
            </Button>
          )}
        </Typography>
      </div>
    );
  };

  return (
    <div className="PastScan">
      <div className="PastScanHeader">
        <div className="HeaderLeft">
          <Typography variant="subtitle2" color="grey.main" className="RefNumber">
            {ref}
          </Typography>
          <Typography variant="subtitle2" className="ScanDate">
            {date}
          </Typography>
        </div>
        {amount && (
          <div className="HeaderRight">
            <Typography variant="subtitle2" color="grey.main">
              {amount}
            </Typography>
          </div>
        )}
      </div>
      <div className="PastScanDetail">
        <Typography variant="subtitle2" color="primary">
          {collections.map((collection) => collectionElement(collection))}
        </Typography>
      </div>
    </div>
  );
}

PastScan.propTypes = {
  scan: scanHistoryShape.isRequired,
};

export default PastScan;
