import { all } from 'redux-saga/effects';

import addressSaga from './addressSaga';
import apiSaga from './apiSaga';
import browserSaga from './browserSaga';
import cartSaga from './cartSaga';
import errorResolutionSaga from './errorResolutionSaga';
import foodSaga from './foodSaga';
import geolocationSaga from './geolocationSaga';
import historySaga from './historySaga';
import includeStateSaga from './includeStateSaga';
import loggingSaga from './loggingSaga';
import loyaltySaga from './loyaltySaga';
import orderItemSaga from './orderItemSaga';
import orderSaga from './orderSaga';
import pageSaga from './pageSaga';
import pricingSaga from './pricingSaga';
import userSaga from './userSaga';

export default function* rootSaga() {
  yield all([
    addressSaga(),
    apiSaga(),
    browserSaga(),
    cartSaga(),
    errorResolutionSaga(),
    foodSaga(),
    geolocationSaga(),
    historySaga(),
    includeStateSaga(),
    loyaltySaga(),
    orderSaga(),
    orderItemSaga(),
    pageSaga(),
    pricingSaga(),
    loggingSaga(),
    userSaga(),
  ]);
}
