import { actions as apiClientActions, apiCallSuccessChannel } from '@spq/redux-api-client';
import { all, select, takeEvery } from 'redux-saga/effects';

import * as loggingActions from '../actions/logging';
import * as loyaltyActions from '../actions/loyalty';
import * as orderActions from '../actions/order';
import * as gtmUtils from '../utils/gtmUtils';
import { captureBreadcrumb } from '../utils/loggingUtils';

import * as selectors from './selectors';

import { loyaltyService } from '../services/api';
import { RAVEN_ACTION_TYPE_BLACKLIST, RAVEN_ENDPOINT_BLACKLIST } from '../settings';

const isNotBlacklisted = (action) => RAVEN_ACTION_TYPE_BLACKLIST.includes(action.type) === false;
const isNotBlacklistedEndpoint = (action) => {
  if (
    action.type === apiClientActions.API_CALL_REQUESTED &&
    RAVEN_ENDPOINT_BLACKLIST.includes(action.endpointName)
  ) {
    return false;
  }
  return true;
};

function handleDefaultAction(action) {
  if (isNotBlacklisted(action) && isNotBlacklistedEndpoint(action)) {
    captureBreadcrumb({
      message: `Redux action: ${action.type}`,
      category: 'action',
      data: {
        ...action,
        response: 'STRIPPED',
      },
    });
  }
}

function* handleRemoveOrderItem(action) {
  const order = yield select(selectors.getOrder);
  const orderItem = order.orderItems[action.orderItemId];
  const landingPageSlug = yield select(selectors.getCurrentLandingPageSlug);

  if (orderItem?.menuItemId) {
    const menuItem = yield select(selectors.selectMenuItem, orderItem.menuItemId);
    const removeQuantity = orderItem?.personalSettings?.length ?? 1;
    yield gtmUtils.pushRemoveFromCart(menuItem, removeQuantity, landingPageSlug);
  }
}

function* handleLogAddItemToCart() {
  const orderItem = yield select(selectors.getOrderItem);
  const order = yield select(selectors.getOrder);
  const landingPageSlug = yield select(selectors.getCurrentLandingPageSlug);

  const menuItem = yield select(selectors.selectMenuItem, orderItem.menuItemId);
  const beforeQuantity = order.orderItems[orderItem.id]?.personalSettings?.length ?? 0;
  const afterQuantity = orderItem.personalSettings?.length ?? 0;

  if (afterQuantity > beforeQuantity) {
    yield gtmUtils.pushAddToCart(menuItem, afterQuantity - beforeQuantity, landingPageSlug);
  } else if (beforeQuantity > afterQuantity) {
    yield gtmUtils.pushRemoveFromCart(menuItem, beforeQuantity - afterQuantity, landingPageSlug);
  }
}

function* handleLogUpdateItemQuantityInCart(action) {
  const order = yield select(selectors.getOrder);
  const orderItem = order.orderItems[action.orderItemId];
  const landingPageSlug = yield select(selectors.getCurrentLandingPageSlug);

  if (orderItem?.menuItemId) {
    const menuItem = yield select(selectors.selectMenuItem, orderItem.menuItemId);
    const previousOrderItemState = action.state?.order?.orderItems[action.orderItemId];
    const beforeQuantity = previousOrderItemState?.personalSettings?.length ?? 0;
    const afterQuantity = action.quantity;

    if (afterQuantity > beforeQuantity) {
      yield gtmUtils.pushAddToCart(menuItem, afterQuantity - beforeQuantity, landingPageSlug);
    } else if (beforeQuantity > afterQuantity) {
      yield gtmUtils.pushRemoveFromCart(menuItem, beforeQuantity - afterQuantity, landingPageSlug);
    }
  }
}

function* handleLogCartConfirm(action) {
  const { order } = action;
  const orderGglocation = yield select(selectors.getOrderGglocation);
  const landingPageSlug = yield select(selectors.getCurrentLandingPageSlug);

  yield gtmUtils.pushCartConfirm(order, orderGglocation, landingPageSlug);
}

function* handleLogScanRewardSuccess(action) {
  const { response } = action;

  yield gtmUtils.pushScanRewardSuccess(response?.action);
}

function* handleLogClaimPromoCode(action) {
  if (action.pricing.discountName) {
    yield gtmUtils.pushClaimPromoCode(action.pricing);
  }
}

function* handleLogClaimUserReward() {
  const userRewardUuid = yield select(selectors.getSelectedUserRewardUuid);
  const claims = yield select(selectors.getClaims);
  const selectedUserReward = claims.find((claim) => claim.uuid === userRewardUuid);

  if (selectedUserReward) {
    yield gtmUtils.pushClaimUserReward(selectedUserReward);
  }
}

function* watchActions() {
  yield takeEvery('*', handleDefaultAction);
}

function* watchGtmEvents() {
  yield takeEvery(loggingActions.LOG_REMOVE_ITEM_FROM_CART, handleRemoveOrderItem);
  yield takeEvery(loggingActions.LOG_ADD_ITEM_TO_CART, handleLogAddItemToCart);
  yield takeEvery(orderActions.UPDATE_ORDER_ITEM_QUANTITY, handleLogUpdateItemQuantityInCart);
  yield takeEvery(loggingActions.LOG_CART_CONFIRM, handleLogCartConfirm);
  yield takeEvery(loyaltyActions.SET_SELECTED_PROMO_CODE_SUCCESS, handleLogClaimPromoCode);
  yield takeEvery(loyaltyActions.CONFIRM_HIGHLIGHTED_REWARD, handleLogClaimUserReward);
  yield takeEvery(apiCallSuccessChannel(`${loyaltyService.scanCode}`), handleLogScanRewardSuccess);
}

export default function* loggingSaga() {
  yield all([watchGtmEvents(), watchActions()]);
}
