import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderItemActions from '../../actions/orderItem';
import * as commonUtils from '../../utils/commonUtils';
import * as foodUtils from '../../utils/foodUtils';
import * as pricingUtils from '../../utils/pricingUtils';
import { getSectionSettings } from '../../utils/settingsUtils';

import cyoSectionShape from '../../shapes/cyoSectionShape';
import ingredientCategoryShape from '../../shapes/ingredientCategoryShape';
import ingredientShape from '../../shapes/ingredientShape';
import menuCategoryShape from '../../shapes/menuCategoryShape';
import menuItemShape from '../../shapes/menuItemShape';
import pricingVariationShape from '../../shapes/pricingVariationShape';
import { baseTypeSettingsShape, sectionSettingsShape } from '../../shapes/settingsShape';
import tagShape from '../../shapes/tagShape';

import IngredientsSectionHeader from '../../components/IngredientsSectionHeader';

class IngredientsSectionHeaderContainer extends Component {
  static propTypes = {
    menuItemId: PropTypes.number.isRequired,
    sectionId: PropTypes.number.isRequired,
    isCyo: PropTypes.bool,
    defaultIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    selectedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    selectedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    menuCategories: PropTypes.objectOf(menuCategoryShape).isRequired,
    tags: PropTypes.objectOf(tagShape).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    ingredientCategories: PropTypes.objectOf(ingredientCategoryShape).isRequired,
    sections: PropTypes.objectOf(cyoSectionShape).isRequired,
    baseTypeSettings: PropTypes.objectOf(baseTypeSettingsShape).isRequired,
    sectionSettings: PropTypes.objectOf(sectionSettingsShape).isRequired,
    globalIngredientRemovalLimit: PropTypes.number.isRequired,
    highlightedIngredientServings: PropTypes.objectOf(PropTypes.number).isRequired,
    pricingVariations: PropTypes.arrayOf(pricingVariationShape).isRequired,
    removeSectionIngredients: PropTypes.func.isRequired,
    restoreSectionIngredients: PropTypes.func.isRequired,
    saveHighlightedIngredientServings: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isCyo: false,
  };

  get sectionSettings() {
    const { sectionId, baseTypeSettings, sectionSettings } = this.props;

    return getSectionSettings({
      baseId: this.baseId,
      sectionId,
      baseTypeSettings,
      sectionSettings,
    });
  }

  get menuItem() {
    const { menuItems, menuItemId } = this.props;

    return menuItems[menuItemId];
  }

  get menuCategory() {
    const { menuCategories } = this.props;

    return menuCategories[this.menuItem.menuCategory];
  }

  get baseId() {
    return this.menuCategory.baseType;
  }

  get globalRemovedIngredientServings() {
    const { defaultIngredientServings, selectedIngredientServings } = this.props;

    return foodUtils.getRemovedIngredientServings(
      defaultIngredientServings,
      selectedIngredientServings,
    );
  }

  get remainingIngredientRemoveLimit() {
    const { globalIngredientRemovalLimit } = this.props;

    return globalIngredientRemovalLimit - this.globalRemovedIngredientServings;
  }

  get selectedIngredientServings() {
    const { sectionId, ingredients, ingredientCategories, selectedIngredientServings } = this.props;

    const selectedIngredientIds = foodUtils.ingredientIdsArray(selectedIngredientServings);
    const query = {
      selectedIngredientIds,
      selectedSectionIds: [sectionId],
    };
    const food = { ingredientCategories };

    return foodUtils.filterIngredientServings(ingredients, selectedIngredientServings, query, food);
  }

  get section() {
    const { sectionId, sections } = this.props;

    return sections[sectionId];
  }

  get isSectionToggleable() {
    const { isSectionToggleable } = this.sectionSettings;

    /* If the section has more ingredient servings than the removal limit allows, don't show the toggle */
    if (isSectionToggleable) {
      const selectedIngredientServingsNumber = commonUtils.sumObjectValues(
        this.selectedIngredientServings,
      );
      if (selectedIngredientServingsNumber <= this.remainingIngredientRemoveLimit) {
        return true;
      }
    }

    return false;
  }

  get hasPricingVariations() {
    const {
      ingredients,
      ingredientCategories,
      tags,
      selectedTagIds,
      highlightedIngredientServings,
      pricingVariations,
    } = this.props;

    return pricingVariations.some((pricingVariation) => {
      /* Match variations based on ingredient id */
      const ingredientId = pricingVariation.ingredient;
      if (ingredientId === null) return false;

      /* Match variations based on menu item */
      if (
        !pricingUtils.matchMenuItemPricingVariation(pricingVariation, { menuItem: this.menuItem })
      ) {
        return false;
      }

      /* Match variations based on section */
      const ingredient = ingredients[ingredientId];
      const ingredientCategory = ingredientCategories[ingredient.ingredientCategory];
      if (ingredientCategory.cyoSection !== this.section.id) return false;

      /* Match variations based on dietary preferences */
      if (!foodUtils.tagIngredientsFilter(ingredient, { selectedTagIds, tags })) return false;

      /* Match variations that can be applied next */
      const ingredientServings = highlightedIngredientServings[ingredientId];
      if (ingredientServings + 1 < pricingVariation.minimumServings) return false;
      if (ingredientServings + 1 > pricingVariation.maximumServings) return false;
      return true;
    });
  }

  handleSectionToggle = (e) => {
    const {
      sectionId,
      removeSectionIngredients,
      restoreSectionIngredients,
      saveHighlightedIngredientServings,
    } = this.props;

    if (e.target.checked) {
      removeSectionIngredients(sectionId, { store: 'highlighted' });
    } else {
      restoreSectionIngredients(sectionId, { store: 'highlighted' });
    }

    saveHighlightedIngredientServings();
  };

  render() {
    const { isCyo, menuItemId } = this.props;
    const { replaceable, showDietInformation } = this.sectionSettings;

    return (
      <IngredientsSectionHeader
        menuItemId={menuItemId}
        sectionId={this.section.id}
        name={this.section.name}
        description={this.section.desc}
        selectedIngredientServings={this.selectedIngredientServings}
        isCyo={isCyo}
        isSectionToggleable={this.isSectionToggleable}
        hasPricingVariations={this.hasPricingVariations}
        replaceable={replaceable}
        showDietInformation={showDietInformation}
        onSectionToggle={this.handleSectionToggle}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  defaultIngredientServings: state.orderItem.defaultIngredientServings,
  selectedIngredientServings: state.orderItem.selectedIngredientServings,
  selectedTagIds: state.food.selectedTagIds,
  menuItems: state.api.menuItems,
  menuCategories: state.api.menuCategories,
  tags: state.api.tags,
  ingredients: state.api.ingredients,
  ingredientCategories: state.api.ingredientCategories,
  sections: state.api.cyoSections,
  baseTypeSettings: state.api.settings.baseTypeSettings,
  sectionSettings: state.api.settings.sectionSettings,
  globalIngredientRemovalLimit: state.api.settings.globalIngredientRemovalLimit,
  highlightedIngredientServings: state.orderItem.highlightedIngredientServings,
  pricingVariations: state.api.pricingVariations,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeSectionIngredients: orderItemActions.removeSectionIngredients,
      restoreSectionIngredients: orderItemActions.restoreSectionIngredients,
      saveHighlightedIngredientServings: orderItemActions.saveHighlightedIngredientServings,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(IngredientsSectionHeaderContainer);
