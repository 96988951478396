import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as historyActions from '../../actions/history';
import * as loyaltyActions from '../../actions/loyalty';
import * as orderActions from '../../actions/order';
import * as userActions from '../../actions/user';
import diningChoiceEnum from '../../enums/diningChoiceEnum';
import orderTypeEnum from '../../enums/orderTypeEnum';
import { getSearchParam, setSearchParams } from '../../utils/historyUtils';

import gglocationShape from '../../shapes/gglocationShape';
import orderShape from '../../shapes/orderShape';
import routerShape, { routerLocationShape } from '../../shapes/routerShape';
import timeSlotShape from '../../shapes/timeSlotShape';

import Cart from '../../components/Cart';
import withRouter from '../WithRouter';

import { ORDER_ERROR_INTERPRETATION } from '../../settings';

class CartContainer extends Component {
  static propTypes = {
    order: orderShape.isRequired,
    gglocation: gglocationShape,
    timeSlot: timeSlotShape,
    optionalSurcharges: PropTypes.objectOf(PropTypes.object).isRequired,
    goToMenu: PropTypes.func.isRequired,
    beforeSignIn: PropTypes.func.isRequired,
    afterSignIn: PropTypes.func.isRequired,
    resetOrder: PropTypes.func.isRequired,
    openMenuItemPageAfterTimeSelected: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    isLandingPageOpen: PropTypes.bool.isRequired,

    /* React Router props */
    location: routerLocationShape.isRequired,
    router: routerShape.isRequired,
  };

  static defaultProps = {
    gglocation: null,
    timeSlot: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { order } = this.props;
    this.handleErrors(prevProps.order.errors, order.errors);
  }

  get orderItems() {
    const { order } = this.props;

    return order.orderItemsIds.map((orderItemId) => order.orderItems[orderItemId]);
  }

  get quantity() {
    if (this.orderItems.length === 0) return null;

    return this.orderItems
      .map((orderItem) => orderItem.personalSettings.length)
      .reduce((a, b) => a + b);
  }

  get capacity() {
    const { timeSlot } = this.props;

    if (timeSlot === null) return null;

    return timeSlot.itemSlotsAvailable;
  }

  get isCartShown() {
    const { location } = this.props;

    return getSearchParam(location.search, 'showCart') === 'true';
  }

  get errorMessage() {
    const { error } = this.state;

    return error && error.message;
  }

  get errorInterpretation() {
    const { error } = this.state;

    return error && ORDER_ERROR_INTERPRETATION[error.code];
  }

  /* No store error is handled differently from other errors */
  get noStoreError() {
    const { order } = this.props;

    const errorMessage = 'No stores available to handle the order';

    if (this.isDeliveryOrder && order.error?.message === errorMessage) {
      return 'No stores available to handle the order at this time';
    }

    return null;
  }

  get isDeliveryOrder() {
    const { order } = this.props;

    if (order.diningChoice === diningChoiceEnum.DELIVERY) {
      return true;
    }

    return false;
  }

  get deliveryInfo() {
    const { order } = this.props;
    const minOrderPrice =
      order.diningChoiceOptions.delivery &&
      order.diningChoiceOptions.delivery.deliveryPartner &&
      order.diningChoiceOptions.delivery.deliveryPartner.minimumOrderTotal;

    return {
      minOrderPrice,
      deliveryAddressHeader:
        order.deliveryAddress &&
        (order.deliveryAddress.buildingName || order.deliveryAddress.label),
      deliveryAddressLabel: order.deliveryAddress && order.deliveryAddress.label,
      deliveryDistance: order.deliveryDistance,
      estimatedDelivery: order.estimatedDelivery,
    };
  }

  handleCartHide = () => {
    const { location, clearErrors, router } = this.props;
    const { pathname, search } = location;

    this.setState({ error: null });
    clearErrors();

    router.replace({ pathname, search: setSearchParams(search, { showCart: false }) });
  };

  handleSignInClick = () => {
    const { location, beforeSignIn, afterSignIn, router } = this.props;
    const { pathname, search } = location;

    beforeSignIn({ pathname, search: setSearchParams(search, { showCart: false }) });
    afterSignIn({ pathname, search: setSearchParams(search, { showCart: false }) });
    router.push('/signIn');
  };

  handleClearCartClick = () => {
    const { resetOrder } = this.props;

    resetOrder();
  };

  handleAddItemClick = () => {
    const { goToMenu } = this.props;

    goToMenu({ option: 'replace' });
  };

  handleErrorResolveClick = () => {
    const { location, beforeSignIn, afterSignIn, openMenuItemPageAfterTimeSelected, router } =
      this.props;
    const { pathname, search } = location;

    this.setState({ error: null });

    switch (this.errorInterpretation) {
      case 'TIME': {
        openMenuItemPageAfterTimeSelected();
        router.push({ pathname: '/time' });
        break;
      }
      case 'LOCATION': {
        openMenuItemPageAfterTimeSelected();
        router.push({ pathname: '/locations' });
        break;
      }
      case 'PHONE_NUMBER': {
        beforeSignIn({ pathname, search });
        afterSignIn({ pathname, search });
        router.push({ pathname: '/signIn/phone' });
        break;
      }
      case 'TOKEN': {
        beforeSignIn({ pathname, search });
        afterSignIn({ pathname, search });
        router.push({ pathname: '/signIn' });
        break;
      }
      default:
      // Pass
    }
  };

  handleErrors = (previousErrors, newErrors) => {
    if (newErrors.length === 0 || previousErrors.length === newErrors.length) return null;
    this.setState({ error: newErrors[0].error });

    return null;
  };

  render() {
    const { gglocation, timeSlot, optionalSurcharges, loggedIn, isLandingPageOpen } = this.props;

    if (!gglocation && !isLandingPageOpen) return null;
    if (gglocation && gglocation.type === orderTypeEnum.SCHEDULED && !timeSlot) return null;

    return (
      <Cart
        gglocation={gglocation}
        orderItems={this.orderItems}
        show={this.isCartShown}
        errorMessage={this.errorMessage}
        errorInterpretation={this.errorInterpretation}
        noStoreError={this.noStoreError}
        quantity={this.quantity}
        capacity={this.capacity}
        loggedIn={loggedIn}
        deliveryInfo={this.deliveryInfo}
        optionalSurcharges={optionalSurcharges}
        isDeliveryOrder={this.isDeliveryOrder}
        onCartHide={this.handleCartHide}
        onSignInClick={this.handleSignInClick}
        onClearCartClick={this.handleClearCartClick}
        onAddItemClick={this.handleAddItemClick}
        onErrorResolveClick={this.handleErrorResolveClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.order,
  loggedIn: !!state.user.token,
  gglocation: state.api.gglocations[state.order.gglocationId],
  location: state.router.location,
  timeSlot: state.api.timeSlots[state.order.timeSlotId],
  optionalSurcharges: state.order.optionalSurcharges,
  isLandingPageOpen: state.page.isLandingPageOpen,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToMenu: historyActions.goToMenu,
      beforeSignIn: userActions.beforeSignIn,
      afterSignIn: userActions.afterSignIn,
      resetOrder: orderActions.resetOrder,
      openMenuItemPageAfterTimeSelected: orderActions.openMenuItemPageAfterTimeSelected,
      clearErrors: loyaltyActions.clearErrors,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartContainer));
