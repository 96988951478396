import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import * as selectors from '../../sagas/selectors';

import Button from '../Button';
import GuestLimitMessage from '../GuestLimitMessage';
import Modal from '../Modal';

import './GuestLimitModal.css';

function GuestLimitModal({ isModalOpen, hideModal }) {
  const guestLimit = useSelector(selectors.getGuestLimit);
  const { guestOrderQuantityLimit, guestOrderAmountLimit } = guestLimit || {};

  return (
    <Modal className="GuestLimitModal" show={isModalOpen}>
      <div className="modal-control" />
      <Modal.Body className="kale">
        <Typography className="guest-limit-title" variant="calafia2" color="primary">
          Oops! We&apos;re sorry...
        </Typography>
        <GuestLimitMessage
          guestOrderAmountLimit={guestOrderAmountLimit}
          guestOrderQuantityLimit={guestOrderQuantityLimit}
        />
        <Button className="btn-primary" onClick={hideModal}>
          Okay
        </Button>
      </Modal.Body>
    </Modal>
  );
}

GuestLimitModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  guestLimit: PropTypes.shape({
    guestOrderAmountLimit: PropTypes.string.isRequired,
    guestOrderQuantityLimit: PropTypes.number.isRequired,
  }).isRequired,
};

export default GuestLimitModal;
