import React from 'react';
import PropTypes from 'prop-types';

import tagShape from '../../../shapes/tagShape';

import Image from '../../../containers/Image';

import './DietInformation.css';

function DietInformation(props) {
  const { tags } = props;

  return (
    <div className="DietInformation">
      <ul>
        {tags.map((tag) => (
          <li key={tag.id}>
            <Image alt={tag.name} id={tag.image} />
          </li>
        ))}
      </ul>
    </div>
  );
}

DietInformation.propTypes = {
  tags: PropTypes.arrayOf(tagShape).isRequired,
};

export default DietInformation;
