/* eslint-disable import/prefer-default-export */

import { Gglocation } from '@spq/redux-api-client-ecom';
import { identity, mapValues } from 'lodash';
import { createTransform } from 'redux-persist';

const rehydrateGglocations = (outboundState) => {
  const { gglocations } = outboundState;

  return {
    ...outboundState,
    gglocations: mapValues(
      gglocations,
      (value) =>
        new Gglocation({
          gglocation: value,
          allTimeSlots: value.allTimeSlots,
        }),
    ),
  };
};

export const gglocationTransform = createTransform(identity, rehydrateGglocations, {
  whitelist: ['api'],
});
