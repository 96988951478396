import PropTypes from 'prop-types';

export default PropTypes.shape({
  direction: PropTypes.string.isRequired,
  discountAmount: PropTypes.string.isRequired,
  ingredient: PropTypes.number.isRequired,
  isPvarApplied: PropTypes.bool.isRequired,
  price: PropTypes.string.isRequired,
  serving: PropTypes.string.isRequired,
  singlePrice: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
});
