import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ingredientCategoryShape from '../../shapes/ingredientCategoryShape';

import Button from '../Button';

import './CategoryPaginationBar.css';

function CategoryPaginationBar(props) {
  const { activeIngredientCategoryId, ingredientCategoriesList, onCategorySelected } = props;

  return (
    <ul className="CategoryPaginationBar">
      <li>
        <ul className="kale">
          {ingredientCategoriesList.map((ingredientCategory) => (
            <li
              key={ingredientCategory.id}
              className={classnames({
                selected: ingredientCategory.id === activeIngredientCategoryId,
              })}
            >
              <Button onClick={() => onCategorySelected(ingredientCategory.id)}>
                {ingredientCategory.shortName}
              </Button>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
}

CategoryPaginationBar.propTypes = {
  activeIngredientCategoryId: PropTypes.number.isRequired,
  ingredientCategoriesList: PropTypes.arrayOf(ingredientCategoryShape).isRequired,
  onCategorySelected: PropTypes.func.isRequired,
};

export default CategoryPaginationBar;
