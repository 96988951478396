import * as foodActions from '../actions/food';
import * as foodUtils from '../utils/foodUtils';

const defaultState = Object.freeze({
  selectedTagIds: [],
  highlightedTagIds: [],
  conflictingIngredientIds: [],
  resolvedIngredientIds: [],
  ingredientDetailsId: null,
  isDietModalShown: false,
});

// eslint-disable-next-line default-param-last
export default function foodReducer(state = defaultState, action) {
  switch (action.type) {
    case foodActions.CLEAR_SELECTED_TAG_IDS: {
      return Object.freeze({
        ...state,
        selectedTagIds: [],
      });
    }
    case foodActions.ADD_HIGHLIGHTED_TAG_ID: {
      const highlightedTagIds = [...state.highlightedTagIds, action.id];

      return Object.freeze({
        ...state,
        highlightedTagIds,
      });
    }
    case foodActions.REMOVE_HIGHLIGHTED_TAG_ID: {
      const highlightedTagIds = state.highlightedTagIds.filter(
        (selectedId) => selectedId !== action.id,
      );

      return Object.freeze({
        ...state,
        highlightedTagIds,
      });
    }
    case foodActions.UPDATE_HIGHLIGHTED_TAG_IDS: {
      return Object.freeze({
        ...state,
        highlightedTagIds: action.highlightedTagIds,
      });
    }
    case foodActions.CLEAR_HIGHLIGHTED_TAG_IDS: {
      return Object.freeze({
        ...state,
        highlightedTagIds: [],
      });
    }
    case foodActions.CHECK_HIGHLIGHTED_TAG_IDS: {
      const filteredIngredientServings = foodUtils.tagIngredientServings(
        state.highlightedTagIds,
        action.ingredientServings,
        action.ingredients,
        action.tags,
      );

      const conflictingIngredientIds = Object.keys(action.ingredientServings)
        .filter((ingredientId) => filteredIngredientServings[ingredientId] === undefined)
        .map((ingredientId) => parseInt(ingredientId, 10));

      const selectedTagIds =
        conflictingIngredientIds.length > 0 ? state.selectedTagIds : state.highlightedTagIds;

      return Object.freeze({
        ...state,
        conflictingIngredientIds,
        selectedTagIds,
      });
    }
    case foodActions.SAVE_HIGHLIGHTED_TAG_IDS: {
      return Object.freeze({
        ...state,
        selectedTagIds: [...state.highlightedTagIds],
        conflictingIngredientIds: [],
      });
    }
    case foodActions.RESET_HIGHLIGHTED_TAG_IDS: {
      return Object.freeze({
        ...state,
        highlightedTagIds: [...state.selectedTagIds],
        conflictingIngredientIds: [],
      });
    }

    case foodActions.SHOW_INGREDIENT_MODAL: {
      return Object.freeze({
        ...state,
        ingredientDetailsId: action.ingredientId,
        bulkQuantity: action.bulkQuantity,
      });
    }
    case foodActions.HIDE_INGREDIENT_MODAL: {
      return Object.freeze({
        ...state,
        ingredientDetailsId: null,
      });
    }
    case foodActions.SHOW_DIET_MODAL: {
      return Object.freeze({
        ...state,
        isDietModalShown: true,
      });
    }
    case foodActions.HIDE_DIET_MODAL: {
      return Object.freeze({
        ...state,
        isDietModalShown: false,
      });
    }
    default:
      return state;
  }
}
