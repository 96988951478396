import { go, LOCATION_CHANGE, push, replace } from 'redux-first-history';
import { all, put, select, take, takeEvery } from 'redux-saga/effects';

import * as historyActions from '../actions/history';
import * as historyUtils from '../utils/historyUtils';

import * as selectors from './selectors';

import { auth2Service } from '../services/api';

function* startFromHome(action) {
  if (action.payload.action === 'POP') {
    const history = yield select(selectors.getHistory);
    const currentLocation = history.locations[history.currentIndex];
    const isNewVisit = history.locations.length === 1 && window.hasRefreshed === false;
    const isLandingPage = !!currentLocation.pathname.match('/site/.+');
    const isKioskPairing = !!currentLocation.pathname.match('/kioskPairing');
    const isScanOrderNumber = !!currentLocation.pathname.match('/scan');

    /* Replace users location with home on a new visit */
    if (isNewVisit && !isLandingPage && !isKioskPairing && !isScanOrderNumber) {
      yield put(replace({ pathname: '/' }));

      /* Redirect them back if they attempted to visit an allowed page */
      const allowedUrls = ['/order', '/browser', '/popup/payment', '/popup/facebook'];
      if (allowedUrls.some((url) => currentLocation.pathname.includes(url))) {
        yield put(push(currentLocation));
      }
    }
  }
}

function* checkAutologin(action) {
  const autologinToken = historyUtils.getSearchParam(action.payload.search, 'autologin_token');
  if (autologinToken) {
    yield put(auth2Service.autoLogin.requestActionCreator({ autologinToken }));
  }
}

function* handleLocationChange(action) {
  yield startFromHome(action);
  yield checkAutologin(action);
}

function* goHome() {
  const history = yield select(selectors.getHistory);
  const landingPageSlug = yield select(selectors.getCurrentLandingPageSlug);

  if (history.currentIndex !== 0) {
    const stepsToFirstLocation = history.currentIndex * -1;
    yield put(go(stepsToFirstLocation));
    yield take(LOCATION_CHANGE);
  }

  const firstPath = history.locations[0];

  // If flow has different entry point, go back to main/landing page
  if (firstPath) {
    if (landingPageSlug && /site\/.+/.test(firstPath.pathname)) {
      yield put(replace({ pathname: `/site/${landingPageSlug}` }));
    } else if (!landingPageSlug && firstPath.pathname !== '/') {
      yield put(replace({ pathname: '/' }));
    }
  }
}

function* goBack() {
  const history = yield select(selectors.getHistory);
  const stepsToPreviousLocation = historyUtils.determineStepsToPreviousLocation(
    history.currentIndex,
    history.locations,
  );
  yield put(go(stepsToPreviousLocation));
}

function* goBackTo(action) {
  const history = yield select(selectors.getHistory);
  const stepsToDesiredLocation = historyUtils.determineStepsToDesiredLocation(
    history.currentIndex,
    history.locations,
    action.pathname,
  );

  yield put(go(stepsToDesiredLocation));
}

function* goToMenu(action) {
  const landingPageSlug = yield select(selectors.getCurrentLandingPageSlug);
  const { option, params } = action;

  switch (option) {
    case 'push': {
      return yield put(
        push({
          pathname: landingPageSlug ? `/site/${landingPageSlug}` : '/menu',
          ...params,
        }),
      );
    }
    case 'replace': {
      return yield put(
        replace({
          pathname: landingPageSlug ? `/site/${landingPageSlug}` : '/menu',
          ...params,
        }),
      );
    }
    case 'back': {
      return yield put(
        historyActions.goBackTo(landingPageSlug ? `/site/${landingPageSlug}` : '/menu'),
      );
    }
    default: {
      return yield put(
        push({
          pathname: landingPageSlug ? `/site/${landingPageSlug}` : '/menu',
          ...params,
        }),
      );
    }
  }
}

function* watchNavigation() {
  yield takeEvery(LOCATION_CHANGE, handleLocationChange);
}

function* watchHistory() {
  yield takeEvery(historyActions.GO_HOME, goHome);
  yield takeEvery(historyActions.GO_BACK, goBack);
  yield takeEvery(historyActions.GO_BACK_TO, goBackTo);
  yield takeEvery(historyActions.GO_TO_MENU, goToMenu);
}

export default function* pageSaga() {
  yield all([watchNavigation(), watchHistory()]);
}
