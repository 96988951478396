const orderEnum = Object.freeze({
  CREATED: 0, // New Order
  SEEKING_PAYMENT_AUTH: 10, // Seeking payment auth
  SEEKING_PAYMENT_INVALID_DATA: 11, // Invalid order or payment data
  FAILED_PAYMENT_AUTH: 20, // Payment auth failed
  ORDER_QR_CHARGE_REQUEST_OK: 21, // QR request successful (QR code received,
  PAYMENT_AUTH_OK: 30, // Payment auth success
  TIMESLOT_RESERVED: 31, // Order Timeslot instance reserved
  LOG_EDC_SALES_OK: 32, // EDC sales logged
  ORDER_QR_CHARGE_SUCCESS: 33, // QR charge payments successfully received
  ORDER_QR_CHARGE_FAILED: 35, // QR charge failed
  ORDER_ON_HOLD: 38, // Order waiting to be sent to store (in case of auto-accept)
  PROCEEDING_TO_STORE: 40, // Order proceeding to store
  RECEIVED_AT_STORE: 50, // Order received and displayed
  SEEN: 60, // Order seen
  CANCELLED_BY_CUSTOMER: 70, // Order cancelled (customer,
  CANCELLED_BY_STORE: 80, // Order cancelled (store,
  ACCEPT_INITIATED: 90, // Order accept initiated
  PAYMENT_COMPLETING: 100, // Completing payment
  ACCEPT_ACKNOWLEDGED: 105, // Order accept acknowledged
  PAYMENT_UNSUCCESSFUL: 110, // Payment confirmed failure
  PAYMENT_SUCCESSFUL: 120, // Payment confirmed successful
  ACCEPTED: 121, // Order accepted
  ASSEMBLING: 122, // Assembling order
  DELIVERY_BOOKED: 125, // Delivery booked
  DELIVERY_CANCELLED: 126, // Delivery cancelled
  DELIVERY_COLLECTED: 127, // Delivery order collected
  COMPLETED: 130, // Order completed

  // Legacy
  LEGACY_SENT_TO_STORE_SUCCESS: 41, // Order sent to store successful
  LEGACY_SENT_TO_STORE_FAILED: 42, // Order sent to store failure
  LEGACY_CANCELLED_BY_CUSTOMER_SUCCESS: 71, // Order cancelled (customer) success
  LEGACY_CANCELLED_BY_CUSTOMER_FAILED: 72, // Order cancelled (customer) failed
  LEGACY_TIMESLOT_CANCELLED_BY_CUSTOMER: 75, // Order Timeslot instance cancelled by customer
  LEGACY_CANCELLED_BY_STORE_SUCCESS: 81, // Order cancelled (store) success
  LEGACY_CANCELLED_BY_STORE_FAILED: 82, // Order cancelled (store) failed
  LEGACY_TIMESLOT_CANCELLED_BY_STORE: 83, // Order Timeslot instance cancelled by store
  LEGACY_LABEL_PRINTED: 91, // Order label printed
  LEGACY_SENT_TO_LOYALTY_SUCCESS: 141, // Order sent to Loyalty API success
  LEGACY_SENT_TO_LOYALTY_FAILED: 142, // Order sent to Loyalty API failed

  // Error
  ORDER_ERROR_TIMESLOT_INSTANCE_NOT_FOUND: -17, // Not found - TS instance
  ORDER_ERROR_TIMESLOT_SCHEDULE_FULL: -16, // Timeslot full
  ORDER_ERROR_TIMESLOT_NOT_EXISTS: -15, // Timeslot not exists
  ORDER_ERROR_DUE_NOT_AVAILABLE: -14, // Store scheduled time not available
  ORDER_ERROR_STORE_BUSY: -13, // Store busy
  ORDER_ERROR_INGREDIENT_NOT_FOUND: -12, // Not found - ingredient
  ORDER_ERROR_PARTNER_INVALID: -11, // Invalid partner
  ORDER_ERROR_STORE_INVALID: -10, // Invalid store
  ORDER_ERROR_ORDER_TYPE_INVALID: -9, // Invalid order type
  ORDER_ERROR_PHONE_INVALID: -8, // Invalid phone
  ORDER_ERROR_USER_INVALID: -7, // Invalid user
  ORDER_ERROR_DUE_INVALID: -6, // Invalid due datetime
  ORDER_ERROR_TIMESLOT_INVALID: -5, // Invalid timeslot
  ORDER_ERROR_INGREDIENT_INVALID: -4, // Invalid ingredient
  ORDER_ERROR_MENU_INVALID: -3, // Invalid menu
  ORDER_ERROR_GGLMENU_INVALID: -2, // Invalid gglmenu
  ORDER_ERROR_GGLOCATION_INVALID: -1, // Invalid gglocation
});

export default orderEnum;
