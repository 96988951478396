import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as geolib from 'geolib';
import { isEqual, without } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as geolocationActions from '../../actions/geolocation';
import * as historyActions from '../../actions/history';
import * as userActions from '../../actions/user';
import statusEnum from '../../enums/statusEnum';

import geolocationShape from '../../shapes/geolocationShape';
import routerShape, { routerLocationShape } from '../../shapes/routerShape';

import AddAddress from '../../components/AddAddress';
import withRouter from '../WithRouter';

import ecomService, { esriService } from '../../services/api';
import * as settings from '../../settings';

class AddAddressContainer extends Component {
  static propTypes = {
    geolocation: geolocationShape.isRequired,
    errors: PropTypes.arrayOf(PropTypes.object).isRequired,
    token: PropTypes.string,
    requestGeolocation: PropTypes.func.isRequired,
    addCustomerAddress: PropTypes.func.isRequired,
    locationInvalid: PropTypes.func.isRequired,
    beforeSignIn: PropTypes.func.isRequired,
    afterSignIn: PropTypes.func.isRequired,

    location: routerLocationShape.isRequired,
    router: routerShape.isRequired,
  };

  static defaultProps = {
    token: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      address: '',
      floorUnit: '',
      buildingName: '',
      postcode: '',
      addressCoordinates: null,
      coordinates: null,
      deliveryInstruction: '',
      streetName: null,
      isPostcodeEditable: true,
      error: null,
    };

    const { requestGeolocation } = props;

    requestGeolocation();
  }

  componentDidMount() {
    const { geolocation, locationInvalid, beforeSignIn, afterSignIn, location, router } =
      this.props;

    if (!this.loggedIn) {
      locationInvalid();
      beforeSignIn({ pathname: '/' });
      afterSignIn({ pathname: location.pathname, search: location.search });
      router.push({ pathname: '/signIn' });
    }

    this.handleGeolocationUpdate(undefined, geolocation);
  }

  componentDidUpdate(prevProps) {
    const { errors, geolocation } = this.props;
    this.handleErrors(prevProps.errors, errors);

    this.handleGeolocationUpdate(prevProps.geolocation, geolocation);
  }

  async handleGeolocationUpdate(prevGeolocation, nextGeolocation) {
    if (!nextGeolocation) {
      return;
    }

    if (nextGeolocation.status !== statusEnum.SUCCESS) {
      return;
    }

    if (prevGeolocation && isEqual(prevGeolocation.coordinates, nextGeolocation.coordinates)) {
      return;
    }

    const { response, error } = await esriService.reverseGeocode.call({
      urlArgs: [nextGeolocation.coordinates?.lng, nextGeolocation.coordinates?.lat],
    });

    if (error) {
      this.addError({ message: `Fetching location failed: ${error.message}` });
      return;
    }

    if (response.address.CountryCode !== settings.COUNTRY_CODE_ALPHA3) {
      return;
    }

    const coordinates = {
      lng: response.location.x,
      lat: response.location.y,
    };

    this.setState({
      address: response.address.LongLabel,
      postcode: response.address.Postal,
      buildingName: response.address.PlaceName || response.address.AddNum,
      addressCoordinates: coordinates,
      coordinates,
      isPostcodeEditable: !response.address.Postal,
    });
  }

  handlePostcodeChange = (event) => {
    const postcode = event.target.value;

    const isCharactersValid = [...postcode].every((char) =>
      settings.POSTCODE_ALLOWED_CHARS.includes(char),
    );
    if (!isCharactersValid) {
      return;
    }

    if (postcode.length > settings.POSTCODE_MAX_LENGTH) {
      return;
    }

    this.setState({ postcode, error: null });
  };

  handleFloorUnitChange = (event) => {
    const floorUnit = event.target.value;

    this.setState({ floorUnit });
  };

  handleBuildingNameChange = (event) => {
    const buildingName = event.target.value;

    this.setState({ buildingName });
  };

  handleDeliveryInstructionChange = (event) => {
    const deliveryInstruction = event.target.value;

    this.setState({ deliveryInstruction });
  };

  handleErrors = (previousErrors, newErrors) => {
    if (newErrors.length === 0 || previousErrors.length === newErrors.length) return null;
    this.addError(newErrors[0].error);

    return null;
  };

  handleMapCoordinateChange = (event) => {
    const { addressCoordinates } = this.state;
    const eventCoordinates = event.target.getCenter();

    /* Disallow panning map until the address is entered. */
    if (!addressCoordinates) {
      return;
    }

    /*
        Calculate best matching coordinates within the bounds of given radius from the selected
        address.
        Will return submitted coordinates if they fit within the radius circle, otherwise will
        return point on the circle's edge in the direction of the submitted coordinate.
      */
    const coordinates = (() => {
      const addressPoint = {
        latitude: addressCoordinates.lat,
        longitude: addressCoordinates.lng,
      };
      const eventPoint = {
        latitude: eventCoordinates.lat,
        longitude: eventCoordinates.lng,
      };

      const isPointWithinRadius = geolib.isPointWithinRadius(
        addressPoint,
        eventPoint,
        settings.ADDRESS_MAP_ADJUST_RADIUS,
      );

      if (isPointWithinRadius) {
        return eventCoordinates;
      }

      const bearing = geolib.getRhumbLineBearing(addressPoint, eventPoint);

      const destinationPoint = geolib.computeDestinationPoint(
        addressPoint,
        settings.ADDRESS_MAP_ADJUST_RADIUS,
        bearing,
      );

      return {
        lat: destinationPoint.latitude,
        lng: destinationPoint.longitude,
      };
    })();

    this.setState({ coordinates });
  };

  handleAddressSelect = (data) => {
    const coordinates = {
      lng: data.attributes.X,
      lat: data.attributes.Y,
    };

    this.setState({
      error: null,
      address: data.address,
      // TODO: Hiding and force postcode for HK temporary (will be phase out later)
      postcode: AddAddressContainer.isHidePostcode ? '000000' : data.attributes.Postal,
      buildingName: data.attributes.AddBldg,
      addressCoordinates: coordinates,
      /*
          StName doesn't include the street number
          so if there is one (AddNum) we include it in the request.
        */
      streetName: without(
        [data.attributes.AddNum, data.attributes.StName],
        null,
        undefined,
        '',
      ).join(' '),
      coordinates,
      isPostcodeEditable: !data.attributes.Postal,
    });
  };

  handleInputFocus = () => {
    this.setState({ isInputFocused: true });
  };

  handleInputBlur = () => {
    this.setState({ isInputFocused: false });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();

    const { addCustomerAddress, location, router } = this.props;
    const {
      address,
      postcode,
      floorUnit,
      buildingName,
      streetName,
      coordinates,
      deliveryInstruction,
    } = this.state;

    if (!this.isAddressValid) {
      this.addError({ message: 'Please enter a valid address', field: 'address' });
      return;
    }

    if (!this.isPostcodeValid) {
      this.addError({ message: 'Please enter a valid postcode', field: 'postcode' });
      return;
    }

    this.setState({ error: null });
    addCustomerAddress({
      address,
      postcode,
      floorUnit,
      buildingName,
      streetName,
      coordinates,
      deliveryInstruction,
    });

    // Get current location and extract state, which includes the next link
    const nextLink = (location.state && location.state.nextLink) || '/time';

    router.push({ pathname: nextLink });
  };

  get loggedIn() {
    const { token } = this.props;

    return !!token;
  }

  get coordinates() {
    const { coordinates } = this.state;

    return coordinates || settings.DEFAULT_COORDINATES;
  }

  get isPostcodeValid() {
    const { postcode } = this.state;

    const isCharactersValid = [...postcode].every((char) =>
      settings.POSTCODE_ALLOWED_CHARS.includes(char),
    );
    if (!isCharactersValid) {
      return false;
    }

    if (postcode.length < settings.POSTCODE_MIN_LENGTH) {
      return false;
    }

    if (postcode.length > settings.POSTCODE_MAX_LENGTH) {
      return false;
    }

    return true;
  }

  get isAddressValid() {
    const { address } = this.state;

    return address.length > 4;
  }

  static get isHidePostcode() {
    return settings.COUNTRY_CODE === 'HK';
  }

  addError = ({ message, field }) => {
    this.setState({ error: { message, field } });
  };

  render() {
    const { coordinates } = this;
    const {
      address,
      addressCoordinates,
      postcode,
      floorUnit,
      buildingName,
      deliveryInstruction,
      isPostcodeEditable,
      isInputFocused,
      error,
    } = this.state;

    return (
      <AddAddress
        error={error}
        address={address}
        addressCoordinates={addressCoordinates}
        postcode={postcode}
        floorUnit={floorUnit}
        buildingName={buildingName}
        coordinates={coordinates}
        deliveryInstruction={deliveryInstruction}
        isHidePostcode={AddAddressContainer.isHidePostcode}
        isPostcodeEditable={isPostcodeEditable}
        isInputFocused={isInputFocused}
        onAddressSelect={this.handleAddressSelect}
        onMapCoordinatesChange={this.handleMapCoordinateChange}
        onPostcodeChange={this.handlePostcodeChange}
        onFloorUnitChange={this.handleFloorUnitChange}
        onBuildingNameChange={this.handleBuildingNameChange}
        onDeliveryInstructionChange={this.handleDeliveryInstructionChange}
        onInputFocus={this.handleInputFocus}
        onInputBlur={this.handleInputBlur}
        onFormSubmit={this.handleFormSubmit}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  geolocation: state.geolocation,
  location: state.router.location,
  token: state.user.token,
  errors: state.user.errors,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestGeolocation: geolocationActions.requestGeolocation,
      addCustomerAddress: ecomService.addCustomerAddress.requestActionCreator,
      locationInvalid: historyActions.locationInvalid,
      beforeSignIn: userActions.beforeSignIn,
      afterSignIn: userActions.afterSignIn,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAddressContainer));
