import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import diningChoiceEnum from '../../../enums/diningChoiceEnum';
import gglocationTypeEnum from '../../../enums/gglocationTypeEnum';
import * as orderUtils from '../../../utils/orderUtils';

import gglocationShape from '../../../shapes/gglocationShape';
import userOrderItemShape from '../../../shapes/userOrderItemShape';

import CurrentOrderItem from '../../../containers/Landing/CurrentOrderItem';
import Currency from '../../Currency';
import MomentCalendar from '../../MomentCalendar';

import iconLocale from '../../../images/icon-locale-black.svg';
import iconStation from '../../../images/icon-station.svg';
import iconStore from '../../../images/icon-store.svg';

function CurrentOrder(props) {
  const {
    id,
    gglocation,
    status,
    orderItems,
    diningChoice,
    orderNumber,
    deliveryAddress,
    totalPrice,
    pickupTime,
  } = props;

  const isDeliveryOrder = diningChoice === diningChoiceEnum.DELIVERY;
  const isStationOrder = gglocation?.gglocationType === gglocationTypeEnum.PARTNER;
  const icon = isStationOrder ? iconStation : iconStore;
  const imageSrc = isDeliveryOrder ? iconLocale : icon;
  const locationName = isDeliveryOrder ? deliveryAddress : gglocation?.name;

  return (
    <Link to={`/order/${id}`}>
      <div className="LandingCurrentOrder slideUp">
        <ul className="order-header">
          <li>
            <Typography variant="subtitle1" color="grey.main">
              <img src={imageSrc} alt="store" height="16" /> {locationName ?? orderNumber}
            </Typography>
          </li>
          {(orderUtils.isOrderStatusAccepted(status) ||
            orderUtils.isOrderStatusCompleted(status)) &&
            pickupTime && (
              <li>
                <p>
                  <MomentCalendar
                    options={{
                      sameDay: '[Today], h:mm a',
                      nextDay: '[Tomorrow], h:mm a',
                      nextWeek: 'MMM Mo',
                      sameElse: 'MMM Mo',
                    }}
                  >
                    {pickupTime}
                  </MomentCalendar>
                </p>
              </li>
            )}
          {orderUtils.isOrderStatusRequested(status) && (
            <li>
              <Typography variant="subtitle1" color="grey.main" fontStyle="italic">
                Pending
              </Typography>
            </li>
          )}
          {orderUtils.isOrderStatusCancelled(status) && (
            <li>
              <Typography variant="subtitle1" color="grey.main" fontStyle="italic">
                Cancelled
              </Typography>
            </li>
          )}
        </ul>
        <div className="order-body">
          {orderItems.map((orderItem) => (
            // eslint-disable-next-line react/jsx-key
            <CurrentOrderItem orderItem={orderItem} />
          ))}
          <p className="total">
            Total:&nbsp;
            <Currency value={totalPrice} />
          </p>
        </div>
      </div>
    </Link>
  );
}

CurrentOrder.propTypes = {
  id: PropTypes.string.isRequired,
  gglocation: gglocationShape,
  status: PropTypes.number.isRequired,
  orderItems: PropTypes.arrayOf(userOrderItemShape).isRequired,
  diningChoice: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  deliveryAddress: PropTypes.string,
  totalPrice: PropTypes.number.isRequired,
  pickupTime: PropTypes.string,
};

CurrentOrder.defaultProps = {
  gglocation: null,
  pickupTime: null,
  deliveryAddress: null,
};

export default CurrentOrder;
