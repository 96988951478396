import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as historyActions from '../../actions/history';
import * as pageActions from '../../actions/page';
import * as userActions from '../../actions/user';
import statusEnum from '../../enums/statusEnum';
import { setSearchParams } from '../../utils/historyUtils';

import { landingPageTopBarStyles } from '../../shapes/landingPageStyleShapes';
import routerShape, { routerLocationShape } from '../../shapes/routerShape';

import TopBar from '../../components/TopBar';
import withRouter from '../WithRouter';

class TopBarContainer extends Component {
  static propTypes = {
    landingPageStatus: PropTypes.number.isRequired,
    landingPageStyles: landingPageTopBarStyles.isRequired,
    gglocationSelected: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    isDeliveryAvailable: PropTypes.bool.isRequired,
    isMobileMenuOpen: PropTypes.bool.isRequired,
    isLandingPageOpen: PropTypes.bool.isRequired,
    /* @note isOverlay to indicate if TopBar was inside a full-screen modal  */
    isOverlay: PropTypes.bool,
    goHome: PropTypes.func.isRequired,
    returnToMenu: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    closeMobileMenu: PropTypes.func.isRequired,
    beforeSignIn: PropTypes.func.isRequired,
    afterSignIn: PropTypes.func.isRequired,
    onExternalBackClick: PropTypes.func,

    /* React Router props */
    location: routerLocationShape.isRequired,
    router: routerShape.isRequired,
  };

  static defaultProps = {
    isOverlay: false,
    onExternalBackClick: null,
  };

  get orderStarted() {
    const { gglocationSelected, isLandingPageOpen } = this.props;

    return gglocationSelected || isLandingPageOpen;
  }

  get landingPageStyles() {
    const { isLandingPageOpen, landingPageStyles } = this.props;

    return isLandingPageOpen ? landingPageStyles : {};
  }

  // Set hidden when on LP and it still hasn't loaded
  get isHidden() {
    const { landingPageStatus, location } = this.props;

    if (
      !!location.pathname.match('/site/.+') &&
      (!landingPageStatus || landingPageStatus < statusEnum.SUCCESS)
    ) {
      return true;
    }

    return false;
  }

  handleHomeClick = () => {
    const { goHome } = this.props;

    goHome();
  };

  handleSignInClick = () => {
    const { beforeSignIn, afterSignIn, location, router } = this.props;
    const { search } = location;

    beforeSignIn({
      pathname: location.pathname,
      search: setSearchParams(search, { showCart: false }),
    });
    afterSignIn({
      pathname: location.pathname,
      search: location.search,
    });
    router.push({ pathname: '/signIn' });
  };

  handleSignOutClick = () => {
    const { signOut } = this.props;

    signOut();
  };

  handleMobileMenuClose = () => {
    const { closeMobileMenu } = this.props;

    closeMobileMenu();
  };

  handleGoBackClick = () => {
    const { returnToMenu } = this.props;

    returnToMenu();
  };

  handleScanHistoryClick = () => {
    const { router } = this.props;

    router.push('/account/orders/?tab=rewards');
  };

  render() {
    const {
      isMobileMenuOpen,
      isDeliveryAvailable,
      isOverlay,
      loggedIn,
      isLandingPageOpen,
      onExternalBackClick,
    } = this.props;

    if (this.isHidden) {
      return null;
    }

    return (
      <TopBar
        orderStarted={this.orderStarted}
        isOverlay={isOverlay}
        isLandingPageOpen={isLandingPageOpen}
        landingPageStyles={this.landingPageStyles}
        loggedIn={loggedIn}
        isMobileMenuOpen={isMobileMenuOpen}
        isDeliveryAvailable={isDeliveryAvailable}
        onHomeClick={this.handleHomeClick}
        onBackClick={this.handleGoBackClick}
        onSignInClick={this.handleSignInClick}
        onSignOutClick={this.handleSignOutClick}
        onMobileMenuClose={this.handleMobileMenuClose}
        onScanHistoryClick={this.handleScanHistoryClick}
        onExternalBackClick={onExternalBackClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  history: state.history,
  location: state.router.location,
  isMobileMenuOpen: state.page.isMobileMenuOpen,
  gglocationSelected: !!state.order.gglocationId,
  isLandingPageOpen: state.page.isLandingPageOpen,
  landingPageStyles: state.landingPage.styling.topBar,
  isDeliveryAvailable: !!state.order.diningChoiceOptions.delivery,
  landingPageStatus: state.landingPage.status.landingPages,
  loggedIn: !!state.user.token,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goHome: historyActions.goHome,
      signOut: userActions.signOut,
      closeMobileMenu: pageActions.closeMobileMenu,
      returnToMenu: pageActions.returnToMenu,
      beforeSignIn: userActions.beforeSignIn,
      afterSignIn: userActions.afterSignIn,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBarContainer));
