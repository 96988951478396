import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import './IngredientMissingNotice.css';

function IngredientMissingNotice({ menuItemNames }) {
  return (
    <div className="IngredientMissingNotice">
      <div className="content-top">
        <Typography variant="h5" color="primary">
          Ingredient no longer available
        </Typography>
      </div>
      <p>
        We are sorry, but one of&nbsp;
        {menuItemNames.join(', ')}
        &apos;s ingredients is missing.
        <br />
        Please choose a different item.
      </p>
    </div>
  );
}

IngredientMissingNotice.propTypes = {
  menuItemNames: PropTypes.arrayOf(PropTypes.string),
};

IngredientMissingNotice.defaultProps = {
  menuItemNames: [],
};

export default IngredientMissingNotice;
