export default function geolocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation === undefined) {
      reject(new Error('Geolocation not supported'));
    } else {
      navigator.geolocation.getCurrentPosition(
        (result) => result && resolve(result),
        (reason) => reject(new Error(reason)),
        { timeout: 5000 },
      );
    }
  })
    .then((response) => response.coords)
    .then(
      (response) => ({ response }),
      (error) => ({ error: error.message || 'Undefined error' }),
    );
}
