import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import PropTypes from 'prop-types';

import Select, { Option } from '../Select';

import './TimePicker.css';

function TimePicker(props) {
  const { value, hours, minutes, onHoursChange, onMinutesChange } = props;

  return (
    <div className="TimePicker">
      <Select outerId="TimePickerHours" value={value.hours()} onChange={onHoursChange}>
        {hours.map((hour) => (
          <Option key={hour} value={hour}>
            <Moment format="HH">{moment().hours(hour)}</Moment>
          </Option>
        ))}
      </Select>
      <Select outerId="TimePickerMinutes" value={value.minutes()} onChange={onMinutesChange}>
        {minutes.map((minute) => (
          <Option key={minute} value={minute}>
            <Moment format="mm">{moment().minutes(minute)}</Moment>
          </Option>
        ))}
      </Select>
    </div>
  );
}

TimePicker.propTypes = {
  value: PropTypes.instanceOf(moment).isRequired,
  hours: PropTypes.arrayOf(PropTypes.number).isRequired,
  minutes: PropTypes.arrayOf(PropTypes.number).isRequired,
  onHoursChange: PropTypes.func.isRequired,
  onMinutesChange: PropTypes.func.isRequired,
};

export default TimePicker;
