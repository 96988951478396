import React from 'react';
import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as selectors from '../../../sagas/selectors';

import { landingPageMenuStyles } from '../../../shapes/landingPageStyleShapes';
import promoFlagShape from '../../../shapes/promoFlagShape';

import Image from '../../../containers/Image';
import Currency from '../../Currency';
import LinearIcon from '../../LinearIcon';

import './MenuItem.css';

function MenuItem(props) {
  const {
    name,
    image,
    price,
    energy,
    landingPageStyles,
    promoFlag,
    isAvailable,
    isUpselling,
    isUpsellSelected,
    onClick,
    onElementEnter,
    scrollableAncestor,
  } = props;

  const isNutritionalInformationHidden = useSelector(selectors.getIsNutritionalInformationHidden);

  return (
    <li
      className={classnames('MenuItem', {
        isUpselling,
        isUpsellSelected,
        unavailable: !isAvailable,
      })}
      style={landingPageStyles}
    >
      <Waypoint onEnter={onElementEnter} scrollableAncestor={scrollableAncestor} />
      <div role="presentation" onClick={onClick}>
        {promoFlag && promoFlag.isBadge && !isUpselling && (
          <div className="PromoFlag" style={{ backgroundColor: promoFlag.colorCode }}>
            {promoFlag.badgeText}
          </div>
        )}
        <div className={classnames('menu-image', { unavailable: !isAvailable })}>
          {image && <Image id={image} />}
          <div className="UpsellStatus">
            <LinearIcon name="checkmark-circle" />
            <span className="UpsellPrice">
              <Currency value={price} variant="ingredient" typographyVariant="h6" />
            </span>
          </div>
        </div>
        <div className={classnames('menu-desc', { unavailable: !isAvailable })}>
          <Typography
            variant="subtitle2"
            fontWeight="700"
            color="grey.main"
            style={{
              fontFamily: landingPageStyles && landingPageStyles.fontFamily,
              color: landingPageStyles && landingPageStyles.colorHeading,
            }}
          >
            {name}
          </Typography>
          {!!price && !isUpselling && (
            <p className="PriceWrapper">
              <Typography
                className="Price"
                style={{
                  fontFamily: landingPageStyles && landingPageStyles.fontFamily,
                  color: landingPageStyles && landingPageStyles.colorHeading,
                }}
              >
                <Currency value={price} />
              </Typography>
            </p>
          )}
          {energy !== null && !isUpselling && !isNutritionalInformationHidden && (
            <p className="EnergyWrapper">
              <span
                className="Energy"
                style={{ color: landingPageStyles && landingPageStyles.colorPageContent }}
              >
                <FormattedNumber value={energy} maximumFractionDigits={0} />
                &nbsp; kcal
              </span>
            </p>
          )}
        </div>
        {isUpselling && (
          <div className="UpsellMenuButton">
            {isUpsellSelected ? (
              <Typography variant="subtitle1" fontWeight="600">
                Added
              </Typography>
            ) : (
              <>
                <span>Add</span>
                <Currency value={price} variant="ingredient" typographyVariant="subtitle1" />
              </>
            )}
          </div>
        )}
      </div>
    </li>
  );
}

MenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.number,
  price: PropTypes.number,
  energy: PropTypes.number,
  promoFlag: promoFlagShape,
  landingPageStyles: landingPageMenuStyles.isRequired,
  isAvailable: PropTypes.bool,
  isUpselling: PropTypes.bool,
  isUpsellSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onElementEnter: PropTypes.func,
  scrollableAncestor: PropTypes.any,
};

MenuItem.defaultProps = {
  scrollableAncestor: window,
  price: null,
  image: null,
  energy: null,
  isAvailable: false,
  isUpselling: false,
  isUpsellSelected: false,
  promoFlag: null,
  onElementEnter: null,
};

export default MenuItem;
