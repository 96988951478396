import React from 'react';
import classnames from 'classnames';

import Spinner from '../Spinner';

import './OrderPriceSpinner.css';

function OrderPriceSpinner() {
  return <Spinner className={classnames('OrderPriceSpinner')} />;
}

export default OrderPriceSpinner;
