import PropTypes from 'prop-types';

export const landingPageTopBarStyles = PropTypes.shape({
  titleStyle: PropTypes.shape({
    fontFamily: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
  backgroudStyle: PropTypes.shape({
    backgroudColor: PropTypes.string.isRequired,
  }),
  cartButtonStyle: PropTypes.shape({
    color: PropTypes.string.isRequired,
  }),
  signInStyle: PropTypes.shape({
    color: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    '&:hover': PropTypes.string.isRequired,
  }),
});

export const landingPageMenuStyles = PropTypes.shape({
  categoryTitleStyle: PropTypes.shape({
    fontFamily: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
  categoryDescStyle: PropTypes.shape({
    fontFamily: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
  menuItemStyle: PropTypes.shape({
    fontFamily: PropTypes.string.isRequired,
    colorHeading: PropTypes.string.isRequired,
    colorPageContent: PropTypes.string.isRequired,
    borderColor: PropTypes.string.isRequired,
  }),
});
