import { useCallback, useEffect, useRef } from 'react';

import useIsomorphicLayoutEffect from './useIsoMorphicLayoutEffect';

const useTimeout = (callback) => {
  const savedCallback = useRef(callback);
  const timeoutRef = useRef(null);

  /* Remember the latest callback if it changes. */
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(
    () =>
      /* Always clear timeout when umount */
      () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      },
    [],
  );

  /* Create timeout action to be called with delay */
  const timeoutAction = useCallback((delay) => {
    /* Don't schedule if no delay is specified, 0 is a valid value for delay */
    if (!delay && delay !== 0) {
      return;
    }
    if (timeoutRef.current) {
      /* Clear previous timeout if exist */
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => savedCallback.current(), delay);
  }, []);

  return timeoutAction;
};

export default useTimeout;
