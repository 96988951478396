import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import paymentMethodShape from '../../../shapes/paymentMethodShape';

import Button from '../../Button';
import InputAlertText from '../../InputAlertText';
import PaymentIcon from '../../PaymentIcon';

import AlertIcon from '../../../images/icon-alert.svg';

import './PaymentFailure.css';

function PaymentFailure(props) {
  const { paymentMethod, onPaymentMethodAddClick, onOrderRetryClick } = props;

  return (
    <div className="PaymentFailure form-body">
      <Typography variant="calafia1" color="primary" className="SignInTitle visible-lg visible-md">
        Payment
      </Typography>
      <img className="PaymentFailureAlertIcon" src={AlertIcon} alt="alert" />
      <InputAlertText noIcon>
        Payment validation unsuccessful
        {paymentMethod && (
          <span>
            <span>
              {' '}
              with your payment method ending with
              {paymentMethod.label}
            </span>
            <PaymentIcon brand={paymentMethod.cardBrand} />
          </span>
        )}
      </InputAlertText>
      <p className="padded-bottom-large">Your transaction could not be processed.</p>
      <div>
        <Button className="btn-primary btn-short" onClick={onPaymentMethodAddClick}>
          {paymentMethod ? <span>Add another payment method</span> : <span>Retry</span>}
        </Button>
        {paymentMethod && (
          <Button className="blue-link padded-top" onClick={onOrderRetryClick}>
            Retry with this payment method
          </Button>
        )}
      </div>
    </div>
  );
}

PaymentFailure.propTypes = {
  paymentMethod: paymentMethodShape,
  onPaymentMethodAddClick: PropTypes.func.isRequired,
  onOrderRetryClick: PropTypes.func.isRequired,
};

PaymentFailure.defaultProps = {
  paymentMethod: null,
};

export default PaymentFailure;
