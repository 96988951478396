import { detect } from 'detect-browser';
import { push } from 'redux-first-history';
import { put } from 'redux-saga/effects';

import * as browserActions from '../actions/browser';

function* checkBrowserCompatibility() {
  const browser = detect();
  if (browser.name === 'edge' || browser.name === 'ie') {
    yield put(push({ pathname: '/browser' }));
  } else {
    yield put(browserActions.browserCheckPassed());
  }
}

export default function* browserSaga() {
  yield checkBrowserCompatibility();
}
