import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import orderDeliveryInfoShape from '../../../shapes/orderDeliveryInfoShape';

import Button from '../../Button';
import Info from '../../Info';

import './OrderDeliveryInfo.css';

function OrderDeliveryInfo({ deliveryInfo, isAccepted }) {
  if (deliveryInfo && deliveryInfo.shareLink) {
    return (
      <Button
        className="btn-secondary deliveryTrackingLink"
        href={deliveryInfo?.shareLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        Track your delivery
      </Button>
    );
  }
  if (isAccepted) {
    return (
      <Typography variant="body2" color="grey.main" className="deliveryTips">
        <Info />
        Delivery time may vary by up to 30 mins due to rider availability.
      </Typography>
    );
  }
  return null;
}

OrderDeliveryInfo.propTypes = {
  deliveryInfo: orderDeliveryInfoShape,
  isAccepted: PropTypes.bool,
};

OrderDeliveryInfo.defaultProps = {
  deliveryInfo: null,
  isAccepted: false,
};

export default OrderDeliveryInfo;
