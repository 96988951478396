import { apiCallSuccessChannel } from '@spq/redux-api-client';
import { toCamelCaseKeys, toUnderscoreKeys } from '@spq/utils';
import moment from 'moment';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import * as loyaltyActions from '../actions/loyalty';
import * as userActions from '../actions/user';
import statusEnum from '../enums/statusEnum';
import * as orderUtils from '../utils/orderUtils';

import { VERSION as version } from '../version';
import * as selectors from './selectors';

import ecomService, { loyaltyService } from '../services/api';

function* handleSignedInUser() {
  const uuid = yield select(selectors.getUserUuid);
  const token = yield select(selectors.getUserToken);
  const brand = yield select(selectors.getLoyaltyBrandPrefix);

  loyaltyService.setToken(token);

  if (uuid) {
    yield put(loyaltyService.scanHistory.requestActionCreator());
    yield put(loyaltyService.rewards.requestActionCreator({ uuid, brand }));
    yield put(loyaltyService.initData.requestActionCreator({ brand }));
    yield put(loyaltyService.fetchMassRewards.requestActionCreator({ brand }));
  }
}

function* setSelectedPromoCode({ promoCode }) {
  /* Check if promo code is valid */
  const order = yield select(selectors.getOrder);
  const gglocations = yield select(selectors.getGglocations);
  const gglocation = gglocations.gglocations[order.gglocationId];
  const timeSlot = gglocations.timeSlots[order.timeSlotId];
  const userRewardUuid = yield select(selectors.getSelectedUserRewardUuid);
  const selectedPaymentMethod = yield select(selectors.getSelectedPaymentMethod);
  const { diningChoice, customerAddressId } = order;

  const body = {
    order,
    gglocation,
    timeSlot,
    userRewardUuid,
    promoCode,
    diningChoice,
    customerAddressId,
    version,
    paymentMethod: selectedPaymentMethod,
  };
  const result = yield ecomService.previewOrder.call(body);

  if (result.error) {
    yield put({
      type: loyaltyActions.SET_SELECTED_PROMO_CODE_ERROR,
      error: {
        ...result.error,
        /* To unify the format with API errors */
        endpointName: 'promoCode',
      },
    });
  } else if (result.response.warning) {
    /*
      If there is a warning when promo code is being applied, we want users to fix that issue
      first.
    */
    yield put({
      type: loyaltyActions.SET_SELECTED_PROMO_CODE_ERROR,
      error: {
        success: false,
        message: result.response.warning,
        code: null,
        data: result.response.warning,
        endpointName: 'promoCode',
      },
    });
  } else {
    const { response } = result;
    yield put({
      type: loyaltyActions.SET_SELECTED_PROMO_CODE_SUCCESS,
      promoCode,
      pricing: {
        discountName: response.discountName,
        discountSource: response.discountSource,
      },
    });
  }
}

function* processScanCode({ newCode, geolocation }) {
  const scanHistory = yield select(selectors.getScanHistory);

  const scanHistoryList = [...scanHistory.pending, ...scanHistory.verified];

  const isExisting = scanHistoryList.find((item) => item.ref === newCode) !== undefined;
  if (isExisting) {
    yield put(
      loyaltyActions.scanCodeError({
        error: { endpointName: 'scanCode', error: { message: 'Existing code' } },
      }),
    );
  } else {
    const location =
      geolocation.status !== statusEnum.SUCCESS || !geolocation.coordinates
        ? undefined
        : {
            lat: geolocation.coordinates?.lat,
            lng: geolocation.coordinates?.lng,
          };
    const body = toUnderscoreKeys({
      subActionType: 'In-Store Purchase',
      transactionDetails: {
        uuid: newCode,
        location,
        transactionDate: moment().toJSON(),
      },
    });
    yield put(loyaltyService.scanCode.requestActionCreator({ body }));
  }
}

function* handleRewardClaimed() {
  const uuid = yield select(selectors.getUserUuid);
  const token = yield select(selectors.getUserToken);
  const brand = yield select(selectors.getLoyaltyBrandPrefix);

  loyaltyService.setToken(token);
  yield put(loyaltyService.rewards.requestActionCreator({ uuid, brand }));
  yield put(loyaltyService.initData.requestActionCreator({ brand }));
}

function* handleUpdateOrder(action) {
  const updatedResponse = toCamelCaseKeys(action.response);
  const isGuestSession = yield select(selectors.getIsGuestSession);
  if (orderUtils.isOrderStatusCompleted(updatedResponse.orderStatusId) && !isGuestSession) {
    yield put(handleRewardClaimed);
  }
}

function* watchUser() {
  yield takeEvery(userActions.SIGNED_IN, handleSignedInUser);
}

function* watchPromoCode() {
  yield takeEvery(loyaltyActions.SET_SELECTED_PROMO_CODE_REQUESTED, setSelectedPromoCode);
}

function* watchScanCode() {
  yield takeEvery(loyaltyActions.DISPATCH_SCAN_CODE, processScanCode);
}

function* watchRewardClaim() {
  yield takeEvery(apiCallSuccessChannel(`${loyaltyService.claimReward}`), handleRewardClaimed);
  yield takeEvery(userActions.UPDATE_ORDER, handleUpdateOrder);
}

export default function* orderSaga() {
  yield all([watchUser(), watchPromoCode(), watchRewardClaim(), watchScanCode()]);
}
