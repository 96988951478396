import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Currency from '../Currency';

import './OrderItemPriceDelta.css';

function OrderItemPriceDelta({ originalPriceDifference, totalPriceDifference, isDiscounted }) {
  return (
    <div className={classnames('OrderItemPriceDelta', { isDiscounted })}>
      <span className="OriginalPrice">
        <Currency value={originalPriceDifference} />
      </span>
      {isDiscounted && (
        <span className="DiscountedPrice">
          <Currency value={totalPriceDifference} />
        </span>
      )}
    </div>
  );
}

OrderItemPriceDelta.propTypes = {
  originalPriceDifference: PropTypes.number,
  totalPriceDifference: PropTypes.number,
  isDiscounted: PropTypes.bool.isRequired,
};

OrderItemPriceDelta.defaultProps = {
  originalPriceDifference: null,
  totalPriceDifference: null,
};

export default OrderItemPriceDelta;
