import { setPusherClient } from 'react-pusher';
import Pusher from 'pusher-js';

import { PUSHER_APP_KEY, PUSHER_SETTINGS } from './settings';

const configurePusher = (store) => {
  const pusherClient = new Pusher(PUSHER_APP_KEY, PUSHER_SETTINGS);

  pusherClient.connection.bind('initialized', () => {
    store.dispatch({ type: 'PUSHER_INITIALIZED' });
  });
  pusherClient.connection.bind('connecting', () => {
    store.dispatch({ type: 'PUSHER_CONNECTING' });
  });
  pusherClient.connection.bind('connected', () => {
    store.dispatch({ type: 'PUSHER_CONNECTED' });
  });
  pusherClient.connection.bind('unavailable', () => {
    store.dispatch({ type: 'PUSHER_UNAVAILABLE' });
  });
  pusherClient.connection.bind('failed', () => {
    store.dispatch({ type: 'PUSHER_FAILED' });
  });
  pusherClient.connection.bind('disconnected', () => {
    store.dispatch({ type: 'PUSHER_DISCONNECTED' });
  });

  setPusherClient(pusherClient);
  return pusherClient;
};

export default configurePusher;
