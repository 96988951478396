export const CHANGE_ORDER_GGLOCATION = 'CHANGE_ORDER_GGLOCATION';
export const CHANGE_ORDER_TIME = 'CHANGE_ORDER_TIME';
export const CHANGE_ORDER_ADDRESS = 'CHANGE_ORDER_ADDRESS';

export const changeOrderGglocation = () => ({
  type: CHANGE_ORDER_GGLOCATION,
});

export const changeOrderTime = () => ({
  type: CHANGE_ORDER_TIME,
});

export const changeOrderAddress = () => ({
  type: CHANGE_ORDER_ADDRESS,
});
