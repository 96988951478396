// The version naming is based on https://github.com/npm/node-semver/blob/v7.5.4/functions/diff.js

const semVerEnum = Object.freeze({
  SAME: 'same',
  MAJOR: 'major',
  MINOR: 'minor',
  PATCH: 'patch',
  PREMAJOR: 'premajor',
  PREMINOR: 'preminor',
  PREPATCH: 'prepatch',
  PRERELEASE: 'prerelease',
});

export default semVerEnum;
