import React from 'react';
import PropTypes from 'prop-types';

import './Image.css';

function Image(props) {
  const { alt, src, context } = props;
  return <img className={`Image-${context}`} alt={alt} src={src} />;
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
};

export default Image;
