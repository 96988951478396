import React from 'react';
import PropTypes from 'prop-types';

import favoriteMenuItemShape from '../../shapes/favoriteMenuItemShape';
import menuItemShape from '../../shapes/menuItemShape';

import MenuCategory from '../../containers/Menu/MenuCategory';
import SectionSeparator from '../SectionSeparator';

import './TitledMenuSection.css';

function TitledMenuSection(props) {
  const { title, menuItems, hideUnavailable, onClick } = props;
  if (!menuItems || menuItems.length === 0) return null;

  return (
    <div className="TitledMenuSection" id="menu-navigate-favorite">
      {title && <SectionSeparator typographyVariant="h5">{title}</SectionSeparator>}
      <MenuCategory
        scrollable
        hideUnavailable={hideUnavailable}
        menuItems={menuItems}
        onClick={onClick}
      />
    </div>
  );
}

TitledMenuSection.propTypes = {
  title: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.oneOfType([menuItemShape, favoriteMenuItemShape]))
    .isRequired,
  hideUnavailable: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

TitledMenuSection.defaultProps = {
  title: null,
  hideUnavailable: false,
};

export default TitledMenuSection;
