import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Image from '../../../containers/Image';
import IngredientChoicePrice from '../../IngredientsPicker/IngredientChoicePrice';

import { PLACEHOLDER_IMAGE } from '../../../settings';

import './Ingredient.css';

function Ingredient(props) {
  const {
    servings,
    name,
    image,
    size,
    removeable,
    replaceable,
    disabled,
    resolved,
    selected,
    included,
    price,
    conflictedTagNames,
  } = props;

  return (
    <div
      className={classnames('Ingredient', 'slideUp', size, {
        removeable,
        replaceable,
        disabled,
        resolved,
        selected,
      })}
    >
      <div className="IngredientImage">
        {image === null ? <img alt="" src={PLACEHOLDER_IMAGE} /> : <Image id={image} />}
      </div>
      <div className="IngredientName">
        <Typography variant="body2" color="grey.main">
          {servings > 1 && <span className="Servings">{servings}</span>}
          {name}
        </Typography>
      </div>
      {!disabled && included && <div className="IngredientIncludeLabel">Included</div>}
      {resolved && conflictedTagNames && (
        <div className="IngredientResolveLabel">
          Removed:
          {conflictedTagNames}
        </div>
      )}
      {!disabled && !included && !!price && (
        <div className="IngredientIncludePrice">
          <IngredientChoicePrice price={price} />
        </div>
      )}
    </div>
  );
}

Ingredient.propTypes = {
  servings: PropTypes.number,
  name: PropTypes.string.isRequired,
  image: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['Label', 'Medium', 'Small', 'ExtraSmall']),
  removeable: PropTypes.bool,
  replaceable: PropTypes.bool,
  disabled: PropTypes.bool,
  resolved: PropTypes.bool,
  selected: PropTypes.bool,
  included: PropTypes.bool,
  price: PropTypes.number,
  conflictedTagNames: PropTypes.string,
};

Ingredient.defaultProps = {
  servings: 1,
  size: 'Medium',
  removeable: false,
  replaceable: false,
  disabled: false,
  resolved: false,
  selected: false,
  included: false,
  price: undefined,
  conflictedTagNames: undefined,
};

export default Ingredient;
