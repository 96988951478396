import PropTypes from 'prop-types';

const claimShape = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  claimed: PropTypes.string,
  milestone: PropTypes.string,
  orderUuid: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['item', 'voucher', 'discount']),
  name: PropTypes.string.isRequired,
  desc: PropTypes.string, // TODO: May be prepare these in loyalty backend?
  rewardName: PropTypes.string,
  isCollection: PropTypes.bool,
  terms: PropTypes.string.isRequired,
  expiry: PropTypes.string.isRequired,
  expiring: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  rewardType: PropTypes.string.isRequired,
  claimCondition: PropTypes.string.isRequired,
  claimImageUrl: PropTypes.string.isRequired,
});

claimShape.secondaryTo = PropTypes.shape(claimShape);

export default claimShape;
