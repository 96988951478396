import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import * as pageActions from '../../actions/page';

import DiningChoiceSelector from '../Landing/DiningChoiceSelector';
import Modal from '../Modal';

import './DiningChoiceModal.css';

function DiningChoiceModal({ isModalOpen, hideModal }) {
  const dispatch = useDispatch();

  useEffect(
    () =>
      // After the modal unmounts, update the state
      () => {
        dispatch(pageActions.closeDiningChoiceModal());
      },
    [],
  );

  return (
    <Modal className="DiningChoiceModal" show={isModalOpen}>
      <div className="modal-control">
        <ul>
          <li>
            <Modal.Dismiss className="btn-close-modal" onClick={hideModal} />
          </li>
        </ul>
      </div>
      <Modal.Body>
        <DiningChoiceSelector />
      </Modal.Body>
    </Modal>
  );
}

DiningChoiceModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default DiningChoiceModal;
