import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as commonUtils from '../../utils/commonUtils';
import * as foodUtils from '../../utils/foodUtils';

import favoriteMenuItemShape from '../../shapes/favoriteMenuItemShape';
import ingredientShape from '../../shapes/ingredientShape';
import menuItemIngredientServingsShape from '../../shapes/menuItemIngredientServingsShape';
import menuItemShape from '../../shapes/menuItemShape';
import { baseTypeSettingsShape } from '../../shapes/settingsShape';

import FavoriteMenuItem from '../../components/FavoriteMenuItem';

import ecomService from '../../services/api';

class FavoriteMenuItemContainer extends Component {
  static propTypes = {
    className: PropTypes.string,
    data: favoriteMenuItemShape.isRequired,
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    menuItemIngredientServings: menuItemIngredientServingsShape.isRequired,
    menuGroups: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string.isRequired)).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    gglocationId: PropTypes.number,
    gglocationMenuGroups: PropTypes.objectOf(PropTypes.numbers).isRequired,
    baseTypeSettings: PropTypes.objectOf(baseTypeSettingsShape).isRequired,
    onEditFavoriteOpen: PropTypes.func.isRequired,
    removeFavoriteMenuItem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    gglocationId: null,
  };

  get ingredientBreakdown() {
    const { data } = this.props;

    return data.ingredientBreakdown;
  }

  get baseId() {
    return this.menuItem.baseType;
  }

  get baseSettings() {
    const { baseTypeSettings } = this.props;

    return baseTypeSettings[this.baseId];
  }

  get menuItem() {
    const { data } = this.props;

    return data;
  }

  get originalMenuItem() {
    const { menuItems, gglocationId, gglocationMenuGroups, menuGroups } = this.props;
    const menuGroupId = gglocationMenuGroups[gglocationId];
    const availableMenuGroups = commonUtils.getMenuItemGglocationMenus({
      apiId: this.menuItem.apiId,
      menuGroups,
    });

    /* If the menu is available in current menu group, return it
    else, return the menuItem available in other menu groups */
    return availableMenuGroups.includes(menuGroupId)
      ? menuItems[`${this.menuItem.apiId}_${menuGroupId}`]
      : menuItems[`${this.menuItem.apiId}_${availableMenuGroups[0]}`];
  }

  get originalMenuItemId() {
    return this.originalMenuItem ? this.originalMenuItem.id : null;
  }

  get originalMenuItemIngredientServings() {
    const { menuItemIngredientServings } = this.props;

    if (!this.originalMenuItem) {
      return null;
    }

    return menuItemIngredientServings[this.originalMenuItemId];
  }

  get compositeIngredientsText() {
    const { ingredients } = this.props;

    const compositeIngredient = foodUtils.getCompositeIngredient(
      this.menuItem.ingredientServings,
      ingredients,
    );

    return compositeIngredient.compositeIngredients;
  }

  get defaultIngredientsText() {
    const { ingredients } = this.props;

    if (!this.originalMenuItem) {
      return '';
    }

    return foodUtils.defaultIngredientsText(this.originalMenuItemIngredientServings, ingredients);
  }

  get cyoIngredientsText() {
    const { ingredients } = this.props;

    const ingredientChangesText = foodUtils.ingredientChangesText(
      this.ingredientBreakdown,
      ingredients,
    );

    return ingredientChangesText.added;
  }

  get ingredientsText() {
    if (this.baseSettings?.premade) return this.compositeIngredientsText;
    if (this.menuItem.isCyo) return this.cyoIngredientsText;

    return this.defaultIngredientsText;
  }

  get ingredientChangesText() {
    const { ingredients } = this.props;

    const ingredientChangesText = foodUtils.ingredientChangesText(
      this.ingredientBreakdown,
      ingredients,
    );

    return ingredientChangesText;
  }

  get addedIngredientsText() {
    if (this.baseSettings?.premade) return null;
    if (this.menuItem.isCyo) return null;

    return this.ingredientChangesText.added || null;
  }

  get removedIngredientsText() {
    if (this.baseSettings?.premade) return null;
    if (this.menuItem.isCyo) return null;

    return this.ingredientChangesText.removed || null;
  }

  handleRemove = () => {
    const { data, removeFavoriteMenuItem } = this.props;

    return removeFavoriteMenuItem({ id: data.id });
  };

  handleEditFavoriteOpen = () => {
    const { data, onEditFavoriteOpen } = this.props;

    return onEditFavoriteOpen(data.id);
  };

  render() {
    const { className, data } = this.props;

    return (
      <FavoriteMenuItem
        className={className}
        data={data}
        menuItem={this.menuItem}
        ingredientsText={this.ingredientsText}
        addedIngredientsText={this.addedIngredientsText}
        removedIngredientsText={this.removedIngredientsText}
        onRemove={this.handleRemove}
        onEditFavoriteOpen={this.handleEditFavoriteOpen}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  menuItems: state.api.menuItems,
  menuItemIngredientServings: state.api.menuItemIngredientServings,
  menuGroups: state.api.menuGroups,
  gglocationMenuGroups: state.api.gglocationMenuGroups,
  gglocationId: state.order.gglocationId,
  ingredients: state.api.ingredients,
  ingredientCategories: state.api.ingredientCategories,
  baseTypeSettings: state.api.settings.baseTypeSettings,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeFavoriteMenuItem: ecomService.removeFavoriteMenuItem.requestActionCreator,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteMenuItemContainer);
