import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';

import broadcastMessageEnum from '../../enums/broadcastMessageEnum';
import { getSearchParam } from '../../utils/historyUtils';
import { BroadcastMessageChannel } from '../../utils/pageUtils';

import './FacebookPopup.css';

import { routerLocationShape } from '../../shapes/routerShape';

class FacebookPopupContainer extends Component {
  static propTypes = {
    location: routerLocationShape.isRequired,
  };

  componentDidMount() {
    const { location } = this.props;
    const { search } = location;

    const response = { accessToken: getSearchParam(search, 'access_token') };
    window.response = response;
    window.completed = true;

    // Set popup response and close the window
    const channel = new BroadcastMessageChannel(broadcastMessageEnum.FACEBOOK_POPUP);
    channel.postMessage(response);

    window.close();
  }

  render() {
    const { location } = this.props;
    const { search } = location;
    const success = getSearchParam(search, 'access_token');

    return (
      <div className="FacebookPopupContainer">
        <div className="FacebookPopupText">
          <Typography variant="h6" color="primary">
            Log in {success ? 'success' : 'failed'}
          </Typography>
          <Typography variant="subtitle1" color="primary">
            Please <strong>close</strong> this tab if it is not auto closed.
          </Typography>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: state.router.location,
});

export default connect(mapStateToProps, undefined)(FacebookPopupContainer);
