import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Gglocation } from '@spq/redux-api-client-ecom';
import { compareDates } from '@spq/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../../actions/order';
import diningChoiceEnum from '../../../enums/diningChoiceEnum';
import gglocationTypeEnum from '../../../enums/gglocationTypeEnum';
import orderTypeEnum from '../../../enums/orderTypeEnum';
import { getDefaultDeliveryTimeInterval } from '../../../sagas/selectors';
import { selectScheduler } from '../../../utils/gglocationUtils';

import orderShape from '../../../shapes/orderShape';
import storeBusyShape from '../../../shapes/storeBusyShape';

import ScheduleTimePicker from '../../../components/PickupTimeSelector/ScheduleTimePicker';
import Scheduler from '../../Scheduler';

class ScheduleTimePickerContainer extends Component {
  static propTypes = {
    menuItemId: PropTypes.string,
    order: orderShape.isRequired,
    storeBusyDetails: storeBusyShape,
    scheduler: PropTypes.instanceOf([Scheduler, Gglocation]).isRequired,
    deliveryTimeInterval: PropTypes.number.isRequired,
    setOrderType: PropTypes.func.isRequired,
    setOrderTime: PropTypes.func.isRequired,
  };

  static defaultProps = {
    menuItemId: undefined,
    storeBusyDetails: {},
  };

  get diningChoice() {
    const { order } = this.props;

    return order.diningChoice;
  }

  getScheduledOrderTimesOnSameDate(selectedTime) {
    const { diningChoice } = this;
    const { scheduler, order } = this.props;
    const orderTime = selectedTime ?? moment(order.orderTime);

    return orderTime && scheduler.getScheduledOrderTimesOnSameDate({ orderTime, diningChoice });
  }

  get processingTime() {
    const { diningChoice } = this;
    const { scheduler, storeBusyDetails, deliveryTimeInterval } = this.props;
    const storeBusy = scheduler.gglocationId && storeBusyDetails[scheduler.gglocationId];
    const estimatedTime = scheduler.getProcessingTime({ diningChoice, storeBusy });

    if (estimatedTime && diningChoice === diningChoiceEnum.DELIVERY) {
      // Show an estimated time range for delivery order
      return [estimatedTime, estimatedTime + deliveryTimeInterval];
    }

    return [estimatedTime];
  }

  get nextTimeSlots() {
    const { diningChoice, isStationOrder } = this;
    const { scheduler } = this.props;

    if (isStationOrder) {
      return scheduler?.nextTimeSlots({ diningChoice }) ?? [];
    }
    return [];
  }

  get pickupDates() {
    const { diningChoice, isStationOrder } = this;
    const { scheduler } = this.props;

    if (isStationOrder) {
      // Remove duplicate date as time slots can have same date
      return [
        ...new Set(
          this.nextTimeSlots.map((timeSlot) => moment(timeSlot.datetime).startOf('day').valueOf()),
        ),
      ].sort();
    }

    const days = scheduler.possibleScheduledOrderDays({ diningChoice });

    return days.slice(0, 5);
  }

  get canPlaceInstantOrder() {
    const { diningChoice, isStationOrder } = this;
    const { scheduler } = this.props;

    if (isStationOrder) {
      return this.nextTimeSlots.length > 0;
    }

    return scheduler.canPlaceInstantOrder({ diningChoice });
  }

  get showCart() {
    const { order } = this.props;

    return order.openMenuItemPageAfterTimeSelected;
  }

  get isDeliveryOrder() {
    const { diningChoice } = this;

    return diningChoice === diningChoiceEnum.DELIVERY;
  }

  get noStoreError() {
    const { order } = this.props;

    const errorMessage = 'No stores available to handle the order';

    if (this.isDeliveryOrder && order.error?.message === errorMessage) {
      return 'Sorry, there are no stores serving your area at the chosen time, please pick a different time';
    }

    return null;
  }

  get isStationOrder() {
    const { scheduler } = this.props;

    return scheduler.gglocationType === gglocationTypeEnum.PARTNER;
  }

  handleTypeSelected = (selectedType) => {
    const { setOrderType, order } = this.props;

    if (selectedType === orderTypeEnum.SCHEDULED && order.orderTime === null) {
      this.handleDateSelected(this.pickupDates[0]?.valueOf());
    }
    setOrderType(selectedType);
  };

  handleDateSelected = (value) => {
    const { setOrderTime } = this.props;
    const selectedDate = moment(parseInt(value, 10));

    if (this.isStationOrder) {
      return setOrderTime(selectedDate);
    }

    const scheduledOrderTimes =
      this.getScheduledOrderTimesOnSameDate(selectedDate).sort(compareDates);

    return setOrderTime(moment(scheduledOrderTimes[0]));
  };

  render() {
    const { menuItemId, order } = this.props;

    return (
      <ScheduleTimePicker
        isDeliveryOrder={this.isDeliveryOrder}
        isStationOrder={this.isStationOrder}
        gglocationId={this.gglocationId}
        menuItemId={menuItemId}
        showCart={this.showCart}
        canPlaceInstantOrder={this.canPlaceInstantOrder}
        pickupDates={this.pickupDates}
        processingTime={this.processingTime}
        selectedType={order.orderType}
        noStoreError={this.noStoreError}
        selectedTime={moment(order.orderTime)}
        onTypeSelected={this.handleTypeSelected}
        onDateSelected={this.handleDateSelected}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  scheduler: selectScheduler(state),
  order: state.order,
  storeBusyDetails: state.api.storeBusyDetails,
  deliveryTimeInterval: getDefaultDeliveryTimeInterval(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setOrderType: orderActions.setOrderType,
      setOrderTime: orderActions.setOrderTime,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTimePickerContainer);
