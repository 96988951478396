import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { sortDietTags } from '../../utils/foodUtils';

import tagShape from '../../shapes/tagShape';

import Button from '../Button';
import Modal from '../Modal';
import DietModalButton from './DietModalButton';

import './DietModal.css';

function DietModal(props) {
  const { isOpen, tags, highlightedTagIds, onModalHide, onSave, onTagClick } = props;
  return (
    <Modal className="DietModal" show={isOpen} onHide={onModalHide}>
      <Modal.Body className="kale">
        <Typography variant="calafia2" color="primary.dark">
          Allergens
        </Typography>
        <div className="diet-support-list">
          {tags.sort(sortDietTags).map((tag) => (
            <DietModalButton
              key={tag.id}
              id={tag.id}
              name={tag.name}
              image={tag.image}
              selected={highlightedTagIds.includes(tag.id)}
              onClick={() => onTagClick(tag.id)}
            />
          ))}
        </div>
        <div className="DietModalButtonRow">
          <Button className="btn-secondary" onClick={onModalHide}>
            Cancel
          </Button>
          <Button className="btn-primary" onClick={onSave}>
            Next
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

DietModal.propTypes = {
  isOpen: PropTypes.bool,
  tags: PropTypes.arrayOf(tagShape).isRequired,
  highlightedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSave: PropTypes.func.isRequired,
  onModalHide: PropTypes.func.isRequired,
  onTagClick: PropTypes.func.isRequired,
};

DietModal.defaultProps = {
  isOpen: true,
};

export default DietModal;
