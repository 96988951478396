import React, { Component } from 'react';
import { connect } from 'react-redux';
import { union, without } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../actions/order';
import gglocationTypeEnum from '../../enums/gglocationTypeEnum';
import statusEnum from '../../enums/statusEnum';
import { getRecommendedGglocationIds } from '../../utils/gglocationUtils';

import geolocationShape from '../../shapes/geolocationShape';
import gglocationShape from '../../shapes/gglocationShape';
import timeSlotShape from '../../shapes/timeSlotShape';

import GglocationsSelect from '../../components/GglocationsSelect';

class GglocationsSelectContainer extends Component {
  static propTypes = {
    icon: PropTypes.string,
    isUsedSignedIn: PropTypes.bool.isRequired,
    orderGglocationId: PropTypes.string,
    diningChoice: PropTypes.string.isRequired,
    isLandingPageOrder: PropTypes.bool.isRequired,
    landingPageDisabledGglocationTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
    landingPagePartners: PropTypes.arrayOf(PropTypes.number).isRequired,
    isStationFlow: PropTypes.bool,
    api: PropTypes.shape({
      gglocations: PropTypes.objectOf(gglocationShape).isRequired,
      timeSlots: PropTypes.objectOf(timeSlotShape).isRequired,
      mostFrequentGglocationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
      mostRecentGglocationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    geolocation: geolocationShape.isRequired,
    updateGglocation: PropTypes.func.isRequired,
    resetGglocation: PropTypes.func.isRequired,
    onSelectionUpdate: PropTypes.func,
  };

  static defaultProps = {
    icon: '',
    orderGglocationId: null,
    isStationFlow: false,
    onSelectionUpdate: null,
  };

  componentDidMount() {
    const { orderGglocationId, resetGglocation } = this.props;

    if (orderGglocationId === null) {
      resetGglocation();
    }
  }

  get isDistanceShown() {
    const { isUsedSignedIn, geolocation } = this.props;

    if (!isUsedSignedIn) return false;
    if (geolocation.status !== statusEnum.SUCCESS) return false;

    return true;
  }

  get gglocationIds() {
    const {
      isUsedSignedIn,
      orderGglocationId,
      diningChoice,
      api,
      geolocation,
      isLandingPageOrder,
      landingPageDisabledGglocationTypes,
      landingPagePartners,
      isStationFlow,
    } = this.props;

    const excludedGglocationTypes = [
      isStationFlow ? gglocationTypeEnum.STORE : gglocationTypeEnum.PARTNER,
    ];
    const gglocationIds = getRecommendedGglocationIds({
      isUsedSignedIn,
      api,
      isLandingPageOrder,
      landingPageDisabledGglocationTypes,
      landingPagePartners,
      geolocation,
      diningChoice,
      excludedGglocationTypes,
    });

    return without(union(gglocationIds, [orderGglocationId]), null, 'null_null');
  }

  get gglocationsList() {
    const { api, isStationFlow } = this.props;
    const { gglocations } = api;

    return this.gglocationIds
      .map((gglocationId) => gglocations[gglocationId])
      .filter((gglocation) =>
        isStationFlow
          ? gglocation.gglocationType === gglocationTypeEnum.PARTNER
          : gglocation.gglocationType === gglocationTypeEnum.STORE,
      );
  }

  handleGglocationSelected = (event) => {
    const gglocationId = event.target.value;
    const { api, updateGglocation, isLandingPageOrder, onSelectionUpdate } = this.props;
    const { gglocations, timeSlots } = api;

    const gglocation = gglocations[gglocationId];
    if ((isLandingPageOrder && gglocation) || !isLandingPageOrder) {
      // Dont reset gglocation for landing page when other store is chosen
      // until it is selected by user
      updateGglocation({ gglocation, timeSlots });
    }
    if (onSelectionUpdate) {
      // Update selection to parent
      onSelectionUpdate(gglocation);
    }
  };

  render() {
    const { icon, orderGglocationId, isStationFlow } = this.props;
    if (this.gglocationsList.length === 0) return null;

    const result = (
      <GglocationsSelect
        icon={icon}
        orderGglocationId={orderGglocationId}
        gglocationsList={this.gglocationsList}
        isDistanceShown={this.isDistanceShown}
        isStationFlow={isStationFlow}
        onGglocationSelected={this.handleGglocationSelected}
      />
    );

    return result;
  }
}

const mapStateToProps = (state) => ({
  isUsedSignedIn: state.user.signedIn,
  orderGglocationId: state.order.gglocationId,
  diningChoice: state.order.diningChoice,
  landingPagePartners: state.landingPage.associatedPartners,
  isStationFlow: state.order.isStationFlow,
  api: {
    gglocations: state.api.gglocations,
    timeSlots: state.api.timeSlots,
    mostFrequentGglocationIds: state.api.mostFrequentGglocationIds,
    mostRecentGglocationIds: state.api.mostRecentGglocationIds,
  },
  geolocation: state.geolocation,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateGglocation: orderActions.updateGglocation,
      resetGglocation: orderActions.resetGglocation,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GglocationsSelectContainer);
