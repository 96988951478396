import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class Option extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  childrenRendererObserver = null;

  constructor(props, context) {
    super(props, context);

    this.childrenRenderer = React.createRef();

    this.state = {
      label: null,
    };
  }

  componentDidMount() {
    this.connectChildrenRenderedObserver();
  }

  componentWillUnmount() {
    this.disconnectChildrenRendererObserver();
  }

  handleChildrenRenderedUpdate() {
    const label = this.childrenRenderer.current.innerText;
    this.setState({ label });
  }

  connectChildrenRenderedObserver() {
    this.childrenRendererObserver = new MutationObserver(
      this.handleChildrenRenderedUpdate.bind(this),
    );
    this.childrenRendererObserver.observe(this.childrenRenderer.current, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    this.handleChildrenRenderedUpdate();
  }

  disconnectChildrenRendererObserver() {
    this.childrenRendererObserver.disconnect();
  }

  render() {
    const { children, ...otherProps } = this.props;
    const { label } = this.state;

    return (
      <>
        <div ref={this.childrenRenderer}>{children}</div>
        <option {...otherProps}>{label}</option>
      </>
    );
  }
}

export default Option;
