import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as selectors from '../../sagas/selectors';
import { setSearchParams } from '../../utils/historyUtils';

import gglocationShape from '../../shapes/gglocationShape';

import Distance from '../../containers/Distance';
import Image from '../../containers/Image';
import GglocationName from '../GglocationName';
import NextAvailableLabel from '../NextAvailableLabel';

import './Gglocation.css';

function Gglocation(props) {
  const { gglocation, isDisabled, isHighlighted, onClick } = props;

  const landingPageSlug = useSelector(selectors.getCurrentLandingPageSlug);
  const { search } = useSelector(selectors.getCurrentLocation);

  const clickLink = !landingPageSlug
    ? ''
    : { pathname: `/site/${landingPageSlug}`, search: setSearchParams(search, { showCart: true }) };

  if (!gglocation) return null;

  const gglocationElement = (
    <li
      key={gglocation.id}
      className={classnames('Gglocation', 'slideUp', {
        selected: isHighlighted,
        disabled: isDisabled,
      })}
    >
      <div className="store-img">
        <Image
          id={gglocation?.images?.banner}
          source="gglocationsImages"
          context="original"
          oneSize
        />
      </div>
      <ul className="store-info">
        <li>
          <GglocationName name={gglocation.name} logo={gglocation?.images?.logo} />
          <Typography variant="body2" className="StoreInfoAddress">
            {gglocation.address1 ?? ''} {gglocation.address2 ?? ''}
          </Typography>
          {gglocation.isAvailable ? (
            <Typography variant="body2" color="primary">
              <strong>Available: </strong>
              <span>
                <NextAvailableLabel gglocation={gglocation} verbose />
              </span>
            </Typography>
          ) : (
            <span>Not available now</span>
          )}
        </li>
        <li>
          <Distance {...gglocation?.coordinates} />
        </li>
      </ul>
    </li>
  );

  if (isDisabled) {
    return gglocationElement;
  }

  return (
    <Link to={clickLink} onClick={onClick}>
      {gglocationElement}
    </Link>
  );
}

Gglocation.propTypes = {
  gglocation: gglocationShape.isRequired,
  isDisabled: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  menuItemId: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

Gglocation.defaultProps = {
  isDisabled: false,
  isHighlighted: false,
  menuItemId: undefined,
};

export default Gglocation;
