import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as foodActions from '../../actions/food';
import { getIngredientLevelTags } from '../../utils/foodUtils';

import ingredientShape from '../../shapes/ingredientShape';
import tagShape from '../../shapes/tagShape';

import DietModal from '../../components/DietModal';

class DietModalContainer extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    tags: PropTypes.objectOf(tagShape).isRequired,
    ingredients: PropTypes.objectOf(ingredientShape).isRequired,
    highlightedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    highlightedIngredientServings: PropTypes.arrayOf(PropTypes.number).isRequired,
    onModalHide: PropTypes.func.isRequired,
    addHighlightedTagId: PropTypes.func.isRequired,
    resetHighlightedTagIds: PropTypes.func.isRequired,
    checkHighlightedTagIds: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
  };

  get tagsArray() {
    const { tags } = this.props;
    const ingredientLevelTags = getIngredientLevelTags(tags);

    return Object.values(ingredientLevelTags);
  }

  handleTagClick = (id) => {
    const { addHighlightedTagId } = this.props;

    addHighlightedTagId(id);
  };

  handleModalHide = () => {
    const { onModalHide, resetHighlightedTagIds } = this.props;

    onModalHide();
    resetHighlightedTagIds();
  };

  handleSave = () => {
    const {
      highlightedIngredientServings,
      onModalHide,
      checkHighlightedTagIds,
      tags,
      ingredients,
    } = this.props;

    onModalHide();
    checkHighlightedTagIds({
      ingredientServings: highlightedIngredientServings,
      tags,
      ingredients,
    });
  };

  render() {
    const { isOpen, highlightedTagIds } = this.props;

    return (
      <DietModal
        isOpen={isOpen}
        tags={this.tagsArray}
        highlightedTagIds={highlightedTagIds}
        onSave={this.handleSave}
        onModalHide={this.handleModalHide}
        onTagClick={this.handleTagClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  tags: state.api.tags,
  highlightedTagIds: state.food.highlightedTagIds,
  highlightedIngredientServings: state.orderItem.highlightedIngredientServings,
  ingredients: state.api.ingredients,
  isOpen: state.food.isDietModalShown,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addHighlightedTagId: foodActions.addHighlightedTagId,
      resetHighlightedTagIds: foodActions.resetHighlightedTagIds,
      checkHighlightedTagIds: foodActions.checkHighlightedTagIds,
      onModalHide: foodActions.hideDietModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DietModalContainer);
