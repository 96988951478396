import React, { Component } from 'react';

import { isLocalStorageSupported } from '../../utils/errorResolutionUtils';

import IncognitoWarning from '../../components/IncognitoWarning';

class IncognitoWarningContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: !isLocalStorageSupported(),
    };
  }

  handleModalHide = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const { modalOpen } = this.state;

    return <IncognitoWarning modalOpen={modalOpen} onModalHide={this.handleModalHide} />;
  }
}

export default IncognitoWarningContainer;
