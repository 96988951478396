import PropTypes from 'prop-types';

const historyItemShape = PropTypes.shape({
  ref: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  summary: PropTypes.shape.isRequired,
});

const historyShape = PropTypes.shape({
  expired: PropTypes.arrayOf(historyItemShape),
  expiring: PropTypes.arrayOf(historyItemShape),
  earned: PropTypes.arrayOf(historyItemShape),
});

const rewardShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
  rewardUuid: PropTypes.string.isRequired,
  discountUuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  imageDashboardUrl: PropTypes.string,
  history: historyShape,
  terms: PropTypes.string.isRequired,
  validUntil: PropTypes.string.isRequired,
  isCollection: PropTypes.bool.isRequired,
  isPromo: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isDollarProgress: PropTypes.bool.isRequired,
  desc: PropTypes.string.isRequired,
  longDesc: PropTypes.string.isRequired,
  earned: PropTypes.number.isRequired,
  total: PropTypes.string,
  rewardStart: PropTypes.string,
  rewardEnd: PropTypes.string,
  rewardName: PropTypes.string.isRequired,
  rewardStatus: PropTypes.oneOf(['IN_PROGRESS', 'AWARDED', 'NOTIFIED', 'CLAIMED']),
  tnc: PropTypes.string.isRequired,
});

export default rewardShape;
