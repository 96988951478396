import React, { Component } from 'react';
import { connect } from 'react-redux';
import { chain } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderItemActions from '../../../actions/orderItem';
import * as pageActions from '../../../actions/page';
import * as foodUtils from '../../../utils/foodUtils';

import favoriteMenuItemShape from '../../../shapes/favoriteMenuItemShape';
import ingredientNutrientAmountsShape from '../../../shapes/ingredientNutrientAmountsShape';
import ingredientServingsShape from '../../../shapes/ingredientServingsShape';
import { landingPageMenuStyles } from '../../../shapes/landingPageStyleShapes';
import menuItemShape from '../../../shapes/menuItemShape';

import MenuItem from '../../../components/Menu/MenuItem';

import { ENERGY_ID } from '../../../settings';

class MenuItemContainer extends Component {
  static propTypes = {
    data: PropTypes.oneOfType([menuItemShape, favoriteMenuItemShape]).isRequired,
    landingPageStyles: landingPageMenuStyles.isRequired,
    visible: PropTypes.bool,
    isUpselling: PropTypes.bool,
    isUpsellSelected: PropTypes.bool,
    hideUnavailable: PropTypes.bool,
    gglocationId: PropTypes.string,
    gglocationDisabledIngredients: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number))
      .isRequired,
    onClick: PropTypes.func,
    ingredientServings: PropTypes.objectOf(ingredientServingsShape).isRequired,
    ingredientNutrientAmounts: ingredientNutrientAmountsShape.isRequired,
    createOrderItemFromMenuItemId: PropTypes.func.isRequired,
    createOrderItemFromFavoriteMenuItemId: PropTypes.func.isRequired,
    openMenuItemPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    visible: false,
    isUpselling: false,
    isUpsellSelected: false,
    hideUnavailable: false,
    gglocationId: null,
    onClick: null,
  };

  constructor(props, context) {
    super(props, context);

    this.scrollableAncestor = document.querySelector('.MenuContentWrapper');

    const { visible } = props;
    this.state = {
      visible,
    };
  }

  get isFavorite() {
    const { data } = this.props;

    return data.isFavorite;
  }

  get ingredientServings() {
    const { data, ingredientServings } = this.props;

    if (this.isFavorite) {
      return data.ingredientServings;
    }

    return ingredientServings[`${data.apiId}_${data.menuGroupId}`];
  }

  get unavailableIngredientIds() {
    const { gglocationId, gglocationDisabledIngredients } = this.props;

    if (!gglocationDisabledIngredients) {
      return [];
    }

    return gglocationDisabledIngredients[gglocationId] || [];
  }

  get isAvailable() {
    return (
      chain(this.ingredientServings)
        .keys()
        .map((key) => parseInt(key, 10))
        .intersection(this.unavailableIngredientIds)
        .value().length === 0
    );
  }

  get energyAmount() {
    const { ingredientNutrientAmounts, data } = this.props;

    const nutrients = foodUtils.totalIngredientsNutrientAmounts(
      this.ingredientServings,
      ingredientNutrientAmounts,
    );

    if (data.bulkQuantity > 1) {
      return nutrients[ENERGY_ID] * data.bulkQuantity;
    }

    return nutrients[ENERGY_ID];
  }

  get name() {
    const { data } = this.props;

    return data.name;
  }

  get promoFlag() {
    const { data } = this.props;

    if (data.flags.length === 0) return null;

    return data.flags.sort((flagA, flagB) => {
      if (flagA.order < flagB.order) return -1;
      if (flagA.order > flagB.order) return 1;
      return 0;
    })[0];
  }

  handleVisibilityChange = () => {
    this.setState({ visible: true });

    return null;
  };

  handleClick = () => {
    const {
      data,
      onClick,
      createOrderItemFromMenuItemId,
      createOrderItemFromFavoriteMenuItemId,
      openMenuItemPage,
    } = this.props;

    if (!this.isAvailable) {
      return false;
    }

    if (onClick) {
      const id = this.isFavorite ? data.id : `${data.apiId}_${data.menuGroupId}`;
      return onClick({ id, isFavorite: this.isFavorite });
    }

    if (this.isFavorite) {
      createOrderItemFromFavoriteMenuItemId({
        favoriteMenuItemId: data.id,
      });
    } else {
      createOrderItemFromMenuItemId({ menuItemId: `${data.apiId}_${data.menuGroupId}` });
    }

    openMenuItemPage();
    return true;
  };

  render() {
    const { data, landingPageStyles, isUpselling, isUpsellSelected, hideUnavailable } = this.props;
    const { visible } = this.state;

    if (hideUnavailable && !this.isAvailable) {
      return null;
    }

    return (
      <MenuItem
        name={this.name}
        image={data.image}
        price={data.price}
        landingPageStyles={landingPageStyles}
        energy={this.energyAmount}
        promoFlag={this.promoFlag}
        isAvailable={this.isAvailable}
        isUpselling={isUpselling}
        isUpsellSelected={isUpsellSelected}
        onClick={this.handleClick}
        onElementEnter={this.handleVisibilityChange}
        visible={isUpselling || visible}
        scrollableAncestor={this.scrollableAncestor}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ingredientServings: state.api.menuItemIngredientServings,
  ingredientNutrientAmounts: state.api.ingredientNutrientAmounts,
  gglocationId: state.order.gglocationId,
  gglocationDisabledIngredients: state.api.gglocationDisabledIngredients,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOrderItemFromMenuItemId: orderItemActions.createOrderItemFromMenuItemId,
      createOrderItemFromFavoriteMenuItemId: orderItemActions.createOrderItemFromFavoriteMenuItemId,
      openMenuItemPage: pageActions.openMenuItemPage,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemContainer);
