import PropTypes from 'prop-types';

const massRewardShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
  validFor: PropTypes.string.isRequired,
  validFrom: PropTypes.string.isRequired,
  validTo: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['DR', 'PU', 'DI']),
  isMassReward: PropTypes.bool.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  applicableDaysOfWeek: PropTypes.arrayOf(PropTypes.number).isRequired,
  desc: PropTypes.string.isRequired,
  longDesc: PropTypes.string.isRequired,
  tnc: PropTypes.string.isRequired,
  country: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  claimName: PropTypes.string.isRequired,
  claimCondition: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  dashboardImageUrl: PropTypes.string.isRequired,
  claimImageUrl: PropTypes.string.isRequired,
});

export default massRewardShape;
