import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import orderItemShape from '../../shapes/orderItemShape';

import Button from '../Button';
import ButtonsRow from '../ButtonsRow';
import Modal from '../Modal';
import IngredientMissingNotice from './IngredientMissingNotice';
import MenuItemMissingNotice from './MenuItemMissingNotice';
import MenuPageGglocationMissingNotice from './MenuPageGglocationMissingNotice';

import './OrderDataValidator.css';

function OrderDataValidator(props) {
  const {
    isMenuItemMissing,
    isIngredientMissing,
    isFavoriteMenuItem,
    isMenuItemAvailableInOtherLocations,
    isMenuPageGglocationMissing,
    disabledOrderItems,
    menuItemNames,
    onResetOrder,
    onResetOrderItem,
    onRemoveOrderItem,
    onFavoriteRemove,
  } = props;

  const navigate = useNavigate();

  let notice;
  if (isMenuPageGglocationMissing) {
    notice = <MenuPageGglocationMissingNotice menuItemNames={menuItemNames} />;
  } else if (isMenuItemMissing) {
    notice = (
      <MenuItemMissingNotice
        menuItemNames={menuItemNames}
        isMenuItemAvailableInOtherLocations={isMenuItemAvailableInOtherLocations}
      />
    );
  } else if (disabledOrderItems.length > 0) {
    notice = (
      <IngredientMissingNotice
        menuItemNames={disabledOrderItems.map(({ orderItemName }) => orderItemName)}
      />
    );
  } else if (isIngredientMissing) {
    notice = <IngredientMissingNotice menuItemNames={menuItemNames} />;
  }

  const showRemoveFavoriteButton = isFavoriteMenuItem && !isMenuItemAvailableInOtherLocations;
  const showRemoveItemButton = disabledOrderItems.length > 0;
  const showCancelButton = !showRemoveFavoriteButton && !showRemoveItemButton;
  const showSkipButton = !showRemoveFavoriteButton && !showRemoveItemButton;

  const onChooseLocation = () => {
    navigate('/', { replace: true });
  };

  return (
    <Modal className="OrderDataValidator" show={notice !== undefined} onHide={onResetOrder}>
      <div className="modal-control">
        <ul>
          <li>
            <Modal.Dismiss className="btn-close-modal" />
          </li>
        </ul>
      </div>
      <Modal.Body className="kale">
        {notice}
        <div className="padded-top">
          <ButtonsRow>
            {isMenuPageGglocationMissing ? (
              <Button className="btn-primary" onClick={onChooseLocation}>
                Choose location
              </Button>
            ) : (
              <>
                {showRemoveItemButton && (
                  <Button className="btn-primary" onClick={onRemoveOrderItem}>
                    Remove Items
                  </Button>
                )}
                {showRemoveFavoriteButton && (
                  <Button className="btn-primary" onClick={onFavoriteRemove}>
                    Remove favourite
                  </Button>
                )}
                {showCancelButton && (
                  <Button className="btn-grey" onClick={onResetOrder}>
                    Cancel
                  </Button>
                )}
                {showSkipButton && (
                  <Button className="btn-primary" onClick={onResetOrderItem}>
                    Remove Items
                  </Button>
                )}
              </>
            )}
          </ButtonsRow>
        </div>
      </Modal.Body>
    </Modal>
  );
}

OrderDataValidator.propTypes = {
  isMenuItemMissing: PropTypes.bool.isRequired,
  isIngredientMissing: PropTypes.bool,
  disabledOrderItems: PropTypes.arrayOf(orderItemShape).isRequired,
  isFavoriteMenuItem: PropTypes.bool,
  isMenuItemAvailableInOtherLocations: PropTypes.bool.isRequired,
  isMenuPageGglocationMissing: PropTypes.bool,
  menuItemNames: PropTypes.arrayOf(PropTypes.string),
  onResetOrder: PropTypes.func.isRequired,
  onResetOrderItem: PropTypes.func.isRequired,
  onRemoveOrderItem: PropTypes.func.isRequired,
  onFavoriteRemove: PropTypes.func.isRequired,
};

OrderDataValidator.defaultProps = {
  isIngredientMissing: false,
  menuItemNames: [],
  isFavoriteMenuItem: false,
  isMenuPageGglocationMissing: false,
};

export default OrderDataValidator;
